import _ from "lodash";
import React from "react";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStarHalf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ratingStar = (rating) => {
    const ratingArr = [];
    if (rating) {
        for (let i = 0; i < rating; i++) {
            if (rating % 1 !== 0 && _.round(rating) == i) {
                ratingArr.push(
                    <FontAwesomeIcon
                        key={i * 10}
                        icon={faStarHalf}
                        style={{ color: "#fcb648" }}
                    />
                );
            } else {
                ratingArr.push(
                    <FontAwesomeIcon
                        key={i}
                        icon={faStar}
                        style={{ color: "#fcb648" }}
                    />
                );
            }
        }
    }
    return ratingArr;
};
