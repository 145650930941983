import axios from "axios";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import Loading from "../../../assets/videos/Loading.mp4";
import Snackbar from "../../../components/Snackbar";
import Navbar from "../../../components/Navbar";
import styles from "../StudentSubscriptionRegister/index.module.scss";
import CommonButton from "../../../components/Button/CommonButton";
import BG from "../../../assets/images/PlayPage/Student.jpg";
import PageLoader from "../../../components/PageLoader";
import { useHistory } from "react-router-dom";

const Upgrade2 = () => {
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [plans, setPlans] = useState([]);
    const [selectPlan, setSelectPlan] = useState(null);
    const history = useHistory();

    useEffect(() => {
        if (localStorage.getItem("auth")) {
            if (
                JSON.parse(localStorage.getItem("auth")).user.role === "student"
            ) {
                history.replace(`/choose-plan-and-subscription-plan`);
                getPlans();
            } else {
                history.replace(`/login`);
            }
        } else {
            history.replace(`/signup`);
        }

        // eslint-disable-next-line
    }, []);

    const getPlans = async () => {
        setLoading(true);
        await axios
            .get("api/v1/plan")
            .then((response) => {
                if (response?.data?.success) {
                    setPlans(response?.data?.data?.plan);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const handleSelectPlan = (p) => {
        setSelectPlan(p);
    };

    const handleConfirm = async () => {
        // console.log("confirm clicked", selectPlan?._id);
        const id = {
            id: selectPlan?._id,
        };
        try {
            const { data } = await axios.get(
                `/api/v1/subscription/checkout/${selectPlan?._id}`
            );
            if (data?.success) {
                window.open(data?.data?.checkout, "_self");
            } else {
                setMsg(_.get(data, "message", "Something went wrong."));
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                    // history.push(`/class-details/${classId}`);
                    history.goBack();
                }, 3000);
            }
        } catch (error) {
            setMsg(
                _.get(error, "response.data.message", "Something went wrong.")
            );
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
                history.goBack();
            }, 3000);
        }
    };

    return (
        <>
            <Navbar />
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            {loading && <PageLoader open={loading} />}
            <div className={styles.container}>
                <h1 className={styles.heading}>Choose your plan</h1>
                <p className={styles.instruction}>Subscribe for</p>
                {plans?.map((p, i) => {
                    return (
                        <div
                            key={i}
                            style={{ display: "grid", placeItems: "center" }}
                        >
                            <CommonButton
                                buttonText={`The ${
                                    p?.type
                                } Plan  ${p?.currency.toUpperCase()} ${
                                    p?.amount / 100
                                }`}
                                classes={
                                    selectPlan?._id === p?._id
                                        ? `${styles.upgrade2Button} ${styles.selectedUpgrade2Button}`
                                        : styles.upgrade2Button
                                }
                                handleClick={() => handleSelectPlan(p)}
                                key={i}
                            />
                            <p
                                className={styles.instruction}
                                style={{ paddingBottom: "20px" }}
                                key={(i + 1) * 1000}
                            >
                                {p?.total_minutes} Plasscoins (estimate value of GBP {p?.type==="Plass" ? "24" : "12"}) a month and access to unlimited FREE
                                sessions
                            </p>
                        </div>
                    );
                })}
                <CommonButton
                    buttonText="Confirm"
                    classes={styles.button}
                    handleClick={() => handleConfirm()}
                    disable={!selectPlan}
                />
                <div className={styles.otherLink}>
                    <span onClick={() => history.push("/planFaqs")}>
                        See plan details and FAQs
                    </span>
                    <span onClick={() => history.push("/classes")}>
                        Browse upcoming sessions
                    </span>
                </div>
            </div>
        </>
    );
};

export default Upgrade2;
