import React from "react";
import { useHistory } from "react-router-dom";
import LinearTeachersList from "../components/LinearTeachersList";
import SearchFilter from "../components/SearchFilter";
import styles from "./index.module.scss";

const TeachersList = ({
    teachers,
    listToDisplay,
    isSearchedFlag,
    searchedTerm,
    setSearchedTerm,
    setFilterKey,
    isFilteredFlag,
    setSortKey,
}) => {
    const history = useHistory();

    return teachers.teacherList.length || isSearchedFlag || isFilteredFlag ? (
        <div className={styles.dataContainer}>
            <div className={`${styles.rowHeader} ${styles.fullList}`}>
                Our amazing team of facilitators
                <SearchFilter
                    listToDisplay={listToDisplay}
                    searchedTerm={searchedTerm}
                    setSearchedTerm={setSearchedTerm}
                    setFilterKey={setFilterKey}
                    setSortKey={setSortKey}
                    isFrom={"Teachers"}
                />
            </div>

            {teachers.teacherList.length ? (
                <div className={styles.teacherListContainer}>
                    <LinearTeachersList
                        teacherList={
                            listToDisplay
                                ? teachers.teacherList
                                : teachers.teachersToDisplay
                        }
                    />

                    {!listToDisplay && (
                        <div
                            className={styles.iconContainer}
                            onClick={() => {
                                history.push("teachers");
                            }}
                        >
                            {/* <FontAwesomeIcon
                                icon={faEllipsisH}
                                className={styles.moreIcon}
                            /> */}
                            See more...
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    ) : null;
};

export default TeachersList;
