import React, { useState } from "react";
import styles from "../play.module.css";
import EndCall from "../../../../assets/images/End_call.png";
import Mute from "../../../../assets/images/Mute.png";
import Effects from "../../../../assets/images/Effects.png";
import ScreenArrangement from "../../../../assets/images/Screen _Arrangement_Icon.png";
import Message from "../../../../assets/images/Messages.png";
import ScreenShare from "../../../../assets/images/Screenshare.svg";
import Screenshot from "../../../../assets/images/Screenshot.png";
import View1 from "../../../../assets/images/student1.svg";
import View2 from "../../../../assets/images/student2.svg";
import View3 from "../../../../assets/images/student3.svg";
import View4 from "../../../../assets/images/student4.svg";
import ViewT1 from "../../../../assets/images/teacher1.svg";
import ViewT2 from "../../../../assets/images/teacher2.svg";
import ViewT3 from "../../../../assets/images/teacher3.svg";
import ViewT4 from "../../../../assets/images/teacher4.svg";
import ViewWB1 from "../../../../assets/images/WB 2 Student 1.svg";
import ViewWB2 from "../../../../assets/images/WB 2 Student 2.svg";
import ViewWB3 from "../../../../assets/images/WB 2 Student 3.svg";
import ViewWB4 from "../../../../assets/images/WB 2 Student 4.svg";
import ViewWBT1 from "../../../../assets/images/WB 2 Teacher 1.svg";
import ViewWBT2 from "../../../../assets/images/WB 2 Teacher 2.svg";
import ViewWBT3 from "../../../../assets/images/WB 2 Teacher 3.svg";
import LessonView1 from "../../../../assets/images/i_v student1.svg";
import LessonView2 from "../../../../assets/images/i_v student2.svg";
import LessonView3 from "../../../../assets/images/i_v student3.svg";
import LessonView4 from "../../../../assets/images/i_v student4.svg";
import LessonViewT1 from "../../../../assets/images/i_v teacher1.svg";
import LessonViewT2 from "../../../../assets/images/i_v teacher2.svg";
import LessonViewT3 from "../../../../assets/images/i_v teacher3.svg";
import LessonViewT4 from "../../../../assets/images/i_v teacher4.svg";

import HappyFaceOne from "../../../../assets/images/Happy Face 1.gif";
import HappyFace from "../../../../assets/images/2.Happy Face 2.gif";
import SadFace from "../../../../assets/images/3.Sad Face 1.gif";
import Applause from "../../../../assets/images/4.Applause 1.gif";
import Thumbsup1 from "../../../../assets/images/5.Thumbs Up 1.gif";
import Thumbsup2 from "../../../../assets/images/6.Thumbs Up 2.gif";
import Star1 from "../../../../assets/images/7.Star PNG 1.gif";
import Star2 from "../../../../assets/images/8.Star PNG 2.gif";
import ShootingStar from "../../../../assets/images/9.Shooting Star.gif";
import Play1 from "../../../../assets/images/12.Play Time 6.gif";
import Play2 from "../../../../assets/images/11.Play Time 5.gif";
import Play3 from "../../../../assets/images/10.Play Time 4.gif";
import Crown1 from "../../../../assets/images/13.Crown 1.gif";
import Crown2 from "../../../../assets/images/14.Crown 2.gif";
import Trophy1 from "../../../../assets/images/15.Trophy 1.gif";
import Trophy2 from "../../../../assets/images/16.Trophy 2.gif";
import Heart from "../../../../assets/images/17.Heart Emoji 1.gif";
import Music from "../../../../assets/images/18.Music Notes.gif";
import Wavinghand1 from "../../../../assets/images/19.Waving Hand 2 (1).gif";
import Wavinghand2 from "../../../../assets/images/20.Waving Hand 3 (1).gif";
import Wavinghand3 from "../../../../assets/images/20.Waving Hand 3.gif";
import Wavinghand4 from "../../../../assets/images/Waving Hand 4.gif";
import Wavinghand5 from "../../../../assets/images/Waving Hand 5.gif";

import CrowdCheer from "../../../../assets/audio/1.Crowd cheer.mp3";
import Tadaa from "../../../../assets/audio/2.Tadaa.mp3";
import Yay from "../../../../assets/audio/3.Yay.mp3";
import Bellend from "../../../../assets/audio/4.Bell end.mp3";
import ClockChime from "../../../../assets/audio/5.Clock chime.mp3";
import OrganEnd from "../../../../assets/audio/6.Organ end.mp3";
import HarpMelody from "../../../../assets/audio/7.Harp melody.mp3";
import TrumpetCall from "../../../../assets/audio/8.Trumpet call.mp3";
import WhistleStart from "../../../../assets/audio/9.Whistle start.wav";
import CrowdAww from "../../../../assets/audio/10.Crowd aww.mp3";
import Investigate from "../../../../assets/audio/11.Investigate.mp3";
import NegativeSound from "../../../../assets/audio/12.Negative sound.mp3";
import WrongAnswer from "../../../../assets/audio/13.Wrong answer.mp3";
import Readysetgo from "../../../../assets/audio/14.Ready,set,go.wav";
import BirdTweet from "../../../../assets/audio/15.Bird Tweet.mp3";
import CatMeow from "../../../../assets/audio/16.Cat Meow.wav";
import ChickenCluck from "../../../../assets/audio/17.Chicken Clucks.mp3";
import DogBark from "../../../../assets/audio/18.Dog Bark.wav";
import LionRoar from "../../../../assets/audio/19.Lion Roar.mp3";
import SnakeHiss from "../../../../assets/audio/20.Snake Hiss.wav";

import { Row, Col } from "react-bootstrap";
import axios from "axios";
import html2canvas from "html2canvas";
import { useParams } from "react-router-dom";
import Snackbar from "../../../../components/Snackbar";
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default ({
    setStickerValue,
    handleBtn,
    btn,
    session,
    endCall,
    mute,
    setMute,
    muteAll,
    unMuteAll,
    setScreenArrangement,
    screenArrangement,
    handleStudentView,
    studentView,
    teacherView,
    handleTeacherView,
    handleWhiteboardTeacherView,
    handleWhiteboardStudentView,
    whiteboardStudentView,
    shareScreenHandler,
    whiteboardTeacherView,
    currentPanel,
    participants,
    socket,
    twilio,
}) => {
    const [tab, setTab] = useState("student");
    const [effects, setEffects] = useState(false);
    const [effectsTab, setEffectsTab] = useState("sticker");
    const [sticker, setSticker] = useState(null);
    const [sound, setSound] = useState(null);
    const { sessionID } = useParams();
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [leaveModal, setLeaveModal] = useState(false);
    const handleScreenArrangement = () => {
        if (currentPanel.type !== "ACTIVITY")
            setScreenArrangement(!screenArrangement);
    };

    const handleEffects = () => {
        setEffects(!effects);
    };

    const handleEffectsTab = (value) => {
        setEffectsTab(value);
    };
    const handleTab = (value) => {
        setTab(value);
    };

    const handlePlay = (e, value) => {
        setSound(value);
        let element = e.currentTarget;
        var r1 = element.querySelector("audio");
        r1.play();
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        socket.emit("playSound", {
            action: "playSound",
            payload: {
                sound: value,
                userIds,
            },
        });
    };

    const handleSticker = (value) => {
        setSticker(value);
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        userIds.push(session.teacher_id)
        socket.emit("sendStickerToAll", {
            action: "sendStickerToAll",
            payload: {
                graphic: value,
                userIds,
            },
        });
        if (setStickerValue) {
            setStickerValue(value)
        }
    };

    const dataURItoBlob = (dataURI) => {
        var byteString;
        if (dataURI.split(",")[0].indexOf("base64") >= 0)
            byteString = atob(dataURI.split(",")[1]);
        else byteString = unescape(dataURI.split(",")[1]);
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    };

    const screenShot = async () => {
        let canvas = document.createElement("canvas"); // declare a canvas element in your html
        let ctx = canvas.getContext("2d");
        let videos = document.querySelectorAll("video");
        let w, h;
        for (let i = 0, len = videos.length; i < len; i++) {
            const v = videos[i];
            if (!v) continue; // no video here
            try {
                w = v.videoWidth;
                h = v.videoHeight;
                canvas.width = w;
                canvas.height = h;
                ctx.fillRect(0, 0, w, h);
                ctx.drawImage(v, 0, 0, w, h);
                v.style.backgroundImage = `url(${canvas.toDataURL()})`;
                v.style.backgroundSize = "cover";
            } catch (e) {
                console.log(e);
                continue;
            }
        }
        html2canvas(document.body).then(async function (canvas) {
            try {
                var base64URL = canvas.toDataURL("image/png");
                var blob = dataURItoBlob(base64URL);
                let formData = new FormData();
                formData.append("image", blob);
                formData.append("session_id", sessionID);
                await axios
                    .post("/api/v1/user/media", formData)
                    .then((result) => { })
                    .catch((error) => {
                        setMsg(
                            _.get(
                                error,
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                        return Promise.reject(error);
                    });
            } catch (error) {
                console.log(error);
            }
        });
    };

    const handleLeaveModal = (value) => {
        setLeaveModal(false);
    };

    return (
        <div className={`${styles.buttonWrapper}`}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            {leaveModal && (
                <div
                    className={`${styles.uploadModal} text-center`}
                    style={{ top: "30%" }}
                >
                    <FontAwesomeIcon
                        className={styles.crossIcon}
                        icon={faTimes}
                        onClick={() => setLeaveModal(false)}
                    />
                    <div className={styles.uploadArea}>
                        <p className={styles.leaveModalHeading}>
                            Are you sure you would like to <br></br> end this session?
                        </p>
                    </div>
                    <div className={styles.actionButtonsContainer}>
                        <div
                            className={styles.actionButton}
                            onClick={() => {
                                endCall()
                            }}
                        >
                            End session
                        </div>
                    </div>
                </div>
            )}
            <div
                onMouseEnter={handleBtn}
                onMouseLeave={handleBtn}
                className={styles.btn}
            >
                {btn ? (
                    <Row>
                        <Col md={4} className={styles.btnText}>
                            <p>{session?.class_name}</p>
                        </Col>
                        <Col md={4} style={{ textAlign: "center" }}>
                            <img
                                className={styles.icon}
                                src={EndCall}
                                alt="plass Begin"
                                onClick={() => {
                                    // endCall();
                                    setLeaveModal(true)
                                }}
                            />
                            <img
                                className={styles.icon}
                                src={Mute}
                                onClick={() => {
                                    if (!mute) {
                                        setMute(true);
                                        muteAll();
                                    } else {
                                        setMute(false);
                                        unMuteAll();
                                    }
                                }}
                                alt="plass Begin"
                            />
                            <img
                                className={styles.icon}
                                src={ScreenShare}
                                alt="plass Begin"
                                onClick={(event) => {
                                    event.preventDefault();
                                    shareScreenHandler();
                                }}
                            />
                        </Col>
                        <Col
                            md={4}
                            style={{
                                textAlign: "right",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <div style={{ position: "relative" }}>
                                <img
                                    onClick={() => {
                                        handleEffects();
                                    }}
                                    className={styles.icon}
                                    src={Effects}
                                    alt="plass Begin"
                                />
                                {effects && (
                                    <div className={styles.effectsWrapper}>
                                        <div className={styles.effectsScroll}>
                                            <div
                                                className={
                                                    styles.arrangementTab
                                                }
                                            >
                                                <p
                                                    style={{
                                                        backgroundColor:
                                                            effectsTab ===
                                                                "sticker"
                                                                ? "#88ADDB"
                                                                : "",
                                                        color:
                                                            effectsTab ===
                                                                "sticker"
                                                                ? "#fff"
                                                                : "",
                                                    }}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        handleEffectsTab(
                                                            "sticker"
                                                        );
                                                    }}
                                                >
                                                    Stickers
                                                </p>
                                                <p
                                                    style={{
                                                        backgroundColor:
                                                            effectsTab ===
                                                                "sound"
                                                                ? "#88ADDB"
                                                                : "",
                                                        color:
                                                            effectsTab ===
                                                                "sound"
                                                                ? "#fff"
                                                                : "",
                                                    }}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        handleEffectsTab(
                                                            "sound"
                                                        );
                                                    }}
                                                >
                                                    Sounds
                                                </p>
                                            </div>
                                            <div
                                                className={
                                                    styles.effectsSection
                                                }
                                            >
                                                {effectsTab === "sticker" ? (
                                                    <>
                                                        <div
                                                            className={
                                                                styles.stickerContainer
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.stickerScroll
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                HappyFaceOne
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    HappyFaceOne
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                HappyFaceOne
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                HappyFace
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    HappyFace
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                HappyFace
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                SadFace
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    SadFace
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                SadFace
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Applause
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Applause
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Applause
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Thumbsup1
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Thumbsup1
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Thumbsup1
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Thumbsup2
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Thumbsup2
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Thumbsup2
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Star1
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Star1
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Star1
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Star2
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Star2
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Star2
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                ShootingStar
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    ShootingStar
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                ShootingStar
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Play1
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Play1
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Play1
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Play2
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Play2
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Play2
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Play3
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Play3
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Play3
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Crown1
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Crown1
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Crown1
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Crown2
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Crown2
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Crown2
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Trophy1
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Trophy1
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Trophy1
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Trophy2
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Trophy2
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Trophy2
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Heart
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Heart
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Heart
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Music
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Music
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Music
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Wavinghand1
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Wavinghand1
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Wavinghand1
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Wavinghand3
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Wavinghand3
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Wavinghand3
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Wavinghand4
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Wavinghand4
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Wavinghand4
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={() => {
                                                                            handleSticker(
                                                                                Wavinghand5
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sticker ===
                                                                                    Wavinghand5
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            src={
                                                                                Wavinghand5
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div>
                                                        <div
                                                            className={
                                                                styles.stickerContainer
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.stickerScroll
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                CrowdCheer
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    CrowdCheer
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    CrowdCheer
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            alt="Logo"
                                                                        >
                                                                            Crowd
                                                                            Cheer
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                Tadaa
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    Tadaa
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    Tadaa
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            alt="Logo"
                                                                        >
                                                                            Tadaa
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                Yay
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    Yay
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    Yay
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            alt="Logo"
                                                                        >
                                                                            Yay
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                Bellend
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    Bellend
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    Bellend
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            alt="Logo"
                                                                        >
                                                                            Bell
                                                                            end
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                ClockChime
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    ClockChime
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    ClockChime
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            alt="Logo"
                                                                        >
                                                                            Clock
                                                                            chime
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                OrganEnd
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    OrganEnd
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    OrganEnd
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                            alt="Logo"
                                                                        >
                                                                            Organ
                                                                            end
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                HarpMelody
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    HarpMelody
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    HarpMelody
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                        >
                                                                            Harp
                                                                            melody
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                TrumpetCall
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    TrumpetCall
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    TrumpetCall
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                        >
                                                                            Trumpet
                                                                            call
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                WhistleStart
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    WhistleStart
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    WhistleStart
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                        >
                                                                            Whistle
                                                                            start
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                CrowdAww
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    CrowdAww
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    CrowdAww
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                        >
                                                                            Crowd
                                                                            aww
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                Investigate
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    Investigate
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    Investigate
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                        >
                                                                            Investi-gate
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                NegativeSound
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    NegativeSound
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    NegativeSound
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                        >
                                                                            Negative
                                                                            sound
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                WrongAnswer
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    WrongAnswer
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    WrongAnswer
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                        >
                                                                            Wrong
                                                                            answer
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                Readysetgo
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    Readysetgo
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    Readysetgo
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                        >
                                                                            Ready,
                                                                            set,go
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                BirdTweet
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    BirdTweet
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    BirdTweet
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                        >
                                                                            Bird
                                                                            tweet
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                CatMeow
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    CatMeow
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    CatMeow
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                        >
                                                                            Cat
                                                                            meow
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                ChickenCluck
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    ChickenCluck
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    ChickenCluck
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                        >
                                                                            Chicken
                                                                            cluck
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                DogBark
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    DogBark
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    DogBark
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                        >
                                                                            Dog
                                                                            bark
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={
                                                                        styles.stickerRow
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                LionRoar
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    LionRoar
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    LionRoar
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                        >
                                                                            Lion
                                                                            roar
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.stickerWraper
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handlePlay(
                                                                                event,
                                                                                SnakeHiss
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            border:
                                                                                sound ===
                                                                                    SnakeHiss
                                                                                    ? "3px solid #88ADDB"
                                                                                    : "",
                                                                        }}
                                                                    >
                                                                        <audio id="myAudio">
                                                                            <source
                                                                                src={
                                                                                    SnakeHiss
                                                                                }
                                                                                type="audio/mpeg"
                                                                            />
                                                                        </audio>
                                                                        <p
                                                                            className={
                                                                                styles.sticker
                                                                            }
                                                                        >
                                                                            Snake
                                                                            hiss
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div style={{ position: "relative" }}>
                                <img
                                    onClick={() => {
                                        handleScreenArrangement();
                                    }}
                                    src={ScreenArrangement}
                                    alt="plass Begin"
                                    className={styles.icon}
                                />
                                {screenArrangement && (
                                    <div
                                        className={
                                            styles.screenArrangementWrapper
                                        }
                                    >
                                        <div
                                            className={
                                                styles.screenArrangementScroll
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.arrangementTab
                                                }
                                            >
                                                <p
                                                    style={{
                                                        backgroundColor:
                                                            tab === "student"
                                                                ? "#88ADDB"
                                                                : "",
                                                        color:
                                                            tab === "student"
                                                                ? "#fff"
                                                                : "",
                                                    }}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        handleTab("student");
                                                    }}
                                                >
                                                    Student
                                                </p>
                                                <p
                                                    style={{
                                                        backgroundColor:
                                                            tab === "teacher"
                                                                ? "#88ADDB"
                                                                : "",
                                                        color:
                                                            tab === "teacher"
                                                                ? "#fff"
                                                                : "",
                                                    }}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        handleTab("teacher");
                                                    }}
                                                >
                                                    Teacher
                                                </p>
                                            </div>
                                            <div
                                                className={styles.imageSection}
                                            >
                                                {currentPanel.type ===
                                                    "VIDEO" && currentPanel?.subType !== "SCREENSHARE" ? (
                                                    tab === "student" ? (
                                                        <>
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleStudentView(
                                                                        "view1"
                                                                    );
                                                                }}
                                                                src={View1}
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .video_settings
                                                                            .student_view ===
                                                                            "view1"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .video_settings
                                                                            .student_view ===
                                                                            "view1"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleStudentView(
                                                                        "view2"
                                                                    );
                                                                }}
                                                                src={View2}
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .video_settings
                                                                            .student_view ===
                                                                            "view2"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .video_settings
                                                                            .student_view ===
                                                                            "view2"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleStudentView(
                                                                        "view3"
                                                                    );
                                                                }}
                                                                src={View3}
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .video_settings
                                                                            .student_view ===
                                                                            "view3"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .video_settings
                                                                            .student_view ===
                                                                            "view3"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleStudentView(
                                                                        "view4"
                                                                    );
                                                                }}
                                                                src={View4}
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .video_settings
                                                                            .student_view ===
                                                                            "view4"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .video_settings
                                                                            .student_view ===
                                                                            "view4"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleTeacherView(
                                                                        "viewT1"
                                                                    );
                                                                }}
                                                                src={ViewT1}
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .video_settings
                                                                            .teacher_view ===
                                                                            "viewT1"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .video_settings
                                                                            .teacher_view ===
                                                                            "viewT1"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleTeacherView(
                                                                        "viewT2"
                                                                    );
                                                                }}
                                                                src={ViewT2}
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .video_settings
                                                                            .teacher_view ===
                                                                            "viewT2"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .video_settings
                                                                            .teacher_view ===
                                                                            "viewT2"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleTeacherView(
                                                                        "viewT3"
                                                                    );
                                                                }}
                                                                src={ViewT3}
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .video_settings
                                                                            .teacher_view ===
                                                                            "viewT3"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .video_settings
                                                                            .teacher_view ===
                                                                            "viewT3"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleTeacherView(
                                                                        "viewT4"
                                                                    );
                                                                }}
                                                                src={ViewT4}
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .video_settings
                                                                            .teacher_view ===
                                                                            "viewT4"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .video_settings
                                                                            .teacher_view ===
                                                                            "viewT4"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                ) : currentPanel.type ===
                                                    "LESSON" &&
                                                    currentPanel.subType !==
                                                    "WHITEBOARD" ? (
                                                    tab === "student" ? (
                                                        <>
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleStudentView(
                                                                        "view1"
                                                                    );
                                                                }}
                                                                src={
                                                                    LessonView1
                                                                }
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .assets
                                                                            .view
                                                                            .student ===
                                                                            "view1"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .assets
                                                                            .view
                                                                            .student ===
                                                                            "view1"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleStudentView(
                                                                        "view2"
                                                                    );
                                                                }}
                                                                src={
                                                                    LessonView2
                                                                }
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .assets
                                                                            .view
                                                                            .student ===
                                                                            "view2"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .assets
                                                                            .view
                                                                            .student ===
                                                                            "view2"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleStudentView(
                                                                        "view3"
                                                                    );
                                                                }}
                                                                src={
                                                                    LessonView3
                                                                }
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .assets
                                                                            .view
                                                                            .student ===
                                                                            "view3"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .assets
                                                                            .view
                                                                            .student ===
                                                                            "view3"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleStudentView(
                                                                        "view4"
                                                                    );
                                                                }}
                                                                src={
                                                                    LessonView4
                                                                }
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .assets
                                                                            .view
                                                                            .student ===
                                                                            "view4"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .assets
                                                                            .view
                                                                            .student ===
                                                                            "view4"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleTeacherView(
                                                                        "viewT1"
                                                                    );
                                                                }}
                                                                src={
                                                                    LessonViewT1
                                                                }
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .assets
                                                                            .view
                                                                            .teacher ===
                                                                            "viewT1"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .assets
                                                                            .view
                                                                            .teacher ===
                                                                            "viewT1"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleTeacherView(
                                                                        "viewT2"
                                                                    );
                                                                }}
                                                                src={
                                                                    LessonViewT2
                                                                }
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .assets
                                                                            .view
                                                                            .teacher ===
                                                                            "viewT2"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .assets
                                                                            .view
                                                                            .teacher ===
                                                                            "viewT2"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleTeacherView(
                                                                        "viewT3"
                                                                    );
                                                                }}
                                                                src={
                                                                    LessonViewT3
                                                                }
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .assets
                                                                            .view
                                                                            .teacher ===
                                                                            "viewT3"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .assets
                                                                            .view
                                                                            .teacher ===
                                                                            "viewT3"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleTeacherView(
                                                                        "viewT4"
                                                                    );
                                                                }}
                                                                src={
                                                                    LessonViewT4
                                                                }
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .assets
                                                                            .view
                                                                            .teacher ===
                                                                            "viewT4"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .assets
                                                                            .view
                                                                            .teacher ===
                                                                            "viewT4"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                ) : (
                                                    ""
                                                )}

                                                {currentPanel.type ===
                                                    "LESSON" &&
                                                    currentPanel.subType ===
                                                    "WHITEBOARD" &&
                                                    tab === "student" &&
                                                    !currentPanel
                                                        .lesson_settings
                                                        .whiteboard.student
                                                        .required_whiteboard && (
                                                        <>
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleStudentView(
                                                                        "view1"
                                                                    );
                                                                }}
                                                                src={
                                                                    LessonView1
                                                                }
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .student
                                                                            .view ===
                                                                            "view1"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .student
                                                                            .view ===
                                                                            "view1"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleStudentView(
                                                                        "view2"
                                                                    );
                                                                }}
                                                                src={
                                                                    LessonView2
                                                                }
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .student
                                                                            .view ===
                                                                            "view2"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .student
                                                                            .view ===
                                                                            "view2"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleStudentView(
                                                                        "view3"
                                                                    );
                                                                }}
                                                                src={
                                                                    LessonView3
                                                                }
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .student
                                                                            .view ===
                                                                            "view3"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .student
                                                                            .view ===
                                                                            "view3"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                        </>
                                                    )}

                                                {currentPanel.type ===
                                                    "LESSON" &&
                                                    currentPanel.subType ===
                                                    "WHITEBOARD" &&
                                                    tab === "teacher" &&
                                                    !currentPanel
                                                        .lesson_settings
                                                        .whiteboard.student
                                                        .required_whiteboard && (
                                                        <>
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleTeacherView(
                                                                        "viewT1"
                                                                    );
                                                                }}
                                                                src={
                                                                    LessonViewT2
                                                                }
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .teacher
                                                                            .view ===
                                                                            "viewT1"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .teacher
                                                                            .view ===
                                                                            "viewT1"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleTeacherView(
                                                                        "viewT2"
                                                                    );
                                                                }}
                                                                src={
                                                                    LessonViewT4
                                                                }
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .teacher
                                                                            .view ===
                                                                            "viewT2"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .teacher
                                                                            .view ===
                                                                            "viewT2"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleTeacherView(
                                                                        "viewT3"
                                                                    );
                                                                }}
                                                                src={
                                                                    LessonViewT3
                                                                }
                                                                alt="plass Begin"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .teacher
                                                                            .view ===
                                                                            "viewT3"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .teacher
                                                                            .view ===
                                                                            "viewT3"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                        </>
                                                    )}

                                                {currentPanel.type ===
                                                    "LESSON" &&
                                                    currentPanel.subType ===
                                                    "WHITEBOARD" &&
                                                    tab === "student" &&
                                                    currentPanel.lesson_settings
                                                        .whiteboard.student
                                                        .required_whiteboard && (
                                                        <>
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleStudentView(
                                                                        "view1"
                                                                    );
                                                                }}
                                                                src={ViewWB1}
                                                                alt="plass Begin"
                                                                className={
                                                                    styles.imgSize
                                                                }
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .student
                                                                            .view ===
                                                                            "view1"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .student
                                                                            .view ===
                                                                            "view1"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleStudentView(
                                                                        "view2"
                                                                    );
                                                                }}
                                                                src={ViewWB2}
                                                                alt="plass Begin"
                                                                className={
                                                                    styles.imgSize
                                                                }
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .student
                                                                            .view ===
                                                                            "view2"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .student
                                                                            .view ===
                                                                            "view2"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleStudentView(
                                                                        "view3"
                                                                    );
                                                                }}
                                                                src={ViewWBT3}
                                                                alt="plass Begin"
                                                                className={
                                                                    styles.imgSize
                                                                }
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .student
                                                                            .view ===
                                                                            "view3"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .student
                                                                            .view ===
                                                                            "view3"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                        </>
                                                    )}

                                                {currentPanel.type ===
                                                    "LESSON" &&
                                                    currentPanel.subType ===
                                                    "WHITEBOARD" &&
                                                    tab === "teacher" &&
                                                    currentPanel.lesson_settings
                                                        .whiteboard.student
                                                        .required_whiteboard && (
                                                        <>
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleTeacherView(
                                                                        "viewT1"
                                                                    );
                                                                }}
                                                                src={ViewWBT1}
                                                                alt="plass Begin"
                                                                className={
                                                                    styles.imgSize
                                                                }
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .teacher
                                                                            .view ===
                                                                            "viewT1"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .teacher
                                                                            .view ===
                                                                            "viewT1"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleTeacherView(
                                                                        "viewT2"
                                                                    );
                                                                }}
                                                                src={ViewWBT2}
                                                                alt="plass Begin"
                                                                className={
                                                                    styles.imgSize
                                                                }
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .teacher
                                                                            .view ===
                                                                            "viewT2"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .teacher
                                                                            .view ===
                                                                            "viewT2"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    handleTeacherView(
                                                                        "viewT3"
                                                                    );
                                                                }}
                                                                src={ViewWBT3}
                                                                alt="plass Begin"
                                                                className={
                                                                    styles.imgSize
                                                                }
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .teacher
                                                                            .view ===
                                                                            "viewT3"
                                                                            ? "5px solid #88addb"
                                                                            : "",
                                                                    borderRadius:
                                                                        currentPanel
                                                                            .lesson_settings
                                                                            .whiteboard
                                                                            .teacher
                                                                            .view ===
                                                                            "viewT3"
                                                                            ? "26px"
                                                                            : "",
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* <img
                className={styles.icon}
                src={Screenshot}
                alt="plass Begin"
                onClick={(event) => {
                  event.preventDefault();
                  screenShot();
                }}
              /> */}
                        </Col>
                    </Row>
                ) : (
                    <hr className={styles.line} align="center" width="5%" />
                )}
            </div>
        </div>
    );
};
