import {
    faChevronLeft,
    faChevronRight,
    faFile,
    faFileAudio,
    faPauseCircle,
    faPlayCircle,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
    ImageFormats,
    MusicFormats,
    VideoFormats,
} from "../../utils/AcceptedFileFormats";
import styles from "./ImageInputModalSelectedFiles.module.scss";

const AudioFileComponent = ({ file, fileName }) => {
    const audioRef = useRef();
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);

    const handlePlayAudio = () => {
        audioRef.current.play();
        setIsAudioPlaying(true);
    };

    const handlePauseAudio = () => {
        audioRef.current.pause();
        setIsAudioPlaying(false);
    };

    return (
        <Fragment>
            <span className={styles.audioFileIconContainer}>
                <FontAwesomeIcon
                    icon={faFileAudio}
                    className={styles.fileIcon}
                />

                <FontAwesomeIcon
                    icon={isAudioPlaying ? faPauseCircle : faPlayCircle}
                    className={styles.controlIcon}
                    onClick={
                        isAudioPlaying ? handlePauseAudio : handlePlayAudio
                    }
                />

                <audio src={_.get(file, "location")} ref={audioRef} />
            </span>

            <span className={styles.fileName}>{fileName}</span>
        </Fragment>
    );
};

const ImageInputModalSelectedFiles = ({
    selectedFiles,
    handleSelectedFile,
}) => {
    const [filesToDisplay, setFilesToDisplay] = useState({
        index: -1,
        files: [],
    });

    /**
     * @description Handle selected files to display in the grid of 3.
     */
    useEffect(() => {
        handleImagesToDisplay();

        // eslint-disable-next-line
    }, [selectedFiles]);

    const handleImagesToDisplay = (status) => {
        let localFilesToDisplay;
        const flag = status ? filesToDisplay.index : -1;

        localFilesToDisplay =
            status === "prev"
                ? selectedFiles.slice(flag - 1, flag + 2)
                : selectedFiles.slice(flag + 1, flag + 4);

        setFilesToDisplay({
            index: status === "prev" ? flag - 1 : flag + 1,
            files: localFilesToDisplay,
        });
    };

    return (
        <div className={styles.ImageInputModalSelectedFilesContainer}>
            <span>
                <FontAwesomeIcon
                    icon={faChevronLeft}
                    className={`${styles.navigationIcon} ${
                        _.get(filesToDisplay, "index") > 0 &&
                        _.get(selectedFiles, "length") > 3
                            ? null
                            : styles.zeroOpacity
                    }`}
                    onClick={() =>
                        _.get(filesToDisplay, "index") > 0 &&
                        _.get(selectedFiles, "length") > 3
                            ? handleImagesToDisplay("prev")
                            : null
                    }
                />
            </span>

            <div className={styles.imagesListContainer}>
                {_.get(filesToDisplay, "files", []).map((file, index) => {
                    const fileFormat = _.last(
                        _.split(
                            _.get(file, "file.name", "") ||
                                _.get(file, "location", ""),
                            "."
                        )
                    ).toUpperCase();

                    const fileName =
                        _.get(file, "file.name", "") || "File Name";

                    return (
                        <div className={styles.imageContainer} key={index}>
                            <span>
                                <FontAwesomeIcon
                                    className={styles.removeIcon}
                                    icon={faTimes}
                                    onClick={() => handleSelectedFile(file)}
                                />
                            </span>
                            {[...ImageFormats].includes(fileFormat) ? (
                                <img
                                    src={_.get(file, "location")}
                                    alt="Selected Item"
                                />
                            ) : [...VideoFormats].includes(fileFormat) ? (
                                <video
                                    src={_.get(file, "location")}
                                    alt="Selected Item"
                                    controls
                                />
                            ) : [...MusicFormats].includes(fileFormat) ? (
                                <AudioFileComponent
                                    file={file}
                                    fileName={fileName}
                                />
                            ) : (
                                <Fragment>
                                    <span className={styles.fileIconContainer}>
                                        <FontAwesomeIcon
                                            icon={faFile}
                                            className={styles.fileIcon}
                                        />
                                    </span>

                                    <span className={styles.fileName}>
                                        {fileName}
                                    </span>
                                </Fragment>
                            )}
                        </div>
                    );
                })}
            </div>

            <span>
                <FontAwesomeIcon
                    icon={faChevronRight}
                    className={`${styles.navigationIcon} ${
                        filesToDisplay.index !== selectedFiles.length - 3 &&
                        _.get(selectedFiles, "length") > 3
                            ? null
                            : styles.zeroOpacity
                    }`}
                    onClick={() =>
                        filesToDisplay.index !== selectedFiles.length - 3 &&
                        _.get(selectedFiles, "length") > 3
                            ? handleImagesToDisplay("next")
                            : null
                    }
                />
            </span>
        </div>
    );
};

export default ImageInputModalSelectedFiles;
