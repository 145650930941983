import React, { useState, useEffect } from "react";
import styles from "./sessionCreate.module.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "react-datetime/css/react-datetime.css";
import Snackbar from "../../../components/Snackbar";
import _ from "lodash";

export default () => {
    const history = useHistory();
    const [teacher, setTeacher] = useState(null);
    const [students, setStudents] = useState(null);
    const [userList, setUserList] = useState([]);
    const [dt, setDt] = useState(moment());
    const [lessons, setLessons] = useState([]);
    const [games, setGames] = useState([]);
    const [sessionName, setSessionName] = useState("");
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await axios
                .get("/api/v1/users")
                .then((response) => {
                    setUserList(response.data.users);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        }
        fetchData();
        // eslint-disable-next-line
    }, []);
    const teacherList = userList
        .filter((user) => user.role === "teacher")
        .map((obj) => {
            return {
                label: `${obj.username} (${obj.email})`,
                value: obj._id,
            };
        });
    const studentList = userList
        .filter((user) => user.role === "student")
        .map((obj) => {
            return {
                label: `${obj.username} (${obj.email})`,
                value: obj._id,
            };
        });

    const lessonList = [
        { label: "Lesson 1", value: "lesson1" },
        { label: "Lesson 2", value: "lesson2" },
    ];
    const gameList = [
        { label: "Bubble pop", value: "bubblePop" },
        { label: "Tile match", value: "tileMatch" },
        { label: "Maze", value: "maze" },
    ];
    const createSession = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const studentsId = students.map((student) => {
            return student.value;
        });
        const selectedLessons = lessons.map((obj) => obj.value);
        const selectedGames = games.map((obj) => obj.value);

        await axios
            .post("/api/v1/session", {
                startTime: dt._d,
                teacherId: teacher.value,
                studentsId,
                selectedGames,
                selectedLessons,
                sessionName,
            })
            .then((response) => {
                if (response.data.success) {
                    setMsg(response.data.message);
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    history.replace("/admin");
                } else {
                    setMsg(response.data.message);
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };
    return (
        <Container fluid id={styles.container}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <FontAwesomeIcon
                icon={faTimes}
                onClick={() => {
                    history.push("/admin");
                }}
                id={styles.icon}
            />
            <Row>
                <Col sm={4} id={styles.sessionHeader}>
                    <h1 style={{ textAlign: "center" }}>Create Session</h1>
                    <Form onSubmit={createSession}>
                        <Form.Group controlId="sessionName">
                            <Form.Label>Session Name</Form.Label>
                            <Form.Control
                                onChange={(event) =>
                                    setSessionName(event.target.value)
                                }
                                type="text"
                            />
                        </Form.Group>
                        <Form.Group controlId="teacherId">
                            <Form.Label>Teacher</Form.Label>
                            <Select
                                onChange={(data) => {
                                    setTeacher(data);
                                }}
                                value={teacher}
                                className="basic-single"
                                classNamePrefix="select"
                                // defaultValue={colourOptions[0]}
                                name="teacher"
                                options={teacherList}
                            />
                        </Form.Group>

                        <Form.Group controlId="studentId">
                            <Form.Label>Students</Form.Label>
                            <Select
                                isMulti
                                value={students}
                                onChange={(data) => {
                                    setStudents(data);
                                }}
                                name="students"
                                options={studentList}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </Form.Group>
                        <Form.Group controlId="lessonsId">
                            <Form.Label>Lessons</Form.Label>
                            <Select
                                isMulti
                                value={lessons}
                                onChange={(data) => {
                                    setLessons(data);
                                }}
                                name="Lessons"
                                options={lessonList}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </Form.Group>
                        <Form.Group controlId="games">
                            <Form.Label>Games</Form.Label>
                            <Select
                                isMulti
                                value={games}
                                onChange={(data) => {
                                    setGames(data);
                                }}
                                name="games"
                                options={gameList}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Date and Time</Form.Label>
                            <Datetime
                                value={dt}
                                onChange={(val) => setDt(val)}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Create
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};
