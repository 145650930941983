import React, { useEffect, useState } from "react";
import styles from "./preview.module.scss";
import { useHistory } from "react-router-dom";
import Student from "./Student";
import Teacher from "./Teacher";

const ScreensharePreview = ({ currentPanel, handlePreview, studentView }) => {
    const [imageSrc, setImageSrc] = useState("");
    let teacherView;
    const history = useHistory();
    useEffect(() => {
        // console.log("--->>>>cccc", currentPanel);
        // eslint-disable-next-line
    }, [currentPanel]);
    teacherView = currentPanel?.video_settings?.teacher_view;
    const studentV = currentPanel?.video_settings?.student_view;

    const exitPreview = () => {
        history.goBack();
    };

    const setImage = (src) => {
        setImageSrc(src);
    };

    return teacherView ? (
        <div>
            {/* <div className={styles.closeButton} onClick={() => exitPreview()}>
                Close
            </div> */}
            <div className={styles.previewContainer}>
                {!studentView ? (
                    teacherView === "viewT1" ? (
                        <Teacher
                            currentPanel={currentPanel}
                            imageSrc={setImage}
                        />
                    ) : null
                ) : null}
                {studentView ? (
                    studentV === "view1" ? (
                        <Student
                            currentPanel={currentPanel}
                            imageSrc={imageSrc}
                        />
                    ) : null
                ) : null}
            </div>
        </div>
    ) : null;
};

export default ScreensharePreview;
