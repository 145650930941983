import React, { useEffect, useState } from "react";
import styles from "./Layout1.module.css";
import TwilioParticipant from "../../../../../../../components/TwilioParticipant";
import Participant from "../../../../../../../components/Participant";
import { Row } from "react-bootstrap";
import Whiteboard from "../../../../../../../components/WhiteboardByNirajan";
import axios from "axios";
import Snackbar from "../../../../../../../components/Snackbar";
import _ from "lodash";

export default ({
    participants,
    drawData,
    room,
    mutedUsers,
    publishAudio,
    socket,
    role,
    key,
    currentPanel,
    session,
}) => {
    var Teacher = null;
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [initialState, setInitaialState] = useState(false);
    const [fitToScreen, setFitToScreen] = useState(
        currentPanel.lesson_settings.whiteboard.fitToScreen
    );

    const [team, setTeam] = useState();
    const [teacherTeam, setTeacherTeam] = useState();
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const [teacherDrawData, setTeacherDrawData] = useState([]);
    const [studentDrawData, setStudentDrawData] = useState([]);
    const [position, setPosition] = useState(
        currentPanel?.lesson_settings?.whiteboard?.position
    );

    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;

    useEffect(() => {
        const getSelectedMedia = async () => {
            const selectedMediaArray =
                currentPanel.lesson_settings?.whiteboard?.image_ids;
            let galleryData = {
                selectedMediaArray: selectedMediaArray,
                userId: session.teacher_id,
            };
            await axios
                .post(`/api/v1/user/gallery/selected`, galleryData)
                .then((data) => {
                    if (data.data.success) {
                        const resultMedia = data.data.data.newArray;
                        setSelectedMedia(resultMedia);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };

        var teacherParticipant = participants.find((obj) => {
            var identityObj = JSON.parse(obj.identity);
            return identityObj.role === "teacher";
        });

        let teacherId;

        if (teacherParticipant) {
            var identityObj = JSON.parse(teacherParticipant.identity);
            teacherId = identityObj._id;
        }

        let teamData = currentPanel.lesson_settings.whiteboard.teams.filter(
            (obj) =>
                obj.value === JSON.parse(localStorage.getItem("auth")).user._id
        );
        if (teamData.length) {
            let team = teamData[0].team;
            if (team) {
                setTeam(team);
            }
        }

        let teacherTeamData =
            currentPanel.lesson_settings.whiteboard.teams.filter(
                (obj) => obj.label === "Teacher"
            );
        if (teacherTeamData.length) {
            let teacherTeams = teacherTeamData[0].team;
            if (teacherTeams) {
                setTeacherTeam(teacherTeams);
            }
        }

        const getTeamDrawData = async () => {
            let teacherData = [];
            let studentData = [];

            drawData.forEach((obj) => {
                if (obj.team === team) {
                    studentData.push(obj.draw_data);
                } else if (obj.team === teacherTeam) {
                    teacherData.push(obj.draw_data);
                }
            });

            setTeacherDrawData(teacherData);
            setStudentDrawData(studentData);
            setInitaialState(true);
        };

        const getDrawData = async () => {
            let teacherData = [];
            let studentData = [];
            if (drawData) {
                drawData.forEach((obj) => {
                    if (
                        obj.user_id ===
                        JSON.parse(localStorage.getItem("auth")).user._id
                    ) {
                        studentData.push(obj.draw_data);
                    } else if (teacherId && obj.user_id === teacherId) {
                        teacherData.push(obj.draw_data);
                    }
                });
            }

            setTeacherDrawData(teacherData);
            setStudentDrawData(studentData);
            setInitaialState(true);
        };
        getSelectedMedia();
        if (
            team &&
            currentPanel.lesson_settings.whiteboard.student.shared_use
        ) {
            getTeamDrawData();
        } else {
            getDrawData();
        }
        // eslint-disable-next-line
    }, [drawData]);

    if (currentUserRole === "student") {
        var teacherParticipant = participants.find((obj) => {
            var identityObj = JSON.parse(obj.identity);
            return identityObj.role === "teacher";
        });

        if (teacherParticipant) {
            Teacher = (
                <TwilioParticipant
                    role="teacher"
                    key={teacherParticipant.sid}
                    participant={teacherParticipant}
                />
            );
        }
    }

    return (
        <div className={styles.assets}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <Row className={styles.whiteboardTop}>
                {initialState && (
                    <div className={styles.wbContainerTop}>
                        <div style={{ width: "1239px", height: "322px" }}>
                            <Whiteboard
                                myWhiteboard
                                drawData={teacherDrawData}
                                socket={socket}
                                // streams={streams}
                                // role={role}
                                participants={participants}
                                selectedMedia={selectedMedia}
                                // myMarkerColor={myMarkerColor}
                                key={key}
                                position={
                                    currentPanel.lesson_settings.whiteboard
                                        .position
                                }
                                fitToScreen={fitToScreen}
                                setFitToScreen={setFitToScreen}
                                studentAccess={
                                    currentPanel.lesson_settings.whiteboard
                                        .student_access
                                }
                                control={false}
                                // requiredWB={true}
                                setPosition={setPosition}
                            />
                        </div>
                    </div>
                )}
            </Row>
            <Row className={styles.whiteboardBottom}>
                {initialState && (
                    <div className={styles.wbContainerBottom}>
                        <div style={{ width: "1239px", height: "322px" }}>
                            <Whiteboard
                                drawData={studentDrawData}
                                socket={socket}
                                // streams={streams}
                                // role={role}
                                participants={participants}
                                selectedMedia={selectedMedia}
                                // myMarkerColor={myMarkerColor}
                                key={key}
                                position={
                                    currentPanel.lesson_settings.whiteboard
                                        .position
                                }
                                fitToScreen={fitToScreen}
                                setFitToScreen={setFitToScreen}
                                studentAccess={
                                    currentPanel.lesson_settings.whiteboard
                                        .student_access
                                }
                                team={team}
                                control={false}
                                requiredWB={true}
                                orientation="bottom"
                                panelID={currentPanel._id}
                                setPosition={setPosition}
                            />
                        </div>
                    </div>
                )}
            </Row>
            <div className={`${styles.block} ${styles.blockMedia}`}>
                <Participant>{Teacher}</Participant>
            </div>
        </div>
    );
};
