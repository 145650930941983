import React from 'react'
import styles from "./layout.module.css";

export default function Layout1() {
    return (
        <>

            <div className="row"
                style={{
                    justifyContent: "center",
                    marginTop: "2%",
                    height: "32%",
                }}
            >
                <div className={`${styles.videoContainer}`}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={`${styles.videoContainer}`}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={`${styles.videoContainer}`}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
            </div>
            <div className="row" style={{
                justifyContent: "center",
                // marginTop: "4%"
            }}>
                <div className={`${styles.videoContainer}`}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={`${styles.videoContainer}`}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={`${styles.videoContainer}`}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
            </div>
            <div className="row" style={{
                justifyContent: "center",
                // marginTop: "4%"
            }}>
                <div className={`${styles.videoContainer}`}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={`${styles.videoContainer}`}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={`${styles.videoContainer}`} style={{backgroundColor: "#E9F3FB"}}>
                    <h2 className={styles.containerText}>T</h2>
                </div>
            </div>
        </ >
    )
}
