import React from "react";
import styles from "./index.module.scss";
import teacherMsg from "../../../../assets/images/PlayPage/Teacher.svg";
import enquiry from "../../../../assets/images/NewDesign/enquiry.svg";

export default function TeacherMsg() {
    return (
        <div className={styles.container}>
            <div className="teacher-blog">
                <div className="img-blog">
                    <img src={teacherMsg} alt="msg" />
                </div>
                <div className="content-blog">
                    <div className={`content-blog-inner ${styles.msgBlock}`}>
                        <h3>Host play sessions with us</h3>
                        <p>
                            All the tools you need to create, list, market and
                            host your classes are available on Plassroom.
                        </p>
                        <p>
                            These tools have been designed by educators and
                            parents, focusing on engagement of children. You
                            decide what and when to teach flexibly, on the topic
                            of your interest.{" "}
                        </p>
                        <img
                            src={enquiry}
                            onClick={() =>
                                (window.location.href =
                                    "mailto:enquiry@plassroom.com")
                            }
                            alt="enquiry"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
