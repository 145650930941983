import React from "react";
import styles from "./CommonButton.module.scss";
import { Button } from "@material-ui/core";
const CommonButton = ({ buttonText, classes, handleClick, disable }) => {
    const onHandleClick = (e) => {
        handleClick ? handleClick() : e.preventDefault();
    };
    return (
        <button
            className={`${styles.originalButton} ${classes} ${
                !!disable ? styles.disablebutton : ""
            }`}
            onClick={(e) => onHandleClick(e)}
            disabled={!!disable}
        >
            {buttonText}
        </button>
    );
};

export default CommonButton;
