import React from "react";
import { Modal } from "react-bootstrap";
import styles from "./modal.module.css";
export default (props) => {
    return (
        <>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={props.show}
                onHide={props.handleClose}
            >
                <div id={styles.bar}></div>
                <Modal.Body className={styles.modalBody}>
                    {props.body}
                </Modal.Body>
                <div className={styles.body_div}>
                    <button
                        style={{ marginRight: "15px" }}
                        className={styles.btn}
                        onClick={props.confirm}
                    >
                        Yes
                    </button>
                    <button className={styles.btn} onClick={props.handleClose}>
                        No
                    </button>
                </div>
            </Modal>
        </>
    );
};
