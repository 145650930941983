import React, { useState, useEffect } from "react";
import Dropdown from "../Dropdown";
import "react-calendar/dist/Calendar.css";

export default ({
    customDropdownStyle,
    width,
    margin,
    data,
    setData,
    dropdownData,
    isRegistration,
    isPublished,
    type,
    sessionID,
}) => {
    const [showDropdown, setShowdropdown] = useState(false);
    const [display, setDisplay] = useState(undefined);
    const handleClick = (event, label, value) => {
        event.preventDefault();

        setData(value);
        setDisplay(label);
        setShowdropdown(!showDropdown);
    };
    useEffect(() => {
        if (dropdownData && !isRegistration) {
            const selectedItem = dropdownData.find((el) => {
                return el.value === data;
            });

            if (selectedItem) {
                setDisplay(selectedItem?.label);
            } else {
                setDisplay(data);
            }
        }

        if (isRegistration && dropdownData.length > 0) {
            setDisplay(dropdownData[0].label);
        }

        if ((type === "currency" || type === "plan") && data) {
            setDisplay(data);
        }
        // eslint-disable-next-line
    }, [data, dropdownData, isRegistration]);

    const styleOfContainer = {
        width: "100%",
        backgroundColor: "#C8E8E5",
        padding: "20px 10px",
        textAlign: "left",
        borderRadius: "20px",
        fontFamily: "RobotoMedium",
        ...customDropdownStyle,
    };

    return (
        <div
            style={{
                minWidth: `${width}`,
                margin: `${margin}`,
            }}
        >
            <Dropdown
                displayValue={display}
                show={showDropdown}
                handleToggleShow={() =>
                    isRegistration || isPublished
                        ? ""
                        : setShowdropdown(!showDropdown)
                }
                setShowdropdown={setShowdropdown}
                isPublished={isPublished}
            >
                <div style={styleOfContainer}>
                    {dropdownData?.map((object, index) => {
                        return (
                            <p
                                key={index + 1}
                                style={{
                                    cursor: "pointer",
                                    margin: "0px",
                                    marginBottom: "5px",
                                    color: "#707070",
                                }}
                                onClick={(event) =>
                                    handleClick(
                                        event,
                                        object.label,
                                        object.value
                                    )
                                }
                            >
                                {object.label}
                            </p>
                        );
                    })}
                </div>
            </Dropdown>
        </div>
    );
};
