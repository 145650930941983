import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import EndCall from "../../assets/images/end-call.svg";
import Mute from "../../assets/images/mute.svg";
import Muted from "../../assets/images/muted.svg";
import styles from "./twilioParticipant.module.css";

const Participant = ({
    participant,
    role,
    isMutedUser,
    muteUser,
    unMuteUser,
    publishAudio,
    endUserSession,
    screen,
    studentName,
    hideVideo,
    index,
    focusStudent,
    selectedStudentToFocus,
}) => {
    const [videoTracks, setVideoTracks] = useState([]);
    const [audioTracks, setAudioTracks] = useState([]);
    const [currentUserMuted, setCurrentUserMuted] = useState(isMutedUser);
    const [showControls, setShowControls] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [screenId, setScreenId] = useState("");

    const videoRef = useRef();
    const audioRef = useRef();

    const trackpubsToTracks = (trackMap) =>
        Array.from(trackMap.values())
            .map((publication) => publication.track)
            .filter((track) => track !== null);

    useEffect(() => {
        // console.log("isMutedUser-->>>",role, isMutedUser)
        // console.log("publishAudio-->>>",role, publishAudio)
        // eslint-disable-next-line
    }, [isMutedUser, publishAudio]);
    useEffect(() => {
        setVideoTracks(trackpubsToTracks(participant.videoTracks));
        setAudioTracks(trackpubsToTracks(participant.audioTracks));

        const trackSubscribed = (track) => {
            if (track.kind === "video") {
                setScreenId(track.sid);
                setVideoTracks((videoTracks) => [track, ...videoTracks]);
            } else if (track.kind === "audio") {
                setAudioTracks((audioTracks) => [track, ...audioTracks]);
            }
        };

        const trackUnsubscribed = (track) => {
            if (track.kind === "video") {
                setVideoTracks((prevTrack) => {
                    const newTrackArr = prevTrack.filter((prevTrack) => {
                        return prevTrack.sid !== track.sid;
                    });
                    return newTrackArr;
                });
            } else if (track.kind === "audio") {
                setAudioTracks((prevTrack) => {
                    // const newTrackArr = prevTrack.slice(1);
                    // return newTrackArr;
                    const newTrackArr = prevTrack.filter((prevTrack) => {
                        return prevTrack.sid !== track.sid;
                    });
                    return newTrackArr;
                });
            }
        };

        participant.on("trackSubscribed", trackSubscribed);
        participant.on("trackUnsubscribed", trackUnsubscribed);

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.removeAllListeners();
        };
        // eslint-disable-next-line
    }, [
        participant,
        Array.from(participant.videoTracks.values()).length,
        isMutedUser,
    ]);
    useEffect(() => {
        let screenShared, teacherCamera;
        if (role === "teacherCamera" || role === "teacherScreen") {
            setTimeout(() => {
                screenShared = videoTracks.filter(
                    (track) => track.sid === screenId
                );
                teacherCamera = videoTracks.filter(
                    (track) => track.sid !== screenId
                );
                // if (videoTracks[0]?.dimensions?.width > videoTracks[1]?.dimensions?.width) {
                //     screenShared = videoTracks[0]
                //     teacherCamera = videoTracks[1]
                //     console.log("teacherCamera", teacherCamera?.sid)
                // } else {
                //     screenShared = videoTracks[1]
                //     teacherCamera = videoTracks[0]
                // }

                if (role === "teacherCamera") {
                    // console.log("teacherCamera", teacherCamera)
                    const videoTrack = teacherCamera[0];
                    if (videoTrack) {
                        videoTrack.attach(videoRef.current);
                        return () => {
                            videoTrack.detach();
                        };
                    }
                } else if (role === "teacherScreen") {
                    // console.log("teacherScreen", screenShared)
                    const videoTrack = screenShared[0];
                    if (videoTrack) {
                        videoTrack.attach(videoRef.current);
                        return () => {
                            videoTrack.detach();
                        };
                    }
                }
            }, 3000);
        } else if (role === "teacher" || role === "student") {
            // console.log("----Role-->",role,  videoTracks)
            const videoTrack = videoTracks[0];
            if (videoTrack) {
                videoTrack.attach(videoRef.current);
                return () => {
                    videoTrack.detach();
                };
            }
        } else if (role === "teacherSideScreen") {
            // console.log("---->",role, videoTracks)
            const videoTrack = videoTracks[videoTracks.length - 1];
            if (videoTrack) {
                videoTrack.attach(videoRef.current);
                return () => {
                    videoTrack.detach();
                };
            }
        }
        // eslint-disable-next-line
    }, [videoTracks, selectedStudentToFocus]);

    useEffect(() => {
        // console.log("audio", publishAudio)
        // console.log("------->>>>>>>>>", audioTracks)
        // console.log("curntMuted", currentUserMuted)
        // console.log("isMutedUser-->>>",role, isMutedUser)

        const audioTrack = audioTracks[0];
        if (audioTrack) {
            audioTrack.attach(audioRef.current);
            return () => {
                audioTrack.detach();
            };
        }
        // eslint-disable-next-line
    }, [audioTracks, isMutedUser]);

    const handleShowModal = () => {
        setShowModal(!showModal);
    };
    return !hideVideo ? (
        <div
            className="participant"
            onMouseEnter={() => {
                setShowControls(true);
            }}
            onMouseLeave={() => {
                setShowControls(false);
            }}
            style={{ position: "relative" }}
        >
            <video
                className={styles.videoSection}
                ref={videoRef}
                autoPlay={true}
                // onClick={
                //     focusStudent
                //         ? () => {
                //             focusStudent();
                //         }
                //         : null
                // }
            />
            <audio ref={audioRef} autoPlay={true} />

            {screen === "teacher" && isMutedUser && (
                <img
                    src={Muted}
                    onClick={() => {}}
                    className={styles.videoImg}
                    style={{
                        display: showControls ? "none" : "block",
                    }}
                    alt=""
                />
            )}
            {screen === "teacher" && (
                <div
                    id="controls"
                    className={styles.controlSection}
                    style={{
                        height: showControls ? "4rem" : 0,
                        position: "absolute",
                        bottom: "0px",
                        width: "100%",
                    }}
                >
                    <div className={styles.controlDiv}>
                        <div className={styles.studentName}>{studentName}</div>

                        <div className={styles.controlIcons}>
                            <img
                                className={styles.controlImgLeft}
                                src={EndCall}
                                onClick={handleShowModal}
                                alt="controls"
                            />
                            <img
                                className={styles.controlImgRight}
                                src={isMutedUser ? Muted : Mute}
                                onClick={() => {
                                    if (currentUserMuted) {
                                        setCurrentUserMuted(false);
                                        unMuteUser();
                                    } else {
                                        setCurrentUserMuted(true);
                                        muteUser();
                                    }
                                }}
                                alt="controls"
                            />
                        </div>
                    </div>

                    <Modal
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={showModal}
                        onHide={handleShowModal}
                        contentClassName={styles.modalContent}
                    >
                        <FontAwesomeIcon
                            className={styles.crossIcon}
                            style={{ width: "25px" }}
                            icon={faTimes}
                        />
                        <Modal.Body className={styles.modalBody}>
                            Are you sure you would like to end this session for{" "}
                            {studentName}?
                        </Modal.Body>
                        <div className={styles.button_div}>
                            <button
                                className={styles.end_call_btn}
                                onClick={() => {
                                    endUserSession();
                                    handleShowModal();
                                }}
                            >
                                End Call
                            </button>
                        </div>
                    </Modal>
                </div>
            )}
            {screen === "student" && !publishAudio ? (
                <img src={Muted} className={styles.mutedImg} alt="" />
            ) : null}
        </div>
    ) : (
        <audio ref={audioRef} autoPlay={true} />
    );
};

export default Participant;
