import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStarHalf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import Photo1 from "../../../../assets/images/DefaultProfilePhoto.svg";
import styles from "./index.module.scss";
import { Card } from "react-bootstrap";
import CarouselSmall from "../../../../components/Carousels/CarouselSmall";

const LinearTeachersList = ({ teacherList }) => {
    const history = useHistory();

    const ratingStar = (rating) => {
        const ratingArr = [];

        if (rating) {
            for (let i = 0; i < rating; i++) {
                if (rating % 1 !== 0 && _.round(rating) === i) {
                    ratingArr.push(
                        <FontAwesomeIcon
                            key={i * 10}
                            icon={faStarHalf}
                            className={styles.starIcon}
                        />
                    );
                } else {
                    ratingArr.push(
                        <FontAwesomeIcon
                            key={i}
                            icon={faStar}
                            className={styles.starIcon}
                        />
                    );
                }
            }
        }
        return ratingArr;
    };

    const handelRoute = (singleTeacher) => {
        history.push(`/teacher-details/${singleTeacher.username}`);
    };

    return (
        <div className={styles.linearTeacherListContainer}>
            {teacherList.map((teacher, caroIdx) => {
                return (
                    <div className={styles.cardRow} key={caroIdx}>
                        <Card className={styles.classcard}>
                            <Card.Body className={styles.cardBody}>
                                {/* <Card.Img variant="top" src={teacher?.image_ids[0]?.location} /> */}
                                <div className={styles.classImage}>
                                    <CarouselSmall
                                        carouselImgs={_.get(
                                            teacher,
                                            "profilePictureIds",
                                            []
                                        )}
                                        onClickEvent={() =>
                                            handelRoute(teacher)
                                        }
                                        specialClass="br19"
                                        playOnHover={true}
                                        smallCarousel={true}
                                    />
                                </div>
                                <div className={styles.bottomDiv}>
                                    <p className={styles.teacherName}>
                                        {teacher?.preferedName}
                                    </p>
                                    <p className={styles.countryName}>{`${
                                        teacher?.state
                                            ? `${teacher?.state},`
                                            : ""
                                    } ${
                                        teacher?.country
                                            ? `${teacher?.country}`
                                            : ""
                                    }`}</p>
                                    <div className={styles.classRating}>
                                        {ratingStar(
                                            _.get(teacher, "teacherRate", 0)
                                        )}
                                    </div>
                                    <div className={styles.numberOfStudents}>
                                        {_.get(teacher, "sessionsLength", "-")}{" "}
                                        classes held on Plassroom
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    /* <div className={styles.teacherImage}>
                            <FetchDisplayPhoto
                                profilePictureIds={_.get(
                                    singleTeacher,
                                    "profilePictureIds",
                                    []
                                )}
                                singleTeacher={singleTeacher}
                            />
                        </div>

                        <Link to={`/teacher-details/${singleTeacher.username}`}>
                            <div className={styles.infoContainer}>
                                <div className={styles.teacherNameContainer}>
                                    <div className={styles.teacherName}>
                                        {_.get(
                                            singleTeacher,
                                            "preferedName",
                                            "-"
                                        )}
                                    </div>

                                    <div className={styles.teacherRating}>
                                        {ratingStar(
                                            _.get(
                                                singleTeacher,
                                                "teacherRate",
                                                0
                                            )
                                        )}
                                    </div>
                                </div>

                                <div className={styles.numberOfStudents}>
                                    {_.get(
                                        singleTeacher,
                                        "sessionsLength",
                                        "-"
                                    )}{" "}
                                    classes held on Plassroom
                                </div>
                            </div>
                        </Link> */
                    // </div>
                );
            })}
        </div>
    );
};

export default LinearTeachersList;
