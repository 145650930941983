import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./layout3.module.css";
import TwilioParticipant from "../../../../../../components/TwilioParticipant";
import Participant from "../../../../../../components/Participant";
import axios from "axios";
import Snackbar from "../../../../../../components/Snackbar";
import _ from "lodash";
import StudentSpotlight from "../../../../../../components/StudentSpotlight";

export default ({
    participants,
    room,
    mutedUsers,
    publishAudio,
    endUserSession,
    muteUser,
    unMuteUser,
}) => {
    var Teacher = null;
    var Students = [];
    const rowRef = useRef();

    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;
    const [studentsData, setStudentsData] = useState([]);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [selectedStudentToFocus, setSelectedStudentToFocus] = useState(-1);

    const userIds = participants.map((participant) => {
        var identityObj = JSON.parse(participant.identity);
        return identityObj._id;
    });

    useEffect(() => {
        const getUserDetails = async () => {
            await axios
                .post(`/api/v1/parents`, {
                    data: userIds,
                })
                .then((studentsResult) => {
                    const users = studentsResult.data.data.users;
                    let filteredUsers = [];
                    users.map((user) => {
                        const tempData = {};
                        tempData.name = user.students[0].studentpreferedname;
                        tempData._id = user.students[0]._id;
                        tempData.parentId = user._id;

                        filteredUsers.push(tempData);
                        return 0;
                    });
                    setStudentsData(filteredUsers);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        if (currentUserRole === "teacher") getUserDetails();
        // eslint-disable-next-line
    }, [participants.length]);

    if (currentUserRole === "teacher") {
        Teacher = (
            <TwilioParticipant
                key={room.localParticipant.sid}
                participant={room.localParticipant}
                role={"teacher"}
                focusStudent={() => {}}
                selectedStudentToFocus={selectedStudentToFocus}
            />
        );

        Students = participants.map((participant, index) => {
            var identityObj = JSON.parse(participant.identity);
            const studentName = studentsData.find(
                (student) => student.parentId === identityObj._id
            );
            return (
                <TwilioParticipant
                    isMutedUser={mutedUsers.indexOf(identityObj._id) !== -1}
                    key={participant.sid}
                    role={"student"}
                    participant={participant}
                    publishAudio={publishAudio}
                    muteUser={() => muteUser(identityObj._id)}
                    unMuteUser={() => unMuteUser(identityObj._id)}
                    endUserSession={() => endUserSession(identityObj._id)}
                    screen="teacher"
                    studentName={studentName?.name}
                    focusStudent={() => {
                        setSelectedStudentToFocus(index);
                    }}
                    selectedStudentToFocus={selectedStudentToFocus}
                />
            );
        });
    } else {
        var teacherParticipant = participants.find((obj) => {
            var identityObj = JSON.parse(obj.identity);
            return identityObj.role === "teacher";
        });

        if (teacherParticipant) {
            Teacher = (
                <TwilioParticipant
                    role="teacher"
                    key={teacherParticipant.sid}
                    participant={teacherParticipant}
                />
            );
        }
        if (room) {
            Students = [
                <TwilioParticipant
                    role="student"
                    key={room.localParticipant.sid}
                    participant={room.localParticipant}
                />,
            ].concat(
                participants
                    .filter((obj) => {
                        var identityObj = JSON.parse(obj.identity);
                        return identityObj.role !== "teacher";
                    })
                    .map((participant) => (
                        <TwilioParticipant
                            role="student"
                            key={participant.sid}
                            participant={participant}
                        />
                    ))
            );
        }
    }
    let noOfParticipants = participants.length;
    let participantBoxes = [];

    for (let i = 0; i < noOfParticipants; i++) {
        participantBoxes.push(
            <Col md="auto" key={i}>
                <div
                    className={styles.block}
                    style={{
                        width: rowRef
                            ? rowRef.current?.offsetWidth / noOfParticipants -
                              30
                            : "",
                    }}
                >
                    <Participant>{Students[i]}</Participant>
                </div>
            </Col>
        );
    }
    return (
        <div className={styles.blockTeacher}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <Participant>{Teacher}</Participant>
            <div style={{ position: "absolute", bottom: "0px", width: "100%" }}>
                <Row style={{ justifyContent: "center" }} ref={rowRef}>
                    {participantBoxes}
                </Row>
            </div>

            <StudentSpotlight
                selectedStudentToFocus={selectedStudentToFocus}
                Participants={Students}
                closeModal={() => {
                    setSelectedStudentToFocus(-1);
                }}
            />
        </div>
    );
};
