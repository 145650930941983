import React, { useEffect, useState } from "react";
import styles from "./Layout1.module.scss";
import TwilioParticipant from "../../../../../../components/TwilioParticipant";
import Participant from "../../../../../../components/Participant";
import Game from "../../Game/index";

export default ({
    participants,
    room,
    mutedUsers,
    publishAudio,
    socket,
    role,
    sessionID,
    status,
    currentPanel,
    session,
    updateSession,
    setSession,
    identifier,
}) => {
    var Teacher = null;
    var Students = [];
    const [blockType, setBlockType] = useState(
        currentPanel.activity_settings.block_type
            ? currentPanel.activity_settings.block_type
            : 5
    );
    const [blockTypeChanged, setBlockTypeChanged] = useState(false);

    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;

    if (currentUserRole === "student") {
        var teacherParticipant = participants.find((obj) => {
            var identityObj = JSON.parse(obj.identity);
            return identityObj.role === "teacher";
        });
        if (teacherParticipant) {
            Teacher = (
                <TwilioParticipant
                    role="teacher"
                    key={teacherParticipant.sid}
                    participant={teacherParticipant}
                />
            );
        }
        if (room) {
            Students = [
                <TwilioParticipant
                    role="student"
                    key={room.localParticipant.sid}
                    participant={room.localParticipant}
                    publishAudio={publishAudio}
                    screen="student"
                    hideVideo={true}
                />,
            ].concat(
                participants
                    .filter((obj) => {
                        var identityObj = JSON.parse(obj.identity);
                        return identityObj.role !== "teacher";
                    })
                    .map((participant) => (
                        <TwilioParticipant
                            role="student"
                            key={participant.sid}
                            participant={participant}
                            publishAudio={publishAudio}
                            screen="student"
                            hideVideo={true}
                        />
                    ))
            );
        }
    }

    let noOfParticipants = participants.length;
    let participantBoxes = [];

    for (let i = 0; i < noOfParticipants; i++) {
        participantBoxes.push(Students[i]);
    }

    const handleBlockType = (data) => {
        setBlockType(data);
        setBlockTypeChanged(true);
        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === currentPanel._id) {
                                    obj.activity_settings.block_type = data;
                                    obj.activity_settings.game2_status.splice(
                                        0,
                                        obj.activity_settings.game2_status
                                            .length
                                    );
                                }
                                return obj;
                            }
                        ),
                },
            },
        };
        setSession(newSession);
    };

    useEffect(() => {
        socket.on("blockTypeChange", handleBlockType);

        return () => {
            socket.off("blockTypeChange", handleBlockType);
        };
        // eslint-disable-next-line
    }, []);

    let selectedUserId = JSON.parse(localStorage.getItem("auth")).user._id;
    const userName = identifier;

    return (
        <div className={styles.assets}>
            <Game
                sessionID={sessionID}
                socket={socket}
                participants={participants}
                room={room}
                key={participants.length}
                status={status}
                currentPanel={currentPanel}
                blockType={blockType}
                session={session}
                updateSession={updateSession}
                blockTypeChanged={blockTypeChanged}
                selectedUserId={selectedUserId}
                setSession={setSession}
                identifier={identifier}
                userName={userName}
            />
            <div className={`${styles.block} ${styles.blockMedia}`}>
                <Participant>{Teacher}</Participant>
                {participantBoxes}
            </div>
        </div>
    );
};
