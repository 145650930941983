import React, { useEffect, useState } from "react";
import styles from "./preview.module.scss";
import Layout1 from "./Teacher/Layout1";
import Layout2 from "./Teacher/Layout2";
import Layout3 from "./Teacher/Layout3";
import Layout4 from "./Teacher/Layout4";
import { useHistory } from "react-router-dom";
import LayoutS1 from "./Student/LayoutS1";
import LayoutS2 from "./Student/LayoutS2";
import LayoutS3 from "./Student/LayoutS3";
import LayoutS4 from "./Student/LayoutS4";

const PresentationPreview = ({ currentPanel, handlePreview, studentView }) => {
    const [imageSrc, setImageSrc] = useState("");
    let teacherView;
    const history = useHistory();
    useEffect(() => {
        // console.log(currentPanel?.lesson_settings);
        // eslint-disable-next-line
    }, [currentPanel]);
    teacherView = currentPanel?.lesson_settings?.assets?.view?.teacher;
    const studentV = currentPanel?.lesson_settings?.assets?.view?.student;

    const exitPreview = () => {
        history.goBack();
    };

    const setImage = (src) => {
        setImageSrc(src);
    };

    return teacherView ? (
        <div>
            {/* <div className={styles.closeButton} onClick={() => exitPreview()}>
                Close
            </div> */}
            <div className={styles.previewContainer}>
                {!studentView ? (
                    teacherView === "viewT1" ? (
                        <Layout1
                            currentPanel={currentPanel}
                            imageSrc={setImage}
                        />
                    ) : teacherView === "viewT2" ? (
                        <Layout2
                            currentPanel={currentPanel}
                            imageSrc={setImage}
                        />
                    ) : teacherView === "viewT3" ? (
                        <Layout3
                            currentPanel={currentPanel}
                            imageSrc={setImage}
                        />
                    ) : teacherView === "viewT4" ? (
                        <Layout4
                            currentPanel={currentPanel}
                            imageSrc={setImage}
                        />
                    ) : null
                ) : null}
                {studentView ? (
                    studentV === "view1" ? (
                        <LayoutS1
                            currentPanel={currentPanel}
                            imageSrc={imageSrc}
                        />
                    ) : studentV === "view2" ? (
                        <LayoutS2
                            currentPanel={currentPanel}
                            imageSrc={imageSrc}
                        />
                    ) : studentV === "view3" ? (
                        <LayoutS3
                            currentPanel={currentPanel}
                            imageSrc={imageSrc}
                        />
                    ) : studentV === "view4" ? (
                        <LayoutS4
                            currentPanel={currentPanel}
                            imageSrc={imageSrc}
                        />
                    ) : null
                ) : null}
            </div>
        </div>
    ) : null;
};

export default PresentationPreview;
