import React, { useState, useEffect } from "react";
import Calender from "../Calendar";
import Dropdown from "../Dropdown";
import Duration from "../Durationpicker";
import styles from "./DateAndTimeInput.module.css";
import Remove from "../../assets/images/remove@2x.png";
import Add from "../../assets/images/Add@2x.png";
import moment from "moment";
import axios from "axios";
import { useParams } from "react-router-dom";

const DateAndTimeInput = ({
    width,
    isPublished,
    isClassFree,
    currentDateValue,
    startDate,
    publish,
    handleChange,
    startTime,
    setStartTime,
    classSlots,
    addClassSlot,
    removeClassSlot,
    classSlotsLength,
    sessionType,
}) => {
    const [showDropDown, setShowDropDown] = useState(false);
    // const [totalTimeInputs, setTotalTimeInputs] = useState([
    //     { date: startDate, time: startTime }, { date: startDate, time: startTime }, { date: startDate, time: startTime }
    // ]);
    const [totalTimeInputs, setTotalTimeInputs] = useState([
        { date: null, time: null },
    ]);
    const [dateToAdd, setDateToAdd] = useState([]);
    const [slotLength, setSlotLength] = useState();
    const isSuperAdmin = JSON.parse(localStorage.getItem("auth"))?.user
        ?.isSuperAdmin;
    const { id } = useParams();

    const checkLastClass = async (cl) => {
        let classes = cl;
        await axios.get(`/api/v1/class/${id}`).then((result) => {
            if (result.data.success) {
                classes = result?.data?.data?.session?.class_slots;
            }
        });
        if (classes.length === 0) {
            setTotalTimeInputs([{ date: null, time: null }]);
        }
    };

    useEffect(() => {
        setSlotLength(classSlots.length);
        let newTotalTimeInputs = [];
        for (let i = 0; i < classSlotsLength; i++) {
            let classTime = classSlots[i]
                ? moment
                      .utc(classSlots[i]?.configuration?.session_start_time)
                      .local()
                      .format("HH:mm")
                : null;

            newTotalTimeInputs[i] = {
                date: classSlots[i]?.configuration?.session_start_time,
                time: classTime
                    ? {
                          hours: classTime.split(":")[0],
                          minutes: classTime.split(":")[1],
                      }
                    : undefined,
                slotId: classSlots[i]?._id,
            };

            setTotalTimeInputs(newTotalTimeInputs);
        }
        var newDateToAdd = [];
        for (let j = 0; j < classSlots.length; j++) {
            // var newDateToAdd = [...dateToAdd]
            var classTime = classSlots[j]
                ? moment
                      .utc(classSlots[j]?.configuration?.session_start_time)
                      .local()
                      .format("HH:mm")
                : null;
            let addDate = {
                date: moment
                    .utc(classSlots[j]?.configuration?.session_start_time)
                    .local()
                    .format("DD MMM hh:mma"),
                time: {
                    hours: classTime.split(":")[0],
                    minutes: classTime.split(":")[1],
                },
            };
            newDateToAdd.push(addDate);
        }
        setDateToAdd(newDateToAdd);
        // eslint-disable-next-line
    }, [classSlots]);
    const handleToggleDropdown = () => {
        if (!isPublished) setShowDropDown(!showDropDown);
    };

    const handleChangeDate = async (date, index) => {
        let newTimeInputs = [...totalTimeInputs];
        newTimeInputs[index] = {
            date: date,
            time: totalTimeInputs[index].time,
        };
        await setTotalTimeInputs(newTimeInputs);
    };

    const handleChangeTime = (time, index) => {
        let newTimeInputs = [...totalTimeInputs];
        newTimeInputs[index] = {
            date: totalTimeInputs[index].date,
            time: time,
        };
        setTotalTimeInputs(newTimeInputs);
    };

    const handleAddDate = async (timeInput, index) => {
        let newDateToAdd = [...dateToAdd];
        newDateToAdd[index] = {
            date: moment.utc(timeInput.date).local().toDate(),
            time: timeInput.time,
        };
        // await setDateToAdd(newDateToAdd)
        addClassSlot(newDateToAdd[index]);
    };

    const handleRemoveDate = async (timeInput, index, id) => {
        if (!id) {
            if (totalTimeInputs.length > 1) {
                let newTotalTimeInputs = [...totalTimeInputs];
                newTotalTimeInputs.splice(index, 1);
                setTotalTimeInputs(newTotalTimeInputs);
            }
        } else {
            const classes = [];
            removeClassSlot(id);
            if (totalTimeInputs.length === 1) {
                setTimeout(() => {
                    checkLastClass(classes);
                }, 1500);
            }
        }
    };

    const handleSetDate = async (date, timeInput, index) => {
        // await handleChangeDate(date, index);
        let newTimeInputs = [...totalTimeInputs];
        newTimeInputs[index] = {
            date: date,
            time: totalTimeInputs[index].time,
        };
        await setTotalTimeInputs(newTimeInputs);
        newTimeInputs[index]?.date &&
            newTimeInputs[index]?.time &&
            !timeInput?.slotId &&
            handleAddDate(newTimeInputs[index], index);
    };

    const handleSetTime = async (time, timeInput, index) => {
        await handleChangeTime(time, index);
        timeInput?.date &&
            timeInput?.time &&
            !timeInput?.slotId &&
            handleAddDate(timeInput, index);
    };

    return (
        <div
            style={{
                minWidth: `${width}`,
            }}
        >
            <Dropdown
                show={showDropDown}
                handleToggleShow={() => handleToggleDropdown()}
                setShowdropdown={setShowDropDown}
                isPublished={publish}
                displayValue={dateToAdd
                    .map((val) => val?.date && val?.time && ` ${val?.date} `)
                    .join("; ")}
                dateTime={true}
                classSlotsLength={classSlotsLength === 5}
                sessionType={sessionType}
            >
                {totalTimeInputs.map((timeInput, index) => {
                    return (
                        <div className={styles.inputContainer} key={index}>
                            <Calender
                                maxDate={
                                    isClassFree &&
                                    new Date(
                                        currentDateValue.getFullYear(),
                                        currentDateValue.getMonth() + 1,
                                        0
                                    )
                                }
                                value={
                                    timeInput.date
                                        ? moment
                                              .utc(timeInput?.date)
                                              .local()
                                              .toDate()
                                        : null
                                }
                                onChange={(date) =>
                                    handleSetDate(date, timeInput, index)
                                }
                                width="100%"
                                isPublished={timeInput?.slotId}
                                isSuperAdmin={isSuperAdmin}
                                // onChange={handleChange}
                                // onChange={() => {
                                //     timeInput?.date && timeInput?.time && !timeInput?.slotId && handleAddDate(timeInput, index)
                                //     // setTotalTimeInputs([...totalTimeInputs, { date: null, time: null }])
                                // }}
                            />
                            <Duration
                                isTime
                                type="time"
                                data={
                                    timeInput?.time
                                        ? timeInput?.time
                                        : undefined
                                }
                                setData={(time) =>
                                    handleSetTime(time, timeInput, index)
                                }
                                width="100%"
                                date={moment
                                    .utc(timeInput?.date)
                                    .local()
                                    .toDate()}
                                isPublished={timeInput?.slotId}
                            />

                            <span className={styles.plusIcon}>
                                {/* <FontAwesomeIcon icon={faPlus} onClick={() => {
                                    handleAddDate()
                                }} /> */}
                                <img
                                    className={styles.addRemoveIcon}
                                    src={Add}
                                    onClick={() => {
                                        // timeInput?.date && timeInput?.time && !timeInput?.slotId && handleAddDate(timeInput, index)
                                        totalTimeInputs.length < 5 &&
                                            setTotalTimeInputs([
                                                ...totalTimeInputs,
                                                { date: null, time: null },
                                            ]);
                                    }}
                                    alt="add"
                                />
                                <img
                                    className={styles.addRemoveIcon}
                                    src={Remove}
                                    onClick={() => {
                                        handleRemoveDate(
                                            timeInput,
                                            index,
                                            timeInput?.slotId
                                        );
                                    }}
                                    alt="remove"
                                />
                            </span>
                        </div>
                    );
                })}
            </Dropdown>
        </div>
    );
};

export default DateAndTimeInput;
