import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Snackbar from "../../components/Snackbar";
import styles from "./loginnew.module.scss";
import moment from "moment-timezone";

export default () => {
    const history = useHistory();
    const [data, setData] = useState({
        email: "",
        password: "",
    });
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("auth")) {
            if (
                JSON.parse(localStorage.getItem("auth")).user.role === "admin"
            ) {
                history.push("/admin");
            } else {
                history.push("/");
            }
        }
        // eslint-disable-next-line
    }, []);

    const handleOnChange = (evt) => {
        setData({
            ...data,
            [evt.target.name]: evt.target.value,
        });
    };

    const handleKeyPress = (evt) => {
        if (evt.key === "Enter") {
            login(evt);
        }
    };

    const login = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const timezone = moment.tz.guess();
        axios
            .post("/api/v1/user/login", {
                email: data.email,
                password: data.password,
                ...(!!timezone ? { timezone } : {}),
            })
            .then((res) => {
                if (res.data.success) {
                    localStorage.setItem("auth", JSON.stringify(res.data.data));
                    if (history.location.search) {
                        const redirectTo =
                            history.location.search.substring(10);
                        const stateHistory = history.location.state;
                        history.push(redirectTo, {
                            ...stateHistory,
                        });
                    } else if (res.data.data.user.role === "admin") {
                        history.push("/admin");
                    } else if (res.data.data.user.role === "teacher") {
                        history.push("/");
                    } else {
                        history.push("/");
                    }
                } else if (res?.data?.data?.accountStatus === "SUSPENDED") {
                    setMsg(res.data.message);
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                } else {
                    if (res.data.data) {
                        localStorage.setItem("plassroom-email", data.email);
                        window.location.href = "/registration-successful";
                    } else {
                        setMsg(res.data.message);
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    }
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };
    const handleSignup = () => {
        history.push("/signup");
    };

    const handleForgotPassword = () => {
        history.push("/forgotpassword");
    };
    return (
        <div className={styles.mainDiv}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <Navbar />

            <div className={styles.formWrapper}>
                <h2 className={styles.heading}> Login</h2>

                <div className={styles.inputWrapper}>
                    <div>
                        <input
                            className={styles.emailInput}
                            placeholder="Email"
                            type="email"
                            id="email"
                            name="email"
                            value={data.email}
                            onChange={handleOnChange}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <div>
                        <input
                            className={styles.passwordInput}
                            placeholder="Password"
                            type="password"
                            id="pass"
                            name="password"
                            minLength="8"
                            required
                            value={data.password}
                            onChange={handleOnChange}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                </div>
                <p className={styles.continueBtn} onClick={login}>
                    Login
                </p>
                <p className={styles.para} onClick={handleForgotPassword}>
                    Forgot password
                </p>
                <p className={styles.signupText}>
                    Dont have an account?{" "}
                    <span className={styles.signup} onClick={handleSignup}>
                        Signup
                    </span>
                </p>
            </div>
        </div>
    );
};
