import axios from "axios";
import _ from "lodash";
import React, { useState } from "react";
import Logo from "../../assets/images/LOGO (3).svg";
import Stars from "../../assets/images/stars.png";
import Loading from "../../assets/videos/Loading.mp4";
import Snackbar from "../../components/Snackbar";
import Navbar from "../../components/Navbar";
import styles from "./emailConfirmation.module.scss";

export default () => {
    const [resendEmailVerificationCount, setResendEmailVerificationCount] =
        useState(0);
    const [verifyingAPI, setVerifyingAPI] = useState(false);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const handleResendEmail = async () => {
        setVerifyingAPI(true);
        const randomString = window.location.pathname.split("/")[2] || "";
        let userEmail = "";

        if (!randomString) {
            userEmail = localStorage.getItem("plassroom-email");
        }

        await axios
            .post("/api/v1/user/resend/email-verification-link", {
                randStr: "",
                email: userEmail,
            })
            .then((response) => {
                setResendEmailVerificationCount(
                    response.data.data.resendEmailVerificationCount
                );
                setVerifyingAPI(false);
            })
            .catch((error) => {
                setVerifyingAPI(false);
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    return (
        <div className={styles.waiting_screen}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <Navbar />

            <div className={styles.success_content__container}>
                {/* <img src={PlusIcons} alt="" className={styles.plus_icons} /> */}
                <div>
                    <p className={styles.success_heading}>Sign up</p>

                    <p className={styles.verification}>
                        Please Verify your email address by following the
                        instructions sent to your email.
                    </p>

                    <p className={styles.instruction}>
                        <div className={styles.waiting_content__container}>
                            <video className="VideoTag" autoPlay loop muted>
                                <source src={Loading} type="video/mp4" />
                            </video>
                        </div>
                        Keep this page open
                    </p>

                    {resendEmailVerificationCount < 3 ? (
                        <p className={styles.verification}>
                            Didn't receive an email? Check your spam folder or{" "}
                            {verifyingAPI ? (
                                <span>Resending verification email...</span>
                            ) : (
                                <span onClick={() => handleResendEmail()}>
                                    Resend verification email
                                </span>
                            )}
                            .
                        </p>
                    ) : resendEmailVerificationCount > 3 ? (
                        <p className={styles.verification}>
                            Email not sent. You've exceeded maximum number of
                            attempts to resend email.
                        </p>
                    ) : (
                        <p className={styles.verification}>
                            You've exceeded maximum number of attempts to resend
                            email.
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};
