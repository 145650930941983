import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Setting from "../../assets/images/icon/Settings.svg";
import CoinSvg from "../../assets/images/Plasscoin.svg";
import Loading from "../../components/Loading/Loading";
import ChatForm from "../../components/ChatForm";
import Navbar from "../../components/Navbar";
import Snackbar from "../../components/Snackbar";
import { currencySymbol } from "../../utils/constants";
import styles from "./index.module.scss";
import { getCurrencySymbol, dateFormat } from "../../utils/commonFunction";
import ClassInfoBox from "./ClassInfoBox";
import SubscriptionFooter from "../../components/FooterNew/SubscriptionFooter";

const navbarMenus = [
    {
        icon: Setting,
        link: "/accountsettings",
        title: "setting",
    },
];
const ClassRegister = (props) => {
    let history = useHistory();
    const classId = props.match.params.classID;
    const [studentDetails, setStudentDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [isAgeDIfferent, setIsAgeDifferent] = useState(false);
    const [plassCoins, setPlassCoins] = useState(0);

    // VERIFY PAYMENT FUNCTION HERE ( PAY BUTTON ACTION  )
    const verifyPaymentFunction = async (availableCoins, sessionId) => {
        const url = availableCoins
            ? `api/v1/session/${sessionId}/register-with-plass-coins`
            : `/api/v1/payment/checkout/${sessionId}`;
        try {
            const { data } = await axios.get(url);
            if (data.success) {
                availableCoins
                    ? history.push(`/class/payment-success/${classId}`)
                    : window.open(data?.data?.checkoutUrl, "_self");
            } else {
                fetchPlanDetails();
                setMsg(_.get(data, "message", "Something went wrong."));
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                    // history.push(`/class-details/${classId}`);
                    // history.goBack();
                    console.log("failed");
                }, 3000);
            }
        } catch (error) {
            setMsg(
                _.get(error, "response.data.message", "Something went wrong.")
            );
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
                // history.push(`/class-details/${classId}`);
                // history.goBack();
            }, 3000);
        }
    };

    // Register for free class
    const handleFreeClassRegistration = () => {
        axios
            .get(
                `api/v1/session/${studentDetails.session._id}/register-in-free-class`
            )
            .then((res) => {
                if (res.data.success) {
                    history.replace(`/class/payment-success/${classId}`);
                } else {
                    fetchPlanDetails();
                    setMsg(_.get(res, "data.message", "Something went wrong."));
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                        // history.push(`/class-details/${classId}`);
                        history.goBack();
                    }, 3000);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                    // history.push(`/class-details/${classId}`);
                    history.goBack();
                }, 3000);
            });
    };

    // FETCHING STUDENT DETAILS AND UPCOMING CLASSES FUNCTION
    const fetchStudentDetails = async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(
                `/api/v1/session/${classId}/register`
            );

            if (data?.success) {
                setStudentDetails(data?.data);

                const bDate = _.get(
                    data,
                    "data.students[0].studentbirthdate",
                    null
                );
                const ageGrp = _.get(
                    data,
                    "data.session.configuration.age_group",
                    null
                );

                if (bDate && ageGrp) {
                    const bDateDiff = moment().diff(bDate, "years");
                    const ageGrpArr = ageGrp.split("-");

                    setIsAgeDifferent(
                        parseInt(bDateDiff) < parseInt(ageGrpArr[0]) ||
                            parseInt(bDateDiff) > parseInt(ageGrpArr[1])
                    );
                }
                setLoading(false);
            } else {
                setLoading(false);
                setMsg(_.get(data, "message", "Something went wrong."));
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                    // history.push(`/class-details/${classId}`);
                    history.goBack();
                }, 3000);
            }
        } catch (error) {
            setLoading(false);
            setMsg(
                _.get(error, "response.data.title", "Something went wrong.")
            );
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
                // history.push(`/class-details/${classId}`);
                history.goBack();
            }, 3000);
        }
    };

    // FETCHING STUDENT PLANN DETAILS
    const fetchPlanDetails = async () => {
        await axios
            .get("/api/v1/user/plan")
            .then((response) => {
                if (response?.data?.success) {
                    setPlassCoins(response?.data?.data?.totalRemainingMinutes);
                } else {
                    setMsg(
                        _.get(
                            response,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    // FETCHING STUDENT DETAILS AND CHECKING IF STUDENT LOGGED IN OR NOT
    useEffect(() => {
        if (localStorage.getItem("auth")) {
            if (
                JSON.parse(localStorage.getItem("auth")).user.role === "student"
            ) {
                history.replace(`/register/${classId}`);
                fetchStudentDetails();
                fetchPlanDetails();
            } else {
                history.replace(`/signup?redirect=/register/${classId}`);
            }
        } else {
            history.replace(`/signup?redirect=/register/${classId}`);
        }

        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <div className={styles.mainContainer}>
                <Navbar
                    message={"Register your child for class"}
                    menus={navbarMenus}
                    studentName={
                        studentDetails?.studentPreferedName
                            ? studentDetails?.studentPreferedName
                            : studentDetails?.studentName
                    }
                />

                <div className={styles.pageContainer}>
                    <div className={`${styles.contentContainer}`}>
                        {loading ? (
                            <Loading />
                        ) : (
                            <Fragment>
                                <div className={`${styles.classRegister} row`}>
                                    <div className={`${styles.leftSection}`}>
                                        <h3 className={styles.headingText}>
                                            Registration for
                                            {` ${
                                                studentDetails?.studentPreferedName
                                                    ? studentDetails?.studentPreferedName ||
                                                      "-"
                                                    : studentDetails?.studentName ||
                                                      "-"
                                            } `}
                                        </h3>

                                        <div className={styles.centerContainer}>
                                            {!!studentDetails && (
                                                <ClassInfoBox
                                                    studentDetails={
                                                        studentDetails
                                                    }
                                                />
                                            )}

                                            <div
                                                className={styles.btnContainer}
                                            >
                                                <p>
                                                    <span
                                                        className={
                                                            styles.plassDetailsText
                                                        }
                                                    >
                                                        {studentDetails?.session
                                                            ?.configuration
                                                            ?.session_duration >
                                                        plassCoins
                                                            ? `Insufficient
                                                                coins`
                                                            : `Current balance`}{" "}
                                                    </span>
                                                    <img
                                                        src={CoinSvg}
                                                        className={
                                                            styles.plassCoin
                                                        }
                                                    />
                                                    <span
                                                        className={
                                                            styles.plassDetailsText
                                                        }
                                                    >
                                                        {" "}
                                                        {plassCoins}
                                                    </span>
                                                </p>
                                                <div>
                                                    <button
                                                        className={
                                                            styles.registerBTN
                                                        }
                                                        style={
                                                            !studentDetails
                                                                ?.session
                                                                ?.configuration
                                                                ?.fee?.amount &&
                                                            !studentDetails
                                                                ?.session
                                                                ?.is_free_class
                                                                ? {
                                                                      opacity: 0.5,
                                                                      cursor: "not-allowed",
                                                                  }
                                                                : null
                                                        }
                                                        onClick={() =>
                                                            studentDetails
                                                                ?.session
                                                                ?.is_free_class
                                                                ? handleFreeClassRegistration()
                                                                : studentDetails
                                                                      ?.session
                                                                      ?.configuration
                                                                      ?.fee
                                                                      ?.amount
                                                                ? verifyPaymentFunction(
                                                                      studentDetails
                                                                          ?.session
                                                                          ?.configuration
                                                                          ?.session_duration <=
                                                                          plassCoins,
                                                                      studentDetails
                                                                          ?.session
                                                                          ?._id
                                                                  )
                                                                : null
                                                        }
                                                    >
                                                        <h6>
                                                            <span>Pay</span>

                                                            {studentDetails
                                                                ?.session
                                                                ?.configuration
                                                                ?.session_duration <=
                                                                plassCoins &&
                                                            !studentDetails
                                                                ?.session
                                                                ?.is_free_class ? (
                                                                <>
                                                                    <span>
                                                                        <img
                                                                            src={
                                                                                CoinSvg
                                                                            }
                                                                            className={
                                                                                styles.plassCoin
                                                                            }
                                                                        />{" "}
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            marginBottom:
                                                                                "-4px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            studentDetails
                                                                                ?.session
                                                                                ?.configuration
                                                                                ?.session_duration
                                                                        }
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span>
                                                                        {currencySymbol[
                                                                            `${studentDetails?.session?.configuration?.fee?.currency}`
                                                                        ] ||
                                                                            `${getCurrencySymbol(
                                                                                studentDetails
                                                                                    ?.session
                                                                                    ?.configuration
                                                                                    ?.fee
                                                                                    ?.currency
                                                                            )}`}
                                                                    </span>
                                                                    <span>
                                                                        {studentDetails
                                                                            ?.session
                                                                            ?.is_free_class
                                                                            ? 0
                                                                            : `${parseFloat(
                                                                                  studentDetails
                                                                                      ?.session
                                                                                      ?.configuration
                                                                                      ?.fee
                                                                                      ?.amount /
                                                                                      100 ||
                                                                                      0
                                                                              )}`}
                                                                    </span>
                                                                </>
                                                            )}
                                                        </h6>
                                                    </button>
                                                </div>
                                                {studentDetails?.session
                                                    ?.configuration
                                                    ?.session_duration <=
                                                    plassCoins &&
                                                    !studentDetails?.session
                                                        ?.is_free_class && (
                                                        <span
                                                            onClick={() =>
                                                                studentDetails
                                                                    ?.session
                                                                    ?.is_free_class
                                                                    ? handleFreeClassRegistration()
                                                                    : studentDetails
                                                                          ?.session
                                                                          ?.configuration
                                                                          ?.fee
                                                                          ?.amount
                                                                    ? verifyPaymentFunction(
                                                                          false,
                                                                          studentDetails
                                                                              ?.session
                                                                              ?._id
                                                                      )
                                                                    : null
                                                            }
                                                            className={
                                                                styles.btnBottomText
                                                            }
                                                        >
                                                            Pay{" "}
                                                            {getCurrencySymbol(
                                                                studentDetails
                                                                    ?.session
                                                                    ?.configuration
                                                                    ?.fee
                                                                    ?.currency
                                                            )}
                                                            {parseFloat(
                                                                studentDetails
                                                                    ?.session
                                                                    ?.configuration
                                                                    ?.fee
                                                                    ?.amount /
                                                                    100 || 0
                                                            )}
                                                        </span>
                                                    )}
                                                {isAgeDIfferent ? (
                                                    <div
                                                        className={
                                                            styles.ageWarningContainer
                                                        }
                                                    >
                                                        Warning: The student is
                                                        not within the
                                                        recommended age range of
                                                        the class. Teacher may
                                                        remove this
                                                        registration. Please
                                                        contact the teacher as
                                                        soon as possible after
                                                        payment to discuss.
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <h3 className={styles.headingText}>
                                            Your Upcoming Classes
                                        </h3>
                                        {studentDetails?.upcomingSessions
                                            ?.length ? (
                                            <Fragment>
                                                <div
                                                    className={
                                                        styles.paragraphText
                                                    }
                                                >
                                                    <p>
                                                        Do check to ensure there
                                                        are no conflicts in time
                                                        before you register for
                                                        another class.
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        styles.classSchedule
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.classScheduleTableHeading
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                styles.classScheduleTitle
                                                            }
                                                        >
                                                            Date
                                                        </span>
                                                        <span
                                                            className={`${styles.classScheduleTitle} ${styles.flex1Class} ${styles.flex1ClassHeading}`}
                                                        >
                                                            Name of Class
                                                        </span>
                                                        <span
                                                            className={
                                                                styles.classScheduleTitle
                                                            }
                                                        >
                                                            Time ( Duration )
                                                        </span>
                                                    </div>
                                                    {studentDetails?.upcomingSessions?.map(
                                                        (classes, classIdx) => {
                                                            const {
                                                                session_start_time,
                                                                session_duration,
                                                            } =
                                                                classes.configuration;
                                                            const {
                                                                class_name,
                                                            } = classes;
                                                            return (
                                                                <div
                                                                    className={
                                                                        styles.classScheduleTable
                                                                    }
                                                                    key={
                                                                        classIdx
                                                                    }
                                                                >
                                                                    <span
                                                                        className={
                                                                            styles.classScheduleData
                                                                        }
                                                                    >
                                                                        {dateFormat(
                                                                            session_start_time,
                                                                            "DD MMM "
                                                                        )}
                                                                    </span>
                                                                    <span
                                                                        className={`${styles.classScheduleData} ${styles.flex1Class}`}
                                                                    >
                                                                        {
                                                                            class_name
                                                                        }
                                                                    </span>
                                                                    <span
                                                                        className={
                                                                            styles.classScheduleData
                                                                        }
                                                                    >
                                                                        {dateFormat(
                                                                            session_start_time,
                                                                            "h:mm A"
                                                                        )}
                                                                        {` ( ${session_duration} minutes )`}
                                                                    </span>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </Fragment>
                                        ) : (
                                            <div
                                                className={
                                                    styles.noBookedClassContainer
                                                }
                                            >
                                                You have no class booked
                                                currently.
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {studentDetails ? (
                                    <ChatForm
                                        classDetails={studentDetails?.session}
                                    />
                                ) : null}
                            </Fragment>
                        )}
                    </div>
                </div>
                <SubscriptionFooter />
            </div>
        </Fragment>
    );
};

export default ClassRegister;
