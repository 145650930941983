import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import { Row } from "react-bootstrap";
import styles from "./AudioPlayer.module.css";
import { faVolumeUp, faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import Note from "../../../../../../assets/images/note.svg";

export default ({ source, isSelectedAud, top }) => {
    const audioContainer = useRef();
    const audioControls = useRef();
    const playpause = useRef();
    const progress = useRef();
    const volume = useRef();
    const controlsRow = useRef();
    const [playPauseIcon, setPlayPauseIcon] = useState(faPlay);
    const audio = useRef();
    const audioImg = useRef();

    useEffect(() => {
        const audioContainerCopy = audioContainer;
        const playpauseCopy = playpause;
        const progressCopy = progress;
        const volumeCopy = volume;
        const audioCopy = audio;
        if (isSelectedAud) audioControls.current.style.display = "flex";
        else audioControls.current.style.display = "none";
        var handlePlayPause = () => {
            if (audio.current.paused || audio.current.ended) {
                audio.current.play();
                setPlayPauseIcon(faPause);
            } else {
                audio.current.pause();
                setPlayPauseIcon(faPlay);
            }
        };
        var setMaxDuration = () => {
            progress.current.setAttribute("max", audio.current.duration);
        };
        var handleAudioTimeUpdate = () => {
            progress.current.value = audio.current.currentTime;
        };
        var handleVolumeChange = () => {
            audio.current.volume = volume.current.value;
        };
        var handleProgressChange = () => {
            audio.current.currentTime = progress.current.value;
        };
        var handleMouseEnter = () => {
            audioControls.current.style.visibility = "visible";
        };
        var handleMouseLeave = () => {
            audioControls.current.style.visibility = "hidden";
        };
        var handleAudioControl = (data) => {
            if (audio.current) {
                const obj = data.item;
                if (obj.isPaused) {
                    audio.current.pause();
                } else {
                    audio.current.play();
                }
                audio.current.currentTime = obj.progress;
                audio.current.volume = obj.volume;
            }
        };
        playpause.current.addEventListener("click", handlePlayPause);
        audio.current.addEventListener("loadedmetadata", setMaxDuration);
        audio.current.addEventListener("timeupdate", handleAudioTimeUpdate);
        volume.current.addEventListener("change", handleVolumeChange);
        progress.current.addEventListener("change", handleProgressChange);
        audioContainer.current.addEventListener("mouseenter", handleMouseEnter);
        audioContainer.current.addEventListener("mouseleave", handleMouseLeave);
        const handleControlsWidth = () => {
            audioControls.current.style.setProperty(
                "width",
                `${Math.round(0.9 * audioImg.current?.offsetWidth)}px`
            );
        };
        window.addEventListener("resize", handleControlsWidth);
        audio.current.addEventListener("loadeddata", handleControlsWidth);
        return () => {
            window.removeEventListener("resize", handleControlsWidth);
            playpauseCopy.current.removeEventListener("click", handlePlayPause);
            audioCopy.current.removeEventListener(
                "loadedmetadata",
                setMaxDuration
            );
            audioCopy.current.removeEventListener(
                "timeupdate",
                handleAudioTimeUpdate
            );
            volumeCopy.current.removeEventListener(
                "change",
                handleVolumeChange
            );
            progressCopy.current.removeEventListener(
                "change",
                handleProgressChange
            );
            audioContainerCopy.current.removeEventListener(
                "mouseenter",
                handleMouseEnter
            );
            audioContainerCopy.current.removeEventListener(
                "mouseleave",
                handleMouseLeave
            );
            audioCopy.current.removeEventListener(
                "loadeddata",
                handleControlsWidth
            );
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div
                id="audioContainer"
                ref={audioContainer}
                className={styles.audioContainer}
            >
                <div className={styles.assets}>
                    <audio id="myAudio" src={source} ref={audio}></audio>
                    <img className={styles.assets} src={Note} ref={audioImg} />
                </div>
                <div
                    id="audio-controls"
                    className={`${styles.controls}`}
                    style={{ top: top }}
                    ref={audioControls}
                >
                    <Row
                        className={`${styles.rowCentered} ${styles.controlsRow}`}
                        ref={controlsRow}
                    >
                        <span
                            id="playpause"
                            ref={playpause}
                            className={styles.button}
                        >
                            <FontAwesomeIcon
                                icon={playPauseIcon}
                                className={styles.button}
                            />
                        </span>
                        <span className={styles.progressContainer}>
                            <input
                                type="range"
                                min="0"
                                step="0.001"
                                className={`${styles.progressSlider} ${styles.slider}`}
                                id="time-slider"
                                ref={progress}
                            ></input>
                        </span>
                        <span>
                            <FontAwesomeIcon
                                icon={faVolumeUp}
                                className={styles.button}
                            />
                        </span>
                        <span className={styles.volContainer}>
                            <input
                                type="range"
                                min="0"
                                max="1"
                                step="0.001"
                                className={`${styles.volSlider} ${styles.slider}`}
                                id="volume-slider"
                                ref={volume}
                            ></input>
                        </span>
                    </Row>
                </div>
            </div>
        </>
    );
};
