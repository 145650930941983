import React from "react";
import { Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";

import styles from "./game2InitModal.module.scss";

export default ({
    showModal,
    handleGame2Start,
    blockType,
    handleBlockType,
}) => {
    return (
        <Modal
            contentClassName={styles.modal}
            backdropClassName={styles.modalBackdrop}
            show={showModal}
            onHide={() => {}}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Body>
                <Row>
                    <div className={styles.modalSubBlock}>
                        <Row>
                            <Col md={6}></Col>
                            <Col md={6}>
                                <div className={styles.modalTextBigger}>
                                    <div>Easy</div>
                                    <div>Normal</div>
                                    <div>Hard</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className={styles.buttonLeftText}>
                                <p className={styles.textConfig}>Block type</p>
                            </Col>
                            <Col md={6}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        className={`${styles.btnCircle} ${
                                            blockType === 5
                                                ? styles.selected
                                                : ""
                                        }`}
                                        onClick={() => handleBlockType(5)}
                                    >
                                        5
                                    </div>
                                    <div
                                        className={`${styles.btnCircle} ${
                                            blockType === 8
                                                ? styles.selected
                                                : ""
                                        }`}
                                        onClick={() => handleBlockType(8)}
                                    >
                                        8
                                    </div>
                                    <div
                                        className={`${styles.btnCircle} ${
                                            blockType === 10
                                                ? styles.selected
                                                : ""
                                        }`}
                                        onClick={() => handleBlockType(10)}
                                    >
                                        10
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className={styles.divRow}>
                            <Col
                                xs={6}
                                className={`${styles.modalTextBigger} ${styles.buttonLeftText}`}
                            >
                                Teacher Demo
                            </Col>
                            <Col xs={4}>
                                <div
                                    className={styles.button}
                                    onClick={(event) => {}}
                                >
                                    <div
                                        className={
                                            true
                                                ? `${styles.circleLeft}`
                                                : `${styles.circleRight}`
                                        }
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Row>
                <Row className={styles.startButtonSection}>
                    <h1
                        className={styles.startButton}
                        onClick={() => {
                            handleGame2Start();
                        }}
                    >
                        Start
                    </h1>
                </Row>
            </Modal.Body>
        </Modal>
    );
};
