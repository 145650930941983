import {
    faSearch,
    faSlidersH,
    faChevronLeft,
    faChevronDown,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";

const SearchFilter = ({
    setFilterKey,
    searchedTerm,
    setSearchedTerm,
    setSortKey,
    isFrom,
}) => {
    const filterOptions = [
        {
            title: "Age",
            key: "age",
        },
        {
            title: "Class Topic",
            key: "classTopic",
        },
        {
            title: "Class Size",
            key: "classSize",
        },
        {
            title: "Time",
            key: "time",
        },
        {
            title: "Free class",
            key: "free",
        },
    ];

    const sortOptionsForClasses = [
        {
            title: "Price",
            key: "price",
        },
        {
            title: "Teacher rating",
            key: "teacher_rating",
        },
        {
            title: "Title A-Z",
            key: "class_name",
        },
        {
            title: "Students registered",
            key: "students_register",
        },
    ];

    const sortOptionsForTeachers = [
        {
            title: "Name A-Z",
            key: "name",
        },
        {
            title: "Teacher rating",
            key: "teacher_rating",
        },
        {
            title: "No. of classes held",
            key: "no_of_classes",
        },
    ];

    const sortOptions =
        isFrom === "newClasses"
            ? sortOptionsForClasses
            : sortOptionsForTeachers;

    const ageOptions = [
        {
            title: "0-1 years",
            key: "0-1",
        },
        {
            title: "1-2 years",
            key: "1-2",
        },
        {
            title: "2-3 years",
            key: "2-3",
        },
        {
            title: "3-4 years",
            key: "3-4",
        },
        {
            title: "4-5 years",
            key: "4-5",
        },
        {
            title: "5-6 years",
            key: "5-6",
        },
        {
            title: "6-7 years",
            key: "6-7",
        },
        {
            title: "7-8 years",
            key: "7-8",
        },
        {
            title: "8-9 years",
            key: "8-9",
        },
        {
            title: "9-10 years",
            key: "9-10",
        },
        {
            title: "10-11 years",
            key: "10-11",
        },
        {
            title: "11-12 years",
            key: "11-12",
        },
        {
            title: "12-13 years",
            key: "12-13",
        },
        {
            title: "13-14 years",
            key: "13-14",
        },
        {
            title: "14-15 years",
            key: "14-15",
        },
        {
            title: "15-16 years",
            key: "15-16",
        },
    ];

    const classTopicOptions = [
        {
            title: "Languages",
            key: "Languages",
        },
        {
            title: "Music",
            key: "Music",
        },
        {
            title: "Arts and Craft",
            key: "Arts and Craft",
        },
        {
            title: "Social/Life Skills",
            key: "Social/Life Skills",
        },
        {
            title: "Tech",
            key: "Tech",
        },
        {
            title: "English",
            key: "English",
        },
        {
            title: "Science",
            key: "Science",
        },
        {
            title: "Travel/Geography",
            key: "Travel/Geography",
        },
        {
            title: "History",
            key: "History",
        },
        {
            title: "Math",
            key: "Math",
        },
    ];

    const classSizeOptions = [
        {
            title: "1 student",
            key: "0-1",
        },
        {
            title: "2-5 students",
            key: "2-5",
        },
        {
            title: "6-8 students",
            key: "6-8",
        },
    ];

    const timeOptions = [
        {
            title: "9am-12pm",
            key: "9-12",
        },
        {
            title: "12pm-8pm",
            key: "12-8",
        },
        {
            title: "8pm-12am",
            key: "8-12",
        },
        {
            title: "12am-9am",
            key: "12-9",
        },
    ];

    const [localSearchedTerm, setLocalSearchedTerm] = useState();
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [mouseOverInput, setMouseOverInput] = useState(false);
    const SearchInputTimer = useRef();

    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [filterMenu, setFilterMenu] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState({
        title: "",
        key: "",
    });
    const [filters, setFilters] = useState({
        age: [],
        classTopic: [],
        classSize: [],
        time: [],
        free: false,
    });
    const [selectedFilters, setSelectedFilters] = useState([]);

    const [sortKeyLocal, setSortKeyLocal] = useState({
        title: "",
        key: "",
    });

    const [showSortMenu, setShowSortMenu] = useState(false);

    // Trigger API to search after 1 second of searching.
    useEffect(() => {
        const delayDebounceFunction = setTimeout(() => {
            if (showSearchInput) setSearchedTerm(localSearchedTerm);
        }, 1000);

        return () => clearTimeout(delayDebounceFunction);
        // eslint-disable-next-line
    }, [localSearchedTerm]);

    // Set already searched term in the filtered list.
    useEffect(() => {
        setLocalSearchedTerm(searchedTerm);
        // eslint-disable-next-line
    }, [searchedTerm]);

    // Clear the open filter menu details when filter menu is closed.
    useEffect(() => {
        if (!showFilterMenu) {
            setFilterMenu([]);
            setSelectedFilter({
                title: "",
                key: "",
            });
        }
        // eslint-disable-next-line
    }, [showFilterMenu]);

    // Timeout to hide search input.
    useEffect(() => {
        hideSearchInput();
        // eslint-disable-next-line
    }, [showSearchInput, mouseOverInput, localSearchedTerm]);

    const hideSearchInput = () => {
        if (showSearchInput && !mouseOverInput && !localSearchedTerm) {
            SearchInputTimer.current = setTimeout(() => {
                setShowSearchInput(false);
            }, 5000);
        } else {
            clearTimeout(SearchInputTimer.current);
        }
    };

    const handleChangeSearchText = (e) => {
        const searchedText = e.target.value;
        setLocalSearchedTerm(searchedText);
    };

    const handleFilterClasses = (option) => {
        if (option.key !== "free") setSelectedFilter(option);

        if (option.key === "age") {
            setFilterMenu(ageOptions);
        } else if (option.key === "classTopic") {
            setFilterMenu(classTopicOptions);
        } else if (option.key === "classSize") {
            setFilterMenu(classSizeOptions);
        } else if (option.key === "time") {
            setFilterMenu(timeOptions);
        } else if (option.key === "free") {
            handleFreeFilter();
        }
    };

    const handleBackMenu = () => {
        setSelectedFilter({
            title: "",
            key: "",
        });
    };

    const handleFreeFilter = () => {
        const localCopy = { ...filters };

        localCopy.free = !localCopy.free;

        setFilters(localCopy);
        setFilterKey(localCopy);
    };

    const handleFilters = (option, key) => {
        const localCopy = { ...filters };
        let localCopySelectedFilters = [...selectedFilters];
        const itemIndex = localCopy[key].indexOf(option.key);

        if (itemIndex > -1) {
            localCopy[key].splice(itemIndex, 1);
        } else {
            localCopy[key].push(option.key);
        }

        const filterListIndex = localCopySelectedFilters.findIndex(
            (filter) => filter.option.key === option.key
        );

        if (filterListIndex > -1) {
            localCopySelectedFilters.splice(filterListIndex, 1);
        } else {
            localCopySelectedFilters = [
                {
                    option,
                    key,
                },
                ...localCopySelectedFilters,
            ];
        }

        setFilters(localCopy);
        setFilterKey(localCopy);
        setSelectedFilters(localCopySelectedFilters);
    };

    const handleSortField = (filter) => {
        setShowSortMenu(false);
        setSortKeyLocal(filter);
        setSortKey(filter.key);
    };

    window.addEventListener("mouseup", function (event) {
        var pol = document.getElementById("searchBar");
        if (event.target !== pol && event.target.parentNode !== pol) {
            setShowSearchInput(Boolean(localSearchedTerm));
        }
    });

    const handleOpenSearchInput = () => {
        setMouseOverInput(true);
    };

    const handleCloseSearchInput = () => {
        setMouseOverInput(false);
        hideSearchInput();
    };

    window.addEventListener("mouseup", function (event) {
        var pol = document.getElementById("filterMenu");
        var iconPol = this.document.getElementById("filterIcon");

        if (
            event.target !== pol &&
            event.target.parentNode !== pol &&
            event.target.parentNode.parentNode !== pol &&
            event.target !== iconPol &&
            event.target.parentNode !== iconPol &&
            event.target.parentNode.parentNode !== iconPol
        ) {
            setShowFilterMenu(false);
        }
    });

    window.addEventListener("mouseup", function (event) {
        var pol = document.getElementById("sortMenu");
        if (event.target !== pol && event.target.parentNode !== pol) {
            setShowSortMenu(false);
        }
    });

    return (
        <div className={styles.filterIconsContainer}>
            <div className={styles.selectedFilterShowCaseContainer}>
                <div className={styles.selectedFiltersShowCaseContainer}>
                    {selectedFilters.map((filter) => {
                        return (
                            <div className={styles.filtersShowCaseContainer}>
                                <div className={styles.filterKeyTitle}>
                                    {filter.option.title}
                                </div>

                                <div
                                    className={styles.filterKeyRemoveIcon}
                                    onClick={() =>
                                        handleFilters(filter.option, filter.key)
                                    }
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            {isFrom !== "Teachers" ? (
                <div className={styles.sortContainer}>
                    <div
                        className={styles.sortIcon}
                        onClick={() => setShowSortMenu(true)}
                    >
                        <div className={styles.sortTitle}>Sort</div>
                        <FontAwesomeIcon icon={faChevronDown} />
                    </div>

                    {showSortMenu ? (
                        <div className={styles.sortMenu} id="sortMenu">
                            {sortOptions.map((option) => {
                                return (
                                    <div
                                        onClick={() => handleSortField(option)}
                                        className={`${
                                            sortKeyLocal.key === option.key
                                                ? styles.selectedFilterItem
                                                : null
                                        }`}
                                        key={option.key}
                                    >
                                        {option.title}
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                </div>
            ) : null}

            <div
                className={styles.searchContainer}
                onMouseEnter={handleOpenSearchInput}
                onMouseLeave={handleCloseSearchInput}
            >
                {showSearchInput ? (
                    <div className={styles.searchBar} id="searchBar">
                        <input
                            type="text"
                            value={localSearchedTerm}
                            onChange={handleChangeSearchText}
                            autoFocus
                        />
                    </div>
                ) : null}

                <div
                    className={`${styles.searchIcon} ${styles.filterIcon}`}
                    onClick={() => setShowSearchInput(true)}
                >
                    <FontAwesomeIcon icon={faSearch} />
                </div>
            </div>

            {isFrom !== "Teachers" ? (
                <div className={styles.filterContainer}>
                    <div className={styles.filterIcon} id="filterIcon">
                        <FontAwesomeIcon
                            icon={faSlidersH}
                            onClick={() => setShowFilterMenu(!showFilterMenu)}
                        />
                    </div>

                    {showFilterMenu ? (
                        selectedFilter.key ? (
                            <div className={styles.filterMenu} id="filterMenu">
                                <div className={styles.filterMenuTitleSection}>
                                    <FontAwesomeIcon
                                        icon={faChevronLeft}
                                        onClick={handleBackMenu}
                                    />

                                    <span
                                        className={styles.filterMenuTitle}
                                        onClick={handleBackMenu}
                                    >
                                        {selectedFilter.title}
                                    </span>
                                </div>

                                {filterMenu.map((option) => {
                                    return (
                                        <div
                                            className={`${styles.filterItem} ${
                                                filters[
                                                    selectedFilter.key
                                                ].indexOf(option.key) > -1
                                                    ? styles.selectedFilterItem
                                                    : null
                                            }`}
                                            onClick={() =>
                                                handleFilters(
                                                    option,
                                                    selectedFilter.key
                                                )
                                            }
                                            key={option.key}
                                        >
                                            {option.title}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div className={styles.filterMenu} id="filterMenu">
                                {filterOptions.map((option) => {
                                    return (
                                        <div
                                            className={`${styles.filterItem} ${
                                                option.key === "free" &&
                                                filters.free
                                                    ? styles.selectedFilterItem
                                                    : null
                                            }`}
                                            onClick={() =>
                                                handleFilterClasses(option)
                                            }
                                            key={option.key}
                                        >
                                            {option.title}
                                        </div>
                                    );
                                })}
                            </div>
                        )
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default SearchFilter;
