import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import styles from "./dropdown.module.css";

export default ({
    children,
    displayValue,
    show,
    handleToggleShow,
    noicon,
    feeAmount,
    setFeeAmount,
    setShowdropdown,
    isPublished,
    topList,
    dateTime,
    classSlotsLength,
    sessionType,
}) => {
    const [left, setLeft] = useState(0);
    const [top, setTop] = useState(null);
    const [width, setWidth] = useState(0);
    let containerRef = React.createRef();

    useEffect(() => {
        if (
            document.getElementById("scrollContainer") &&
            document.getElementById("scrollContainer-2")
        ) {
            if (show) {
                document.getElementById("scrollContainer").style.overflow =
                    "hidden";
                document.getElementById("scrollContainer-2").style.overflowX =
                    "hidden";
                setTop(
                    containerRef.current.getBoundingClientRect().y +
                        containerRef.current.getBoundingClientRect().height +
                        10 +
                        180 >
                        window.innerHeight
                        ? containerRef.current.getBoundingClientRect().y -
                              document.getElementById("dropdown").clientHeight
                        : containerRef.current.getBoundingClientRect().y +
                              containerRef.current.getBoundingClientRect()
                                  .height +
                              10
                );
            } else {
                document.getElementById("scrollContainer").style.overflow =
                    "auto";
                document.getElementById("scrollContainer-2").style.overflowX =
                    "auto";
            }
            return () => {
                if (
                    document.getElementById("scrollContainer") &&
                    document.getElementById("scrollContainer-2")
                ) {
                    document.getElementById("scrollContainer").style.overflow =
                        "auto";
                    document.getElementById(
                        "scrollContainer-2"
                    ).style.overflowX = "auto";
                }
            };
        } else if (
            containerRef.current.getBoundingClientRect().y +
                containerRef.current.getBoundingClientRect().height +
                10 +
                180 >
            window.innerHeight
        ) {
            setTop(
                containerRef.current.getBoundingClientRect().y +
                    containerRef.current.getBoundingClientRect().height +
                    10 +
                    180 >
                    window.innerHeight
                    ? containerRef.current.getBoundingClientRect().y -
                          document.getElementById("dropdown")?.clientHeight
                    : containerRef.current.getBoundingClientRect().y +
                          containerRef.current.getBoundingClientRect().height +
                          10
            );
        }
        // eslint-disable-next-line
    }, [show]);

    // useEffect(() => {
    //     document.onclick = function (e) {
    //         var element = e.target;
    //         if (
    //             element?.id == "downarrow" ||
    //             element?.parentElement?.id == "downarrow"
    //         ) {
    //         } else {
    //             // setShowdropdown(false);
    //         }
    //     };
    //     // eslint-disable-next-line
    // }, []);

    return (
        <div
            ref={containerRef}
            className={styles.container}
            style={{ position: "relative" }}
        >
            <div
                className={
                    dateTime
                        ? classSlotsLength
                            ? `${styles.greaterData} ${styles.inputContainerDateTime}`
                            : `${styles.lessData} ${styles.inputContainerDateTime}`
                        : styles.inputContainer
                    //  classSlotsLength ? styles.greaterData : styles.lessData
                }
            >
                {noicon ? (
                    <>
                        <div
                            className={
                                show
                                    ? `${styles.feeCurrency} ${styles.dropdownopen}`
                                    : styles.feeCurrency
                            }
                            onClick={(ev) => {
                                handleToggleShow();
                                setTop(
                                    ev.target.getBoundingClientRect().y +
                                        ev.target.getBoundingClientRect()
                                            .height +
                                        10
                                );
                                setLeft(ev.target.getBoundingClientRect().x);
                            }}
                        >
                            {displayValue}
                        </div>
                        <div className={styles.feeAmount}>
                            <input
                                type="number"
                                value={feeAmount}
                                onChange={(event) =>
                                    setFeeAmount(event.target.value)
                                }
                            />
                        </div>
                    </>
                ) : (
                    <span
                        title={displayValue}
                        className={
                            dateTime
                                ? styles.inputsectionDateTime
                                : styles.inputsection
                        }
                    >
                        {dateTime
                            ? displayValue
                                ? displayValue
                                : `${
                                      !!sessionType
                                          ? sessionType === "Course"
                                              ? "Choose up to 6 sessions within a course"
                                              : "Choose up to 6 options"
                                          : ""
                                  }`
                            : displayValue}
                    </span>
                )}
                {noicon || isPublished ? (
                    <></>
                ) : (
                    <FontAwesomeIcon
                        className={
                            show
                                ? `${styles.dropicon} ${styles.dropdownopen}`
                                : styles.dropicon
                        }
                        style={{ width: "30px", cursor: "pointer" }}
                        icon={faAngleDown}
                        id="downarrow"
                        onClick={(ev) => {
                            ev.preventDefault();
                            handleToggleShow();
                            // if (show) {
                            //     setTop(
                            //         containerRef.current.getBoundingClientRect()
                            //             .y +
                            //             containerRef.current.getBoundingClientRect()
                            //                 .height +
                            //             10 +
                            //             180 >
                            //             window.innerHeight
                            //             ? containerRef.current.getBoundingClientRect()
                            //                   .y -
                            //                   document.getElementById(
                            //                       "dropdown"
                            //                   ).clientHeight
                            //             : containerRef.current.getBoundingClientRect()
                            //                   .y +
                            //                   containerRef.current.getBoundingClientRect()
                            //                       .height +
                            //                   10
                            //     );
                            // }

                            setWidth(
                                containerRef.current.getBoundingClientRect()
                                    .width
                            );
                            setLeft(
                                containerRef.current.getBoundingClientRect().x
                            );
                        }}
                    />
                )}
            </div>
            {show && (
                <div
                    id="dropdown"
                    className={dateTime ? styles.dateTimeInput : ""}
                    style={
                        noicon
                            ? {
                                  position: "absolute",
                                  zIndex: "2",
                                  width: "21px",
                                  left: left,
                                  top: top,
                              }
                            : {
                                  position: "fixed",
                                  zIndex: "2",
                                  left: left,
                                  top: top,
                                  width: width,
                              }
                    }
                >
                    {children}
                </div>
            )}
        </div>
    );
};
