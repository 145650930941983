import { faGreaterThan, faLessThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Game2InitModal from "../../../../../../components/Game2InitModal";
import Participant from "../../../../../../components/Participant";
import Snackbar from "../../../../../../components/Snackbar";
import StudentSpotlight from "../../../../../../components/StudentSpotlight";
import TwilioParticipant from "../../../../../../components/TwilioParticipant";
import Game from "../../Game/index";
import styles from "./Layout1.module.scss";

export default ({
    participants,
    room,
    mutedUsers,
    publishAudio,
    endUserSession,
    muteUser,
    unMuteUser,
    socket,
    sessionID,
    currentPanel,
    status,
    session,
    updateSession,
    setSession,
    setWrapperStyle,
    identifier,
}) => {
    const [showModal, setShowModal] = useState();
    var Teacher = null;
    var Participants = [];
    const rowRef = useRef();
    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;
    const currentUserId = JSON.parse(localStorage.getItem("auth")).user._id;
    let noOfParticipants = 8;
    let participantBoxes = [];
    const studentParticipantIds = [];
    const [selectedUserIndex, setSelectedUserIndex] = useState(0);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [blockType, setBlockType] = useState(
        currentPanel.activity_settings.block_type
            ? currentPanel.activity_settings.block_type
            : 5
    );
    const [blockTypeChanged, setBlockTypeChanged] = useState(false);
    const [studentOnlyLayout, setStudentOnlyLayout] = useState(false);
    const [studentsData, setStudentsData] = useState([]);
    const [selectedStudentToFocus, setSelectedStudentToFocus] = useState(-1);

    const userIds = participants.map((participant) => {
        var identityObj = JSON.parse(participant.identity);
        return identityObj._id;
    });

    useEffect(() => {
        const getUserDetails = async () => {
            await axios
                .post(`/api/v1/parents`, {
                    data: userIds,
                })
                .then((studentsResult) => {
                    const users = studentsResult.data.data.users;
                    let filteredUsers = [];
                    users.map((user) => {
                        const tempData = {};
                        tempData.name = user.students[0].studentpreferedname;
                        tempData._id = user.students[0]._id;
                        tempData.parentId = user._id;

                        filteredUsers.push(tempData);
                        return 0;
                    });
                    setStudentsData(filteredUsers);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        if (currentUserRole === "teacher") getUserDetails();
        // eslint-disable-next-line
    }, [participants.length]);

    if (currentUserRole === "teacher") {
        Teacher = (
            <TwilioParticipant
                key={room.localParticipant.sid}
                participant={room.localParticipant}
                role={"teacher"}
                focusStudent={() => {}}
                selectedStudentToFocus={selectedStudentToFocus}
            />
        );

        let studentParticipants = participants.map((participant, index) => {
            var identityObj = JSON.parse(participant.identity);
            studentParticipantIds.push(identityObj._id);
            const studentName = studentsData.find(
                (student) => student.parentId === identityObj._id
            );
            return (
                <TwilioParticipant
                    isMutedUser={mutedUsers.indexOf(identityObj._id) !== -1}
                    key={participant.sid}
                    role={"student"}
                    participant={participant}
                    publishAudio={publishAudio}
                    muteUser={() => muteUser(identityObj._id)}
                    unMuteUser={() => unMuteUser(identityObj._id)}
                    endUserSession={() => endUserSession(identityObj._id)}
                    screen="teacher"
                    studentName={studentName?.name}
                    focusStudent={() => {
                        setSelectedStudentToFocus(index);
                    }}
                    selectedStudentToFocus={selectedStudentToFocus}
                />
            );
        });
        Participants = studentParticipants;
    }

    useEffect(() => {
        setShowModal(true);
        // eslint-disable-next-line
    }, [status]);

    useEffect(() => {
        if (
            currentPanel.activity_settings.game2_status[selectedUserIndex]
                ?.user_id === currentUserId
        ) {
            setWrapperStyle("wrapperWB");
            setStudentOnlyLayout(false);
        } else {
            setWrapperStyle("wrapper");
            setStudentOnlyLayout(true);
        }
        // eslint-disable-next-line
    }, [selectedUserIndex, session]);

    useEffect(() => {
        const index = currentPanel.activity_settings.game2_status.findIndex(
            (obj) => {
                if (obj) {
                    return obj.user_id === currentUserId;
                }
                return null;
            }
        );
        setSelectedUserIndex(index);
        // eslint-disable-next-line
    }, [showModal]);

    for (let i = 0; i < noOfParticipants; i++) {
        participantBoxes.push(
            <Col md="auto">
                {rowRef && rowRef.current && (
                    <div
                        className={styles.block}
                        style={{
                            width: rowRef
                                ? rowRef.current?.offsetWidth /
                                      noOfParticipants -
                                  30
                                : 0,
                        }}
                    >
                        <Participant>{Participants[i]}</Participant>
                    </div>
                )}
            </Col>
        );
    }

    const handleBlockType = async (num) => {
        setBlockType(num);
        setBlockTypeChanged(true);
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === currentPanel._id) {
                                    obj.activity_settings.block_type = num;
                                    obj.activity_settings.game2_status.splice(
                                        0,
                                        obj.activity_settings.game2_status
                                            .length
                                    );
                                }
                                return obj;
                            }
                        ),
                },
            },
        };
        setSession(newSession);
        await updateSession(newSession);
        socket.emit("blockTypeChange", {
            action: "blockTypeChange",
            payload: {
                blockType: num,
                userIds,
            },
        });
    };

    let userName = undefined;
    let selectedUserId = currentUserId;
    if (currentPanel.activity_settings.game2_status[selectedUserIndex]) {
        selectedUserId =
            currentPanel.activity_settings.game2_status[selectedUserIndex]
                .user_id;
    }
    if (selectedUserId === currentUserId) {
        userName = "teacher";
    } else {
        userName =
            currentPanel.activity_settings.game2_status[selectedUserIndex]
                ?.user_name;
    }

    const decreaseFlag = () => {
        if (selectedUserIndex > 0) {
            setSelectedUserIndex(selectedUserIndex - 1);
        } else {
            setSelectedUserIndex(
                currentPanel.activity_settings.game2_status.length - 1
            );
        }
    };

    const increaseFlag = () => {
        if (
            selectedUserIndex <
            currentPanel.activity_settings.game2_status.length - 1
        ) {
            setSelectedUserIndex(selectedUserIndex + 1);
        } else {
            setSelectedUserIndex(0);
        }
    };

    const selectedParticipantIndex = participants.findIndex((participant) => {
        const identityObj = JSON.parse(participant.identity);
        return identityObj._id === selectedUserId;
    });
    const topRightBlock = studentOnlyLayout
        ? Participants[selectedParticipantIndex]
        : Teacher;

    return (
        <div className={styles.assets}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            {showModal && (
                <>
                    <Game2InitModal
                        showModal={showModal}
                        handleGame2Start={() => {
                            setShowModal(false);
                            setBlockTypeChanged(false);
                        }}
                        handleBlockType={handleBlockType}
                        blockType={blockType}
                    />
                </>
            )}
            <Game
                key={selectedUserIndex}
                sessionID={sessionID}
                socket={socket}
                participants={participants}
                room={room}
                currentPanel={currentPanel}
                status={status}
                blockType={blockType}
                session={session}
                updateSession={updateSession}
                blockTypeChanged={blockTypeChanged}
                setSession={setSession}
                selectedUserId={selectedUserId}
                setShowModal={setShowModal}
                identifier={identifier}
                userName={userName}
                selectedUserIndex={selectedUserIndex}
            />

            <div className={styles.gameSelectorDiv}>
                {currentPanel.activity_settings.game2_status.length > 1 && (
                    <FontAwesomeIcon
                        icon={faLessThan}
                        onClick={decreaseFlag}
                        className={styles.leftArrow}
                    />
                )}
                <h1 className={styles.userName}>{userName}</h1>
                {currentPanel.activity_settings.game2_status.length > 1 && (
                    <FontAwesomeIcon
                        icon={faGreaterThan}
                        onClick={increaseFlag}
                        className={styles.rightArrow}
                    />
                )}
            </div>
            <div className={`${styles.block} ${styles.blockMedia}`}>
                <Participant>{topRightBlock}</Participant>
            </div>
            <div
                className={`${styles.rowDiv} ${
                    studentOnlyLayout ? styles.hidden : ""
                }`}
            >
                <Row style={{ justifyContent: "center" }} ref={rowRef}>
                    {participantBoxes}
                </Row>
            </div>

            <StudentSpotlight
                selectedStudentToFocus={selectedStudentToFocus}
                Participants={Participants}
                closeModal={() => {
                    setSelectedStudentToFocus(-1);
                }}
            />
        </div>
    );
};
