import _ from "lodash";
import React, { useState } from "react";
import styles from "./index.module.scss";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import defaultImage from "../../../assets/images//DefaultProfilePhoto.svg";
import reviewData from "./reviews.js";
import { ratingStar } from "../../../utils/Ratting/rating";

const ReviewCarousel = ({
    carouselImgs,
    onClickEvent,
    specialClass,
    autoPlay,
    controls,
    playOnHover,
    smallCarousel,
}) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleClick = (path) => {
        path === "left"
            ? setCurrentSlide(
                  currentSlide > 0 ? currentSlide - 1 : reviewData.length - 1
              )
            : setCurrentSlide(
                  currentSlide < reviewData.length - 1 ? currentSlide + 1 : 0
              );
    };

    // const checkExtension = (source) => {
    //     const ext = source.split(".");
    //     return _.last(ext);
    // };

    return (
        <div className={`review-carousel ${styles.carouselPage}`}>
            <article
                className={`review-carousel-wrapper ${styles.carouselWrapper}`}
                onClick={onClickEvent}
            >
                <div
                    className={`review-carousel-inner ${styles.carouselSlider}`}
                    style={{
                        transform: `translateX(-${currentSlide * 100}%)`,
                    }}
                >
                    {reviewData?.length > 0 ? (
                        reviewData?.map((caroData, caroIdx) => {
                            return (
                                <div
                                    className={`${styles.slide} ${styles[specialClass]}`}
                                    key={caroIdx}
                                >
                                    <p>{JSON.stringify(caroData?.review)}</p>
                                    <p style={{ color: "#13385c" }}>
                                        {caroData?.description}
                                    </p>
                                    <div className={styles.classRating}>
                                        {ratingStar(
                                            _.get(caroData, "class_rate", 0)
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        // <img
                        //     src={defaultImage}
                        //     alt="Carousel"
                        //     className={`${styles.smallCarouselDefaultImage} ${styles[specialClass]}`}
                        // />
                        <div></div>
                    )}
                </div>
            </article>
            {reviewData?.length > 1 ? (
                <button
                    className={`left-btn ${styles.leftBtn}`}
                    onClick={() => handleClick("left")}
                    // style={{ left: "0.5rem" }}
                >
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        className={styles.icon}
                    />
                </button>
            ) : null}
            {reviewData?.length > 1 ? (
                <button
                    className={`right-btn ${styles.rightBtn}`}
                    onClick={() => handleClick()}
                >
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        className={styles.icon}
                    />
                </button>
            ) : null}
        </div>
    );
};

export default ReviewCarousel;
