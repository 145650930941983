import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { faGreaterThan, faLessThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Snackbar from "../../../../../../../../components/Snackbar";
import Whiteboard from "./../../../WhiteboardByNirajan";
import styles from "./Layout1.module.css";

export default ({ currentPanel, session, studentView }) => {
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [fitToScreen, setFitToScreen] = useState(
        currentPanel.lesson_settings.whiteboard.fitToScreen
    );
    const [initialState, setInitaialState] = useState(false);
    const [position, setPosition] = useState({ left: 2, up: 3 });
    const [modalFlag, setModalFlag] = useState(0);
    let participantBoxes = [];

    //(rowRef.current.offsetWidth/noOfParticipants) - 30;
    for (let i = 0; i < 9; i++) {
        if (i === 0) {
            participantBoxes.push(
                <div
                    className={styles.videoContainer}
                    style={{ backgroundColor: "#E9F3FB" }}
                >
                    <h2 className={styles.containerText}>T</h2>
                </div>
            );
        } else {
            participantBoxes.push(
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S{i}</h2>
                </div>
            );
        }
    }

    useEffect(() => {
        const getSelectedMedia = async () => {
            const selectedMediaArray =
                currentPanel.lesson_settings?.whiteboard?.image_ids;
            let galleryData = {
                selectedMediaArray: selectedMediaArray,
                userId: session.teacher_id,
            };
            await axios
                .post(`/api/v1/user/gallery/selected`, galleryData)
                .then((data) => {
                    if (data.data.success) {
                        const resultMedia = data.data.data.newArray;
                        setSelectedMedia(resultMedia);
                    }
                    setInitaialState(true);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };

        getSelectedMedia();
        // eslint-disable-next-line
    }, []);

    const decreaseModalFlag = () => {
        if (modalFlag !== 0) {
            setModalFlag(modalFlag - 1);
        }
    };

    const increaseModalFlag = () => {
        if (modalFlag < participantBoxes.length - 3) {
            setModalFlag(modalFlag + 1);
        }
    };

    return (
        <>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <div className={styles.assets}>
                <Row className={styles.whiteboardTop}>
                    {initialState && (
                        <div className={styles.wbContainerTop}>
                            <div style={{ width: "1239px", height: "322px" }}>
                                <Whiteboard
                                    selectedMedia={selectedMedia}
                                    position={position}
                                    fitToScreen={fitToScreen}
                                    setFitToScreen={setFitToScreen}
                                    control={true}
                                    session={session}
                                    panelID={currentPanel._id}
                                    orientation="top"
                                    studentView={studentView}
                                    requiredWB={studentView ? false : true}
                                    setPosition={(data) => setPosition(data)}
                                />
                            </div>
                        </div>
                    )}
                </Row>
                <Row className={styles.whiteboardBottom}>
                    {initialState && (
                        <div className={styles.wbContainerBottom}>
                            <div style={{ width: "1239px", height: "322px" }}>
                                <Whiteboard
                                    selectedMedia={selectedMedia}
                                    position={position}
                                    fitToScreen={fitToScreen}
                                    setFitToScreen={setFitToScreen}
                                    control={false}
                                    session={session}
                                    panelID={currentPanel._id}
                                    orientation="bottom"
                                    studentView={studentView}
                                    requiredWB={studentView ? true : false}
                                    setPosition={(data) => setPosition(data)}
                                />
                            </div>
                        </div>
                    )}
                </Row>
                {studentView ? (
                    <>
                        <div
                            className={`${styles.studentView} ${styles.smallList}`}
                        >
                            <div
                                className={styles.videoContainer}
                                style={{
                                    backgroundColor: "#E9F3FB",
                                    height: "150px",
                                    width: "160px",
                                }}
                            >
                                <h2 className={styles.containerText}>T</h2>
                            </div>
                        </div>
                    </>
                ) : (
                    <div
                        className={`${styles.participantsList} ${styles.smallList}`}
                    >
                        <FontAwesomeIcon
                            style={{
                                color: "#83838b",
                                width: "30px",
                                position: "absolute",
                                zIndex: "8",
                                cursor: "pointer",
                                transform: "rotate(90deg)",
                                top: "0",
                            }}
                            icon={faLessThan}
                            onClick={decreaseModalFlag}
                        />
                        <div className={styles.moreParticipantBoxesCol}>
                            {participantBoxes
                                .slice(modalFlag, modalFlag + 4)
                                .map((obj, index) => {
                                    return obj;
                                })}
                        </div>
                        <FontAwesomeIcon
                            style={{
                                color: "#83838b",
                                width: "30px",
                                position: "absolute",
                                zIndex: "8",
                                cursor: "pointer",
                                transform: "rotate(90deg)",
                                bottom: "0",
                            }}
                            icon={faGreaterThan}
                            onClick={increaseModalFlag}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
