import axios from "axios";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import Loading from "../../../assets/videos/Loading.mp4";
import Stripe from "../../../assets/images/stripe/stripe.png";
import Snackbar from "../../../components/Snackbar";
import Navbar from "../../../components/Navbar";
import styles from "./upgrade3.module.scss";
const FailPlan = () => {
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    return (
        <>
            <Navbar />
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <div className={styles.container}>
                <div className={styles.upgradeContainer}>
                    <h1 className={styles.heading}>
                        Failed to subscibe your plan
                    </h1>
                </div>
                <p
                    style={{
                        cursor: "pointer",
                        color: "#13385c",
                        fontFamily: "ComfortaBold",
                    }}
                    onClick={() => (window.location.href = "/home")}
                >
                    {" "}
                    Go to home{" "}
                </p>
            </div>
        </>
    );
};

export default FailPlan;
