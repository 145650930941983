import React, { useEffect, useState } from "react";
import styles from "./layout1.module.css";
import TwilioParticipant from "../../../../../../components/TwilioParticipant";
import Participant from "../../../../../../components/Participant";
import VideoPlayer from "../../VideoPlayer/index";
import AudioPlayer from "../../AudioPlayer/index";
import axios from "axios";
import Snackbar from "../../../../../../components/Snackbar";
import _ from "lodash";

export default ({
    participants,
    room,
    mutedUsers,
    publishAudio,
    socket,
    currentPanel,
}) => {
    var Teacher = null;
    var Students = [];
    let slideNo = 0;

    const [slide, setSlide] = useState(() => {});
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;

    if (currentUserRole === "student") {
        var teacherParticipant = participants.find((obj) => {
            var identityObj = JSON.parse(obj.identity);
            return identityObj.role === "teacher";
        });
        if (teacherParticipant) {
            Teacher = (
                <TwilioParticipant
                    role="teacher"
                    key={teacherParticipant.sid}
                    participant={teacherParticipant}
                />
            );
        }

        if (room) {
            Students = [
                <TwilioParticipant
                    role="student"
                    key={room.localParticipant.sid}
                    participant={room.localParticipant}
                    publishAudio={publishAudio}
                    screen="student"
                    hideVideo={true}
                />,
            ].concat(
                participants
                    .filter((obj) => {
                        var identityObj = JSON.parse(obj.identity);
                        return identityObj.role !== "teacher";
                    })
                    .map((participant) => (
                        <TwilioParticipant
                            role="student"
                            key={participant.sid}
                            participant={participant}
                            publishAudio={publishAudio}
                            screen="student"
                            hideVideo={true}
                        />
                    ))
            );
        }
    }

    let noOfParticipants = participants.length;
    let participantBoxes = [];

    for (let i = 0; i < noOfParticipants; i++) {
        participantBoxes.push(Students[i]);
    }

    const handleSlideUpdate = (data) => {
        const obj = data.item;
        const type = obj.type;
        const initialSlide = data.initialSlide;

        if (type === "VIDEO") {
            const createVid = () => {
                const vid = (
                    <VideoPlayer
                        source={obj.location}
                        isSelectedVid={false}
                        top="50%"
                        socket={socket}
                        isStudent={true}
                        key={slideNo}
                        initialSlide={initialSlide}
                    ></VideoPlayer>
                );
                slideNo++;
                return vid;
            };
            setSlide(createVid);
        } else if (type === "AUDIO") {
            const createAud = () => {
                const aud = (
                    <AudioPlayer
                        source={obj.location}
                        isSelectedAud={false}
                        top="50%"
                        socket={socket}
                        isStudent={true}
                        key={slideNo}
                        initialSlide={initialSlide}
                    ></AudioPlayer>
                );
                slideNo++;
                return aud;
            };
            setSlide(createAud);
        } else if (type === "IMAGE") {
            const createImg = () => {
                slideNo++;
                return (
                    <img
                        className={`${styles.assets} ${styles.image}`}
                        src={obj.location}
                        alt=""
                    />
                );
            };
            setSlide(createImg);
        }
    };

    useEffect(() => {
        socket.on("updateLessonSlide", (data) => {
            handleSlideUpdate({ ...data, initialSlide: false });
        });
        const getCurrentSlide = async () => {
            let galleryData = {
                selectedMediaArray: [
                    currentPanel.lesson_settings.assets.image_ids[
                        currentPanel.lesson_settings.assets.activeSlideIndex
                    ],
                ],
                userId: "",
            };
            await axios
                .post(`/api/v1/user/gallery/selected`, galleryData)
                .then((data) => {
                    if (data.data.success && data.data.data?.newArray) {
                        const resultMedia = data.data?.data?.newArray[0];
                        if (resultMedia) {
                            handleSlideUpdate({
                                item: resultMedia,
                                initialSlide: true,
                            });
                        }
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        getCurrentSlide();
        return () => {
            socket.removeAllListeners("updateLessonSlide");
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className={styles.assets}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            {slide ? slide : ""}
            <div className={`${styles.block} ${styles.blockMedia}`}>
                <Participant>{Teacher}</Participant>

                {participantBoxes}
            </div>
        </div>
    );
};
