import {
    faFile,
    faPauseCircle,
    faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useRef, useState } from "react";
import {
    DocumentFormats,
    ImageFormats,
    MusicFormats,
    VideoFormats,
} from "../../utils/AcceptedFileFormats";
import styles from "./SavedFolderImages.module.scss";

const SelectedVideo = ({ file, handleSelectedFile }) => {
    const videoRef = useRef();
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const handlePlayVideo = () => {
        videoRef.current.play();
        setIsVideoPlaying(true);
    };

    const handlePauseVideo = () => {
        videoRef.current.pause();
        setIsVideoPlaying(false);
    };

    return (
        <div className={styles.videoContainer}>
            <FontAwesomeIcon
                icon={isVideoPlaying ? faPauseCircle : faPlayCircle}
                className={styles.videoControlIcon}
                onClick={isVideoPlaying ? handlePauseVideo : handlePlayVideo}
            />

            <video
                src={_.get(file, "location")}
                alt="Saved Item"
                onClick={() => handleSelectedFile(file)}
                ref={videoRef}
            />
        </div>
    );
};

const SavedFolderImages = ({
    savedImages,
    selectedFiles,
    handleSelectedFile,
}) => {
    const [selectedTab, setSelectedTab] = useState("IMAGE");

    return (
        <div className={styles.savedFolderImagesContainer}>
            <div className={styles.savedFolderHeading}>
                <span
                    className={
                        selectedTab === "IMAGE" ? styles.selectedTab : null
                    }
                    onClick={() => setSelectedTab("IMAGE")}
                >
                    Image
                </span>

                <span
                    className={
                        selectedTab === "VIDEO" ? styles.selectedTab : null
                    }
                    onClick={() => setSelectedTab("VIDEO")}
                >
                    Video
                </span>

                {/* <span
                    className={
                        selectedTab === "OTHERS" ? styles.selectedTab : null
                    }
                    onClick={() => setSelectedTab("OTHERS")}
                >
                    Others
                </span> */}
            </div>

            <div className={styles.savedFolderContainer}>
                {savedImages.map((file) => {
                    const fileFormat = _.last(
                        _.split(_.get(file, "location", ""), ".")
                    ).toUpperCase();

                    const selectedFormatArray =
                        selectedTab === "IMAGE"
                            ? [...ImageFormats]
                            : selectedTab === "VIDEO"
                            ? [...VideoFormats]
                            : [...MusicFormats, ...DocumentFormats];

                    return selectedFormatArray.includes(fileFormat) ? (
                        <div
                            className={`${styles.imageContainer} ${
                                selectedFiles.includes(file)
                                    ? styles.selectedImageContainer
                                    : null
                            }`}
                            key={_.get(file, "_id")}
                        >
                            {[...ImageFormats].includes(fileFormat) ? (
                                <img
                                    src={_.get(file, "location")}
                                    alt="Saved Item"
                                    onClick={() => handleSelectedFile(file)}
                                />
                            ) : [...VideoFormats].includes(fileFormat) ? (
                                <SelectedVideo
                                    file={file}
                                    handleSelectedFile={handleSelectedFile}
                                />
                            ) : (
                                <div className={styles.otherFileContainer}>
                                    <span className={styles.fileIconContainer}>
                                        <FontAwesomeIcon
                                            icon={faFile}
                                            className={styles.downloadIcon}
                                        />
                                    </span>

                                    <span className={styles.fileName}>
                                        {_.get(
                                            file,
                                            "original_name",
                                            "Name of file"
                                        )}
                                    </span>
                                </div>
                            )}
                        </div>
                    ) : null;
                })}
            </div>
        </div>
    );
};

export default SavedFolderImages;
