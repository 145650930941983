import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import EditAccount from "./components/AccountSettings/EditAccount/index";
import AccountSettings from "./components/AccountSettings/index";
import ErrorPage from "./components/Redirect/Error/index";
import AddSuccessfully from "./components/Redirect/Success";
import UserPilot from "./components/UserPilot";
import AdminDashboard from "./routes/AdminRoutes/Dashboard";
import AdminSessionCreate from "./routes/AdminRoutes/SessionCreate";
import AdminUserCreate from "./routes/AdminRoutes/UserCreate";
import BookingConfirmation from "./routes/BookingConfirmation";
import ChangeEmail from "./routes/ChangeEmail";
import ClassRegister from "./routes/ClassRegister";
import CourseRegister from "./routes/CourseRegister";
import EmailConfirmation from "./routes/EmailConfirmation";
import RegistrationSuccessful from "./routes/EmailConfirmation/registrationSuccessful";
import EmailNotMatch from "./routes/EmailNotMatch";
import ForgotPassword from "./routes/ForgotPassword/index";
import AboutUs from "./routes/GuestUser/AboutUs";
import Classes from "./routes/GuestUser/Classes";
import GuestLanding from "./routes/GuestUser/GuestLanding";
import GuestPlay from "./routes/GuestUser/Play";
import Teach from "./routes/GuestUser/Teach";
import Teachers from "./routes/GuestUser/Teachers";
import Invite from "./routes/Invite";
import LoginNew from "./routes/LoginNew/index";
import NotFound from "./routes/NotFound";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import ResetPassword from "./routes/ResetPassword";
import SessionActivityCustomise from "./routes/Session/Activity/Customise";
import SessionActivityList from "./routes/Session/Activity/List";
import SessionCreateAndUpdate from "./routes/Session/CreateAndUpdate";
import SessionEffect from "./routes/Session/Effects";
import Sessions from "./routes/Session/List";
import SessionMediaMedia from "./routes/Session/Media/media";
import SessionMediaView from "./routes/Session/Media/view";
import SessionTool from "./routes/Session/ToolSelect";
import SessionView from "./routes/Session/View";
import SessionWhiteboardMedia from "./routes/Session/Whiteboard/media";
import SessionWhiteboardSetting from "./routes/Session/Whiteboard/setting";
import SessionWhiteboardView from "./routes/Session/Whiteboard/view";
import SessionHome from "./routes/SessionHome";
import Landing from "./routes/SessionHosting/Landing";
import Play from "./routes/SessionHosting/Play";
import SignupOne from "./routes/Signup/Signup1";
import PaymentSuccess from "./routes/StripePayment";
import CoursePaymentSuccess from "./routes/StripePayment/CoursePaymentSuccess.js";
import StripePaymentCancel from "./routes/StripePaymentCancel/index";
import StudentRegistration from "./routes/StudentRegistration";
import TermsOfService from "./routes/TermsOfService";
import AdminRouteWrapper from "./routes/Wrapper/AdminRoute";
import PrivateRouteWrapper from "./routes/Wrapper/PrivateRoute";
import TeacherAuthWrapper from "./routes/Wrapper/TeacherRoute";
import PreviewSession from "./routes/Preview/index";

import Chat from "./routes/Chat";
import VideoSelect from "./routes/Session/VideoSelect";
import Screenshare from "./routes/Session/Screenshare";
import ClassDetailsNew from "./routes/ClassDetailsNew";
import TeacherDetailsNew from "./routes/TeacherDetailsNew";
import StudentSubscriptionRegister from "./routes/Subscription/StudentSubscriptionRegister/index";
import Upgrade1 from "./routes/Subscription/UpgradePlans/Upgrade1.js";
import Upgrade2 from "./routes/Subscription/UpgradePlans/Upgrade2";
import Upgrade3 from "./routes/Subscription/UpgradePlans/Upgrade3";
import FailPlan from "./routes/Subscription/UpgradePlans/FailPlan";
import PlanDetailsFaq from "./routes/Subscription/PlanDetailsFaq/index.js";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

const refreshAuthLogic = (failedRequest) => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    return axios
        .post("/api/v1/refresh-token", { token: auth?.refreshToken })
        .then((tokenRefreshResponse) => {
            localStorage.setItem(
                "auth",
                JSON.stringify(tokenRefreshResponse.data.data)
            );
            failedRequest.response.config.headers["Authorization"] =
                "Bearer " + tokenRefreshResponse.data.data.accessToken;
            return Promise.resolve();
        })
        .catch((e) => {
            localStorage.clear();
            window.location.href = "/login";
        });
};

createAuthRefreshInterceptor(axios, refreshAuthLogic);

axios.interceptors.request.use(
    function (config) {
        const auth = localStorage.getItem("auth");
        if (auth) {
            config.headers.Authorization =
                "bearer " + JSON.parse(auth).accessToken;
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

function App() {
    return (
        <Router>
            {/* <UserPilot /> */}

            <Switch>
                {/* <Route path="/" exact>
                    <PrivateRouteWrapper Component={Home}></PrivateRouteWrapper>
                </Route> */}

                <Route path="/privacypolicy" exact>
                    <PrivacyPolicy />
                </Route>

                <Route path="/termsofservice" exact>
                    <TermsOfService />
                </Route>

                <Route exact path="/chat">
                    <Chat />
                </Route>

                <Route path="/invites/email-not-match" exact>
                    <PrivateRouteWrapper
                        Component={EmailNotMatch}
                    ></PrivateRouteWrapper>
                </Route>

                <Route path="/invites/:id/confirmation" exact>
                    <PrivateRouteWrapper
                        Component={BookingConfirmation}
                    ></PrivateRouteWrapper>
                </Route>

                <Route path="/invites/:id/registration" exact>
                    <PrivateRouteWrapper
                        Component={StudentRegistration}
                    ></PrivateRouteWrapper>
                </Route>

                <Route path="/invites/:id" exact>
                    <PrivateRouteWrapper
                        Component={Invite}
                    ></PrivateRouteWrapper>
                </Route>

                <Route path="/plan-subscription" exact>
                    <StudentSubscriptionRegister />
                </Route>

                <Route path="/subscription" exact>
                    <Upgrade1 />
                </Route>
                <Route path="/planFaqs" exact>
                    <PlanDetailsFaq />
                </Route>
                <Route path="/choose-plan-and-subscription-plan" exact>
                    <Upgrade2 />
                </Route>
                <Route path="/SuccessPlan-details" exact>
                    <Upgrade3 />
                </Route>
                <Route path="/FailPlan-details" exact>
                    <FailPlan />
                </Route>
                <Route path="/confirm_email_verification/:randomString" exact>
                    <EmailConfirmation />
                </Route>

                <Route path="/change_email/:randomString" exact>
                    <ChangeEmail />
                </Route>

                <Route path="/home" exact>
                    <GuestLanding />
                </Route>

                <Route path="/classes" exact>
                    <Classes
                        listToDisplay="newClasses"
                        title="Explore our upcoming sessions"
                    />
                </Route>

                <Route path="/recommended-classes" exact>
                    <Classes
                        listToDisplay="recommendedClasses"
                        title="Recommended for you"
                    />
                </Route>

                <Route path="/teachers" exact>
                    <Teachers listToDisplay="teachers" />
                </Route>

                <Route path="/about" exact>
                    <AboutUs />
                </Route>

                <Route path="/play" exact>
                    <GuestPlay />
                </Route>

                <Route path="/teach" exact>
                    <Teach />
                </Route>

                <Route
                    path="/class-details/:classID"
                    exact
                    component={ClassDetailsNew}
                />

                <Route
                    path="/register/:classID"
                    exact
                    component={ClassRegister}
                />

                <Route
                    path="/course-register/:classID"
                    exact
                    component={CourseRegister}
                />

                <Route
                    path="/class/payment-success/:classID"
                    exact
                    component={PaymentSuccess}
                />

                <Route
                    path="/class/course-payment-success/:classID"
                    exact
                    component={CoursePaymentSuccess}
                />

                <Route
                    path="/stripe/payment-cancel/:classID"
                    exact
                    component={StripePaymentCancel}
                />

                <Route
                    path="/stripe/course-payment-cancel/:classID"
                    exact
                    component={StripePaymentCancel}
                />
                <Route
                    path="/teacher-details/:teacherID"
                    exact
                    component={TeacherDetailsNew}
                />

                <Route path="/registration-successful" exact>
                    <RegistrationSuccessful />
                </Route>

                <Route path="/" exact>
                    <PrivateRouteWrapper
                        Component={Landing}
                    ></PrivateRouteWrapper>
                </Route>

                <Route path="/sessions" exact>
                    <TeacherAuthWrapper Component={Sessions} />
                </Route>

                <Route path="/session/:sessionID/activity/list" exact>
                    <SessionActivityList></SessionActivityList>
                </Route>

                <Route path="/session/:sessionID/activity/customise" exact>
                    <SessionActivityCustomise></SessionActivityCustomise>
                </Route>

                <Route path="/session/:sessionID/whiteboard/setting" exact>
                    <SessionWhiteboardSetting></SessionWhiteboardSetting>
                </Route>

                <Route path="/session/:sessionID/whiteboard/view" exact>
                    <SessionWhiteboardView></SessionWhiteboardView>
                </Route>

                <Route path="/session/:sessionID/whiteboard/media" exact>
                    <SessionWhiteboardMedia></SessionWhiteboardMedia>
                </Route>

                <Route path="/session/:sessionID/asset/view" exact>
                    <SessionMediaView></SessionMediaView>
                </Route>

                <Route path="/session/:sessionID/asset/media" exact>
                    <SessionMediaMedia></SessionMediaMedia>
                </Route>

                <Route path="/session/:sessionID/play" exact>
                    <Play></Play>
                </Route>

                <Route path="/session/:sessionID/tool" exact>
                    <SessionTool></SessionTool>
                </Route>

                <Route path="/session/:id" exact>
                    <TeacherAuthWrapper Component={SessionCreateAndUpdate} />
                </Route>

                <Route path="/session/:id/preview" exact>
                    <TeacherAuthWrapper Component={PreviewSession} />
                </Route>

                <Route path="/session/:sessionID/view" exact>
                    <SessionView></SessionView>
                </Route>

                <Route path="/session/:sessionID/screenshare" exact>
                    <Screenshare></Screenshare>
                </Route>

                <Route path="/session/:sessionID/viewTool" exact>
                    <VideoSelect></VideoSelect>
                </Route>

                <Route path="/session/:sessionId" exact>
                    <PrivateRouteWrapper
                        Component={SessionHome}
                    ></PrivateRouteWrapper>
                </Route>

                <Route path="/effect/:id" exact>
                    <SessionEffect></SessionEffect>
                </Route>
                <Route path="/accountsettings" exact>
                    <PrivateRouteWrapper
                        Component={AccountSettings}
                    ></PrivateRouteWrapper>
                </Route>

                <Route path="/paymentdetails/editaccount" exact>
                    <PrivateRouteWrapper
                        Component={EditAccount}
                    ></PrivateRouteWrapper>
                </Route>

                <Route path="/stripe/success" exact>
                    <AddSuccessfully></AddSuccessfully>
                </Route>

                <Route path="/paymentdetails/editaccount" exact>
                    <EditAccount />
                </Route>

                <Route path="/success" exact>
                    <AddSuccessfully />
                </Route>

                <Route path="/error" exact>
                    <ErrorPage />
                </Route>

                {/* <Route path="/login" exact>
          <Login />
        </Route> */}

                <Route path="/login" exact>
                    <LoginNew />
                </Route>
                <Route path="/forgotpassword" exact>
                    <ForgotPassword />
                </Route>
                <Route path="/reset-password" exact>
                    <ResetPassword />
                </Route>

                <Route path="/signup" exact>
                    <SignupOne />
                </Route>
                {/* <Route path="/signuptwo" exact>
          <SignupTwo />
        </Route> */}
                <Route path="/admin" exact>
                    <AdminRouteWrapper Component={AdminDashboard} />
                </Route>
                <Route path="/admin/session/create" exact>
                    <AdminRouteWrapper Component={AdminSessionCreate} />
                </Route>
                <Route path="/admin/user/create" exact>
                    <AdminRouteWrapper Component={AdminUserCreate} />
                </Route>
                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
