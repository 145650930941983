import React from "react";
import Navbar from "../../../components/Navbar";
import styles from "./index.module.scss";
import StarPhoto from "../../../assets/images/stars.png";
import FooterNew from "../../../components/FooterNew";

const AboutUs = () => {
    return (
        <div className={styles.mainContainer}>
            <Navbar />

            <div className={styles.pageContainer}>
                <div className={styles.contentContainer}>
                    <h1>About Us</h1>
                </div>

                <FooterNew />
            </div>

            <img src={StarPhoto} alt="Star" className={styles.starPhoto} />
        </div>
    );
};

export default AboutUs;
