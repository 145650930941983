import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Preview.module.scss";
import { Link } from "react-router-dom";
import Preview from "./../Session/Whiteboard/media/Preview/index";
import PreviewVideo from "./../Session/Whiteboard/media/Preview/VideoLayout/index";
import { useHistory, useLocation } from "react-router";
import DemoGame from "../Session/Activity/Customise/Game1/index";
import DemoGameTwo from "../Session/Activity/Customise/Game2/index";
import PresentationPreview from "./../Session/Media/media/Preview/index";
import ControlsBar from "../SessionHosting/Play/ControlsBar";
import socketIOClient from "socket.io-client";
import studentViewButton from "../../assets/images/viewButton/studentView.svg";
import teacherViewButton from "../../assets/images/viewButton/teacherView.svg";
import ScreensharePreview from "../Session/Whiteboard/media/Preview/Screeshare";

const PreviewSession = ({ handlePreview, currentPanel }) => {
    const history = useHistory();
    const location = useLocation();
    const [tab, setTab] = useState("");
    const [state, setState] = useState(undefined);
    const [currentPanelNew, setCurrentPanelNew] = useState({});
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [renderGame, setRenderGame] = useState(true);
    const [activityType, setActivityType] = useState(true);
    const [btn, setBtn] = useState(false);
    const [mute, setMute] = useState(false);
    const sessionID = location?.state?.session?._id;
    const [socket, setSocket] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [twilio, setTwilio] = useState(null);
    const [sticker, setSticker] = useState("");
    const [screenArrangement, setScreenArrangement] = useState(false);
    const session = location?.state?.session;
    const [studentView, setStudentView] = useState(false);
    const handleLeave = () => {
        setState(undefined);
    };
    const handleSwitch = (index) => {
        setState(index);
    };

    const handleBtn = () => {
        if (!screenArrangement) {
            setBtn(!btn);
        }
    };

    const muteAll = () => {
        // console.log("mute all");
    };

    const shareScreenHandler = () => {
        // console.log("Share screen");
    };

    const updateSession = async (newSession) => {
        const sessionData = newSession;
        await axios
            .put("/api/v1/sessions", sessionData)
            .then((response) => {
                if (response.data.success) {
                    // console.log(response.data);
                } else {
                    // console.log("======>else");
                }
            })
            .catch((error) => {
                // console.log(error);
            });
    };

    const handleStudentView = async (panelId, value) => {
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === panelId) {
                                    if (currentPanelNew.type === "VIDEO") {
                                        obj.video_settings.student_view = value;
                                    } else if (
                                        currentPanelNew.type === "LESSON"
                                    ) {
                                        if (
                                            currentPanelNew.subType === "ASSET"
                                        ) {
                                            obj.lesson_settings.assets.view.student =
                                                value;
                                        } else {
                                            obj.lesson_settings.whiteboard.student.view =
                                                value;
                                            obj.lesson_settings.whiteboard.teacher.view =
                                                value.slice(0, 4) +
                                                "T" +
                                                value.slice(4, value.length);
                                        }
                                    } else if (
                                        currentPanelNew.type === "ACTIVITY"
                                    ) {
                                        obj.activity_settings.student_view =
                                            value;
                                    }
                                }
                                return obj;
                            }
                        ),
                },
            },
        };
        // setSession(newSession);
        await updateSession(newSession);
    };

    const handleTeacherView = async (panelId, value) => {
        // console.log(panelId, value);
        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === panelId) {
                                    if (currentPanelNew.type === "VIDEO") {
                                        obj.video_settings.teacher_view = value;
                                    } else if (
                                        currentPanelNew.type === "LESSON"
                                    ) {
                                        if (
                                            currentPanelNew.subType === "ASSET"
                                        ) {
                                            obj.lesson_settings.assets.view.teacher =
                                                value;
                                        } else {
                                            obj.lesson_settings.whiteboard.student.view =
                                                value.slice(0, 4) +
                                                value.slice(5, value.length);
                                            obj.lesson_settings.whiteboard.teacher.view =
                                                value;
                                        }
                                    } else if (
                                        currentPanelNew.type === "ACTIVITY"
                                    ) {
                                        obj.activity_settings.teacher_view =
                                            value;
                                    }
                                }
                                return obj;
                            }
                        ),
                },
            },
        };
        await updateSession(newSession);
    };

    useEffect(() => {
        async function fetchData() {
            await axios
                .get(`/api/v1/twilio/session/${sessionID}`)
                .then((response) => {
                    if (!response.data.success) {
                        // console.log(response.data.message);
                    } else {
                        setTwilio(response.data.data);
                    }
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setTab(location?.state?.currentPanel?.label);
        setCurrentPanelNew(location?.state?.currentPanel);
        setActivityType(location?.state?.currentPanel?.subType);
        setSelectedTabIndex(location?.state?.panelIndex);
        // setSession(location?.state?.session);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const socket = socketIOClient(process.env.REACT_APP_BACKEND_URL, {
            query: {
                userId: JSON.parse(localStorage.getItem("auth")).user._id,
                sessionId: sessionID,
                userRole: JSON.parse(localStorage.getItem("auth")).user.role,
            },
            pingTimeout: 60000,
        });

        setSocket(socket);
        // eslint-disable-next-line
    }, [sessionID]);

    const setStickerImage = (value) => {
        setSticker(value);
        setTimeout(() => {
            setSticker("");
        }, 2000);
    };

    return (
        <div className={styles.previewContainer}>
            {tab === "Activity" && activityType === "BUBBLE_POP" ? (
                <DemoGame
                    currentPanel={currentPanelNew}
                    session={location?.state?.session}
                    renderGame={renderGame}
                    studentView={studentView}
                    // setIsConfigComplete={setIsConfigComplete}
                />
            ) : tab === "Activity" && activityType === "BUILD_YOUR_DREAM" ? (
                <DemoGameTwo
                    renderGame={renderGame}
                    session={location?.state?.session}
                    // setSession={setSession}
                    currentPanel={currentPanelNew}
                    studentView={studentView}
                    // setIsConfigComplete={setShowDemoGame}
                />
            ) : tab === "Lesson" && currentPanelNew?.subType === "ASSET" ? (
                <PresentationPreview
                    currentPanel={currentPanelNew}
                    session={location?.state?.session}
                    studentView={studentView}
                />
            ) : tab === "Lesson" &&
              currentPanelNew?.subType === "WHITEBOARD" ? (
                <Preview
                    currentPanel={currentPanelNew}
                    session={location?.state?.session}
                    studentView={studentView}
                />
            ) : tab === "Video" &&
              currentPanelNew?.subType !== "SCREENSHARE" ? (
                <PreviewVideo
                    currentPanel={currentPanelNew}
                    session={location?.state?.session}
                    studentView={studentView}
                />
            ) : tab === "Video" &&
              currentPanelNew?.subType === "SCREENSHARE" ? (
                <ScreensharePreview
                    currentPanel={currentPanelNew}
                    session={location?.state?.session}
                    studentView={studentView}
                />
            ) : null}
            <div className={styles.switchContainer}>
                {location?.state?.session?.configuration?.right_panel_settings.map(
                    (type, index) => {
                        return (
                            // type?.label !== "Video" &&
                            type?.isConfigurationCompleted === true && (
                                <div
                                    className={styles.switchWrapper}
                                    key={index}
                                >
                                    <div
                                        onMouseEnter={() => {
                                            handleSwitch(index);
                                        }}
                                        onMouseLeave={() => {
                                            handleLeave();
                                        }}
                                        className={styles.switch}
                                        style={{
                                            backgroundColor:
                                                selectedTabIndex === index
                                                    ? "#fdd7c6"
                                                    : "#C8E8E5",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setTab(type?.label);
                                            setCurrentPanelNew(type);
                                            setRenderGame(
                                                type?.isConfigurationCompleted
                                            );
                                            setActivityType(type?.subType);
                                            setSelectedTabIndex(index);
                                        }}
                                    >
                                        {state === index ? (
                                            <p>
                                                {type?.label
                                                    ? type?.label
                                                    : type?.type}
                                            </p>
                                        ) : (
                                            <p>
                                                {type?.label
                                                    ? type?.label.charAt(0)
                                                    : type?.type.charAt(0)}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )
                        );
                    }
                )}
            </div>
            {sticker && (
                <img src={sticker} className={styles.stickerContainer} />
            )}
            {!studentView && (
                <ControlsBar
                    endCall={() => history.goBack()}
                    session={location?.state?.session}
                    handleBtn={handleBtn}
                    btn={btn}
                    setMute={setMute}
                    muteAll={muteAll}
                    socket={socket}
                    participants={participants}
                    twilio={twilio}
                    setStickerValue={setStickerImage}
                    shareScreenHandler={shareScreenHandler}
                    currentPanel={currentPanelNew}
                    screenArrangement={screenArrangement}
                    setScreenArrangement={setScreenArrangement}
                    whiteboardStudentView={(value) =>
                        console.log("whiteboardStudentView", value)
                    }
                    whiteboardTeacherView={(value) =>
                        console.log("whiteboardTeacherView", value)
                    }
                    handleTeacherView={(newView) => {
                        handleTeacherView(currentPanelNew?._id, newView);
                    }}
                    handleStudentView={(newView) => {
                        handleStudentView(currentPanelNew?._id, newView);
                    }}
                />
            )}
            <img
                className={styles.showFeedButton}
                src={studentView ? studentViewButton : teacherViewButton}
                onClick={() => {
                    studentView ? setStudentView(false) : setStudentView(true);
                }}
            />
            {/* <div className={styles.showFeedButton}>
                {!studentView ? `Student view` : `Teacher view`}
            </div> */}
        </div>
    );
};

export default PreviewSession;
