import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/images/LOGO (3).svg";
import PlusIcons from "../../assets/images/Plus_icons.svg";
import Loading from "../../assets/videos/Loading.mp4";
import FooterNew from "../../components/FooterNew";
import Snackbar from "../../components/Snackbar";
import Navbar from "../../components/Navbar";
import styles from "./emailConfirmation.module.scss";
import RegistrationSuccessful from "./registrationSuccessful";

export default () => {
    const history = useHistory();

    const [verifyingAPI, setVerifyingAPI] = useState(true);
    const [responseError, setResponseError] = useState(false);
    const [emailSent, setEmailSent] = useState(true);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const handleVerifyUser = async () => {
        const randomString = window.location.pathname.split("/")[2];

        await axios
            .post("/api/v1/user/email-verification", {
                randStr: randomString,
            })
            .then((response) => {
                setVerifyingAPI(false);
                setResponseError(false);
                setEmailSent(false);

                if (response.data.success) {
                    if (response?.data?.data) {
                        localStorage.setItem(
                            "auth",
                            JSON.stringify(response?.data?.data)
                        );
                        const redirectTo =
                            response?.data?.data?.user?.verifyLink;
                        !!redirectTo
                            ? (window.location.href = redirectTo)
                            : (window.location.href = "/login");
                    } else {
                        window.location.href = "/login";
                    }
                } else {
                    setResponseError(true);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const handleResendEmail = async () => {
        setVerifyingAPI(true);
        const randomString = window.location.pathname.split("/")[2] || "";
        let userEmail = "";

        if (!randomString) {
            userEmail = localStorage.getItem("plassroom-email");
        }
        await axios
            .post("/api/v1/user/resend/email-verification-link", {
                randStr: randomString,
                email: userEmail,
            })
            .then((response) => {
                setVerifyingAPI(false);
                if (response.data.success) {
                    setResponseError(false);
                    setEmailSent(true);
                } else {
                    setResponseError(true);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                setResponseError(true);
            });
    };

    useEffect(() => {
        handleVerifyUser();
        // eslint-disable-next-line
    }, []);

    return (
        <div className={styles.waiting_screen}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <Navbar />

            {verifyingAPI ? (
                <div className={styles.waiting_content__container}>
                    <video className="VideoTag" autoPlay loop muted>
                        <source src={Loading} type="video/mp4" />
                    </video>
                    Give us a second while we verify your email
                </div>
            ) : responseError ? (
                <div className={styles.error_content__container}>
                    <div>
                        <p className={styles.error_heading}>
                            Oops, something went wrong.
                        </p>

                        <p
                            style={{
                                fontStyle: "italic",
                            }}
                        >
                            Please click on the verification link again in your
                            email or if you need the verification email to be
                            resent to you, please{" "}
                            <span onClick={handleResendEmail}>click here</span>.
                        </p>

                        <p>
                            Please{" "}
                            <a href="mailto:enquiry@plassroom.com">
                                contact support
                            </a>{" "}
                            if this problem persists.
                        </p>
                    </div>
                </div>
            ) : emailSent ? (
                <RegistrationSuccessful />
            ) : (
                <div className={styles.success_content__container}>
                    <div>
                        <p className={styles.success_heading}>
                            It works! Welcome to Plassroom.
                        </p>

                        <p>
                            You will automatically be directed to the login page
                            shortly. Please use the account details you have
                            just verified to access Plassroom fully.
                        </p>

                        <p>
                            If you are not automatically directed to the login
                            page, please{" "}
                            <span onClick={handleResendEmail}>click here</span>.
                        </p>
                    </div>
                </div>
            )}

            <FooterNew />
        </div>
    );
};
