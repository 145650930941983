import { Backdrop, CircularProgress } from "@material-ui/core";
import React from "react";
const PageLoader = ({ open, zIndex }) => {
    return (
        <div>
            <Backdrop
                sx={{
                    color: "#88ADDB",
                    zIndex: zIndex ? zIndex : "10000",
                }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default PageLoader;
