import axios from "axios";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import GameInitModal from "../../../../../../components/GameInitModal";
import Participant from "../../../../../../components/Participant";
import Snackbar from "../../../../../../components/Snackbar";
import StudentSpotlight from "../../../../../../components/StudentSpotlight";
import TwilioParticipant from "../../../../../../components/TwilioParticipant";
import Game from "../../Game/index";
import styles from "./Layout1.module.scss";

export default ({
    participants,
    room,
    mutedUsers,
    publishAudio,
    endUserSession,
    muteUser,
    unMuteUser,
    socket,
    sessionID,
    currentPanel,
    status,
    handleImageTypes,
    isIndividual,
    handleTotalImages,
    handleTurnBased,
    handleTimer,
    handleScoreboard,
    session,
    setSession,
    updateSession,
    setWrapperStyle,
}) => {
    const [showModal, setShowModal] = useState(true);
    var Teacher = null;
    var Participants = [];
    const rowRef = useRef();
    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;
    let noOfParticipants = 8;
    let participantBoxes = [];
    const studentParticipantIds = [];
    const studentAccess = [];
    const studentColors = [];
    const [studentsData, setStudentsData] = useState([]);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [selectedStudentToFocus, setSelectedStudentToFocus] = useState(-1);

    const userIds = participants.map((participant) => {
        var identityObj = JSON.parse(participant.identity);
        return identityObj._id;
    });

    useEffect(() => {
        const getUserDetails = async () => {
            await axios
                .post(`/api/v1/parents`, {
                    data: userIds,
                })
                .then((studentsResult) => {
                    const users = studentsResult.data.data.users;
                    let filteredUsers = [];
                    users.map((user) => {
                        const tempData = {};
                        tempData.name = user.students[0].studentpreferedname;
                        tempData._id = user.students[0]._id;
                        tempData.parentId = user._id;

                        filteredUsers.push(tempData);
                        return 0;
                    });
                    if (currentUserRole === "teacher")
                        setStudentsData(filteredUsers);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        getUserDetails();
        // eslint-disable-next-line
    }, [participants.length]);

    if (currentUserRole === "teacher") {
        Teacher = (
            <TwilioParticipant
                key={room.localParticipant.sid}
                participant={room.localParticipant}
                role={"teacher"}
                focusStudent={() => {}}
                selectedStudentToFocus={selectedStudentToFocus}
            />
        );

        let studentParticipants = participants.map((participant, index) => {
            var identityObj = JSON.parse(participant.identity);
            studentParticipantIds.push(identityObj._id);
            const studentName = studentsData.find(
                (student) => student.parentId === identityObj._id
            );
            const student = session.students_ids.find(
                (obj) => obj.id === identityObj._id
            );
            const gameStatus = currentPanel.activity_settings.game1_status.find(
                (obj) => obj.user_id === identityObj._id
            );
            if (student) studentAccess.push(student.access);
            if (gameStatus) studentColors.push(gameStatus.user_color);
            return (
                <TwilioParticipant
                    isMutedUser={mutedUsers.indexOf(identityObj._id) !== -1}
                    key={participant.sid}
                    role={"student"}
                    participant={participant}
                    publishAudio={publishAudio}
                    muteUser={() => muteUser(identityObj._id)}
                    unMuteUser={() => unMuteUser(identityObj._id)}
                    endUserSession={() => endUserSession(identityObj._id)}
                    screen="teacher"
                    studentName={studentName?.name}
                    focusStudent={() => {
                        setSelectedStudentToFocus(index);
                    }}
                    selectedStudentToFocus={selectedStudentToFocus}
                />
            );
        });
        Participants = studentParticipants;
    }

    useEffect(() => {
        setShowModal(status === "play" || status === "pause" ? false : true);
        setWrapperStyle("wrapperWB");
        // eslint-disable-next-line
    }, [status]);

    const handleStudentAccess = (id) => {
        const newSession = { ...session };
        const student = newSession.students_ids.find((obj) => {
            return obj.id === id;
        });
        if (student) {
            student.access = !student.access;
            setSession(newSession);
            socket.emit("toggleAccess", {
                action: "toggleAccess",
                payload: {
                    id: id,
                },
            });
        }
    };

    for (let i = 0; i < noOfParticipants; i++) {
        participantBoxes.push(
            <Col md="auto" key={i}>
                {rowRef && rowRef.current && (
                    <div
                        className={`${styles.block} ${
                            studentAccess[i] &&
                            currentPanel.activity_settings.turn_based
                                ? styles.selected
                                : null
                        }`}
                        style={{
                            width: rowRef
                                ? rowRef.current?.offsetWidth /
                                      noOfParticipants -
                                  30
                                : 0,
                            borderColor:
                                studentAccess[i] &&
                                currentPanel.activity_settings.turn_based
                                    ? studentColors[i]
                                    : "#707070",
                        }}
                        onClick={() => {
                            if (currentPanel.activity_settings.turn_based)
                                handleStudentAccess(studentParticipantIds[i]);
                        }}
                    >
                        <Participant>{Participants[i]}</Participant>
                    </div>
                )}
            </Col>
        );
    }

    if (!showModal) {
    }

    return (
        <div className={styles.assets}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            {showModal && (
                <>
                    <GameInitModal
                        showModal={showModal}
                        isIndividual={isIndividual}
                        handleGame1Start={() => {
                            setShowModal(false);
                        }}
                        imageTypes={currentPanel.activity_settings.image_type}
                        handleTotalImages={handleTotalImages}
                        handleImageTypes={handleImageTypes}
                        totalImages={currentPanel.activity_settings.total_image}
                        turnBased={currentPanel.activity_settings.turn_based}
                        handleTurnBased={handleTurnBased}
                        handleTimer={handleTimer}
                        handleScoreboard={handleScoreboard}
                        currentPanel={currentPanel}
                        preview={false}
                    />
                </>
            )}
            <Game
                sessionID={sessionID}
                socket={socket}
                participants={participants}
                room={room}
                currentPanel={currentPanel}
                status={status}
                session={session}
                setSession={setSession}
                userName={"teacher"}
                updateSession={updateSession}
                renderGame={!showModal}
                setShowModal={setShowModal}
            />
            <div className={`${styles.block} ${styles.blockMedia}`}>
                <Participant>{Teacher}</Participant>
            </div>
            <div className={styles.rowDiv}>
                <Row style={{ justifyContent: "center" }} ref={rowRef}>
                    {participantBoxes}
                </Row>
            </div>

            <StudentSpotlight
                selectedStudentToFocus={selectedStudentToFocus}
                Participants={Participants}
                closeModal={() => {
                    setSelectedStudentToFocus(-1);
                }}
            />
        </div>
    );
};
