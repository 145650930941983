import React, { useEffect, useState } from "react";
import Snackbar from "../../../components/Snackbar";
import Navbar from "../../../components/Navbar";
import Classes from "../Classes";
import Carousel from "../components/Carousel";
import styles from "./index.module.scss";
import InstagramCarousel from "../InstagramCarousel";
import _ from "lodash";
import Badge from "../components/Badge";
import ParentReview from "../components/ParentReview";
import TeacherCarousel from "../../../components/Carousels/TeacherCarousel";
import TeacherMsg from "../components/TeacherMsg";
import FooterNew from "../../../components/FooterNew";

const GuestLanding = () => {
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackBarStatus, setSnackBarStatus] = useState("error");

    let userToken = localStorage.getItem("auth");
    let isSuperAdmin = false;

    if (userToken) {
        userToken = JSON.parse(userToken);
        isSuperAdmin = _.get(userToken, "user.isSuperAdmin", false);
    }
    useEffect(() => {
        window.scroll(0, 0);
        // eslint-disable-next-line
    }, []);
    return (
        <>
            <Navbar />
            <div className={styles.mainContainer}>
                {msg && (
                    <Snackbar
                        status={snackBarStatus}
                        message={msg}
                        open={showSnackbar}
                        setOpen={setShowSnackbar}
                    />
                )}

                <div className={styles.pageContainer}>
                    <div className={styles.contentContainer}>
                        <div className={styles.carouselContainer}>
                            <Carousel
                                setMsg={setMsg}
                                setShowSnackbar={setShowSnackbar}
                            />
                        </div>

                        {/* {isSuperAdmin ? null : (
                        <Classes
                            title="Recommended for you"
                            setMsg={setMsg}
                            setShowSnackbar={setShowSnackbar}
                        />
                    )} */}
                        <Classes
                            title="Explore our upcoming sessions"
                            setMsg={setMsg}
                            setShowSnackbar={setShowSnackbar}
                        />
                        <ParentReview page={"home"} />
                        {isSuperAdmin ? null : (
                            // <TeachersNew
                            //     setMsg={setMsg}
                            //     setShowSnackbar={setShowSnackbar}
                            // />
                            <>
                                <TeacherCarousel title="Explore our team of experienced facilitators" />
                            </>
                        )}
                        <TeacherMsg />
                        <InstagramCarousel />
                        <Badge
                            setMsg={setMsg}
                            setShowSnackbar={setShowSnackbar}
                            setSnackBarStatus={setSnackBarStatus}
                        />
                    </div>

                    {/* <Footer /> */}
                    <FooterNew />
                </div>
            </div>
        </>
    );
};

export default GuestLanding;
