import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import Hamburger from "../../assets/images/HamburgerMenu.svg";
import Setting from "../../assets/images/icon/Settings.svg";
import SettingsText from "../../assets/images/icon/SettingsText.svg";
import BigLogo from "../../assets/images/LOGO (3).svg";
import CoinSvg from "../../assets/images/Plasscoin.svg";
import styles from "./index.module.scss";
import Store from "../../assets/images/icon/Store.svg";
import StoreText from "../../assets/images/icon/StoreText.svg";
import ChatIcon from "../../assets/images/chat/chat.svg";
import ChatText from "../../assets/images/chat/Chat word.svg";
import DashboardText from "../../assets/images/icon/dashboardText.svg";
import Dashboard from "../../assets/images/Dashboard@2x.png";
import Play from "../../assets/images/play.png";
import axios from "axios";

const NavbarLanding = ({
    screen,
    userRole,
    unreadMessageCount,
    setShowDashboardModal,
    userName,
    unSaveChanges,
}) => {
    const history = useHistory();
    const location = useLocation();
    const ref = useRef(null);
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const [plassCoins, setPlassCoins] = useState(0);

    useEffect(() => {
        if (localStorage.getItem("auth")) {
            setIsUserAuthenticated(true);
            if (JSON.parse(localStorage.getItem("auth"))?.user?.role) {
                fetchPlanDetails();
            }
        }
        // eslint-disable-next-line
    }, []);

    // FETCHING STUDENT PLANN DETAILS
    const fetchPlanDetails = async () => {
        await axios
            .get("/api/v1/user/plan")
            .then((response) => {
                if (response?.data?.success) {
                    setPlassCoins(response?.data?.data?.totalRemainingMinutes);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const logoutFunc = () => {
        localStorage.clear();
        window.location.href = "/login";
    };

    const handleFunc = (path) => {
        if (!!unSaveChanges) {
            unSaveChanges(path);
        } else {
            path == "logout" ? logoutFunc() : history.push(path);
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                // alert("You clicked outside of me!");
                setShowHamburgerMenu(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line
    }, [ref]);

    return (
        <div className={styles.navbarPageContainer}>
            <div className={styles.navbarContainer}>
                <div
                    className={styles.logoContainer}
                    onClick={() => handleFunc("/home")}
                >
                    <img
                        src={BigLogo}
                        className={styles.studentLogoBG}
                        alt="Logo"
                    />
                    <h1 className={styles.logoText}>
                        plassroom<sup>+</sup>
                    </h1>
                    {userRole === "teacher" && (
                        <p className={styles.logoSubText}>Teacher</p>
                    )}
                </div>
                <div className={styles.navbarLinks}>
                    {!!userName && (
                        <span className={styles.welcomeText}>{userName}</span>
                    )}

                    {/* plass icon */}
                    {isUserAuthenticated && userRole === "student" && (
                        <p className={`${styles.balance} ${styles.navbarLink}`}>
                            <img src={CoinSvg} /> <span>{plassCoins}</span>
                        </p>
                    )}
                    {isUserAuthenticated &&
                    location.pathname === "/accountsettings" ? (
                        <img
                            src={Play}
                            className={`${styles.letsPlay} ${styles.navbarLink}`}
                            onClick={() => history.push("/")}
                            alt={"Logo"}
                        />
                    ) : null}
                    {/* chat icon */}
                    <div className={`${styles.navLink} ${styles.navbarLink}`}>
                        <img
                            id="ChatText"
                            src={ChatText}
                            className={styles.hoverText}
                            alt="Chat"
                            style={{
                                visibility: "hidden",
                            }}
                        />
                        <span
                            onMouseOver={() =>
                                (document.querySelector(
                                    "#ChatText"
                                ).style.visibility = "visible")
                            }
                            onClick={() => handleFunc("/chat")}
                            onMouseLeave={() =>
                                (document.querySelector(
                                    "#ChatText"
                                ).style.visibility = "hidden")
                            }
                            style={{
                                position: "relative",
                            }}
                        >
                            <img
                                src={ChatIcon}
                                className={styles.dashboardIcon}
                                alt="Chat"
                            />
                            {unreadMessageCount ? (
                                <span className={styles.unreadChatCount}>
                                    {unreadMessageCount}
                                </span>
                            ) : null}
                        </span>
                    </div>
                    {/* store icon */}
                    <div className={`${styles.navLink} ${styles.navbarLink} `}>
                        <img
                            id="StoreText"
                            src={StoreText}
                            className={styles.hoverText}
                            style={{
                                visibility: "hidden",
                            }}
                        ></img>
                        <img
                            onMouseOver={() =>
                                (document.querySelector(
                                    "#StoreText"
                                ).style.visibility = "visible")
                            }
                            onMouseLeave={() =>
                                (document.querySelector(
                                    "#StoreText"
                                ).style.visibility = "hidden")
                            }
                            src={Store}
                            className={styles.dashboardIcon}
                            onClick={() => handleFunc("/home")}
                            alt="Store"
                        ></img>
                    </div>
                    {/* settings icon */}
                    <div className={`${styles.navLink} ${styles.navbarLink}`}>
                        <img
                            id="SettingsText"
                            src={SettingsText}
                            className={styles.hoverText}
                            style={{
                                visibility: "hidden",
                            }}
                        ></img>
                        <img
                            onMouseOver={() =>
                                (document.querySelector(
                                    "#SettingsText"
                                ).style.visibility = "visible")
                            }
                            onMouseLeave={() =>
                                (document.querySelector(
                                    "#SettingsText"
                                ).style.visibility = "hidden")
                            }
                            src={Setting}
                            className={styles.dashboardIcon}
                            alt="settings"
                            onClick={() => handleFunc("/accountsettings")}
                        />
                    </div>
                    {/* dashboard icon */}
                    {userRole === "teacher" ? (
                        <div
                            className={`${styles.navLink} ${styles.navbarLink} `}
                        >
                            <img
                                id="DashboardText"
                                src={DashboardText}
                                className={styles.hoverText}
                                style={{
                                    visibility: "hidden",
                                }}
                            ></img>
                            <img
                                onMouseOver={() =>
                                    (document.querySelector(
                                        "#DashboardText"
                                    ).style.visibility = "visible")
                                }
                                onMouseLeave={() =>
                                    (document.querySelector(
                                        "#DashboardText"
                                    ).style.visibility = "hidden")
                                }
                                src={Dashboard}
                                className={styles.dashboardIcon}
                                onClick={() => {
                                    !!setShowDashboardModal
                                        ? setShowDashboardModal(true)
                                        : handleFunc("/");
                                }}
                                alt="dashboard"
                            ></img>
                        </div>
                    ) : null}

                    <div
                        className={`${styles.navLink} ${styles.navbarLink} ${styles.logout}`}
                        onClick={() => handleFunc("logout")}
                    >
                        Logout
                    </div>

                    <div className={styles.burgerMenu}>
                        {showHamburgerMenu ? (
                            <span>
                                <img
                                    src={Hamburger}
                                    alt=""
                                    className={styles.moreIcon}
                                    onClick={() => setShowHamburgerMenu(false)}
                                />
                            </span>
                        ) : (
                            <img
                                src={Hamburger}
                                alt=""
                                className={styles.moreIcon}
                                onClick={() => setShowHamburgerMenu(true)}
                            />
                        )}
                    </div>
                </div>
            </div>

            {showHamburgerMenu ? (
                <div
                    className={`${styles.burgerMenuList} ${
                        isUserAuthenticated
                            ? styles.loggedInBurgerMenuList
                            : null
                    }`}
                    style={{
                        height: window.innerHeight,
                    }}
                    ref={ref}
                    id="burgerMenu"
                >
                    {isUserAuthenticated ? (
                        <>
                            {userRole === "student" && (
                                <p className={styles.balance}>
                                    <img src={CoinSvg} />{" "}
                                    <span>{plassCoins}</span>
                                </p>
                            )}
                            {isUserAuthenticated &&
                            location.pathname === "/accountsettings" ? (
                                <img
                                    src={Play}
                                    className={`${styles.letsPlay}`}
                                    onClick={() => history.push("/")}
                                    alt={"Logo"}
                                />
                            ) : null}
                            <div
                                className={`${styles.navLink} ${
                                    location.pathname === "/accountsettings"
                                        ? styles.activeLink
                                        : ""
                                }`}
                                onClick={() => handleFunc("/accountsettings")}
                            >
                                Settings{" "}
                                <img
                                    src={Setting}
                                    className={styles.dashboardIcon}
                                    alt="settings"
                                />
                            </div>
                            <div
                                className={`${styles.navLink} ${
                                    location.pathname === "/chat"
                                        ? styles.activeLink
                                        : ""
                                }`}
                                onClick={() => handleFunc("/chat")}
                            >
                                Chat{" "}
                                <span
                                    style={{
                                        position: "relative",
                                    }}
                                >
                                    <img
                                        src={ChatIcon}
                                        className={styles.dashboardIcon}
                                        alt="Chat"
                                    />
                                    {unreadMessageCount ? (
                                        <span
                                            className={styles.unreadChatCount}
                                        >
                                            {unreadMessageCount}
                                        </span>
                                    ) : null}
                                </span>
                            </div>
                            <div
                                className={`${styles.navLink} ${
                                    location.pathname === "/home"
                                        ? styles.activeLink
                                        : ""
                                }`}
                                onClick={() => handleFunc("/home")}
                            >
                                Store{" "}
                                <img
                                    src={Store}
                                    className={styles.dashboardIcon}
                                    alt="store"
                                />
                            </div>
                            {userRole === "teacher" ? (
                                <div
                                    className={`${styles.navLink}`}
                                    onClick={() => {
                                        !!setShowDashboardModal
                                            ? setShowDashboardModal(true)
                                            : handleFunc("/");
                                    }}
                                >
                                    Dashboard{" "}
                                    <img
                                        src={Dashboard}
                                        className={styles.dashboardIcon}
                                        alt="settings"
                                    />
                                </div>
                            ) : null}
                            <div
                                className={`${styles.logout} ${styles.navLink}`}
                                onClick={() => handleFunc("logout")}
                            >
                                Logout
                            </div>
                        </>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default NavbarLanding;
