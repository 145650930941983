import React from "react";
import styles from "./participant.module.css";

const Participant = (props) => {
    return (
        <div
            className={
                props.multiRow ? styles.multiRowContainer : styles.container
            }
            style={props.style}
        >
            {props.children}
        </div>
    );
};

export default Participant;
