const reviewData = [
    {
        review: "Another great Lego club session! These sessions really sparked my child’s imagination and encouraged his problem solving skills through the weekly theme. He played his Legos for another hour after the class ended, building and describing what he built like how they did in class.",
        class_rate: 5,
        description: "Parent of 7 year old, United States",
    },
    {
        review: "The boys had a splendid time during this fun online circle time designed for little ones. It was very interactive, they were singing and dancing and when we were saying goodbye, the boys were saying 'no, more'. It was easy to follow for all ages and related well to Spring. Thank you.",
        class_rate: 5,
        description: "Parent of 3 and 2 year old, United Kingdom",
    },
    {
        review: "My daughter has always taken to clothing and the fashion design class was great to help inspire her and improve her drawing! She learnt about proportions and colours too. It’s simple things like this that help our kids fall in love with learning and discover their interests.",
        class_rate: 5,
        description: "Parent of 10 year old, Singapore",
    },
    {
        review: "My child is a bit of a picky eater, but by playing with the ingredients she ended up eating what we made after class and eventually made another too. She also learnt some kitchen safety skills and the teacher was so kind and fun too. Could not recommend this class more!",
        class_rate: 5,
        description: "Parent of 4 year old, Dubai",
    },
    {
        review: "My son is autistic and was very nervous before the session as he tends to struggle with new environments and in social settings. The teacher was so kind and not long into the class, he was already chatting comfortably with the teacher and other children about Minecraft which he loves! I will definitely be booking him in again. Thank you so much.",
        class_rate: 5,
        description: "Parent of 7 year old, United Kingdom",
    },
    // { review: "dolor sit amet consectetur adipisicing elit. Nostrum iure sed sint vero dolores.", class_rate: 5 },
    // { review: "maksc, Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nostrum iure sed sint vero dolores.", class_rate: 1 }
];

export default reviewData;
