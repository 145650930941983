import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Snackbar from "../../../components/Snackbar";
import Navbar from "../../../components/Navbar";
import SignupDetail from "../Signup2/index";
import styles from "./signup1.module.scss";

export default () => {
    const history = useHistory();
    const [data, setData] = useState({
        email: "",
        password: "",
    });
    const [marketingMessages, setMarketingMessages] = useState(true);

    const [showDetails, setShowDetails] = useState(false);
    const [socialData, setSocialData] = useState(undefined);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [duration, setDuration] = useState(3000);

    const redirectTo =
        history.location.search.substring(10) || "/plan-subscription";

    useEffect(() => {
        if (localStorage.getItem("auth")) {
            if (
                JSON.parse(localStorage.getItem("auth")).user.role === "admin"
            ) {
                history.replace("/admin");
            } else {
                history.replace("/");
            }
        } else {
            if (history.location.search) {
                // history.push(redirectTo, {
                //     ...stateHistory,
                // });
            }
        }
        // eslint-disable-next-line
    }, []);

    const handleChange = (evt) => {
        const value = evt.target.value;
        setData({
            ...data,
            [evt.target.name]: value,
        });
    };

    const handleKeyPress = (evt) => {
        if (evt.key === "Enter") {
            handleContinue();
        }
    };

    const handleContinue = () => {
        if (data.email && data.password) {
            if (validate()) {
                const payload = {
                    email: data.email,
                    password: data.password,
                };
                axios
                    .post("/api/v1/user/validate/email-password", payload)
                    .then((res) => {
                        if (!res.data.success) {
                            throw res;
                        }

                        localStorage.setItem("plassroom-email", data.email);
                        setShowDetails(true);
                    })
                    .catch((error) => {
                        let message = _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        );
                        if (message === "Something went wrong.") {
                            message = _.get(
                                error,
                                "data.message",
                                "Something went wrong."
                            );
                        }
                        setMsg(message);
                        setShowSnackbar(true);
                        setDuration(8000);
                        setTimeout(function () {
                            // console.log("I am here!!");
                            setMsg(null);
                            setShowDetails(false);
                        }, 8000);
                        return Promise.reject(error);
                    });
            } else {
                setMsg("email is incorrect");
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
            }
        } else {
            setMsg("fill data first");
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        }
    };

    const validate = () => {
        let input = document.getElementById("email");
        let isValid = true;
        if (!input) {
            isValid = false;
        }

        if (typeof input !== "undefined") {
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(input.value)) {
                isValid = false;
            }
        }

        return isValid;
    };

    const handleLogin = () => {
        history.push("/login");
    };

    const handleCheckbox = (e) => {
        setMarketingMessages(!e?.target?.checked);
    };

    return (
        <div className={styles.mainDiv}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                    duration={duration}
                ></Snackbar>
            )}

            <Navbar />

            {!showDetails ? (
                <div className={styles.formWrapper}>
                    <h2 className={styles.heading}>Sign Up</h2>
                    <div className={styles.inputWrapper}>
                        <div>
                            <input
                                className={styles.emailInput}
                                placeholder="Email"
                                type="email"
                                id="email"
                                name="email"
                                value={data.email}
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                                required
                            />
                        </div>
                        <div>
                            <input
                                className={styles.passwordInput}
                                placeholder="Password"
                                type="password"
                                id="pass"
                                name="password"
                                minLength="8"
                                value={data.password}
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                                required
                            />
                        </div>
                    </div>
                    <div className={styles.socialBtn}>
                        <p>or use</p>
                        <p>to signup</p>
                    </div>
                    <p className={styles.loginText}>
                        Already have an account?
                        <span className={styles.login} onClick={handleLogin}>
                            Login
                        </span>
                    </p>
                    <p className={styles.para}>
                        By selecting Agree and Continue below, I agree to
                        Plassroom+’s{" "}
                        <Link to="/termsofservice">Terms of Service</Link> and{" "}
                        <Link to="/privacypolicy">Privacy Policy</Link>.
                    </p>
                    <p className={styles.continueBtn} onClick={handleContinue}>
                        Agree and Continue
                    </p>
                    <p className={styles.para}>
                        Plassroom+ will send you content updates, marketing
                        material and account related emails. You can opt out of
                        receiving these at any time in your account settings or
                        directly from the email.
                    </p>
                    <div className={styles.radiobtn}>
                        <input
                            className={styles.inputCheckbox}
                            type="checkbox"
                            onClick={handleCheckbox}
                        />
                        <p className={styles.para}>
                            I do not want to receive any marketing messages from
                            Plassroom+.
                        </p>
                    </div>
                </div>
            ) : (
                <SignupDetail
                    data={data}
                    socialData={socialData}
                    setShowDetails={setShowDetails}
                    setData={setData}
                    marketingMessages={marketingMessages}
                    verifyLink={redirectTo}
                />
            )}
        </div>
    );
};
