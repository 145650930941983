import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Setting from "../../assets/images/icon/Settings.svg";
import Store from "../../assets/images/icon/Store.svg";
import CoinSvg from "../../assets/images/Plasscoin.svg";
import CarouselSmall from "../../components/Carousels/CarouselSmall";
import ChatForm from "../../components/ChatForm";
import FooterNew from "../../components/FooterNew";
import Loading from "../../components/Loading/Loading";
import Navbar from "../../components/Navbar";
import Snackbar from "../../components/Snackbar";
import styles from "./index.module.scss";

// const navbarMenus = [
//     {
//         icon: Store,
//         link: "/accountsettings",
//         title: "store",
//     },
//     {
//         icon: Setting,
//         link: "/accountsettings",
//         title: "setting",
//     },
// ];

const renderHTML = (rawHTML) =>
    React.createElement("div", {
        dangerouslySetInnerHTML: { __html: rawHTML },
    });

const PaymentSuccess = (props) => {
    const classID = props.match.params.classID;
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [studentDetails, setStudentDetails] = useState({});
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [plassCoins, setPlassCoins] = useState(0);

    const fetchStudentDetails = async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(
                `/api/v1/session/${classID}/register`
            );
            setLoading(false);
            if (data?.success) {
                setStudentDetails(data?.data);
            } else {
                history.push(`/class-details/${classID}`);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);

            setMsg(
                _.get(error, "response.data.title", "Something went wrong.")
            );
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
                history.push(`/class-details/${classID}`);
            }, 3000);
        }
    };

    // FETCHING STUDENT PLANN DETAILS
    const fetchPlanDetails = async () => {
        setLoading(true);
        await axios
            .get("/api/v1/user/plan")
            .then((response) => {
                if (response?.data?.success) {
                    setPlassCoins(response?.data?.data?.totalRemainingMinutes);
                } else {
                    setLoading(false);
                    setMsg(
                        _.get(
                            response,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setLoading(false);
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    useEffect(() => {
        fetchStudentDetails();
        fetchPlanDetails();
        // eslint-disable-next-line
    }, []);

    return (
        <div className={styles.mainContainer}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <Navbar />

            <div className={styles.pageContainer}>
                <div className={styles.contentContainer}>
                    {loading ? (
                        <Loading />
                    ) : (
                        <article className={styles.classArticle}>
                            <section className={styles.classBody}>
                                <section className={styles.sectionLeft}>
                                    <h1>
                                        You have successfully booked{" "}
                                        {studentDetails?.studentPreferedName
                                            ? studentDetails?.studentPreferedName
                                            : studentDetails?.studentName ||
                                              "-"}{" "}
                                        into{" "}
                                        {studentDetails?.session?.class_name ||
                                            "-"}
                                        .
                                    </h1>

                                    <p>Class description:</p>
                                    <p>
                                        {renderHTML(
                                            studentDetails?.session
                                                ?.class_description || "-"
                                        )}
                                    </p>
                                    <p>
                                        Current balance <img src={CoinSvg} />{" "}
                                        {plassCoins}
                                    </p>
                                </section>

                                <section className={styles.sectionRight}>
                                    <figure>
                                        <CarouselSmall
                                            carouselImgs={
                                                studentDetails?.session
                                                    ?.image_ids || []
                                            }
                                            autoPlay={true}
                                        />
                                    </figure>
                                </section>
                            </section>

                            <p>
                                You will also be receiving the above information
                                and your payment receipt in your email. If you’d
                                like to cancel your booking, you can do that via
                                the calendar on your Plassroom homepage.
                            </p>

                            <div className={styles.classInfo}>
                                <div className={styles.classTiming}>
                                    <p>
                                        {studentDetails?.session?.configuration
                                            ?.session_start_time
                                            ? moment(
                                                  studentDetails?.session
                                                      ?.configuration
                                                      ?.session_start_time
                                              ).format("Do MMM YYYY,dddd")
                                            : "-"}
                                    </p>
                                    <p>
                                        {studentDetails?.session?.configuration
                                            ?.session_start_time
                                            ? moment(
                                                  studentDetails?.session
                                                      ?.configuration
                                                      ?.session_start_time
                                              ).format("h:mm A")
                                            : "-"}
                                        {` ${moment
                                            .tz(moment.tz.guess())
                                            .zoneAbbr()} `}
                                        (
                                        {studentDetails?.session?.configuration
                                            ?.session_duration || "-"}
                                        minutes)
                                    </p>
                                </div>
                                <div className={styles.classStudents}>
                                    <p>
                                        Students age
                                        {` ${
                                            studentDetails?.session
                                                ?.configuration?.age_group ||
                                            "-"
                                        } `}
                                        years
                                    </p>
                                    <p>
                                        {` ${
                                            studentDetails?.session
                                                ?.configuration?.max_students ||
                                            "-"
                                        } `}
                                        students maximum per class
                                    </p>
                                </div>
                            </div>

                            <h1 style={{ color: "#88ADDB" }}>
                                See you at Plassroom+!
                            </h1>
                        </article>
                    )}
                </div>

                <FooterNew />
            </div>

            {studentDetails ? (
                <ChatForm classDetails={studentDetails.session} />
            ) : null}
        </div>
    );
};

export default PaymentSuccess;
