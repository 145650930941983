import {
    faGreaterThan,
    faLessThan,
    faMinus,
    faPlus,
    faTimes,
    faGripHorizontal,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "easymde/dist/easymde.min.css";
import _ from "lodash";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col, Row } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-markdown-editor-lite/lib/index.css";
import CustomDropdown from "../../../components/CustomDropdown";
import styles from "./create.module.css";
import { lessonData } from "../../../utils/constants";
import { useHistory } from "react-router-dom";

const Panels = (props) => {
    let {
        rightPanelSettings,
        panelSlotId,
        onDragEnd,
        disableInputs,
        show,
        sessions,
        setShow,
        sessionID,
        setRightPanelIndex,
        removeLessionBlock,
        selectClass,
        sessionData,
        importModal,
        handleImportModal,
        handleImportOption,
        handleRightPanelSetting,
        addLessionBlock,
        imgModalFlag,
        increaseImgModalFlag,
        decreaseImgModalFlag,
        handleSelectClass,
        updateRightPanelSettings,
    } = props;

    const navigate = useHistory();

    const handleData = (type) => {
        if (type === "VIDEO") {
            return "Video";
        } else if (type === "LESSON") {
            return "Lesson";
        } else if (type === "ACTIVITY") {
            return "Activity";
        }
    };

    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            className={`${styles.scroll} ${styles.content}`}
                            {...provided.droppableProps}
                        >
                            {rightPanelSettings.map((item, index) => (
                                <Draggable
                                    key={index}
                                    draggableId={`${index}`}
                                    index={index}
                                >
                                    {(provided) => (
                                        <div
                                            key={index}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            id="div"
                                        >
                                            <div
                                                style={{
                                                    display: "inline-block",
                                                    marginRight: "40px",
                                                    height: "100%",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <div
                                                    className={`${
                                                        styles.classblock
                                                    } ${
                                                        item.isConfigurationCompleted
                                                            ? null
                                                            : styles.incompleteClass
                                                    }`}
                                                    onClick={(event) => {
                                                        if (!disableInputs) {
                                                            event.preventDefault();
                                                            if (
                                                                event.target
                                                                    .id ==
                                                                    "downarrow" ||
                                                                event.target
                                                                    .parentElement
                                                                    .id ==
                                                                    "downarrow" ||
                                                                document.getElementById(
                                                                    "dropdown"
                                                                ) ||
                                                                show != null
                                                            ) {
                                                            } else {
                                                                if (
                                                                    item.type ===
                                                                    "LESSON"
                                                                ) {
                                                                    if (
                                                                        rightPanelSettings[
                                                                            index
                                                                        ]
                                                                            .subType ===
                                                                        "ASSET"
                                                                    ) {
                                                                        if (
                                                                            rightPanelSettings[
                                                                                index
                                                                            ]
                                                                                .lesson_settings
                                                                                .assets
                                                                                .view
                                                                                ?.student &&
                                                                            rightPanelSettings[
                                                                                index
                                                                            ]
                                                                                .lesson_settings
                                                                                .assets
                                                                                .view
                                                                                ?.teacher
                                                                        ) {
                                                                            navigate.push(
                                                                                `/session/${sessionID}/asset/media?panelIndex=${index}`
                                                                            );
                                                                        } else {
                                                                            navigate.push(
                                                                                `/session/${sessionID}/asset/view?panelIndex=${index}`
                                                                            );
                                                                        }
                                                                    } else {
                                                                        if (
                                                                            rightPanelSettings[
                                                                                index
                                                                            ]
                                                                                .subType ===
                                                                            "WHITEBOARD"
                                                                        ) {
                                                                            if (
                                                                                rightPanelSettings[
                                                                                    index
                                                                                ]
                                                                                    .lesson_settings
                                                                                    .whiteboard
                                                                                    ?.student
                                                                                    ?.view &&
                                                                                rightPanelSettings[
                                                                                    index
                                                                                ]
                                                                                    .lesson_settings
                                                                                    .whiteboard
                                                                                    ?.teacher
                                                                                    ?.view
                                                                            ) {
                                                                                navigate.push(
                                                                                    `/session/${sessionID}/whiteboard/media?panelIndex=${index}`
                                                                                );
                                                                            } else if (
                                                                                rightPanelSettings[
                                                                                    index
                                                                                ]
                                                                                    .lesson_settings
                                                                                    .whiteboard
                                                                            ) {
                                                                                navigate.push(
                                                                                    `/session/${sessionID}/whiteboard/view?panelIndex=${index}`
                                                                                );
                                                                            } else {
                                                                                navigate.push(
                                                                                    `/session/${sessionID}/whiteboard/setting?panelIndex=${index}`
                                                                                );
                                                                            }
                                                                        } else {
                                                                            navigate.push(
                                                                                `/session/${sessionID}/tool?panelIndex=${index}`
                                                                            );
                                                                        }
                                                                    }
                                                                } else if (
                                                                    item.type ===
                                                                    "VIDEO"
                                                                ) {
                                                                    if (
                                                                        !item.video_settings
                                                                    ) {
                                                                        navigate.push(
                                                                            `/session/${sessionID}/viewTool?panelIndex=${index}`
                                                                        );
                                                                    } else if (
                                                                        item.subType ===
                                                                        "SCREENSHARE"
                                                                    ) {
                                                                        navigate.push(
                                                                            `/session/${sessionID}/screenshare?panelIndex=${index}`
                                                                        );
                                                                    } else {
                                                                        navigate.push(
                                                                            `/session/${sessionID}/view?panelIndex=${index}`
                                                                        );
                                                                    }
                                                                } else if (
                                                                    item.type ===
                                                                    "ACTIVITY"
                                                                ) {
                                                                    if (
                                                                        rightPanelSettings[
                                                                            index
                                                                        ]
                                                                            .subType
                                                                    ) {
                                                                        navigate.push(
                                                                            `/session/${sessionID}/activity/customise?panelIndex=${index}`
                                                                        );
                                                                    } else {
                                                                        navigate.push(
                                                                            `/session/${sessionID}/activity/list?panelIndex=${index}`
                                                                        );
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <span
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <FontAwesomeIcon
                                                            className={
                                                                styles.dragIcon
                                                            }
                                                            icon={
                                                                faGripHorizontal
                                                            }
                                                        />
                                                    </span>

                                                    <FontAwesomeIcon
                                                        className={
                                                            styles.minusicon
                                                        }
                                                        style={{
                                                            width: "30px",
                                                            position:
                                                                "absolute",
                                                            left: 0,
                                                        }}
                                                        icon={faPlus}
                                                        onClick={(ev) => {
                                                            if (
                                                                !disableInputs
                                                            ) {
                                                                ev.stopPropagation();
                                                                if (
                                                                    show !==
                                                                    undefined
                                                                ) {
                                                                    setShow(
                                                                        undefined
                                                                    );
                                                                } else {
                                                                    setShow(
                                                                        index
                                                                    );
                                                                    setRightPanelIndex(
                                                                        index
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    {show === index && (
                                                        <div
                                                            className={
                                                                styles.option
                                                            }
                                                        >
                                                            <p
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    if (
                                                                        !disableInputs
                                                                    ) {
                                                                        event.preventDefault();
                                                                        handleImportOption(
                                                                            index
                                                                        );
                                                                        setShow(
                                                                            undefined
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                Use Existing
                                                            </p>
                                                        </div>
                                                    )}
                                                    <FontAwesomeIcon
                                                        className={
                                                            styles.minusicon
                                                        }
                                                        style={{
                                                            width: "30px",
                                                            position:
                                                                "absolute",
                                                            right: 0,
                                                        }}
                                                        icon={faMinus}
                                                        onClick={(e) => {
                                                            if (
                                                                !disableInputs
                                                            ) {
                                                                e.stopPropagation();
                                                                removeLessionBlock(
                                                                    index
                                                                );
                                                            }
                                                        }}
                                                    />
                                                    <div
                                                        className={
                                                            styles.customDropContainer
                                                        }
                                                    >
                                                        <CustomDropdown
                                                            dropdownData={
                                                                lessonData
                                                            }
                                                            width="100%"
                                                            margin="auto"
                                                            isPublished={
                                                                disableInputs
                                                            }
                                                            setData={(
                                                                payload
                                                            ) => {
                                                                updateRightPanelSettings(
                                                                    index,
                                                                    payload.toUpperCase(),
                                                                    payload,
                                                                    () => {
                                                                        const param =
                                                                            !!panelSlotId
                                                                                ? `&slotId=${panelSlotId}`
                                                                                : ``;
                                                                        if (
                                                                            payload ===
                                                                            "Lesson"
                                                                        ) {
                                                                            navigate.push(
                                                                                `/session/${sessionID}/tool?panelIndex=${index}${param}`
                                                                            );
                                                                        } else if (
                                                                            payload ===
                                                                            "Video"
                                                                        ) {
                                                                            navigate.push(
                                                                                `/session/${sessionID}/viewTool?panelIndex=${index}${param}`
                                                                            );
                                                                        } else if (
                                                                            payload ===
                                                                            "Activity"
                                                                        ) {
                                                                            navigate.push(
                                                                                `/session/${sessionID}/activity/list?panelIndex=${index}${param}`
                                                                            );
                                                                        }
                                                                    }
                                                                );
                                                            }}
                                                            sessionID={
                                                                sessionID
                                                            }
                                                            data={
                                                                item.label
                                                                    ? `${item.label}`
                                                                    : handleData(
                                                                          item.type
                                                                      )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {importModal && (
                                                    <div
                                                        className={
                                                            styles.importModal
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            className={
                                                                styles.crossIcon
                                                            }
                                                            icon={faTimes}
                                                            onClick={
                                                                disableInputs
                                                                    ? null
                                                                    : handleImportModal
                                                            }
                                                        />
                                                        <p
                                                            className={
                                                                styles.classModalText
                                                            }
                                                        >
                                                            Pick the class you
                                                            would like to access
                                                        </p>
                                                        <div
                                                            className={
                                                                styles.modalClassWrapper
                                                            }
                                                        >
                                                            {selectClass ? (
                                                                <div
                                                                    className={
                                                                        styles.modalClassScroll
                                                                    }
                                                                >
                                                                    <Row>
                                                                        <Col>
                                                                            <div
                                                                                className={
                                                                                    styles.modalClassBlock
                                                                                }
                                                                            >
                                                                                <img
                                                                                    className={
                                                                                        styles.modalCoverImage
                                                                                    }
                                                                                    src={
                                                                                        sessionData
                                                                                            .image_ids[0] &&
                                                                                        sessionData
                                                                                            .image_ids[0]
                                                                                            .location
                                                                                    }
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <p
                                                                                className={
                                                                                    styles.modalClassName
                                                                                }
                                                                            >
                                                                                {
                                                                                    sessionData.class_name
                                                                                }
                                                                            </p>
                                                                        </Col>
                                                                        <Col></Col>
                                                                        <Col></Col>
                                                                    </Row>
                                                                    <div
                                                                        className={
                                                                            styles.modalLessonWrapper
                                                                        }
                                                                    >
                                                                        {rightPanelSettings.map(
                                                                            (
                                                                                obj,
                                                                                index
                                                                            ) => {
                                                                                return (
                                                                                    <div
                                                                                        className={
                                                                                            styles.modalLesson
                                                                                        }
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                !disableInputs
                                                                                            ) {
                                                                                                handleRightPanelSetting(
                                                                                                    index
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <p
                                                                                            className={
                                                                                                styles.modalLessonName
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                obj.type
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className={
                                                                        styles.modalClassScroll
                                                                    }
                                                                >
                                                                    <Row>
                                                                        {sessions.map(
                                                                            (
                                                                                obj,
                                                                                index
                                                                            ) => {
                                                                                return (
                                                                                    <Col
                                                                                        md={
                                                                                            4
                                                                                        }
                                                                                    >
                                                                                        <div
                                                                                            className={
                                                                                                styles.modalClassBlock
                                                                                            }
                                                                                            onClick={(
                                                                                                event
                                                                                            ) => {
                                                                                                if (
                                                                                                    !disableInputs
                                                                                                ) {
                                                                                                    handleSelectClass(
                                                                                                        event,
                                                                                                        index
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            {obj
                                                                                                .image_ids
                                                                                                .length >=
                                                                                                1 && (
                                                                                                <div
                                                                                                    style={{
                                                                                                        position:
                                                                                                            "relative",
                                                                                                        height: "100%",
                                                                                                    }}
                                                                                                >
                                                                                                    {obj
                                                                                                        .image_ids
                                                                                                        .length >
                                                                                                        1 && (
                                                                                                        <>
                                                                                                            <FontAwesomeIcon
                                                                                                                style={{
                                                                                                                    color: "#83838b",
                                                                                                                    width: "30px",
                                                                                                                    position:
                                                                                                                        "absolute",

                                                                                                                    left: "-7px",
                                                                                                                    marginTop:
                                                                                                                        "10px",
                                                                                                                    zIndex: "8",
                                                                                                                    top: "20px",
                                                                                                                    cursor: "pointer",
                                                                                                                }}
                                                                                                                icon={
                                                                                                                    faLessThan
                                                                                                                }
                                                                                                                onClick={(
                                                                                                                    event
                                                                                                                ) => {
                                                                                                                    if (
                                                                                                                        !disableInputs
                                                                                                                    ) {
                                                                                                                        event.preventDefault();
                                                                                                                        event.stopPropagation();
                                                                                                                        decreaseImgModalFlag(
                                                                                                                            obj
                                                                                                                                .image_ids
                                                                                                                                .length
                                                                                                                        );
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                            <FontAwesomeIcon
                                                                                                                style={{
                                                                                                                    color: "#83838b",
                                                                                                                    width: "30px",
                                                                                                                    position:
                                                                                                                        "absolute",
                                                                                                                    right: "-7px",
                                                                                                                    marginTop:
                                                                                                                        "10px",
                                                                                                                    zIndex: "8",
                                                                                                                    top: "20px",
                                                                                                                    cursor: "pointer",
                                                                                                                }}
                                                                                                                icon={
                                                                                                                    faGreaterThan
                                                                                                                }
                                                                                                                onClick={(
                                                                                                                    event
                                                                                                                ) => {
                                                                                                                    if (
                                                                                                                        !disableInputs
                                                                                                                    ) {
                                                                                                                        event.preventDefault();
                                                                                                                        event.stopPropagation();
                                                                                                                        increaseImgModalFlag(
                                                                                                                            obj
                                                                                                                                .image_ids
                                                                                                                                .length
                                                                                                                        );
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                        </>
                                                                                                    )}
                                                                                                    {obj.image_ids.map(
                                                                                                        (
                                                                                                            element,
                                                                                                            index
                                                                                                        ) => {
                                                                                                            const fileType =
                                                                                                                element.location
                                                                                                                    .split(
                                                                                                                        "."
                                                                                                                    )
                                                                                                                    .pop();
                                                                                                            return (
                                                                                                                <>
                                                                                                                    {fileType ===
                                                                                                                    "mp4"
                                                                                                                        ? imgModalFlag ===
                                                                                                                              index && (
                                                                                                                              <video
                                                                                                                                  className={
                                                                                                                                      styles.modalCoverImage
                                                                                                                                  }
                                                                                                                                  src={
                                                                                                                                      element.location
                                                                                                                                  }
                                                                                                                                  controls
                                                                                                                              ></video>
                                                                                                                          )
                                                                                                                        : imgModalFlag ===
                                                                                                                              index && (
                                                                                                                              <img
                                                                                                                                  className={
                                                                                                                                      styles.modalCoverImage
                                                                                                                                  }
                                                                                                                                  src={
                                                                                                                                      element.location
                                                                                                                                  }
                                                                                                                                  alt=""
                                                                                                                              />
                                                                                                                          )}
                                                                                                                </>
                                                                                                            );
                                                                                                        }
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        <p
                                                                                            className={
                                                                                                styles.modalClassName
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                obj.class_name
                                                                                            }
                                                                                        </p>
                                                                                    </Col>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </Row>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <div className={styles.plusiconContainer}>
                <FontAwesomeIcon
                    className={styles.plusicon}
                    style={{
                        width: "30px",
                    }}
                    icon={faPlus}
                    onClick={!disableInputs ? addLessionBlock : null}
                />
            </div>
        </div>
    );
};

export default Panels;
