import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import Circle from "../../../../../../assets/images/circle.svg";
import CircleSelected from "../../../../../../assets/images/circle_selected.svg";
import Eraser from "../../../../../../assets/images/eraser.svg";
import Large from "../../../../../../assets/images/large.svg";
import LargeSelected from "../../../../../../assets/images/large_selected.svg";
import Line from "../../../../../../assets/images/line.svg";
import LineSelected from "../../../../../../assets/images/line_selected.svg";
import Medium from "../../../../../../assets/images/medium.svg";
import MediumSelected from "../../../../../../assets/images/medium_selected.svg";
import Rectangle from "../../../../../../assets/images/rectangle.svg";
import RectangleSelected from "../../../../../../assets/images/rectangle_selected.svg";
import Small from "../../../../../../assets/images/small.svg";
import SmallSelected from "../../../../../../assets/images/small_selected.svg";
import Triangle from "../../../../../../assets/images/triangle.svg";
import TriangleSelected from "../../../../../../assets/images/triangle_selected.svg";
import Snackbar from "../../../../../../components/Snackbar";
import Layout1 from "../Video/Teacher/Layout1";
import Layout2 from "../Video/Teacher/Layout2";
import Layout3 from "../Video/Teacher/Layout3";
import Settings from "../WhiteboardSetting//index";
import styles from "./WhiteboardByNirajan.module.scss";

const WhiteboardNirajan = ({
    session,
    selectedMedia,
    position,
    fitToScreen,
    setFitToScreen,
    control,
    panelID,
    teacherView,
    requiredWB,
    orientation,
    studentView,
    setPosition,
}) => {
    const [tooltype, setTooltype] = useState("draw");
    const [lineWidth, setLineWidth] = useState("2");
    const [color, setColor] = useState("#333232");
    const [option, setOption] = useState(false);
    const [settings, setSettings] = useState(false);
    const [up, setUp] = useState(0);
    const [left, setLeft] = useState(0);
    const [teacherName, setTeacherName] = useState("");
    const [colorType, setColorType] = useState("all");
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [visibleControl, setVisibleControl] = useState(false);
    const [penColor, setPenColor] = useState();
    const [newPosition, setNewPosition] = useState(position);
    const canvasRef = useRef();
    const shapeRef = useRef();
    const wrapperRef = useRef();
    var canvas = canvasRef.current;
    var shapeCanvas = shapeRef.current;
    const drawOrder = useRef([]);
    const mousedown = useRef(undefined);

    useEffect(() => {
        const getUserDetails = async () => {
            await axios
                .get("/api/v1/user")
                .then((result) => {
                    let resTeacherName =
                        result.data.user?.preferedName || "Teacher";
                    if (resTeacherName.length > 7) {
                        resTeacherName = resTeacherName.slice(0, 7) + "...";
                    }
                    setTeacherName(resTeacherName);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        getUserDetails();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (position) {
            setLeft(position.left);
            setUp(position.up);
        }
        // eslint-disable-next-line
    }, [position]);

    if (canvas) {
        var width = wrapperRef.current.clientWidth;
        var height = wrapperRef.current.offsetHeight;
        var ctx = canvas.getContext("2d");
        var last_mousex;
        var last_mousey;
        var mousex;
        var mousey;
        var operation;
        var coordinates;
    }

    if (shapeCanvas) {
        var shapeCtx = shapeCanvas.getContext("2d");
        var shapeCanvasx = document
            .getElementById("myCanvasForShape")
            .getBoundingClientRect().x;
        var shapeCanvasy = document
            .getElementById("myCanvasForShape")
            .getBoundingClientRect().y;
    }

    const onMouseDown = (e) => {
        e.preventDefault();
        var textobject = document.getElementsByClassName("info");
        if (tooltype === "text" && !textobject.length) {
            var textarea = document.createElement("textarea");
            textarea.className = "info";
            document.getElementById("canvaswrapper").appendChild(textarea);
            var textobject = document.getElementsByClassName("info");
            textarea.style.left = `${e.clientX}px`;
            textarea.style.top = `${e.clientY}px`;
        } else if (textobject.length) {
            var canvas = document.getElementById("drawingCanvas");
            var ctx = canvas.getContext("2d");
            ctx.globalCompositeOperation = "source-over";
            ctx.font = "30px Arial";
            ctx.fillStyle = color;
            var value = textobject[0].value;
            ctx.fillText(
                value,
                textobject[0].offsetLeft - shapeCanvasx,
                textobject[0].offsetTop + shapeCanvasy
            );
        }
        if (requiredWB) {
            last_mousex = e.clientX || e.touches[0].clientX;
            last_mousey = e.clientY || e.touches[0].clientY;
            last_mousex = last_mousex - shapeCanvasx;
            last_mousey = last_mousey - shapeCanvasy;
            mousedown.current = true;
        }
    };

    const drawshape = (
        last_mousex,
        last_mousey,
        mousex,
        mousey,
        color,
        line_width,
        operation,
        tooltype
    ) => {
        drawOrder.current = [
            ...drawOrder.current,
            {
                marking_time_in_UTC: moment.utc(),
                draw_data: [
                    last_mousex,
                    last_mousey,
                    mousex,
                    mousey,
                    color,
                    line_width,
                    operation,
                    tooltype,
                ],
            },
        ];
    };

    const drawtriangle = (
        coordinates,
        color,
        lineWidth,
        operation,
        tooltype
    ) => {
        drawOrder.current = [
            ...drawOrder.current,
            {
                marking_time_in_UTC: moment.utc(),
                draw_data: [coordinates, color, lineWidth, operation, tooltype],
            },
        ];
    };

    const drawLine = (
        last_mousex,
        last_mousey,
        mousex,
        mousey,
        color,
        line_width,
        operation,
        tooltype
    ) => {
        drawOrder.current = [
            ...drawOrder.current,
            {
                marking_time_in_UTC: moment.utc(),
                draw_data: [
                    last_mousex,
                    last_mousey,
                    mousex,
                    mousey,
                    color,
                    line_width,
                    operation,
                    tooltype,
                ],
            },
        ];

        ctx.globalCompositeOperation = operation;
        ctx.lineWidth = line_width;
        if (operation === "source-over") {
            ctx.strokeStyle = color;
        }
        if (orientation === "bottom") {
            ctx.moveTo(last_mousex, last_mousey - shapeCanvas.height);
            ctx.lineTo(mousex, mousey - shapeCanvas.height);
        } else if (orientation === "right") {
            ctx.moveTo(last_mousex - width, last_mousey);
            ctx.lineTo(mousex - width, mousey);
        } else {
            ctx.moveTo(last_mousex, last_mousey);
            ctx.lineTo(mousex, mousey);
        }

        ctx.lineJoin = ctx.lineCap = "round";
        ctx.stroke();
    };

    const onStudentColor = (data) => {
        setPenColor(data.color);
    };

    const onMouseUp = (e) => {
        e.preventDefault();
        mousedown.current = false;
        shapeCtx.clearRect(0, 0, shapeCanvas.width, shapeCanvas.height);
        if (tooltype === "draw") {
            // setLastMouseUpTime(moment.utc());
        } else if (tooltype === "rectangle" && last_mousex) {
            ctx.globalCompositeOperation = "source-over";
            if (orientation === "bottom") {
                ctx.rect(
                    last_mousex,
                    last_mousey - height,
                    mousex - last_mousex,
                    mousey - last_mousey
                );
            } else {
                ctx.rect(
                    last_mousex,
                    last_mousey,
                    mousex - last_mousex,
                    mousey - last_mousey
                );
            }

            ctx.lineWidth = lineWidth;
            ctx.strokeStyle = color;
            ctx.stroke();
            var operation = "source-over";

            drawshape(
                last_mousex,
                last_mousey,
                mousex,
                mousey,
                color,
                lineWidth,
                operation,
                tooltype
            );
            // setLastMouseUpTime(moment.utc());
        } else if (tooltype === "circle" && last_mousex) {
            ctx.globalCompositeOperation = "source-over";
            ctx.save();
            ctx.beginPath();
            var scalex = (mousex - last_mousex) / 2;
            var scaley = (mousey - last_mousey) / 2;
            ctx.scale(scalex, scaley);
            var centerx = last_mousex / scalex + 1;
            if (orientation === "bottom") {
                var centery = (last_mousey - height) / scaley + 1;
            } else {
                var centery = last_mousey / scaley + 1;
            }

            ctx.arc(centerx, centery, 1, 0, 2 * Math.PI);
            ctx.restore();
            ctx.lineWidth = lineWidth;
            ctx.strokeStyle = color;
            ctx.stroke();
            var operation = "source-over";
            drawshape(
                last_mousex,
                last_mousey,
                mousex,
                mousey,
                color,
                lineWidth,
                operation,
                tooltype
            );
            // setLastMouseUpTime(moment.utc());
        } else if (tooltype === "triangle" && coordinates) {
            ctx.beginPath();
            ctx.globalCompositeOperation = "source-over";

            if (orientation === "bottom") {
                ctx.moveTo(coordinates[0].x, coordinates[0].y - 300);
                for (var index = 1; index < 3; index++) {
                    ctx.lineTo(
                        coordinates[index].x,
                        coordinates[index].y - 300
                    );
                }
            } else {
                ctx.moveTo(coordinates[0].x, coordinates[0].y);
                for (var index = 1; index < 3; index++) {
                    ctx.lineTo(coordinates[index].x, coordinates[index].y);
                }
            }

            ctx.closePath();
            ctx.lineWidth = lineWidth;
            ctx.strokeStyle = color;
            ctx.stroke();
            var operation = "source-over";
            drawtriangle(coordinates, color, lineWidth, operation, tooltype);
            // setLastMouseUpTime(moment.utc());
        }
    };

    const onMouseMove = (e, flag) => {
        e.preventDefault();
        mousex = parseInt(
            (flag ? e.clientX : e.touches[0].clientX) - shapeCanvasx
        );
        mousey = parseInt(
            (flag ? e.clientY : e.touches[0].clientY) - shapeCanvasy
        );
        if (mousedown.current) {
            ctx.beginPath();
            if (tooltype === "draw") {
                operation = "source-over";
                if (penColor) {
                    drawLine(
                        last_mousex,
                        last_mousey,
                        mousex,
                        mousey,
                        penColor,
                        lineWidth,
                        operation,
                        tooltype
                    );
                } else {
                    drawLine(
                        last_mousex,
                        last_mousey,
                        mousex,
                        mousey,
                        color,
                        lineWidth,
                        operation,
                        tooltype
                    );
                }

                last_mousex = mousex;
                last_mousey = mousey;
            } else if (tooltype === "erase") {
                operation = "destination-out";
                drawLine(
                    last_mousex,
                    last_mousey,
                    mousex,
                    mousey,
                    color,
                    lineWidth,
                    operation,
                    tooltype
                );
                last_mousex = mousex;
                last_mousey = mousey;
            } else if (tooltype === "rectangle") {
                shapeCtx.clearRect(0, 0, shapeCanvas.width, shapeCanvas.height);
                shapeCtx.globalCompositeOperation = "source-over";
                shapeCtx.lineWidth = lineWidth;
                var width = mousex - last_mousex;
                var height = mousey - last_mousey;
                if (orientation === "bottom") {
                    shapeCtx.strokeRect(
                        last_mousex,
                        last_mousey - height,
                        width,
                        height
                    );
                } else {
                    shapeCtx.strokeRect(
                        last_mousex,
                        last_mousey,
                        width,
                        height
                    );
                }

                shapeCtx.strokeStyle = color;
            } else if (tooltype === "circle") {
                shapeCtx.save();
                shapeCtx.clearRect(0, 0, shapeCanvas.width, shapeCanvas.height);
                shapeCtx.globalCompositeOperation = "source-over";
                shapeCtx.beginPath();
                var scalex = (mousex - last_mousex) / 2;
                var scaley = (mousey - last_mousey) / 2;
                shapeCtx.scale(scalex, scaley);
                var centerx = last_mousex / scalex + 1;
                if (orientation === "bottom") {
                    var centery = (last_mousey - height) / scaley + 1;
                } else {
                    var centery = last_mousey / scaley + 1;
                }

                shapeCtx.arc(centerx, centery, 1, 0, 2 * Math.PI);
                shapeCtx.restore();
                shapeCtx.strokeStyle = color;
                shapeCtx.lineWidth = lineWidth;
                shapeCtx.stroke();
            } else if (tooltype === "triangle") {
                coordinates = [];
                var angle = 100,
                    sides = 3,
                    radius = Math.sqrt(
                        Math.pow(last_mousex - mousex, 2) +
                            Math.pow(last_mousex - mousex, 2)
                    ),
                    index = 0;
                for (index = 0; index < sides; index++) {
                    coordinates.push({
                        x: last_mousex + radius * Math.cos(angle),
                        y: last_mousey - radius * Math.sin(angle),
                    });
                    angle += (2 * Math.PI) / sides;
                }
                shapeCtx.globalCompositeOperation = "source-over";
                shapeCtx.clearRect(0, 0, shapeCanvas.width, shapeCanvas.height);
                shapeCtx.beginPath();

                if (orientation === "bottom") {
                    shapeCtx.moveTo(coordinates[0].x, coordinates[0].y - 300);
                    for (index = 1; index < sides; index++) {
                        shapeCtx.lineTo(
                            coordinates[index].x,
                            coordinates[index].y - 300
                        );
                    }
                } else {
                    shapeCtx.moveTo(coordinates[0].x, coordinates[0].y);
                    for (index = 1; index < sides; index++) {
                        shapeCtx.lineTo(
                            coordinates[index].x,
                            coordinates[index].y
                        );
                    }
                }

                shapeCtx.closePath();
                shapeCtx.lineWidth = lineWidth;
                shapeCtx.strokeStyle = color;
                shapeCtx.stroke();
            }
        }
    };

    const tool = (tool) => {
        setTooltype(tool);
    };

    const handleWidth = (value) => {
        setLineWidth(value);
    };

    const handleClear = () => {
        const element = document.getElementsByName("fname");

        for (let i = 0; i < element.length; i++) {
            let ctx = element[i].getContext("2d");
            ctx.clearRect(0, 0, element[i].width, element[i].height);
        }
    };

    const handleColor = (value) => {
        setColor(value);
    };

    const handleOption = () => {
        setOption(!option);
    };

    const handleBtn = () => {
        setVisibleControl(!visibleControl);
    };

    const settingsHandle = () => {
        setSettings(!settings);
    };

    return (
        <>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <div style={{ height: "100%", width: "100%" }}>
                <div
                    id="canvaswrapper"
                    className={styles.canvasWrapper}
                    ref={wrapperRef}
                >
                    {fitToScreen ? (
                        <div
                            style={{
                                position: "absolute",
                                height: "100%",
                                width: "100%",
                            }}
                        >
                            {selectedMedia.length ? (
                                <>
                                    <img
                                        src={selectedMedia[0]?.location}
                                        style={{
                                            width: "100%",
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                        }}
                                        alt="plass Begin"
                                    />
                                    {/* <pre>{JSON.stringify(studentView)}</pre> */}
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    ) : (
                        <div
                            style={{
                                position: "absolute",
                                left: `${left}%`,
                                top: `${up}%`,
                            }}
                        >
                            {selectedMedia.length ? (
                                <>
                                    <img
                                        src={selectedMedia[0]?.location}
                                        style={{
                                            width: "31rem",
                                            height: "18rem",
                                        }}
                                        alt="plass Begin"
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    )}
                    <canvas
                        width={width}
                        height={height}
                        className={styles.whiteboardCanvas}
                        ref={canvasRef}
                        id="drawingCanvas"
                        name="fname"
                    />
                    <canvas
                        width={width}
                        height={height}
                        className={styles.whiteboardCanvasShape}
                        onMouseDown={(e) => onMouseDown(e, true)}
                        onTouchStart={(e) => onMouseDown(e, false)}
                        onMouseUp={(e) => onMouseUp(e, true)}
                        onTouchEnd={(e) => onMouseUp(e, false)}
                        onMouseMove={(e) => onMouseMove(e, true)}
                        onTouchMove={(e) => onMouseMove(e, false)}
                        ref={shapeRef}
                        id="myCanvasForShape"
                    />

                    {teacherView === "viewT1" ? (
                        <Layout1 />
                    ) : teacherView === "viewT2" ? (
                        <Layout2 />
                    ) : teacherView === "viewT3" ? (
                        <Layout3 />
                    ) : null}
                </div>
                {!studentView && control && (
                    <div onMouseEnter={handleBtn} onMouseLeave={handleBtn}>
                        {visibleControl ? (
                            <div className={styles.whiteboard_control}>
                                <div className={styles.settingsBtnWrapper}>
                                    <div
                                        className={styles.settingBtn}
                                        onClick={() => {
                                            settingsHandle();
                                        }}
                                    >
                                        Settings
                                    </div>
                                    {settings && (
                                        <div className={styles.settingsModal}>
                                            <Settings
                                                session={session}
                                                panelID={panelID}
                                                color={color}
                                                selectedMedia={selectedMedia}
                                                setColor={(data) =>
                                                    setColor(data)
                                                }
                                                setLeft={(data) => {
                                                    setLeft(data);
                                                    setPosition({
                                                        left: data,
                                                        up: up,
                                                    });
                                                }}
                                                setUp={(data) => {
                                                    setUp(data);
                                                    setPosition({
                                                        left: left,
                                                        up: data,
                                                    });
                                                }}
                                                left={left}
                                                up={up}
                                                fitToScreen={fitToScreen}
                                                setFitToScreen={setFitToScreen}
                                                teacherName={teacherName}
                                                colorType={colorType}
                                                setColorType={setColorType}
                                                setNewPosition={setNewPosition}
                                                // onPositionSet={onPositionSet}
                                            ></Settings>
                                        </div>
                                    )}
                                </div>

                                <div
                                    className={styles.whiteboard_shape_wrapper}
                                >
                                    <p
                                        onClick={() => {
                                            tool("rectangle");
                                        }}
                                    >
                                        <img
                                            src={
                                                tooltype === "rectangle"
                                                    ? RectangleSelected
                                                    : Rectangle
                                            }
                                            alt="Rectangle"
                                        />
                                    </p>
                                    <p
                                        onClick={() => {
                                            tool("circle");
                                        }}
                                    >
                                        <img
                                            src={
                                                tooltype === "circle"
                                                    ? CircleSelected
                                                    : Circle
                                            }
                                            alt="Circle"
                                        />
                                    </p>
                                    <p
                                        onClick={() => {
                                            tool("triangle");
                                        }}
                                    >
                                        <img
                                            src={
                                                tooltype === "triangle"
                                                    ? TriangleSelected
                                                    : Triangle
                                            }
                                            alt="Triangle"
                                        />
                                    </p>
                                    <p
                                        onClick={() => {
                                            tool("draw");
                                        }}
                                    >
                                        <img
                                            src={
                                                tooltype === "draw"
                                                    ? LineSelected
                                                    : Line
                                            }
                                            alt="Line"
                                        />
                                    </p>
                                </div>

                                <div
                                    className={styles.whiteboard_color_wrapper}
                                >
                                    <div
                                        className={
                                            color === "#333232"
                                                ? styles.whiteboard_color_selected
                                                : styles.whiteboard_color_unselected
                                        }
                                    >
                                        <div
                                            className={styles.whiteboard_color}
                                            onClick={() => {
                                                handleColor("#333232");
                                            }}
                                            style={{
                                                backgroundColor: "#333232",
                                            }}
                                        />
                                    </div>

                                    <div
                                        className={
                                            color === "#88ADDB"
                                                ? styles.whiteboard_color_selected
                                                : styles.whiteboard_color_unselected
                                        }
                                    >
                                        <div
                                            className={styles.whiteboard_color}
                                            onClick={() => {
                                                handleColor("#88ADDB");
                                            }}
                                            style={{
                                                backgroundColor: "#88ADDB",
                                            }}
                                        />
                                    </div>

                                    <div
                                        className={
                                            color === "#D94E43"
                                                ? styles.whiteboard_color_selected
                                                : styles.whiteboard_color_unselected
                                        }
                                    >
                                        <div
                                            className={styles.whiteboard_color}
                                            onClick={() => {
                                                handleColor("#D94E43");
                                            }}
                                            style={{
                                                backgroundColor: "#D94E43",
                                            }}
                                        />
                                    </div>

                                    <div
                                        className={
                                            color === "#4EA59D"
                                                ? styles.whiteboard_color_selected
                                                : styles.whiteboard_color_unselected
                                        }
                                    >
                                        <div
                                            className={styles.whiteboard_color}
                                            onClick={() => {
                                                handleColor("#4EA59D");
                                            }}
                                            style={{
                                                backgroundColor: "#4EA59D",
                                            }}
                                        />
                                    </div>

                                    <div
                                        className={
                                            color === "#FCB648"
                                                ? styles.whiteboard_color_selected
                                                : styles.whiteboard_color_unselected
                                        }
                                    >
                                        <div
                                            className={styles.whiteboard_color}
                                            onClick={() => {
                                                handleColor("#FCB648");
                                            }}
                                            style={{
                                                backgroundColor: "#FCB648",
                                            }}
                                        />
                                    </div>
                                </div>

                                <div
                                    className={styles.whiteboard_width_wrapper}
                                >
                                    <div
                                        className={styles.whiteboard_width}
                                        onClick={() => {
                                            handleWidth("2");
                                        }}
                                    >
                                        <img
                                            src={
                                                lineWidth === "2"
                                                    ? SmallSelected
                                                    : Small
                                            }
                                            alt="Small"
                                        />
                                    </div>
                                    <div
                                        className={styles.whiteboard_width}
                                        onClick={() => {
                                            handleWidth("6");
                                        }}
                                    >
                                        <img
                                            src={
                                                lineWidth === "6"
                                                    ? MediumSelected
                                                    : Medium
                                            }
                                            alt="Medium"
                                        />
                                    </div>
                                    <div
                                        className={styles.whiteboard_width}
                                        onClick={() => {
                                            handleWidth("8");
                                        }}
                                    >
                                        <img
                                            src={
                                                lineWidth === "8"
                                                    ? LargeSelected
                                                    : Large
                                            }
                                            alt="Large"
                                        />
                                    </div>
                                </div>

                                <div className={styles.clear_wrapper}>
                                    <img
                                        src={Eraser}
                                        alt="eraser"
                                        id="eraser"
                                        onClick={() => handleOption()}
                                    />
                                    <>
                                        {option && (
                                            <div
                                                className={styles.clear_option}
                                            >
                                                <p
                                                    onClick={() => {
                                                        setOption(false);
                                                        handleClear();
                                                    }}
                                                >
                                                    Clear all
                                                </p>
                                                <p
                                                    onClick={() => {
                                                        setOption(false);
                                                        tool("erase");
                                                    }}
                                                >
                                                    Eraser
                                                </p>
                                            </div>
                                        )}
                                    </>
                                </div>

                                <div
                                    className={
                                        tooltype === "text"
                                            ? styles.whiteboard_text_highlighted
                                            : styles.whiteboard_text
                                    }
                                >
                                    <p
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            tool("text");
                                        }}
                                    >
                                        Text
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className={styles.lineBoxContainer}>
                                <div className={styles.lineContainer}>
                                    <hr
                                        className={styles.line}
                                        align="center"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default WhiteboardNirajan;
