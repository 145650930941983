import React from 'react'
import styles from "./layout.module.css";

export default function Layout2() {
    return (
        <>
            <div className="row" style={{
                // marginLeft: "4%",
                marginTop: "30%",
                justifyContent: 'center'
            }}>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
            </div>
        </ >
    )
}
