import axios from "axios";
import _ from "lodash";
import React, { Fragment, useEffect, useState, useRef } from "react";
import Navbar from "../../../components/Navbar";
import Carousel from "../components/Carousel";
import Pagination from "../components/Pagination";
import styles from "./index.module.scss";
import TeachersList from "./TeachersList";
import InstagramCarousel from "../InstagramCarousel";
import TeacherMsg from "../components/TeacherMsg";
import FooterNew from "../../../components/FooterNew";
import Loading from "../../../components/Loading/Loading";
import ParentReview from "../components/ParentReview";
import SessionCarousel from "../../../components/Carousels/SessionCarousel";
import BreadCrumbs from "../../../components/BreadCrumbs";

const Teachers = ({ listToDisplay, setMsg, setShowSnackbar }) => {
    const [teachers, setTeachers] = useState({
        teacherList: [],
        teachersToDisplay: [],
    });
    const scollToRef = useRef(null);

    const [paginationInfo, setPaginationInfo] = useState();
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [isSearchedFlag, setIsSearchedFlag] = useState(false);
    const [searchedTerm, setSearchedTerm] = useState();
    const [isFilteredFlag, setIsFilteredFlag] = useState(false);
    const [filterKey, setFilterKey] = useState();
    const [sortKey, setSortKey] = useState();

    useEffect(() => {
        fetchFilteredClasses();
        // eslint-disable-next-line
    }, [searchedTerm, filterKey, sortKey, pageNumber]);

    useEffect(() => {
        scollToRef.current.scrollIntoView();
    }, [pageNumber]);

    const fetchFilteredClasses = () => {
        let url = "";
        url = url + "&sortDirection=ASC";

        if (searchedTerm) {
            url = "&search=" + searchedTerm;
            setIsSearchedFlag(true);
            setPageNumber(1);
        }

        if (filterKey) {
            setIsFilteredFlag(true);
            if (filterKey.age.length) {
                url = url + "&age=";
                filterKey.age.forEach((age) => (url = url + age + ","));
                url = url.slice(0, -1);
            }

            if (filterKey.classTopic.length) {
                url = url + "&learningObjectives=";
                filterKey.classTopic.forEach(
                    (topic) => (url = url + topic + ",")
                );
                url = url.slice(0, -1);
            }

            if (filterKey.classSize.length) {
                url = url + "&classSize=";
                filterKey.classSize.forEach((size) => (url = url + size + ","));
                url = url.slice(0, -1);
            }

            if (filterKey.time.length) {
                url = url + "&time=";
                filterKey.time.forEach((time) => (url = url + time + ","));
                url = url.slice(0, -1);
            }
            setPageNumber(1);
        }

        if (sortKey) {
            url = url + "&sortField=" + sortKey;
            setPageNumber(1);
        }

        let currentTeachersList = teachers;
        currentTeachersList = {
            teacherList: [],
            teachersToDisplay: [],
        };

        fetchTeachers(url, currentTeachersList);
    };

    // Function to get teachers list.
    const fetchTeachers = (updatedUrl, updatedTeachersList) => {
        let teachersList = updatedTeachersList;
        if (!teachersList) {
            teachersList = teachers;
        }
        setLoading(true);
        let url = "/api/v1/home/teachers?perPage=12&page=" + pageNumber;
        if (updatedUrl) {
            url = url + updatedUrl;
        } else {
            url = url + "&sortDirection=DESC&sortField=createdAt";
        }

        axios
            .get(url)
            .then((res) => {
                const responseTeachers = res.data;
                const teachersRes = [
                    ...teachersList.teacherList,
                    ...responseTeachers.data.teachers,
                ];
                const teachersToDisplay = teachersRes.slice(
                    0,
                    window.screen.width < 415 ? 4 : 12
                );
                const pagination = responseTeachers.data.pagination;

                setTeachers({
                    teacherList: teachersRes,
                    teachersToDisplay: teachersToDisplay,
                });
                setPaginationInfo(pagination);
                setLoading(false);
            })
            .catch((err) => {
                setTeachers({
                    teacherList: [],
                    teachersToDisplay: [],
                });
                setLoading(false);
                setMsg(
                    _.get(err, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
            });
    };

    return (
        <Fragment>
            {listToDisplay ? (
                <div className={styles.mainContainer}>
                    <Navbar />

                    <div className={styles.pageContainer}>
                        <div className={styles.contentContainer}>
                            <div
                                className={styles.carouselContainer}
                                ref={scollToRef}
                            >
                                <Carousel />
                            </div>
                            <BreadCrumbs
                                links={[
                                    { to: "/home", label: "Home" },
                                    { to: "/teachers", label: "Facilitators" },
                                ]}
                            />
                            {loading ? (
                                <Loading />
                            ) : (
                                <TeachersList
                                    teachers={teachers}
                                    listToDisplay={listToDisplay}
                                    isSearchedFlag={isSearchedFlag}
                                    isFilteredFlag={isFilteredFlag}
                                    searchedTerm={searchedTerm}
                                    setSearchedTerm={setSearchedTerm}
                                    setFilterKey={setFilterKey}
                                    setSortKey={setSortKey}
                                />
                            )}
                        </div>

                        {paginationInfo ? (
                            <Pagination
                                paginationInfo={paginationInfo}
                                setPageNumber={setPageNumber}
                            />
                        ) : null}

                        <ParentReview page="home" />
                        <SessionCarousel title="Explore our live sessions" />
                        <TeacherMsg />
                        <InstagramCarousel />
                        <FooterNew />
                    </div>
                </div>
            ) : loading ? (
                <Loading />
            ) : (
                <TeachersList
                    teachers={teachers}
                    isSearchedFlag={isSearchedFlag}
                    isFilteredFlag={isFilteredFlag}
                    searchedTerm={searchedTerm}
                    setSearchedTerm={setSearchedTerm}
                    setFilterKey={setFilterKey}
                    setSortKey={setSortKey}
                />
            )}
        </Fragment>
    );
};

export default Teachers;
