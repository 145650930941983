import axios from "axios";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import Badge from "../../../assets/images/PlayPage/Badge.svg";
import Book from "../../../assets/images/PlayPage/Book.svg";
import CalenderYellow from "../../../assets/images/PlayPage/CalenderYellow.svg";
import ClickYellow from "../../../assets/images/PlayPage/ClickYellow.svg";
import Friends from "../../../assets/images/PlayPage/Friends.svg";
import RegisterBtn from "../../../assets/images/PlayPage/RegisterBtn.svg";
import SignUpBtn from "../../../assets/images/PlayPage/SignUpBtn.svg";
import TeacherStudent from "../../../assets/images/PlayPage/TeacherStudent.svg";
import FooterNew from "../../../components/FooterNew";
import Navbar from "../../../components/Navbar";
import Snackbar from "../../../components/Snackbar";
import styles from "./index.module.scss";

const validateEmail = (emailAddress) => {
    let input = emailAddress;

    let isValid = true;
    if (!input) {
        isValid = false;
    }
    if (typeof input !== "undefined") {
        var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(input)) {
            isValid = false;
        }
    }

    return isValid;
};

const Play = () => {
    const history = useHistory();
    const [userData, setUserData] = useState({
        email: "",
        firstName: "",
        lastName: "",
    });
    const [loading, isLoading] = useState({
        newsLetter: false,
    });
    const [snackBarStatus, setSnackBarStatus] = useState("error");
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const registerForNewsLetter = () => {
        if (!userData.firstName || !userData.lastName || !userData.email) {
            setMsg("Please enter valid details.");
            setSnackBarStatus("error");
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        } else if (validateEmail(userData.email)) {
            isLoading((loadingCopy) => {
                return {
                    ...loadingCopy,
                    newsLetter: true,
                };
            });

            axios
                .post("/api/v1/newsletter", userData)
                .then((response) => {
                    if (response.data.success) {
                        setMsg(
                            _.get(
                                response,
                                "data.message",
                                "Successfully registered a user for newsletter."
                            )
                        );
                        setSnackBarStatus("information");
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    } else {
                        setMsg(
                            _.get(
                                response,
                                "data.message",
                                "Something went wrong."
                            )
                        );
                        setSnackBarStatus("error");
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setSnackBarStatus("error");
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                })
                .finally(() => {
                    isLoading((loadingCopy) => {
                        return {
                            ...loadingCopy,
                            newsLetter: false,
                        };
                    });
                    setUserData({
                        email: "",
                        firstName: "",
                        lastName: "",
                    });
                });
        } else {
            setMsg("Please enter a valid email address.");
            setSnackBarStatus("error");
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        }
    };

    const handleUserData = (e) => {
        const inputId = e.target.id;
        const inputValue = e.target.value;

        setUserData((userDataCopy) => {
            return {
                ...userDataCopy,
                [inputId]: inputValue,
            };
        });
    };

    return (
        <Fragment>
            {msg && (
                <Snackbar
                    status={snackBarStatus}
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <div className={styles.mainContainer}>
                <Navbar />

                <div className={styles.pageContainer}>
                    <div className={styles.contentContainer}>
                        <div className={styles.introContainer}>
                            <div className={styles.teacherStudentImg}>
                                <img
                                    src={TeacherStudent}
                                    alt="Teacher Student"
                                />
                            </div>

                            <div className={styles.introData}>
                                <div className={styles.introHeader}>
                                    ONLINE LEARNING FOR CHILDREN
                                </div>

                                <div className={styles.introSubHeader}>
                                    Our children love learning through play
                                </div>

                                <div className={styles.introPara}>
                                    Facilitators create interactive and fun
                                    online sesssions through creating their own
                                    content and using the tools we provide on
                                    Plassroom. Children interact with others
                                    from all around the world in an online live
                                    group setting.
                                </div>

                                <div className={styles.introSignUpBtn}>
                                    <img
                                        src={SignUpBtn}
                                        alt="Sign Up Btn"
                                        className={styles.signUpBtn}
                                        onClick={() => history.push("/signup")}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={styles.infoContainer}>
                            <div className={styles.infoHeader}>
                                PERSONALISED ONLINE LEARNING EXPERIENCES
                            </div>

                            <div className={styles.infoPara}>
                                Engaging content, child friendly online tools,
                                trusted and experienced facilitators and
                                safeguards in place to ensure enjoyable learning
                                experiences.
                            </div>
                        </div>

                        <div className={styles.iconsContainer}>
                            <div className={styles.iconContainer}>
                                <img src={Book} alt="Book" />

                                <p>Academic and non academic topics</p>
                            </div>

                            <div className={styles.iconContainer}>
                                <img src={Friends} alt="Friends" />

                                <p>
                                    Social interaction in a live class setting
                                </p>
                            </div>

                            <div
                                className={styles.iconContainer}
                                style={{
                                    maxWidth: "8.8rem",
                                }}
                            >
                                <img src={ClickYellow} alt="ClickYellow" />

                                <p>Easy to use and child friendly</p>
                            </div>

                            <div
                                className={styles.iconContainer}
                                style={{
                                    maxWidth: "8.8rem",
                                }}
                            >
                                <img src={Badge} alt="Badge" />

                                <p>Safe and secure platform</p>
                            </div>

                            <div className={styles.iconContainer}>
                                <img
                                    src={CalenderYellow}
                                    alt="CalenderYellow"
                                />

                                <p>Create your own learning schedule</p>
                            </div>
                        </div>

                        <div className={styles.contactBGContainer}>
                            <div className={styles.contactHeader}>
                                Enrichment classes that your children will love
                            </div>

                            <div className={styles.contactPara}>
                                Parents, facilitators and children all have a
                                good time, building friendships with others they
                                meet. Sign up to enjoy your first session free!
                            </div>

                            <div className={styles.contactSignUpBtn}>
                                <img
                                    src={SignUpBtn}
                                    alt="Sign Up Btn"
                                    className={styles.signUpBtn}
                                    onClick={() => history.push("/signup")}
                                />
                            </div>

                            <div className={styles.contactLink}>
                                Are you intereseted in facilitating fun
                                Plassroom sessions?{" "}
                                <span
                                    onClick={() => {
                                        history.push("/teach");
                                    }}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Click here
                                </span>
                            </div>
                        </div>

                        <div className={styles.contactUsContainer}>
                            <div className={styles.contactUsDetails}>
                                <div className={styles.contactUsDetailsHeader}>
                                    Stay updated
                                </div>

                                <div className={styles.contactUsDetailsPara}>
                                    Come and be part of a community of like
                                    minded parents and get updates on
                                    educational information, upcoming sessions
                                    and new product features right into your
                                    mailbox!
                                </div>
                            </div>

                            <div className={styles.contactUsInputContainer}>
                                <div className={styles.nameInputContainer}>
                                    <input
                                        type="text"
                                        name="FName"
                                        id="firstName"
                                        placeholder="First Name"
                                        value={userData.firstName}
                                        onChange={handleUserData}
                                        disabled={loading.newsLetter}
                                    />

                                    <input
                                        type="text"
                                        name="LName"
                                        id="lastName"
                                        placeholder="Last Name"
                                        value={userData.lastName}
                                        onChange={handleUserData}
                                        disabled={loading.newsLetter}
                                    />
                                </div>

                                <input
                                    type="email"
                                    name="EmailAddress"
                                    id="email"
                                    placeholder="Email Address"
                                    className={styles.emailAddress}
                                    value={userData.email}
                                    onChange={handleUserData}
                                    disabled={loading.newsLetter}
                                />

                                <img
                                    src={RegisterBtn}
                                    alt="Register Btn"
                                    className={styles.registerBtn}
                                    onClick={
                                        loading.newsLetter
                                            ? null
                                            : registerForNewsLetter
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <FooterNew />
                </div>
            </div>
        </Fragment>
    );
};

export default Play;
