import React from "react";
import styles from "./Loading.module.css";
import LoadingVideo from "../../assets/videos/Loading.mp4";

const Loading = () => {
    return (
        <div className={styles.waiting_content__container}>
            <video className="VideoTag" autoPlay loop muted>
                <source src={LoadingVideo} type="video/mp4" />
            </video>
        </div>
    );
};

export default Loading;
