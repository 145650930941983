import axios from "axios";
import _ from "lodash";
import React, { useState } from "react";
import Loading from "../../../assets/videos/Loading.mp4";
import Snackbar from "../../../components/Snackbar";
import Navbar from "../../../components/Navbar";
import styles from "../StudentSubscriptionRegister/index.module.scss";
import CommonButton from "../../../components/Button/CommonButton";
import BG1 from "../../../assets/images/Plan/lite_plan.png";
import BG2 from "../../../assets/images/Plan/plass_plan.png";
import { useHistory } from "react-router-dom";

const Upgrade1 = () => {
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const history = useHistory();

    return (
        <>
            <Navbar />
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <div className={styles.container}>
                <div className={styles.mainContainer}>
                    <h1 className={styles.heading}>
                        Ease with no commitment on our plans
                    </h1>

                    <p className={styles.instruction}>
                        Need something convenient for you to book learning or
                        entertainment sessions for your children?
                    </p>

                    <div className={styles.imageContent}>
                        <img
                            src={BG1}
                            onClick={() =>
                                history.push(
                                    "/choose-plan-and-subscription-plan"
                                )
                            }
                        />
                        <div className={styles.textWrapper}>
                            <h4>The LITE Plan (30 days free trial)</h4>
                        </div>
                    </div>
                    <ul className={styles.planList}>
                        <li>
                            30 days free trial upon signing up to a student
                            account - only once per student.
                        </li>
                        <li>
                            Use Plasscoins to redeem 60 minutes of learning
                            (estimate worth GBP 12).
                        </li>
                        <li>Unlimited registration to any free sessions. </li>
                        <li>
                            Unused Plasscoins will roll over for 3 months from
                            date Plasscoins were entered into your account.
                        </li>
                        <li>
                            Personal concierge - reminders and tech support will
                            be communicated via the email tied to your account.
                        </li>
                        <li>
                            Plasscoins cannot be used for courses or 1 to 1
                            sessions.
                            <br />
                            <span
                                style={{ color: "#88addb" }}
                                onClick={() =>
                                    (window.location.href = "/planFaqs")
                                }
                            >
                                Read more...
                            </span>
                        </li>
                    </ul>

                    <div
                        className={styles.imageContent}
                        style={{ marginTop: "20px" }}
                    >
                        <img
                            src={BG2}
                            onClick={() =>
                                history.push(
                                    "/choose-plan-and-subscription-plan"
                                )
                            }
                        />
                        <div className={styles.textWrapper}>
                            <h4>The PLASS Plan</h4>
                        </div>
                    </div>
                    <ul className={styles.planList}>
                        <li>
                            Use Plasscoins to redeem 120 minutes of learning
                            (estimate worth GBP 20).
                        </li>
                        <li>Unlimited registration to any free sessions. </li>
                        <li>
                            Unused Plasscoins will roll over for 3 months from
                            date Plasscoins were entered into your account.
                        </li>
                        <li>
                            Personal concierge - reminders and tech support will
                            be communicated via the email tied to your account.
                        </li>
                        <li>
                            Plasscoins cannot be used for courses or 1 to 1
                            sessions.
                            <br />
                            <span
                                style={{ color: "#88addb" }}
                                onClick={() =>
                                    (window.location.href = "/planFaqs")
                                }
                            >
                                Read more...
                            </span>
                        </li>
                    </ul>

                    <p
                        className={styles.instruction}
                        style={{ paddingBottom: "20px" }}
                    >
                        Or you can immediately suscribe to one of our plans or
                        enjoy free 60 plasscoins on us!
                    </p>
                    <CommonButton
                        buttonText="Subscribe"
                        handleClick={() =>
                            (window.location.href =
                                "/choose-plan-and-subscription-plan")
                        }
                        classes={styles.button}
                    />

                    <div className={styles.otherLink}>
                        <span onClick={() => history.push("/planFaqs")}>
                            See plan details and FAQs
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Upgrade1;
