import React, { useEffect, useState } from "react";

const CountDown = ({
    endTime,
    endClass,
    setShowTimeoutWarningModal,
    showTimeoutWarningModal,
}) => {
    const targetTime = new Date(endTime).getTime();

    const [currentTime, setCurrentTime] = useState(Date.now());
    let [flag, setFlag] = useState(0);

    const timeBetween = targetTime - currentTime;
    const seconds =
        timeBetween < 0
            ? Math.ceil((timeBetween / 1000) % 60)
            : Math.floor((timeBetween / 1000) % 60);
    const minutes =
        timeBetween < 0
            ? Math.ceil((timeBetween / 1000 / 60) % 60)
            : Math.floor((timeBetween / 1000 / 60) % 60);
    const hours =
        timeBetween < 0
            ? Math.ceil((timeBetween / (1000 * 60 * 60)) % 24)
            : Math.floor((timeBetween / (1000 * 60 * 60)) % 24);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(Date.now());
        }, 1000);

        if (
            parseInt(seconds) <= 0 &&
            parseInt(minutes) <= -5 &&
            parseInt(hours) <= 0
        ) {
            endClass();
        }
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [seconds, minutes, hours]);

    const showModal = setInterval(() => {
        if (hours === 0 && minutes === 5 && seconds === 0) {
            if (!showTimeoutWarningModal) {
                setShowTimeoutWarningModal(true);
                clearInterval(showModal);
            }
        }
    }, 1000);

    return (
        <span
            className="counter"
            style={{
                position: "absolute",
                right: "10px",
                top: "10px",
                boxShadow: "0px 4px 8px #00000029",
                padding: "2px 20px",
                fontSize: "27px",
                zIndex: 1,
                borderRadius: "23px",
                color: hours === 0 && minutes < 5 ? "#E36B2D" : "inherit",
                backgroundColor:
                    hours === 0 && minutes < 5 ? "#FDD7C6" : "#e9f3fb",
            }}
        >
            <span>{hours}h </span>
            <span>{minutes}min </span>
            <span>{seconds}s</span>
        </span>
    );
};

export default CountDown;
