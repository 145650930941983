import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styles from "./index.module.scss";
import CarouselSmall from "../CarouselSmall";
import ourTeachers from "../../../assets/images/NewDesign/ourTeachers.svg";
import { ratingStar } from "../../../utils/Ratting/rating";
import Loading from "../../Loading/Loading";

const TeacherCarousel = ({
    carouselImgs,
    onClickEvent,
    specialClass,
    autoPlay,
    controls,
    playOnHover,
    smallCarousel,
    teacherDetails,
    title,
}) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [teacherList, setTeacherList] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        axios
            .get("/api/v1/home/teachers")
            .then((res) => {
                if (res?.data?.success) {
                    setLoading(false);
                    setTeacherList(res?.data?.data?.teachers.splice(0, 8));
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const handelRoute = (teacher) => {
        history.push(`/teacher-details/${teacher?.username}`);
    };

    const routeToTeacher = () => {
        history.push(`/teachers`);
    };

    const handleClick = (path) => {
        if (path === "left") {
            setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 0);
        } else {
            setCurrentSlide(
                currentSlide < teacherList.length / 2 - 1 ? currentSlide + 1 : 0
            );
        }
    };

    // const checkExtension = (source) => {
    //     const ext = source.split(".");
    //     return _.last(ext);
    // };

    return (
        <>
            {loading ? <Loading /> : ""}
            {teacherList?.length > 0 && (
                <>
                    {!!title && (
                        <h2
                            className="main-title spacing-adjust"
                            style={{
                                color: "#13385C",
                                textAlign: "center",
                                margin: "3rem 0",
                            }}
                        >
                            {title}
                        </h2>
                    )}

                    <div className={styles.carouselPage}>
                        <article
                            className={styles.carouselWrapper}
                            onClick={onClickEvent}
                        >
                            <div
                                className={styles.carouselSlider}
                                style={{
                                    transform: `translateX(-${
                                        currentSlide * 25
                                    }%)`,
                                }}
                            >
                                <>
                                    <div className={styles.container}>
                                        {teacherList.map((teacher, caroIdx) => {
                                            return (
                                                // <div
                                                //     className={`${styles.slide} ${styles[specialClass]}`}
                                                //     key={caroIdx}
                                                // >
                                                <div
                                                    className={styles.cardRow}
                                                    key={caroIdx}
                                                >
                                                    <Card
                                                        className={
                                                            styles.classcard
                                                        }
                                                    >
                                                        <Card.Body
                                                            className={
                                                                styles.cardBody
                                                            }
                                                        >
                                                            {/* <Card.Img variant="top" src={teacher?.image_ids[0]?.location} /> */}
                                                            <div
                                                                className={
                                                                    styles.classImage
                                                                }
                                                            >
                                                                <CarouselSmall
                                                                    carouselImgs={_.get(
                                                                        teacher,
                                                                        "profilePictureIds",
                                                                        []
                                                                    )}
                                                                    onClickEvent={() =>
                                                                        handelRoute(
                                                                            teacher
                                                                        )
                                                                    }
                                                                    specialClass="br19"
                                                                    playOnHover={
                                                                        true
                                                                    }
                                                                    smallCarousel={
                                                                        true
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.bottomDiv
                                                                }
                                                            >
                                                                <p
                                                                    className={
                                                                        styles.teacherName
                                                                    }
                                                                >
                                                                    {
                                                                        teacher?.preferedName
                                                                    }
                                                                </p>
                                                                <p
                                                                    className={
                                                                        styles.countryName
                                                                    }
                                                                >{`${
                                                                    teacher?.state
                                                                        ? `${teacher?.state},`
                                                                        : ""
                                                                } ${
                                                                    teacher?.country
                                                                        ? `${teacher?.country}`
                                                                        : ""
                                                                }`}</p>
                                                                <div
                                                                    className={
                                                                        styles.classRating
                                                                    }
                                                                >
                                                                    {ratingStar(
                                                                        _.get(
                                                                            teacher,
                                                                            "teacherRate",
                                                                            0
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                // </div>
                                            );
                                        })}
                                    </div>
                                </>
                            </div>
                        </article>
                        {teacherList?.length > 1 ? (
                            <button
                                className={styles.leftBtn}
                                onClick={() => handleClick("left")}
                                // style={{ left: "0.5rem" }}
                            >
                                <FontAwesomeIcon
                                    icon={faChevronLeft}
                                    className={styles.icon}
                                />
                            </button>
                        ) : null}
                        {teacherList?.length > 1 ? (
                            <button
                                className={styles.rightBtn}
                                onClick={() => handleClick()}
                            >
                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    className={styles.icon}
                                />
                            </button>
                        ) : null}
                        <img
                            src={ourTeachers}
                            style={{
                                margin: "2rem 0px",
                                cursor: "pointer",
                                maxWidth: "180px",
                            }}
                            onClick={() => routeToTeacher()}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default TeacherCarousel;
