import React, { useEffect, useState } from "react";
import styles from "./Preview.module.scss";
import Layout1 from "./Teacher/Layout1";
import Layout2 from "./Teacher/Layout2";
import Layout3 from "./Teacher/Layout3";
import Layout4 from "./Teacher/Layout4";
import LayoutS1 from "./Student/LayoutS1";
import LayoutS2 from "./Student/LayoutS2";
import LayoutS3 from "./Student/LayoutS3";
import LayoutS4 from "./Student/LayoutS4";
// import WbLayout1 from "./RequiredStudentWB/Teacher/Layout1";
// import WbLayout2 from "./RequiredStudentWB/Teacher/Layout2";
// import WbLayout3 from "./RequiredStudentWB/Teacher/Layout3";
// import media from "../../../Media/media";

const PreviewVideo = ({ currentPanel, session, studentView }) => {
    useEffect(() => {
        // console.log(
        //     currentPanel,
        //     " currentPanel >>"
        // );
        // eslint-disable-next-line
    }, [currentPanel]);
    // const [showGameTab, setShowGameTab] = useState(false);
    const teacherView = currentPanel?.video_settings?.teacher_view;
    const student_view = currentPanel?.video_settings?.student_view;
    const currentLayout = currentPanel?.label;
    // const isWbRequired =
    //     currentPanel?.lesson_settings?.whiteboard?.student?.required_whiteboard;
    return (
        <div className={styles.previewContainer}>
            {/* <div
                className={styles.closeButton}
                onClick={() => history.goBack()}
            >
                Close
            </div> */}
            <div className={styles.peviewContainer}>
                {currentLayout === "Video" && !studentView ? (
                    teacherView === "viewT1" ? (
                        <Layout1 />
                    ) : teacherView === "viewT2" ? (
                        <Layout2 />
                    ) : teacherView === "viewT3" ? (
                        <Layout3 />
                    ) : teacherView === "viewT4" ? (
                        <Layout4 />
                    ) : null
                ) : null}
                {currentLayout === "Video" && studentView ? (
                    student_view === "view1" ? (
                        <LayoutS1 />
                    ) : student_view === "view2" ? (
                        <LayoutS2 />
                    ) : student_view === "view3" ? (
                        <LayoutS3 />
                    ) : student_view === "view4" ? (
                        <LayoutS4 />
                    ) : null
                ) : null}
            </div>
            {/* {!isWbRequired ? (
                currentLayout === "viewT1" ? (
                    <Layout1 currentPanel={currentPanel} session={session} />
                ) : currentLayout === "viewT2" ? (
                    <Layout2 currentPanel={currentPanel} session={session} />
                ) : currentLayout === "viewT3" ? (
                    <Layout3 currentPanel={currentPanel} session={session} />
                ) : null
            ) : currentLayout === "viewT1" ? (
                <WbLayout1 currentPanel={currentPanel} session={session} />
            ) : currentLayout === "viewT2" ? (
                <WbLayout2 currentPanel={currentPanel} session={session} />
            ) : currentLayout === "viewT3" ? (
                <WbLayout3 currentPanel={currentPanel} session={session} />
            ) : null} */}
        </div>
    );
};

export default PreviewVideo;
