import React, { useEffect, useState } from "react";
import { Row, Tab, Tabs } from "react-bootstrap";
import { Col } from "react-bootstrap";
import axios from "axios";
import _ from "lodash";
import { useLocation } from "react-router-dom";

import styles from "./accountSetting.module.css";
import StudentLogoBg from "../../assets/images/LOGO (3).svg";
import Dashboard from "../../assets/images/Dashboard@2x.png";
import Setting from "../../assets/images/icon/Settings.svg";
import Store from "../../assets/images/icon/Store.svg";
// import Messages from "../../assets/images/icon/Messages.svg";
// import MessagesText from "../../assets/images/icon/MessagesText.svg";
import SettingsText from "../../assets/images/icon/SettingsText.svg";
import StoreText from "../../assets/images/icon/StoreText.svg";
import dashboardText from "../../assets/images/icon/dashboardText.svg";
import PersonalDetail from "./PersonalDetail/index";
import EmailPreference from "./EmailPreference/index";
import PaymentDetails from "./PaymentDetails/index";
import ClassRatings from "./ClassRatings/index";
import "./style.css";
import StudentProfile from "./PersonalDetail/StudentProfle/index";
import StudentPaymentDetails from "./PaymentDetails/StudentPaymentDetails/index";
import ClassRatingsStudent from "./ClassRatings/StdentRatings/index";
import { useHistory } from "react-router";
import ChatIcon from "../../assets/images/chat/chat.svg";
import ChatText from "../../assets/images/chat/Chat word.svg";
import NavbarLanding from "../NavbarLanding";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import SessionPreferences from "./SessionPreferences";

export default function AccountSettings(props) {
    const history = useHistory();
    const currentUserRole = JSON.parse(localStorage.getItem("auth"))?.user
        ?.role;
    const [user, setUser] = useState();
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [sessions, setSessions] = useState();
    const [lastSession, setLastSession] = useState();
    const [sessionID, setSessionID] = useState(undefined);
    const location = useLocation();
    const [leaveModal, setLeaveModal] = useState(false);
    const [unsaveDetails, setUnsaveDetails] = useState(false);
    const [changeFlag, setChangeFlag] = useState(false);
    const [changeFlagStudent, setChangeFlagStudent] = useState(false);
    const [getUser, setGetUser] = useState(false);
    const [activeTab, setActiveTab] = useState(null);
    const [redirectTo, setRedirectTo] = useState("accountsettings");
    const [tabValue, setTabValue] = useState("personalDetails");

    useEffect(() => {
        const getSessionInfo = async () => {
            await axios
                .get(`/api/v1/sessions-current`)
                .then(async (currentSessionsResult) => {
                    await axios
                        .get(`/api/v1/sessions/students`)
                        .then((allUserSessionsResult) => {
                            if (
                                currentSessionsResult.data.success &&
                                allUserSessionsResult.data.success
                            ) {
                                const lastSession =
                                    currentSessionsResult.data.sessions[
                                        currentSessionsResult.data.sessions
                                            .length - 1
                                    ];
                                setSessions(
                                    allUserSessionsResult.data.sessions
                                );

                                if (lastSession) {
                                    setLastSession(lastSession);
                                    const id = lastSession._id;
                                    setSessionID(id);
                                }
                            }
                        })
                        .catch((error) => {
                            setMsg(
                                _.get(
                                    error,
                                    "response.data.title",
                                    "Something went wrong."
                                )
                            );
                            setShowSnackbar(true);
                            setTimeout(function () {
                                setMsg(null);
                            }, 3000);
                            return Promise.reject(error);
                        });
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        const getUserDetails = async () => {
            await axios
                .get("/api/v1/user")
                .then((result) => {
                    setUser(result.data.user);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        getSessionInfo();
        getUserDetails();
        const sessionIntervalObj = setInterval(getSessionInfo, 60000);

        return () => {
            clearInterval(sessionIntervalObj);
        };
        // eslint-disable-next-line
    }, []);

    const checkUnSaveDetails = (value) => {
        setUnsaveDetails(JSON.parse(localStorage.getItem("unSaveChanges")));
        setLeaveModal(JSON.parse(localStorage.getItem("unSaveChanges")));
        setTabValue(value);
        if (
            !localStorage.getItem("unSaveChanges") ||
            JSON.parse(localStorage.getItem("unSaveChanges")) === false
        ) {
            setActiveTab(value);
        }
    };

    const handleLeaveModal = (value) => {
        setLeaveModal(false);
        localStorage.setItem("unSaveChanges", false);
        currentUserRole === "teacher"
            ? setChangeFlag(false)
            : setChangeFlagStudent(false);
        setGetUser(true);
        if (redirectTo === "logout") {
            logout();
        } else if (tabValue !== "personalDetails") {
            setActiveTab(tabValue);
        } else {
            history.push(`${redirectTo}`);
        }
    };

    const handleSaveChanges = async () => {
        setActiveTab("personalDetails");
        setLeaveModal(false);
        // localStorage.setItem("unSaveChanges", false);
        if (currentUserRole === "teacher") {
            await setChangeFlag(true);
        } else {
            await setChangeFlagStudent(true);
        }
        handleLeaveModal();
    };

    const logout = () => {
        localStorage.clear();
        window.location.href = "/login";
    };

    return (
        <React.Fragment>
            {leaveModal && (
                <div
                    className={`${styles.uploadModal} text-center`}
                    style={{ top: "30%" }}
                >
                    <div className={styles.uploadArea}>
                        <p className={styles.modalHeading}>
                            There are unsaved changes on this page. <br></br>
                            Would you like to save before leaving?
                        </p>
                    </div>
                    <div className={styles.actionButtonsContainer}>
                        <div
                            className={styles.actionButton}
                            onClick={() => {
                                handleLeaveModal();
                            }}
                        >
                            Leave
                        </div>
                        <div
                            className={`${styles.actionButton} ${styles.primaryActionButton}`}
                            onClick={() => {
                                handleSaveChanges();
                            }}
                        >
                            Save Changes
                        </div>
                    </div>
                </div>
            )}
            <div>
                {" "}
                <NavbarLanding
                    userRole={currentUserRole}
                    unSaveChanges={(path) => {
                        setRedirectTo(path);
                        JSON.parse(localStorage.getItem("unSaveChanges"))
                            ? setLeaveModal(true)
                            : path === "logout"
                            ? logout()
                            : window.location.replace(path);
                    }}
                />
            </div>

            <div>
                <div>
                    <div className="accordion-wrapper">
                        <div className="container">
                            <h4>Account Settings</h4>
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle
                                            as={Button}
                                            variant="link"
                                            eventKey="0"
                                        >
                                            Personal Details
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            {currentUserRole === "teacher" && (
                                                <PersonalDetail
                                                    changeFlag={changeFlag}
                                                    getUser={getUser}
                                                />
                                            )}
                                            {currentUserRole === "student" && (
                                                <StudentProfile
                                                    changeFlag={
                                                        changeFlagStudent
                                                    }
                                                />
                                            )}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle
                                            as={Button}
                                            variant="link"
                                            eventKey="1"
                                        >
                                            Email Preference
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            <EmailPreference />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                {currentUserRole === "student" && (
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle
                                                as={Button}
                                                variant="link"
                                                eventKey="2"
                                            >
                                                Session Preferences
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="2">
                                            <Card.Body>
                                                <SessionPreferences />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )}
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle
                                            as={Button}
                                            variant="link"
                                            eventKey="3"
                                        >
                                            Plan and Payment Details
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body>
                                            {currentUserRole === "teacher" ? (
                                                <PaymentDetails />
                                            ) : (
                                                <StudentPaymentDetails />
                                            )}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle
                                            as={Button}
                                            variant="link"
                                            eventKey="4"
                                        >
                                            Class Ratings & Reviews
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="4">
                                        <Card.Body>
                                            {currentUserRole === "teacher" ? (
                                                <ClassRatings />
                                            ) : (
                                                <ClassRatingsStudent />
                                            )}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
