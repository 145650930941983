import React from "react";
import ClassCard from "../components/ClassCard";
import SearchFilterNew from "../components/SearchFilterNew";
import styles from "./index.module.scss";

const ClassesList = ({
    recommendedClasses,
    title,
    listToDisplay,
    setFilterKey,
    isSearchedFlag,
    isFilteredFlag,
    searchedTerm,
    setSearchedTerm,
    setSortKey,
    mobileView,
}) => {
    const availableClasses = mobileView
        ? recommendedClasses?.classList?.slice(0, 2)
        : recommendedClasses?.classList;

    return recommendedClasses.classList.length ||
        isSearchedFlag ||
        isFilteredFlag ? (
        <div className={styles.dataContainer}>
            <div
                className={
                    title ===
                    "Learn, play and interact with various fun enrichment topics"
                        ? `${styles.rowHeader} ${styles.fullList}`
                        : styles.rowHeader
                }
            >
                <SearchFilterNew
                    setFilterKey={setFilterKey}
                    searchedTerm={searchedTerm}
                    setSearchedTerm={setSearchedTerm}
                    setSortKey={setSortKey}
                    isFrom={"newClasses"}
                />
            </div>

            {recommendedClasses.classList.length ? (
                <div
                    className={`upcoming-section-outer ${styles.classListContainer}`}
                >
                    <p className={`main-title ${styles.titleText}`}>{title}</p>
                    <ClassCard classList={availableClasses} />
                </div>
            ) : null}
        </div>
    ) : null;
};

export default ClassesList;
