export const ImageFormats = ["JPEG", "GIF", "SVG", "TIF", "PNG", "JPG"];

export const VideoFormats = ["MP4", "AVI", "MOV"];

export const DocumentFormats = [
    "PDF",
    "DOC",
    "DOCX",
    "HTML",
    "XLS",
    "XLSX",
    "TXT",
    "PPT",
    "ODP",
    "KEY",
];

export const MusicFormats = ["M4A", "MP3", "WAVE"];
