import _ from "lodash";
import React, { useState } from "react";
import styles from "./index.module.scss";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import defaultImage from "../../../assets/images//DefaultProfilePhoto.svg";

const CarouselSmall = ({
    carouselImgs,
    onClickEvent,
    specialClass,
    autoPlay,
    controls,
    playOnHover,
    smallCarousel
}) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const handleClick = (path) => {
        path === "left"
            ? setCurrentSlide(
                currentSlide > 0 ? currentSlide - 1 : carouselImgs.length - 1
            )
            : setCurrentSlide(
                currentSlide < carouselImgs.length - 1 ? currentSlide + 1 : 0
            );
    };

    const checkExtension = (source) => {
        const ext = source.split(".");
        return _.last(ext);
    };

    return (
        <div className={smallCarousel ? styles.carouselPageNew : styles.carouselPage}>
            <article className={styles.carouselWrapper} onClick={onClickEvent}>
                <div
                    className={styles.carouselSlider}
                    style={{
                        transform: `translateX(-${currentSlide * 100}%)`,
                    }}
                >
                    {carouselImgs?.length > 0 ? (
                        carouselImgs?.map((caroImgObj, caroIdx) => {
                            return (
                                <div
                                    className={`${styles.slide} ${styles[specialClass]}`}
                                    key={caroIdx}
                                >
                                    {[
                                        "gif",
                                        "mp4",
                                        "webm",
                                        "m4p",
                                        "m4v",
                                        "mp4a",
                                        "mpeg",
                                    ].includes(
                                        checkExtension(caroImgObj.location)
                                    ) ? (
                                        <video
                                            src={caroImgObj.location}
                                            autoPlay={autoPlay}
                                            loop
                                            alt="carousel"
                                            controls={controls}
                                            onMouseOver={(event) =>
                                                playOnHover
                                                    ? event.target.play()
                                                    : null
                                            }
                                            onMouseOut={(event) =>
                                                playOnHover
                                                    ? event.target.pause()
                                                    : null
                                            }
                                        />
                                    ) : (
                                        <img
                                            src={caroImgObj.location}
                                            alt="carousel"
                                        />
                                    )}
                                </div>
                            );
                        })
                    ) : (
                        <img
                            src={defaultImage}
                            alt="Carousel"
                            className={`${styles.smallCarouselDefaultImage} ${styles[specialClass]}`}
                        />
                    )}
                </div>
            </article>
            {carouselImgs?.length > 1 ? (
                <button
                    className={smallCarousel ? styles.leftBtn : styles.leftBtnClose}
                    onClick={() => handleClick("left")}
                    style={{ left: "0.5rem" }}
                >
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        className={styles.icon}
                    />
                </button>
            ) : null}
            {carouselImgs?.length > 1 ? (
                <button
                    className={smallCarousel ? styles.rightBtn : styles.rightBtnClose}
                    onClick={() => handleClick()}
                    style={{ right: "0.5rem" }}
                >
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        className={styles.icon}
                    />
                </button>
            ) : null}
        </div>
    );
};

export default CarouselSmall;
