import React from "react";
import styles from "./index.module.scss";

const WarningModal = ({ handleShowWarningModal, showWarningModal }) => {
    return (
        <div className={styles.warningModalOuterContainer}>
            <div className={styles.warningModalContainer}>
                <div className={styles.contentContainer}>
                    <div className={styles.contentLine}>
                        This block has not been completed.
                    </div>
                    <div className={styles.contentLine}>
                        Would you still like to leave this page?
                    </div>
                </div>

                <div className={styles.actionButtonsContainer}>
                    <div
                        className={styles.actionButton}
                        onClick={() =>
                            handleShowWarningModal(
                                true,
                                showWarningModal.type !== "closeButton"
                            )
                        }
                    >
                        Leave
                    </div>
                    <div
                        className={`${styles.actionButton} ${styles.primaryActionButton}`}
                        onClick={() => handleShowWarningModal(false)}
                    >
                        Continue Editing
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WarningModal;
