import React from "react";
import styles from "./index.module.scss";
import moreClass from "../../../../assets/images/NewDesign/moreClass.png";
import TeacherClassCard from "../TeacherClassCard";

export default function RelatedClasses({ classList, title }) {
    return (
        <div>
            <div className={styles.container}>
                <h3>{!!title ? title : "Related classes"}</h3>
                <TeacherClassCard classList={classList} teacherPage={false} />
                <img
                    src={moreClass}
                    onClick={() => (window.location.href = "/classes")}
                    style={{ marginTop: "6rem" }}
                />
            </div>
        </div>
    );
}
