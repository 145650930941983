import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Logo from "../../assets/images/LOGO (3).svg";
import Stars from "../../assets/images/stars_image@2x.png";
import styles from "./bookingConfirmation.module.css";
import Snackbar from "../../components/Snackbar";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan, faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

export default () => {
    const history = useHistory();
    const inviteId = useParams().id;
    const [session, setSession] = useState();
    const [gallery, setGallery] = useState();
    const [imgFlag, setImgFlag] = useState(0);
    const [invite, setInvite] = useState();
    const [invitee, setInvitee] = useState();
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    // Not in use

    useEffect(() => {
        const getInviteInfo = async () => {
            await axios
                .get(`/api/v1/invite/${inviteId}`)
                .then(async (inviteRes) => {
                    const invite = inviteRes.data.data.invite;
                    setInvite(invite);
                    const sessionRes = await axios
                        .get(`/api/v1/session/${invite._session._id}`)
                        .then()
                        .catch((error) => {
                            setMsg(
                                _.get(
                                    error,
                                    "response.data.title",
                                    "Something went wrong."
                                )
                            );
                            setShowSnackbar(true);
                            setTimeout(function () {
                                setMsg(null);
                            }, 3000);
                            return Promise.reject(error);
                        });
                    const session = sessionRes.data.data.session;
                    setSession(session);
                    await axios
                        .get(`/api/v1/user?email=${invite._invitee}`)
                        .then(async (inviteeRes) => {
                            setInvitee(inviteeRes.data.user);
                            if (session.image_ids) {
                                let galleryData = {
                                    selectedMediaArray: session.image_ids,
                                    userId: "",
                                };
                                await axios
                                    .post(
                                        `/api/v1/user/gallery/selected`,
                                        galleryData
                                    )
                                    .then((imgRes) => {
                                        if (imgRes.data.success) {
                                            setGallery(
                                                imgRes.data.data.newArray
                                            );
                                        }
                                    })
                                    .catch((error) => {
                                        setMsg(
                                            _.get(
                                                error,
                                                "response.data.title",
                                                "Something went wrong."
                                            )
                                        );
                                        setShowSnackbar(true);
                                        setTimeout(function () {
                                            setMsg(null);
                                        }, 3000);
                                        return Promise.reject(error);
                                    });
                            }
                        })
                        .catch((error) => {
                            setMsg(
                                _.get(
                                    error,
                                    "response.data.title",
                                    "Something went wrong."
                                )
                            );
                            setShowSnackbar(true);
                            setTimeout(function () {
                                setMsg(null);
                            }, 3000);
                            return Promise.reject(error);
                        });
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        getInviteInfo();
        // eslint-disable-next-line
    }, []);

    let startDate = "";
    let startTime = "";
    let duration = "";
    let studentName = "";

    if (session) {
        startDate = moment
            .utc(session.configuration.session_start_time, "DD/MM/YYYY hh:mm a")
            .local()
            .format("DD MMMM YYYY, dddd");
        const generatedStartTime = moment.utc(
            session.configuration.session_start_time,
            "DD/MM/YYYY hh:mm a"
        );
        const tz = moment.tz(moment.tz.guess()).zoneAbbr();
        startTime = generatedStartTime.local().format("HH:mm a") + " " + tz;
        duration = {
            hours:
                session.configuration.session_duration > 60
                    ? Math.floor(
                          session.configuration.session_duration / 60
                      ).toString() + " hours"
                    : "",
            minutes:
                (session.configuration.session_duration % 60).toString() +
                " minutes",
        };

        if (invite && invitee) {
            let newStudentId = "";
            session.students_ids.forEach((parent) => {
                if (parent.id === invitee._id) {
                    newStudentId =
                        parent.students[parent.students.length - 1].id;
                }
            });
            const students = invitee.students;
            studentName = students.find(
                (student) => student._id === newStudentId
            ).studentname;
        }
    }

    const decreaseFlag = () => {
        if (imgFlag > 0) {
            setImgFlag(imgFlag - 1);
        } else {
            setImgFlag(gallery.length - 1);
        }
    };

    const increaseFlag = () => {
        if (imgFlag < gallery.length - 1) {
            setImgFlag(imgFlag + 1);
        } else {
            setImgFlag(0);
        }
    };

    return (
        <div style={{ height: "100vh", overflow: "hidden" }}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <div
                className={styles.logoContainer}
                onClick={() => history.push("/")}
            >
                <img src={Logo} className={styles.studentLogoBg} alt="Logo" />
                <h1 className={styles.logoText}>
                    plassroom<sup>+</sup>
                </h1>
                <p className={styles.logoSubText}>Register for class</p>
            </div>

            <img className={styles.starsImg} src={Stars} alt="Stars"></img>
            <div className={styles.parentWrapper}>
                <div className={styles.outerBlock}>
                    <div className={styles.wrapper}>
                        <Row className={styles.content}>
                            <Col xs={8} className={styles.leftCol}>
                                <h1
                                    className={styles.heading}
                                >{`You have successfully booked ${studentName} into ${session?.class_name}.`}</h1>
                                <p className={styles.description}>
                                    Please check your email for your<br></br>
                                    1. Booking confirmation<br></br>
                                    2. List of things to prepare before class as
                                    provided by your teacher (if any) and
                                    <br></br>
                                    3. Receipt of payment<br></br>
                                </p>
                                <div className={styles.classDetails}>
                                    <Row>
                                        <Col className={styles.time}>
                                            <p
                                                className={styles.timeText}
                                            >{`${startDate}`}</p>
                                            <p
                                                className={styles.timeText}
                                            >{`${startTime} (${duration.hours}${duration.minutes})`}</p>
                                        </Col>
                                        <Col className={styles.studentDetails}>
                                            <p
                                                className={
                                                    styles.descriptionText
                                                }
                                            >{`Students age ${session?.configuration.age_group} years`}</p>
                                            <p
                                                className={
                                                    styles.descriptionText
                                                }
                                            >{`${session?.configuration.max_students} students maximum per class`}</p>
                                        </Col>
                                    </Row>
                                </div>
                                <p className={styles.bottomText}>
                                    See you at Plassroom+!
                                </p>
                            </Col>
                            <Col xs={4} className={styles.rightCol}>
                                <div className={styles.classImgsDiv}>
                                    <div className={styles.classImgs}>
                                        {gallery?.length > 1 && (
                                            <>
                                                <FontAwesomeIcon
                                                    icon={faLessThan}
                                                    onClick={decreaseFlag}
                                                    className={styles.leftArrow}
                                                />
                                                <FontAwesomeIcon
                                                    icon={faGreaterThan}
                                                    onClick={increaseFlag}
                                                    className={
                                                        styles.rightArrow
                                                    }
                                                />
                                            </>
                                        )}
                                        <div className={styles.rightclassblock}>
                                            {gallery?.map((obj, index) => {
                                                const fileType = obj.location
                                                    .split(".")
                                                    .pop();
                                                return (
                                                    <>
                                                        {fileType == "mp4"
                                                            ? imgFlag ===
                                                                  index && (
                                                                  <video
                                                                      className={
                                                                          styles.coverImage
                                                                      }
                                                                      src={
                                                                          obj.location
                                                                      }
                                                                      controls
                                                                  ></video>
                                                              )
                                                            : imgFlag ===
                                                                  index && (
                                                                  <img
                                                                      className={
                                                                          styles.coverImage
                                                                      }
                                                                      src={
                                                                          obj.location
                                                                      }
                                                                  />
                                                              )}
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
};
