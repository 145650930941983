import React, { useRef, useEffect, useState } from "react";
import moment from "moment";
import "./Whiteboard.css";
import Eraser from "../../assets/images/eraser.svg";

const Whiteboard = ({ socket, streams, role, myMarkerColor, drawData }) => {
    const [drawOrder] = useState([]);
    const [drawing, setDrawing] = useState(false);
    const canvasRef = useRef(null);
    const [lastSyncTime, setLastSyncTime] = useState(moment.utc());
    const [myDrawingOrder, setMyDrawingOrder] = useState([]);
    const [current, setCurrent] = useState({});
    const [color, setColor] = useState("black");
    const [width, setWidth] = useState("2");
    const [mode, setMode] = useState("pen");
    const [option, setOption] = useState(false);
    const [rectStatus, setRectStatus] = useState();

    const fitToContainer = () => {
        // const canvas = document.getElementById("drawingCanvas");
        // canvas.width = canvas.offsetWidth;
        // canvas.height = canvas.offsetHeight;
        if (drawData) {
            const canvas = document.getElementById("drawingCanvas");
            // const w = canvas.offsetWidth;
            // const h = canvas.offsetHeight;
            const context = canvas.getContext("2d");
            drawData.forEach((arr) => {
                context.beginPath();
                // var xDeviation = 0;
                // var yDeviation = 0;
                // xDeviation = document.querySelector("canvas").getBoundingClientRect().x;
                // yDeviation = document.querySelector("canvas").getBoundingClientRect().y;

                context.moveTo(arr[0] * 250, arr[1] * 152);
                context.lineTo(arr[2] * 250, arr[3] * 152);
                context.strokeStyle = arr[4];
                context.lineWidth = 2;
                context.stroke();
                context.closePath();
            });
        }
    };
    useEffect(() => {
        fitToContainer();
        // eslint-disable-next-line
    }, [drawData]);

    useEffect(() => {
        const intervalID = setInterval(() => {
            const currentTime = moment.utc();
            const data = myDrawingOrder.filter(
                (obj) =>
                    obj.marking_time_in_UTC.isAfter(lastSyncTime) &&
                    obj.marking_time_in_UTC.isBefore(currentTime)
            );
            socket.emit(
                "recordDrawData",
                data.map((obj) => {
                    return {
                        marked_at: obj.marking_time_in_UTC.format(),
                        draw_data: obj.draw_data,
                    };
                })
            );
            setLastSyncTime(currentTime);
        }, 3000);
        return () => {
            clearInterval(intervalID);
        };
        // eslint-disable-next-line
    }, [myDrawingOrder, lastSyncTime]);

    // const throttle = (callback, delay) => {
    //   let previousCall = new Date().getTime();
    //   return function () {
    //     const time = new Date().getTime();

    //     if (time - previousCall >= delay) {
    //       previousCall = time;
    //       callback.apply(null, arguments);
    //     }
    //   };
    // };
    var textarea;

    const onMouseDown = (e) => {
        var textobject = document.getElementsByClassName("info");

        if (mode === "text" && !textobject.length) {
            textarea = document.createElement("textarea");
            textarea.className = "info";

            document.getElementById("whiteboard_wrapper").appendChild(textarea);
            textobject = document.getElementsByClassName("info");
            textarea.style.left = `${e.clientX}px`;
            textarea.style.top = `${e.clientY}px`;
        } else if (textobject.length) {
            var canvas = document.getElementById("drawingCanvas");
            var ctx = canvas.getContext("2d");
            ctx.font = "30px Arial";
            var value = textobject[0].value;
            ctx.fillText(
                value,
                textobject[0].offsetLeft,
                textobject[0].offsetTop
            );
            textobject[0].remove();
        } else {
            setDrawing(true);
            current.x = e.clientX || e.touches[0].clientX;
            current.y = e.clientY || e.touches[0].clientY;
            setRectStatus(true);
        }
    };

    const onMouseMove = (e) => {
        // const xDeviation = document.querySelector("canvas").getBoundingClientRect()
        //   .x;
        // const yDeviation = document.querySelector("canvas").getBoundingClientRect()
        //   .y;
        // var last_mousex = current.x - xDeviation;
        // var last_mousey = current.y - yDeviation;
        if (!drawing) {
            return;
        }
        if (mode === "eraser") {
            // var mousex = parseInt(e.clientX - xDeviation);
            // var mousey = parseInt(e.clientY - yDeviation);
            // const canvas = document.getElementById("drawingCanvas");
            // const context = canvas.getContext("2d");
            // context.beginPath();
            // context.globalCompositeOperation = "destination-out";
            // context.lineWidth = 10;
            // context.moveTo(last_mousex, last_mousey);
            // context.lineTo(mousex, mousey);
            // context.lineJoin = context.lineCap = "round";
            // context.stroke();
            // last_mousex = mousex;
            // last_mousey = mousey;
        } else if (mode === "rectangle") {
            // var mouseX = parseInt(e.clientX);
            // var mouseY = parseInt(e.clientY);
            // const canvas = document.getElementById("drawingCanvas");
            // const context = canvas.getContext("2d");
            // context.globalCompositeOperation = "source-over";
            // if (current.x < e.clientX) {
            //   context.clearRect(
            //     current.x - xDeviation,
            //     current.y - yDeviation,
            //     e.clientX - current.x,
            //     e.clientY - current.y
            //   );
            // } else if (current.x > e.clientX) {
            //   context.clearRect(
            //     e.clientX - xDeviation,
            //     e.clientY - yDeviation,
            //     current.x - e.clientX,
            //     current.y - e.clientY
            //   );
            // }
            // var width = mouseX - current.x;
            // var height = mouseY - current.y;
            // if (getx > e.clientX) {
            //   context.clearRect(
            //     current.x - xDeviation - 2,
            //     current.y - yDeviation - 2,
            //     getx + 4 - current.x,
            //     gety + 4 - current.y
            //   );
            // } else if (getx < e.clientX) {
            //   context.clearRect(
            //     getx - xDeviation - 4,
            //     gety - yDeviation - 4,
            //     current.x - getx + 10,
            //     current.y - gety + 10
            //   );
            // }
            // getx = e.clientX;
            // gety = e.clientY;
            // context.strokeRect(
            //   current.x - xDeviation,
            //   current.y - yDeviation,
            //   width,
            //   height
            // );
        } else if (mode === "circle") {
            // var mouseX = parseInt(e.clientX - xDeviation);
            // var mouseY = parseInt(e.clientY - yDeviation);
            // const canvas = document.getElementById("drawingCanvas");
            // const context = canvas.getContext("2d");
            // context.globalCompositeOperation = "source-over";
            // if (getx > e.clientX) {
            //   context.clearRect(
            //     last_mousex + 3,
            //     last_mousey + 3,
            //     mouseX - last_mousex,
            //     mouseY - last_mousey
            //   ); //clear canvas
            // }
            // context.clearRect(
            //   last_mousex - 3,
            //   last_mousey - 3,
            //   mouseX - last_mousex,
            //   mouseY - last_mousey
            // ); //clear canvas
            // context.save();
            // context.beginPath();
            // var scalex = (mouseX - last_mousex) / 2;
            // var scaley = (mouseY - last_mousey) / 2;
            // context.scale(scalex, scaley);
            // var centerx = last_mousex / scalex + 1;
            // var centery = last_mousey / scaley + 1;
            // context.arc(centerx, centery, 1, 0, 2 * Math.PI);
            // context.restore();
            // context.stroke();
            // getx = e.clientX;
        } else if (mode === "triangle") {
            // var mouseX = parseInt(e.clientX - xDeviation);
            // var mouseY = parseInt(e.clientY - yDeviation);
            // const canvas = document.getElementById("drawingCanvas");
            // const context = canvas.getContext("2d");
            // context.globalCompositeOperation = "source-over";
            // // context.clearRect(0, 0, canvas.width, canvas.height);
            // context.globalCompositeOperation = "source-over";
            // context.clearRect(
            //   current.x - xDeviation,
            //   current.y - yDeviation,
            //   250,
            //   mouseY - last_mousey
            // ); //clear canvas
            // context.beginPath();
            // context.moveTo(current.x - xDeviation, current.y - yDeviation);
            // context.lineTo(current.x - xDeviation + 250, current.y - yDeviation);
            // context.lineTo(mouseX, mouseY);
            // context.closePath();
            // // context.lineWidth = 5;
            // context.strokeStyle = "#666666";
            // context.stroke();
        } else {
            drawLine(
                current.x,
                current.y,
                e.clientX || e.touches[0].clientX,
                e.clientY || e.touches[0].clientY,
                color,
                true
            );
            current.x = e.clientX || e.touches[0].clientX;
            current.y = e.clientY || e.touches[0].clientY;
        }
    };

    const onMouseUp = (e) => {
        if (mode === "rectangle" || mode === "circle" || mode === "triangle") {
            setDrawing(false);
            setRectStatus(false);
            return;
        }
        if (!drawing) {
            return;
        }
        setDrawing(false);
        drawLine(
            current.x,
            current.y,
            e.clientX || e.touches[0].clientX,
            e.clientY || e.touches[0].clientY,
            color,
            true
        );
    };

    // ----------------------- socket.io connection ----------------------------

    // const onDrawingEvent = (data) => {
    //   const canvas = document.getElementById("drawingCanvas");

    //   const cordinates = data.data;
    //   canvas.style.width = "100%";
    //   canvas.style.height = "100%";
    //   const w = canvas.offsetWidth;
    //   const h = canvas.offsetHeight;
    //   drawLine(
    //     cordinates.x0 * w,
    //     cordinates.y0 * h,
    //     cordinates.x1 * w,
    //     cordinates.y1 * h,
    //     cordinates.color,
    //     null,
    //     w / cordinates.canvasWidth,
    //     h / cordinates.canvasHeight
    //   );
    // };

    // const onClearWhiteboard = () => {
    //   const canvas = document.getElementById("drawingCanvas");
    //   const context = canvas.getContext("2d");
    //   context.clearRect(0, 0, canvas.width, canvas.height);
    // };

    // if (socket) {
    //   socket.on("drawing", onDrawingEvent);
    //   socket.on("clearWhiteboard", onClearWhiteboard);
    // }

    const drawLine = (x0, y0, x1, y1, color, emit) => {
        const canvas = document.getElementById("drawingCanvas");

        const context = canvas.getContext("2d");
        context.globalCompositeOperation = "source-over";
        drawOrder.push([x0, y0, x1, y1, color, emit]);

        context.beginPath();
        var xDeviation = 0;
        var yDeviation = 0;
        if (emit) {
            xDeviation = document
                .querySelector("canvas")
                .getBoundingClientRect().x;
            yDeviation = document
                .querySelector("canvas")
                .getBoundingClientRect().y;
        }

        context.moveTo(x0 - xDeviation, y0 - yDeviation);
        context.lineTo(x1 - xDeviation, y1 - yDeviation);
        context.strokeStyle = color;
        context.lineWidth = width;
        context.stroke();
        context.closePath();

        if (!emit) {
            return;
        }

        const w = canvas.offsetWidth;
        const h = canvas.offsetHeight;
        // if (streams) {
        //   streams.forEach((obj, index) => {
        //     var nameObj = JSON.parse(obj.name);
        //     socket.emit("drawing", {
        //       action: "drawing",
        //       payload: {
        //         userId: nameObj._id,
        //         data: {
        //           x0: (x0 - xDeviation) / w,
        //           y0: (y0 - yDeviation) / h,
        //           x1: (x1 - xDeviation) / w,
        //           y1: (y1 - yDeviation) / h,
        //           canvasWidth: document.getElementById("drawingCanvas").offsetWidth,
        //           canvasHeight: document.getElementById("drawingCanvas")
        //             .offsetHeight,
        //           color: color,
        //         },
        //         index,
        //       },
        //     });
        //   });
        // }
        var newMyDrawingOrder = [...myDrawingOrder];
        newMyDrawingOrder.push({
            marking_time_in_UTC: moment.utc(),
            draw_data: [
                (x0 - xDeviation) / w,
                (y0 - yDeviation) / h,
                (x1 - xDeviation) / w,
                (y1 - yDeviation) / h,
                color,
            ],
        });
        setMyDrawingOrder(newMyDrawingOrder);
    };

    const clear = () => {
        setOption(false);
        const canvas = document.getElementById("drawingCanvas");
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
    };

    const handleColor = (value) => {
        setMode("pen");
        setColor(value);
    };

    const handleWidth = (value) => {
        setWidth(value);
    };

    const erase = () => {
        setMode("eraser");
        setOption(false);
    };

    const textArea = () => {
        setMode("text");
    };

    const handleClear = () => {
        setOption(!option);
    };

    const handleRectangle = (e) => {
        e.preventDefault();
        setMode("rectangle");
    };

    const handleCircle = () => {
        setMode("circle");
    };

    const handleTriangle = () => {
        setMode("triangle");
    };

    return (
        <div className="Whiteboard_main" id="whiteboard_wrapper">
            {role === "teacher" ? (
                <div className="whiteboard_control">
                    {role === "teacher" ? (
                        <div className="whiteboard_shape_wrapper">
                            <p
                                onClick={(event) => {
                                    handleRectangle(event);
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                Rectangle
                            </p>
                            <p
                                onClick={() => {
                                    handleCircle();
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                Circle
                            </p>
                            <p
                                onClick={() => {
                                    handleTriangle();
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                Triangle
                            </p>
                        </div>
                    ) : null}
                    {role === "teacher" ? (
                        <div className="whiteboard_color_wrapper">
                            <div
                                className="whiteboard_color"
                                onClick={() => {
                                    handleColor("red");
                                }}
                                style={{
                                    backgroundColor: "red",
                                    cursor: "pointer",
                                }}
                            ></div>
                            <div
                                className="whiteboard_color"
                                onClick={() => {
                                    handleColor("blue");
                                }}
                                style={{
                                    backgroundColor: "blue",
                                    cursor: "pointer",
                                }}
                            ></div>
                            <div
                                className="whiteboard_color"
                                onClick={() => {
                                    handleColor("green");
                                }}
                                style={{
                                    backgroundColor: "green",
                                    cursor: "pointer",
                                }}
                            ></div>
                            <div
                                className="whiteboard_color"
                                onClick={() => {
                                    handleColor("yellow");
                                }}
                                style={{
                                    backgroundColor: "yellow",
                                    cursor: "pointer",
                                }}
                            ></div>
                        </div>
                    ) : null}

                    {role === "teacher" ? (
                        <div className="whiteboard_width_wrapper">
                            <div
                                className="whiteboard_width"
                                onClick={() => {
                                    setMode("pen");
                                    handleWidth("2");
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                Thin
                            </div>
                            <div
                                className="whiteboard_width"
                                onClick={() => {
                                    setMode("pen");
                                    handleWidth("4");
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                Moderate
                            </div>
                            <div
                                className="whiteboard_width"
                                onClick={() => {
                                    setMode("pen");
                                    handleWidth("6");
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                Thick
                            </div>
                        </div>
                    ) : null}
                    {role === "teacher" ? (
                        <div className="clear_wrapper">
                            <img
                                src={Eraser}
                                alt="eraser"
                                id="eraser"
                                onClick={() => handleClear()}
                            />
                            <>
                                {option && (
                                    <div className="clear_option">
                                        <p onClick={clear}>Clear all</p>
                                        <p onClick={erase}>Eraser</p>
                                    </div>
                                )}
                            </>
                        </div>
                    ) : null}

                    {role === "teacher" ? (
                        <div className="whiteboard_text">
                            <p style={{ cursor: "pointer" }} onClick={textArea}>
                                Text
                            </p>
                        </div>
                    ) : null}
                </div>
            ) : null}

            <canvas
                style={{
                    height: "50%",
                    width: "50%",
                }}
                onLoad={fitToContainer}
                ref={canvasRef}
                id="drawingCanvas"
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseOut={onMouseUp}
                // onDoubleClick={onMouseDoubleClick}
                onMouseMove={onMouseMove}
            />
        </div>
    );
};

export default Whiteboard;
