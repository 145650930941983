import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import styles from "./customselect.module.css";
import Delete from "../../assets/images/exit.svg";

export default ({
    width,
    setObjective,
    learningObjectives,
    tempData,
    isPublished,
}) => {
    const [objectives, setObjectives] = useState("");
    const [show, setShow] = useState(false);
    const [left, setLeft] = useState(0);
    const [top, setTop] = useState(0);
    const [widths, setWidths] = useState(0);

    let containerRef = React.createRef();

    useEffect(() => {
        let objectivesString = "";
        learningObjectives.forEach(
            (objective) =>
                (objectivesString = objectivesString + objective + ", ")
        );
        objectivesString = objectivesString.slice(0, -2);
        setObjectives(objectivesString);
        // eslint-disable-next-line
    }, [learningObjectives]);

    const handleAdd = (element) => {
        setObjective([...learningObjectives, element]);
    };

    const handleToggleShow = () => {
        setShow(!show && !isPublished);
    };

    const deleteItem = (element) => {
        const newData = [...learningObjectives];
        const index = newData.indexOf(element);
        newData.splice(index, 1);
        setObjective(newData);
    };

    return (
        <div
            ref={containerRef}
            style={{
                minWidth: `${width}`,
                display: "flex",
                position: "relative",
                padding: "5px 7px",
            }}
            className={styles.inputWrapper}
            tabIndex="0"
            onBlur={() => setShow(false)}
        >
            <input
                type="text"
                className={styles.customInput}
                placeholder="Please select tag"
                value={objectives}
                disabled={true}
            />

            {!isPublished && (
                <FontAwesomeIcon
                    className={
                        show
                            ? `${styles.dropicon} ${styles.dropdownopen}`
                            : styles.dropicon
                    }
                    style={{ width: "30px", cursor: "pointer" }}
                    icon={faAngleDown}
                    onClick={(ev) => {
                        handleToggleShow();
                        setTop(
                            containerRef.current.getBoundingClientRect().y +
                                containerRef.current.getBoundingClientRect()
                                    .height +
                                10
                        );
                        setWidths(
                            containerRef.current.getBoundingClientRect().width
                        );
                        setLeft(containerRef.current.getBoundingClientRect().x);
                    }}
                />
            )}
            {show && (
                <div
                    className={styles.customeDropContainer}
                    style={{
                        width: widths,
                    }}
                >
                    {tempData?.map((element, index) => {
                        return (
                            <p key={index} className={styles.item}>
                                <span onClick={() => handleAdd(element)}>
                                    {element}
                                </span>
                                {learningObjectives.includes(element) ? (
                                    <img
                                        src={Delete}
                                        className={styles.delete}
                                        onClick={() => deleteItem(element)}
                                        alt=""
                                    />
                                ) : null}
                            </p>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
