import axios from "axios";
import React, { useRef, useEffect, useState } from "react";
import styles from "./WhiteboardByNirajan.module.scss";
import Eraser from "../../assets/images/eraser.svg";
import Circle from "../../assets/images/circle.svg";
import CircleSelected from "../../assets/images/circle_selected.svg";
import Large from "../../assets/images/large.svg";
import LargeSelected from "../../assets/images/large_selected.svg";
import Line from "../../assets/images/line.svg";
import LineSelected from "../../assets/images/line_selected.svg";
import Medium from "../../assets/images/medium.svg";
import MediumSelected from "../../assets/images/medium_selected.svg";
import Rectangle from "../../assets/images/rectangle.svg";
import RectangleSelected from "../../assets/images/rectangle_selected.svg";
import Small from "../../assets/images/small.svg";
import SmallSelected from "../../assets/images/small_selected.svg";
import Triangle from "../../assets/images/triangle.svg";
import TriangleSelected from "../../assets/images/triangle_selected.svg";
import Settings from "../WhiteboardSetting//index";
import Snackbar from "../../components/Snackbar";
import moment from "moment";
import _ from "lodash";

const WhiteboardNirajan = ({
    drawData,
    socket,
    session,
    participants,
    selectedMedia,
    key,
    position,
    fitToScreen,
    setFitToScreen,
    studentAccess,
    team,
    control,
    requiredWB,
    orientation,
    permission,
    myWhiteboard,
    panelID,
    setPosition,
}) => {
    const userIds = participants.map((participant) => {
        var identityObj = JSON.parse(participant.identity);
        return identityObj._id;
    });

    const drawOrder = useRef([]);
    const mousedown = useRef(undefined);

    const currentData = JSON.parse(localStorage.getItem("auth")).user._id;
    const role = JSON.parse(localStorage.getItem("auth")).user.role;
    const [access, setAccess] = useState([]);
    const [tooltype, setTooltype] = useState("draw");
    const [lineWidth, setLineWidth] = useState("2");
    const [color, setColor] = useState("#333232");
    const [option, setOption] = useState(false);
    const [settings, setSettings] = useState(false);
    const [lastSyncTime, setLastSyncTime] = useState(moment.utc());
    const [up, setUp] = useState(2);
    const [left, setLeft] = useState(3);
    const [penColor, setPenColor] = useState();
    const [teacherName, setTeacherName] = useState("");
    const [studentsData, setStudentsData] = useState([]);
    const [data, setData] = useState([]);
    const [colorType, setColorType] = useState("all");
    const [individualBgColor, setIndividualBgColor] = useState([]);
    const [isStudentNotAccessed, setIsStudentNotAccessed] = useState(false);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const [drawCount, setDrawCount] = useState(0);
    const [lastMouseUpTime, setLastMouseUpTime] = useState(moment.utc());
    const [visibleControl, setVisibleControl] = useState(false);
    const canvasRef = useRef();
    const shapeRef = useRef();
    const wrapperRef = useRef();

    var canvas = canvasRef.current;
    var shapeCanvas = shapeRef.current;

    useEffect(() => {
        const getUserDetails = async () => {
            await axios
                .get("/api/v1/user")
                .then((result) => {
                    let resTeacherName =
                        result.data.user?.preferedName || "Teacher";
                    if (resTeacherName.length > 7) {
                        resTeacherName = resTeacherName.slice(0, 7) + "...";
                    }
                    setTeacherName(resTeacherName);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        getUserDetails();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let isStudentNotAccessedLocal = false;
        if (studentAccess.some((student) => student === currentData)) {
            isStudentNotAccessedLocal = true;
        }

        setIsStudentNotAccessed(isStudentNotAccessedLocal);
        // eslint-disable-next-line
    }, [studentAccess, currentData]);

    useEffect(() => {
        const getParentInfo = async () => {
            await axios
                .post(`/api/v1/users`, {
                    data: session.students_ids,
                })
                .then((result) => {
                    setData(result.data.data.users);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        if (session) getParentInfo();
        // eslint-disable-next-line
    }, [participants, session]);

    useEffect(() => {
        const getUserDetails = async () => {
            await axios
                .post(`/api/v1/parents`, {
                    data: userIds,
                })
                .then((studentsResult) => {
                    const users = studentsResult.data.data.users;
                    let filteredUsers = [];

                    for (let user in users) {
                        const tempData = {};
                        tempData.name =
                            users[user].students[0].studentpreferedname;
                        tempData._id = users[user].students[0]._id;
                        tempData.parentId = users[user]._id;

                        filteredUsers.push(tempData);
                    }

                    setStudentsData(filteredUsers);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        if (role === "teacher") getUserDetails();
        // eslint-disable-next-line
    }, [userIds.length]);

    if (canvas) {
        var width = wrapperRef.current.clientWidth;
        var height = wrapperRef.current.offsetHeight;
        var ctx = canvas.getContext("2d");
        var last_mousex;
        var last_mousey;
        var mousex;
        var mousey;
        var operation;
        var coordinates;
    }

    if (shapeCanvas) {
        var shapeCtx = shapeCanvas.getContext("2d");
        var shapeCanvasx = document
            .getElementById("myCanvasForShape")
            .getBoundingClientRect().x;
        var shapeCanvasy = document
            .getElementById("myCanvasForShape")
            .getBoundingClientRect().y;
    }

    const fitToContainer = (positionData) => {
        if (positionData.length > 0 && canvasRef.current) {
            let canvasdraw = canvasRef.current;
            let ctxdraw = canvasdraw.getContext("2d");
            let obj = positionData;

            if (obj[7] === "draw" || obj[7] === "erase") {
                ctxdraw.beginPath();
                ctxdraw.globalCompositeOperation = obj[6];
                if (obj[6] === "source-over") {
                    ctxdraw.strokeStyle = obj[4];
                }
                if (obj[4] === null) {
                    ctxdraw.strokeStyle = "black";
                }
                ctxdraw.lineWidth = obj[5];

                if (orientation === "bottom") {
                    ctxdraw.moveTo(obj[0], obj[1] - height);
                    ctxdraw.lineTo(obj[2], obj[3] - height);
                } else if (orientation === "right") {
                    ctxdraw.moveTo(obj[0] - width, obj[1]);
                    ctxdraw.lineTo(obj[2] - width, obj[3]);
                } else {
                    ctxdraw.moveTo(obj[0], obj[1]);
                    ctxdraw.lineTo(obj[2], obj[3]);
                    ctxdraw.stroke();
                }

                ctxdraw.lineJoin = ctxdraw.lineCap = "round";
            } else if (obj[7] === "rectangle") {
                ctxdraw.beginPath();
                ctxdraw.globalCompositeOperation = "source-over";
                if (orientation === "bottom") {
                    ctxdraw.rect(
                        obj[0],
                        obj[1] - height,
                        obj[2] - obj[0],
                        obj[3] - obj[1]
                    );
                } else {
                    ctxdraw.rect(
                        obj[0],
                        obj[1],
                        obj[2] - obj[0],
                        obj[3] - obj[1]
                    );
                }

                ctxdraw.strokeStyle = obj[4];
                if (obj[4] === null) {
                    ctxdraw.strokeStyle = "black";
                }
                ctxdraw.lineWidth = obj[5];
                ctxdraw.stroke();
                setDrawCount(1);
            } else if (obj[7] === "circle") {
                ctxdraw.globalCompositeOperation = "source-over";
                ctxdraw.save();
                ctxdraw.beginPath();
                var scalex = (obj[2] - obj[0]) / 2;
                var scaley = (obj[3] - obj[1]) / 2;
                ctxdraw.scale(scalex, scaley);
                var centerx = obj[0] / scalex + 1;
                if (orientation === "bottom") {
                    var centery = (obj[1] - height) / scaley + 1;
                } else {
                    var centery = obj[1] / scaley + 1;
                }

                ctxdraw.arc(centerx, centery, 1, 0, 2 * Math.PI);
                ctxdraw.restore();
                ctxdraw.strokeStyle = obj[4];
                if (obj[4] === null) {
                    ctxdraw.strokeStyle = "black";
                }
                ctxdraw.lineWidth = obj[5];
                ctxdraw.stroke();
            } else if (obj[4] === "triangle") {
                var coordinates = obj[0];
                ctxdraw.beginPath();
                ctxdraw.globalCompositeOperation = "source-over";
                if (orientation === "bottom") {
                    ctxdraw.moveTo(coordinates[0].x, coordinates[0].y - 300);
                    for (var index = 1; index < 3; index++) {
                        ctxdraw.lineTo(
                            coordinates[index].x,
                            coordinates[index].y - 300
                        );
                    }
                } else {
                    ctxdraw.moveTo(coordinates[0].x, coordinates[0].y);
                    for (var index = 1; index < 3; index++) {
                        ctxdraw.lineTo(
                            coordinates[index].x,
                            coordinates[index].y
                        );
                    }
                }

                ctxdraw.closePath();
                ctxdraw.strokeStyle = obj[1];
                if (obj[1] === null) {
                    ctxdraw.strokeStyle = "black";
                }
                ctxdraw.lineWidth = obj[2];
                ctxdraw.stroke();
            } else if (obj[4] === "text") {
                ctxdraw.globalCompositeOperation = "source-over";
                ctxdraw.font = "30px Arial";
                ctxdraw.fillStyle = obj[3];
                ctxdraw.fillText(obj[0], obj[1], obj[2]);
            }
            // });
        }
    };

    const fitToContainerDrawData = () => {
        if (drawData.length) {
            let canvasdraw = canvasRef.current;
            let ctxdraw = canvasdraw.getContext("2d");
            drawData.forEach((obj, index) => {
                if (obj[7] === "draw" || obj[7] === "erase") {
                    ctxdraw.beginPath();
                    ctxdraw.globalCompositeOperation = obj[6];
                    if (obj[6] === "source-over") {
                        ctxdraw.strokeStyle = obj[4];
                    }
                    if (obj[4] === null) {
                        ctxdraw.strokeStyle = "black";
                    }
                    ctxdraw.lineWidth = obj[5];

                    if (orientation === "bottom") {
                        ctxdraw.moveTo(obj[0], obj[1] - height);
                        ctxdraw.lineTo(obj[2], obj[3] - height);
                    } else if (orientation === "right") {
                        ctxdraw.moveTo(obj[0] - width, obj[1]);
                        ctxdraw.lineTo(obj[2] - width, obj[3]);
                    } else {
                        ctxdraw.moveTo(obj[0], obj[1]);
                        ctxdraw.lineTo(obj[2], obj[3]);
                    }

                    ctxdraw.lineJoin = ctxdraw.lineCap = "round";
                    ctxdraw.stroke();
                } else if (obj[7] === "rectangle") {
                    ctxdraw.beginPath();
                    ctxdraw.globalCompositeOperation = "source-over";
                    if (orientation === "bottom") {
                        ctxdraw.rect(
                            obj[0],
                            obj[1] - height,
                            obj[2] - obj[0],
                            obj[3] - obj[1]
                        );
                    } else {
                        ctxdraw.rect(
                            obj[0],
                            obj[1],
                            obj[2] - obj[0],
                            obj[3] - obj[1]
                        );
                    }

                    ctxdraw.strokeStyle = obj[4];
                    if (obj[4] === null) {
                        ctxdraw.strokeStyle = "black";
                    }
                    ctxdraw.lineWidth = obj[5];
                    ctxdraw.stroke();
                } else if (obj[7] === "circle") {
                    ctxdraw.globalCompositeOperation = "source-over";
                    ctxdraw.save();
                    ctxdraw.beginPath();
                    var scalex = (obj[2] - obj[0]) / 2;
                    var scaley = (obj[3] - obj[1]) / 2;
                    ctxdraw.scale(scalex, scaley);
                    var centerx = obj[0] / scalex + 1;
                    if (orientation === "bottom") {
                        var centery = (obj[1] - height) / scaley + 1;
                    } else {
                        var centery = obj[1] / scaley + 1;
                    }

                    ctxdraw.arc(centerx, centery, 1, 0, 2 * Math.PI);
                    ctxdraw.restore();
                    ctxdraw.strokeStyle = obj[4];
                    if (obj[4] === null) {
                        ctxdraw.strokeStyle = "black";
                    }
                    ctxdraw.lineWidth = obj[5];
                    ctxdraw.stroke();
                } else if (obj[4] === "triangle") {
                    var coordinates = obj[0];
                    ctxdraw.beginPath();
                    ctxdraw.globalCompositeOperation = "source-over";
                    if (orientation === "bottom") {
                        ctxdraw.moveTo(
                            coordinates[0].x,
                            coordinates[0].y - 300
                        );
                        for (var index = 1; index < 3; index++) {
                            ctxdraw.lineTo(
                                coordinates[index].x,
                                coordinates[index].y - 300
                            );
                        }
                    } else {
                        ctxdraw.moveTo(coordinates[0].x, coordinates[0].y);
                        for (var index = 1; index < 3; index++) {
                            ctxdraw.lineTo(
                                coordinates[index].x,
                                coordinates[index].y
                            );
                        }
                    }

                    ctxdraw.closePath();
                    ctxdraw.strokeStyle = obj[1];
                    if (obj[1] === null) {
                        ctxdraw.strokeStyle = "black";
                    }
                    ctxdraw.lineWidth = obj[2];
                    ctxdraw.stroke();
                } else if (obj[4] === "text") {
                    ctxdraw.globalCompositeOperation = "source-over";
                    ctxdraw.font = "30px Arial";
                    ctxdraw.fillStyle = obj[3];
                    ctxdraw.fillText(obj[0], obj[1], obj[2]);
                }
            });
        }
    };

    useEffect(() => {
        setAccess([...access, studentAccess]);
        if (position) {
            setLeft(position.left);
            setUp(position.up);
        }
        // eslint-disable-next-line
    }, [position]);

    useEffect(() => {
        const currentTime = moment.utc();
        const data = drawOrder.current.filter(
            (obj) =>
                obj.draw_data[0] &&
                obj.draw_data[1] &&
                obj.marking_time_in_UTC.isAfter(lastSyncTime) &&
                obj.marking_time_in_UTC.isBefore(currentTime)
        );

        if (panelID)
            socket.emit("recordDrawData", {
                data: data.map((obj) => {
                    return {
                        marked_at: obj.marking_time_in_UTC.format(),
                        draw_data: obj.draw_data,
                        team: team,
                        panel_id: panelID,
                    };
                }),
                panel_id: panelID,
                userIds: userIds,
            });
        setLastSyncTime(currentTime);
        // eslint-disable-next-line
    }, [drawOrder.current, lastMouseUpTime, panelID]);

    // const throttle = (callback, delay) => {
    //     let previousCall = new Date().getTime();
    //     return function () {
    //         const time = new Date().getTime();

    //         if (time - previousCall >= delay) {
    //             previousCall = time;
    //             callback.apply(null, arguments);
    //         }
    //     };
    // };

    const onMouseDown = (e) => {
        e.preventDefault();
        var textobject = document.getElementsByClassName("info");
        if (tooltype === "text" && !textobject.length) {
            var textarea = document.createElement("textarea");
            textarea.className = "info";
            document.getElementById("canvaswrapper").appendChild(textarea);
            textarea.style.left = `${e.clientX}px`;
            textarea.style.top = `${e.clientY}px`;
        } else if (textobject.length) {
            var canvas = document.getElementById("drawingCanvas");
            var ctx = canvas.getContext("2d");
            ctx.globalCompositeOperation = "source-over";
            ctx.font = "30px Arial";
            ctx.fillStyle = color;
            var value = textobject[0].value;
            ctx.fillText(
                value,
                textobject[0].offsetLeft - shapeCanvasx,
                textobject[0].offsetTop + shapeCanvasy
            );

            drawText(
                value,
                textobject[0].offsetLeft - shapeCanvasx,
                textobject[0].offsetTop + shapeCanvasy,
                color,
                tooltype
            );

            textobject[0].remove();
            setLastMouseUpTime(moment.utc());
        }

        const found = access.find((element) => element === currentData);

        if (requiredWB || found || permission) {
            last_mousex = e.clientX || e.touches[0].clientX;
            last_mousey = e.clientY || e.touches[0].clientY;
            last_mousex = last_mousex - shapeCanvasx;
            last_mousey = last_mousey - shapeCanvasy;
            mousedown.current = true;
        }
    };

    const onMouseUp = (e) => {
        e.preventDefault();
        mousedown.current = false;
        shapeCtx.clearRect(0, 0, shapeCanvas.width, shapeCanvas.height);
        if (tooltype === "draw") {
            setLastMouseUpTime(moment.utc());
        } else if (tooltype === "rectangle" && last_mousex) {
            ctx.globalCompositeOperation = "source-over";
            if (orientation === "bottom") {
                ctx.rect(
                    last_mousex,
                    last_mousey - height,
                    mousex - last_mousex,
                    mousey - last_mousey
                );
            } else {
                ctx.rect(
                    last_mousex,
                    last_mousey,
                    mousex - last_mousex,
                    mousey - last_mousey
                );
            }

            ctx.lineWidth = lineWidth;
            ctx.strokeStyle = color;
            ctx.stroke();
            var operation = "source-over";

            drawshape(
                last_mousex,
                last_mousey,
                mousex,
                mousey,
                color,
                lineWidth,
                operation,
                tooltype
            );
            setLastMouseUpTime(moment.utc());
        } else if (tooltype === "circle" && last_mousex) {
            ctx.globalCompositeOperation = "source-over";
            ctx.save();
            ctx.beginPath();
            var scalex = (mousex - last_mousex) / 2;
            var scaley = (mousey - last_mousey) / 2;
            ctx.scale(scalex, scaley);
            var centerx = last_mousex / scalex + 1;
            if (orientation === "bottom") {
                var centery = (last_mousey - height) / scaley + 1;
            } else {
                var centery = last_mousey / scaley + 1;
            }

            ctx.arc(centerx, centery, 1, 0, 2 * Math.PI);
            ctx.restore();
            ctx.lineWidth = lineWidth;
            ctx.strokeStyle = color;
            ctx.stroke();
            var operation = "source-over";
            drawshape(
                last_mousex,
                last_mousey,
                mousex,
                mousey,
                color,
                lineWidth,
                operation,
                tooltype
            );
            setLastMouseUpTime(moment.utc());
        } else if (tooltype === "triangle" && coordinates) {
            ctx.beginPath();
            ctx.globalCompositeOperation = "source-over";

            if (orientation === "bottom") {
                ctx.moveTo(coordinates[0].x, coordinates[0].y - 300);
                for (var index = 1; index < 3; index++) {
                    ctx.lineTo(
                        coordinates[index].x,
                        coordinates[index].y - 300
                    );
                }
            } else {
                ctx.moveTo(coordinates[0].x, coordinates[0].y);
                for (var index = 1; index < 3; index++) {
                    ctx.lineTo(coordinates[index].x, coordinates[index].y);
                }
            }

            ctx.closePath();
            ctx.lineWidth = lineWidth;
            ctx.strokeStyle = color;
            ctx.stroke();
            var operation = "source-over";
            drawtriangle(coordinates, color, lineWidth, operation, tooltype);
            setLastMouseUpTime(moment.utc());
        }
    };

    const onMouseMove = (e, flag) => {
        e.preventDefault();
        mousex = parseInt(
            (flag ? e.clientX : e.touches[0].clientX) - shapeCanvasx
        );
        mousey = parseInt(
            (flag ? e.clientY : e.touches[0].clientY) - shapeCanvasy
        );
        if (mousedown.current) {
            ctx.beginPath();
            if (tooltype === "draw") {
                operation = "source-over";
                if (penColor) {
                    drawLine(
                        last_mousex,
                        last_mousey,
                        mousex,
                        mousey,
                        penColor,
                        lineWidth,
                        operation,
                        tooltype
                    );
                } else {
                    drawLine(
                        last_mousex,
                        last_mousey,
                        mousex,
                        mousey,
                        color,
                        lineWidth,
                        operation,
                        tooltype
                    );
                }

                last_mousex = mousex;
                last_mousey = mousey;
            } else if (tooltype === "erase") {
                operation = "destination-out";
                drawLine(
                    last_mousex,
                    last_mousey,
                    mousex,
                    mousey,
                    color,
                    lineWidth,
                    operation,
                    tooltype
                );
                last_mousex = mousex;
                last_mousey = mousey;
            } else if (tooltype === "rectangle") {
                shapeCtx.clearRect(0, 0, shapeCanvas.width, shapeCanvas.height);
                shapeCtx.globalCompositeOperation = "source-over";
                shapeCtx.lineWidth = lineWidth;
                var width = mousex - last_mousex;
                var height = mousey - last_mousey;
                if (orientation === "bottom") {
                    shapeCtx.strokeRect(
                        last_mousex,
                        last_mousey - height,
                        width,
                        height
                    );
                } else {
                    shapeCtx.strokeRect(
                        last_mousex,
                        last_mousey,
                        width,
                        height
                    );
                }

                shapeCtx.strokeStyle = color;
            } else if (tooltype === "circle") {
                shapeCtx.save();
                shapeCtx.clearRect(0, 0, shapeCanvas.width, shapeCanvas.height);
                shapeCtx.globalCompositeOperation = "source-over";
                shapeCtx.beginPath();
                var scalex = (mousex - last_mousex) / 2;
                var scaley = (mousey - last_mousey) / 2;
                shapeCtx.scale(scalex, scaley);
                var centerx = last_mousex / scalex + 1;
                if (orientation === "bottom") {
                    var centery = (last_mousey - height) / scaley + 1;
                } else {
                    var centery = last_mousey / scaley + 1;
                }

                shapeCtx.arc(centerx, centery, 1, 0, 2 * Math.PI);
                shapeCtx.restore();
                shapeCtx.strokeStyle = color;
                shapeCtx.lineWidth = lineWidth;
                shapeCtx.stroke();
            } else if (tooltype === "triangle") {
                coordinates = [];
                var angle = 100,
                    sides = 3,
                    radius = Math.sqrt(
                        Math.pow(last_mousex - mousex, 2) +
                            Math.pow(last_mousex - mousex, 2)
                    ),
                    index = 0;
                for (index = 0; index < sides; index++) {
                    coordinates.push({
                        x: last_mousex + radius * Math.cos(angle),
                        y: last_mousey - radius * Math.sin(angle),
                    });
                    angle += (2 * Math.PI) / sides;
                }
                shapeCtx.globalCompositeOperation = "source-over";
                shapeCtx.clearRect(0, 0, shapeCanvas.width, shapeCanvas.height);
                shapeCtx.beginPath();

                if (orientation === "bottom") {
                    shapeCtx.moveTo(coordinates[0].x, coordinates[0].y - 300);
                    for (index = 1; index < sides; index++) {
                        shapeCtx.lineTo(
                            coordinates[index].x,
                            coordinates[index].y - 300
                        );
                    }
                } else {
                    shapeCtx.moveTo(coordinates[0].x, coordinates[0].y);
                    for (index = 1; index < sides; index++) {
                        shapeCtx.lineTo(
                            coordinates[index].x,
                            coordinates[index].y
                        );
                    }
                }

                shapeCtx.closePath();
                shapeCtx.lineWidth = lineWidth;
                shapeCtx.strokeStyle = color;
                shapeCtx.stroke();
            }
        }
    };

    const tool = (tool) => {
        setTooltype(tool);
    };

    const handleWidth = (value) => {
        setLineWidth(value);
    };

    const handleClear = () => {
        socket.emit("clearDrawData", {
            payload: {
                userIds,
            },
        });
        const element = document.getElementsByName("fname");

        for (let i = 0; i < element.length; i++) {
            let ctx = element[i].getContext("2d");
            ctx.clearRect(0, 0, element[i].width, element[i].height);
        }
    };

    const handleColor = (value) => {
        userIds.map((user) => {
            let data = {
                id: user,
                color: value,
            };
            setIndividualBgColor([data, ...individualBgColor]);
            socket.emit("studentColor", {
                action: "studentColor",
                payload: {
                    userIds: userIds,
                    data: data,
                },
            });
        });

        setColor(value);
    };

    const drawLine = (
        last_mousex,
        last_mousey,
        mousex,
        mousey,
        color,
        line_width,
        operation,
        tooltype
    ) => {
        drawOrder.current = [
            ...drawOrder.current,
            {
                marking_time_in_UTC: moment.utc(),
                draw_data: [
                    last_mousex,
                    last_mousey,
                    mousex,
                    mousey,
                    color,
                    line_width,
                    operation,
                    tooltype,
                ],
            },
        ];

        if (userIds) {
            userIds.forEach((obj, index) => {
                socket.emit("drawing", {
                    action: "drawing",
                    payload: {
                        userId: obj,
                        data: {
                            last_mousex,
                            last_mousey,
                            mousex,
                            mousey,
                            color,
                            line_width,
                            operation,
                            tooltype,
                        },
                    },
                });
            });
        }

        ctx.globalCompositeOperation = operation;
        ctx.lineWidth = line_width;
        if (operation === "source-over") {
            ctx.strokeStyle = color;
        }
        if (orientation === "bottom") {
            ctx.moveTo(last_mousex, last_mousey - shapeCanvas.height);
            ctx.lineTo(mousex, mousey - shapeCanvas.height);
        } else if (orientation === "right") {
            ctx.moveTo(last_mousex - width, last_mousey);
            ctx.lineTo(mousex - width, mousey);
        } else {
            ctx.moveTo(last_mousex, last_mousey);
            ctx.lineTo(mousex, mousey);
        }

        ctx.lineJoin = ctx.lineCap = "round";
        ctx.stroke();
    };

    const drawshape = (
        last_mousex,
        last_mousey,
        mousex,
        mousey,
        color,
        line_width,
        operation,
        tooltype
    ) => {
        if (userIds) {
            userIds.forEach((obj, index) => {
                socket.emit("drawing", {
                    action: "drawing",
                    payload: {
                        userId: obj,
                        data: {
                            last_mousex,
                            last_mousey,
                            mousex,
                            mousey,
                            color,
                            line_width,
                            operation,
                            tooltype,
                        },
                    },
                });
            });
        }
        drawOrder.current = [
            ...drawOrder.current,
            {
                marking_time_in_UTC: moment.utc(),
                draw_data: [
                    last_mousex,
                    last_mousey,
                    mousex,
                    mousey,
                    color,
                    line_width,
                    operation,
                    tooltype,
                ],
            },
        ];
    };

    const drawtriangle = (
        coordinates,
        color,
        lineWidth,
        operation,
        tooltype
    ) => {
        if (userIds) {
            userIds.forEach((obj, index) => {
                socket.emit("drawing", {
                    action: "drawing",
                    payload: {
                        userId: obj,
                        data: {
                            coordinates,
                            color,
                            lineWidth,
                            operation,
                            tooltype,
                        },
                    },
                });
            });
        }
        drawOrder.current = [
            ...drawOrder.current,
            {
                marking_time_in_UTC: moment.utc(),
                draw_data: [coordinates, color, lineWidth, operation, tooltype],
            },
        ];
    };

    const drawText = (value, mousex, mousey, color, tooltype) => {
        if (userIds) {
            userIds.forEach((obj, index) => {
                socket.emit("drawing", {
                    action: "drawing",
                    payload: {
                        userId: obj,
                        data: {
                            value,
                            mousex,
                            mousey,
                            color,
                            tooltype,
                        },
                    },
                });
            });
        }
        drawOrder.current = [
            ...drawOrder.current,
            {
                marking_time_in_UTC: moment.utc(),
                draw_data: [value, mousex, mousey, color, tooltype],
            },
        ];
    };

    const handleOption = () => {
        setOption(!option);
    };

    const onClearWhiteboard = () => {
        const element = document.getElementsByName("fname");

        for (let i = 0; i < element.length; i++) {
            let ctx = element[i].getContext("2d");
            ctx.clearRect(0, 0, element[i].width, element[i].height);
        }
    };

    const onPositionSet = (data) => {
        setPosition(data.position);
        setLeft(data.position.left);
        setUp(data.position.up);
    };

    const onFitScreen = (data) => {
        setFitToScreen(data.imageSize);
    };

    const onStudentAccess = (data) => {
        let accessData;
        const findid = access.find((element) => element === data.id);

        if (findid) {
            accessData = access.filter((element) => element === findid[0]);
            setAccess([accessData]);
        } else {
            accessData = data.id;
            setAccess([...access, accessData]);
        }
    };

    const onStudentColor = (data) => {
        setPenColor(data.color);
    };

    const handleBtn = () => {
        setVisibleControl(!visibleControl);
    };

    const onDrawLines = (position) => {
        fitToContainer(Object.values(position?.data));
    };

    if (socket) {
        socket.on("clearWhiteboard", onClearWhiteboard);
        socket.on("position", onPositionSet);
        socket.on("imageSize", onFitScreen);
        socket.on("studentAccess", onStudentAccess);
        socket.on("studentColor", onStudentColor);
        socket.on("drawing", onDrawLines);
    }

    useEffect(() => {
        if (!myWhiteboard) {
            let canvasdraw = canvasRef.current;
            let ctxdraw = canvasdraw.getContext("2d");
            ctxdraw.clearRect(0, 0, canvasdraw.width, canvasdraw.height);
        }
        if (drawCount === 0) {
            fitToContainerDrawData();
        }
        // eslint-disable-next-line
    }, [drawData, window.innerHeight, window.innerWidth]);

    const settingsHandle = () => {
        setSettings(!settings);
    };

    return (
        <>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <div style={{ height: "100%", width: "100%" }}>
                <div
                    id="canvaswrapper"
                    ref={wrapperRef}
                    className={styles.canvasWrapper}
                >
                    {fitToScreen ? (
                        <div
                            style={{
                                position: "absolute",
                                height: "100%",
                                width: "100%",
                            }}
                        >
                            {selectedMedia.length ? (
                                <img
                                    src={selectedMedia[0]?.location}
                                    style={{
                                        width: "100%",
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                    }}
                                    alt="plass Begin"
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                    ) : (
                        <div
                            style={{
                                position: "absolute",
                                left: `${left}%`,
                                top: `${up}%`,
                            }}
                        >
                            {selectedMedia.length ? (
                                <img
                                    src={selectedMedia[0]?.location}
                                    style={{
                                        width: "500px",
                                    }}
                                    alt="plass Begin"
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                    )}

                    <canvas
                        width={width}
                        height={height}
                        className={styles.whiteboardCanvas}
                        ref={canvasRef}
                        id="drawingCanvas"
                        name="fname"
                    />
                    <canvas
                        width={width}
                        height={height}
                        className={styles.whiteboardCanvasShape}
                        onMouseDown={
                            isStudentNotAccessed
                                ? null
                                : (e) => onMouseDown(e, true)
                        }
                        onTouchStart={
                            isStudentNotAccessed
                                ? null
                                : (e) => onMouseDown(e, false)
                        }
                        onMouseUp={
                            isStudentNotAccessed
                                ? null
                                : (e) => onMouseUp(e, true)
                        }
                        onTouchEnd={
                            isStudentNotAccessed
                                ? null
                                : (e) => onMouseUp(e, false)
                        }
                        onMouseMove={
                            isStudentNotAccessed
                                ? null
                                : (e) => onMouseMove(e, true)
                        }
                        onTouchMove={
                            isStudentNotAccessed
                                ? null
                                : (e) => onMouseMove(e, false)
                        }
                        ref={shapeRef}
                        id="myCanvasForShape"
                    ></canvas>
                </div>

                {control && (
                    <div onMouseEnter={handleBtn} onMouseLeave={handleBtn}>
                        {visibleControl ? (
                            <div className={styles.whiteboard_control}>
                                <div className={styles.settingsBtnWrapper}>
                                    <div
                                        className={styles.settingBtn}
                                        onClick={() => {
                                            settingsHandle();
                                        }}
                                    >
                                        Settings
                                    </div>
                                    {settings && (
                                        <div className={styles.settingsModal}>
                                            <Settings
                                                newPosition={(data) =>
                                                    setPosition(data)
                                                }
                                                // setPosition={setPosition}
                                                session={session}
                                                panelID={panelID}
                                                parentUserIds={userIds}
                                                color={color}
                                                selectedMedia={selectedMedia}
                                                setColor={setColor}
                                                setLeft={setLeft}
                                                setUp={setUp}
                                                left={left}
                                                up={up}
                                                participants={participants}
                                                socket={socket}
                                                key={key}
                                                fitToScreen={fitToScreen}
                                                setFitToScreen={setFitToScreen}
                                                studentAccess={studentAccess}
                                                teacherName={teacherName}
                                                studentsData={studentsData}
                                                data={data}
                                                colorType={colorType}
                                                setColorType={setColorType}
                                                individualBgColor={
                                                    individualBgColor
                                                }
                                                setIndividualBgColor={
                                                    setIndividualBgColor
                                                }
                                            ></Settings>
                                        </div>
                                    )}
                                </div>

                                <div
                                    className={styles.whiteboard_shape_wrapper}
                                >
                                    <p
                                        onClick={() => {
                                            tool("rectangle");
                                        }}
                                    >
                                        <img
                                            src={
                                                tooltype === "rectangle"
                                                    ? RectangleSelected
                                                    : Rectangle
                                            }
                                            alt="Rectangle"
                                        />
                                    </p>
                                    <p
                                        onClick={() => {
                                            tool("circle");
                                        }}
                                    >
                                        <img
                                            src={
                                                tooltype === "circle"
                                                    ? CircleSelected
                                                    : Circle
                                            }
                                            alt="Circle"
                                        />
                                    </p>
                                    <p
                                        onClick={() => {
                                            tool("triangle");
                                        }}
                                    >
                                        <img
                                            src={
                                                tooltype === "triangle"
                                                    ? TriangleSelected
                                                    : Triangle
                                            }
                                            alt="Triangle"
                                        />
                                    </p>
                                    <p
                                        onClick={() => {
                                            tool("draw");
                                        }}
                                    >
                                        <img
                                            src={
                                                tooltype === "draw"
                                                    ? LineSelected
                                                    : Line
                                            }
                                            alt="Line"
                                        />
                                    </p>
                                </div>

                                <div
                                    className={styles.whiteboard_color_wrapper}
                                >
                                    <div
                                        className={
                                            color === "#333232"
                                                ? styles.whiteboard_color_selected
                                                : styles.whiteboard_color_unselected
                                        }
                                    >
                                        <div
                                            className={styles.whiteboard_color}
                                            onClick={() => {
                                                handleColor("#333232");
                                            }}
                                            style={{
                                                backgroundColor: "#333232",
                                            }}
                                        />
                                    </div>

                                    <div
                                        className={
                                            color === "#88ADDB"
                                                ? styles.whiteboard_color_selected
                                                : styles.whiteboard_color_unselected
                                        }
                                    >
                                        <div
                                            className={styles.whiteboard_color}
                                            onClick={() => {
                                                handleColor("#88ADDB");
                                            }}
                                            style={{
                                                backgroundColor: "#88ADDB",
                                            }}
                                        />
                                    </div>

                                    <div
                                        className={
                                            color === "#D94E43"
                                                ? styles.whiteboard_color_selected
                                                : styles.whiteboard_color_unselected
                                        }
                                    >
                                        <div
                                            className={styles.whiteboard_color}
                                            onClick={() => {
                                                handleColor("#D94E43");
                                            }}
                                            style={{
                                                backgroundColor: "#D94E43",
                                            }}
                                        />
                                    </div>

                                    <div
                                        className={
                                            color === "#4EA59D"
                                                ? styles.whiteboard_color_selected
                                                : styles.whiteboard_color_unselected
                                        }
                                    >
                                        <div
                                            className={styles.whiteboard_color}
                                            onClick={() => {
                                                handleColor("#4EA59D");
                                            }}
                                            style={{
                                                backgroundColor: "#4EA59D",
                                            }}
                                        />
                                    </div>

                                    <div
                                        className={
                                            color === "#FCB648"
                                                ? styles.whiteboard_color_selected
                                                : styles.whiteboard_color_unselected
                                        }
                                    >
                                        <div
                                            className={styles.whiteboard_color}
                                            onClick={() => {
                                                handleColor("#FCB648");
                                            }}
                                            style={{
                                                backgroundColor: "#FCB648",
                                            }}
                                        />
                                    </div>
                                </div>

                                <div
                                    className={styles.whiteboard_width_wrapper}
                                >
                                    <div
                                        className={styles.whiteboard_width}
                                        onClick={() => {
                                            handleWidth("2");
                                        }}
                                    >
                                        <img
                                            src={
                                                lineWidth === "2"
                                                    ? SmallSelected
                                                    : Small
                                            }
                                            alt="Small"
                                        />
                                    </div>
                                    <div
                                        className={styles.whiteboard_width}
                                        onClick={() => {
                                            handleWidth("6");
                                        }}
                                    >
                                        <img
                                            src={
                                                lineWidth === "6"
                                                    ? MediumSelected
                                                    : Medium
                                            }
                                            alt="Medium"
                                        />
                                    </div>
                                    <div
                                        className={styles.whiteboard_width}
                                        onClick={() => {
                                            handleWidth("8");
                                        }}
                                    >
                                        <img
                                            src={
                                                lineWidth === "8"
                                                    ? LargeSelected
                                                    : Large
                                            }
                                            alt="Large"
                                        />
                                    </div>
                                </div>

                                <div className={styles.clear_wrapper}>
                                    <img
                                        src={Eraser}
                                        alt="eraser"
                                        id="eraser"
                                        onClick={() => handleOption()}
                                    />
                                    <>
                                        {option && (
                                            <div
                                                className={styles.clear_option}
                                            >
                                                <p
                                                    onClick={() => {
                                                        setOption(false);
                                                        handleClear();
                                                    }}
                                                >
                                                    Clear all
                                                </p>
                                                <p
                                                    onClick={() => {
                                                        setOption(false);
                                                        tool("erase");
                                                    }}
                                                >
                                                    Eraser
                                                </p>
                                            </div>
                                        )}
                                    </>
                                </div>

                                <div
                                    className={
                                        tooltype === "text"
                                            ? styles.whiteboard_text_highlighted
                                            : styles.whiteboard_text
                                    }
                                >
                                    <p
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            tool("text");
                                        }}
                                    >
                                        Text
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className={styles.lineBoxContainer}>
                                <div className={styles.lineContainer}>
                                    <hr
                                        className={styles.line}
                                        align="center"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default WhiteboardNirajan;
