import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Login from "../../assets/images/GuestLogin.svg";
import CoinSVG from "../../assets/images/Plasscoin.svg";
import Hamburger from "../../assets/images/HamburgerMenu.svg";
import Setting from "../../assets/images/icon/Settings.svg";
import Logo from "../../assets/images/LOGO (6).svg";
import Play from "../../assets/images/play.png";
import styles from "./index.module.scss";
import axios from "axios";

const Navbar = ({ screen }) => {
    const history = useHistory();
    const location = useLocation();
    const [plassCoins, setPlassCoins] = useState(0);
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("auth")) {
            setIsUserAuthenticated(true);
            if (
                JSON.parse(localStorage.getItem("auth"))?.user?.role ===
                "student"
            ) {
                fetchPlanDetails();
            }
        }
        // eslint-disable-next-line
    }, []);

    // FETCHING STUDENT PLANN DETAILS
    const fetchPlanDetails = async () => {
        await axios
            .get("/api/v1/user/plan")
            .then((response) => {
                if (response?.data?.success) {
                    setPlassCoins(response?.data?.data?.totalRemainingMinutes);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    window.addEventListener("mouseup", function (event) {
        var pol = document.getElementById("burgerMenu");
        if (event.target !== pol && event.target.parentNode !== pol) {
            setShowHamburgerMenu(false);
        }
    });

    return (
        <div className={styles.navbarPageContainer}>
            <div className={styles.navbarContainer}>
                <div className={styles.logoContainer}>
                    <img
                        src={Logo}
                        className={styles.navbarLogo}
                        alt="Logo"
                        onClick={() =>
                            // history.push(screen === "Landing" ? "/home" : "/")
                            history.push("/home")
                        }
                    />
                </div>

                <div className={styles.navbarLinks}>
                    {isUserAuthenticated &&
                        JSON.parse(localStorage.getItem("auth"))?.user?.role ===
                            "student" && (
                            <div
                                className={`${styles.balance} ${styles.navbarLink}`}
                            >
                                <img src={CoinSVG} /> <span>{plassCoins}</span>
                            </div>
                        )}
                    {screen !== "Landing" && isUserAuthenticated ? (
                        <div
                            className={`${styles.navLink} ${styles.navbarLink} ${styles.letsPlay}`}
                            onClick={() => history.push("/")}
                        >
                            <img src={Play} alt={"Logo"} />
                        </div>
                    ) : null}
                    <div
                        className={`${styles.navLink} ${styles.navbarLink} ${
                            location.pathname === "/play"
                                ? styles.activeLink
                                : ""
                        }`}
                        onClick={() => history.push("/play")}
                    >
                        About
                    </div>

                    <div
                        className={`${styles.navLink} ${styles.navbarLink} ${
                            location.pathname === "/teach"
                                ? styles.activeLink
                                : ""
                        }`}
                        onClick={() => history.push("/teach")}
                    >
                        Teach
                    </div>

                    <div
                        className={`${styles.navLink} ${styles.navbarLink}`}
                        onClick={() =>
                            (window.location.href =
                                "mailto:enquiry@plassroom.com")
                        }
                    >
                        Support
                    </div>

                    {screen === "Landing" ? (
                        <div
                            className={`${styles.navLink} ${styles.mobileLogout}`}
                            onClick={() => history.push("/chat")}
                        >
                            Chat
                        </div>
                    ) : null}

                    {screen === "Landing" ? (
                        <div
                            className={`${styles.navLink} ${styles.mobileLogout}`}
                            onClick={() => {
                                localStorage.clear();
                                window.location.reload();
                            }}
                        >
                            Logout
                        </div>
                    ) : null}

                    {screen !== "Landing" && !isUserAuthenticated ? (
                        <div
                            className={`${styles.navLink} ${styles.navbarLink}`}
                            onClick={() =>
                                history.push(
                                    `/login?redirect=${location.pathname}`
                                )
                            }
                        >
                            <img src={Login} alt={"Logo"} />
                        </div>
                    ) : null}

                    {isUserAuthenticated ? (
                        <>
                            <span
                                id="SettingsText"
                                className={styles.hoverText}
                                style={{
                                    visibility: "hidden",
                                }}
                            >
                                Settings
                            </span>
                            <img
                                onMouseOver={() =>
                                    (document.querySelector(
                                        "#SettingsText"
                                    ).style.visibility = "visible")
                                }
                                onMouseLeave={() =>
                                    (document.querySelector(
                                        "#SettingsText"
                                    ).style.visibility = "hidden")
                                }
                                src={Setting}
                                className={`${styles.dashboardIcon} ${styles.navbarLink}`}
                                alt="settings"
                                onClick={() => history.push("/accountsettings")}
                            />
                        </>
                    ) : null}

                    {screen === "Landing" ? null : (
                        <div className={styles.burgerMenu}>
                            <img
                                src={Hamburger}
                                alt=""
                                className={styles.moreIcon}
                                onClick={() => setShowHamburgerMenu(true)}
                            />
                        </div>
                    )}
                </div>
            </div>

            {showHamburgerMenu ? (
                <div
                    className={`${styles.burgerMenuList} ${
                        isUserAuthenticated
                            ? styles.loggedInBurgerMenuList
                            : null
                    }`}
                    id="burgerMenu"
                    style={{
                        height: window.innerHeight,
                    }}
                >
                    {isUserAuthenticated &&
                        JSON.parse(localStorage.getItem("auth"))?.user?.role ===
                            "student" && (
                            <p className={`${styles.balance}`}>
                                <img src={CoinSVG} /> <span>{plassCoins}</span>
                            </p>
                        )}
                    {screen !== "Landing" && isUserAuthenticated ? (
                        <div
                            className={`${styles.letsPlay}`}
                            onClick={() => history.push("/")}
                        >
                            <img
                                src={Play}
                                alt={"Logo"}
                                style={{ zIndex: -1 }}
                            />
                        </div>
                    ) : null}

                    {screen !== "Landing" && !isUserAuthenticated ? (
                        <img
                            src={Login}
                            className={`${styles.loginBtn}`}
                            onClick={() =>
                                history.push(
                                    `/login?redirect=${location.pathname}`
                                )
                            }
                            alt={"Logo"}
                        />
                    ) : null}

                    <div
                        className={`${styles.navLink} ${styles.navbarLink} ${
                            location.pathname === "/play"
                                ? styles.activeLink
                                : ""
                        }`}
                        onClick={() => history.push("/play")}
                    >
                        About
                    </div>
                    <div
                        className={`${styles.navLink} ${styles.navbarLink} ${
                            location.pathname === "/teach"
                                ? styles.activeLink
                                : ""
                        }`}
                        onClick={() => history.push("/teach")}
                    >
                        Teach
                    </div>
                    <div
                        className={`${styles.navLink} ${styles.navbarLink}`}
                        onClick={() =>
                            (window.location.href =
                                "mailto:enquiry@plassroom.com")
                        }
                    >
                        Support
                    </div>
                    {isUserAuthenticated ? (
                        <div
                            className={`${styles.navLink} ${styles.navLink}`}
                            onClick={() => history.push("/accountsettings")}
                        >
                            Settings
                        </div>
                    ) : null}
                    {!isUserAuthenticated && (
                        <div
                            className={`${styles.navLink} ${styles.navbarLink}`}
                            onClick={() =>
                                (window.location.href =
                                    "/subscription")
                            }
                        >
                            The Lite &<br />
                            Plass Plans
                        </div>
                    )}
                    {isUserAuthenticated ? (
                        <div
                            className={`${styles.logout} ${styles.navLink}`}
                            onClick={() => {
                                localStorage.clear();
                                window.location.reload();
                            }}
                        >
                            Logout
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default Navbar;
