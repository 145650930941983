import React from "react";
import { Breadcrumbs } from "@mui/material";
import { NavLink } from "react-router-dom";
import styles from "./index.module.scss";
import clsx from "clsx";

const BreadCrumbs = (props) => {
    const { links } = props;

    const activeLink = () => {
        return (isActive) => (isActive ? styles.isActive : "");
    };
    return (
        <div className={clsx(styles.container, props.class)}>
            <Breadcrumbs aria-label="breadcrumb" separator=">">
                {links.map((link, i) => {
                    return (
                        <NavLink
                            to={link.to}
                            className={activeLink()}
                            title="Homes"
                            key={i}
                        >
                            {link.label}
                        </NavLink>
                    );
                })}
            </Breadcrumbs>
        </div>
    );
};

export default BreadCrumbs;
