import React from "react";
import { useState, useEffect } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { colors } from "./../../../utils/colors";
import styles from "./paymentDetails.module.css";
import stripe from "../../../assets/images/stripe/stripe.png";
import bankLogo from "../../../assets/images/icon/bankLogo.png";
import payout from "../../../assets/images/icon/payout.png";
import "./style.css";
import axios from "axios";
import moment from "moment";
import Snackbar from "../../../components/Snackbar";
import { getCurrencySymbol } from "../../../utils/commonFunction";
import CommonButton from "../../Button/CommonButton.js";

// import PrivacyPolicy from "../../../assets/documents/Privacy Policy 1.pdf";
// import TermsOfService from "../../../assets/documents/Terms of Service 1.pdf";

export default function PaymentDetails() {
    const [data, setData] = useState([]);
    const [sortData, setSortData] = useState([]);
    const [expand, setExpand] = useState(true);
    const [collapseRow, setCollapseRow] = useState(null);
    const [showDetail, setShowDetail] = useState(false);
    const currentUserRole = JSON.parse(localStorage.getItem("auth"))?.user
        ?.role;
    const [balance, setBalance] = useState([]);
    const [haveStripeAccount, setHaveStripeAccount] = useState(false);
    const [accountDetails, setAccountDetails] = useState([]);
    const [accountSubmitted, setAccountSubmitted] = useState(false);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackBarStatus, setSnackBarStatus] = useState("error");
    const [stripeAccountErrors, setStripeAccountErrors] = useState([]);
    const [errorReason, setErrorReason] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (currentUserRole === "teacher") {
            checkBalance();
            checkAccountDetails();
            checkSubmitted();
            // createAccount()
            transactionHistory();
        } else if (currentUserRole === "student") {
            studentTransactionHistory();
            checkAccountDetailsStudent();
        }
        // eslint-disable-next-line
    }, []);

    // function uniq(value, index, self) {
    //     console.log(value);
    //     return self.reason.indexOf(value) === index;
    // }

    const transactionHistory = () => {
        axios
            .get("api/v1/payment/transaction-history")
            .then((res) => {
                if (res?.data?.data?.length > 0) {
                    setData(res?.data?.data);
                    setSortData(res?.data?.data.slice(0, 3));
                }
            })
            .catch((err) => {});
    };

    const studentTransactionHistory = () => {
        axios
            .get("api/v1/payment/student-transaction-history")
            .then((res) => {})
            .catch((err) => {});
    };

    const checkSubmitted = () => {
        axios
            .get("api/v1/payment/stripe-account-detail-submitted")
            .then((res) => {
                if (res?.data?.data?.submitted) {
                    setAccountSubmitted(true);
                } else {
                    setAccountSubmitted(false);
                    setStripeAccountErrors(res?.data?.data?.errors);
                    setErrorReason([
                        ...new Set(
                            res?.data?.data?.errors.map((item) => item.reason)
                        ),
                    ]);
                    setErrorMessage(res?.data?.message);
                }
            })
            .catch((error) => {
                setAccountSubmitted(false);
            });
    };

    const checkAccountDetailsStudent = () => {
        axios
            .get("api/v1/payment/get-stripe-customer-cards")
            .then((res) => {})
            .catch((error) => {});
    };

    const checkAccountDetails = () => {
        axios
            .get("api/v1/payment/stripe-account-payment-details")
            .then((res) => {
                if (res?.data?.data?.length > 0) {
                    setHaveStripeAccount(true);
                    setAccountDetails(res?.data?.data);
                } else if (res?.data?.data?.length <= 0) {
                    setHaveStripeAccount(false);
                }
            })
            .catch((error) => {});
    };

    const checkBalance = () => {
        axios
            .get("api/v1/payment/stripe-account-balance")
            .then((res) => {
                if (res.status === 200) {
                    setBalance(res?.data?.data?.available);
                }
            })
            .catch((error) => {});
    };

    const createAccount = () => {
        axios
            .get("api/v1/payment/stripe-account-link")
            .then((res) => {
                window.open(res?.data?.data?.url, "_self");
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const editAccount = () => {
        axios
            .get("api/v1/payment/stripe-account-login-url")
            .then((res) => {
                window.open(`${res?.data?.data?.url}#/account`, "_blank");
            })
            .catch((error) => {
                setMsg(
                    _.get(
                        error,
                        "response.data.message",
                        "Something went wrong."
                    )
                );
                setSnackBarStatus("error");
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                createAccount();
                return Promise.reject(error);
            });
    };

    const payoutListRedirect = () => {
        axios
            .get("api/v1/payment/stripe-account-login-url")
            .then((res) => {
                window.open(`${res?.data?.data?.url}#/`, "_blank");
            })
            .catch((error) => {
                setMsg(
                    _.get(
                        error,
                        "response.data.message",
                        "Something went wrong."
                    )
                );
                setSnackBarStatus("error");
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const handlePayout = async () => {
        axios
            .post("api/v1/payment/stripe-account-payout", { currency: "gbp" })
            .then((res) => {
                setMsg(res.data.message);
                setSnackBarStatus("information");
                setShowSnackbar(true);
                setTimeout(() => {
                    setMsg(null);
                }, 3000);
                checkBalance();
            })
            .catch((error) => {
                setMsg(
                    _.get(
                        error,
                        "response.data.message",
                        "Something went wrong."
                    )
                );
                setSnackBarStatus("error");
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    return (
        <React.Fragment>
            {msg && (
                <Snackbar
                    status={snackBarStatus}
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <div className={styles.studentblock}>
                <div className={`payment-outer ${styles.studentScroll}`}>
                    <div
                        className={`payment-outer-wrapper ${styles.innerContainer}`}
                    >
                        <div className="col-12">
                            <div className="personal-detail mt-3">
                                <div className="history-title d-flex justify-content-between">
                                    <h3 style={{ fontSize: "25px" }}>
                                        Transaction history
                                    </h3>
                                </div>
                                {currentUserRole === "teacher" && (
                                    <div className="d-flex justify-content-end align-items-center">
                                        <p
                                            style={{
                                                fontFamily: "RobotoMedium",
                                                fontSize: "18px",
                                            }}
                                            className="mr-3"
                                        >
                                            Your balance
                                        </p>
                                        <p
                                            className={styles.price}
                                            style={{
                                                fontFamily: "RobotoMedium",
                                                fontSize: "23px",
                                            }}
                                        >
                                            {accountSubmitted &&
                                            balance.length > 0
                                                ? `${"£"}${Number(
                                                      balance[0].amount < 0
                                                          ? 0
                                                          : balance[0].amount /
                                                                100
                                                  ).toFixed(2)}`
                                                : `${"£0.00"}`}
                                        </p>
                                    </div>
                                )}
                                {sortData?.length <= 0 ? (
                                    <p style={{ marginLeft: "8px" }}>
                                        No transaction done yet
                                    </p>
                                ) : (
                                    <div className="table-outer">
                                        <table
                                            className={`table ${styles.paymentTable}`}
                                            id="historyTable"
                                        >
                                            <tbody className={styles.tableHead}>
                                                <tr
                                                    className={styles.tableBody}
                                                    style={{
                                                        color: "#88addb",
                                                    }}
                                                >
                                                    <td>Date</td>
                                                    <td>Type</td>
                                                    <td>Name of class</td>
                                                    <td className="text-center">
                                                        Time (Duration)
                                                    </td>
                                                    <td
                                                        className="text-center"
                                                        hidden={
                                                            currentUserRole ===
                                                            "teacher"
                                                                ? false
                                                                : true
                                                        }
                                                    >
                                                        Students Registered
                                                    </td>
                                                    <td className="text-center">
                                                        Transacted (£) in/
                                                        <span
                                                            style={{
                                                                color: colors.orange,
                                                            }}
                                                        >
                                                            out
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            {sortData &&
                                                sortData.map((data, index) => {
                                                    return (
                                                        <tbody
                                                            className={
                                                                styles.tableBodyMain
                                                            }
                                                            key={index}
                                                        >
                                                            <tr
                                                                style={{
                                                                    backgroundColor:
                                                                        data
                                                                            ?.students
                                                                            .length >
                                                                        0
                                                                            ? "#E9F3FB"
                                                                            : "#FDD7C6",
                                                                }}
                                                                className={
                                                                    styles.tableBody
                                                                }
                                                                onClick={() => {
                                                                    setCollapseRow(
                                                                        index
                                                                    );
                                                                    showDetail
                                                                        ? setShowDetail(
                                                                              false
                                                                          )
                                                                        : setShowDetail(
                                                                              true
                                                                          );
                                                                }}
                                                            >
                                                                <td>
                                                                    {moment
                                                                        .utc(
                                                                            data?.session_start_time
                                                                        )
                                                                        .format(
                                                                            "DD MMM YYYY"
                                                                        )}
                                                                </td>
                                                                <td>
                                                                    {data?.type}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        data?.class_name
                                                                    }
                                                                </td>
                                                                <td className="text-center">
                                                                    {moment
                                                                        .utc(
                                                                            data?.session_start_time
                                                                        )
                                                                        .format(
                                                                            "hh:mma"
                                                                        )}
                                                                    {` (${data?.session_duration}mins)`}
                                                                </td>
                                                                <td
                                                                    className="text-center"
                                                                    hidden={
                                                                        currentUserRole ===
                                                                        "teacher"
                                                                            ? false
                                                                            : true
                                                                    }
                                                                >
                                                                    {data?.is_free_class
                                                                        ? data
                                                                              ?.students
                                                                              .length
                                                                        : data?.students.filter(
                                                                              (
                                                                                  student
                                                                              ) =>
                                                                                  student.payment_status ===
                                                                                  "succeeded"
                                                                          )
                                                                              .length}
                                                                </td>
                                                                <td
                                                                    className="text-center"
                                                                    style={{
                                                                        color: "#002B4A",
                                                                    }}
                                                                >
                                                                    {!!data?.total_amount
                                                                        ? Number(
                                                                              data?.total_amount
                                                                          ).toFixed(
                                                                              2
                                                                          )
                                                                        : ""}
                                                                    {!!data?.total_amount &&
                                                                    !!data?.total_coins
                                                                        ? ", "
                                                                        : ""}
                                                                    {!!data?.total_coins
                                                                        ? `${data?.total_coins} Coins`
                                                                        : ""}
                                                                </td>
                                                            </tr>
                                                            <tr
                                                                hidden={
                                                                    showDetail &&
                                                                    collapseRow ===
                                                                        index
                                                                        ? false
                                                                        : true
                                                                }
                                                                className={
                                                                    styles.tableBodyInner
                                                                }
                                                            >
                                                                <td></td>
                                                                <td>
                                                                    Registered:{" "}
                                                                </td>
                                                                <td className="text-center">
                                                                    <div className="mt-1">
                                                                        {data?.students.map(
                                                                            (
                                                                                student,
                                                                                index
                                                                            ) => {
                                                                                if (
                                                                                    data?.is_free_class
                                                                                ) {
                                                                                    return (
                                                                                        <p
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            className={`mb-0 ${styles.ltd} ${styles.oneRem} text-center`}
                                                                                            style={{
                                                                                                marginLeft: 0,
                                                                                                color: student?.is_Attended
                                                                                                    ? "inherit"
                                                                                                    : "rgb(227, 107, 45)",
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                student
                                                                                                    ?.user
                                                                                                    ?.name
                                                                                            }
                                                                                        </p>
                                                                                    );
                                                                                } else if (
                                                                                    student?.payment_status ===
                                                                                    "succeeded"
                                                                                ) {
                                                                                    return (
                                                                                        <p
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            className={`mb-0 ${styles.ltd} ${styles.oneRem} text-center`}
                                                                                            style={{
                                                                                                marginLeft: 0,
                                                                                                color: student?.is_Attended
                                                                                                    ? "inherit"
                                                                                                    : "rgb(227, 107, 45)",
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                student
                                                                                                    ?.user
                                                                                                    ?.name
                                                                                            }
                                                                                        </p>
                                                                                    );
                                                                                }
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr
                                                                hidden={
                                                                    showDetail &&
                                                                    collapseRow ===
                                                                        index
                                                                        ? false
                                                                        : true
                                                                }
                                                                className={
                                                                    styles.tableBodyInner
                                                                }
                                                            >
                                                                <td></td>
                                                                <td>
                                                                    Refunds:{" "}
                                                                </td>
                                                                <td className="text-center">
                                                                    <div>
                                                                        {data?.students.map(
                                                                            (
                                                                                student,
                                                                                index
                                                                            ) => {
                                                                                if (
                                                                                    student?.refund
                                                                                ) {
                                                                                    return (
                                                                                        <p
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            className={
                                                                                                styles.onRem
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                student
                                                                                                    ?.user
                                                                                                    ?.name
                                                                                            }
                                                                                        </p>
                                                                                    );
                                                                                }
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            {data?.students.map(
                                                                (
                                                                    student,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        student?.refund
                                                                    ) {
                                                                        return (
                                                                            <tr
                                                                                key={
                                                                                    index
                                                                                }
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        "#FDD7C6",
                                                                                }}
                                                                                hidden={
                                                                                    showDetail &&
                                                                                    collapseRow ===
                                                                                        index
                                                                                        ? false
                                                                                        : true
                                                                                }
                                                                                className={
                                                                                    styles.tableBodyInner
                                                                                }
                                                                            >
                                                                                <td>
                                                                                    {moment
                                                                                        .utc(
                                                                                            student
                                                                                                ?.refund
                                                                                                ?.date
                                                                                        )
                                                                                        .format(
                                                                                            "DD MMM YYYY"
                                                                                        )}
                                                                                </td>
                                                                                <td>
                                                                                    Refund
                                                                                    -{" "}
                                                                                    {
                                                                                        student
                                                                                            ?.user
                                                                                            ?.name
                                                                                    }
                                                                                </td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td
                                                                                    className="text-center"
                                                                                    style={{
                                                                                        color: "#E36B2D",
                                                                                    }}
                                                                                >
                                                                                    {Number(
                                                                                        student
                                                                                            ?.refund
                                                                                            ?.amount
                                                                                    ).toFixed(
                                                                                        2
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                        </tbody>
                                                    );
                                                })}
                                        </table>
                                        {data?.length > 3 && (
                                            <p
                                                style={{
                                                    color: colors.blue,
                                                    cursor: "pointer",
                                                    fontSize: "18px",
                                                    marginLeft: "10px",
                                                    fontFamily: "ComfortaBold",
                                                }}
                                                className={styles.onRem}
                                                onClick={() => {
                                                    if (expand) {
                                                        setExpand(false);
                                                        setSortData(
                                                            data.slice(0, 7)
                                                        );
                                                    } else {
                                                        // setExpand(true);
                                                        // setSortData(data.slice(0, 1));
                                                        payoutListRedirect();
                                                    }
                                                }}
                                            >
                                                {expand
                                                    ? "See more..."
                                                    : "view on stripe"}
                                            </p>
                                        )}{" "}
                                        {data.length <= 3 && (
                                            <p
                                                className={styles.onRem}
                                                style={{
                                                    color: colors.blue,
                                                    cursor: "pointer",
                                                    fontSize: "18px",
                                                    marginLeft: "10px",
                                                    fontFamily: "ComfortaBold",
                                                }}
                                                onClick={() => {
                                                    payoutListRedirect();
                                                }}
                                            >
                                                view on stripe
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="personal-detail payment-detail">
                                <div className="d-flex flex-row justify-content-between mt-3">
                                    <h3>Current payment details</h3>
                                    <img
                                        width="42px"
                                        height="21px"
                                        src={stripe}
                                        alt="stripe"
                                    />
                                </div>

                                {haveStripeAccount ? (
                                    <div className="row d-flex justify-content-between">
                                        <div className="d-flex col-12 flex-column mb-3">
                                            {accountDetails.map(
                                                (accountDetail, index) => {
                                                    return (
                                                        <div
                                                            className="d-flex flex-row justify-content-start"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            key={index}
                                                        >
                                                            <div
                                                                className={
                                                                    styles.cardBoxStudent
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        bankLogo
                                                                    }
                                                                    height="70%"
                                                                    style={{
                                                                        marginLeft:
                                                                            "27px",
                                                                    }}
                                                                />
                                                                <p
                                                                    className={`mr-3 ${styles.ltd} ${styles.oneRem}`}
                                                                >
                                                                    Bank Account
                                                                    ending ****
                                                                    {accountDetail
                                                                        ? accountDetail?.last4
                                                                        : 2222}
                                                                </p>
                                                                <a
                                                                    onClick={() => {
                                                                        editAccount();
                                                                    }}
                                                                    to="/paymentdetails/editaccount"
                                                                    className={
                                                                        styles.edit
                                                                    }
                                                                >
                                                                    Edit
                                                                </a>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                        <div className="ml-2 col-md-12">
                                            <CommonButton
                                                buttonText="Payout"
                                                handleClick={() =>
                                                    handlePayout()
                                                }
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="payment-des">
                                        <p className={styles.addAccount}>
                                            We use Stripe to make sure you get
                                            paid on time and to keep your
                                            personal bank and details secure.{" "}
                                            <br></br>Click{" "}
                                            <span
                                                style={{
                                                    cursor: "pointer",
                                                    color: "#88ADDB",
                                                }}
                                                onClick={() => createAccount()}
                                            >
                                                {" "}
                                                here{" "}
                                            </span>
                                            to set up your payments on Stripe.
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>

                        <ul className={`col-12 ${styles.errorList}`}>
                            {errorMessage && (
                                <li className={styles.errors}>
                                    {errorMessage}
                                </li>
                            )}
                            {stripeAccountErrors.length > 0 &&
                                errorReason?.map((error, index) => {
                                    return (
                                        <li
                                            className={styles.errors}
                                            key={index}
                                        >
                                            {error}
                                        </li>
                                    );
                                })}
                        </ul>
                        <div className="col-12">
                            <p className={styles.termsStyle}>
                                <Link to="/termsofservice">
                                    See our Terms of Service and Payment Terms
                                    of Service
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
