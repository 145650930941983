import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useParams, useHistory } from "react-router-dom";
import CloseButton from "../../../assets/images/Close_button.png";
import Logo from "../../../assets/images/plass_logo_new.svg";
import PlayIcon from "../../../assets/images/PlayIcon.png";
import ScreenIcon from "../../../assets/images/ScreenArrangement.png";
import Snackbar from "../../../components/Snackbar";
import WarningModal from "../../../components/WarningModal";
import styles from "./tool.module.css";

export default () => {
    const { sessionID } = useParams();
    const location = useLocation();
    const history = useHistory();
    const panelindex = Number(
        new URLSearchParams(location.search).get("panelIndex")
    );
    const panelSlotId =
        new URLSearchParams(location.search).get("slotId") || null;
    const [showOption, setShowOption] = useState(false);
    const [rightPanelSettings, setRightPanelSettings] = useState([]);
    const [versionTime, setVersionTime] = useState(undefined);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState({
        flag: false,
        type: null,
    });
    const urlParam = !!panelSlotId ? `&slotId=${panelSlotId}` : ``;

    const updateSession = async (cb) => {
        if (rightPanelSettings.length > 0) {
            const sessionData = {
                id: sessionID,
                rightPanelSettings: rightPanelSettings,
                ...(!!panelSlotId
                    ? { rightPanelSettingsSessionId: panelSlotId }
                    : {}),
            };

            await axios
                .put("/api/v1/sessions", sessionData)
                .then((result) => {
                    if (result.data.success) {
                        setVersionTime(moment().format("HH:mm"));
                        cb();
                    } else {
                        setMsg(
                            _.get(
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        }
    };

    const saveSubtypeAndResetIfDifferent = async (param) => {
        // api call
        const sessionData = {
            id: sessionID,
            rightPanelSettings: rightPanelSettings.map((obj, index) => {
                if (parseInt(panelindex) === index) {
                    if (obj.subType !== param.toUpperCase()) {
                        return {
                            type: obj.type.toUpperCase(),
                            label: obj.label,
                            subType: param.toUpperCase(),
                        };
                    }
                    obj.subType = param.toUpperCase();
                }
                return obj;
            }),
            ...(!!panelSlotId
                ? {
                      rightPanelSettingsSessionId: panelSlotId,
                  }
                : {}),
        };
        await axios
            .put("/api/v1/sessions", sessionData)
            .then((result) => {
                if (result.data.success) {
                    setVersionTime(moment().format("HH:mm"));
                } else {
                    setMsg(
                        _.get("response.data.title", "Something went wrong.")
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
                setShowOption(false);
                if (param.toUpperCase() === "WHITEBOARD") {
                    history.push(
                        `/session/${sessionID}/${param.toLowerCase()}/setting?panelIndex=${panelindex}${urlParam}`
                    );
                } else {
                    history.push(
                        `/session/${sessionID}/${param.toLowerCase()}/view?panelIndex=${panelindex}${urlParam}`
                    );
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    useEffect(() => {
        const getSessionInfo = async () => {
            await axios
                .get(`/api/v1/class/${sessionID}`)
                .then((result) => {
                    let rightPanelSettingsRes =
                        result?.data?.data?.session?.configuration
                            ?.right_panel_settings;

                    setRightPanelSettings(rightPanelSettingsRes);
                    const updateTime = moment
                        .utc(
                            result.data.data.session.updatedAt,
                            "DD/MM/YYYY HH:mm"
                        )
                        .local()
                        .format("HH:mm");
                    setVersionTime(updateTime);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        getSessionInfo();
        // eslint-disable-next-line
    }, []);

    const handleShowWarningModal = async (decision) => {
        setShowWarningModal({
            flag: false,
            type: null,
        });

        if (decision) {
            const cb = () => {
                history.push(`/session/${sessionID}`);
            };
            await updateSession(cb);
        }
    };

    let date = new Date();
    const currentTime = moment(date).format("HH:mm");
    return (
        <div>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            {showWarningModal.flag && (
                <WarningModal
                    handleShowWarningModal={handleShowWarningModal}
                    showWarningModal={showWarningModal}
                />
            )}

            <Row className={styles.row}>
                <Col xs={10} className={styles.leftsection}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                            className={styles.iconsection}
                            style={{
                                padding: "9px 34px",
                            }}
                        >
                            <img
                                src={ScreenIcon}
                                style={{ width: "40px" }}
                                alt=""
                                className={styles.playicon}
                            />
                        </div>
                        <h1
                            className={styles.heading}
                            style={{
                                marginLeft: "10px",
                                marginTop: 0,
                                marginBottom: 0,
                            }}
                        >
                            Screen arrangement
                        </h1>
                    </div>
                    <div className={styles.leftSection}>
                        <div className={styles.leftScroll}>
                            <div className={styles.sessionblock}>
                                <div className={styles.crosssection}>
                                    <img
                                        onClick={() => {
                                            setShowWarningModal({
                                                flag: true,
                                                type: "closeButton",
                                            });
                                        }}
                                        className={styles.playicon}
                                        src={CloseButton}
                                        alt="Logo"
                                        style={{ marginRight: "5px" }}
                                    />
                                    <p className={styles.italicPara}>
                                        Last version saved at {versionTime}
                                    </p>
                                </div>
                                <div className={styles.selectedToolSection}>
                                    <div>
                                        <img
                                            src={PlayIcon}
                                            style={{
                                                width: "40px",
                                                display: "none",
                                            }}
                                            alt=""
                                            className={styles.playicon}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={styles.selectedTool}>
                                    <p className={styles.toolText}>
                                        Tools needed
                                    </p>
                                    <FontAwesomeIcon
                                        className={styles.downAngleIcon}
                                        style={{
                                            width: "30px",
                                            cursor: showOption
                                                ? "initial"
                                                : "pointer",
                                        }}
                                        icon={faAngleDown}
                                        onClick={() => {
                                            setShowOption(true);
                                        }}
                                    />
                                </div>
                                {showOption && (
                                    <div className={styles.toolSection}>
                                        <p
                                            className={styles.toolType}
                                            onClick={() => {
                                                saveSubtypeAndResetIfDifferent(
                                                    "Asset"
                                                );
                                            }}
                                        >
                                            Presentation
                                        </p>
                                        <p
                                            className={styles.toolType}
                                            onClick={() => {
                                                saveSubtypeAndResetIfDifferent(
                                                    "Whiteboard"
                                                );
                                            }}
                                        >
                                            Whiteboard
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={2}>
                    <div
                        style={{ position: "relative", cursor: "pointer" }}
                        onClick={() => history.push("/home")}
                    >
                        <img src={Logo} className={styles.logoImg} alt="Logo" />
                        <h1 className={styles.logoText}>
                            plassroom<sup>+</sup>
                        </h1>
                        <p className={styles.logoSubText}>Teacher</p>
                        <h1 className={styles.rightTime}>{currentTime}</h1>
                    </div>
                    <div className={styles.rightSection}>
                        <div className={styles.rightTabSection}>
                            <h1
                                style={{ backgroundColor: "#F5C6A2" }}
                                className={styles.rightTab}
                            >
                                Video
                            </h1>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
