import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Participant from "../Participant";
import styles from "./index.module.css";

const StudentSpotlight = ({
    selectedStudentToFocus,
    Participants,
    closeModal,
}) => {
    return selectedStudentToFocus > -1 ? (
        <div className={styles.spotlightOuterContainer}>
            <div className={styles.spotlightContainer}>
                <div className={styles.spotlightHeaderContainer}>
                    <FontAwesomeIcon
                        className={styles.crossIcon}
                        icon={faTimes}
                        onClick={() => {
                            closeModal();
                        }}
                    />
                </div>

                <div className={styles.spotlightParticipantContainer}>
                    <Participant>
                        {Participants[selectedStudentToFocus]}
                    </Participant>
                </div>
            </div>
        </div>
    ) : null;
};

export default StudentSpotlight;
