import axios from "axios";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import RegisterBtn from "../../../../assets/images/PlayPage/RegisterBtn.svg";
import styles from "./index.module.scss";
import ClubHubVerification from "../../../../assets/images/PlayPage/ClubHubVerification.png";
import signUpBtn from '../../../../assets/images/NewDesign/signUp.svg'

const validateEmail = (emailAddress) => {
    let input = emailAddress;

    let isValid = true;
    if (!input) {
        isValid = false;
    }
    if (typeof input !== "undefined") {
        var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(input)) {
            isValid = false;
        }
    }

    return isValid;
};

const Badge = ({ setShowSnackbar, setMsg, setSnackBarStatus }) => {
    const [userData, setUserData] = useState({
        email: "",
        firstName: "",
        lastName: "",
    });
    const [loading, isLoading] = useState({
        newsLetter: false,
    });

    const registerForNewsLetter = () => {
        if (!userData.firstName || !userData.lastName || !userData.email) {
            setMsg("Please enter valid details.");
            setSnackBarStatus("error");
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        } else if (validateEmail(userData.email)) {
            isLoading((loadingCopy) => {
                return {
                    ...loadingCopy,
                    newsLetter: true,
                };
            });

            axios
                .post("/api/v1/newsletter", userData)
                .then((response) => {
                    if (response.data.success) {
                        setMsg(
                            _.get(
                                response,
                                "data.message",
                                "Successfully registered a user for newsletter."
                            )
                        );
                        setSnackBarStatus("information");
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    } else {
                        setMsg(
                            _.get(
                                response,
                                "data.message",
                                "Something went wrong."
                            )
                        );
                        setSnackBarStatus("error");
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setSnackBarStatus("error");
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                })
                .finally(() => {
                    isLoading((loadingCopy) => {
                        return {
                            ...loadingCopy,
                            newsLetter: false,
                        };
                    });
                    setUserData({
                        email: "",
                        firstName: "",
                        lastName: "",
                    });
                });
        } else {
            setMsg("Please enter a valid email address.");
            setSnackBarStatus("error");
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        }
    };

    const handleUserData = (e) => {
        const inputId = e.target.id;
        const inputValue = e.target.value;

        setUserData((userDataCopy) => {
            return {
                ...userDataCopy,
                [inputId]: inputValue,
            };
        });
    };

    return (
        <Fragment>
            <div className={`badge-outer ${styles.badge_main__container}`}>
                {/* <div className={styles.badge_outer__container}>
                    <div className={styles.member_header}>Member of</div>

                    <div className={styles.member__container}>
                        <div className={styles.badge__container}>
                            <div className={styles.badge_image}>
                                <img
                                    src={ClubHubVerification}
                                    alt="Club Hub UK"
                                />
                            </div>
                            <div className={styles.badge_title}>
                                Club Hub UK
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className={`badge-outer-wrapper ${styles.contact_us__container}`}>
                    <div className={`badge-content ${styles.contact_us__heading}`}>
                        <div className={`main-title ${styles.contact_us__header}`}>
                            Stay updated
                        </div>

                        <div className={styles.contact_us__description}>
                            Come and be part of a community of like minded
                            parents and get updates on educational information,
                            upcoming classes and new product features right into
                            your mailbox!
                        </div>
                    </div>
                    <img src={signUpBtn} style={{
                        width: "8rem",
                        height: "3rem",
                        cursor: "pointer"
                    }} onClick={()=>window.location.href = '/signup'}/>
                        {/* <div className={styles.contact_us_input__container}>
                        <div className={styles.name_input__container}>
                            <input
                                type="text"
                                name="FName"
                                id="firstName"
                                placeholder="First Name"
                                value={userData.firstName}
                                onChange={handleUserData}
                                disabled={loading.newsLetter}
                            />

                            <input
                                type="text"
                                name="LName"
                                id="lastName"
                                placeholder="Last Name"
                                value={userData.lastName}
                                onChange={handleUserData}
                                disabled={loading.newsLetter}
                            />
                        </div>
                        <div className={styles.emailDiv}>
                            <input
                                type="email"
                                name="EmailAddress"
                                id="email"
                                placeholder="Email Address"
                                className={styles.emailAddress}
                                value={userData.email}
                                onChange={handleUserData}
                                disabled={loading.newsLetter}
                            />

                            <img
                                src={RegisterBtn}
                                alt="Register Btn"
                                className={styles.registerBtn}
                                onClick={
                                    loading.newsLetter
                                        ? null
                                        : registerForNewsLetter
                                }
                            />
                        </div>
                    </div> */}
                </div>
            </div>
        </Fragment>
    );
};

export default Badge;
