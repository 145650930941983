import React, { useState } from "react";
import style from "./navigationBtn.module.css";
import { OverlayTrigger, Popover } from "react-bootstrap";
export default ({
    setActiveNavButton,
    gamesList,
    lessonList,
    text,
    active,
    click,
    overlay,
    changeTeacherLayout,
    changeStudentLayout,
    teacherLayoutIndex,
}) => {
    const [show, setShow] = useState(false);
    const lessons = (
        <Popover className={style.lessonPopover}>
            <Popover.Content style={{ paddingLeft: "5px" }}>
                <div>
                    <div
                        style={{
                            height: `${
                                lessonList ? 40 * lessonList.length : 0
                            }px`,
                        }}
                        id={style.bar}
                    ></div>
                    {lessonList && lessonList.includes("lesson1") && (
                        <button
                            className={
                                teacherLayoutIndex === 3
                                    ? style.buttonActive
                                    : style.buttonInActive
                            }
                            onClick={() => {
                                setActiveNavButton("lessons");
                                setShow(false);
                                changeTeacherLayout(3);
                                changeStudentLayout(3);
                            }}
                        >
                            Lesson1
                        </button>
                    )}
                    {lessonList && lessonList.includes("lesson2") && (
                        <button
                            className={
                                teacherLayoutIndex === 4
                                    ? style.buttonActive
                                    : style.buttonInActive
                            }
                            onClick={() => {
                                setActiveNavButton("lessons");
                                setShow(false);
                                changeTeacherLayout(4);
                                changeStudentLayout(4);
                            }}
                        >
                            Lesson2
                        </button>
                    )}
                </div>
            </Popover.Content>
        </Popover>
    );
    const games = (
        <Popover className={style.lessonPopover}>
            <Popover.Content style={{ paddingLeft: "5px" }}>
                <div>
                    <div
                        id={style.bar}
                        style={{
                            height: `${
                                gamesList ? 40 * gamesList.length : 0
                            }px`,
                        }}
                    ></div>
                    {gamesList && gamesList.includes("bubblePop") && (
                        <button
                            className={
                                teacherLayoutIndex === 5
                                    ? style.buttonActive
                                    : style.buttonInActive
                            }
                            onClick={() => {
                                setActiveNavButton("games");
                                setShow(false);
                                changeTeacherLayout(5);
                                changeStudentLayout(5);
                            }}
                        >
                            Bubble pop
                        </button>
                    )}
                    {gamesList && gamesList.includes("tileMatch") && (
                        <button
                            className={
                                teacherLayoutIndex === 7
                                    ? style.buttonActive
                                    : style.buttonInActive
                            }
                            onClick={() => {
                                setActiveNavButton("lessons");
                                setShow(false);
                                changeTeacherLayout(7);
                                changeStudentLayout(6);
                            }}
                        >
                            Tile match
                        </button>
                    )}
                    {gamesList && gamesList.includes("maze") && (
                        <button
                            className={
                                teacherLayoutIndex === 8
                                    ? style.buttonActive
                                    : style.buttonInActive
                            }
                            onClick={() => {
                                setActiveNavButton("lessons");
                                setShow(false);
                                changeTeacherLayout(8);
                                changeStudentLayout(9);
                            }}
                        >
                            Maze
                        </button>
                    )}
                </div>
            </Popover.Content>
        </Popover>
    );
    return (
        <>
            {overlay ? (
                <OverlayTrigger
                    trigger="click"
                    placement="left"
                    overlay={text === "Lesson" ? lessons : games}
                    alt="screens"
                    rootClose
                    show={show}
                    style={{ cursor: "pointer" }}
                >
                    <div
                        onClick={() => {
                            if (click) {
                                click();
                            }
                            setShow(!show);
                        }}
                        className={
                            active
                                ? style.containerActive
                                : style.containerInactive
                        }
                    >
                        {text}
                    </div>
                </OverlayTrigger>
            ) : (
                <div
                    onClick={click}
                    className={
                        active ? style.containerActive : style.containerInactive
                    }
                >
                    {text}
                </div>
            )}
        </>
    );
};
