import React from "react";
import { Link } from "react-router-dom";
import FooterNew from "../../components/FooterNew";
import Navbar from "../../components/Navbar";
import styles from "./index.module.scss";

const PrivacyPolicy = () => {
    return (
        <div className={styles.mainContainer}>
            <Navbar />

            <div className={styles.pageContainer}>
                <div className={styles.contentContainer}>
                    <h1 className={styles.pageHeading}>
                        Plassroom Privacy Policy
                    </h1>

                    <div className={styles.updatedTime}>
                        Last Updated: 10 Nov 2021
                    </div>

                    <div className={styles.bodyContainer}>
                        This privacy policy applies between you, the User of
                        this Website, and Plassroom Ltd., the owner and provider
                        of this Website. Throughout the site, the terms “we”,
                        “us” and “our” and “Plassroom” refer to Plassroom Ltd.
                    </div>

                    <div className={styles.bodyContainer}>
                        Plassroom Ltd. takes the privacy of your information
                        very seriously. This privacy policy applies to our use
                        of any or all Data collected by us or provided by you in
                        relation to your use of the Website and services
                        provided by us on it.
                    </div>

                    <div className={styles.bodyContainer}>
                        This privacy policy should be read alongside, and in
                        addition to, our{" "}
                        <Link to="/termsofservice">Terms of Service</Link>
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 1 – DEFINITIONS AND INTERPRETATION
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        1. In this privacy policy, the following definitions are
                        used:
                        <table className={styles.table}>
                            <tr>
                                <td>Data</td>
                                <td>
                                    collectively all information that you submit
                                    to Plassroom via the Website. This
                                    definition incorporates, where applicable,
                                    the definitions provided in the Data
                                    Protection Laws;
                                </td>
                            </tr>

                            <tr>
                                <td>Data Protection Laws</td>
                                <td>
                                    any applicable law relating to the
                                    processing of personal Data, including but
                                    not limited to the GDPR, and any national
                                    implementing and supplementary laws,
                                    regulations and secondary legislation;
                                </td>
                            </tr>

                            <tr>
                                <td>GDPR</td>
                                <td>
                                    the UK General Data Protection Regulation;
                                </td>
                            </tr>

                            <tr>
                                <td>Plassroom Ltd., we or us</td>
                                <td>
                                    Plassroom Ltd., a company incorporated in
                                    England and Wales with registered number
                                    13225803 whose registered office is at 20-22
                                    Wenlock Road, London, N1 7GU, England;
                                </td>
                            </tr>

                            <tr>
                                <td>User or you</td>
                                <td>
                                    any third party that accesses the Website
                                    and is not either (i) employed by Plassroom
                                    and acting in the course of their employment
                                    or (ii) engaged as a consultant or otherwise
                                    providing services to Plassroom and
                                    accessing the Website in connection with the
                                    provision of such services; and
                                </td>
                            </tr>

                            <tr>
                                <td>Website</td>
                                <td>
                                    the website that you are currently using,
                                    www.plassroom.com, and any sub-domains of
                                    this site unless expressly excluded by their
                                    own terms and conditions.
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        2. In this privacy policy, unless the context requires a
                        different interpretation:
                        <ol type="a">
                            <li>
                                the singular includes the plural and vice versa;
                            </li>

                            <li>
                                references to sub-clauses, clauses, schedules or
                                appendices are to subclauses, clauses, schedules
                                or appendices of this privacy policy;
                            </li>

                            <li>
                                a reference to a person includes firms,
                                companies, government entities, trusts and
                                partnerships;
                            </li>

                            <li>
                                "including" is understood to mean "including
                                without limitation";
                            </li>

                            <li>
                                reference to any statutory provision includes
                                any modification or amendment of it;
                            </li>

                            <li>
                                the headings and sub-headings do not form part
                                of this privacy policy.
                            </li>
                        </ol>
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 2 – SCOPE OF THIS PRIVACY POLICY
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        3. This privacy policy applies only to the actions of
                        Plassroom and Users with respect to this Website. It
                        does not extend to any websites that can be accessed
                        from this Website including, but not limited to, any
                        links we may provide to social media websites.
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        4. For purposes of the applicable Data Protection Laws,
                        Plassroom is the "data controller". This means that
                        Plassroom determines the purposes for which, and the
                        manner in which, your Data is processed.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 3 – DATA COLLECTION
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        5. We may collect the following Data, which includes
                        personal Data, from you:
                        <ol type="a">
                            <li>name;</li>
                            <li>date of birth;</li>
                            <li>
                                contact Information such as email addresses and
                                contact numbers;
                            </li>
                            <li>location (state, country);</li>
                            <li>picture of identification document;</li>
                            <li>picture of latest criminal check document;</li>
                        </ol>
                        in each case, in accordance with this privacy policy.
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        6. Financial information such as credit / debit card
                        numbers are obtained and stored only on Stripe Ltd. and
                        its servers and we adhere to their company’s terms and
                        privacy policy on this matter.
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        7. We collect Data in the following ways:
                        <ol type="a">
                            <li>data is given to us by you; and</li>
                            <li>data is collected automatically.</li>
                        </ol>
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        8. Plassroom Ltd. will collect your Data in a number of
                        ways, for example:
                        <ol type="a">
                            <li>
                                when you contact us through the Website, by
                                telephone, post, e-mail or through any other
                                means;
                            </li>
                            <li>
                                when you register with us and set up an account
                                to receive our products/services;
                            </li>
                            <li>
                                when you elect to receive marketing
                                communications from us;
                            </li>
                        </ol>
                        in each case, in accordance with this privacy policy
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        9. To the extent that you access the Website, we will
                        collect your Data automatically, for example:
                        <ol type="a">
                            <li>
                                we automatically collect some information about
                                your visit to the Website. This information
                                helps us to make improvements to Website content
                                and navigation, recommendations that are
                                personalized to you and includes your IP
                                address, the date, times and frequency with
                                which you access the Website and the way you use
                                and interact with its content.
                            </li>
                        </ol>
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        10. Any or all of the above Data may be required by us
                        from time to time in order to provide you with the best
                        possible service and experience when using our Website.
                        <br />
                        Specifically, Data may be used by us for the following
                        reasons:
                        <ol type="a">
                            <li>internal record keeping;</li>
                            <li>improvement of our products / services;</li>
                            <li>
                                recommendation of classes that may be of
                                interest to you;
                            </li>
                            <li>
                                transmission by email of marketing materials
                                that may be of interest to you;
                            </li>
                        </ol>
                        in each case, in accordance with this privacy policy.
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        11. We may use your Data for the above purposes if we
                        deem it necessary to do so for our legitimate interests.
                        If you are not satisfied with this, you have the right
                        to object in certain circumstances (see the section
                        headed "Your rights" below).
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        12. For the delivery of direct marketing to you via
                        e-mail, we'll need your consent, whether via an opt-in
                        or soft-opt-in:
                        <ol type="a">
                            <li>
                                soft opt-in consent is a specific type of
                                consent which applies when you have previously
                                engaged with us (for example, you contact us to
                                ask us for more details about a particular
                                product/service, and we are marketing similar
                                products/services). Under "soft opt-in" consent,
                                we will take your consent as given unless you
                                opt-out.
                            </li>
                            <li>
                                for other types of e-marketing, we are required
                                to obtain your explicit consent; that is, you
                                need to take positive and affirmative action
                                when consenting by, for example, checking a tick
                                box that we'll provide. This can be found during
                                registration of an account on Plassroom, and
                                within the Settings pages (Email preference tab)
                                upon login.
                            </li>
                            <li>
                                if you are not satisfied with our approach to
                                marketing, you have the right to withdraw
                                consent at any time. To find out how to withdraw
                                your consent, see the section headed "Your
                                rights" below.
                            </li>
                        </ol>
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        13. When you register with us and set up an account to
                        receive our services, the legal basis for this
                        processing is the performance of a contract between you
                        and us and/or taking steps, at your request, to enter
                        into such a contract.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 4 – WHO WE SHARE DATA WITH
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        14. We may share your Data with the following groups of
                        people for the following reasons:
                        <ol type="a">
                            <li>
                                our employees, agents and/or professional
                                advisors - for administration of account and
                                other functions on the platform, to conduct any
                                investigation due to quality assurance checks,
                                dispute or report or for internal data analytics
                                by Plassroom;
                            </li>
                            <li>
                                third party payment providers who process
                                payments made over the Website - to enable third
                                party payment providers to process user payments
                                and refunds;
                            </li>
                            <li>
                                relevant authorities - to facilitate or assist
                                in the detection of crime, collection of taxes
                                or duties, where required;
                            </li>
                        </ol>
                        in each case, in accordance with this privacy policy.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 5 – KEEPING DATA SECURE
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        15. We will use technical and organisational measures to
                        safeguard your Data, for example:
                        <ol type="a">
                            <li>
                                access to your account is controlled by a
                                password and a user name that is unique to you.
                            </li>
                            <li>we store your Data on secure servers.</li>
                            <li>
                                class recordings cannot be accessed, even by
                                internal staff, except for those with Master
                                Admin access capabilities (ie. Plassroom CEO and
                                COO only). Access is only when a request has
                                been approved by these parties for a legitimate
                                reason according to the{" "}
                                <Link to="/termsofservice">
                                    Terms of Service
                                </Link>{" "}
                                and users will be notified as such upon such an
                                access and for what purpose.
                            </li>
                        </ol>
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        16. Technical and organisational measures include
                        measures to deal with any suspected data breach. If you
                        suspect any misuse or loss or unauthorised access to
                        your Data, please let us know immediately by contacting
                        us via this e-mail address:{" "}
                        <a href="mailto:enquiry@plassroom.com">
                            enquiry@plassroom.com
                        </a>
                        .
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        17. If you want detailed information from Get Safe
                        Online on how to protect your information and your
                        computers and devices against fraud, identity theft,
                        viruses and many other online problems, please visit
                        <a href="www.getsafeonline.org">
                            www.getsafeonline.org
                        </a>
                        . Get Safe Online is supported by HM Government and
                        leading businesses.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 6 – DATA RETENTION
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        18. Unless a longer retention period is required or
                        permitted by law, we will only hold your Data on our
                        systems for the period necessary to fulfil the purposes
                        outlined in this privacy policy or until you request
                        that the Data be deleted.
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        19. Even if we delete your Data, it may persist on
                        backup or archival media for legal, tax or regulatory
                        purposes.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 7 – YOUR RIGHTS
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        20. You have the following rights in relation to your
                        Data:
                        <ol type="a">
                            <li>
                                Right to access - the right to request (i)
                                copies of the information we hold about you at
                                any time, or (ii) that we modify, update or
                                delete such information. If we provide you with
                                access to the information we hold about you, we
                                will not charge you for this, unless your
                                request is "manifestly unfounded or excessive."
                                Where we are legally permitted to do so, we may
                                refuse your request. If we refuse your request,
                                we will tell you the reasons why.
                            </li>
                            <li>
                                Right to correct - the right to have your Data
                                rectified if it is inaccurate or incomplete.
                            </li>
                            <li>
                                Right to erase - the right to request that we
                                delete or remove your Data from our systems.
                            </li>
                            <li>
                                Right to restrict our use of your Data - the
                                right to "block" us from using your Data or
                                limit the way in which we can use it.
                            </li>
                            <li>
                                Right to data portability - the right to request
                                that we move, copy or transfer your Data.
                            </li>
                            <li>
                                Right to object - the right to object to our use
                                of your Data including where we use it for our
                                legitimate interests.
                            </li>
                        </ol>
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        21. To make enquiries, exercise any of your rights set
                        out above, or withdraw your consent to the processing of
                        your Data (where consent is our legal basis for
                        processing your Data), please contact us via this e-mail
                        address:{" "}
                        <a href="mailto:enquiry@plassroom.com">
                            enquiry@plassroom.com
                        </a>
                        .
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        22. If you are not satisfied with the way a complaint
                        you make in relation to your Data is handled by us, you
                        may be able to refer your complaint to the relevant data
                        protection authority. For the UK, this is the
                        Information Commissioner's Office (ICO). The ICO's
                        contact details can be found on their website at
                        <a href="https://ico.org.uk/">https://ico.org.uk/</a>.
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        23. It is important that the Data we hold about you is
                        accurate and current. Please keep us informed if your
                        Data changes during the period for which we hold it.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 8 – LINKS TO OTHER WEBSITES
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        24. This Website may, from time to time, provide links
                        to other websites. We have no control over such websites
                        and are not responsible for the content of these
                        websites. This privacy policy does not extend to your
                        use of such websites. You are advised to read the
                        privacy policy or statement of other websites prior to
                        using them.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 9 – CHANGES OF BUSINESS OWNERSHIP AND CONTROL
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        25. Plassroom may, from time to time, expand or reduce
                        our business and this may involve the sale and/or the
                        transfer of control of all or part of Plassroom. Data
                        provided by Users will, where it is relevant to any part
                        of our business so transferred, be transferred along
                        with that part and the new owner or newly controlling
                        party will, under the terms of this privacy policy, be
                        permitted to use the Data for the purposes for which it
                        was originally supplied to us.
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        26. We may also disclose Data to a prospective purchaser
                        of our business or any part of it.
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        27. In the above instances, we will take steps with the
                        aim of ensuring your privacy is protected.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 10 – GENERAL
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        28. You may not transfer any of your rights under this
                        privacy policy to any other person. We may transfer our
                        rights under this privacy policy where we reasonably
                        believe your rights will not be affected.
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        29. If any court or competent authority finds that any
                        provision of this privacy policy (or part of any
                        provision) is invalid, illegal or unenforceable, that
                        provision or partprovision will, to the extent required,
                        be deemed to be deleted, and the validity and
                        enforceability of the other provisions of this privacy
                        policy will not be affected.
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        30. Unless otherwise agreed, no delay, act or omission
                        by a party in exercising any right or remedy will be
                        deemed a waiver of that, or any other, right or remedy.
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        31. This Agreement will be governed by and interpreted
                        according to the law of England and Wales. All disputes
                        arising under the Agreement will be subject to the
                        exclusive jurisdiction of the English and Welsh courts.
                    </div>

                    <div
                        className={styles.bodyContainer}
                        style={{
                            fontWeight: "bold",
                        }}
                    >
                        Changes to this privacy policy
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        32. Plassroom reserves the right to change this privacy
                        policy as we may deem necessary from time to time or as
                        may be required by law. Any changes will be immediately
                        posted on the Website and will indicate at the top of
                        this page the date these terms were last revised. It is
                        your responsibility to check this page periodically for
                        changes and you are deemed to have accepted the terms of
                        the privacy policy on your first use of the Website
                        following the alterations.
                    </div>

                    <div
                        className={`${styles.bodyContainer} ${styles.bulletPoints}`}
                    >
                        33. You may contact Plassroom Ltd. by email at{" "}
                        <a href="mailto:enquiry@plassroom.com">
                            enquiry@plassroom.com
                        </a>
                        .
                    </div>
                </div>

                <FooterNew />
            </div>
        </div>
    );
};

export default PrivacyPolicy;
