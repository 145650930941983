import React from 'react'
import styles from "./layout.module.css";

export default function Layout2() {
    return (
        <>
           <div className="row justify-content-center">
                <div className={styles.leftCol}>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
            </div>
            <div className={styles.centerCol} >
                <div className={styles.videoContainer} style={{ width: "604px", height: "367px", backgroundColor: "#E9F3FB" }}>
                    <h2 className={styles.containerText}>T</h2>
                </div>
                <div className="row justify-content-end">
                    <div className={styles.videoContainer}>
                        <h2 className={styles.containerText}>S</h2>
                    </div>
                    <div className={styles.videoContainer}>
                        <h2 className={styles.containerText}>S</h2>
                    </div>
                </div>

            </div>
            <div className={styles.rightCol}>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
            </div>
        </div>
        </ >
    )
}
