import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ChatIcon from "../../assets/images/PlayPage/MessageIcon.svg";
import Send from "../../assets/images/Send.svg";
import Snackbar from "../Snackbar";
import styles from "./index.module.scss";

const TimeModule = ({ time }) => {
    return time ? (
        <div>
            {`${moment(time).format("DD/MM h.mm A")} ${moment
                .tz(moment.tz.guess())
                .zoneAbbr()}`}
        </div>
    ) : (
        <div>-</div>
    );
};

const ChatForm = ({ classDetails, classes }) => {
    let location = useLocation();
    const history = useHistory();

    const [messageData, setMessageData] = useState("");
    const [isMessageSent, setIsMessageSent] = useState(false);
    const [showChatForm, setShowChatForm] = useState(false);
    const [userData, setUserData] = useState(undefined);

    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackBarStatus, setSnackBarStatus] = useState("error");

    useEffect(() => {
        let localUserData = localStorage.getItem("auth");

        if (localUserData) {
            setUserData(JSON.parse(localUserData));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setShowChatForm(
            Boolean(new URLSearchParams(location.search).get("modelOpen"))
        );
        // eslint-disable-next-line
    }, [location]);

    const handleMessageChange = (e) => {
        setMessageData(e.currentTarget.textContent);
    };

    const handleSubmitMessage = () => {
        if (messageData.length) {
            const requestBody = {
                sessionId: classDetails._id,
                parentId: userData.user._id,
                teacherId: classDetails.teacher_id._id,
                message: messageData,
            };

            axios
                .post("api/v1/chat/parent-enquiry", requestBody)
                .then((response) => {
                    if (response.data.success) {
                        setIsMessageSent(true);
                    } else {
                        setSnackBarStatus("error");
                        setMsg(
                            _.get(
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    console.log(error);

                    setSnackBarStatus("error");
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                });
        } else {
            setShowChatForm(false);
        }
    };

    const handleInitiateChat = () => {
        history.push({
            pathname: "/chat",
            state: {
                classDetails,
            },
        });
    };

    return (
        <Fragment>
            {msg && (
                <Snackbar
                    status={snackBarStatus}
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                />
            )}

            {!userData || userData.user.role === "student" ? (
                <img
                    src={ChatIcon}
                    alt="Chat Icon"
                    className={`${styles.chatIcon} ${classes}`}
                    onClick={handleInitiateChat}
                />
            ) : null}

            {showChatForm ? (
                <div className={styles.chat_form__main__container}>
                    <div
                        className={`${styles.chat_form__outer_container} ${
                            isMessageSent
                                ? styles.chat_success_form__outer__container
                                : null
                        }`}
                    >
                        <div className={styles.chat_form__close_container}>
                            <span
                                onClick={() => setShowChatForm(false)}
                                className={styles.chat_form__close__container}
                            >
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className={styles.chat_form__close}
                                />
                            </span>
                        </div>

                        {isMessageSent ? (
                            <div className={styles.success_message__container}>
                                Your message has been sent to your teacher and
                                your teacher will get back to you via email as
                                soon as they can.
                            </div>
                        ) : (
                            <div className={styles.chat_form__container}>
                                <div className={styles.chat_form__class_name}>
                                    {_.get(classDetails, "class_name", "-")}
                                </div>

                                <div
                                    className={
                                        styles.chat_form__teacher_name__container
                                    }
                                >
                                    <div
                                        className={
                                            styles.chat_form__teacher_name
                                        }
                                    >
                                        Teacher{" "}
                                        {_.get(
                                            classDetails,
                                            "teacher_id.preferedName",
                                            "-"
                                        )}
                                    </div>

                                    <div
                                        className={styles.chat_form__class_date}
                                    >
                                        <TimeModule
                                            time={_.get(
                                                classDetails,
                                                "configuration.session_start_time",
                                                null
                                            )}
                                        />
                                    </div>
                                </div>

                                <span
                                    className={styles.chat_form__message_box}
                                    role="textbox"
                                    onInput={(e) => handleMessageChange(e)}
                                    contentEditable
                                />

                                <div>{messageData.length}/1000</div>

                                <img
                                    src={Send}
                                    alt=""
                                    className={styles.chat_form__submit_btn}
                                    onClick={handleSubmitMessage}
                                />
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
        </Fragment>
    );
};

export default ChatForm;
