import React, { useState, useEffect } from "react";
import styles from "../classRatings.module.css";
import StarRatings from "react-star-ratings";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import Snackbar from "../../../Snackbar";
import Pagination from "../../../../routes/GuestUser/components/Pagination/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import Delete from "../../../../assets/images/icon/accountSettings/Delete.svg";
import Submit from "../../../../assets/images/icon/accountSettings/submit.svg";
import Delete from "../../../../assets/images/icon/accountSettings/Delete.svg";
import CarouselSmall from "../../../Carousels/CarouselSmall";
import { useHistory } from "react-router";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function ClassRatingsStudent() {
    const history = useHistory();
    // const currentUserRole = JSON.parse(localStorage.getItem("auth"))?.user
    //     ?.role;
    // const [noClassAttended, setNoClassAttended] = useState(false);
    // const [reviewPending, setReviewPending] = useState(true);
    // const [classRatings, setClassRatings] = useState(0);
    // const [teacherRatings, setTeacherRatings] = useState(0);

    const [ratingAndReviewsData, setRatingAndReviewsData] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [reviewsPerPage, setReviewsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteReview, setDeleteReview] = useState("");
    // const [reviewComment, setReviewComment] = useState("");
    const [pendingDatas, setPendingDatas] = useState([]);
    // const [reviewData, setReviewData] = useState({});
    const converter = require("number-to-words");
    const [snackBarStatus, setSnackBarStatus] = useState("error");
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    // const [checked, setChecked] = useState(false);
    const [disable, setDisable] = useState(false);
    const parentId = JSON.parse(localStorage.getItem("auth"))?.user._id;

    useEffect(() => {
        fetchReviewsAndRatings();
        fetchPending();
        // eslint-disable-next-line
    }, [pageNumber, reviewsPerPage]);

    const fetchReviewsAndRatings = async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(
                `/api/v1/ratingAndReviews?page=${pageNumber}&perPage=${reviewsPerPage}`
            );
            if (data?.success) {
                setRatingAndReviewsData(data?.data?.ratingAndReviewsData);
                setPaginationInfo(data?.data?.pagination);
                setDisable(false);
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const fetchPending = () => {
        axios
            .get("api/v1/ratingAndReviews/pending")
            .then((response) => {
                if (
                    response?.data?.data?.pendingRatingAndReviewsData.length !==
                    0
                ) {
                    let data =
                        response?.data?.data?.pendingRatingAndReviewsData.map(
                            (item, index) => {
                                return {
                                    ...item,
                                    class_rate: 0,
                                    teacher_rate: 0,
                                };
                            }
                        );
                    setPendingDatas(data);
                } else {
                    setPendingDatas([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const deleteRating = () => {
        // http://localhost:3002/api/v1/ratingAndReviews/615d86b8857c1e137448bb7a'
        axios
            .delete(`/api/v1/ratingAndReviews/${deleteReview}`)
            .then((response) => {
                setDeleteModal(false);
                setDeleteReview("");
                fetchReviewsAndRatings();
                fetchPending();
            })
            .catch((error) => {
                console.log(error);
                setSnackBarStatus("error");
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
            });
    };

    const submitReview = async (updateData) => {
        if (updateData?.class_rate < 1 || updateData?.teacher_rate < 1) {
            setSnackBarStatus("error");
            setMsg("Pease rate class and teacher first.");
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        } else {
            setDisable(true);
            const data = {
                class_id: updateData?.class_id,
                session_id: updateData?.session_id,
                teacher_id: updateData?.teacher_id?._id,
                session_history_id: updateData?._id,
                parent_id: parentId,
                class_rate: updateData?.class_rate,
                teacher_rate: updateData?.teacher_rate,
                review: updateData?.review,
            };
            await axios
                .post(`/api/v1/ratingAndReviews`, data)
                .then((response) => {
                    fetchReviewsAndRatings();
                    fetchPending();

                    setSnackBarStatus("information");
                    setMsg("Review Submitted");
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                })
                .catch((error) => {
                    console.log(error);
                    setSnackBarStatus("error");
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                });
        }
    };

    const openUploadModal = () => {
        setDeleteModal(true);
    };

    const closeUploadModal = () => {
        setDeleteModal(false);
        setDeleteReview("");
    };

    return (
        <React.Fragment>
            {deleteModal && (
                <div
                    className={`${styles.uploadModal} text-center`}
                    style={{ top: "30%" }}
                >
                    <FontAwesomeIcon
                        className={styles.crossIcon}
                        icon={faTimes}
                        onClick={closeUploadModal}
                    />
                    <div className={styles.uploadArea}>
                        <p className={styles.modalHeading}>
                            Are you sure you would like to remove this review?
                        </p>
                    </div>
                    <img
                        onClick={() => {
                            deleteRating();
                        }}
                        src={Delete}
                        className={`${styles.submitButton}`}
                        alt="delete"
                    />
                </div>
            )}
            <div className="rating-outer">
                <div className="rating-info">
                    <p>
                        This will appear on the Plassroom website along with
                        parent’s first name.
                    </p>
                </div>
                <div className="ratting-inner">
                    {pendingDatas.length > 0 &&
                        pendingDatas.map((pendingData, index) => {
                            return (
                                <div className="rating-list" key={index}>
                                    <div className="rating-blog">
                                        <h3>{pendingData?.class_name}</h3>
                                        <h4>
                                            {pendingData?.teacher_id?.name ||
                                                pendingData?.teacher_id
                                                    ?.preferedName}
                                        </h4>
                                        <div className="review-rating">
                                            <div className="d-flex align-items-center mb-2">
                                                <p>Rating of class</p>
                                                <div>
                                                    <StarRatings
                                                        starRatedColor="#FCB648"
                                                        starDimension="32px"
                                                        starSpacing="1px"
                                                        numberOfStars={5}
                                                        starEmptyColor="rgb(203, 211, 227)"
                                                        starHoverColor="#FCB648"
                                                        rating={
                                                            pendingData?.class_rate
                                                        }
                                                        changeRating={(
                                                            newRatings
                                                        ) => {
                                                            setPendingDatas([
                                                                ...pendingDatas.slice(
                                                                    0,
                                                                    index
                                                                ),
                                                                {
                                                                    ...pendingData,
                                                                    class_rate:
                                                                        newRatings,
                                                                },
                                                                ...pendingDatas.slice(
                                                                    index + 1
                                                                ),
                                                            ]);
                                                        }}
                                                        svgIconViewBox={
                                                            "0 0 1000 1000"
                                                        }
                                                        svgIconPath={
                                                            "M10,394.5c0-14.8,10.9-23.9,32.7-27.4l295.4-42.2L471,56.9c7.7-16.2,17.2-24.3,28.5-24.3s21.1,8.1,29.5,24.3l131.9,267.9l295.4,42.2c22.5,3.5,33.8,12.7,33.8,27.4c0,8.4-5.3,17.9-15.8,28.5L760,630.8l50.6,294.3c0.7,2.8,1.1,7,1.1,12.7c0,7.7-2.1,14.4-6.3,20c-4.2,5.6-10.2,8.8-17.9,9.5c-7,0-14.8-2.5-23.2-7.4L499.5,820.7L235.7,959.9c-9.1,4.9-17.2,7.4-24.3,7.4c-7.7,0-13.7-3.2-17.9-9.5c-4.2-6.3-6.3-13-6.3-20c0-2.8,0.4-7,1.1-12.7l50.6-294.3L24.8,423C14.9,412.4,10,402.9,10,394.5L10,394.5z"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-2">
                                                <p>Rating of facilitator</p>
                                                <div>
                                                    <StarRatings
                                                        starRatedColor="#FCB648"
                                                        starDimension="32px"
                                                        starSpacing="1px"
                                                        numberOfStars={5}
                                                        starEmptyColor="rgb(203, 211, 227)"
                                                        starHoverColor="#FCB648"
                                                        rating={
                                                            pendingData?.teacher_rate
                                                        }
                                                        changeRating={(
                                                            newRatings
                                                        ) => {
                                                            setPendingDatas([
                                                                ...pendingDatas.slice(
                                                                    0,
                                                                    index
                                                                ),
                                                                {
                                                                    ...pendingData,
                                                                    teacher_rate:
                                                                        newRatings,
                                                                },
                                                                ...pendingDatas.slice(
                                                                    index + 1
                                                                ),
                                                            ]);
                                                        }}
                                                        svgIconViewBox={
                                                            "0 0 1000 1000"
                                                        }
                                                        svgIconPath={
                                                            "M10,394.5c0-14.8,10.9-23.9,32.7-27.4l295.4-42.2L471,56.9c7.7-16.2,17.2-24.3,28.5-24.3s21.1,8.1,29.5,24.3l131.9,267.9l295.4,42.2c22.5,3.5,33.8,12.7,33.8,27.4c0,8.4-5.3,17.9-15.8,28.5L760,630.8l50.6,294.3c0.7,2.8,1.1,7,1.1,12.7c0,7.7-2.1,14.4-6.3,20c-4.2,5.6-10.2,8.8-17.9,9.5c-7,0-14.8-2.5-23.2-7.4L499.5,820.7L235.7,959.9c-9.1,4.9-17.2,7.4-24.3,7.4c-7.7,0-13.7-3.2-17.9-9.5c-4.2-6.3-6.3-13-6.3-20c0-2.8,0.4-7,1.1-12.7l50.6-294.3L24.8,423C14.9,412.4,10,402.9,10,394.5L10,394.5z"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <Form>
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlTextarea1"
                                                >
                                                    <Form.Label>
                                                        Review (optional)
                                                    </Form.Label>

                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        placeholder="Please type here"
                                                        className={
                                                            styles.reviewInput
                                                        }
                                                        onChange={(e) => {
                                                            setPendingDatas([
                                                                ...pendingDatas.slice(
                                                                    0,
                                                                    index
                                                                ),
                                                                {
                                                                    ...pendingData,
                                                                    review: e
                                                                        .target
                                                                        .value,
                                                                },
                                                                ...pendingDatas.slice(
                                                                    index + 1
                                                                ),
                                                            ]);
                                                        }}
                                                    />
                                                </Form.Group>
                                                <div className="btn-wrapper">
                                                    <Button
                                                        className="btn"
                                                        onClick={() => {
                                                            submitReview(
                                                                pendingData
                                                            );
                                                        }}
                                                        disabled={disable}
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    {pendingDatas.length < 1 &&
                    ratingAndReviewsData.length < 1 ? (
                        <div>
                            <p className={styles.noRating}>
                                No previous ratings and reviews. <br></br>
                                After your child attends a class, we will prompt
                                you to rate and review the class.
                            </p>
                            <h2
                                className={styles.seeList}
                                onClick={() => history.push("/")}
                            >
                                See list of upcoming classes
                            </h2>
                        </div>
                    ) : (
                        <div>
                            {ratingAndReviewsData.length > 0 && (
                                <h5 className={styles.heading}>
                                    Previous ratings and reviews
                                </h5>
                            )}
                            {ratingAndReviewsData.length > 0 &&
                                ratingAndReviewsData?.map(
                                    (review, reviewIdx) => {
                                        return (
                                            <div
                                                className="rating-list"
                                                key={reviewIdx}
                                            >
                                                <div className="rating-blog">
                                                    <h3>
                                                        {review
                                                            ?.session_history_id
                                                            ?.class_name &&
                                                            review
                                                                ?.session_history_id
                                                                ?.class_name}
                                                    </h3>
                                                    <h4>
                                                        {review?.teacher_id
                                                            ?.preferedName
                                                            ? review?.teacher_id
                                                                  ?.preferedName
                                                            : review?.teacher_id
                                                                  ?.name}
                                                    </h4>
                                                    <div className="review-rating">
                                                        <div className="d-flex align-items-center mb-2">
                                                            <p>
                                                                Rating of class
                                                            </p>
                                                            <div>
                                                                <StarRatings
                                                                    starRatedColor="#FCB648"
                                                                    starDimension="32px"
                                                                    starSpacing="1px"
                                                                    numberOfStars={
                                                                        review?.class_rate
                                                                    }
                                                                    rating={
                                                                        review?.class_rate
                                                                    }
                                                                    svgIconViewBox={
                                                                        "0 0 1000 1000"
                                                                    }
                                                                    svgIconPath={
                                                                        "M10,394.5c0-14.8,10.9-23.9,32.7-27.4l295.4-42.2L471,56.9c7.7-16.2,17.2-24.3,28.5-24.3s21.1,8.1,29.5,24.3l131.9,267.9l295.4,42.2c22.5,3.5,33.8,12.7,33.8,27.4c0,8.4-5.3,17.9-15.8,28.5L760,630.8l50.6,294.3c0.7,2.8,1.1,7,1.1,12.7c0,7.7-2.1,14.4-6.3,20c-4.2,5.6-10.2,8.8-17.9,9.5c-7,0-14.8-2.5-23.2-7.4L499.5,820.7L235.7,959.9c-9.1,4.9-17.2,7.4-24.3,7.4c-7.7,0-13.7-3.2-17.9-9.5c-4.2-6.3-6.3-13-6.3-20c0-2.8,0.4-7,1.1-12.7l50.6-294.3L24.8,423C14.9,412.4,10,402.9,10,394.5L10,394.5z"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center mb-2">
                                                            <p>
                                                                Rating of
                                                                facilitator
                                                            </p>
                                                            <div>
                                                                <StarRatings
                                                                    starRatedColor="#FCB648"
                                                                    starDimension="32px"
                                                                    starSpacing="1px"
                                                                    numberOfStars={
                                                                        review?.teacher_rate
                                                                    }
                                                                    rating={
                                                                        review?.teacher_rate
                                                                    }
                                                                    svgIconViewBox={
                                                                        "0 0 1000 1000"
                                                                    }
                                                                    svgIconPath={
                                                                        "M10,394.5c0-14.8,10.9-23.9,32.7-27.4l295.4-42.2L471,56.9c7.7-16.2,17.2-24.3,28.5-24.3s21.1,8.1,29.5,24.3l131.9,267.9l295.4,42.2c22.5,3.5,33.8,12.7,33.8,27.4c0,8.4-5.3,17.9-15.8,28.5L760,630.8l50.6,294.3c0.7,2.8,1.1,7,1.1,12.7c0,7.7-2.1,14.4-6.3,20c-4.2,5.6-10.2,8.8-17.9,9.5c-7,0-14.8-2.5-23.2-7.4L499.5,820.7L235.7,959.9c-9.1,4.9-17.2,7.4-24.3,7.4c-7.7,0-13.7-3.2-17.9-9.5c-4.2-6.3-6.3-13-6.3-20c0-2.8,0.4-7,1.1-12.7l50.6-294.3L24.8,423C14.9,412.4,10,402.9,10,394.5L10,394.5z"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <Form>
                                                            <Form.Group
                                                                className="mb-3"
                                                                controlId="exampleForm.ControlTextarea1"
                                                            >
                                                                <Form.Label>
                                                                    Review
                                                                    (optional)
                                                                </Form.Label>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    value={
                                                                        !!review?.review
                                                                            ? review?.review
                                                                            : ""
                                                                    }
                                                                    placeholder="-"
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                            <div className="btn-wrapper">
                                                                <Button
                                                                    className="btn btn-orange"
                                                                    onClick={() => {
                                                                        openUploadModal();
                                                                        setDeleteReview(
                                                                            review?._id
                                                                        );
                                                                    }}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                    )}

                    {paginationInfo ? (
                        <Pagination
                            paginationInfo={paginationInfo}
                            setPageNumber={setPageNumber}
                        />
                    ) : null}
                </div>
            </div>
            {msg && (
                <Snackbar
                    status={snackBarStatus}
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
        </React.Fragment>
    );
}
