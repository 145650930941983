import axios from "axios";
import React, { useEffect, useState } from "react";
import DownArrow from "../../assets/images/D.svg";
import LeftArrow from "../../assets/images/L.svg";
import RightArrow from "../../assets/images/R.svg";
import UpArrow from "../../assets/images/U.svg";
import styles from "./WhiteboardSetting.module.scss";
import Snackbar from "../../components/Snackbar";
import _ from "lodash";

const WhiteboardSetting = ({
    session,
    setColor,
    color,
    selectedMedia,
    setLeft,
    setUp,
    left,
    up,
    participants,
    socket,
    key,
    fitToScreen,
    setFitToScreen,
    studentAccess,
    teacherName,
    studentsData,
    data,
    colorType,
    setColorType,
    individualBgColor,
    setIndividualBgColor,
    panelID,
    parentUserIds,
    setPosition,
    newPosition,
}) => {
    // const currentData = JSON.parse(localStorage.getItem("auth")).user._id;
    // const role = JSON.parse(localStorage.getItem("auth")).user.role;

    const userIds = studentsData.map((student) => {
        return student.parentId;
    });

    const [settingType, setSettingType] = useState("access");
    const [accessAll, setAccessAll] = useState(false);
    const [activeIndex, setActiveIndex] = useState([]);
    const [studentColorId, setStudentColorId] = useState();
    const [currentSessionData, setCurrentSessionData] = useState();
    const [nonAccessedStudents, setNonAccessedStudents] = useState([]);
    const [panelIndex, setPanelIndex] = useState();
    const [changeTeacherColorFlag, setChangeTeacherColorFlag] = useState(false);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    useEffect(() => {
        let localCurrentSession =
            session.configuration.right_panel_settings.filter(
                (panel) => panel._id === panelID
            );
        localCurrentSession = localCurrentSession[0];
        const index = session.configuration.right_panel_settings.findIndex(
            (panel) => panel._id === localCurrentSession._id
        );

        setPanelIndex(index);
        setCurrentSessionData(localCurrentSession);
        setAccessAll(
            localCurrentSession.lesson_settings.whiteboard.student_access
                .length === 0
        );
        setNonAccessedStudents([
            ...localCurrentSession.lesson_settings.whiteboard.student_access,
        ]);
        // eslint-disable-next-line
    }, [panelID, session.configuration.right_panel_settings]);

    useEffect(() => {
        setActiveIndex([...activeIndex, studentAccess]);
        // eslint-disable-next-line
    }, []);

    const handleSession = async (sessionData) => {
        let sessionCopy = session;
        sessionCopy.configuration.right_panel_settings[panelIndex] =
            sessionData;
        await axios
            .put("/api/v1/sessions", sessionCopy)
            .then((response) => {
                if (!response.data.success) {
                    setMsg(
                        _.get("response.data.title", "Something went wrong.")
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const handleSettingType = (value) => {
        setSettingType(value);
    };

    const handleColorType = (value) => {
        setColorType(value);
    };

    const handleColor = (value) => {
        if (colorType === "individual") {
            if (studentColorId) {
                let data = {
                    id: studentColorId,
                    color: value,
                };
                setIndividualBgColor([data, ...individualBgColor]);
                socket.emit("studentColor", {
                    action: "studentColor",
                    payload: {
                        userIds: userIds,
                        data: data,
                    },
                });
            } else if (changeTeacherColorFlag) {
                setColor(value);
            }
        } else {
            userIds.map((user) => {
                let data = {
                    id: user,
                    color: value,
                };
                setIndividualBgColor([data, ...individualBgColor]);
                socket.emit("studentColor", {
                    action: "studentColor",
                    payload: {
                        userIds: userIds,
                        data: data,
                    },
                });
            });

            setColor(value);
        }
    };

    const handleAccess = () => {
        if (!accessAll) {
            setNonAccessedStudents([]);

            let updatedSession = currentSessionData;
            updatedSession.lesson_settings.whiteboard.student_access = [];
            handleSession(updatedSession);

            socket.emit("message", {
                action: "studentConfigChange",
                payload: {
                    userIds,
                },
            });
        }
        setAccessAll(!accessAll);
    };

    const colorArray = [
        "#333232",
        "#88ADDB",
        "#D94E43",
        "#4EA59D",
        "#FCB648",
        "#6A4636",
        "#7A559F",
        "#2F577E",
        "#F0B094",
    ];
    const random = Math.floor(Math.random() * colorArray.length);

    const handleRandomColor = () => {
        var color = colorArray[random];
        setColor(color);
    };

    const handleUpArrow = () => {
        newPosition({
            left: left,
            up: up - 1,
        });
        if (userIds) {
            socket.emit("position", {
                action: "position",
                payload: {
                    userIds: userIds,
                    key: key,
                    data: {
                        left: left,
                        up: up - 1,
                    },
                },
            });
        }
        setUp(up - 1);
    };

    const handleDownArrow = () => {
        newPosition({
            left: left,
            up: up + 1,
        });
        if (userIds) {
            socket.emit("position", {
                action: "position",
                payload: {
                    userIds: userIds,
                    key: key,
                    data: {
                        left: left,
                        up: up + 1,
                    },
                },
            });
        }
        setUp(up + 1);
    };

    const handleLeftArrow = () => {
        newPosition({
            left: left - 1,
            up: up,
        });
        if (userIds) {
            socket.emit("position", {
                action: "position",
                payload: {
                    userIds: userIds,
                    key: key,
                    data: {
                        left: left - 1,
                        up: up,
                    },
                },
            });
        }
        setLeft(left - 1);
    };

    const handleRightArrow = () => {
        newPosition({
            left: left + 1,
            up: up,
        });
        // setPosition("Hellllloooo")
        if (userIds) {
            socket.emit("position", {
                action: "position",
                payload: {
                    userIds: userIds,
                    key: key,
                    data: {
                        left: left + 1,
                        up: up,
                    },
                },
            });
        }
        setLeft(left + 1);
    };

    const handleAccessStudent = async (obj, isStudentNotAccessed) => {
        if (!accessAll) {
            if (isStudentNotAccessed) {
                const filteredList = nonAccessedStudents.filter(
                    (student) => student !== obj.parentId
                );

                let updatedSession = currentSessionData;
                updatedSession.lesson_settings.whiteboard.student_access =
                    filteredList;
                handleSession(updatedSession);

                setNonAccessedStudents(filteredList);
            } else {
                let selectedID = parentUserIds.filter(
                    (user) => user === obj.parentId
                );
                selectedID = selectedID[0];

                let updatedSession = currentSessionData;
                updatedSession.lesson_settings.whiteboard.student_access = [
                    ...nonAccessedStudents,
                    selectedID,
                ];
                handleSession(updatedSession);

                setNonAccessedStudents((prevAccessedStudents) => {
                    return [...prevAccessedStudents, selectedID];
                });
            }

            socket.emit("message", {
                action: "studentConfigChange",
                payload: {
                    userIds: [obj.parentId],
                },
            });
        }
    };

    const handlefitToScreen = () => {
        if (userIds) {
            socket.emit("imageSize", {
                action: "imageSize",
                payload: {
                    userIds: userIds,
                    key: key,
                    data: !fitToScreen,
                },
            });
        }
        setFitToScreen(!fitToScreen);
    };

    const handleStudentColor = (obj) => {
        setChangeTeacherColorFlag(false);
        setStudentColorId(
            studentColorId === obj.parentId || colorType === "all"
                ? null
                : obj.parentId
        );
    };

    return (
        <>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <div className={styles.settingsWrapper}>
                <div
                    className={
                        settingType === "image"
                            ? styles.fullWidth
                            : styles.settingLeft
                    }
                >
                    <div className={styles.settingsType}>
                        <p
                            onClick={() => {
                                handleSettingType("access");
                            }}
                            className={
                                settingType === "access"
                                    ? styles.activeType
                                    : styles.inactive
                            }
                        >
                            Access
                        </p>
                        <p
                            onClick={() => {
                                handleSettingType("color");
                            }}
                            className={
                                settingType === "color"
                                    ? styles.activeType
                                    : styles.inactive
                            }
                        >
                            Pen Color
                        </p>
                        <p
                            onClick={() => {
                                handleSettingType("image");
                            }}
                            className={
                                settingType === "image"
                                    ? styles.activeType
                                    : styles.inactive
                            }
                        >
                            Image
                        </p>
                    </div>

                    {settingType === "access" && (
                        <div className={styles.accessBlock}>
                            <div className={styles.participant}>
                                <p
                                    style={{
                                        backgroundColor: "#fff",
                                    }}
                                >
                                    {teacherName}
                                </p>
                                {data.length > 0 &&
                                    studentsData.map((obj) => {
                                        const id = obj._id;

                                        let studna = data?.find(
                                            (value) => value._id === id
                                        );

                                        let isStudentNotAccessed = false;
                                        if (
                                            nonAccessedStudents.some(
                                                (student) =>
                                                    student === obj.parentId
                                            )
                                        ) {
                                            isStudentNotAccessed = true;
                                        }

                                        return (
                                            <p
                                                onClick={() => {
                                                    handleAccessStudent(
                                                        obj,
                                                        isStudentNotAccessed
                                                    );
                                                }}
                                                className={styles.studentAccess}
                                                style={{
                                                    backgroundColor:
                                                        isStudentNotAccessed
                                                            ? ""
                                                            : "#fff",
                                                }}
                                            >
                                                {studna?.studentpreferedname}
                                            </p>
                                        );
                                    })}
                            </div>
                        </div>
                    )}

                    {settingType === "color" && (
                        <div className={styles.accessBlock}>
                            <div
                                className={styles.participant}
                                style={{ width: "auto" }}
                            >
                                <p
                                    onClick={() => {
                                        setChangeTeacherColorFlag(
                                            colorType === "individual" &&
                                                !changeTeacherColorFlag
                                        );
                                        setStudentColorId(null);
                                    }}
                                    style={{
                                        backgroundColor: changeTeacherColorFlag
                                            ? "#fff"
                                            : "",
                                        width: "106px",
                                    }}
                                >
                                    {teacherName}
                                </p>

                                {data.length > 0 &&
                                    studentsData.map((obj) => {
                                        const id = obj._id;
                                        let studna = data?.find(
                                            (value) => value._id === id
                                        );

                                        return (
                                            <p
                                                onClick={() => {
                                                    handleStudentColor(obj);
                                                }}
                                                className={styles.studentAccess}
                                                style={{
                                                    backgroundColor:
                                                        studentColorId ===
                                                        obj.parentId
                                                            ? "#fff"
                                                            : "",
                                                    width: "106px",
                                                }}
                                            >
                                                {studna?.studentpreferedname}
                                            </p>
                                        );
                                    })}
                            </div>
                            <div>
                                <div
                                    className={styles.pencolor}
                                    style={{ backgroundColor: color }}
                                />

                                {studentsData.map((student) => {
                                    const id = student.parentId;
                                    let indiv = individualBgColor.find(
                                        (value) => value.id === id
                                    );

                                    return (
                                        <div
                                            className={styles.pencolor}
                                            style={{
                                                backgroundColor:
                                                    colorType === "individual"
                                                        ? indiv?.color
                                                        : color,
                                            }}
                                        ></div>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    {settingType === "image" && selectedMedia[0]?.location && (
                        <div className={styles.imageSetting}>
                            <div
                                className={styles.imageWrapper}
                                id="imageWrapper"
                                style={{ overflow: "hidden" }}
                            >
                                {fitToScreen ? (
                                    <img
                                        src={selectedMedia[0].location}
                                        style={{
                                            position: "absolute",
                                            width: "100%",
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                        }}
                                        alt="plass Begin"
                                    />
                                ) : (
                                    <img
                                        src={selectedMedia[0].location}
                                        style={{
                                            position: "absolute",
                                            width: "160px",
                                            left: `${left}%`,
                                            top: up,
                                        }}
                                        alt="plass Begin"
                                    />
                                )}
                            </div>

                            <div className={styles.imgPosition}>
                                <div className={styles.arrowWrapper}>
                                    <img
                                        src={UpArrow}
                                        style={{
                                            position: "absolute",
                                            top: "0px",
                                            left: "50%",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            handleUpArrow();
                                        }}
                                        alt="plass Begin"
                                    />
                                    <img
                                        src={DownArrow}
                                        style={{
                                            position: "absolute",
                                            top: "100%",
                                            left: "50%",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            handleDownArrow();
                                        }}
                                        alt="plass Begin"
                                    />
                                    <img
                                        src={LeftArrow}
                                        style={{
                                            position: "absolute",
                                            left: "-22px",
                                            top: "50%",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            handleLeftArrow();
                                        }}
                                        alt="plass Begin"
                                    />
                                    <img
                                        src={RightArrow}
                                        style={{
                                            position: "absolute",
                                            right: "-45px",
                                            top: "50%",
                                            cursor: "pointer",
                                        }}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleRightArrow();
                                        }}
                                        alt="plass Begin"
                                    />
                                </div>

                                <p>Size</p>
                                <p
                                    className={styles.positionBtn}
                                    onClick={() => {
                                        handlefitToScreen();
                                    }}
                                >
                                    Fit to screen
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                {settingType === "access" && (
                    <div className={styles.settingRight}>
                        <div className={styles.grantAccess}>
                            <p>Grant access to all</p>
                            <div
                                className={styles.button}
                                onClick={(event) => {
                                    event.preventDefault();
                                    handleAccess();
                                }}
                            >
                                <div
                                    className={
                                        accessAll
                                            ? `${styles.circleLeft}`
                                            : `${styles.circleRight}`
                                    }
                                />
                            </div>
                            <p>
                                Click on specific names to grant or remove
                                access
                            </p>
                        </div>
                        <div className={styles.resetTeams}>
                            <p className={styles.resetBtn}>Reset teams</p>
                            <p>This will reset all whiteboards</p>
                        </div>
                    </div>
                )}

                {settingType === "color" && (
                    <div className={styles.settingRight}>
                        <div className={styles.grantColor}>
                            <div className={styles.grantColorType}>
                                <p
                                    onClick={() => {
                                        handleColorType("all");
                                        setChangeTeacherColorFlag(false);
                                        setStudentColorId(null);
                                    }}
                                    className={
                                        colorType === "all"
                                            ? styles.activeColorType
                                            : styles.inactive
                                    }
                                >
                                    All
                                </p>
                                <p
                                    onClick={() => {
                                        handleColorType("individual");
                                    }}
                                    className={
                                        colorType === "individual"
                                            ? styles.activeColorType
                                            : styles.inactive
                                    }
                                >
                                    Individual
                                </p>
                            </div>
                            <div className={styles.colorGrid}>
                                <div
                                    className={styles.pencolorRight}
                                    onClick={() => {
                                        handleColor("#333232");
                                    }}
                                    style={{ backgroundColor: "#333232" }}
                                ></div>
                                <div
                                    className={styles.pencolorRight}
                                    onClick={() => {
                                        handleColor("#88ADDB");
                                    }}
                                    style={{ backgroundColor: "#88ADDB" }}
                                ></div>
                                <div
                                    className={styles.pencolorRight}
                                    onClick={() => {
                                        handleColor("#D94E43");
                                    }}
                                    style={{ backgroundColor: "#D94E43" }}
                                ></div>
                            </div>
                            <div className={styles.colorGrid}>
                                <div
                                    className={styles.pencolorRight}
                                    onClick={() => {
                                        handleColor("#4EA59D");
                                    }}
                                    style={{ backgroundColor: "#4EA59D" }}
                                ></div>
                                <div
                                    className={styles.pencolorRight}
                                    onClick={() => {
                                        handleColor("#FCB648");
                                    }}
                                    style={{ backgroundColor: "#FCB648" }}
                                ></div>
                                <div
                                    className={styles.pencolorRight}
                                    onClick={() => {
                                        handleColor("#6A4636");
                                    }}
                                    style={{ backgroundColor: "#6A4636" }}
                                ></div>
                            </div>
                            <div className={styles.colorGrid}>
                                <div
                                    className={styles.pencolorRight}
                                    onClick={() => {
                                        handleColor("#7A559F");
                                    }}
                                    style={{ backgroundColor: "#7A559F" }}
                                ></div>
                                <div
                                    className={styles.pencolorRight}
                                    onClick={() => {
                                        handleColor("#2F577E");
                                    }}
                                    style={{ backgroundColor: "#2F577E" }}
                                ></div>
                                <div
                                    className={styles.pencolorRight}
                                    onClick={() => {
                                        handleColor("#F0B094");
                                    }}
                                    style={{ backgroundColor: "#F0B094" }}
                                ></div>
                            </div>
                            <p
                                onClick={handleRandomColor}
                                className={styles.randomBtn}
                            >
                                Random
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default WhiteboardSetting;
