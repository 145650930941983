import React from "react";
import { Link } from "react-router-dom";
import Header from "../../Header";
import styles from "./index.module.scss";
import goBack from "../../../assets/images/icon/goBack.svg";

export default function ErrorPage() {
    return (
        <div className={styles.errorPageContainer}>
            <Header showDashboard={true} />
            <div className={styles.paymentCancel}>
                <p className={styles.oops}>Oops, something went wrong.</p>
                <p>You can start another session.</p>

                <Link
                    to={{
                        pathname: "/accountSettings",
                        state: { tab: "paymentDet" },
                    }}
                >
                    <img
                        src={goBack}
                        style={{
                            cursor: "pointer",
                        }}
                        alt="Go Back"
                    />
                </Link>
            </div>
            {/* <div className="container h-80" style={{marginTop: "8em", marginLeft: "30%"}}>
                <div className="my-auto align-items-center">
                    <p className="ml-4 mb-1">You can start another session.</p>
                    <p
                        className="ml-4 mt-1"
                        style={{ color: "#88ADDB", cursor: "pointer" }}
                        onClick={() =>
                            window.location.replace("/accountsettings")
                        }
                    >
                        Click here
                    </p>
                </div>
            </div> */}
        </div>
    );
}
