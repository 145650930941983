import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Snackbar from "../../../../../../../components/Snackbar";
import Whiteboard from "./../../WhiteboardByNirajan";
import styles from "./Layout2.module.css";

export default ({ currentPanel, session, studentView }) => {
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [initialState, setInitaialState] = useState(false);
    const [teacherView, setTeacherView] = useState("");

    useEffect(() => {
        setTeacherView(
            currentPanel?.lesson_settings?.whiteboard?.teacher?.view
        );
        const getSelectedMedia = async () => {
            const selectedMediaArray =
                currentPanel.lesson_settings?.whiteboard?.image_ids;

            if (selectedMediaArray.length) {
                let galleryData = {
                    selectedMediaArray: selectedMediaArray,
                    userId: session.teacher_id,
                };
                await axios
                    .post(`/api/v1/user/gallery/selected`, galleryData)
                    .then((data) => {
                        if (data.data.success) {
                            const resultMedia = data.data.data.newArray;

                            setSelectedMedia(resultMedia);
                        }
                    })
                    .catch((error) => {
                        setMsg(
                            _.get(
                                error,
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                        return Promise.reject(error);
                    });
            }
            setInitaialState(true);
        };

        getSelectedMedia();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <div className={styles.assets}>
                {initialState && (
                    <div className={styles.whiteboardContainer}>
                        <div className={styles.whiteboard}>
                            <div style={{ width: "90rem", height: "645px" }}>
                                <Whiteboard
                                    selectedMedia={selectedMedia}
                                    position={
                                        currentPanel.lesson_settings.whiteboard
                                            .position
                                    }
                                    studentAccess={
                                        currentPanel.lesson_settings.whiteboard
                                            .student_access
                                    }
                                    control={true}
                                    requiredWB={true}
                                    session={session}
                                    panelID={currentPanel._id}
                                    teacherView={teacherView}
                                    orientation="left"
                                    studentView={studentView}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
