import React from 'react'
import styles from "./layout.module.css";

export default function Layout2() {
    return (
        <>
            {/* <div className={styles.videoContainer} style={{ float: "right", width: "150px", marginTop: "4%" }}>
                <h2 className={styles.containerText}>T</h2>
            </div> */}

            <div className="row" style={{
                marginLeft: "4%",
                marginTop: "30%"
            }}>
                <div className={styles.videoContainer} style={{backgroundColor: "#E9F3FB"}}>
                    <h2 className={styles.containerText}>T</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
            </div>
        </ >
    )
}
