import React, { useState, useEffect } from "react";
import styles from "./studentProfile.module.css";
import {
    CountryDropdown,
    RegionDropdown,
    CountryRegionData,
} from "react-country-region-selector";
import confirm from "../../../../assets/images/icon/accountSettings/confirm.svg";
import Calendar from "../../../Calendar";
import Axios from "axios";
import Snackbar from "../../../../components/Snackbar";
import _ from "lodash";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function StudentProfile({ changeFlag }) {
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackBarStatus, setSnackBarStatus] = useState("error");
    const [userDetails, setUserDetails] = useState({
        name: "",
        preferedName: "",
        birthDate: "",
        contact: "",
        country: "",
        state: "",
        student: {
            studentName: "",
            studentPreferedName: "",
            studentBirthDate: "",
        },
    });
    const [state, setState] = useState("");
    const lookup = require("country-code-lookup");
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    useEffect(() => {
        getUserDetails();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (changeFlag == true) {
            updateUserDetails();
        }
        // eslint-disable-next-line
    }, [changeFlag]);

    const getUserDetails = () => {
        Axios.get("/api/v1/user")
            .then((response) => {
                if (response.status == 200) {
                    setUserDetails({
                        name: response?.data?.user?.name,
                        preferedName: response?.data?.user?.preferedName,
                        birthDate: response?.data?.user?.birthDate,
                        contact: response?.data?.user?.contact,
                        country: response?.data?.user?.country,
                        state: response?.data?.user?.state,
                        student: {
                            studentName:
                                response?.data?.user?.students[0]?.studentname,
                            studentPreferedName:
                                response?.data?.user?.students[0]
                                    ?.studentpreferedname,
                            studentBirthDate:
                                response?.data?.user?.students[0]
                                    ?.studentbirthdate,
                        },
                    });
                }
            })
            .catch((error) => {});
    };

    const updateUserDetails = () => {
        Axios.post("api/v1/user/save-student-personal-details", userDetails)
            .then((response) => {
                setMsg(response.data.message);
                setSnackBarStatus("information");
                setShowSnackbar(true);
                setTimeout(() => {
                    setMsg(null);
                }, 3000);
                getUserDetails();
                setUnsavedChanges(false);
                localStorage.setItem("unSaveChanges", false);
            })
            .catch((error) => {
                console.log(error);
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const changesPending = () => {
        setUnsavedChanges(true);
        localStorage.setItem("unSaveChanges", true);
    };

    const updateParentValues = (event) => {
        setUserDetails({
            ...userDetails,
            [event.target.name]: event.target.value,
        });
    };

    const updateStudentValues = (e) => {
        setUserDetails({
            ...userDetails,
            student: {
                ...userDetails.student,
                [e.target.name]: e.target.value,
            },
        });
        changesPending();
    };

    const setcountry = (country) => {
        setUserDetails({
            ...userDetails,
            country: country,
        });
        changesPending();
    };

    const setStateValue = (state) => {
        setUserDetails({
            ...userDetails,
            state: state,
        });
        changesPending();
    };

    const setBirthDate = (date, type) => {
        if (type == "student") {
            setUserDetails({
                ...userDetails,
                student: {
                    ...userDetails.student,
                    studentBirthDate: date,
                },
            });
        } else if (type == "parent") {
            setUserDetails({
                ...userDetails,
                birthDate: date,
            });
        }
    };

    const handleOnFocus = (e) => {
        setMsg("Make sure it matches the information on your government ID");
        setSnackBarStatus("information");
        setShowSnackbar(true);
        setTimeout(function () {
            setMsg(null);
        }, 3000);
    };

    return (
        <React.Fragment>
            {msg && (
                <Snackbar
                    status={snackBarStatus}
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <div className={styles.container}>
                <div className={`student-profile ${styles.studentScroll}`}>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="personal-detail">
                                <h3>Parent name</h3>
                                <div className="personal-detail-inner">
                                    <div className="input-item">
                                        <input
                                            className={`${styles.inputBox} ${styles.middleInput}`}
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={userDetails?.name}
                                            onChange={(e) => {
                                                updateParentValues(e);
                                                changesPending();
                                            }}
                                            onClick={() => handleOnFocus()}
                                        />
                                    </div>
                                    <div className="input-item">
                                        <input
                                            className={`${styles.inputBox} ${styles.middleInput}`}
                                            type="text"
                                            id="preferedName"
                                            name="preferedName"
                                            value={userDetails?.preferedName}
                                            onChange={(e) => {
                                                updateParentValues(e);
                                                changesPending();
                                            }}
                                            // onKeyPress={handleKeyPress}
                                        />
                                    </div>
                                    <div className="input-item">
                                        <input
                                            max={moment
                                                .utc(new Date())
                                                .format("YYYY-MM-DD")}
                                            id="parentBirthDate"
                                            name="parentBirthDate"
                                            type="date"
                                            className={`${styles.inputBox} ${styles.middleInput}`}
                                            value={moment
                                                .utc(userDetails?.birthDate)
                                                .format("YYYY-MM-DD")}
                                            onChange={(e) => {
                                                setBirthDate(
                                                    e.target.value,
                                                    "parent"
                                                );
                                                changesPending();
                                            }}
                                            style={{
                                                width: "100%",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* *****Student Detail *****/}

                        <div className="col-12 col-md-6">
                            <div className="personal-detail">
                                <h3>Student name</h3>
                                <div className="personal-detail-inner">
                                    <div className="input-item">
                                        <input
                                            className={`${styles.inputBox} ${styles.middleInput}`}
                                            type="text"
                                            id="studentName"
                                            name="studentName"
                                            value={
                                                userDetails?.student &&
                                                userDetails?.student
                                                    ?.studentName
                                            }
                                            onChange={updateStudentValues}
                                            onClick={() => handleOnFocus()}
                                        />
                                    </div>
                                    <div className="input-item">
                                        <input
                                            className={`${styles.inputBox} ${styles.middleInput}`}
                                            type="text"
                                            id="studentPreferedName"
                                            name="studentPreferedName"
                                            value={
                                                userDetails?.student &&
                                                userDetails?.student
                                                    ?.studentPreferedName
                                            }
                                            onChange={updateStudentValues}
                                            // value={values.name}
                                            // onKeyPress={handleKeyPress}
                                        />
                                    </div>
                                    <div className="input-item">
                                        <div>
                                            <input
                                                max={moment
                                                    .utc(new Date())
                                                    .format("YYYY-MM-DD")}
                                                id="studentBirthDate"
                                                name="studentBirthDate"
                                                type="date"
                                                className={`${styles.inputBox} ${styles.middleInput}`}
                                                value={moment
                                                    .utc(
                                                        userDetails?.student
                                                            ?.studentBirthDate
                                                    )
                                                    .format("YYYY-MM-DD")}
                                                onChange={(e) => {
                                                    setBirthDate(
                                                        e.target.value,
                                                        "student"
                                                    );
                                                    changesPending();
                                                }}
                                                style={{
                                                    width: "100%",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="personal-detail">
                                <h3>Emergency contact</h3>
                                <div className="personal-detail-inner">
                                    <div className="input-item">
                                        <input
                                            required
                                            className={`${styles.inputBox} ${styles.middleInput}`}
                                            type="text"
                                            id="contact"
                                            name="contact"
                                            value={userDetails?.contact}
                                            onChange={(e) => {
                                                updateParentValues(e);
                                                changesPending();
                                            }}
                                            // onKeyPress={handleKeyPress}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="personal-detail">
                                <h3>Location</h3>
                                <div className="personal-detail-inner">
                                    <div className="input-item">
                                        <div
                                            style={{
                                                position: "relative",
                                            }}
                                        >
                                            <CountryDropdown
                                                required
                                                id="country"
                                                name="country"
                                                valueType="full"
                                                value={userDetails?.country}
                                                defaultOptionLabel="Country"
                                                onChange={setcountry}
                                                classes={`${styles.countryBox} ${styles.inputBox} ${styles.middleInput} ${styles.arrows}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-item">
                                        <div
                                            style={{
                                                position: "relative",
                                            }}
                                        >
                                            <RegionDropdown
                                                countryValueType="full"
                                                valueType="full"
                                                defaultOptionLabel="State"
                                                blankOptionLabel="State"
                                                country={userDetails?.country}
                                                value={userDetails?.state}
                                                onChange={(val) => {
                                                    setStateValue(val);
                                                }}
                                                classes={`${styles.countryBox} ${styles.inputBox} ${styles.middleInput} ${styles.arrows}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <div className={styles.confirmBlock}>
                                    <img
                                        src={confirm}
                                        width="184px"
                                        height="62px"
                                        style={{ cursor: "pointer" }}
                                        onClick={updateUserDetails}
                                    />
                                    <p
                                        className={styles.clear}
                                        onClick={() =>
                                            setUserDetails({
                                                name: "",
                                                preferedName: "",
                                                birthDate: "",
                                                contact: "",
                                                country: "",
                                                state: "",
                                                student: {
                                                    studentName: "",
                                                    studentPreferedName: "",
                                                    studentBirthDate: "",
                                                },
                                            })
                                        }
                                    >
                                        Clear
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
