import { faGreaterThan, faLessThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Participant from "../../../../../../components/Participant";
import Snackbar from "../../../../../../components/Snackbar";
import StudentSpotlight from "../../../../../../components/StudentSpotlight";
import TwilioParticipant from "../../../../../../components/TwilioParticipant";
import Whiteboard from "../../../../../../components/WhiteboardByNirajan";
import styles from "./Layout3.module.css";

export default ({
    stickerInfo,
    drawData,
    socket,
    selectedItem,
    updateSelectedItem,
    myMarkerColor,
    streams,
    role,
    endUserSession,
    muteUser,
    unMuteUser,
    publishAudio,
    muteAllUsers,
    unMuteAllUsers,
    mutedUsers,
    endEverySession,
    participants,
    room,
    key,
    currentPanel,
    session,
}) => {
    var Teacher = null;
    var Participants = [];
    const [modalFlag, setModalFlag] = useState(0);

    const [selectedMedia, setSelectedMedia] = useState([]);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [initialState, setInitaialState] = useState(false);
    const [teacherDrawData, setTeacherDrawData] = useState([]);
    const [studentsData, setStudentsData] = useState([]);
    const [showFeed, setShowFeed] = useState(false);
    const [selectedStudentToFocus, setSelectedStudentToFocus] = useState(-1);

    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;

    const userIds = participants.map((participant) => {
        var identityObj = JSON.parse(participant.identity);
        return identityObj._id;
    });

    useEffect(() => {
        const getUserDetails = async () => {
            await axios
                .post(`/api/v1/parents`, {
                    data: userIds,
                })
                .then((studentsResult) => {
                    const users = studentsResult.data.data.users;
                    let filteredUsers = [];
                    users.map((user) => {
                        const tempData = {};
                        tempData.name = user.students[0].studentpreferedname;
                        tempData._id = user.students[0]._id;
                        tempData.parentId = user._id;

                        filteredUsers.push(tempData);
                        return 0;
                    });
                    setStudentsData(filteredUsers);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        if (role === "teacher") getUserDetails();
        // eslint-disable-next-line
    }, [participants.length]);

    useEffect(() => {
        const getSelectedMedia = async () => {
            const selectedMediaArray =
                currentPanel.lesson_settings?.whiteboard?.image_ids;

            if (selectedMediaArray.length) {
                let galleryData = {
                    selectedMediaArray: selectedMediaArray,
                    userId: session.teacher_id,
                };
                await axios
                    .post(`/api/v1/user/gallery/selected`, galleryData)
                    .then((data) => {
                        if (data.data.success) {
                            const resultMedia = data.data.data.newArray;

                            setSelectedMedia(resultMedia);
                        }
                    })
                    .catch((error) => {
                        setMsg(
                            _.get(
                                error,
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                        return Promise.reject(error);
                    });
            }
        };
        const getDrawData = async () => {
            let teacherData = [];

            drawData.forEach((obj) => {
                teacherData.push(obj.draw_data);
            });

            setTeacherDrawData(teacherData);
            setInitaialState(true);
        };
        getSelectedMedia();
        getDrawData();
        // eslint-disable-next-line
    }, [drawData]);

    if (currentUserRole === "teacher") {
        Teacher = (
            <TwilioParticipant
                key={room.localParticipant.sid}
                participant={room.localParticipant}
                role={"teacher"}
                focusStudent={() => {}}
                selectedStudentToFocus={selectedStudentToFocus}
            />
        );
        Participants.push(Teacher);

        let studentParticipants = participants.map((participant, index) => {
            var identityObj = JSON.parse(participant.identity);
            const studentName = studentsData.find(
                (student) => student.parentId === identityObj._id
            );

            return (
                <TwilioParticipant
                    isMutedUser={mutedUsers.indexOf(identityObj._id) !== -1}
                    key={participant.sid}
                    role={"student"}
                    participant={participant}
                    publishAudio={publishAudio}
                    muteUser={() => muteUser(identityObj._id)}
                    unMuteUser={() => unMuteUser(identityObj._id)}
                    endUserSession={() => endUserSession(identityObj._id)}
                    screen="teacher"
                    studentName={studentName?.name}
                    focusStudent={() => {
                        setSelectedStudentToFocus(index);
                    }}
                    selectedStudentToFocus={selectedStudentToFocus}
                />
            );
        });
        Participants = [...Participants, ...studentParticipants];
    }

    let noOfParticipants = Participants.length;
    let participantBoxes = [];
    let boxWidth = "10vw";
    //(rowRef.current.offsetWidth/noOfParticipants) - 30;
    for (let i = 0; i < noOfParticipants; i++) {
        participantBoxes.push(
            <div className={styles.block} style={{ width: boxWidth }}>
                <Participant>{Participants[i]}</Participant>
            </div>
        );
    }

    const decreaseModalFlag = () => {
        if (modalFlag !== 0) {
            setModalFlag(modalFlag - 1);
        }
    };

    const increaseModalFlag = () => {
        if (modalFlag < participantBoxes.length - 3) {
            setModalFlag(modalFlag + 1);
        }
    };

    return (
        <>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <div className={styles.assets}>
                {initialState && (
                    <div className={styles.whiteboardContainer}>
                        <div className={styles.whiteboard}>
                            <div style={{ width: "1239px", height: "645px" }}>
                                <Whiteboard
                                    myWhiteboard
                                    drawData={teacherDrawData}
                                    socket={socket}
                                    // streams={streams}
                                    // role={role}
                                    participants={participants}
                                    selectedMedia={selectedMedia}
                                    // myMarkerColor={myMarkerColor}
                                    key={key}
                                    position={
                                        currentPanel.lesson_settings.whiteboard
                                            .position
                                    }
                                    // fitToScreen={fitToScreen}
                                    // setFitToScreen={setFitToScreen}
                                    studentAccess={
                                        currentPanel.lesson_settings.whiteboard
                                            .student_access
                                    }
                                    control={true}
                                    requiredWB={true}
                                    session={session}
                                    panelID={currentPanel._id}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div
                    className={styles.showFeedButton}
                    onClick={() => {
                        setShowFeed((prevFlag) => {
                            return !prevFlag;
                        });
                    }}
                >
                    Video Feed
                </div>

                <div
                    className={`${styles.participantsList} ${
                        showFeed ? styles.smallRow : ""
                    }`}
                >
                    {noOfParticipants > 4 && (
                        <FontAwesomeIcon
                            style={{
                                color: "#83838b",
                                width: "30px",
                                position: "absolute",
                                zIndex: "8",
                                cursor: "pointer",
                                transform: "rotate(90deg)",
                                top: "0",
                            }}
                            icon={faLessThan}
                            onClick={decreaseModalFlag}
                        />
                    )}
                    <div
                        className={
                            noOfParticipants > 4
                                ? styles.moreParticipantBoxesCol
                                : styles.lessParticipantBoxesCol
                        }
                    >
                        {participantBoxes
                            .slice(modalFlag, modalFlag + 4)
                            .map((obj, index) => {
                                return obj;
                            })}
                    </div>
                    {noOfParticipants > 4 && (
                        <FontAwesomeIcon
                            style={{
                                color: "#83838b",
                                width: "30px",
                                position: "absolute",
                                zIndex: "8",
                                cursor: "pointer",
                                transform: "rotate(90deg)",
                                bottom: "0",
                            }}
                            icon={faGreaterThan}
                            onClick={increaseModalFlag}
                        />
                    )}
                </div>

                <StudentSpotlight
                    selectedStudentToFocus={selectedStudentToFocus}
                    Participants={Participants}
                    closeModal={() => {
                        setSelectedStudentToFocus(-1);
                    }}
                />
            </div>
        </>
    );
};
