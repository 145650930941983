import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Snackbar from "../../../../../../../components/Snackbar";
import AudioPlayer from "../../AudioPlayer/index";
import VideoPlayer from "../../VideoPlayer/index";
import styles from "./layout1.module.css";

export default ({ currentPanel, imageSrc }) => {
    const [isSelected, setIsSelected] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState();
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    useEffect(() => {
        const getSelectedMedia = async () => {
            let sessionMediaIds = [];
            sessionMediaIds = currentPanel.lesson_settings.assets.image_ids;
            let galleryData = {
                selectedMediaArray: sessionMediaIds,
                userId: "",
            };
            await axios
                .post(`/api/v1/user/gallery/selected`, galleryData)
                .then((data) => {
                    if (data.data.success) {
                        const resultMedia = data.data.data.newArray;
                        setSelectedMedia(resultMedia);
                        setIsSelected(
                            new Array(resultMedia.length).fill(false)
                        );
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        getSelectedMedia();
        // eslint-disable-next-line
    }, []);

    let carouselItems = [];
    carouselItems = selectedMedia.map((obj, index) => {
        const type = obj.type;
        if (type === "VIDEO") {
            const createVid = (bar) => {
                const vid = (
                    <VideoPlayer
                        source={obj.location}
                        isSelectedVid={bar}
                        top="50%"
                        isStudent={false}
                        key={index}
                    ></VideoPlayer>
                );
                return vid;
            };
            return createVid;
        } else if (type === "AUDIO") {
            const createAud = (bar) => {
                const aud = (
                    <AudioPlayer
                        source={obj.location}
                        isSelectedAud={bar}
                        top="50%"
                        isStudent={false}
                        key={index}
                    ></AudioPlayer>
                );
                return aud;
            };
            return createAud;
        } else if (type === "IMAGE") {
            const createImg = () => {
                return (
                    <img
                        className={`${styles.assets} ${styles.image}`}
                        src={obj.location}
                        alt=""
                    />
                );
            };
            return createImg;
        }
        return null;
    });

    const handleSelect = async (index) => {
        const newArray = new Array(isSelected.length).fill(false);
        newArray[index] = true;
        setIsSelected([...newArray]);
        if (selectedMedia[newArray.indexOf(true)]) {
            setSelectedIndex(index);
            imageSrc(carouselItems[index](true).props.src);
        }
    };

    let carouselItemsGrouped = [];
    for (let i = 0; i < selectedMedia.length; i += 3) {
        carouselItemsGrouped.push(
            <Carousel.Item>
                <div style={{ height: "9vh", width: "10vw" }}>
                    <Row className={styles.rowCarousel}>
                        <div
                            className={`${styles.block} ${
                                styles.blockCarouselItem
                            } ${isSelected[i] ? styles.selected : ""}`}
                            onClick={() => {
                                handleSelect(i);
                            }}
                        >
                            {carouselItems[i](false)}
                        </div>
                        {selectedMedia[i + 1] && (
                            <div
                                className={`${styles.block} ${
                                    styles.blockCarouselItem
                                } ${isSelected[i + 1] ? styles.selected : ""}`}
                                onClick={() => {
                                    handleSelect(i + 1);
                                }}
                            >
                                {carouselItems[i + 1](false)}
                            </div>
                        )}
                        {selectedMedia[i + 2] && (
                            <div
                                className={`${styles.block} ${
                                    styles.blockCarouselItem
                                } ${isSelected[i + 2] ? styles.selected : ""}`}
                                onClick={() => {
                                    handleSelect(i + 2);
                                }}
                            >
                                {carouselItems[i + 2](false)}
                            </div>
                        )}
                    </Row>
                </div>
            </Carousel.Item>
        );
    }

    return (
        <div className={styles.blockTeacher}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <Carousel
                indicators={false}
                className={styles.carousel}
                interval={null}
            >
                {carouselItemsGrouped}
            </Carousel>
            <div className={`${styles.block} ${styles.blockMedia}`}>
                <div className={styles.selectedImg}>
                    {carouselItems[selectedIndex]
                        ? carouselItems[selectedIndex](true)
                        : ""}
                </div>
            </div>
            <div
                className="row"
                style={{
                    justifyContent: "center",
                    marginTop: "30%",
                }}
            >
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S</h2>
                </div>
            </div>
        </div>
    );
};
