import React, { useState } from "react";
import Dropdown from "../Dropdown";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

export default ({
    value,
    onChange,
    width,
    isPublished,
    name,
    maxDate,
    isSuperAdmin,
}) => {
    const [showDropdown, setShowdropdown] = useState(false);
    const currentDate = new Date();
    if (isSuperAdmin) {
        currentDate.setDate(currentDate.getDate());
    } else {
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return (
        <div style={{ width: `${width}` }}>
            <Dropdown
                displayValue={value ? value.toDateString() : "Date"}
                show={showDropdown}
                handleToggleShow={() =>
                    setShowdropdown(!showDropdown && !isPublished)
                }
                setShowdropdown={setShowdropdown}
                isPublished={isPublished}
            >
                <Calendar
                    maxDate={maxDate || false}
                    name={name}
                    onChange={(date) => {
                        setShowdropdown(false);
                        onChange(date);
                    }}
                    value={value}
                    style={{ zIndex: "2", width: "400px" }}
                    // minDate={currentDate}
                    placeholderText="Date"
                />
            </Dropdown>
        </div>
    );
};
