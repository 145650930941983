import React from "react";
import _ from "lodash";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CarouselSmall from "../../../../components/Carousels/CarouselSmall";
import styles from "./index.module.scss";
import learnMore from "../../../../assets/images/NewDesign/learnMore.png";
import moment from "moment";

export default function TeacherIntro({ classDetails }) {
    const ratingStar = (rating) => {
        const ratingArr = [];

        for (let i = 0; i < rating; i++) {
            ratingArr.push(
                <FontAwesomeIcon
                    key={i}
                    icon={faStar}
                    className={styles.starIcon}
                />
            );
        }

        return ratingArr;
    };
    return (
        <div className={styles.container}>
            <div className={styles.carouselBlock}>
                <CarouselSmall
                    carouselImgs={classDetails?.teacher_id?.profilePictureIds}
                    autoPlay={true}
                />
            </div>
            <div className={styles.seondDiv}>
                <p className={styles.introText}>Facilitator Introduction</p>
                <h2>{`Meet ${classDetails?.teacher_id?.preferedName}`}</h2>
                <div className={styles.classRating}>
                    {ratingStar(
                        parseInt(_.get(classDetails, "teacherRate", 0))
                    )}
                </div>
                <p
                    className={styles.blueText}
                >{`${classDetails?.teacher_id?.state}, ${classDetails?.teacher_id?.country}`}</p>
                <p
                    className={styles.blueText}
                >{`Teaching on Plassroom+ since ${moment(
                    classDetails?.teacher_id?.createdAt
                )
                    .local()
                    .format("MM/YYYY")}`}</p>
                <p
                    className={styles.blueText}
                >{`${classDetails?.noOfClassesConducted} classes conducted`}</p>
                <p
                    className={styles.darkBlueTxt}
                >{`${classDetails?.teacher_id?.storeDescription}`}</p>
                <img
                    src={learnMore}
                    onClick={() =>
                        (window.location.href = `/teacher-details/${classDetails?.teacher_id?.username}`)
                    }
                    style={{ cursor: "pointer" }}
                />
            </div>
        </div>
    );
}
