import axios from "axios";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import Loading from "../../../assets/videos/Loading.mp4";
import Stripe from "../../../assets/images/stripe/stripe.png";
import Snackbar from "../../../components/Snackbar";
import Navbar from "../../../components/Navbar";
import styles from "./upgrade3.module.scss";
import { useHistory } from "react-router-dom";
import SubscriptionDetailsBox from "../../../components/AccountSettings/PaymentDetails/SubscriptionDetailsBox";
const Upgrade3 = () => {
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const [plassCoins, setPlassCoins] = useState(0);
    const [plans, setPlans] = useState([]);

    const dataD = {
        subscription: {
            paymentMethodType: ["card"],
            _id: "639b23e52da16821dcbcf739",
            subscriptionId: "sub_1MFHg3Bm0JevscBtkBqxZS1B",
            studentId: "62564d716faa005224e536c5",
            customerId: "cus_MvRMSAewffjfzK",
            subscriptionStatus: "active",
            subscriptionPlan: {
                id: "price_1MD5pRBm0JevscBtt4vmoA4a",
                object: "plan",
                active: true,
                aggregate_usage: null,
                amount: 100,
                amount_decimal: "100",
                billing_scheme: "per_unit",
                created: 1670589445,
                currency: "gbp",
                interval: "day",
                interval_count: 1,
                livemode: false,
                nickname: null,
                product: "prod_MoEwV6qi5ThJfi",
                tiers_mode: null,
                transform_usage: null,
                trial_period_days: null,
                usage_type: "licensed",
            },
            subscriptionStartDate: "2022-12-15T13:40:47.000Z",
            subscriptionEndDate: "2022-12-16T13:40:47.000Z",
            paymentIntentId: "pi_3MFHg4Bm0JevscBt1Q4IYzyY",
            paymentDate: "2022-12-15T13:40:53.399Z",
            invoiceId: "in_1MFHg3Bm0JevscBtoo2yJdU5",
            invoiceStatus: "paid",
            paymentMethod: "pm_1MBaTeBm0JevscBtpKFmtBQP",
            cardDetails: {
                brand: "visa",
                last: "4242",
                exp_month: 12,
                exp_year: 2023,
            },
            createdAt: "2022-12-15T13:40:53.400Z",
            updatedAt: "2022-12-15T13:40:53.400Z",
            __v: 0,
        },
        plan: {
            type: "Lite",
            _id: "639b23e52da16821dcbcf73c",
            user_id: "62564d716faa005224e536c5",
            customer_id: "cus_MvRMSAewffjfzK",
            invoiceId: "in_1MFHg3Bm0JevscBtoo2yJdU5",
            start_date: "2022-12-15T13:40:47.000Z",
            expire_date: "2023-03-15T13:40:47.000Z",
            total_mins: 60,
            remaining_mins: 0,
            subscriptionId: "sub_1MFHg3Bm0JevscBtkBqxZS1B",
            isActive: true,
            createdAt: "2022-12-15T13:40:53.404Z",
            updatedAt: "2022-12-16T04:50:48.036Z",
            __v: 0,
        },
    };

    useEffect(() => {
        if (localStorage.getItem("auth")) {
            if (
                JSON.parse(localStorage.getItem("auth")).user.role === "student"
            ) {
                getSubscriptionDetails();
                fetchPlanDetails();
            } else {
                history.replace(`/login`);
            }
        } else {
            history.replace(`/login`);
        }

        // eslint-disable-next-line
    }, []);

    const getSubscriptionDetails = async () => {
        setLoading(true);
        // setDetails(dataD);
        await axios
            .get("api/v1/subscription")
            .then((response) => {
                if (response?.data?.success) {
                    if (response?.data?.data) {
                        setDetails(response?.data?.data);
                        setLoading(false);
                    } else {
                        history.push("/choose-plan-and-subscription-plan");
                        setMsg(
                            _.get(
                                response?.data,
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    // FETCHING STUDENT PLANN DETAILS
    const fetchPlanDetails = async () => {
        await axios
            .get("/api/v1/user/plan")
            .then((response) => {
                if (response?.data?.success) {
                    setPlassCoins(response?.data?.data?.totalRemainingMinutes);
                    setPlans(response?.data?.data?.plans);
                } else {
                    setMsg(
                        _.get(
                            response,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    return (
        <>
            <Navbar />
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <div className={styles.container}>
                <div className={styles.upgradeContainer}>
                    <h1 className={styles.heading}>Congratulations</h1>
                    <SubscriptionDetailsBox
                        totalPlassCoins={plassCoins}
                        plans={plans}
                        details={details}
                    />
                    <div style={{ marginBottom: "30px" }}></div>
                    {/* <PlanPaymentDetailsBox details={details} /> */}
                    <div className="d-flex flex-row justify-content-between mt-5">
                        <h4 className={`mr-5 ${styles.paymentHeading}`}>
                            Payment details
                        </h4>
                        <img className={styles.stripSVG} src={Stripe} />
                    </div>
                    <div className="d-flex flex-row mb-4 justify-content-between">
                        <div
                            className="d-flex flex-row justify-content-between"
                            style={{ width: "100%" }}
                        >
                            <div className={styles.cardBox}>
                                <p
                                    className={`mr-3 ${styles.ltd} ${styles.oneRem}`}
                                >
                                    {details?.subscription?.cardDetails?.brand}{" "}
                                    Card ending ****
                                    {details?.subscription?.cardDetails?.last}
                                </p>
                                <a
                                    // href="/paymentdetails/editaccount"
                                    className={styles.edit}
                                >
                                    Edit
                                </a>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginBottom: "30px" }}></div>
                    <h6 className={styles.footertext}>
                        These details are available at any time in your
                        settings.
                    </h6>
                    <div className={styles.otherLink}>
                        <span onClick={() => history.push("/planFaqs")}>
                            See plan details and FAQs
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Upgrade3;
