import React, { useEffect, useState } from "react";
import styles from "./Layout3.module.css";
import axios from "axios";
import TwilioParticipant from "../../../../../../components/TwilioParticipant";
import Participant from "../../../../../../components/Participant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan, faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import Whiteboard from "../../../../../../components/WhiteboardByNirajan";
import Snackbar from "../../../../../../components/Snackbar";
import _ from "lodash";

export default ({
    participants,
    drawData,
    room,
    mutedUsers,
    publishAudio,
    socket,
    role,
    key,
    currentPanel,
    session,
}) => {
    var Teacher = null;
    var Participants = [];

    const [modalFlag, setModalFlag] = useState(1);

    const [selectedMedia, setSelectedMedia] = useState([]);
    const [initialState, setInitaialState] = useState(false);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [fitToScreen, setFitToScreen] = useState(
        currentPanel.lesson_settings.whiteboard.fitToScreen
    );
    const [teacherDrawData, setTeacherDrawData] = useState([]);

    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;

    useEffect(() => {
        const getSelectedMedia = async () => {
            const selectedMediaArray =
                currentPanel.lesson_settings?.whiteboard?.image_ids;

            if (selectedMediaArray.length) {
                let galleryData = {
                    selectedMediaArray: selectedMediaArray,
                    userId: session.teacher_id,
                };
                await axios
                    .post(`/api/v1/user/gallery/selected`, galleryData)
                    .then((data) => {
                        if (data.data.success) {
                            const resultMedia = data.data.data.newArray;

                            setSelectedMedia(resultMedia);
                        }
                    })
                    .catch((error) => {
                        setMsg(
                            _.get(
                                error,
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                        return Promise.reject(error);
                    });
            }
        };
        const getDrawData = async () => {
            let teacherData = [];

            drawData.forEach((obj) => {
                teacherData.push(obj.draw_data);
            });

            setTeacherDrawData(teacherData);
            setInitaialState(true);
        };
        getSelectedMedia();
        getDrawData();
        // eslint-disable-next-line
    }, [drawData]);

    if (currentUserRole === "student") {
        var teacherParticipant = participants.find((obj) => {
            var identityObj = JSON.parse(obj.identity);
            return identityObj.role === "teacher";
        });
        if (teacherParticipant) {
            Teacher = (
                <TwilioParticipant
                    role="teacher"
                    key={teacherParticipant.sid}
                    participant={teacherParticipant}
                />
            );
        }
        Participants.push(Teacher);

        let studentParticipants = [
            <TwilioParticipant
                role="student"
                key={room.localParticipant.sid}
                participant={room.localParticipant}
                publishAudio={publishAudio}
                screen="student"
            />,
        ].concat(
            participants
                .filter((obj) => {
                    var identityObj = JSON.parse(obj.identity);
                    return identityObj.role !== "teacher";
                })
                .map((participant) => (
                    <TwilioParticipant
                        role="student"
                        key={participant.sid}
                        participant={participant}
                        publishAudio={publishAudio}
                        screen="student"
                    />
                ))
        );
        Participants = [...Participants, ...studentParticipants];
    }

    const decreaseModalFlag = () => {
        if (modalFlag > 1) {
            setModalFlag(modalFlag - 1);
        }
    };

    const increaseModalFlag = () => {
        if (modalFlag < participantBoxes.length - 3) {
            setModalFlag(modalFlag + 1);
        }
    };

    let noOfParticipants = participants.length + 1;
    let participantBoxes = [];
    let boxWidth = "10vw";
    //(rowRef.current.offsetWidth/noOfParticipants) - 30;
    for (let i = 0; i < noOfParticipants; i++) {
        participantBoxes.push(
            <div className={styles.block} style={{ width: boxWidth }}>
                <Participant>{Participants[i]}</Participant>
            </div>
        );
    }

    return (
        <div className={styles.assets}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            {initialState && (
                <div className={styles.whiteboardContainer}>
                    <div className={styles.whiteboard}>
                        <div style={{ width: "1239px", height: "645px" }}>
                            <Whiteboard
                                myWhiteboard
                                drawData={teacherDrawData}
                                socket={socket}
                                // streams={streams}
                                // role={role}
                                participants={participants}
                                selectedMedia={selectedMedia}
                                // myMarkerColor={myMarkerColor}
                                key={key}
                                position={
                                    currentPanel.lesson_settings.whiteboard
                                        .position
                                }
                                fitToScreen={fitToScreen}
                                setFitToScreen={setFitToScreen}
                                studentAccess={
                                    currentPanel.lesson_settings.whiteboard
                                        .student_access
                                }
                                requiredWB={
                                    currentPanel.lesson_settings.whiteboard
                                        .teacher.shared_use
                                }
                                panelID={currentPanel._id}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className={styles.participantsList}>
                {noOfParticipants > 4 && (
                    <FontAwesomeIcon
                        style={{
                            color: "#83838b",
                            width: "30px",
                            position: "absolute",
                            zIndex: "8",
                            cursor: "pointer",
                            transform: "rotate(90deg)",
                            top: "0",
                        }}
                        icon={faLessThan}
                        onClick={decreaseModalFlag}
                        className={styles.studentsParticipantsList}
                    />
                )}
                <div
                    className={
                        noOfParticipants > 4
                            ? styles.moreParticipantBoxesCol
                            : styles.lessParticipantBoxesCol
                    }
                >
                    {participantBoxes[0]}

                    <span className={styles.studentsParticipantsList}>
                        {participantBoxes
                            .slice(modalFlag, modalFlag + 4)
                            .map((obj, index) => {
                                return obj;
                            })}
                    </span>
                </div>
                {noOfParticipants > 4 && (
                    <FontAwesomeIcon
                        style={{
                            color: "#83838b",
                            width: "30px",
                            position: "absolute",
                            zIndex: "8",
                            cursor: "pointer",
                            transform: "rotate(90deg)",
                            bottom: "0",
                        }}
                        icon={faGreaterThan}
                        onClick={increaseModalFlag}
                        className={styles.studentsParticipantsList}
                    />
                )}
            </div>
        </div>
    );
};
