import React, { useState } from "react";
import "../style.css";
import {
    learningObjectivesData,
    educationalPreferenceData,
    daysWithAmPm,
} from "../../../utils/constants";
import Form from "react-bootstrap/Form";

const SessionPreferences = () => {
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [selectedEducation, setSelectedEducation] = useState(false);
    const [timeAmPm, setTimeAmPM] = useState(daysWithAmPm);

    const handleTopics = (e, topic) => {
        if (e.target.checked) {
            setSelectedTopics([...selectedTopics, topic]);
        } else {
            setSelectedTopics(selectedTopics.filter((t) => t != topic));
        }
    };
    const handleEducation = (e, education) => {
        if (e.target.checked) {
            setSelectedEducation(education);
        } else {
            setSelectedEducation("");
        }
    };

    const handleDays = (object) => {
        if (object.selected) {
            const arr = timeAmPm.map((t) =>
                t.day === object.day
                    ? { ...object, selected: false, AM: false, PM: false }
                    : t
            );
            setTimeAmPM(arr);
        } else {
            const arr = timeAmPm.map((t) =>
                t.day === object.day
                    ? { ...object, selected: true, PM: true }
                    : t
            );
            setTimeAmPM(arr);
        }
    };
    const handleAmPm = (type, object) => {
        if (object.selected) {
            const arr = timeAmPm.map((t) =>
                t.day === object.day ? { ...object, [type]: !object[type] } : t
            );
            setTimeAmPM(arr);
        }
    };

    const handleSelectAll = () => {
        const arr = timeAmPm?.map((o) => ({
            ...o,
            selected: true,
            AM: true,
            PM: true,
        }));
        setTimeAmPM(arr);
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="personal-detail">
                    <h3>Educational level / approach</h3>
                    <div className="times-outer">
                        <Form>
                            {["checkbox"].map((type) => (
                                <div
                                    key={`inline-${type}`}
                                    className="checkbox-list"
                                >
                                    {educationalPreferenceData?.map((o, i) => {
                                        return (
                                            <Form.Check
                                                key={i}
                                                inline
                                                label={o}
                                                onChange={(e) =>
                                                    handleEducation(e, o)
                                                }
                                                checked={
                                                    selectedEducation === o
                                                }
                                                name={`group100`}
                                                type={type}
                                                id={`inline-${type}-${i}`}
                                            />
                                        );
                                    })}
                                </div>
                            ))}
                        </Form>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="personal-detail">
                    <h3>Topics of Interest</h3>
                    <div className="times-outer">
                        <Form>
                            {["checkbox"].map((type) => (
                                <div
                                    key={`inline-${type}`}
                                    className="checkbox-list"
                                >
                                    {learningObjectivesData?.map((o, i) => {
                                        const checked =
                                            !!selectedTopics.includes(o);
                                        return (
                                            <Form.Check
                                                key={i}
                                                inline
                                                onChange={(e) =>
                                                    handleTopics(e, o)
                                                }
                                                checked={checked}
                                                label={o}
                                                name={`group${i * 100}`}
                                                type={type}
                                                id={`inline-${type}-${i}`}
                                            />
                                        );
                                    })}
                                </div>
                            ))}
                        </Form>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="personal-detail">
                    <div className="d-flex align-items-center justify-content-between">
                        <h3>Preferred times</h3>
                        <span onClick={() => handleSelectAll()}>
                            Select all
                        </span>
                    </div>
                    <div className="times-outer">
                        <Form>
                            <div className="days">
                                {["checkbox"].map((type) => (
                                    <div
                                        key={`inline-${type}`}
                                        className="checkbox-list"
                                    >
                                        {timeAmPm?.map((o, i) => {
                                            return (
                                                <Form.Check
                                                    key={i}
                                                    inline
                                                    onChange={() =>
                                                        handleDays(o)
                                                    }
                                                    checked={o.selected}
                                                    label={o.day}
                                                    name={`group1`}
                                                    type={type}
                                                    id={`inline-${type}-${i}`}
                                                />
                                            );
                                        })}
                                    </div>
                                ))}
                            </div>

                            <div className="times-am">
                                <div className="checkbox-list">
                                    {timeAmPm?.map((o, i) => {
                                        return (
                                            <Form.Check
                                                key={i}
                                                inline
                                                onChange={(e) =>
                                                    handleAmPm("AM", o)
                                                }
                                                checked={o.AM}
                                                label="AM"
                                                name={`group2`}
                                                type="checkbox"
                                                id={`inline-${i}`}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="times-pm">
                                <div className="checkbox-list">
                                    {timeAmPm?.map((o, i) => {
                                        return (
                                            <Form.Check
                                                key={i}
                                                inline
                                                onChange={(e) =>
                                                    handleAmPm("PM", o)
                                                }
                                                checked={o.PM}
                                                label="PM"
                                                name={`group3`}
                                                type="checkbox"
                                                id={`inline-${i}`}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SessionPreferences;
