import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Setting from "../../assets/images/icon/Settings.svg";
import Store from "../../assets/images/icon/Store.svg";
import CarouselSmall from "../../components/Carousels/CarouselSmall";
import FooterNew from "../../components/FooterNew";
import Loading from "../../components/Loading/Loading";
import Navbar from "../../components/Navbar";
import Snackbar from "../../components/Snackbar";
import Badge from "../GuestUser/components/Badge";
import ParentReview from "../GuestUser/components/ParentReview";
import TeacherClassCard from "../GuestUser/components/TeacherClassCard";
import InstagramCarousel from "../GuestUser/InstagramCarousel";
import styles from "./index.module.scss";
import moreClass from "../../assets/images/NewDesign/moreClass.png";
import BreadCrumbs from "../../components/BreadCrumbs";
import TeacherCarousel from "../../components/Carousels/TeacherCarousel";
import PageLoader from "../../components/PageLoader/index.js";

const UrlText = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    return text.split(urlRegex).map((part, index) => {
        if (part.match(urlRegex)) {
            return (
                <a
                    href={part}
                    key={index}
                    target={"_blank"}
                    rel="noopener noreferrer"
                >
                    {part}
                </a>
            );
        }
        return part;
    });
};

const TeacherDetailsNew = () => {
    const location = useLocation();
    let history = useHistory();
    const [isDetailsFetched, setIsDetailsFetched] = useState(false);
    const [showClassesOnTop, setShowClassesOnTop] = useState(true);
    const [teacherDetails, setTeacherDetails] = useState();
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [ratingAndReviews, setRatingAndReviews] = useState([]);
    const [loading, setLoading] = useState(false);
    const teacherID = _.last(location.pathname.split("/"));

    const ratingStar = (rating) => {
        const ratingArr = [];

        for (let i = 0; i < rating; i++) {
            ratingArr.push(
                <FontAwesomeIcon
                    key={i}
                    icon={faStar}
                    className={styles.starIcon}
                />
            );
        }

        return ratingArr;
    };

    useEffect(() => {
        handleFetchProfileData();
        window.scroll(0, 0);
        // eslint-disable-next-line
    }, [teacherID]);

    const handleFetchProfileData = () => {
        const url = `api/v1/user/teacher/${teacherID}/profile`;
        setLoading(true);
        axios
            .get(url)
            .then((res) => {
                if (res.data.success) {
                    const teacherProfileData = _.defaultTo(
                        _.get(res, "data.data"),
                        {}
                    );
                    setTeacherDetails(teacherProfileData);
                    setRatingAndReviews(res?.data?.data?.ratingAndReviews);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setMsg(_.get(res, "data.message", "Something went wrong."));
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                        history.push("/home");
                    }, 3000);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                setMsg(
                    _.get(err, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                    history.push("/home");
                }, 3000);
            })
            .finally(() => {
                setIsDetailsFetched(true);
            });
    };

    return (
        <Fragment>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <div>
                <Navbar />
                {loading ? <PageLoader open={loading} /> : <></>}
                <div className={styles.teacher__container}>
                    {isDetailsFetched ? (
                        <Fragment>
                            <BreadCrumbs
                                links={[
                                    { to: "/home", label: "Home" },
                                    {
                                        to: "/teachers",
                                        label: "Facilitators",
                                    },
                                    {
                                        to: "/teacher-details/",
                                        label: `${_.defaultTo(
                                            _.get(
                                                teacherDetails,
                                                "preferedName"
                                            ),
                                            "-"
                                        )}`,
                                    },
                                ]}
                                class={styles.breadCrumb}
                            />
                            <div className={styles.teacher_details__container}>
                                <div className={styles.teacher_info__container}>
                                    <div
                                        className={
                                            styles.teacher_name__container
                                        }
                                    >
                                        <div className={styles.teacher_name}>
                                            {`Meet ${_.defaultTo(
                                                _.get(
                                                    teacherDetails,
                                                    "preferedName"
                                                ),
                                                "-"
                                            )}`}
                                        </div>
                                        <div className={styles.teacher_rating}>
                                            {ratingStar(
                                                _.defaultTo(
                                                    _.get(
                                                        teacherDetails,
                                                        "teacherRate"
                                                    ),
                                                    0
                                                )
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            color: "#88addb",
                                            color: "#88ADDB",
                                        }}
                                    >
                                        {teacherDetails?.state &&
                                            teacherDetails?.country &&
                                            `${teacherDetails?.state}, ${teacherDetails?.country}`}
                                    </div>
                                    <div
                                        className={
                                            styles.teacher_experience__container
                                        }
                                    >
                                        <div
                                            className={
                                                styles.teacher_experience_years__row
                                            }
                                            style={{ color: "#88ADDB" }}
                                        >
                                            Teaching on Plassroom+ since{" "}
                                            {_.defaultTo(
                                                _.get(teacherDetails, "since"),
                                                "-"
                                            )}
                                        </div>

                                        <div
                                            className={
                                                styles.teacher_experience_class__row
                                            }
                                            style={{ color: "#88ADDB" }}
                                        >
                                            {_.defaultTo(
                                                _.get(
                                                    teacherDetails,
                                                    "noClasses"
                                                ),
                                                "-"
                                            )}{" "}
                                            classes conducted
                                        </div>
                                    </div>

                                    <div
                                        className={
                                            styles.teacher_introduction__container
                                        }
                                    >
                                        {/* <div
                                            className={
                                                styles.introduction__header
                                            }
                                        >
                                            Introduction
                                        </div> */}

                                        <div
                                            className={
                                                styles.introduction__details
                                            }
                                            style={{ color: "#13385C" }}
                                        >
                                            {UrlText(
                                                _.defaultTo(
                                                    _.get(
                                                        teacherDetails,
                                                        "introduction"
                                                    ),
                                                    "-"
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={
                                        styles.teacher_photo_details__container
                                    }
                                >
                                    <div
                                        className={
                                            styles.teacher_photo__container
                                        }
                                    >
                                        <CarouselSmall
                                            carouselImgs={_.defaultTo(
                                                _.get(
                                                    teacherDetails,
                                                    "profilePictureIds"
                                                ),
                                                []
                                            )}
                                            controls={true}
                                        />
                                    </div>

                                    {/* <div
                                        className={
                                            styles.teacher_criminal_record__container
                                        }
                                    >
                                        <div
                                            className={
                                                styles.teacher_criminal_record__heading
                                            }
                                        >
                                            Latest criminal check
                                        </div>

                                        <div
                                            className={
                                                styles.teacher_criminal_record__data
                                            }
                                        >
                                            {_.defaultTo(
                                                _.get(
                                                    teacherDetails,
                                                    "latestCriminalCheckDate"
                                                ),
                                                "-"
                                            )}{" "}
                                            verified
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                            <div
                                className={`${styles.teacher_data__container} ${
                                    !showClassesOnTop
                                        ? styles.show_classes_on_top
                                        : null
                                }`}
                            >
                                <div
                                    className={`${
                                        styles.teacher_classes__container
                                    } ${
                                        showClassesOnTop
                                            ? styles.class_on_top
                                            : null
                                    }`}
                                >
                                    <div
                                        className={
                                            styles.teacher_classes__heading
                                        }
                                    >
                                        {/* <div>Classes on Plassroom+</div>

                                        {showClassesOnTop ? (
                                            <div
                                                className={
                                                    styles.heading__switch
                                                }
                                                onClick={() =>
                                                    setShowClassesOnTop(false)
                                                }
                                            >
                                                See parent reviews
                                            </div>
                                        ) : null} */}
                                    </div>

                                    {ratingAndReviews.length ? (
                                        <ParentReview
                                            ratingAndReviews={ratingAndReviews}
                                        />
                                    ) : null}
                                    {/* 
                                    {ratingAndReviews.length ? (
                                        <Review
                                            ratingAndReviews={ratingAndReviews}
                                        />
                                    ) : null} */}

                                    {/* <LinearClassList
                                        classList={_.get(
                                            teacherDetails,
                                            "sessions",
                                            []
                                        )}
                                        from="teacher"
                                    /> */}
                                    {teacherDetails?.sessions?.length ? (
                                        <>
                                            <h2
                                                style={{
                                                    color: "#13385C",
                                                    textAlign: "center",
                                                    margin: "3rem 0",
                                                }}
                                            >{`${_.get(
                                                teacherDetails,
                                                "preferedName"
                                            )}'s Classes`}</h2>
                                            <TeacherClassCard
                                                teacherPage={true}
                                                classList={_.get(
                                                    teacherDetails,
                                                    "sessions",
                                                    []
                                                )}
                                            />
                                            <img
                                                src={moreClass}
                                                onClick={() =>
                                                    history.push("/classes")
                                                }
                                                style={{
                                                    margin: "4rem 0px",
                                                    cursor: "pointer",
                                                    width: "20%",
                                                }}
                                            />
                                        </>
                                    ) : null}
                                    {/* <SessionCarousel
                                        classListDetails={_.get(
                                            teacherDetails,
                                            "sessions",
                                            []
                                        ).splice(0, 4)}
                                        title={`${_.get(
                                            teacherDetails,
                                            "preferedName"
                                        )}'s sessions`}
                                    /> */}

                                    <TeacherCarousel title="Some of our other amazing facilitators" />
                                </div>

                                {/* <div
                                    className={`${styles.parent_reviews__container
                                        } ${!showClassesOnTop
                                            ? styles.reviews_on_top
                                            : null
                                        }`}
                                >
                                    <div
                                        className={
                                            styles.parent_reviews__heading
                                        }
                                    >
                                        <div>Parent Reviews</div>

                                        {!showClassesOnTop ? (
                                            <div
                                                className={
                                                    styles.heading__switch
                                                }
                                                onClick={() =>
                                                    setShowClassesOnTop(true)
                                                }
                                            >
                                                See available classes
                                            </div>
                                        ) : null}
                                    </div>

                                    <ParentReviews
                                        ratings={_.get(
                                            teacherDetails,
                                            "ratingAndReviews",
                                            []
                                        )}
                                    />
                                </div> */}
                            </div>
                        </Fragment>
                    ) : (
                        <Loading />
                    )}
                </div>

                <InstagramCarousel />
                <Badge
                // setMsg={setMsg}
                // setShowSnackbar={setShowSnackbar}
                // setSnackBarStatus={setSnackBarStatus}
                />
                <FooterNew />
            </div>
        </Fragment>
    );
};

export default TeacherDetailsNew;
