import { faGreaterThan, faLessThan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import styles from "./layout.module.css";
import Carousel from "react-bootstrap/Carousel";

export default function Layout3() {
    const [modalFlag, setModalFlag] = useState(0);
    let participantBoxes = [];
    //(rowRef.current.offsetWidth/noOfParticipants) - 30;
    for (let i = 0; i < 9; i++) {
        if (i === 0) {
            participantBoxes.push(
                <div className={styles.videoContainer} style={{backgroundColor:"#E9F3FB"}}>
                    <h2 className={styles.containerText}>T</h2>
                </div>
            );
        }
        else {
            participantBoxes.push(
                <div className={styles.videoContainer}>
                    <h2 className={styles.containerText}>S{i}</h2>
                </div>
            );
        }
    }
    const decreaseModalFlag = () => {
        if (modalFlag !== 0) {
            setModalFlag(modalFlag - 1);
        }
    };

    const increaseModalFlag = () => {
        if (modalFlag < 8 - 3) {
            setModalFlag(modalFlag + 1);
        }
    };

    return (
        <>
            {/* <div className={styles.videoContainer} style={{ float: "right", width: "150px", marginTop: "4%" }}>
                <h2 className={styles.containerText}>T</h2>
            </div> */}

            <Carousel
                indicators={false}
                className={styles.carousel}
                interval={null}
            >
                {/* {carouselItemsGrouped} */}
            </Carousel>
            <div className={styles.participantsList}>
                <FontAwesomeIcon
                    style={{
                        color: "#83838b",
                        width: "30px",
                        position: "absolute",
                        zIndex: "0",
                        cursor: "pointer",
                        transform: "rotate(90deg)",
                        top: "0",
                    }}
                    icon={faLessThan}
                    onClick={decreaseModalFlag}
                />
                <div
                    className={
                        styles.moreParticipantBoxesCol
                    }
                >
                    {participantBoxes
                        .slice(modalFlag, modalFlag + 4)
                        .map((obj, index) => {
                            return obj;
                        })}
                </div>
                <FontAwesomeIcon
                    style={{
                        color: "#83838b",
                        width: "30px",
                        position: "absolute",
                        zIndex: "8",
                        cursor: "pointer",
                        transform: "rotate(90deg)",
                        bottom: "0",
                    }}
                    icon={faGreaterThan}
                    onClick={increaseModalFlag}
                />
            </div>

        </ >
    )
}
