import React from "react";
import ReviewCarousel from "../../../../components/Carousels/ReviewCarousel";
import ReviewCarouselClass from "../../../../components/Carousels/ReviewCarouselClass";
import styles from "./index.module.scss";

export default function ParentReview({ ratingAndReviews, page }) {
    return (
        <div>
            {page === "home" ? (
                <div className={`parent-review-section ${styles.container}`}>
                    <div className="container">
                        <p className="main-title">Parent Reviews</p>
                        <ReviewCarousel ratingAndReviews={ratingAndReviews} />
                    </div>
                </div>
            ) : (
                <>
                    <ReviewCarouselClass ratingAndReviews={ratingAndReviews} />
                </>
            )}
        </div>
    );
}
