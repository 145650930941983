import axios from "axios";
import _ from "lodash";
import React, { useState } from "react";
import Logo from "../../../assets/images/LOGO (3).svg";
import BG1 from "../../../assets/images/Plan/lite_plan.png";
import BG2 from "../../../assets/images/Plan/plass_plan.png";
import Loading from "../../../assets/videos/Loading.mp4";
import Snackbar from "../../../components/Snackbar";
import Navbar from "../../../components/Navbar";
import styles from "./index.module.scss";
import CommonButton from "../../../components/Button/CommonButton";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import PageLoader from "../../../components/PageLoader";

const StudentSubscriptionRegister = () => {
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        const currentUserRole = JSON.parse(localStorage.getItem("auth"))?.user;
        if (currentUserRole?.role === "student") {
            setLoading(false);
        } else {
            window.location.href = "/login";
            setLoading(false);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            {loading && <PageLoader open={loading} />}
            <div className={styles.container}>
                <h1 className={styles.heading}>Welcome to Plassroom</h1>

                <p className={styles.instruction}>
                    Congratulation on your new account!
                </p>
                <CommonButton
                    buttonText="Tell us your preferences"
                    classes={styles.button}
                    handleClick={() =>
                        (window.location.href = "/accountsettings")
                    }
                />
                <p className={styles.instruction}>
                    And just to celebrate we are gifting you a
                </p>
                <div className={styles.textWrapper}>
                    <h4>ONE MONTH free trial to the LITE plan</h4>
                    <p>Tap on image for more info</p>
                </div>
                <div className={styles.imageContent}>
                    <img
                        src={BG1}
                        onClick={() =>
                            history.push("/subscription")
                        }
                    />
                </div>
                <CommonButton
                    buttonText="Subscribe"
                    classes={styles.button}
                    handleClick={() =>
                        (window.location.href =
                            "/choose-plan-and-subscription-plan")
                    }
                />
                <p className={styles.instruction}>
                    Or you can immediately suscribe to one of our plans or enjoy
                    free 60 plasscoins on us!
                </p>
                <div className={styles.imageContent}>
                    <img
                        src={BG2}
                        onClick={() =>
                            history.push("/subscription")
                        }
                    />
                </div>
                <p className={styles.instruction}>
                    At the end of 30 days if you decide not to continue with the
                    Plan, you can still pay as you go for your session
                </p>

                <div className={styles.otherLink}>
                    <h4>Other useful links</h4>
                    <span onClick={() => history.push("/signup")}>
                        Sign up for another student account
                    </span>
                    <span onClick={() => history.push("/classes")}>
                        Browse upcoming sessions
                    </span>
                    <span onClick={() => history.push("/about")}>
                        How to begin using plassroom
                    </span>
                </div>
            </div>
        </>
    );
};

export default StudentSubscriptionRegister;
