import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styles from "./index.module.scss";
import CarouselSmall from "../CarouselSmall";
import moreClass from "../../../assets/images/NewDesign/moreClass.svg";
import { ratingStar } from "../../../utils/Ratting/rating";
import moment from "moment";
import FreeBG from "../../../assets/images/LOGO (4).svg";
import Loading from "../../Loading/Loading";
import registerBtn from "../../../assets/images/NewDesign/register.png";

const RegisterSessionCarousel = ({
    onClickEvent,
    classDetails,
    currentUserRole,
}) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const history = useHistory();

    const handleClick = (path) => {
        if (path === "left") {
            setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 0);
        } else {
            setCurrentSlide(
                currentSlide < classDetails?.class_slots.length / 2 - 1
                    ? currentSlide + 1
                    : 0
            );
        }
    };

    // const checkExtension = (source) => {
    //     const ext = source.split(".");
    //     return _.last(ext);
    // };

    return (
        <div className={styles.carouselPage}>
            <article className={styles.carouselWrapper} onClick={onClickEvent}>
                <div
                    className={styles.carouselSlider}
                    style={{
                        transform: `translateX(-${currentSlide * 25}%)`,
                    }}
                >
                    {classDetails?.class_slots ? (
                        <div className={styles.container}>
                            {classDetails?.class_slots.length > 0 ? (
                                classDetails?.class_slots.map((slot, i) => {
                                    return (
                                        <div className={styles.cardRow} key={i}>
                                            <Card className={styles.classcard}>
                                                <Card.Body
                                                    className={styles.cardBody}
                                                >
                                                    <h4>
                                                        {`${
                                                            classDetails?.type ===
                                                            "Course"
                                                                ? ""
                                                                : "Next on "
                                                        }
                                                            ${moment(
                                                                slot
                                                                    ?.configuration
                                                                    ?.session_start_time
                                                            ).format(
                                                                "ddd MMM D"
                                                            )}`}
                                                    </h4>
                                                    {classDetails?.type ===
                                                        "Course" && (
                                                        <p
                                                            style={{
                                                                color: "#13385c",
                                                                margin: "16px 2px",
                                                            }}
                                                        >
                                                            {`${
                                                                classDetails?.class_name
                                                            }
                                                                                    ${
                                                                                        i +
                                                                                        1
                                                                                    }`}
                                                        </p>
                                                    )}
                                                    <p>
                                                        {`
                                                        ${moment(
                                                            slot?.configuration
                                                                ?.session_start_time
                                                        ).format("dddd")}`}
                                                    </p>
                                                    <p>{`
                                                        ${moment(
                                                            slot?.configuration
                                                                ?.session_start_time
                                                        ).format("hh:mm A")} - 
                                                        ${moment(
                                                            slot?.configuration
                                                                ?.session_end_time
                                                        ).format(
                                                            "hh:mm A"
                                                        )}                                                        
                                                        `}</p>

                                                    {currentUserRole !==
                                                        "teacher" &&
                                                    classDetails?.type !==
                                                        "Course" ? (
                                                        <img
                                                            src={registerBtn}
                                                            onClick={() =>
                                                                history.push(
                                                                    `/register/${slot?.class_name_slug}`
                                                                )
                                                            }
                                                        />
                                                    ) : null}
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    );
                                })
                            ) : (
                                <Loading />
                            )}
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            </article>
            {classDetails?.class_slots?.length > 1 ? (
                <button
                    className={styles.leftBtn}
                    onClick={() => handleClick("left")}
                    // style={{ left: "0.5rem" }}
                >
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        className={styles.icon}
                    />
                </button>
            ) : null}
            {classDetails?.class_slots?.length > 1 ? (
                <button
                    className={styles.rightBtn}
                    onClick={() => handleClick()}
                >
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        className={styles.icon}
                    />
                </button>
            ) : null}
        </div>
    );
};

export default RegisterSessionCarousel;
