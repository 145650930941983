import React, { useEffect } from "react";
import styles from "./Preview.module.scss";
import Layout1 from "./Teacher/Layout1";
import Layout2 from "./Teacher/Layout2";
import Layout3 from "./Teacher/Layout3";
import WbLayout1 from "./RequiredStudentWB/Teacher/Layout1";
import WbLayout2 from "./RequiredStudentWB/Teacher/Layout2";
import WbLayout3 from "./RequiredStudentWB/Teacher/Layout3";

const Preview = ({ currentPanel, session, studentView }) => {
    useEffect(() => {
        console.log(currentPanel, " currentPanel >>");
        // eslint-disable-next-line
    }, [currentPanel]);
    const currentLayout =
        currentPanel?.lesson_settings?.whiteboard?.teacher?.view;
    const isWbRequired =
        currentPanel?.lesson_settings?.whiteboard?.student?.required_whiteboard;
    return (
        <div className={styles.previewContainer}>
            {/* <div
                className={styles.closeButton}
                onClick={() => history.goBack()}
            >
                Close
            </div> */}

            {!isWbRequired ? (
                currentLayout === "viewT1" ? (
                    <Layout1
                        currentPanel={currentPanel}
                        session={session}
                        studentView={studentView}
                    />
                ) : currentLayout === "viewT2" ? (
                    <Layout2
                        currentPanel={currentPanel}
                        session={session}
                        studentView={studentView}
                    />
                ) : currentLayout === "viewT3" ? (
                    <Layout3
                        currentPanel={currentPanel}
                        session={session}
                        studentView={studentView}
                    />
                ) : null
            ) : currentLayout === "viewT1" ? (
                <WbLayout1
                    currentPanel={currentPanel}
                    session={session}
                    studentView={studentView}
                />
            ) : currentLayout === "viewT2" ? (
                <WbLayout2
                    currentPanel={currentPanel}
                    session={session}
                    studentView={studentView}
                />
            ) : currentLayout === "viewT3" ? (
                <WbLayout3
                    currentPanel={currentPanel}
                    session={session}
                    studentView={studentView}
                />
            ) : null}
        </div>
    );
};

export default Preview;
