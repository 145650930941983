import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styles from "./index.module.scss";
import CarouselSmall from "../CarouselSmall";
import moreClass from "../../../assets/images/NewDesign/moreClass.svg";
import StudentLogoBg from "../../../assets/images/LOGO (7).png";
import { ratingStar } from "../../../utils/Ratting/rating";
import moment from "moment";
import FreeBG from "../../../assets/images/LOGO (4).svg";
import Loading from "../../Loading/Loading";
import { getCurrencySymbol } from "../../../utils/commonFunction";
const SessionCarousel = ({
    carouselImgs,
    onClickEvent,
    specialClass,
    autoPlay,
    controls,
    playOnHover,
    smallCarousel,
    classListDetails,
    title,
}) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [classList, setClassList] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        let url = "?perPage=8&page=" + 1;
        url = "/api/v1/home/classes" + url;
        setLoading(true);
        axios
            .get(url)
            .then((res) => {
                setLoading(false);
                setClassList(res?.data?.data?.sessions.splice(0, 8));
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
        // eslint-disable-next-line
    }, []);

    const handelRoute = (singleClass) => {
        history.push(`/class-details/${singleClass.class_name_slug}`);
    };

    const routeToClass = () => {
        history.push(`/classes`);
    };

    const handleClick = (path) => {
        if (path === "left") {
            setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 0);
        } else {
            setCurrentSlide(
                currentSlide < classList.length / 2 - 1 ? currentSlide + 1 : 0
            );
        }
    };

    // const checkExtension = (source) => {
    //     const ext = source.split(".");
    //     return _.last(ext);
    // };

    return (
        <>
            {title ? (
                <h2
                    style={{
                        color: "#13385C",
                        textAlign: "center",
                        margin: "3rem 0",
                    }}
                >
                    {title}
                </h2>
            ) : (
                ""
            )}
            <div className={styles.carouselPage}>
                <article
                    className={styles.carouselWrapper}
                    onClick={onClickEvent}
                >
                    <div
                        className={styles.carouselSlider}
                        style={{
                            transform: `translateX(-${currentSlide * 25}%)`,
                        }}
                    >
                        {classList ? (
                            <div className={styles.container}>
                                {!loading ? (
                                    classList?.length > 0 &&
                                    classList.map((classDetails, index) => {
                                        return (
                                            <div
                                                className={styles.cardRow}
                                                key={index}
                                            >
                                                <Card
                                                    className={styles.classcard}
                                                >
                                                    <Card.Body
                                                        className={
                                                            styles.cardBody
                                                        }
                                                    >
                                                        {/* <Card.Img variant="top" src={classDetails?.image_ids[0]?.location} /> */}
                                                        <div
                                                            className={
                                                                styles.classImage
                                                            }
                                                        >
                                                            {classDetails?.type ===
                                                            "Course" ? (
                                                                <div
                                                                    className={
                                                                        styles.logoContainer
                                                                    }
                                                                >
                                                                    <h1
                                                                        className={
                                                                            styles.courseText
                                                                        }
                                                                    >
                                                                        Course
                                                                    </h1>
                                                                    <img
                                                                        src={
                                                                            StudentLogoBg
                                                                        }
                                                                        className={
                                                                            styles.studentLogoBg
                                                                        }
                                                                        alt="Logo"
                                                                    />
                                                                </div>
                                                            ) : classDetails.is_free_class ? (
                                                                <img
                                                                    src={FreeBG}
                                                                    alt=""
                                                                    className={
                                                                        styles.freeBG
                                                                    }
                                                                />
                                                            ) : null}
                                                            <CarouselSmall
                                                                carouselImgs={_.get(
                                                                    classDetails,
                                                                    "image_ids",
                                                                    []
                                                                )}
                                                                onClickEvent={() =>
                                                                    handelRoute(
                                                                        classDetails
                                                                    )
                                                                }
                                                                specialClass="br19"
                                                                playOnHover={
                                                                    true
                                                                }
                                                                smallCarousel={
                                                                    true
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.bottomBlock
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.classRating
                                                                }
                                                            >
                                                                {ratingStar(
                                                                    parseInt(
                                                                        _.get(
                                                                            classDetails,
                                                                            "teacherRate",
                                                                            0
                                                                        )
                                                                    )
                                                                )}
                                                            </div>
                                                            <Card.Text
                                                                style={{
                                                                    fontSize:
                                                                        "medium",
                                                                }}
                                                            >
                                                                {
                                                                    classDetails?.class_name
                                                                }
                                                            </Card.Text>
                                                            <div
                                                                className={
                                                                    styles.blockRow
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.blueBlock
                                                                    }
                                                                >
                                                                    <h4>
                                                                        {
                                                                            classDetails
                                                                                ?.configuration
                                                                                ?.age_group
                                                                        }
                                                                    </h4>
                                                                    <p>Ages</p>
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles.blueBlock
                                                                    }
                                                                >
                                                                    <h4>
                                                                        {
                                                                            classDetails
                                                                                ?.configuration
                                                                                ?.session_duration
                                                                        }
                                                                    </h4>
                                                                    <p>Mins</p>
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles.blueBlock
                                                                    }
                                                                >
                                                                    {classDetails?.is_free_class ? (
                                                                        <h4>{`Free`}</h4>
                                                                    ) : (
                                                                        <>
                                                                            {/* <h4>{`£ ${classDetails?.configuration?.fee?.amount}`}</h4> */}
                                                                            <h4>
                                                                                {classDetails?.is_free_class
                                                                                    ? "Free"
                                                                                    : `${getCurrencySymbol(
                                                                                          classDetails
                                                                                              .configuration
                                                                                              ?.fee
                                                                                              ?.currency
                                                                                      )} ${
                                                                                          classDetails
                                                                                              .configuration
                                                                                              ?.fee
                                                                                              ?.amount /
                                                                                              100 ||
                                                                                          0
                                                                                      }`}
                                                                            </h4>
                                                                            <p>
                                                                                Per
                                                                                class
                                                                            </p>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.infoBlock
                                                                }
                                                            >
                                                                {/* <p className={styles.classNameTxt}>{classDetails?.class_name}</p> */}
                                                                {
                                                                    <>
                                                                        <p
                                                                            className={
                                                                                styles.startTime
                                                                            }
                                                                        >
                                                                            {moment(
                                                                                classDetails
                                                                                    ?.class_slots[0]
                                                                                    ?.configuration
                                                                                    ?.session_start_time
                                                                            ).format(
                                                                                "MMM DD (ddd) hh:mm A"
                                                                            )}{" "}
                                                                            {moment
                                                                                .tz(
                                                                                    moment.tz.guess()
                                                                                )
                                                                                .zoneAbbr()}
                                                                        </p>
                                                                        <p
                                                                            className={
                                                                                styles.startTime
                                                                            }
                                                                        >
                                                                            {classDetails
                                                                                ?.class_slots
                                                                                .length >
                                                                            1
                                                                                ? `and more...`
                                                                                : null}
                                                                        </p>
                                                                    </>
                                                                }
                                                            </div>
                                                            <div>
                                                                <p
                                                                    className={
                                                                        styles.hostedBlock
                                                                    }
                                                                >{`Hosted by ${classDetails?.teacher[0]?.preferedName}`}</p>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <Loading />
                                )}
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </article>
                {classList?.length > 1 ? (
                    <button
                        className={styles.leftBtn}
                        onClick={() => handleClick("left")}
                        // style={{ left: "0.5rem" }}
                    >
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            className={styles.icon}
                        />
                    </button>
                ) : null}
                {classList?.length > 1 ? (
                    <button
                        className={styles.rightBtn}
                        onClick={() => handleClick()}
                    >
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            className={styles.icon}
                        />
                    </button>
                ) : null}
                {classList?.length > 0 && (
                    <img
                        src={moreClass}
                        style={{
                            margin: "2rem 0px",
                            cursor: "pointer",
                            width: "20%",
                        }}
                        onClick={() => routeToClass()}
                    />
                )}
            </div>
        </>
    );
};

export default SessionCarousel;
