import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import reset from "../../../../assets/images/NewDesign/reset.svg";
import searchButton from "../../../../assets/images/NewDesign/searchButton.svg";

const SearchFilterNew = ({
    setFilterKey,
    searchedTerm,
    setSearchedTerm,
    setSortKey,
    isFrom,
}) => {
    const filterOptions = [
        {
            title: "Age Range",
            key: "age",
        },
        {
            title: "Category",
            key: "classTopic",
        },
        {
            title: "Time",
            key: "time",
        },
    ];

    const sortOptionsForClasses = [
        {
            title: "Price",
            key: "price",
        },
        {
            title: "Teacher rating",
            key: "teacher_rating",
        },
        {
            title: "Title A-Z",
            key: "class_name",
        },
        {
            title: "Students registered",
            key: "students_register",
        },
    ];

    const sortOptionsForTeachers = [
        {
            title: "Name A-Z",
            key: "name",
        },
        {
            title: "Teacher rating",
            key: "teacher_rating",
        },
        {
            title: "No. of classes held",
            key: "no_of_classes",
        },
    ];

    // const sortOptions =
    //     isFrom === "newClasses"
    //         ? sortOptionsForClasses
    //         : sortOptionsForTeachers;

    const ageOptions = [
        {
            title: "0-1 years",
            key: "0-1",
        },
        {
            title: "1-2 years",
            key: "1-2",
        },
        {
            title: "2-3 years",
            key: "2-3",
        },
        {
            title: "3-4 years",
            key: "3-4",
        },
        {
            title: "4-5 years",
            key: "4-5",
        },
        {
            title: "5-6 years",
            key: "5-6",
        },
        {
            title: "6-7 years",
            key: "6-7",
        },
        {
            title: "7-8 years",
            key: "7-8",
        },
        {
            title: "8-9 years",
            key: "8-9",
        },
        {
            title: "9-10 years",
            key: "9-10",
        },
        {
            title: "10-11 years",
            key: "10-11",
        },
        {
            title: "11-12 years",
            key: "11-12",
        },
        {
            title: "12-13 years",
            key: "12-13",
        },
        {
            title: "13-14 years",
            key: "13-14",
        },
        {
            title: "14-15 years",
            key: "14-15",
        },
        {
            title: "15-16 years",
            key: "15-16",
        },
    ];

    const classTopicOptions = [
        {
            title: "Languages",
            key: "Languages",
        },
        {
            title: "Music",
            key: "Music",
        },
        {
            title: "Arts and Craft",
            key: "Arts and Craft",
        },
        {
            title: "Social/Life Skills",
            key: "Social/Life Skills",
        },
        {
            title: "Tech",
            key: "Tech",
        },
        {
            title: "English",
            key: "English",
        },
        {
            title: "Science",
            key: "Science",
        },
        {
            title: "Travel/Geography",
            key: "Travel/Geography",
        },
        {
            title: "History",
            key: "History",
        },
        {
            title: "Math",
            key: "Math",
        },
    ];

    const classSizeOptions = [
        {
            title: "1 student",
            key: "0-1",
        },
        {
            title: "2-5 students",
            key: "2-5",
        },
        {
            title: "6-8 students",
            key: "6-8",
        },
    ];

    const timeOptions = [
        {
            title: "9am-12pm",
            key: "9-12",
        },
        {
            title: "12pm-8pm",
            key: "12-8",
        },
        {
            title: "8pm-12am",
            key: "8-12",
        },
        {
            title: "12am-9am",
            key: "12-9",
        },
    ];

    const [localSearchedTerm, setLocalSearchedTerm] = useState();
    const [showSearchInput, setShowSearchInput] = useState(true);
    // const SearchInputTimer = useRef();

    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [filterMenu, setFilterMenu] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState({
        title: "",
        key: "",
    });
    const [filters, setFilters] = useState({
        age: [],
        classTopic: [],
        classSize: [],
        time: [],
        free: false,
    });
    const [selectedFilters, setSelectedFilters] = useState([]);

    const [sortKeyLocal, setSortKeyLocal] = useState({
        title: "",
        key: "",
    });

    const [showSortMenu, setShowSortMenu] = useState(false);
    const [inputVal, setinputVal] = useState(0);
    const [dropdownState, setdropdownState] = useState(false);
    const [humbBtn, setHumbBtn] = useState("");
    const [humbMenu, setHumbMenu] = useState("");

    // Trigger API to search after 1 second of searching.
    useEffect(() => {
        const delayDebounceFunction = setTimeout(() => {
            if (showSearchInput) setSearchedTerm(localSearchedTerm);
        }, 1000);

        return () => clearTimeout(delayDebounceFunction);
        // eslint-disable-next-line
    }, [inputVal]);

    // Set already searched term in the filtered list.
    useEffect(() => {
        setLocalSearchedTerm(searchedTerm);
        // eslint-disable-next-line
    }, [searchedTerm]);

    // Clear the open filter menu details when filter menu is closed.
    useEffect(() => {
        if (!showFilterMenu) {
            setFilterMenu([]);
            setSelectedFilter({
                title: "",
                key: "",
            });
        }
        // eslint-disable-next-line
    }, [showFilterMenu]);

    // Timeout to hide search input.
    // useEffect(() => {
    //     hideSearchInput();
    //     // eslint-disable-next-line
    // }, [showSearchInput, mouseOverInput, localSearchedTerm]);

    // const hideSearchInput = () => {
    //     if (showSearchInput && !mouseOverInput && !localSearchedTerm) {
    //         SearchInputTimer.current = setTimeout(() => {
    //             setShowSearchInput(false);
    //         }, 5000);
    //     } else {
    //         clearTimeout(SearchInputTimer.current);
    //     }
    // }
    const handleChangeSearchText = (e) => {
        const searchedText = e.target.value;
        setLocalSearchedTerm(searchedText);
        // const searhedText = e.target.value;
        // setinputVal(e.target.value)
    };

    const handleFilterClasses = (option) => {
        if (option.key !== "free") setSelectedFilter(option);

        if (option.key === "age") {
            setFilterMenu(ageOptions);
        } else if (option.key === "classTopic") {
            setFilterMenu(classTopicOptions);
        } else if (option.key === "classSize") {
            setFilterMenu(classSizeOptions);
        } else if (option.key === "time") {
            setFilterMenu(timeOptions);
        } else if (option.key === "free") {
            handleFreeFilter();
        }
    };

    // const handleBackMenu = () => {
    //     setSelectedFilter({
    //         title: "",
    //         key: "",
    //     });
    // };

    const handleFreeFilter = () => {
        const localCopy = { ...filters };

        localCopy.free = !localCopy.free;

        setFilters(localCopy);
        setFilterKey(localCopy);
    };

    const handleFilters = async (option, key) => {
        await handleFilterClasses(option);
        // handleSortField(option)
        setSortKeyLocal(option);

        const localCopy = { ...filters };
        let localCopySelectedFilters = [...selectedFilters];
        const itemIndex = localCopy[key].indexOf(option.key);

        if (itemIndex > -1) {
            localCopy[key].splice(itemIndex, 1);
        } else {
            localCopy[key].push(option.key);
        }

        const filterListIndex = localCopySelectedFilters.findIndex(
            (filter) => filter.option.key === option.key
        );

        if (filterListIndex > -1) {
            localCopySelectedFilters.splice(filterListIndex, 1);
        } else {
            localCopySelectedFilters = [
                {
                    option,
                    key,
                },
                ...localCopySelectedFilters,
            ];
        }
        setFilters(localCopy);
        setFilterKey(localCopy);
        setSelectedFilters(localCopySelectedFilters);
    };

    // const handleSortField = (filter) => {
    //     setShowSortMenu(false);
    //     setSortKeyLocal(filter);
    //     setSortKey(filter.key);
    // };

    // window.addEventListener("mouseup", function (event) {
    //     var pol = document.getElementById("searchBar");
    //     if (event.target !== pol && event.target.parentNode !== pol) {
    //         setShowSearchInput(Boolean(localSearchedTerm));
    //     }
    // });

    // const handleOpenSearchInput = () => {
    //     setMouseOverInput(true);
    // };

    // const handleCloseSearchInput = () => {
    //     setMouseOverInput(false);
    //     hideSearchInput();
    // };

    window.addEventListener("mouseup", function (event) {
        var pol = document.getElementById("sortMenu");
        var dol = document.getElementById("humbMenu");
        if (event.target !== pol && event.target.parentNode !== pol) {
            // setShowSortMenu(false);
            setShowSortMenu(false);
        }
        if (event.target !== dol && event.target.parentNode !== dol) {
            // setShowSortMenu(false);
            setHumbMenu(false);
        }
    });

    const handleDropdown = (value) => {
        if (dropdownState) {
            setShowSortMenu(false);
            setdropdownState(false);
        } else {
            setdropdownState(true);
            setShowSortMenu(value);
        }
    };

    const resetValues = () => {
        setLocalSearchedTerm("");
        setFilterKey({
            age: [],
            classTopic: [],
            classSize: [],
            time: [],
            free: false,
        });
        setFilters({
            age: [],
            classTopic: [],
            classSize: [],
            time: [],
            free: false,
        });
        setinputVal(inputVal + 1);
        setHumbBtn("");
        setHumbMenu("");
    };

    return (
        <>
            <div className={styles.filterIconsContainer}>
                <p>Filter</p>
                {isFrom !== "Teachers" ? (
                    <div className={styles.sortContainer}>
                        <div
                            className={styles.sortIcon}
                            onClick={() => handleDropdown("classTopic")}
                        >
                            <div className={styles.sortTitle}>
                                {filters?.classTopic?.length
                                    ? filters?.classTopic?.map((a) => `${a}, `)
                                    : "Class Topic"}
                            </div>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </div>

                        {showSortMenu === "classTopic" ? (
                            <div className={styles.sortMenu} id="sortMenu">
                                {classTopicOptions.map((option) => {
                                    return (
                                        <div
                                            // onClick={() => handleSortField(option)}
                                            onClick={() => {
                                                handleFilters(
                                                    option,
                                                    "classTopic"
                                                );
                                            }}
                                            className={`${styles.filterItem} ${
                                                filters["classTopic"].indexOf(
                                                    option.key
                                                ) > -1
                                                    ? styles.selectedFilterItem
                                                    : null
                                            }`}
                                            key={option.key}
                                        >
                                            {option.title}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : null}
                    </div>
                ) : null}

                {isFrom !== "Teachers" ? (
                    <div className={styles.sortContainer}>
                        <div
                            className={styles.sortIcon}
                            onClick={() => {
                                handleDropdown("ageRange");
                            }}
                        >
                            <div className={styles.sortTitle}>
                                {filters?.age?.length
                                    ? filters?.age?.map((a) => `${a} years, `)
                                    : "Age Range"}
                            </div>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </div>

                        {showSortMenu === "ageRange" ? (
                            <div className={styles.sortMenu} id="sortMenu">
                                {ageOptions.map((option) => {
                                    return (
                                        <div
                                            // onClick={() => handleSortField(option)}
                                            onClick={() => {
                                                handleFilters(option, "age");
                                            }}
                                            className={`${styles.filterItem} ${
                                                filters["age"].indexOf(
                                                    option.key
                                                ) > -1
                                                    ? styles.selectedFilterItem
                                                    : null
                                            }`}
                                            key={option.key}
                                        >
                                            {option.title}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : null}
                    </div>
                ) : null}

                {showSearchInput ? (
                    <div className={styles.searchBar} id="searchBar">
                        <input
                            type="text"
                            value={localSearchedTerm}
                            onChange={handleChangeSearchText}
                            // autoFocus
                            placeholder="Keyword search..."
                        />
                    </div>
                ) : null}
                {/* <pre>{JSON.stringify(localSearchedTerm)}</pre>
                <pre>{JSON.stringify(showSearchInput)}</pre> */}

                <img
                    src={searchButton}
                    style={{ cursor: "pointer" }}
                    onClick={() => setinputVal(inputVal + 1)}
                    alt="search"
                    className={styles.searchBtn}
                />

                <img
                    src={reset}
                    style={{ cursor: "pointer", margin: "0 5px" }}
                    onClick={() => resetValues()}
                    alt="reset"
                />
            </div>

            <div className={styles.hambContainer}>
                {isFrom !== "Teachers" ? (
                    <>
                        <div className={styles.sortContainer}>
                            <div
                                className={styles.sortIcon}
                                onClick={() =>
                                    setHumbBtn(!!humbBtn ? "" : "humbFilters")
                                }
                            >
                                <div className={styles.sortTitle}>
                                    Browse By
                                </div>
                                <FontAwesomeIcon icon={faChevronDown} />
                            </div>
                            {humbBtn === "humbFilters" && (
                                <div
                                    className={styles.sortMenu}
                                    style={{ marginTop: "7px" }}
                                >
                                    {isFrom !== "Teachers" && (
                                        <>
                                            <div
                                                className={styles.sortContainer}
                                            >
                                                <div
                                                    className={styles.sortIcon}
                                                >
                                                    <div
                                                        className={
                                                            styles.sortTitle
                                                        }
                                                        onClick={() =>
                                                            setHumbMenu(
                                                                "classTopic"
                                                            )
                                                        }
                                                        style={{
                                                            color:
                                                                humbMenu ===
                                                                "classTopic"
                                                                    ? "#88addb"
                                                                    : "#13385c",
                                                        }}
                                                    >
                                                        Class Topic
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={styles.sortContainer}
                                            >
                                                <div
                                                    className={styles.sortIcon}
                                                >
                                                    <div
                                                        className={
                                                            styles.sortTitle
                                                        }
                                                        onClick={() => {
                                                            setHumbMenu(
                                                                "ageRange"
                                                            );
                                                        }}
                                                        style={{
                                                            color:
                                                                humbMenu ===
                                                                "ageRange"
                                                                    ? "#88addb"
                                                                    : "#13385c",
                                                        }}
                                                    >
                                                        Age Range
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {humbMenu === "classTopic" ? (
                                        <div
                                            className={styles.humbMenu}
                                            id="humbMenu"
                                        >
                                            {classTopicOptions.map((option) => {
                                                return (
                                                    <div
                                                        // onClick={() => handleSortField(option)}
                                                        onClick={() => {
                                                            handleFilters(
                                                                option,
                                                                "classTopic"
                                                            );
                                                        }}
                                                        className={`${
                                                            styles.filterItem
                                                        } ${
                                                            filters[
                                                                "classTopic"
                                                            ].indexOf(
                                                                option.key
                                                            ) > -1
                                                                ? styles.selectedFilterItem
                                                                : null
                                                        }`}
                                                        key={option.key}
                                                    >
                                                        {option.title}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : humbMenu === "ageRange" ? (
                                        <div
                                            className={styles.humbMenu}
                                            id="humbMenu"
                                        >
                                            {ageOptions.map((option) => {
                                                return (
                                                    <div
                                                        // onClick={() => handleSortField(option)}
                                                        onClick={() => {
                                                            handleFilters(
                                                                option,
                                                                "age"
                                                            );
                                                        }}
                                                        className={`${
                                                            styles.filterItem
                                                        } ${
                                                            filters[
                                                                "age"
                                                            ].indexOf(
                                                                option.key
                                                            ) > -1
                                                                ? styles.selectedFilterItem
                                                                : null
                                                        }`}
                                                        key={option.key}
                                                    >
                                                        {option.title}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </div>
                    </>
                ) : showSearchInput ? (
                    <div className={styles.searchBar} id="searchBar">
                        <input
                            type="text"
                            value={localSearchedTerm}
                            onChange={handleChangeSearchText}
                            // autoFocus
                            placeholder="Keyword search..."
                        />
                    </div>
                ) : null}

                <img
                    src={reset}
                    style={{ cursor: "pointer", margin: "0 5px" }}
                    onClick={() => resetValues()}
                    alt="reset"
                />
            </div>
        </>
    );
};

export default SearchFilterNew;
