import {
    faGreaterThan,
    faLessThan,
    faPlus,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import "easymde/dist/easymde.min.css";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDropzone } from "react-dropzone";
import "react-markdown-editor-lite/lib/index.css";
import { Link, useParams } from "react-router-dom";
import CloseButton from "../../../assets/images/Close_button.png";
import Note from "../../../assets/images/note.svg";
import paymentDetails from "../../../assets/images/paymentDetails.svg";
import Logo from "../../../assets/images/plass_logo_new.svg";
// import Calender from "../../../components/Calendar";
import Clock from "../../../components/Clock";
import CustomDropdown from "../../../components/CustomDropdown";
import CustomSelectDropdown from "../../../components/CustomSelectDropdown";
import DateAndTimeInput from "../../../components/DateAndTimeInput";
// import Duration from "../../../components/Durationpicker";
import Snackbar from "../../../components/Snackbar";
import styles from "./create.module.css";
import clsx from "clsx";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import {
    recurringClassesData,
    learningObjectivesData,
    maxStudentsData,
    ageGroupDataFrom,
    ageGroupDataTo,
    feeCurrencyData,
} from "../../../utils/constants";
import Panels from "./Panels";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import PageLoader from "../../../components/PageLoader";
import { getCurrencySymbol } from "../../../utils/commonFunction";

export default () => {
    const { id } = useParams();
    const history = useHistory();
    const [publish, setPublish] = useState(false);
    const [startDate, setStartDate] = useState(undefined);
    const [startTime, setStartTime] = useState(undefined);
    const [duration, setDuration] = useState(undefined);
    const [feePlan, setFeePlan] = useState("CLASS");
    const [recurringClasses, setRecurringClasses] = useState(undefined);
    const [learningObjectives, setLearningObjectives] = useState([]);
    const [isClassFree, setIsClassFree] = useState(true);
    const [minAmount, setMinAmount] = useState(4);
    const [ageGroup, setAgeGroup] = useState(undefined);
    const [ageGroupFrom, setAgeGroupFrom] = useState(undefined);
    const [ageGroupTo, setAgeGroupTo] = useState(undefined);
    const [userList, setUserList] = useState([]);
    const [maxStudents, setMaxStudents] = useState(0);
    const [students, setStudents] = useState([]);
    const [feeCurrency, setFeeCurrency] = useState("GBP £");
    const [planValue, setPlanValue] = useState("CLASS");
    const [feeAmount, setFeeAmount] = useState(undefined);
    const [localFeeAmount, setLocalFeeAmount] = useState(undefined);
    const [sessionID, setSessionID] = useState(undefined);
    const [className, setClassName] = useState(undefined);
    const [versionTime, setVersionTime] = useState(undefined);
    const [classModal, setClassModal] = useState(false);
    const [mediaModal, setMediaModal] = useState(false);
    const [description, setDescription] = useState("");
    const [modalClassName, setModalClassName] = useState("");
    const [modalClassDescription, setModalClassDescription] = useState("");
    const [show, setShow] = useState(undefined);
    const [importModal, setImportModal] = useState(false);
    const [selectClass, setSelectClass] = useState(false);
    const [rightPanelSettings, setRightPanelSettings] = useState([]);
    const [games, setGames] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [imgFlag, setImgFlag] = useState(0);
    const [modalFlag, setModalFlag] = useState(0);
    const { acceptedFiles, getRootProps } = useDropzone();
    const [sessions, setSessions] = useState([]);
    const [sessionData, setSessionData] = useState();
    const [rightPanelIndex, setRightPanelIndex] = useState(undefined);
    const [imgModalFlag, setImgModalFlag] = useState(0);
    const [savedFolder, setSavedFolder] = useState(false);
    const [category, setCategory] = useState("IMAGE");
    const [userGallery, setUserGallery] = useState([]);
    const [msg, setMsg] = useState(null);
    const [msgColor, setMsgColor] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [toggleShowMore, setToggleShowMore] = useState(false);
    const [updateNewClass, setUpdateNewClass] = useState(false);
    const [snackBarDuration, setSnackBarDuration] = useState();
    const [accountSubmitted, setAccountSubmitted] = useState(false);
    const [stripeModal, setStripeModal] = useState(false);
    const [disableInputs, setDisableInputs] = useState(false);
    const [sessionType, setSessionType] = useState(null);
    const [classSlots, setClassSlots] = useState([]);
    const [navClick, setNavClick] = useState({});
    const [panelSlotId, setPanelSlotId] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [pageloding, setPageloading] = useState(false);
    const currentDateValue = new Date();
    const isSuperAdmin = JSON.parse(localStorage.getItem("auth"))?.user
        ?.isSuperAdmin;
    const handleSetDuration = (duration) => {
        const minAmountCounted =
            duration === 90
                ? 12
                : duration === 60
                ? 8
                : duration === 45
                ? 6
                : duration === 30
                ? 5
                : 4;
        setMinAmount(minAmountCounted);
        setFeeAmount(minAmountCounted);
        setDuration(duration);
        setLocalFeeAmount(minAmountCounted);
    };

    const handleNavClick = async (slotId) => {
        await axios.get(`/api/v1/class/${sessionID}`).then((result) => {
            if (result.data.success) {
                setClassSlots(result?.data?.data?.session?.class_slots);
                setPanelSlotId(slotId);
                const rpSettings =
                    result?.data?.data?.session?.class_slots?.filter(
                        (o) => o?._id === slotId
                    ) || null;
                setRightPanelSettings(
                    rpSettings[0].configuration.right_panel_settings
                );
                setNavClick({
                    [slotId]: true,
                });
            }
        });
    };

    const checkSubmitted = () => {
        axios
            .get("api/v1/payment/stripe-account-detail-submitted")
            .then((res) => {
                if (res?.data?.data?.submitted) {
                    setAccountSubmitted(true);
                } else {
                    setAccountSubmitted(false);
                }
            })
            .catch((error) => {
                setAccountSubmitted(false);
            });
    };

    const updateSessionDetails = async (arr, cb, updatedSessionData) => {
        if (arr.length > 0) {
            const sessionData = {
                id: sessionID,
                rightPanelSettings: arr || rightPanelSettings,
                ...updatedSessionData,
            };
            await axios
                .put("/api/v1/sessions", sessionData)
                .then((result) => {
                    setDisableInputs(result.data?.data?.isTimeClash);
                    if (result.data.success) {
                        if (cb) {
                            cb();
                        }
                        setVersionTime(moment().format("HH:mm"));
                    } else {
                        setPublish(
                            result?.data?.data?.session_type === "PUBLISHED"
                        );
                        setMsg(
                            _.get(
                                result,
                                "data.message",
                                "Something went wrong."
                            )
                        );
                        setMsgColor("error");
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                            setMsgColor("");
                        }, 3000);
                    }
                })
                .catch((error) => {
                    setPublish(false);
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setMsgColor("error");
                    setTimeout(function () {
                        setMsg(null);
                        setMsgColor("");
                    }, 3000);
                });
        }
    };

    const updateSession = async (rpSettings, cb, updatedSessionData) => {
        if (rightPanelSettings.length > 0) {
            const date =
                startDate && startTime
                    ? moment(
                          `${new moment(startDate).format("YYYY-MM-DD")} ${
                              startTime.hours
                          } ${startTime.minutes}`,
                          "YYYY-MM-DD HH mm"
                      )
                    : undefined;

            const dateTimeInUTC = date
                ? date.clone().utc().format("DD/MM/YYYY hh:mm a")
                : undefined;

            const sessionData = {
                id: id,
                fee:
                    feeCurrency || feeAmount || feePlan
                        ? {
                              currency: feeCurrency,
                              amount: feeAmount,
                              plan: feePlan,
                          }
                        : undefined,
                rightPanelSettings: rpSettings || rightPanelSettings,
                recurringClasses,
                learningObjectives,
                maxStudents,
                isFreeClass: isClassFree,
                ageGroup:
                    ageGroupFrom != undefined && ageGroupTo != undefined
                        ? `${ageGroupFrom}-${ageGroupTo}`
                        : undefined,
                sessionStartTime: dateTimeInUTC,
                sessionDuration: duration,
                selectedStudentIds: students,
                className: className,
                classDescription: description,
                session_type: publish ? "PUBLISHED" : "UNPUBLISHED",
                ...updatedSessionData,
            };
            await axios
                .post("/api/v1/session", sessionData)
                .then((result) => {
                    setDisableInputs(result.data?.data?.isTimeClash);
                    if (result.data.success) {
                        if (cb) {
                            cb();
                        }
                        setVersionTime(moment().format("HH:mm"));
                        setLoading(false);
                    } else {
                        setPublish(
                            result?.data?.data?.session_type === "PUBLISHED"
                        );
                        setLoading(false);
                        setMsg(
                            _.get(
                                result,
                                "data.message",
                                "Something went wrong."
                            )
                        );
                        setMsgColor("error");
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                            setMsgColor("");
                        }, 3000);
                    }
                })
                .catch((error) => {
                    setPublish(false);
                    setLoading(false);
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setMsgColor("error");
                    setTimeout(function () {
                        setMsg(null);
                        setMsgColor("");
                    }, 3000);
                });
        }
    };

    useEffect(() => {
        if (publish) {
            setMsg("Details can only be amended once unpublished");
            setShowSnackbar(true);
            setMsgColor("information");
            setSnackBarDuration(10000);

            setTimeout(function () {
                setMsgColor(null);
                setSnackBarDuration();
            }, 10100);
        }
        // eslint-disable-next-line
    }, [publish]);

    useEffect(() => {
        checkSubmitted();
        const getSessionInfo = async () => {
            setPageloading(true);
            await axios
                .get(`/api/v1/class/${id}`)
                .then((result) => {
                    if (result.data.success) {
                        setSessionType(result.data.data.session.type);
                        setGames(result.data.data.session.configuration.games);
                        setLessons(
                            result.data.data.session.configuration.lessons
                        );
                        setPublish(
                            result.data.data.session.session_type ===
                                "PUBLISHED"
                        );
                        setClassSlots(result?.data?.data?.session?.class_slots);
                        setIsClassFree(
                            result?.data?.data?.session?.is_free_class
                        );
                        setSessionID(result.data.data.session._id);
                        if (result.data.data.session.session_images) {
                            setGallery(result.data.data.session.session_images);
                        }
                        const updateTime = moment
                            .utc(
                                result.data.data.session.updatedAt,
                                "DD/MM/YYYY HH:mm"
                            )
                            .local()
                            .format("HH:mm");
                        setVersionTime(updateTime);
                        if (
                            result.data.data.session.configuration
                                .session_start_time
                        ) {
                            const startDate = moment
                                .utc(
                                    result.data.data.session.configuration
                                        .session_start_time,
                                    "DD/MM/YYYY hh:mm a"
                                )
                                .local()
                                .toDate();
                            const startTime = moment
                                .utc(
                                    result.data.data.session?.class_slots
                                        .configuration.session_start_time,
                                    "DD/MM/YYYY hh:mm a"
                                )
                                .local()
                                .format("HH:mm");
                            setStartDate(startDate);
                            setStartTime({
                                hours: startTime.split(":")[0],
                                minutes: startTime.split(":")[1],
                            });
                        }

                        if (
                            result.data.data.session.configuration
                                .session_duration
                        ) {
                            setDuration(
                                result.data.data.session.configuration
                                    .session_duration
                            );

                            setMinAmount(
                                result.data.data.session.configuration
                                    .session_duration === 90
                                    ? 12
                                    : result.data.data.session.configuration
                                          .session_duration === 60
                                    ? 8
                                    : result.data.data.session.configuration
                                          .session_duration === 45
                                    ? 6
                                    : result.data.data.session.configuration
                                          .session_duration === 30
                                    ? 5
                                    : 4
                            );
                        }

                        setFeeCurrency(
                            result?.data?.data?.session?.configuration?.fee
                                ?.currency
                        );
                        setFeeAmount(
                            result.data.data.session.is_free_class
                                ? 0
                                : result.data.data.session.configuration.fee
                                      ?.amount
                                ? result.data.data.session.configuration.fee
                                      ?.amount / 100
                                : result.data.data.session.configuration
                                      .session_duration === 90
                                ? 12
                                : result.data.data.session.configuration
                                      .session_duration === 60
                                ? 8
                                : result.data.data.session.configuration
                                      .session_duration === 45
                                ? 6
                                : result.data.data.session.configuration
                                      .session_duration === 30
                                ? 5
                                : 4
                        );
                        setLocalFeeAmount(
                            result.data.data.session.is_free_class
                                ? 0
                                : result.data.data.session.configuration.fee
                                      ?.amount
                                ? result.data.data.session.configuration.fee
                                      ?.amount / 100
                                : result.data.data.session.configuration
                                      .session_duration === 90
                                ? 12
                                : result.data.data.session.configuration
                                      .session_duration === 60
                                ? 8
                                : result.data.data.session.configuration
                                      .session_duration === 45
                                ? 6
                                : result.data.data.session.configuration
                                      .session_duration === 30
                                ? 5
                                : 4
                        );
                        setFeePlan("CLASS");
                        setRecurringClasses(
                            result.data.data.session.configuration
                                .recurring_classes
                        );
                        setLearningObjectives(
                            result.data.data.session.configuration
                                .learning_objectives
                        );
                        setMaxStudents(
                            result.data.data.session.configuration.max_students
                        );
                        setAgeGroup(
                            result.data.data.session.configuration.age_group
                        );
                        if (
                            result?.data?.data?.session?.configuration
                                ?.age_group
                        ) {
                            const ageGroup =
                                result?.data?.data?.session?.configuration?.age_group?.split(
                                    "-"
                                );
                            let intArray = [];
                            for (let i = 0; i < ageGroup.length; i++) {
                                intArray[i] = parseInt(ageGroup[i]);
                            }
                            setAgeGroupFrom(
                                intArray[0] || intArray[0] === 0
                                    ? intArray[0]
                                    : null
                            );
                            setAgeGroupTo(intArray[1] ? intArray[1] : null);
                        }
                        setClassName(result.data.data.session.class_name);
                        setModalClassName(result.data.data.session.class_name);
                        setDescription(
                            result.data.data.session.class_description
                        );
                        setModalClassDescription(
                            result?.data?.data?.session?.class_description
                        );

                        if (result?.data?.data?.session?.type === "Course") {
                            if (
                                result?.data?.data?.session?.class_slots.length
                            ) {
                                const id =
                                    result?.data?.data?.session?.class_slots[0]
                                        ?._id || null;
                                setPanelSlotId(id);
                                setRightPanelSettings(
                                    result?.data?.data?.session?.class_slots[0]
                                        .configuration.right_panel_settings
                                );
                                setNavClick({
                                    [id]: true,
                                });
                            } else {
                                setRightPanelSettings([]);
                            }
                        } else {
                            setRightPanelSettings(
                                result.data.data.session?.configuration
                                    .right_panel_settings
                            );
                        }

                        getUserGallery();
                        setPageloading(false);
                    } else {
                        setPublish(false);
                        setPageloading(false);
                        setMsg(
                            _.get(
                                result,
                                "data.message",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setMsgColor("error");
                        setTimeout(function () {
                            setMsg(null);
                            setMsgColor("");
                        }, 3000);
                    }
                })
                .catch((error) => {
                    setPublish(false);
                    setPageloading(false);
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setMsgColor("error");
                    setTimeout(function () {
                        setMsg(null);
                        setMsgColor("");
                    }, 3000);
                });
        };
        getSessionInfo();
        // eslint-disable-next-line
    }, []);
    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         updateSession();
    //     }, 10000);
    //     return () => clearInterval(timer);
    // }, [
    //     publish,
    //     sessionID,
    //     feePlan,
    //     recurringClasses,
    //     learningObjectives,
    //     ageGroupFrom,
    //     ageGroupTo,
    //     userList,
    //     maxStudents,
    //     students,
    //     feeCurrency,
    //     feeAmount,
    //     startDate,
    //     startTime,
    //     duration,
    //     className,
    //     description,
    //     rightPanelSettings,
    // ]);

    useEffect(() => {
        if (updateNewClass) {
            updateSession();
            setUpdateNewClass(false);
        }
        // eslint-disable-next-line
    }, [updateNewClass]);

    useEffect(
        () => {
            updateSession();
        },
        // eslint-disable-next-line
        [
            learningObjectives,
            ageGroupFrom,
            ageGroupTo,
            maxStudents,
            feeAmount,
            startDate,
            startTime,
            duration,
            className,
            description,
            isClassFree,
            feeCurrency,
            // publish,
            // sessionID,
            // feePlan,
            // recurringClasses,
            // userList,
            // students,
            // rightPanelSettings,
        ]
    );

    useEffect(() => {
        acceptedFiles.map((obj) => {
            return handleUploadImage(obj);
        });
        // eslint-disable-next-line
    }, [acceptedFiles]);

    const handleLearningObjectives = (arr) => {
        const setArrLearningObjectives = new Set(arr);
        setLearningObjectives(Array.from(setArrLearningObjectives));
    };

    const getUserGallery = async () => {
        await axios
            .get("/api/v1//user/gallery")
            .then((result) => {
                if (result.data.success) {
                    const newArray = result.data.data.gallery;
                    setUserGallery(newArray);
                }
            })
            .catch((error) => {
                setPublish(false);
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setMsgColor("error");
                setTimeout(function () {
                    setMsg(null);
                    setMsgColor("");
                }, 3000);
            });
    };

    const handleClassName = (e) => {
        setModalClassName(e.target.value);
    };

    const handleChange = (date) => {
        setStartDate(date);
        const currentDate = new Date();
        if (date.getDate() === currentDate.getDate() + 1) {
            setStartTime({
                hours: currentDate.getHours(),
                minutes: currentDate.getMinutes() + 1,
            });
        }
        // if (date >= new Date()) {
        //   setStartDate(date);
        // }
    };

    const addClassSlot = (timeInput) => {
        const date =
            timeInput.date && timeInput.time
                ? moment(
                      `${new moment(timeInput.date).format("YYYY-MM-DD")} ${
                          timeInput.time.hours
                      } ${timeInput.time.minutes}`,
                      "YYYY-MM-DD HH mm"
                  )
                : undefined;
        const dateTimeInUTC = date
            ? date.clone().utc().format("DD/MM/YYYY hh:mm a")
            : undefined;
        axios
            .post(`/api/v1/class/${sessionID}/slot`, {
                sessionStartTime: dateTimeInUTC,
            })
            .then((res) => {
                if (res?.data?.success) {
                    if (sessionType === "Course") {
                        if (res?.data?.data?.class_slots.length > 0) {
                            handleNavClick(
                                res?.data?.data?.class_slots.pop()._id
                            );
                        }
                    } else {
                        setClassSlots(res?.data?.data?.class_slots);
                    }
                    setMsgColor("information");
                    setMsg(res?.data?.message);
                    setShowSnackbar(true);
                } else {
                    setMsgColor("error");
                    setMsg(res?.data?.message);
                    setShowSnackbar(true);
                }
            })
            .catch((err) => {
                setMsgColor("error");
                setMsg(err?.message);
                setShowSnackbar(true);
            });
    };

    const removeClassSlot = (slotId) => {
        if (slotId) {
            axios
                .delete(`/api/v1/class/${sessionID}/slot/${slotId}`)
                .then((res) => {
                    if (res?.data?.success) {
                        if (sessionType === "Course") {
                            if (res?.data?.data?.class_slots.length > 0) {
                                handleNavClick(
                                    res?.data?.data?.class_slots[0]?._id
                                );
                            } else {
                                setRightPanelSettings([]);
                                setClassSlots([]);
                                setPanelSlotId(null);
                                setNavClick({});
                            }
                        } else {
                            setClassSlots(res?.data?.data?.class_slots);
                        }
                        setMsgColor("information");
                        setMsg("Session removed");
                        setShowSnackbar(true);
                    } else {
                        setMsgColor("error");
                        setMsg(res?.data?.message);
                        setShowSnackbar(true);
                    }
                })
                .catch((err) => {
                    setMsgColor("error");
                    setMsg(err?.message);
                    setShowSnackbar(true);
                });
        }
    };

    const handlePublish = async () => {
        let incompleteConfig = true;
        let config = false;
        if (rightPanelSettings) {
            config = await rightPanelSettings.every(
                (r) => r?.isConfigurationCompleted === true
            );
        } else {
            config = true;
        }

        let classFirstSlotTime = classSlots[0]
            ? moment
                  .utc(classSlots[0]?.configuration?.session_start_time)
                  .local()
                  .format("DD/MM/YYYY hh:mm a")
            : undefined;
        let classLastSlotTime = classSlots[classSlots.length - 1]
            ? moment
                  .utc(
                      classSlots[classSlots.length - 1]?.configuration
                          ?.session_start_time
                  )
                  .local()
                  .format("DD/MM/YYYY hh:mm a")
            : undefined;

        if (!publish) {
            const sessionData = {
                id: id,
                fee:
                    feeCurrency || feeAmount || feePlan
                        ? {
                              currency: "GBP",
                              amount: feeAmount,
                              plan: feePlan,
                          }
                        : undefined,
                recurringClasses,
                learningObjectives,
                maxStudents,
                ageGroup:
                    ageGroupFrom != undefined && ageGroupTo != undefined
                        ? `${ageGroupFrom}-${ageGroupTo}`
                        : undefined,
                sessionDuration: duration,
                selectedStudentIds: students,
                className: className,
                classDescription: description,
                session_type: publish ? "PUBLISHED" : "UNPUBLISHED",
                ...(sessionType === "Course"
                    ? {
                          startDate: classFirstSlotTime,
                          endDate: classLastSlotTime,
                          rightPanelSettingsSessionId: panelSlotId,
                      }
                    : {}),
            };

            if (
                ageGroupFrom === null ||
                ageGroupFrom === undefined ||
                !ageGroupTo ||
                ageGroupFrom >= ageGroupTo ||
                !sessionData.classDescription ||
                !sessionData.className ||
                !sessionData.learningObjectives.length ||
                !sessionData.maxStudents ||
                !sessionData.sessionDuration ||
                !config
                // isConfigurationCompletedValue === false
            ) {
                incompleteConfig = false;
            }
        }

        if (incompleteConfig) {
            setLoading(true);
            updateSession(
                null,
                () => {
                    setPublish(!publish);
                    setLoading(false);
                    if (publish) {
                        setMsg("Class has been removed from the Store+");
                        setShowSnackbar(true);
                    }
                },
                {
                    session_type: publish ? "UNPUBLISHED" : "PUBLISHED",
                }
            );
        } else if (parseInt(ageGroupFrom) >= parseInt(ageGroupTo)) {
            setMsg("Please select correct age group.");
            setShowSnackbar(true);
            setMsgColor("error");
        } else {
            setMsg(
                "This class cannot be published as some of your class details have not been completed."
            );
            setShowSnackbar(true);
            setMsgColor("error");
        }
    };

    const addLessionBlock = () => {
        const data =
            !!panelSlotId && sessionType === "Course"
                ? {
                      rightPanelSettingsSessionId: panelSlotId,
                  }
                : {};
        const rs = rightPanelSettings.concat([{}]);
        setRightPanelSettings(rs);
        sessionType === "Course"
            ? updateSessionDetails(rs, null, data)
            : setUpdateNewClass(true);
    };

    const removeLessionBlock = (index) => {
        const data =
            !!panelSlotId && sessionType === "Course"
                ? {
                      rightPanelSettingsSessionId: panelSlotId,
                  }
                : {};
        const rs = rightPanelSettings
            .slice(0, index)
            .concat(rightPanelSettings.slice(index + 1));
        setRightPanelSettings(rs);
        sessionType === "Course" && updateSessionDetails(rs, null, data);
    };

    const updateRightPanelSettings = (index, type, label, cb) => {
        const updatedRightPanel = rightPanelSettings.map((obj, i) => {
            if (index === i && (!obj.type || obj.type !== type)) {
                return {
                    label,
                    type,
                };
            } else {
                return obj;
            }
        });
        const data =
            !!panelSlotId && sessionType === "Course"
                ? {
                      rightPanelSettingsSessionId: panelSlotId,
                  }
                : {};
        setRightPanelSettings(updatedRightPanel);
        updateSessionDetails(updatedRightPanel, cb, data);
    };

    const durationData = [
        { label: "20 minutes", value: 20 },
        { label: "30 minutes", value: 30 },
        { label: "45 minutes", value: 45 },
        { label: "1 hour", value: 60 },
    ];
    const studentList = userList
        .filter((user) => user.role === "student")
        .map((obj) => {
            return {
                label: `${obj.username} (${obj.email})`,
                value: obj._id,
            };
        });

    const handleClassModal = (e) => {
        e.preventDefault();
        setModalClassDescription(description);
        setClassModal(true);
    };

    const handleClassModalClose = () => {
        setClassModal(false);
    };

    const handleMediaModal = () => {
        setMediaModal(false);
    };

    const handleOpenMediaModal = () => {
        setMediaModal(true);
    };

    const handleFeeInput = () => {
        const inputAmount = parseInt(localFeeAmount);
        let amount;

        amount =
            duration === 90
                ? 12
                : duration === 60
                ? 8
                : duration === 45
                ? 6
                : duration === 30
                ? 5
                : 4;
        if (inputAmount > amount) {
            amount = inputAmount;
        }

        setFeeAmount(amount);
        setLocalFeeAmount(amount);
    };

    const handleLocalFeeInput = (event) => {
        const inputAmount = parseInt(event.target.value);
        setLocalFeeAmount(inputAmount);
    };

    const onEditorStateChange = (editorState) => {
        let inputStr = editorState.target.value;
        // console.log(`----> ${inputStr}`);
        setModalClassDescription(inputStr);
    };

    const handleSave = () => {
        setClassName(modalClassName);

        const finalDescription = modalClassDescription.replace(
            /(?:\r\n|\r|\n)/g,
            " "
        );
        // console.log(finalDescription);
        setDescription(finalDescription);
        setClassModal(!classModal);
    };

    const handleImportOption = async (index) => {
        await axios
            .get("/api/v1/sessions")
            .then((result) => {
                if (result.data.success) {
                    setSessions(result.data.sessions);
                }
                setImportModal(true);
            })
            .catch((error) => {
                setPublish(false);
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setMsgColor("error");
                setTimeout(function () {
                    setMsg(null);
                    setMsgColor("");
                }, 3000);
            });
    };

    const handleImportModal = () => {
        setImportModal(false);
        setSelectClass(false);
    };

    const handleSelectClass = async (event, id) => {
        event.preventDefault();
        await axios
            .get("/api/v1/sessions")
            .then((result) => {
                if (result.data.success) {
                    const data = result.data.sessions.find(
                        (element, index) => index === id
                    );
                    setSessionData(data);
                }
                setSelectClass(true);
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setMsgColor("error");
                setTimeout(function () {
                    setMsg(null);
                    setMsgColor("");
                }, 3000);
            });
    };

    const inputFile = useRef(null);

    const handleImageUpload = () => {
        inputFile.current.click();
    };

    const fileSelected = (event) => {
        if (event.target.files.length > 0) {
            const extension = event.target.files[0].type
                .split("/")[1]
                .toUpperCase();
            const type = event.target.files[0].type.split("/")[0].toUpperCase();
            const file_size = event.target.files[0].size;
            if (
                (type === "IMAGE" || type === "VIDEO" || type === "AUDIO") &&
                (extension === "PNG" ||
                    extension === "JPEG" ||
                    extension === "MP4" ||
                    extension === "MP4A" ||
                    extension === "MP3")
            ) {
                if (file_size / 1000000 > 5) {
                    // console.log(file_size);
                    setMsg("Please select file lesser than 5 MB");
                    setShowSnackbar(true);
                    setMsgColor("error");
                    setTimeout(function () {
                        setMsg(null);
                        setMsgColor("");
                        setShowSnackbar(false);
                    }, 5000);
                } else {
                    handleUploadImage(event.target.files[0], type);
                }
            } else {
                setMsg("Invalid file type");
                setShowSnackbar(true);
                setMsgColor("error");
                setTimeout(function () {
                    setMsg(null);
                    setShowSnackbar(false);
                    setMsgColor("");
                }, 3000);
            }
        }
    };

    const handleUploadImage = async (fileData, type) => {
        if (fileData) {
            let formData = new FormData();
            formData.append("image", fileData);
            formData.append("session_id", sessionID);
            formData.append("type", type);
            await axios
                .post("/api/v1/user/gallery", formData)
                .then((result) => {
                    if (result.data.success) {
                        setGallery(result.data.data.sessionImages);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setMsgColor("error");
                    setTimeout(function () {
                        setMsg(null);
                        setMsgColor("");
                    }, 3000);
                });
        }
    };

    const decreaseFlag = () => {
        if (imgFlag > 0) {
            setImgFlag(imgFlag - 1);
        } else {
            setImgFlag(gallery.length - 1);
        }
    };

    const increaseFlag = () => {
        if (imgFlag < gallery.length - 1) {
            setImgFlag(imgFlag + 1);
        } else {
            setImgFlag(0);
        }
    };

    const decreaseModalFlag = () => {
        if (modalFlag !== 0) {
            setModalFlag(modalFlag - 1);
        }
    };

    const increaseModalFlag = () => {
        if (modalFlag < gallery.length - 3) {
            setModalFlag(modalFlag + 1);
        }
    };

    const handleGalleryImage = async (event, index) => {
        event.preventDefault();
        gallery.splice(index, 1);
        const sessionData = {
            id: id,
            gallery,
        };
        await axios
            .post("/api/v1/session", sessionData)
            .then((result) => {
                if (result.data.success) {
                    getUserGallery();
                } else {
                    setMsg(
                        _.get(result, "data.message", "Something went wrong.")
                    );
                    setShowSnackbar(true);
                    setMsgColor("error");
                    setTimeout(function () {
                        setMsg(null);
                        setMsgColor("");
                    }, 3000);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setMsgColor("error");
                setTimeout(function () {
                    setMsg(null);
                    setMsgColor("");
                }, 3000);
            });
    };

    const handleRightPanelSetting = (index) => {
        const rightPanelData =
            sessionData.configuration.right_panel_settings[index];
        const newArray = rightPanelSettings;
        newArray[rightPanelIndex] = rightPanelData;
        newArray[rightPanelIndex]._id =
            sessionData.configuration.right_panel_settings[rightPanelIndex]._id;
        setRightPanelSettings(newArray);
        setImportModal(false);
        setSelectClass(false);
    };

    const decreaseImgModalFlag = (length) => {
        if (imgModalFlag > 0) {
            setImgModalFlag(imgModalFlag - 1);
        } else {
            setImgModalFlag(length - 1);
        }
    };

    const increaseImgModalFlag = (length) => {
        if (imgModalFlag < length - 1) {
            setImgModalFlag(imgModalFlag + 1);
        } else {
            setImgModalFlag(0);
        }
    };

    const handleSavedFolder = () => {
        setSavedFolder(!savedFolder);
    };

    const handleCategories = (category) => {
        setCategory(category);
    };

    const handleSessionImage = async (obj) => {
        const data = {
            id: id,
            obj,
        };
        await axios
            .post("/api/v1/session/image", data)
            .then((result) => {
                if (result.data.success) {
                    setGallery(result.data.data.sessionImages);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setMsgColor("error");
                setTimeout(function () {
                    setMsg(null);
                    setMsgColor("");
                }, 3000);
            });
    };

    // Drag and drop

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            rightPanelSettings,
            result.source.index,
            result.destination.index
        );

        setRightPanelSettings(items);

        updateSession(items);
    };

    return (
        <div className={styles.classList}>
            {msg && (
                <Snackbar
                    status={msgColor || "warning"}
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                    duration={snackBarDuration}
                ></Snackbar>
            )}
            {pageloding && <PageLoader open={pageloding} />}
            {stripeModal && (
                <div
                    className={`${styles.uploadModal} text-center`}
                    style={{ top: "30%" }}
                >
                    <FontAwesomeIcon
                        className={styles.crossIcon}
                        icon={faTimes}
                        onClick={() => setStripeModal(false)}
                    />
                    <div className={styles.uploadAreaStripe}>
                        <p className={styles.modalHeading}>
                            Please complete your payment details before
                            publishing your class.
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: "/accountSettings",
                            state: { tab: "paymentDet" },
                        }}
                    >
                        <img src={paymentDetails} alt="Details" />
                    </Link>
                </div>
            )}
            <Row>
                <Col key={1} xs={9} sm={10} className={styles.leftsection}>
                    <div className={styles.leftSection}>
                        <div className={styles.leftSectionChild}>
                            <div>
                                <div className={styles.sessionblock}>
                                    <img
                                        onClick={async () => {
                                            await updateSession(
                                                null,
                                                null,
                                                !!panelSlotId
                                                    ? {
                                                          rightPanelSettingsSessionId:
                                                              panelSlotId,
                                                      }
                                                    : {}
                                            );
                                            history.push("/sessions");
                                        }}
                                        style={{
                                            cursor: "pointer",
                                            marginRight: "5px",
                                        }}
                                        src={CloseButton}
                                        alt="Logo"
                                    />
                                    {versionTime && (
                                        <p
                                            className={styles.italicPara}
                                            style={{ marginBottom: "0px" }}
                                        >
                                            Last version saved at {versionTime}
                                        </p>
                                    )}
                                </div>
                                <div className={styles.classheading}>
                                    <h1
                                        className={styles.heading}
                                        style={{
                                            margin: "0px",
                                            color: "#707070",
                                        }}
                                    >
                                        {sessionType === "Session"
                                            ? "Session details"
                                            : "Course details"}
                                    </h1>

                                    <span
                                        onClick={() => {
                                            if (!disableInputs) {
                                                // handlePublish();
                                                accountSubmitted || isSuperAdmin
                                                    ? handlePublish()
                                                    : !publish
                                                    ? setStripeModal(true)
                                                    : handlePublish();
                                            }
                                        }}
                                        className={styles.button}
                                    >
                                        {publish ? ` Unpublish ` : ` Publish `}
                                        {loading && (
                                            <CircularProgress
                                                color="inherit"
                                                size={18}
                                                thickness={5}
                                            />
                                        )}
                                    </span>
                                </div>

                                <div
                                    id="scrollContainer"
                                    className={styles.leftScroll}
                                >
                                    <div className={styles.dateTimeDuration}>
                                        <div
                                            className={
                                                styles.dateTimeDurationchild
                                            }
                                        >
                                            <p
                                                className={styles.className}
                                                style={{
                                                    margin: "0px",
                                                    marginRight: "15px",
                                                }}
                                            >
                                                {`Date(s) & time(s)`}
                                            </p>
                                            {/* <Calender
                                            maxDate={
                                                isClassFree &&
                                                new Date(
                                                    currentDateValue.getFullYear(),
                                                    currentDateValue.getMonth() +
                                                        1,
                                                    0
                                                )
                                            }
                                            value={startDate}
                                            onChange={(date) => {
                                                handleChange(date);
                                            }}
                                            width="350px"
                                            isPublished={publish}
                                        /> */}
                                            <DateAndTimeInput
                                                width="38rem"
                                                isClassFree={false}
                                                currentDateValue={
                                                    currentDateValue
                                                }
                                                startDate={startDate}
                                                publish={publish}
                                                handleChange={handleChange}
                                                startTime={startTime}
                                                setStartTime={setStartTime}
                                                classSlots={classSlots}
                                                classSlotsLength={
                                                    classSlots.length
                                                }
                                                addClassSlot={addClassSlot}
                                                removeClassSlot={
                                                    removeClassSlot
                                                }
                                                sessionType={sessionType}
                                            />
                                        </div>
                                        {/* <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <p
                                            className={styles.className}
                                            style={{
                                                margin: "0px",
                                                marginRight: "20px",
                                            }}
                                        >
                                            Time
                                        </p>
                                        <Duration
                                            isTime
                                            type="time"
                                            data={startTime}
                                            setData={setStartTime}
                                            width="200px"
                                            date={startDate}
                                            isPublished={publish}
                                        />
                                    </div> */}
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginTop: "25px",
                                            }}
                                        >
                                            <p
                                                className={styles.className}
                                                style={{
                                                    margin: "0px",
                                                    marginRight: "20px",
                                                }}
                                            >
                                                Duration
                                            </p>
                                            <CustomDropdown
                                                dropdownData={durationData}
                                                setData={handleSetDuration}
                                                data={duration}
                                                width="200px"
                                                isPublished={
                                                    // isClassFree ||
                                                    publish || disableInputs
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            marginTop: "2.5vh",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginBottom: "24px",
                                            }}
                                        >
                                            <p
                                                className={styles.className}
                                                style={{
                                                    margin: "0px",
                                                    marginRight: "20px",
                                                }}
                                            >
                                                {sessionType === "Course" &&
                                                    `Total Course `}
                                                {sessionType === "Course" && (
                                                    <br />
                                                )}
                                                Fee(per Student)
                                            </p>

                                            {!isClassFree ? (
                                                <>
                                                    {sessionType ===
                                                        "Session" && (
                                                        <button
                                                            className={
                                                                styles.freeSession
                                                            }
                                                            onClick={() =>
                                                                setIsClassFree(
                                                                    true
                                                                )
                                                            }
                                                            disabled={
                                                                publish ||
                                                                disableInputs
                                                            }
                                                        >
                                                            Free
                                                        </button>
                                                    )}
                                                    <CustomDropdown
                                                        width="100px"
                                                        data={feeCurrency}
                                                        setData={setFeeCurrency}
                                                        dropdownData={
                                                            feeCurrencyData
                                                        }
                                                        type="currency"
                                                        isPublished={true}
                                                    />
                                                    <div
                                                        className={
                                                            styles.moneyContainer
                                                        }
                                                    >
                                                        <input
                                                            type="number"
                                                            className={
                                                                styles.feeInput
                                                            }
                                                            placeholder={
                                                                classSlots.length *
                                                                    minAmount ||
                                                                minAmount
                                                            }
                                                            value={
                                                                localFeeAmount
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                !publish &&
                                                                    handleLocalFeeInput(
                                                                        event
                                                                    );
                                                            }}
                                                            onBlur={(event) => {
                                                                !publish &&
                                                                    handleFeeInput(
                                                                        event
                                                                    );
                                                            }}
                                                            disabled={
                                                                publish ||
                                                                isClassFree ||
                                                                disableInputs
                                                            }
                                                        />
                                                        {isClassFree ? null : (
                                                            <span
                                                                style={{
                                                                    position:
                                                                        "absolute",
                                                                    top: "3rem",
                                                                    minWidth:
                                                                        "116px",
                                                                    color:
                                                                        sessionType ===
                                                                        "Course"
                                                                            ? localFeeAmount <
                                                                                  classSlots.length *
                                                                                      minAmount &&
                                                                              "red"
                                                                            : localFeeAmount <
                                                                                  minAmount &&
                                                                              "red",
                                                                }}
                                                            >
                                                                {`Minimum  
                                                                ${getCurrencySymbol(
                                                                    feeCurrency
                                                                )}${
                                                                    sessionType ===
                                                                    "Course"
                                                                        ? classSlots.length *
                                                                          minAmount
                                                                        : minAmount
                                                                }
                                                                `}
                                                            </span>
                                                        )}
                                                    </div>

                                                    {
                                                        sessionType ===
                                                            "Course" && (
                                                            <div
                                                                className={
                                                                    styles.courseLengthBtn
                                                                }
                                                            >
                                                                <p>for</p>
                                                                <button>
                                                                    <span
                                                                        style={{
                                                                            marginRight:
                                                                                "8px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            classSlots.length
                                                                        }
                                                                    </span>
                                                                    <span>
                                                                        Sessions
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        )
                                                        // <CustomDropdown
                                                        //     width="100px"
                                                        //     data={planValue}
                                                        //     isPublished={true}
                                                        //     type="plan"
                                                        // />
                                                    }
                                                </>
                                            ) : (
                                                sessionType === "Session" && (
                                                    <button
                                                        className={clsx(
                                                            styles.freeSession,
                                                            styles.freeSessionClose
                                                        )}
                                                        onClick={() =>
                                                            setIsClassFree(
                                                                false
                                                            )
                                                        }
                                                        disabled={
                                                            publish ||
                                                            disableInputs
                                                        }
                                                    >
                                                        Free{" "}
                                                        <FontAwesomeIcon
                                                            style={{
                                                                marginLeft:
                                                                    "5px",
                                                                cursor: "pointer",
                                                            }}
                                                            icon={faTimes}
                                                        />
                                                    </button>
                                                )
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                display: "none",
                                                alignItems: "center",
                                            }}
                                        >
                                            <p
                                                className={styles.className}
                                                style={{
                                                    margin: "0px",
                                                    marginRight: "20px",
                                                }}
                                            >
                                                Recurring Class
                                            </p>
                                            <CustomDropdown
                                                dropdownData={
                                                    recurringClassesData
                                                }
                                                data={recurringClasses}
                                                setData={setRecurringClasses}
                                                width="220px"
                                            />
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <p
                                                className={styles.className}
                                                style={{
                                                    display: "grid",
                                                    margin: "0px",
                                                    marginRight: "20px",
                                                }}
                                            >
                                                Search/Filter tags
                                                <span
                                                    className={
                                                        styles.tagsDescription
                                                    }
                                                >
                                                    Please select 1-3 tags that
                                                    best describes your class
                                                </span>
                                            </p>
                                            <CustomSelectDropdown
                                                width="450px"
                                                setObjective={
                                                    handleLearningObjectives
                                                }
                                                tempData={
                                                    learningObjectivesData
                                                }
                                                learningObjectives={
                                                    learningObjectives
                                                }
                                                isPublished={
                                                    publish || disableInputs
                                                }
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: "none",
                                                alignItems: "center",
                                            }}
                                        >
                                            <p
                                                className={styles.className}
                                                style={{
                                                    margin: "0px",
                                                    marginRight: "20px",
                                                }}
                                            >
                                                Student Names
                                            </p>
                                            <CustomDropdown
                                                dropdownData={studentList}
                                                setData={setStudents}
                                                width="220px"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {sessionType === "Course" ? (
                                <div
                                    className={
                                        classSlots.length
                                            ? styles.btnNavContainer
                                            : ""
                                    }
                                >
                                    {classSlots?.map((course, index) => {
                                        return (
                                            <>
                                                <div
                                                    className={styles.btnNavbar}
                                                    key={index}
                                                >
                                                    <button
                                                        className={clsx(
                                                            styles.btnNav,
                                                            !!navClick[
                                                                course._id
                                                            ]
                                                                ? styles.updateBtnNav
                                                                : ""
                                                        )}
                                                        onClick={() =>
                                                            handleNavClick(
                                                                course._id
                                                            )
                                                        }
                                                    >
                                                        {moment
                                                            .utc(
                                                                course
                                                                    ?.configuration
                                                                    ?.session_start_time
                                                            )
                                                            .local()
                                                            .format("DD MMM")}
                                                    </button>

                                                    {!!navClick[course._id] && (
                                                        <button
                                                            style={{
                                                                color: "#88addb",
                                                            }}
                                                        >
                                                            {moment
                                                                .utc(
                                                                    course
                                                                        ?.configuration
                                                                        ?.session_start_time
                                                                )
                                                                .local()
                                                                .format(
                                                                    "HH:mm a"
                                                                )}
                                                        </button>
                                                    )}
                                                </div>
                                                {panelSlotId === course._id && (
                                                    <div
                                                        className={
                                                            styles.slotContainer
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                marginTop:
                                                                    "5vh",
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            <div>
                                                                {`Course title: ${
                                                                    className
                                                                        ? className
                                                                        : "Slot"
                                                                } ${index + 1}`}
                                                            </div>
                                                            <Panels
                                                                rightPanelSettings={
                                                                    rightPanelSettings
                                                                }
                                                                sessionType={
                                                                    sessionType
                                                                }
                                                                panelSlotId={
                                                                    panelSlotId
                                                                }
                                                                onDragEnd={
                                                                    onDragEnd
                                                                }
                                                                disableInputs={
                                                                    disableInputs
                                                                }
                                                                show={show}
                                                                sessions={
                                                                    sessions
                                                                }
                                                                updateSession={
                                                                    updateSession
                                                                }
                                                                setShow={
                                                                    setShow
                                                                }
                                                                sessionID={
                                                                    sessionID
                                                                }
                                                                setRightPanelIndex={
                                                                    setRightPanelIndex
                                                                }
                                                                removeLessionBlock={
                                                                    removeLessionBlock
                                                                }
                                                                selectClass={
                                                                    selectClass
                                                                }
                                                                sessionData={
                                                                    sessionData
                                                                }
                                                                importModal={
                                                                    importModal
                                                                }
                                                                handleImportModal={
                                                                    handleImportModal
                                                                }
                                                                handleImportOption={
                                                                    handleImportOption
                                                                }
                                                                handleRightPanelSetting={
                                                                    handleRightPanelSetting
                                                                }
                                                                addLessionBlock={
                                                                    addLessionBlock
                                                                }
                                                                imgModalFlag={
                                                                    imgModalFlag
                                                                }
                                                                increaseImgModalFlag={
                                                                    increaseImgModalFlag
                                                                }
                                                                decreaseImgModalFlag={
                                                                    decreaseImgModalFlag
                                                                }
                                                                handleSelectClass={
                                                                    handleSelectClass
                                                                }
                                                                updateRightPanelSettings={
                                                                    updateRightPanelSettings
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div style={{ marginTop: "5vh" }}>
                                    <Panels
                                        rightPanelSettings={rightPanelSettings}
                                        sessionType={sessionType}
                                        onDragEnd={onDragEnd}
                                        disableInputs={disableInputs}
                                        show={show}
                                        sessions={sessions}
                                        setShow={setShow}
                                        sessionID={sessionID}
                                        setRightPanelIndex={setRightPanelIndex}
                                        removeLessionBlock={removeLessionBlock}
                                        selectClass={selectClass}
                                        sessionData={sessionData}
                                        importModal={importModal}
                                        handleImportModal={handleImportModal}
                                        handleImportOption={handleImportOption}
                                        handleRightPanelSetting={
                                            handleRightPanelSetting
                                        }
                                        addLessionBlock={addLessionBlock}
                                        imgModalFlag={imgModalFlag}
                                        increaseImgModalFlag={
                                            increaseImgModalFlag
                                        }
                                        decreaseImgModalFlag={
                                            decreaseImgModalFlag
                                        }
                                        handleSelectClass={handleSelectClass}
                                        updateRightPanelSettings={
                                            updateRightPanelSettings
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
                <Col key={2} xs={3} sm={2} style={{ paddingLeft: "0px" }}>
                    <div
                        style={{ position: "relative", cursor: "pointer" }}
                        onClick={() => history.push("/home")}
                    >
                        <img src={Logo} className={styles.logoImg} alt="Logo" />
                        <h1 className={styles.logoText}>
                            plassroom<sup>+</sup>
                        </h1>
                        <p className={styles.logoSubText}>Teacher</p>
                        <h1 className={styles.rightTime}>
                            <Clock />
                        </h1>
                    </div>
                    <div className={styles.rightClassWrapper}>
                        {gallery.length > 1 && (
                            <>
                                <FontAwesomeIcon
                                    style={{
                                        color: "#83838b",
                                        width: "30px",
                                        position: "absolute",
                                        left: "-10px",
                                        marginTop: "9vh",
                                        zIndex: "8",

                                        cursor: "pointer",
                                    }}
                                    icon={faLessThan}
                                    onClick={decreaseFlag}
                                />
                                <FontAwesomeIcon
                                    style={{
                                        color: "#83838b",
                                        width: "30px",
                                        position: "absolute",
                                        right: "18px",
                                        marginTop: "9vh",
                                        zIndex: "8",
                                        cursor: "pointer",
                                    }}
                                    icon={faGreaterThan}
                                    onClick={increaseFlag}
                                />
                            </>
                        )}

                        <div className={styles.rightclassblock}>
                            {gallery.map((obj, index) => {
                                const fileType = obj.location.split(".").pop();
                                return (
                                    <>
                                        {fileType === "mp4"
                                            ? imgFlag === index && (
                                                  <video
                                                      className={
                                                          styles.coverImage
                                                      }
                                                      src={obj.location}
                                                      controls
                                                  ></video>
                                              )
                                            : imgFlag === index && (
                                                  <img
                                                      className={
                                                          styles.coverImage
                                                      }
                                                      src={obj.location}
                                                      alt=""
                                                  />
                                              )}
                                    </>
                                );
                            })}
                            {!publish && !disableInputs && (
                                <FontAwesomeIcon
                                    className={styles.plusicon}
                                    style={{
                                        width: "30px",
                                        position: "absolute",
                                        right: "10px",
                                        marginTop: "10px",
                                    }}
                                    icon={faPlus}
                                    onClick={handleOpenMediaModal}
                                />
                            )}
                        </div>
                        {mediaModal && (
                            <div className={styles.classModal2}>
                                <FontAwesomeIcon
                                    className={styles.crossIcon}
                                    icon={faTimes}
                                    onClick={handleMediaModal}
                                />
                                {savedFolder ? (
                                    <div className={styles.uploadArea}>
                                        <div className={styles.mediaType}>
                                            <p
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    handleCategories("IMAGE");
                                                }}
                                            >
                                                Image
                                            </p>
                                            <p
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    handleCategories("VIDEO");
                                                }}
                                            >
                                                Video
                                            </p>
                                            <p
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    handleCategories("AUDIO");
                                                }}
                                            >
                                                Audio
                                            </p>
                                        </div>
                                        <div className={styles.mediaContent}>
                                            <Row>
                                                {userGallery.map(
                                                    (obj, index) => {
                                                        const type = obj.type;
                                                        if (
                                                            type === "VIDEO" &&
                                                            type === category
                                                        ) {
                                                            return (
                                                                <Col
                                                                    key={index}
                                                                    md={3}
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.mediaBlockModal
                                                                        }
                                                                        onClick={() => {
                                                                            handleSessionImage(
                                                                                obj
                                                                            );
                                                                        }}
                                                                    >
                                                                        <video
                                                                            className={
                                                                                styles.modalCoverImage
                                                                            }
                                                                            src={
                                                                                obj.location
                                                                            }
                                                                            controls
                                                                        ></video>
                                                                    </div>
                                                                </Col>
                                                            );
                                                        } else if (
                                                            type === "AUDIO" &&
                                                            type === category
                                                        ) {
                                                            return (
                                                                <Col
                                                                    key={index}
                                                                    md={3}
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.mediaBlockModal
                                                                        }
                                                                        onClick={() => {
                                                                            handleSessionImage(
                                                                                obj
                                                                            );
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.assets
                                                                            }
                                                                            src={
                                                                                Note
                                                                            }
                                                                            onClick={() => {
                                                                                // controlAudio();
                                                                            }}
                                                                            alt=""
                                                                        />
                                                                        <audio
                                                                            id="myAudio"
                                                                            style={{
                                                                                width: "100%",
                                                                                display:
                                                                                    "none",
                                                                            }}
                                                                            controls
                                                                            src={
                                                                                obj.location
                                                                            }
                                                                        ></audio>
                                                                    </div>
                                                                </Col>
                                                            );
                                                        } else if (
                                                            type === "IMAGE" &&
                                                            type === category
                                                        ) {
                                                            return (
                                                                <Col
                                                                    key={index}
                                                                    md={3}
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.mediaBlockModal
                                                                        }
                                                                        onClick={() => {
                                                                            handleSessionImage(
                                                                                obj
                                                                            );
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.assets
                                                                            }
                                                                            src={
                                                                                obj.location
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            );
                                                        }
                                                        return null;
                                                    }
                                                )}
                                            </Row>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                marginTop: "5%",
                                            }}
                                        >
                                            <h1
                                                onClick={() => {
                                                    setSavedFolder(false);
                                                }}
                                                className={
                                                    styles.savedFolderBtn
                                                }
                                            >
                                                Back
                                            </h1>
                                            <h1
                                                onClick={() => {
                                                    setSavedFolder(false);
                                                }}
                                                className={
                                                    styles.savedFolderBtn
                                                }
                                            >
                                                Next
                                            </h1>
                                        </div>
                                    </div>
                                ) : !toggleShowMore ? (
                                    <>
                                        <div className={styles.classWrapper}>
                                            <div
                                                style={{ outline: "none" }}
                                                {...getRootProps({
                                                    className: "dropzone",
                                                })}
                                            >
                                                {!gallery.length && (
                                                    <>
                                                        <p
                                                            className={
                                                                styles.upperText
                                                            }
                                                        >
                                                            Drag and drop your
                                                            image or
                                                        </p>
                                                        <p
                                                            className={
                                                                styles.upperText
                                                            }
                                                        >
                                                            video files here{" "}
                                                        </p>
                                                        <p
                                                            className={
                                                                styles.upperText
                                                            }
                                                        >
                                                            or
                                                        </p>
                                                    </>
                                                )}

                                                <div
                                                    className={
                                                        styles.uploadMediaModal
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.uploadBtn
                                                        }
                                                        onClick={
                                                            handleImageUpload
                                                        }
                                                    >
                                                        <p
                                                            className={
                                                                styles.toolText
                                                            }
                                                        >
                                                            Upload From Computer
                                                        </p>
                                                        <FontAwesomeIcon
                                                            className={
                                                                styles.uploadIcon
                                                            }
                                                            style={{
                                                                width: "25px",
                                                            }}
                                                            icon={faPlus}
                                                        />
                                                        <input
                                                            type="file"
                                                            id="file"
                                                            ref={inputFile}
                                                            style={{
                                                                display: "none",
                                                            }}
                                                            onChange={
                                                                fileSelected
                                                            }
                                                            accept="audio/*,video/*,image/*"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        styles.uploadMediaModal
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.uploadBtn
                                                        }
                                                        onClick={
                                                            handleSavedFolder
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            className={
                                                                styles.uploadIcon
                                                            }
                                                            style={{
                                                                width: "25px",
                                                            }}
                                                            icon={faPlus}
                                                        />
                                                        <p
                                                            className={
                                                                styles.toolText
                                                            }
                                                        >
                                                            Select from Saved
                                                            Folder
                                                        </p>
                                                    </div>
                                                </div>

                                                {gallery.length !== 0 && (
                                                    <div
                                                        className={
                                                            styles.modalGalleryWrapper
                                                        }
                                                    >
                                                        {gallery.length > 3 && (
                                                            <>
                                                                <FontAwesomeIcon
                                                                    style={{
                                                                        color: "#83838b",
                                                                        width: "30px",
                                                                        position:
                                                                            "absolute",
                                                                        left: "-2px",
                                                                        marginTop:
                                                                            "10px",
                                                                        zIndex: "8",
                                                                        top: "30px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    icon={
                                                                        faLessThan
                                                                    }
                                                                    onClick={
                                                                        decreaseModalFlag
                                                                    }
                                                                />
                                                                <FontAwesomeIcon
                                                                    style={{
                                                                        color: "#83838b",
                                                                        width: "30px",
                                                                        position:
                                                                            "absolute",
                                                                        right: "-7px",
                                                                        marginTop:
                                                                            "10px",
                                                                        zIndex: "8",
                                                                        top: "30px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    icon={
                                                                        faGreaterThan
                                                                    }
                                                                    onClick={
                                                                        increaseModalFlag
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                        {gallery
                                                            .slice(
                                                                modalFlag,
                                                                modalFlag + 3
                                                            )
                                                            .map(
                                                                (
                                                                    obj,
                                                                    index
                                                                ) => {
                                                                    const fileType =
                                                                        obj.location
                                                                            .split(
                                                                                "."
                                                                            )
                                                                            .pop();
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            className={
                                                                                styles.modalImageWrapper
                                                                            }
                                                                        >
                                                                            {fileType ===
                                                                            "mp4" ? (
                                                                                <video
                                                                                    className={
                                                                                        styles.modalImageGallery
                                                                                    }
                                                                                    src={
                                                                                        obj.location
                                                                                    }
                                                                                    controls
                                                                                ></video>
                                                                            ) : (
                                                                                <img
                                                                                    className={
                                                                                        styles.modalImageGallery
                                                                                    }
                                                                                    src={
                                                                                        obj.location
                                                                                    }
                                                                                    alt=""
                                                                                />
                                                                            )}

                                                                            <FontAwesomeIcon
                                                                                className={
                                                                                    styles.modalCross
                                                                                }
                                                                                style={{
                                                                                    width: "25px",
                                                                                }}
                                                                                icon={
                                                                                    faTimes
                                                                                }
                                                                                onClick={(
                                                                                    event
                                                                                ) => {
                                                                                    handleGalleryImage(
                                                                                        event,
                                                                                        index
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <p
                                            style={{
                                                fontSize: "13px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Want to know which types of files
                                            are supported?{" "}
                                            <span
                                                style={{
                                                    color: "#88ADDB",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    setToggleShowMore(true)
                                                }
                                            >
                                                Read more
                                            </span>
                                        </p>
                                        <h1
                                            className={styles.saveBtn}
                                            onClick={() => {
                                                setMediaModal(false);
                                            }}
                                        >
                                            Finish
                                        </h1>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className={`${styles.classWrapper} ${styles.uploadMediaModal}`}
                                        >
                                            <p>Types of files you can upload</p>

                                            <p>
                                                <div>Video: MP4</div>
                                                <div>Audio: MP4A, MP3</div>
                                                <div>Images: PNG, JPEG</div>
                                            </p>

                                            <p>
                                                Size of each file is less than
                                                5MB
                                            </p>
                                        </div>
                                        <h1
                                            className={styles.saveBtn}
                                            onClick={() =>
                                                setToggleShowMore(false)
                                            }
                                            style={{ float: "left" }}
                                        >
                                            Back
                                        </h1>
                                    </>
                                )}
                            </div>
                        )}
                        <div className={styles.aboutClass}>
                            <div
                                onClick={(event) => {
                                    !publish &&
                                        !disableInputs &&
                                        handleClassModal(event);
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                {/* <input
                                    className={styles.classDescription}
                                    style={{ marginTop: "10px" }}
                                    placeholder="Name of Class"
                                    value={className}
                                    type="text"
                                    disabled
                                /> */}
                                <div
                                    className={styles.classTitle}
                                    style={{ marginTop: "10px" }}
                                >
                                    {className}
                                </div>
                                {description ? (
                                    <div
                                        style={{
                                            color: "#707070",
                                            fontFamily: "RobotoMedium",
                                        }}
                                        className={styles.classDescription}
                                        disabled
                                        data-text="Enter text here"
                                        dangerouslySetInnerHTML={{
                                            __html: description,
                                        }}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            color: "#707070",
                                            fontFamily: "RobotoMediumItalic",
                                            pointerEvents: "none",
                                        }}
                                        disabled
                                        className={styles.nameAndDescription}
                                    >
                                        <p>Name of Class &#38; Description</p>
                                        <FontAwesomeIcon
                                            className={styles.dropicon}
                                            icon={faAngleDown}
                                            id="downarrow"
                                        />
                                    </div>
                                )}
                            </div>

                            <div>
                                <p
                                    className={styles.className}
                                    style={{
                                        marginBottom: "5px",
                                        marginTop: "20px",
                                    }}
                                >
                                    Max. no. of students
                                </p>
                                <CustomDropdown
                                    dropdownData={maxStudentsData}
                                    setData={setMaxStudents}
                                    data={maxStudents}
                                    isPublished={publish || disableInputs}
                                />
                                <p
                                    className={styles.className}
                                    style={{
                                        marginTop: "20px",
                                        marginBottom: "2px",
                                    }}
                                >
                                    Age group
                                </p>
                                <div
                                    className={`d-flex align-items-center ${styles.ageGroupRow}`}
                                >
                                    <div style={{ width: "40%" }}>
                                        <CustomDropdown
                                            dropdownData={ageGroupDataFrom}
                                            setData={setAgeGroupFrom}
                                            data={ageGroupFrom}
                                            isPublished={
                                                publish || disableInputs
                                            }
                                            customDropdownStyle={{
                                                height: "150px",
                                                overflowY: "scroll",
                                            }}
                                        />
                                    </div>
                                    <span
                                        style={{
                                            marginLeft: "5%",
                                        }}
                                    >
                                        to
                                    </span>
                                    <div
                                        style={{
                                            marginLeft: "5%",
                                            width: "40%",
                                        }}
                                    >
                                        <CustomDropdown
                                            dropdownData={ageGroupDataTo}
                                            setData={setAgeGroupTo}
                                            data={ageGroupTo}
                                            isPublished={
                                                publish || disableInputs
                                            }
                                            customDropdownStyle={{
                                                height: "150px",
                                                overflowY: "scroll",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {classModal && (
                            <div className={styles.classModal}>
                                <FontAwesomeIcon
                                    className={styles.crossIcon}
                                    icon={faTimes}
                                    onClick={handleClassModalClose}
                                />
                                <div className={styles.classWrapper}>
                                    <div className={styles.classScroll}>
                                        <h5>Name of Class:</h5>
                                        <input
                                            className={styles.classTitle}
                                            type="text"
                                            style={{
                                                marginTop: "10px",
                                                borderBottom:
                                                    "1px solid #707070",
                                            }}
                                            value={modalClassName}
                                            onChange={handleClassName}
                                        />
                                        <span>
                                            {modalClassName?.length || 0}/40
                                        </span>

                                        <h5>Brief description:</h5>
                                        <div
                                            className={
                                                styles.classWrapperEditor
                                            }
                                        >
                                            <textarea
                                                className={
                                                    styles.chat_form__message_box
                                                }
                                                onChange={onEditorStateChange}
                                                value={modalClassDescription}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <h1
                                    className={styles.saveBtn}
                                    onClick={handleSave}
                                >
                                    Save
                                </h1>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};
