import _ from "lodash";
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import reviewStaticData from "./reviews.js";
import moment from "moment";
import { ratingStar } from "../../../utils/Ratting/rating";
import Pagination from "../../../routes/GuestUser/components/Pagination";

const ReviewCarouselClass = ({
    carouselImgs,
    onClickEvent,
    specialClass,
    autoPlay,
    controls,
    playOnHover,
    smallCarousel,
    ratingAndReviews,
}) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [reviewData, setReviewData] = useState([]);
    const rowsPerPage = 3;
    const [page, setPage] = useState(1);
    const handleChangePage = (newPage) => {
        setPage(newPage);
        handleClickScroll();
    };

    const handleClickScroll = () => {
        const element = document.getElementById("section-1");
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        ratingAndReviews
            ? setReviewData(ratingAndReviews)
            : setReviewData(reviewStaticData);
        // eslint-disable-next-line
    }, [ratingAndReviews]);

    return (
        <div className={styles.container} id="section-1">
            <h4 className={styles.heading}>Parent Reviews</h4>
            {reviewData?.length > 0 &&
                reviewData
                    ?.slice(
                        (page - 1) * rowsPerPage,
                        (page - 1) * rowsPerPage + rowsPerPage
                    )
                    ?.map((caroData, caroIdx) => {
                        return (
                            <div
                                className={`${styles.section}`}
                                key={caroIdx}
                                style={{
                                    background:
                                        caroIdx % 2 === 0 ? "#e9f3fb" : "#fff",
                                }}
                            >
                                <p>{JSON.stringify(caroData?.review)}</p>
                                <p style={{ color: "#88ADDB" }}>{`${
                                    caroData?.parent_id?.preferedName
                                }, ${
                                    caroData?.session_id?.class_name
                                }, ${moment(caroData?.createdAt).format(
                                    "ddd MMM D"
                                )}`}</p>
                                <div className={styles.classRating}>
                                    {ratingStar(
                                        _.get(caroData, "teacher_rate", 0)
                                    )}
                                </div>
                            </div>
                        );
                    })}
            <div className={styles.paginationContainer}>
                <Pagination
                    paginationInfo={{
                        totalPages: Math.ceil(reviewData?.length / rowsPerPage),
                        currentPage: page,
                    }}
                    setPageNumber={handleChangePage}
                />
            </div>
        </div>
    );
};
export default ReviewCarouselClass;
