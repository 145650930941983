import {
    faPause,
    faPlay,
    faVolumeMute,
    faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import styles from "./VideoPlayer.module.css";

export default ({ source, isSelectedVid, top, isStudent }) => {
    const videoContainer = useRef();
    const video = useRef();
    const videoControls = useRef();
    const playpause = useRef();
    const mute = useRef();
    const progress = useRef();
    const volume = useRef();
    const controlsRow = useRef();
    const [playPauseIcon, setPlayPauseIcon] = useState(faPlay);
    const [volumeIcon, setVolumeIcon] = useState(faVolumeUp);

    useEffect(() => {
        const videoContainerCopy = videoContainer;
        const videoCopy = video;
        const playpauseCopy = playpause;
        const muteCopy = mute;
        const progressCopy = progress;
        const volumeCopy = volume;

        var supportsVideo = !!document.createElement("video").canPlayType;
        if (supportsVideo) {
            if (isSelectedVid) videoControls.current.style.display = "flex";
            else videoControls.current.style.display = "none";
            var handlePlayPause = () => {
                if (video.current.paused || video.current.ended) {
                    video.current.play();
                    setPlayPauseIcon(faPause);
                } else {
                    video.current.pause();
                    setPlayPauseIcon(faPlay);
                }
            };
            var handleMute = () => {
                video.current.muted = !video.current.muted;
                if (video.current.muted) {
                    setVolumeIcon(faVolumeMute);
                    volume.current.value = 0;
                } else {
                    setVolumeIcon(faVolumeUp);
                    volume.current.value = 1;
                }
            };
            var setMaxDuration = () => {
                progress.current.setAttribute("max", video.current.duration);
            };
            var handleVideoTimeUpdate = () => {
                progress.current.value = video.current.currentTime;
            };
            var handleVolumeChange = () => {
                video.current.volume = volume.current.value;
            };
            var handleProgressChange = () => {
                video.current.currentTime = progress.current.value;
            };
            var handleMouseEnter = () => {
                videoControls.current.style.visibility = "visible";
            };
            var handleMouseLeave = () => {
                videoControls.current.style.visibility = "hidden";
            };
            playpause.current.addEventListener("click", handlePlayPause);
            mute.current.addEventListener("click", handleMute);
            video.current.addEventListener("loadedmetadata", setMaxDuration);
            video.current.addEventListener("timeupdate", handleVideoTimeUpdate);
            volume.current.addEventListener("change", handleVolumeChange);
            progress.current.addEventListener("change", handleProgressChange);
            videoContainer.current.addEventListener(
                "mouseenter",
                handleMouseEnter
            );
            videoContainer.current.addEventListener(
                "mouseleave",
                handleMouseLeave
            );
        }
        const handleControlsWidth = () => {
            videoControls.current.style.setProperty(
                "width",
                `${Math.round(0.9 * video.current?.offsetWidth)}px`
            );
        };
        window.addEventListener("resize", handleControlsWidth);
        video.current.addEventListener("loadeddata", handleControlsWidth);
        return () => {
            window.removeEventListener("resize", handleControlsWidth);
            playpauseCopy.current.removeEventListener("click", handlePlayPause);
            muteCopy.current.removeEventListener("click", handleMute);
            videoCopy.current.removeEventListener(
                "loadedmetadata",
                setMaxDuration
            );
            videoCopy.current.removeEventListener(
                "timeupdate",
                handleVideoTimeUpdate
            );
            volumeCopy.current.removeEventListener(
                "change",
                handleVolumeChange
            );
            progressCopy.current.removeEventListener(
                "change",
                handleProgressChange
            );
            videoContainerCopy.current.removeEventListener(
                "mouseenter",
                handleMouseEnter
            );
            videoContainerCopy.current.removeEventListener(
                "mouseleave",
                handleMouseLeave
            );
            videoCopy.current.removeEventListener(
                "loadeddata",
                handleControlsWidth
            );
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div
                id="videoContainer"
                ref={videoContainer}
                className={styles.videoContainer}
            >
                <video
                    id="video"
                    preload="auto"
                    className={`${
                        isSelectedVid ? "" : isStudent ? "" : styles.assets
                    } ${isSelectedVid ? styles.video : ""}`}
                    ref={video}
                >
                    <source src={`${source}`} type="video/mp4"></source>
                </video>
                <div
                    id="video-controls"
                    className={`${styles.controls}`}
                    style={{ top: top }}
                    ref={videoControls}
                >
                    <Row
                        className={`${styles.rowCentered} ${styles.controlsRow}`}
                        ref={controlsRow}
                    >
                        <span
                            id="playpause"
                            ref={playpause}
                            className={styles.button}
                        >
                            <FontAwesomeIcon
                                icon={playPauseIcon}
                                className={styles.button}
                            />
                        </span>
                        <span className={styles.progressContainer}>
                            <input
                                type="range"
                                min="0"
                                step="0.001"
                                className={`${styles.progressSlider} ${styles.slider}`}
                                id="time-slider"
                                ref={progress}
                            ></input>
                        </span>
                        <span ref={mute}>
                            <FontAwesomeIcon
                                icon={volumeIcon}
                                className={styles.button}
                            />
                        </span>
                        <span className={styles.volContainer}>
                            <input
                                type="range"
                                min="0"
                                max="1"
                                step="0.001"
                                className={`${styles.volSlider} ${styles.slider}`}
                                id="volume-slider"
                                ref={volume}
                            ></input>
                        </span>
                    </Row>
                </div>
            </div>
        </>
    );
};
