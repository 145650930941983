import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";

const Pagination = ({ paginationInfo, setPageNumber }) => {
    const [pagesArray, setPagesArray] = useState([]);
    const [showLessButton, setShowLessButton] = useState([]);
    const [showMoreButton, setShowMoreButton] = useState([]);

    useEffect(() => {
        const currentPage = paginationInfo.currentPage;
        const totalPages = paginationInfo.totalPages;
        const pagesToShow = [];

        let firstPage = currentPage;
        let lastPage = firstPage + 5;

        if (lastPage > totalPages) {
            lastPage = totalPages;
            firstPage = lastPage - 5 > 0 ? lastPage - 5 : 1;
        }

        for (let i = firstPage; i <= lastPage; i++) {
            pagesToShow.push(i);
        }
        setPagesArray(pagesToShow);

        setShowLessButton(firstPage !== 1);
        setShowMoreButton(lastPage < totalPages);
        // eslint-disable-next-line
    }, [paginationInfo]);

    const handleShowMorePages = () => {
        let pagesToShow = [...pagesArray];
        const totalPages = paginationInfo.totalPages;

        if (pagesToShow.at(-1) < totalPages) {
            setShowLessButton(true);
            pagesToShow.shift();
            pagesToShow.push(pagesToShow.at(-1) + 1);
            setPagesArray(pagesToShow);
            setShowMoreButton(pagesToShow.at(-1) !== totalPages);
        }
    };

    const handleShowLessPages = () => {
        let pagesToShow = [...pagesArray];

        if (pagesToShow[0] > 1) {
            setShowMoreButton(true);
            pagesToShow = [pagesToShow[0] - 1, ...pagesToShow];
            pagesToShow.pop();

            setPagesArray(pagesToShow);
            setShowLessButton(pagesToShow[0] > 1);
        }
    };

    return (
        <div className={styles.paginationContainer}>
            <div
                className={styles.lessContainer}
                style={{
                    color: showLessButton ? "black" : "gray",
                    cursor: showLessButton ? "pointer" : "default",
                }}
            >
                <div
                    className={styles.lessContainerData}
                    onClick={handleShowLessPages}
                >
                    {`<`}
                </div>
                <div
                    className={styles.lessContainerData}
                    style={{
                        display: showLessButton ? "default" : "none",
                    }}
                >
                    ...
                </div>
            </div>

            <div className={styles.pageNumberContainer}>
                {pagesArray.map((page, index) => {
                    return (
                        <div
                            className={`${styles.pageNumber} ${
                                page === paginationInfo.currentPage
                                    ? styles.activePage
                                    : null
                            }`}
                            onClick={() =>
                                paginationInfo.currentPage !== page
                                    ? setPageNumber(page)
                                    : null
                            }
                            key={index}
                        >
                            {page}
                        </div>
                    );
                })}
            </div>

            <div
                className={styles.moreContainer}
                style={{
                    color: showMoreButton ? "black" : "gray",
                    cursor: showMoreButton ? "pointer" : "default",
                }}
            >
                <div
                    className={styles.moreContainerData}
                    style={{
                        display: showMoreButton ? "default" : "none",
                    }}
                >
                    ...
                </div>
                <div
                    className={styles.moreContainerData}
                    onClick={handleShowMorePages}
                >
                    {`>`}
                </div>
            </div>
        </div>
    );
};

export default Pagination;
