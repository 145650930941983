import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fade, Modal } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { ImageFormats, VideoFormats } from "../../utils/AcceptedFileFormats";
import styles from "./MessageImageModal.module.scss";

const MessageImageModal = ({
    isModalOpen,
    setIsModalOpen,
    currentIndex,
    setCurrentIndex,
    files,
}) => {
    const [filesToDisplay, setFilesToDisplay] = useState({
        index: -1,
        files: [],
    });

    /**
     * @description Handle selected files to display in the grid of 3.
     */
    useEffect(() => {
        handleImagesToDisplay();

        // eslint-disable-next-line
    }, [files]);

    const handleImagesToDisplay = (status) => {
        let localFilesToDisplay;
        const flag = status ? filesToDisplay.index : -1;

        localFilesToDisplay =
            status === "prev"
                ? files.slice(flag - 1, flag + 3)
                : files.slice(flag + 1, flag + 5);

        setFilesToDisplay({
            index: status === "prev" ? flag - 1 : flag + 1,
            files: localFilesToDisplay,
        });
    };

    /**
     * @description Select the image to display in modal.
     */
    const handleSelect = (selectedIndex, e) => {
        setCurrentIndex(selectedIndex);
    };

    return (
        <Modal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            className={styles.ImageModal}
        >
            <Fade in={isModalOpen}>
                <div className={styles.ImageModalBody}>
                    <Carousel
                        activeIndex={currentIndex}
                        onSelect={handleSelect}
                        className={styles.CarouselWrapper}
                    >
                        {files.map((file, index) => {
                            const fileFormat = _.last(
                                _.split(_.get(file, "location", ""), ".")
                            ).toUpperCase();

                            return (
                                <Carousel.Item
                                    className={styles.CarouselItem}
                                    key={index}
                                >
                                    {[...ImageFormats].includes(fileFormat) ? (
                                        <img
                                            src={_.get(file, "location")}
                                            alt="Selected Item"
                                        />
                                    ) : (
                                        <video
                                            src={_.get(file, "location")}
                                            alt="Selected Item"
                                            controls
                                        />
                                    )}
                                </Carousel.Item>
                            );
                        })}
                    </Carousel>

                    <div
                        className={styles.ImageInputModalSelectedFilesContainer}
                    >
                        <span>
                            <FontAwesomeIcon
                                icon={faChevronLeft}
                                className={`${styles.navigationIcon} ${
                                    _.get(filesToDisplay, "index") > 0 &&
                                    _.get(files, "length") > 4
                                        ? null
                                        : styles.zeroOpacity
                                }`}
                                onClick={() =>
                                    _.get(filesToDisplay, "index") > 0 &&
                                    _.get(files, "length") > 4
                                        ? handleImagesToDisplay("prev")
                                        : null
                                }
                            />
                        </span>

                        <div className={styles.imagesListContainer}>
                            {_.get(filesToDisplay, "files", []).map(
                                (file, index) => {
                                    const fileFormat = _.last(
                                        _.split(
                                            _.get(file, "location", ""),
                                            "."
                                        )
                                    ).toUpperCase();

                                    return [
                                        ...ImageFormats,
                                        ...VideoFormats,
                                    ].includes(fileFormat) ? (
                                        <div
                                            className={styles.imageContainer}
                                            key={index}
                                            onClick={() => {
                                                const fileIndex =
                                                    files.findIndex(
                                                        (individualFile) => {
                                                            return (
                                                                file._id ===
                                                                individualFile._id
                                                            );
                                                        }
                                                    );
                                                handleSelect(fileIndex);
                                            }}
                                        >
                                            {[...ImageFormats].includes(
                                                fileFormat
                                            ) ? (
                                                <img
                                                    src={_.get(
                                                        file,
                                                        "location"
                                                    )}
                                                    alt="Selected Item"
                                                />
                                            ) : (
                                                <video
                                                    src={_.get(
                                                        file,
                                                        "location"
                                                    )}
                                                    alt="Selected Item"
                                                />
                                            )}
                                        </div>
                                    ) : null;
                                }
                            )}
                        </div>

                        <span>
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className={`${styles.navigationIcon} ${
                                    filesToDisplay.index !== files.length - 4 &&
                                    _.get(files, "length") > 4
                                        ? null
                                        : styles.zeroOpacity
                                }`}
                                onClick={() =>
                                    filesToDisplay.index !== files.length - 4 &&
                                    _.get(files, "length") > 4
                                        ? handleImagesToDisplay("next")
                                        : null
                                }
                            />
                        </span>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default MessageImageModal;
