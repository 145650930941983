import { faRedo, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { DraggableCore } from "react-draggable";
import Snackbar from "../../../../../components/Snackbar";
import styles from "./Game.module.scss";
import { useHistory } from "react-router-dom";

export default ({
    currentPanel,
    session,
    setIsConfigComplete,
    studentView,
}) => {
    const history = useHistory();
    const userName = "teacher";
    const blockType = 8;
    const selectedUserId = session.teacher_id;
    const userId = JSON.parse(localStorage.getItem("auth")).user._id;
    const fiveShapes = [
        {
            className: styles.circle,
            x: 75,
            y: 100,
            height: 75,
            width: 75,
            ref: null,
            id: 0,
            color: "#D94E43",
            borderColor: "none",
        },
        {
            className: styles.circle,
            x: 225,
            y: 112.5,
            height: 37.5,
            width: 37.5,
            ref: null,
            id: 1,
            color: "#FCB648",
            borderColor: "none",
        },
        {
            x: 100,
            y: 225,
            height: 25,
            width: 150,
            className: styles.rect,
            ref: null,
            id: 2,
            color: "#88ADDB",
            borderColor: "none",
        },
        {
            x: 75,
            y: 300,
            height: 75,
            width: 75,
            className: styles.rect,
            ref: null,
            id: 3,
            color: "#43968C",
            borderColor: "none",
        },
        {
            x: 200,
            y: 300,
            height: 75,
            width: 75,
            className: styles.triup,
            ref: null,
            id: 4,
            color: "none",
            borderColor: "#13385C",
        },
    ];
    const eightShapes = [
        {
            className: styles.circle,
            x: 75,
            y: 75,
            height: 75,
            width: 75,
            ref: null,
            id: 0,
            color: "#D94E43",
            borderColor: "none",
        },
        {
            className: styles.circle,
            x: 225,
            y: 87.5,
            height: 37.5,
            width: 37.5,
            ref: null,
            id: 1,
            color: "#FCB648",
            borderColor: "none",
        },
        {
            x: 75,
            y: 175,
            height: 75,
            width: 75,
            className: styles.rect,
            ref: null,
            id: 2,
            color: "#43968C",
            borderColor: "none",
        },
        {
            x: 225,
            y: 175,
            height: 37.5,
            width: 37.5,
            className: styles.tridown,
            ref: null,
            id: 3,
            color: "none",
            borderColor: "#7A559F",
        },
        {
            x: 75,
            y: 275,
            height: 150,
            width: 25,
            className: styles.rect,
            ref: null,
            id: 4,
            color: "#88ADDB",
            borderColor: "none",
        },
        {
            x: 175,
            y: 225,
            height: 75,
            width: 75,
            className: styles.triup,
            ref: null,
            id: 5,
            color: "none",
            borderColor: "#13385C",
        },
        {
            x: 150,
            y: 325,
            height: 75,
            width: 150,
            className: styles.rect,
            ref: null,
            id: 6,
            color: "#482A2D",
            borderColor: "none",
        },
        {
            x: 112.5,
            y: 412.5,
            height: 25,
            width: 150,
            className: styles.rect,
            ref: null,
            id: 7,
            color: "#C8E8E5",
            borderColor: "none",
        },
    ];
    const tenShapes = [
        {
            className: styles.circle,
            x: 75,
            y: 75,
            height: 75,
            width: 75,
            ref: null,
            id: 0,
            color: "#D94E43",
            borderColor: "none",
        },
        {
            className: styles.circle,
            x: 225,
            y: 87.5,
            height: 37.5,
            width: 37.5,
            ref: null,
            id: 1,
            color: "#FCB648",
            borderColor: "none",
        },
        {
            className: styles.circle,
            x: 325,
            y: 87.5,
            height: 75,
            width: 37.5,
            ref: null,
            id: 2,
            color: "#FDD7C6",
            borderColor: "none",
        },
        {
            x: 75,
            y: 175,
            height: 75,
            width: 75,
            className: styles.rect,
            ref: null,
            id: 3,
            color: "#43968C",
            borderColor: "none",
        },
        {
            className: styles.semicircle,
            x: 200,
            y: 175,
            height: 37.5,
            width: 75,
            ref: null,
            id: 4,
            color: "#E36B2D",
            borderColor: "none",
        },
        {
            x: 75,
            y: 275,
            height: 150,
            width: 25,
            className: styles.rect,
            ref: null,
            id: 5,
            color: "#88ADDB",
            borderColor: "none",
        },
        {
            x: 125,
            y: 275,
            height: 37.5,
            width: 37.5,
            className: styles.tridown,
            ref: null,
            id: 6,
            color: "none",
            borderColor: "#7A559F",
        },
        {
            x: 200,
            y: 225,
            height: 75,
            width: 75,
            className: styles.triup,
            ref: null,
            id: 7,
            color: "none",
            borderColor: "#13385C",
        },
        {
            x: 150,
            y: 325,
            height: 75,
            width: 150,
            className: styles.rect,
            ref: null,
            id: 8,
            color: "#482A2D",
            borderColor: "none",
        },
        {
            x: 112.5,
            y: 412.5,
            height: 25,
            width: 150,
            className: styles.rect,
            ref: null,
            id: 9,
            color: "#C8E8E5",
            borderColor: "none",
        },
    ];
    const blockTypeToShapesMap = {
        5: fiveShapes,
        8: eightShapes,
        10: tenShapes,
    };
    // const [lastId, setLastId] = useState(blockType - 1);
    const lastId = useRef(blockType - 1);
    const shapesDivRef = useRef();
    const [shapesRaw, setShapesRaw] = useState({
        arr: blockTypeToShapesMap[blockType],
    });
    const [shapeDevices, setShapeDevices] = useState({ arr: [] });
    const [msg, setMsg] = useState(null);
    const [sessionLocal, setSessionLocal] = useState({ ...session });
    const [showSnackbar, setShowSnackbar] = useState(false);
    const nearDistance = 10;

    useEffect(() => {
        setSessionLocal({ ...session });
        // eslint-disable-next-line
    }, [session]);

    useEffect(() => {
        setShapesRaw({ arr: blockTypeToShapesMap[blockType] });
        // setLastId(blockType - 1);
        // lastId.current = blockType - 1;
        // eslint-disable-next-line
    }, [blockType, sessionLocal]);

    const move = ({ deltaX, deltaY, shapeDistance, index }) => {
        const newShapeDevices = shapeDevices.arr;
        if (Math.abs(shapeDistance.magnetX) < nearDistance) {
            newShapeDevices[index].lockX =
                newShapeDevices[index].x + shapeDistance.magnetX;
        } else {
            newShapeDevices[index].lockX = null;
        }
        if (Math.abs(shapeDistance.magnetY) < nearDistance) {
            newShapeDevices[index].lockY =
                newShapeDevices[index].y + shapeDistance.magnetY;
        } else {
            newShapeDevices[index].lockY = null;
        }
        if (
            newShapeDevices[index].x + deltaX >
            shapesDivRef?.current.clientWidth
        ) {
            newShapeDevices[index].x += deltaX;
            newShapeDevices[index].left = newShapeDevices[index].x;
            newShapeDevices[index].right =
                newShapeDevices[index].x + newShapeDevices[index].width;
        }
        newShapeDevices[index].y += deltaY;
        newShapeDevices[index].top = newShapeDevices[index].y;
        newShapeDevices[index].bottom =
            newShapeDevices[index].y + newShapeDevices[index].height;
        setShapeDevices({ arr: newShapeDevices });
    };

    const clearLock = (index) => {
        const newShapeDevices = [...shapeDevices.arr];
        if (newShapeDevices[index].lockX) {
            newShapeDevices[index].x = newShapeDevices[index].lockX;
            newShapeDevices[index].lockX = null;
        }
        if (newShapeDevices[index].lockY) {
            newShapeDevices[index].y = newShapeDevices[index].lockY;
            newShapeDevices[index].lockY = null;
        }
        setShapeDevices({ arr: newShapeDevices });
        return newShapeDevices;
    };

    const getShapeDistance = (nowDeviceIndex) => {
        const newShapeDevices = shapeDevices.arr;
        const nowDevice = newShapeDevices[nowDeviceIndex];
        const shapeDistance = newShapeDevices
            .filter((d) => d.id !== nowDevice.id && !d.inLibrary)
            .reduce(
                (acc, d) => {
                    const topToTop = d.top - nowDevice.top;
                    const bottomToBottom = d.bottom - nowDevice.bottom;
                    const leftToLeft = d.left - nowDevice.left;
                    const rightToRight = d.right - nowDevice.right;
                    const topToBottom = d.top - nowDevice.bottom;
                    const bottomToTop = d.bottom - nowDevice.top;
                    const leftToRight = d.left - nowDevice.right;
                    const rightToLeft = d.right - nowDevice.left;
                    const magnetY = closestZero([
                        topToTop,
                        bottomToBottom,
                        topToBottom,
                        bottomToTop,
                    ]);
                    const magnetX = closestZero([
                        leftToLeft,
                        leftToRight,
                        rightToLeft,
                        rightToRight,
                    ]);
                    if (Math.abs(magnetX) < Math.abs(acc.magnetX)) {
                        acc.magnetX = magnetX;
                    }
                    if (Math.abs(magnetY) < Math.abs(acc.magnetY)) {
                        acc.magnetY = magnetY;
                    }
                    return acc;
                },
                {
                    magnetX: Number.MAX_SAFE_INTEGER,
                    magnetY: Number.MAX_SAFE_INTEGER,
                }
            );
        return shapeDistance;
    };

    const closestZero = (nums) => {
        return nums.reduce((acc, num) => {
            if (Math.abs(num) < Math.abs(acc)) {
                acc = num;
            }
            return acc;
        }, Number.MAX_SAFE_INTEGER);
    };

    const onDrag = (e) => {
        const { deltaX, deltaY } = e;
        const nowDeviceIndex = shapeDevices.arr.findIndex((d) => d.id === e.id);
        const shapeDistance = getShapeDistance(nowDeviceIndex);
        move({
            deltaX,
            deltaY,
            shapeDistance,
            index: nowDeviceIndex,
        });
    };

    const gameStatusUpdate = async (newShapeDevices) => {
        const newShapeDevicesWithoutRef = newShapeDevices.map((obj) => {
            const newObj = { ...obj };
            // delete newObj.refObj;
            newObj.refObj = null;
            return newObj;
        });

        return newShapeDevicesWithoutRef;
    };

    const onDragStop = (id) => {
        const nowDeviceIndex = shapeDevices.arr.findIndex((d) => d.id === id);
        const newShapeDevices = clearLock(nowDeviceIndex);
        let newShapeDevicesWithoutRef = undefined;
        gameStatusUpdate(newShapeDevices).then((res) => {
            newShapeDevicesWithoutRef = [...res];
            updateSessionGameStatus(newShapeDevicesWithoutRef);
        });
    };

    const handleGameStatusChange = (data) => {
        const newSession = {
            ...sessionLocal,
            ...{
                configuration: {
                    ...sessionLocal.configuration,
                    right_panel_settings:
                        sessionLocal.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === currentPanel._id) {
                                    // const userIndex = obj.activity_settings.game2_status.findIndex(obj => (obj.user_id === data.studentId));
                                    const userIndex =
                                        obj.activity_settings.game2_status.findIndex(
                                            (obj) =>
                                                obj.user_id ===
                                                    data.studentId &&
                                                obj.user_name ===
                                                    data.studentName
                                        );
                                    if (userIndex === -1) {
                                        obj.activity_settings.game2_status = [
                                            ...obj.activity_settings
                                                .game2_status,
                                            {
                                                user_id: data.studentId,
                                                shape_devices:
                                                    data.shapeDevices,
                                                user_name: data.studentName,
                                            },
                                        ];
                                    } else {
                                        obj.activity_settings.game2_status[
                                            userIndex
                                        ].shape_devices = data.shapeDevices;
                                    }
                                }
                                return obj;
                            }
                        ),
                },
            },
        };
        setSessionLocal(newSession);
    };

    useEffect(() => {
        const userId = JSON.parse(localStorage.getItem("auth")).user._id;
        const updatedCurrentPanel =
            sessionLocal?.configuration?.right_panel_settings.find(
                (obj) => obj._id === currentPanel._id
            );
        // const status = updatedCurrentPanel.activity_settings.game2_status.find(obj => {return obj?.user_id === selectedUserId});
        const status =
            updatedCurrentPanel?.activity_settings?.game2_status.find((obj) => {
                return (
                    obj &&
                    obj.user_id === selectedUserId &&
                    obj.user_name === userName
                );
            });
        if (status?.shape_devices.length > 0) {
            createShapeDevicesFromSession(status.shape_devices);
        } else if (selectedUserId === userId && status === undefined) {
            const newShapeDevices = createShapeDevices();
            if (userName !== undefined) {
                gameStatusUpdate(newShapeDevices).then((res) => {
                    const newShapeDevicesWithoutRef = { ...res };
                    const newSession = {
                        ...sessionLocal,
                        ...{
                            configuration: {
                                ...sessionLocal.configuration,
                                right_panel_settings: [
                                    ...sessionLocal?.configuration?.right_panel_settings.map(
                                        (obj) => {
                                            if (
                                                obj._id ===
                                                updatedCurrentPanel._id
                                            ) {
                                                const userIndex =
                                                    obj.activity_settings.game2_status.findIndex(
                                                        (o) => {
                                                            return (
                                                                o &&
                                                                o.user_id ===
                                                                    userId &&
                                                                o.user_name ===
                                                                    userName
                                                            );
                                                        }
                                                    );
                                                if (userIndex === -1) {
                                                    obj.activity_settings.game2_status =
                                                        [
                                                            ...obj
                                                                .activity_settings
                                                                .game2_status,
                                                            {
                                                                user_id: userId,
                                                                shape_devices:
                                                                    newShapeDevicesWithoutRef,
                                                                user_name:
                                                                    userName,
                                                            },
                                                        ];
                                                }
                                            }
                                            return obj;
                                        }
                                    ),
                                ],
                            },
                        },
                    };
                    setSessionLocal(newSession);
                });
            }
        }
        // eslint-disable-next-line
    }, [selectedUserId, shapesRaw, userName]);

    const createShapeDevicesFromSession = (shapesDevicesData) => {
        const shapeDevicesNew = shapesDevicesData.map((obj) => {
            const newObj = { ...obj, refObj: null };
            const ref = React.createRef();
            newObj.refObj = ref;
            return newObj;
        });
        setShapeDevices({ arr: shapeDevicesNew });
        const maxId = Math.max.apply(
            Math,
            shapeDevicesNew.map((obj) => {
                return obj.id;
            })
        );
        // setLastId(maxId);
        lastId.current = maxId;
    };

    const generateNewId = () => {
        const newId = lastId.current + 1;
        // setLastId(newId);
        lastId.current = newId;
        return newId;
    };

    const createShapeDevices = () => {
        const shapeDevicesNew = shapesRaw.arr.map((obj) => {
            const ref = React.createRef();
            const shapeModel = {
                refObj: ref,
                x: obj.x,
                y: obj.y,
                lockX: null,
                lockY: null,
                id: obj.id,
                className: obj.className,
                bottom: obj.y + obj.height,
                right: obj.x + obj.width,
                left: obj.x,
                top: obj.y,
                height: obj.height,
                width: obj.width,
                inLibrary: true,
                color: obj.color,
                borderColor: obj.borderColor,
                isSelected: false,
                zIndex: 0,
            };
            return shapeModel;
        });
        setShapeDevices({ arr: shapeDevicesNew });
        return shapeDevicesNew;
    };

    const updateSessionGameStatus = (newShapeDevices) => {
        const userId = JSON.parse(localStorage.getItem("auth")).user._id;
        const newSession = {
            ...sessionLocal,
            ...{
                configuration: {
                    ...sessionLocal.configuration,
                    right_panel_settings:
                        sessionLocal.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === currentPanel._id) {
                                    const userIndex =
                                        obj.activity_settings.game2_status.findIndex(
                                            (obj) =>
                                                obj.user_id === userId &&
                                                obj.user_name === userName
                                        );
                                    obj.activity_settings.game2_status[
                                        userIndex
                                    ].shape_devices = newShapeDevices;
                                }
                                return obj;
                            }
                        ),
                },
            },
        };
        setSessionLocal(newSession);
    };

    const handleMouseDown = (id) => {
        const newShapeDevices = shapeDevices.arr;
        const nowDeviceIndex = newShapeDevices.findIndex((d) => d.id === id);
        newShapeDevices
            .filter((d) => d.id !== id)
            .forEach((d) => {
                d.zIndex = 0;
                d.isSelected = false;
            });
        if (newShapeDevices[nowDeviceIndex].inLibrary) {
            const ref = React.createRef();
            const newId = generateNewId();
            const shapeModel = {
                ...newShapeDevices[nowDeviceIndex],
                key: newId,
                refObj: ref,
                id: newId,
            };
            newShapeDevices[nowDeviceIndex].x = 600;
            newShapeDevices[nowDeviceIndex].left = 600;
            newShapeDevices[nowDeviceIndex].right =
                newShapeDevices[nowDeviceIndex].width + 600;
            newShapeDevices.push(shapeModel);
            newShapeDevices[nowDeviceIndex].inLibrary = false;
            setShapeDevices({ arr: newShapeDevices });
            let newShapeDevicesWithoutRef = undefined;
            gameStatusUpdate(newShapeDevices).then((res) => {
                newShapeDevicesWithoutRef = [...res];
                updateSessionGameStatus(newShapeDevicesWithoutRef);
            });
            return false;
        } else {
            newShapeDevices[nowDeviceIndex].zIndex = 1;
            newShapeDevices[nowDeviceIndex].isSelected = true;
            setShapeDevices({ arr: newShapeDevices });
            let newShapeDevicesWithoutRef = undefined;
            gameStatusUpdate(newShapeDevices).then((res) => {
                newShapeDevicesWithoutRef = [...res];
                updateSessionGameStatus(newShapeDevicesWithoutRef);
            });
        }
    };

    const handleRemoveShape = () => {
        if (selectedUserId === userId) {
            let newShapeDevices = shapeDevices.arr.filter((d) => !d.isSelected);
            setShapeDevices({ arr: newShapeDevices });
            let newShapeDevicesWithoutRef = undefined;
            gameStatusUpdate(newShapeDevices).then((res) => {
                newShapeDevicesWithoutRef = [...res];
                updateSessionGameStatus(newShapeDevicesWithoutRef);
            });
        }
    };

    const refreshGame = () => {};

    const emptyShapes = () => {
        const updatedCurrentPanel =
            sessionLocal.configuration.right_panel_settings.find(
                (obj) => obj._id === currentPanel._id
            );

        updatedCurrentPanel.activity_settings.game2_status = [];

        return updatedCurrentPanel;
    };

    // const exitGame = () => {
    //     const panel = emptyShapes();
    //     // setIsConfigComplete(false);
    //     // setSessionLocal(panel);
    //     history.goBack();
    // };

    const shapeElements = shapeDevices?.arr.map((d) => {
        return (
            <DraggableCore
                key={`${d.id}-${userName}-${selectedUserId}`}
                onStart={() => {
                    if (selectedUserId === userId) {
                        return handleMouseDown(d.id);
                    }
                }}
                onDrag={(e, coreData) => {
                    if (selectedUserId === userId) {
                        onDrag({
                            id: d.id,
                            deltaX: coreData.deltaX,
                            deltaY: coreData.deltaY,
                        });
                    }
                }}
                onStop={() => {
                    if (selectedUserId === userId) {
                        onDragStop(d.id);
                    }
                }}
                nodeRef={d.refObj}
            >
                <div
                    className={d.className}
                    ref={d.refObj}
                    style={{
                        width: d.width,
                        height: d.height,
                        left: d.lockX ? d.lockX : d.x,
                        top: d.lockY ? d.lockY : d.y,
                        background: d.color,
                        borderBottomColor: d.borderColor,
                        borderTopColor: d.borderColor,
                        zIndex: d.zIndex,
                    }}
                ></div>
            </DraggableCore>
        );
    });

    return (
        <div className={styles.gameScreen}>
            {/* <div
                className={styles.closeButton}
                onClick={() => {
                    exitGame();
                }}
            >
                Close
            </div> */}

            <div className={styles.gameContainer}>
                <div className={styles.assets}>
                    {msg && (
                        <Snackbar
                            status="error"
                            message={msg}
                            open={showSnackbar}
                            setOpen={setShowSnackbar}
                        ></Snackbar>
                    )}
                    <div className={styles.gameArea}>
                        <div className={styles.shapesDiv} ref={shapesDivRef}>
                            {shapeElements}
                            <FontAwesomeIcon
                                icon={faTrash}
                                onClick={handleRemoveShape}
                                alt="remove shape"
                                className={styles.trash}
                            />
                        </div>
                        <div className={styles.shapesDropArea}>
                            {!studentView && (
                                <div className={styles.gameControls}>
                                    <FontAwesomeIcon
                                        icon={faRedo}
                                        onClick={refreshGame}
                                        alt="refresh"
                                        className={styles.gameControl}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
