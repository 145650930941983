import React, { useEffect, useState } from "react";
import styles from "../../../Play/play.module.css";
import { Row, Col } from "react-bootstrap";
import { Modal } from "react-bootstrap";
// import CustomDropdown from "../../../../../components/CustomDropdown";
import MultiSelect from "../../../../../components/MultiSelect/index";
import axios from "axios";
import Snackbar from "../../../../../components/Snackbar";
import _ from "lodash";

export default ({
    participants,
    currentPanel,
    session,
    handleChangeStudentConfig,
    accessAll,
    setAccessAll,
    socket,
}) => {
    const userIds = participants.map((participant) => {
        var identityObj = JSON.parse(participant.identity);
        return identityObj._id;
    });

    const [showModal, setShowModal] = useState(true);
    const [teacherOnlyAccess, setTeacherOnlyAccess] = useState(false);
    const [isIndividual, setIsIndividual] = useState(true);
    const [currentSession, setCurrentSession] = useState(session);
    const [selectedOption, setSelectedOption] = useState([]);
    const [selectOption, setSelectOption] = useState([]);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const updateSession = async (newSession) => {
        const sessionData = newSession;
        await axios
            .put("/api/v1/sessions", sessionData)
            .then((response) => {
                if (response.data.success) {
                    handleChangeStudentConfig();
                } else {
                    setMsg(
                        _.get("response.data.title", "Something went wrong.")
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    useEffect(() => {
        var options = [{ label: "Teacher", value: currentSession?.teacher_id }];

        for (var i = 0; i < currentSession?.students_ids.length; i++) {
            options.push({
                label: `Student ${i}`,
                value: currentSession.students_ids[i].id,
            });
        }

        setSelectedOption(options);
        setAccessAll(
            !Boolean(
                currentPanel.lesson_settings.whiteboard?.student_access.length
            )
        );
        setSelectOption(currentPanel.lesson_settings.whiteboard.teams);
        // eslint-disable-next-line
    }, [participants]);

    useEffect(() => {
        setShowModal(true);

        currentSession.configuration.right_panel_settings.map((obj) => {
            if (obj._id === currentPanel?._id) {
                setTeacherOnlyAccess(
                    obj.lesson_settings.whiteboard?.student_access.length
                );
            }
            return obj;
        });
        // eslint-disable-next-line
    }, [currentPanel._id, currentSession.configuration.right_panel_settings]);

    const handleAccess = async () => {
        let updatedSession = currentSession;
        currentSession.configuration.right_panel_settings.map((obj) => {
            if (obj._id === currentPanel?._id) {
                obj.lesson_settings.whiteboard.student_access = accessAll
                    ? userIds
                    : [];
            }
            return obj;
        });
        socket.emit("message", {
            action: "studentConfigChange",
            payload: {
                userIds,
            },
        });
        setAccessAll(!accessAll);
        await updateSession(updatedSession);
    };

    const handleWhiteboardStart = async () => {
        const newSession = {
            ...currentSession,

            ...{
                configuration: {
                    ...currentSession.configuration,

                    right_panel_settings:
                        currentSession.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === currentPanel?._id) {
                                    if (
                                        currentPanel.type === "LESSON" &&
                                        !isIndividual
                                    ) {
                                        obj.lesson_settings.whiteboard.teams =
                                            selectOption;
                                    } else if (
                                        currentPanel.type === "LESSON" &&
                                        isIndividual
                                    ) {
                                        obj.lesson_settings.whiteboard.teams =
                                            [];
                                    }
                                }

                                return obj;
                            }
                        ),
                },
            },
        };

        await updateSession(newSession);
        setShowModal(false);
    };

    const teams = currentPanel?.lesson_settings?.whiteboard.teams;

    const teams1 = teams?.filter((element) => element.team === "team1");

    const teams2 = teams?.filter((element) => element.team === "team2");

    const teams3 = teams?.filter((element) => element.team === "team3");

    const teams4 = teams?.filter((element) => element.team === "team4");

    return (
        <>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <Modal
                contentClassName={styles.modal}
                backdropClassName={styles.modalBackdrop}
                show={showModal}
                onHide={() => {}}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <Row>
                        <div className={styles.modalSubBlock}>
                            <Row className={styles.modalHeading}>
                                Whiteboard Settings
                            </Row>
                            <Row
                                style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Col
                                    className={`${styles.modalText} ${styles.buttonLeftText}`}
                                    xs={4}
                                >
                                    Teacher only access
                                </Col>
                                <Col xs={3}>
                                    <div
                                        className={`${styles.button}`}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleAccess();
                                        }}
                                    >
                                        <div
                                            className={
                                                accessAll
                                                    ? `${styles.circleRight}`
                                                    : `${styles.circleLeft}`
                                            }
                                        />
                                    </div>
                                </Col>
                                <Col
                                    className={`${styles.modalText} ${styles.buttonRightText}`}
                                    xs={4}
                                >
                                    Student Access
                                </Col>
                            </Row>
                            <Row className={styles.createButtonSectionText}>
                                You can make changes to this in class.
                            </Row>
                        </div>
                    </Row>
                    <Row
                        className={styles.startButtonSection}
                        style={{ marginRight: "0px" }}
                    >
                        <h1
                            className={styles.startButton}
                            onClick={handleWhiteboardStart}
                        >
                            Start
                        </h1>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};
