import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faAngleDown,
    faAngleUp,
    faMinus,
    faLessThan,
    faGreaterThan,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Logo from "../../../../assets/images/plass_logo_new.svg";
import styles from "./media.module.css";
import CustomDropdown from "../../../../components/CustomDropdown";
import { useParams, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import Clock from "../../../../components/Clock";
import view1 from "../../../../assets/images/i_v student1.svg";
import view2 from "../../../../assets/images/i_v student2.svg";
import view3 from "../../../../assets/images/i_v student3.svg";
import view4 from "../../../../assets/images/i_v student4.svg";
import viewT1 from "../../../../assets/images/i_v teacher1.svg";
import viewT2 from "../../../../assets/images/i_v teacher2.svg";
import viewT3 from "../../../../assets/images/i_v teacher3.svg";
import viewT4 from "../../../../assets/images/i_v teacher4.svg";
import Note from "../../../../assets/images/note.svg";
import Edit from "../../../../assets/images/edit.png";
import moment from "moment-timezone";
import { useDropzone } from "react-dropzone";
import Snackbar from "../../../../components/Snackbar";
import CloseButton from "../../../../assets/images/Close_button.png";
import ScreenIcon from "../../../../assets/images/ScreenArrangement.png";
import PlayIcon from "../../../../assets/images/PlayIcon.png";
import UploadContent from "../../../../assets/images/UploadContent.png";
import _ from "lodash";
import PresentationPreview from "./Preview";

var imageStringToImageMap = {
    view1,
    view2,
    view3,
    view4,
    viewT1,
    viewT2,
    viewT3,
    viewT4,
};

export default () => {
    const [upload, setUpload] = useState(false);
    const { sessionID } = useParams();
    const location = useLocation();
    const panelindex = Number(
        new URLSearchParams(location.search).get("panelIndex")
    );
    const panelSlotId =
        new URLSearchParams(location.search).get("slotId") || null;
    const [rightPanelSettings, setRightPanelSettings] = useState([]);
    const [rightPanelSettingsToShow, setRightPanelSettingsToShow] = useState(
        []
    );
    const [panelIndex, setPanelIndex] = useState(panelindex);
    const [gallery, setGallery] = useState([]);
    const [studentView, setStudentView] = useState(undefined);
    const [teacherView, setTeacherView] = useState(undefined);
    const [category, setCategory] = useState("IMAGE");
    const [showOption, setShowOption] = useState(false);
    const [audio, setAudio] = useState(false);
    const [savedFolder, setSavedFolder] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [modalFlag, setModalFlag] = useState(0);
    const [rightPanelIndex, setRightPanelIndex] = useState(undefined);
    const [sessions, setSessions] = useState([]);
    const [session, setSession] = useState();
    const [imgModalFlag, setImgModalFlag] = useState(0);
    const [show, setShow] = useState(null);
    const [importModal, setImportModal] = useState(false);
    const [selectClass, setSelectClass] = useState(false);
    const [sessionData, setSessionData] = useState();
    const [versionTime, setVersionTime] = useState(undefined);
    const [toggleShowMore, setToggleShowMore] = useState(false);
    const { acceptedFiles, getRootProps } = useDropzone();
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [finishModal, setFinishModal] = useState(false);
    const history = useHistory();
    const urlParam = !!panelSlotId ? `&slotId=${panelSlotId}` : ``;

    useEffect(() => {
        const getSessionInfo = async () => {
            await axios
                .get(`/api/v1/class/${sessionID}`)
                .then((result) => {
                    if (result.data.success) {
                        setSession(result.data.data.session);
                        const newArray =
                            result.data.data.session.configuration
                                .right_panel_settings;

                        setStudentView(
                            result.data.data.session.configuration
                                .right_panel_settings[panelIndex]
                                .lesson_settings.assets.view?.student
                        );
                        setTeacherView(
                            result.data.data.session.configuration
                                .right_panel_settings[panelIndex]
                                .lesson_settings.assets.view?.teacher
                        );

                        if (
                            result.data.data.session.configuration
                                .right_panel_settings[panelIndex]
                                .lesson_settings.assets.view?.student &&
                            result.data.data.session.configuration
                                .right_panel_settings[panelIndex]
                                .lesson_settings.assets.view?.teacher
                        ) {
                            newArray[
                                panelIndex
                            ].isConfigurationCompleted = true;
                        }

                        setRightPanelSettings(newArray);
                        setRightPanelSettingsToShow(newArray.slice(0, 5));
                        getUserGallery();
                        getSelectedMedia();
                        const updateTime = moment
                            .utc(
                                result.data.data.session.updatedAt,
                                "DD/MM/YYYY HH:mm"
                            )
                            .local()
                            .format("HH:mm");
                        setVersionTime(updateTime);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };

        getSessionInfo();
        // eslint-disable-next-line
    }, []);

    const handlePreview = () => {
        // setShowPreview(!showPreview);
        history.push({
            pathname: `/session/${sessionID}/preview`,
            state: {
                // handlePreview: handlePreview,
                currentPanel: rightPanelSettings[panelIndex],
                panelIndex: panelIndex,
                session: session,
            },
        });
    };

    const getUserGallery = async () => {
        await axios
            .get("/api/v1/user/gallery")
            .then((result) => {
                if (result.data.success) {
                    const newArray = result.data.data.gallery;
                    setGallery(newArray);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const inputFile = useRef(null);

    const handleImageUpload = (e) => {
        // e.preventDefault();

        inputFile.current.click();
    };

    const fileSelected = (event) => {
        const extension = event.target.files[0].type
            .split("/")[1]
            .toUpperCase();
        const type = event.target.files[0].type.split("/")[0].toUpperCase();

        if (type === "VIDEO" && extension === "MP4") {
            handleUploadImage(event.target.files[0], type);
        } else if (
            type === "AUDIO" &&
            (extension === "MP4A" ||
                extension === "MP3" ||
                extension === "MPEG")
        ) {
            handleUploadImage(event.target.files[0], type);
        } else if (
            type === "IMAGE" &&
            (extension === "PNG" || extension === "JPEG" || extension === "JPG")
        ) {
            handleUploadImage(event.target.files[0], type);
        } else {
            setMsg("Invalid file type");
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        }
        event.target.value = "";
    };

    const handleUploadImage = async (fileData, type) => {
        if (fileData) {
            let formData = new FormData();
            formData.append("image", fileData);
            formData.append("session_id", sessionID);
            formData.append("type", type);
            await axios
                .post("/api/v1/user/gallery/upload", formData)
                .then((result) => {
                    if (result.data.success) {
                        setGallery(result.data.data.gallery);
                        getUserGallery();
                        const obj =
                            result.data.data.gallery[
                                result.data.data.gallery.length - 1
                            ];

                        handleLessonAssets(obj);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                });
        }
    };

    const openUploadModal = () => {
        setUpload(true);
    };

    const closeUploadModal = () => {
        setUpload(false);
    };

    const lessonData = [
        {
            label: "Video",
            value: "Video",
        },
        {
            label: "Lesson",
            value: "Lesson",
        },
        { label: "Activity", value: "Activity" },
    ];

    const handleData = (type) => {
        if (type === "VIDEO") {
            return "Video";
        } else if (type === "LESSON") {
            return "Lesson";
        } else if (type === "ACTIVITY") {
            return "Activity";
        }
    };

    const rightTab = (data) => {
        if (data === "VIDEO") {
            return "Video";
        } else if (data === "LESSON") {
            return "Lesson";
        } else if (data === "ACTIVITY") {
            return "Activity";
        }
    };

    const updateSession = async (newSettings, cb) => {
        if (rightPanelSettings.length > 0) {
            const sessionData = {
                id: sessionID,
                rightPanelSettings: newSettings || rightPanelSettings,
                ...(!!panelSlotId
                    ? {
                          rightPanelSettingsSessionId: panelSlotId,
                      }
                    : {}),
            };
            await axios
                .put("/api/v1/sessions", sessionData)
                .then((result) => {
                    if (result.data.success) {
                        if (cb) {
                            cb();
                        }
                        setVersionTime(moment().format("HH:mm"));
                    } else {
                        setMsg(
                            _.get(
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        }
    };

    const updateRightPanelSettings = (index, type, label, cb) => {
        const updatedRightPanel = rightPanelSettings.map((obj, i) => {
            if (index === i && (!obj.type || obj.type !== type)) {
                return {
                    label,
                    type,
                };
            } else {
                return obj;
            }
        });
        setRightPanelSettings(updatedRightPanel);
        setRightPanelSettingsToShow(updatedRightPanel.slice(0, 5));
        cb(updatedRightPanel);
    };

    const addLessionBlock = async () => {
        const newArray = [...rightPanelSettings, {}];
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
    };

    const removeLessionBlock = async (index) => {
        if (index < panelIndex) {
            setPanelIndex(panelIndex - 1);
        }
        const newArray = rightPanelSettings
            .slice(0, index)
            .concat(rightPanelSettings.slice(index + 1));
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
    };

    const getSelectedMedia = async () => {
        await axios
            .get(`/api/v1/class/${sessionID}`)
            .then(async (result) => {
                if (result.data.success) {
                    const selectedMediaArray =
                        result.data.data.session.configuration
                            .right_panel_settings[panelIndex].lesson_settings
                            .assets.image_ids;
                    let galleryData = {
                        selectedMediaArray: selectedMediaArray,
                        userId: "",
                    };

                    await axios
                        .post(`/api/v1//user/gallery/selected`, galleryData)
                        .then((data) => {
                            if (data.data.success) {
                                setSelectedMedia(data.data.data.newArray);
                            }
                        })
                        .catch((error) => {
                            setMsg(
                                _.get(
                                    error,
                                    "response.data.title",
                                    "Something went wrong."
                                )
                            );
                            setShowSnackbar(true);
                            setTimeout(function () {
                                setMsg(null);
                            }, 3000);
                            return Promise.reject(error);
                        });
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const handleLessonAssets = async (obj) => {
        const newArray = [...rightPanelSettings];
        newArray[panelIndex].lesson_settings.assets.image_ids.push(obj._id);
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
        await getSelectedMedia();
    };

    const handleCategories = (category) => {
        setCategory(category);
    };

    const handleRightTab = async (ev, index) => {
        const newArray = [...rightPanelSettings];
        newArray[index] = {
            ...newArray[index],
            label: ev.target.innerText,
        };
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
    };

    const handleTextChange = (e) => {
        if (e.currentTarget.textContent.length > 6 && e.which !== 8) {
            e.preventDefault();
        }
    };

    const controlAudio = () => {
        let x = document.getElementById("myAudio");
        setAudio(!audio);
        if (!audio) {
            x.play();
        } else {
            x.pause();
        }
    };

    const handleSavedFolder = () => {
        setSavedFolder(!savedFolder);
    };

    const decreaseModalFlag = () => {
        if (modalFlag !== 0) {
            setModalFlag(modalFlag - 1);
        }
    };

    const increaseModalFlag = () => {
        if (modalFlag < selectedMedia.length - 3) {
            setModalFlag(modalFlag + 1);
        }
    };

    const handleSelectedMedia = async (event, obj) => {
        event.preventDefault();

        const newArray = [...rightPanelSettings];
        const data = newArray[
            panelIndex
        ].lesson_settings.assets.image_ids.filter((item) => {
            return item !== obj._id;
        });

        newArray[panelIndex].lesson_settings.assets.image_ids = data;
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
        await getSelectedMedia();
    };

    const saveSubtypeAndResetIfDifferent = async (param) => {
        // api call
        if (rightPanelSettings.length > 0) {
            const sessionData = {
                id: sessionID,
                rightPanelSettings: rightPanelSettings.map((obj, index) => {
                    if (parseInt(panelindex) === index) {
                        if (obj.subType !== param.toUpperCase()) {
                            return {
                                type: obj.type.toUpperCase(),
                                label: obj.label,
                                subType: param.toUpperCase(),
                            };
                        }
                        obj.subType = param.toUpperCase();
                    }
                    return obj;
                }),
                ...(!!panelSlotId
                    ? {
                          rightPanelSettingsSessionId: panelSlotId,
                      }
                    : {}),
            };
            await axios
                .put("/api/v1/sessions", sessionData)
                .then((response) => {
                    if (response.data.success) {
                        setShowOption(false);
                        if (param.toUpperCase() === "WHITEBOARD") {
                            history.push(
                                `/session/${sessionID}/${param.toLowerCase()}/setting?panelIndex=${panelindex}${urlParam}`
                            );
                        } else {
                            history.push(
                                `/session/${sessionID}/${param.toLowerCase()}/view?panelIndex=${panelindex}${urlParam}`
                            );
                        }
                    } else {
                        setMsg(
                            _.get(
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        }
    };

    const handleRightPanelSetting = async (index) => {
        const rightPanelData =
            sessionData.configuration.right_panel_settings[index];
        const newArray = rightPanelSettings;
        newArray[rightPanelIndex] = rightPanelData;
        newArray[rightPanelIndex]._id =
            sessionData.configuration.right_panel_settings[rightPanelIndex]._id;
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        setImportModal(false);
        setSelectClass(false);
        await updateSession(newArray);
    };

    const moveSessionUp = () => {
        const index = rightPanelSettings.indexOf(rightPanelSettingsToShow[0]);

        if (index > 0) {
            let rightPanelSettingsToShowCopy = [...rightPanelSettingsToShow];
            rightPanelSettingsToShowCopy.pop();
            rightPanelSettingsToShowCopy = [
                rightPanelSettings[index - 1],
                ...rightPanelSettingsToShowCopy,
            ];
            setRightPanelSettingsToShow(rightPanelSettingsToShowCopy);
        }
    };

    const moveSessionDown = () => {
        const index = rightPanelSettings.indexOf(rightPanelSettingsToShow[0]);

        if (index < rightPanelSettings.length - 5) {
            const rightPanelSettingsToShowCopy = [...rightPanelSettingsToShow];
            rightPanelSettingsToShowCopy.shift();
            rightPanelSettingsToShowCopy.push(rightPanelSettings[index + 5]);
            setRightPanelSettingsToShow(rightPanelSettingsToShowCopy);
        }
    };

    const handleImportModal = () => {
        setImportModal(false);
        setSelectClass(false);
    };

    const handleSelectClass = async (event, id) => {
        event.preventDefault();
        await axios
            .get("/api/v1/sessions")
            .then((result) => {
                if (result.data.success) {
                    const data = result.data.sessions.find(
                        (element, index) => index === id
                    );
                    setSessionData(data);
                }
                setSelectClass(true);
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const decreaseImgModalFlag = (length) => {
        if (imgModalFlag > 0) {
            setImgModalFlag(imgModalFlag - 1);
        } else {
            setImgModalFlag(length - 1);
        }
    };

    const increaseImgModalFlag = (length) => {
        if (imgModalFlag < length - 1) {
            setImgModalFlag(imgModalFlag + 1);
        } else {
            setImgModalFlag(0);
        }
    };

    const handleImportOption = async (index) => {
        await axios
            .get("/api/v1/sessions")
            .then((result) => {
                if (result.data.success) {
                    setSessions(result.data.sessions);
                }
                setImportModal(true);
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    return (
        <div>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            {showPreview ? (
                <PresentationPreview
                    currentPanel={rightPanelSettings[panelIndex]}
                    handlePreview={handlePreview}
                />
            ) : (
                <Row className={styles.row}>
                    <Col xs={10} className={styles.leftsection}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div
                                className={styles.iconsection}
                                style={{
                                    padding: "6px 34px",
                                    marginRight: 0,
                                    zIndex: 0,
                                }}
                            >
                                <img
                                    src={ScreenIcon}
                                    style={{ width: "40px" }}
                                    alt=""
                                    className={styles.playicon}
                                    onClick={async () => {
                                        await updateSession();
                                        history.push(
                                            `/session/${sessionID}/asset/view?panelIndex=${panelindex}${urlParam}`
                                        );
                                    }}
                                />
                            </div>
                            <div
                                className={styles.iconsection}
                                style={{
                                    padding: "6px 34px",
                                    marginLeft: "-4px",
                                    zIndex: 22,
                                }}
                            >
                                <img
                                    src={UploadContent}
                                    style={{ width: "40px" }}
                                    alt=""
                                    className={styles.playicon}
                                />
                            </div>
                            <h1
                                className={styles.heading}
                                style={{
                                    marginLeft: "10px",
                                    marginBottom: 0,
                                    marginTop: 0,
                                }}
                            >
                                Upload Content
                            </h1>
                        </div>
                        {upload && (
                            <div className={styles.uploadModalMain}>
                                <FontAwesomeIcon
                                    className={styles.crossIcon}
                                    icon={faTimes}
                                    onClick={closeUploadModal}
                                />
                                {savedFolder ? (
                                    <>
                                        <div
                                            className={`${styles.uploadArea} ${styles.height23}`}
                                        >
                                            <div className={styles.mediaType}>
                                                <p
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        handleCategories(
                                                            "IMAGE"
                                                        );
                                                    }}
                                                >
                                                    Image
                                                </p>
                                                <p
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        handleCategories(
                                                            "VIDEO"
                                                        );
                                                    }}
                                                >
                                                    Video
                                                </p>
                                                <p
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        handleCategories(
                                                            "AUDIO"
                                                        );
                                                    }}
                                                >
                                                    Audio
                                                </p>
                                            </div>
                                            <div
                                                className={`${styles.mediaContent} ${styles.height88}`}
                                            >
                                                <Row style={{ width: "100%" }}>
                                                    {gallery.map(
                                                        (obj, index) => {
                                                            const type =
                                                                obj.type;
                                                            if (
                                                                type ===
                                                                    "VIDEO" &&
                                                                type ===
                                                                    category
                                                            ) {
                                                                return (
                                                                    <Col md={3}>
                                                                        <div
                                                                            className={
                                                                                styles.mediaBlockModal
                                                                            }
                                                                            onClick={() => {
                                                                                handleLessonAssets(
                                                                                    obj
                                                                                );
                                                                            }}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            <video
                                                                                className={
                                                                                    styles.modalCoverImage
                                                                                }
                                                                                src={
                                                                                    obj.location
                                                                                }
                                                                                controls
                                                                            ></video>
                                                                        </div>
                                                                    </Col>
                                                                );
                                                            } else if (
                                                                type ===
                                                                    "AUDIO" &&
                                                                type ===
                                                                    category
                                                            ) {
                                                                return (
                                                                    <Col md={3}>
                                                                        <div
                                                                            className={
                                                                                styles.mediaBlockModal
                                                                            }
                                                                            onClick={() => {
                                                                                handleLessonAssets(
                                                                                    obj
                                                                                );
                                                                            }}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                className={
                                                                                    styles.assets
                                                                                }
                                                                                src={
                                                                                    Note
                                                                                }
                                                                                onClick={() => {
                                                                                    // controlAudio();
                                                                                }}
                                                                                alt=""
                                                                            />
                                                                            <audio
                                                                                id="myAudio"
                                                                                style={{
                                                                                    width: "100%",
                                                                                    display:
                                                                                        "none",
                                                                                }}
                                                                                controls
                                                                                src={
                                                                                    obj.location
                                                                                }
                                                                            ></audio>
                                                                        </div>
                                                                    </Col>
                                                                );
                                                            } else if (
                                                                type ===
                                                                    "IMAGE" &&
                                                                type ===
                                                                    category
                                                            ) {
                                                                return (
                                                                    <Col md={3}>
                                                                        <div
                                                                            className={
                                                                                styles.savedMediaBlockModal
                                                                            }
                                                                            onClick={() => {
                                                                                handleLessonAssets(
                                                                                    obj
                                                                                );
                                                                            }}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                className={
                                                                                    styles.assets
                                                                                }
                                                                                src={
                                                                                    obj.location
                                                                                }
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                );
                                                            }
                                                            return null;
                                                        }
                                                    )}
                                                </Row>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <h1
                                                onClick={() => {
                                                    setSavedFolder(false);
                                                }}
                                                className={
                                                    styles.savedFolderBtn
                                                }
                                            >
                                                Back
                                            </h1>
                                            <h1
                                                onClick={() => {
                                                    setSavedFolder(false);
                                                }}
                                                className={
                                                    styles.savedFolderBtn
                                                }
                                            >
                                                Next
                                            </h1>
                                        </div>
                                    </>
                                ) : !toggleShowMore ? (
                                    <>
                                        <div className={styles.uploadArea}>
                                            <div
                                                style={{ outline: "none" }}
                                                {...getRootProps({
                                                    className: "dropzone",
                                                })}
                                            >
                                                <p className={styles.dragText}>
                                                    Drag and drop your files
                                                    here or
                                                </p>

                                                <div
                                                    className={
                                                        styles.uploadMediaModal
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.uploadBtn
                                                        }
                                                        onClick={(event) => {
                                                            handleImageUpload(
                                                                event
                                                            );
                                                        }}
                                                    >
                                                        <p
                                                            className={
                                                                styles.toolText
                                                            }
                                                        >
                                                            Upload From Computer
                                                        </p>
                                                        <FontAwesomeIcon
                                                            className={
                                                                styles.uploadIcon
                                                            }
                                                            style={{
                                                                width: "25px",
                                                            }}
                                                            icon={faPlus}
                                                        />
                                                        <input
                                                            type="file"
                                                            id="file"
                                                            ref={inputFile}
                                                            style={{
                                                                display: "none",
                                                            }}
                                                            onChange={
                                                                fileSelected
                                                            }
                                                            accept="audio/*,video/*,image/*"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        styles.uploadMediaModal
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.uploadBtn
                                                        }
                                                        onClick={
                                                            handleSavedFolder
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            className={
                                                                styles.uploadIcon
                                                            }
                                                            style={{
                                                                width: "25px",
                                                            }}
                                                            icon={faPlus}
                                                        />
                                                        <p
                                                            className={
                                                                styles.toolText
                                                            }
                                                        >
                                                            Select from Saved
                                                            Folder
                                                        </p>
                                                    </div>
                                                </div>
                                                {selectedMedia.length !== 0 && (
                                                    <div
                                                        className={
                                                            styles.modalGalleryWrapper
                                                        }
                                                    >
                                                        {selectedMedia.length >
                                                            3 && (
                                                            <>
                                                                <FontAwesomeIcon
                                                                    style={{
                                                                        color: "#83838b",
                                                                        width: "30px",
                                                                        position:
                                                                            "absolute",
                                                                        left: "-2px",
                                                                        marginTop:
                                                                            "10px",
                                                                        zIndex: "8",
                                                                        top: "30px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    icon={
                                                                        faLessThan
                                                                    }
                                                                    onClick={
                                                                        decreaseModalFlag
                                                                    }
                                                                />
                                                                <FontAwesomeIcon
                                                                    style={{
                                                                        color: "#83838b",
                                                                        width: "30px",
                                                                        position:
                                                                            "absolute",
                                                                        right: "-7px",
                                                                        marginTop:
                                                                            "10px",
                                                                        zIndex: "8",
                                                                        top: "30px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    icon={
                                                                        faGreaterThan
                                                                    }
                                                                    onClick={
                                                                        increaseModalFlag
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                        {selectedMedia
                                                            .slice(
                                                                modalFlag,
                                                                modalFlag + 3
                                                            )
                                                            .map(
                                                                (
                                                                    obj,
                                                                    index
                                                                ) => {
                                                                    const fileType =
                                                                        obj.location
                                                                            .split(
                                                                                "."
                                                                            )
                                                                            .pop();

                                                                    if (
                                                                        fileType ===
                                                                        "mp3"
                                                                    ) {
                                                                        return (
                                                                            <div
                                                                                className={
                                                                                    styles.modalImageWrapper
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.mediaBlockModal
                                                                                    }
                                                                                    onClick={() => {
                                                                                        // handleLessonAssets(obj);
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        className={
                                                                                            styles.assets
                                                                                        }
                                                                                        src={
                                                                                            Note
                                                                                        }
                                                                                        onClick={() => {
                                                                                            controlAudio();
                                                                                        }}
                                                                                        alt=""
                                                                                    />
                                                                                    <audio
                                                                                        id="myAudio"
                                                                                        style={{
                                                                                            width: "100%",
                                                                                            display:
                                                                                                "none",
                                                                                        }}
                                                                                        controls
                                                                                        src={
                                                                                            obj.location
                                                                                        }
                                                                                    ></audio>
                                                                                </div>

                                                                                <FontAwesomeIcon
                                                                                    className={
                                                                                        styles.modalCross
                                                                                    }
                                                                                    style={{
                                                                                        width: "25px",
                                                                                    }}
                                                                                    icon={
                                                                                        faTimes
                                                                                    }
                                                                                    onClick={(
                                                                                        event
                                                                                    ) => {
                                                                                        handleSelectedMedia(
                                                                                            event,
                                                                                            obj
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <div
                                                                                className={
                                                                                    styles.modalImageWrapper
                                                                                }
                                                                            >
                                                                                {fileType ===
                                                                                "mp4" ? (
                                                                                    <video
                                                                                        className={
                                                                                            styles.modalImageGallery
                                                                                        }
                                                                                        src={
                                                                                            obj.location
                                                                                        }
                                                                                        controls
                                                                                    ></video>
                                                                                ) : (
                                                                                    <img
                                                                                        className={
                                                                                            styles.modalImageGallery
                                                                                        }
                                                                                        src={
                                                                                            obj.location
                                                                                        }
                                                                                        alt=""
                                                                                    />
                                                                                )}

                                                                                <FontAwesomeIcon
                                                                                    className={
                                                                                        styles.modalCross
                                                                                    }
                                                                                    style={{
                                                                                        width: "25px",
                                                                                    }}
                                                                                    icon={
                                                                                        faTimes
                                                                                    }
                                                                                    onClick={(
                                                                                        event
                                                                                    ) => {
                                                                                        handleSelectedMedia(
                                                                                            event,
                                                                                            obj
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <p
                                            style={{
                                                fontSize: "13px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Want to know which types of files
                                            are supported?{" "}
                                            <span
                                                style={{
                                                    color: "#88ADDB",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    setToggleShowMore(true)
                                                }
                                            >
                                                Read more
                                            </span>
                                        </p>
                                        <h1
                                            className={styles.finishBtn}
                                            onClick={closeUploadModal}
                                        >
                                            Finish
                                        </h1>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className={`${styles.uploadArea} ${styles.upperText}`}
                                        >
                                            <p>Types of files you can upload</p>

                                            <p>
                                                <div>Video: MP4</div>
                                                <div>Audio: MP4A, MP3</div>
                                                <div>Images: PNG, JPEG</div>
                                            </p>

                                            <p>
                                                Size of each file is less than
                                                5MB
                                            </p>
                                        </div>
                                        <h1
                                            className={styles.finishBtn}
                                            onClick={() =>
                                                setToggleShowMore(false)
                                            }
                                            style={{
                                                float: "left",
                                                marginTop: "1rem",
                                            }}
                                        >
                                            Back
                                        </h1>
                                    </>
                                )}
                            </div>
                        )}

                        <div className={styles.leftSection}>
                            <div>
                                <div className={styles.sessionblock}>
                                    <div className={styles.crosssection}>
                                        <img
                                            onClick={async () => {
                                                await updateSession();
                                                history.push(
                                                    `/session/${sessionID}`
                                                );
                                            }}
                                            style={{ marginRight: "5px" }}
                                            src={CloseButton}
                                            alt="Logo"
                                            className={styles.playicon}
                                        />
                                        <p className={styles.italicPara}>
                                            Last version saved at {versionTime}
                                        </p>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <h1
                                            className={styles.backBtn}
                                            onClick={async () => {
                                                await updateSession();
                                                history.push(
                                                    `/session/${sessionID}/asset/view?panelIndex=${panelindex}${urlParam}`
                                                );
                                            }}
                                        >
                                            Back
                                        </h1>
                                        <div style={{ position: "relative" }}>
                                            <div
                                                className={styles.selectedTool}
                                            >
                                                <p className={styles.toolText}>
                                                    Presentation
                                                </p>
                                                <FontAwesomeIcon
                                                    className={
                                                        styles.downAngleIcon
                                                    }
                                                    style={{
                                                        width: "30px",
                                                        cursor: showOption
                                                            ? "initial"
                                                            : "pointer",
                                                    }}
                                                    icon={faAngleDown}
                                                    onClick={() => {
                                                        setShowOption(
                                                            !showOption
                                                        );
                                                    }}
                                                />
                                            </div>
                                            {showOption && (
                                                <div
                                                    className={
                                                        styles.toolSection
                                                    }
                                                >
                                                    <p
                                                        className={
                                                            styles.toolType
                                                        }
                                                        onClick={() => {
                                                            saveSubtypeAndResetIfDifferent(
                                                                "Asset"
                                                            );
                                                        }}
                                                    >
                                                        Presentation
                                                    </p>
                                                    <p
                                                        className={
                                                            styles.toolType
                                                        }
                                                        onClick={() => {
                                                            saveSubtypeAndResetIfDifferent(
                                                                "Whiteboard"
                                                            );
                                                        }}
                                                    >
                                                        Whiteboard
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <img
                                                src={PlayIcon}
                                                style={{
                                                    width: "40px",
                                                    marginLeft: "5px",
                                                }}
                                                alt=""
                                                className={styles.playicon}
                                                onClick={handlePreview}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.mediaSection}>
                                    <div
                                        className={styles.uploadMedia}
                                        onClick={openUploadModal}
                                    >
                                        <p className={styles.toolText}>
                                            Upload Media
                                        </p>
                                        <FontAwesomeIcon
                                            className={styles.uploadIcon}
                                            style={{ width: "25px" }}
                                            icon={faPlus}
                                        />
                                    </div>

                                    <div className={styles.mediaWrapper}>
                                        {/* <div className={styles.folderIconBlock}>
                                                <FontAwesomeIcon
                                                    className={styles.folderIcon}
                                                    style={{ width: "40px" }}
                                                    icon={faFolder}
                                                />
                                            </div> */}
                                        <div className={styles.mediaType}>
                                            <p>Selected Media</p>
                                        </div>
                                        <div className={styles.mediaContent}>
                                            {!selectedMedia.length && (
                                                <>
                                                    <p
                                                        className={
                                                            styles.selectedMediaText
                                                        }
                                                    >
                                                        There are no files
                                                        selected yet
                                                    </p>
                                                    <p
                                                        className={
                                                            styles.selectedMediaText
                                                        }
                                                    >
                                                        Click{" "}
                                                        <span
                                                            style={{
                                                                color: "#88ADDB",
                                                            }}
                                                        >
                                                            Upload media
                                                        </span>{" "}
                                                        to begin selecting.
                                                    </p>
                                                </>
                                            )}
                                            <Row style={{ width: "100%" }}>
                                                {selectedMedia.map(
                                                    (obj, index) => {
                                                        const type = obj.type;
                                                        if (type === "VIDEO") {
                                                            return (
                                                                <Col md={3}>
                                                                    <div
                                                                        className={
                                                                            styles.mediaBlockModal
                                                                        }
                                                                        // onClick={() => {
                                                                        //   handleLessonAssets(obj);
                                                                        // }}
                                                                    >
                                                                        <video
                                                                            className={
                                                                                styles.modalCoverImage
                                                                            }
                                                                            src={
                                                                                obj.location
                                                                            }
                                                                            controls
                                                                        ></video>
                                                                    </div>
                                                                </Col>
                                                            );
                                                        } else if (
                                                            type === "AUDIO"
                                                        ) {
                                                            return (
                                                                <Col md={3}>
                                                                    <div
                                                                        className={
                                                                            styles.mediaBlockModal
                                                                        }
                                                                        // onClick={() => {
                                                                        //   handleLessonAssets(obj);
                                                                        // }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.assets
                                                                            }
                                                                            src={
                                                                                Note
                                                                            }
                                                                            onClick={() => {
                                                                                controlAudio();
                                                                            }}
                                                                            alt=""
                                                                        />
                                                                        <audio
                                                                            id="myAudio"
                                                                            style={{
                                                                                width: "100%",
                                                                                display:
                                                                                    "none",
                                                                            }}
                                                                            controls
                                                                            src={
                                                                                obj.location
                                                                            }
                                                                        ></audio>
                                                                    </div>
                                                                </Col>
                                                            );
                                                        } else if (
                                                            type === "IMAGE"
                                                        ) {
                                                            return (
                                                                <Col md={3}>
                                                                    <div
                                                                        className={
                                                                            styles.mediaBlockModal
                                                                        }
                                                                        // onClick={() => {
                                                                        //   handleLessonAssets(obj);
                                                                        // }}
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.assets
                                                                            }
                                                                            src={
                                                                                obj.location
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            );
                                                        }
                                                        return null;
                                                    }
                                                )}
                                            </Row>
                                        </div>
                                    </div>
                                    <div className={styles.tempHidden}>
                                        <Row>
                                            <Col>
                                                <h1 className={styles.heading}>
                                                    Student View
                                                </h1>
                                                <div
                                                    className={
                                                        styles.studentView
                                                    }
                                                >
                                                    <img
                                                        className={
                                                            styles.assets
                                                        }
                                                        src={
                                                            imageStringToImageMap[
                                                                studentView
                                                            ]
                                                        }
                                                        alt=""
                                                    />
                                                </div>
                                            </Col>
                                            <Col>
                                                <h1 className={styles.heading}>
                                                    Teacher View
                                                </h1>
                                                <div
                                                    className={
                                                        styles.teacherView
                                                    }
                                                >
                                                    <img
                                                        className={
                                                            styles.assets
                                                        }
                                                        src={
                                                            imageStringToImageMap[
                                                                teacherView
                                                            ]
                                                        }
                                                        alt=""
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                {finishModal && (
                                    <div className={styles.uploadModal}>
                                        <div className={styles.finishModal}>
                                            <FontAwesomeIcon
                                                // className={styles.uploadIcon}
                                                style={{
                                                    width: "25px",
                                                    color: "#E36B2D",
                                                    cursor: "pointer",
                                                }}
                                                icon={faTimes}
                                                onClick={() =>
                                                    setFinishModal(false)
                                                }
                                            />
                                        </div>

                                        <div
                                            className={styles.uploadArea}
                                            style={{
                                                textAlign: "center",
                                                width: "-webkit-fill-available",
                                            }}
                                        >
                                            <p>Great!</p>
                                            <p className={styles.dragText}>
                                                You can now work on your next
                                                block
                                            </p>
                                            <p> or</p>
                                            <p>
                                                Click to
                                                <span
                                                    style={{
                                                        color: "#88addb",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={handlePreview}
                                                >
                                                    {` preview `}
                                                </span>
                                                your class so far
                                            </p>
                                        </div>
                                    </div>
                                )}
                                <h1
                                    className={styles.finishBtn}
                                    onClick={() => {
                                        // handleFinalAnswer
                                        setFinishModal(true);
                                    }}
                                >
                                    Finish
                                </h1>
                                <div>
                                    <div
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div className={styles.scroll}>
                                            <div className={styles.content}>
                                                {rightPanelSettings.map(
                                                    (obj, index) => {
                                                        return (
                                                            <div
                                                                className={
                                                                    styles.lessonWrapper
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.classblock
                                                                    }
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={(
                                                                        event
                                                                    ) => {
                                                                        event.preventDefault();
                                                                        if (
                                                                            event
                                                                                .target
                                                                                .id ==
                                                                                "downarrow" ||
                                                                            event
                                                                                .target
                                                                                .parentElement
                                                                                .id ==
                                                                                "downarrow" ||
                                                                            document.getElementById(
                                                                                "dropdown"
                                                                            ) ||
                                                                            show !=
                                                                                null
                                                                        ) {
                                                                        } else {
                                                                            if (
                                                                                obj.type ===
                                                                                "LESSON"
                                                                            ) {
                                                                                if (
                                                                                    rightPanelSettings[
                                                                                        index
                                                                                    ]
                                                                                        .subType ===
                                                                                    "ASSET"
                                                                                ) {
                                                                                    if (
                                                                                        rightPanelSettings[
                                                                                            index
                                                                                        ]
                                                                                            .lesson_settings
                                                                                            .assets
                                                                                            .view
                                                                                            ?.student &&
                                                                                        rightPanelSettings[
                                                                                            index
                                                                                        ]
                                                                                            .lesson_settings
                                                                                            .assets
                                                                                            .view
                                                                                            ?.teacher
                                                                                    ) {
                                                                                        history.push(
                                                                                            `/session/${sessionID}/asset/media?panelIndex=${index}${urlParam}`
                                                                                        );
                                                                                    } else {
                                                                                        history.push(
                                                                                            `/session/${sessionID}/asset/view?panelIndex=${index}${urlParam}`
                                                                                        );
                                                                                    }
                                                                                } else {
                                                                                    if (
                                                                                        rightPanelSettings[
                                                                                            index
                                                                                        ]
                                                                                            .subType ===
                                                                                        "WHITEBOARD"
                                                                                    ) {
                                                                                        if (
                                                                                            rightPanelSettings[
                                                                                                index
                                                                                            ]
                                                                                                .lesson_settings
                                                                                                .whiteboard
                                                                                                ?.student
                                                                                                ?.view &&
                                                                                            rightPanelSettings[
                                                                                                index
                                                                                            ]
                                                                                                .lesson_settings
                                                                                                .whiteboard
                                                                                                ?.teacher
                                                                                                ?.view
                                                                                        ) {
                                                                                            history.push(
                                                                                                `/session/${sessionID}/whiteboard/media?panelIndex=${index}${urlParam}`
                                                                                            );
                                                                                        } else if (
                                                                                            rightPanelSettings[
                                                                                                index
                                                                                            ]
                                                                                                .lesson_settings
                                                                                                .whiteboard
                                                                                        ) {
                                                                                            history.push(
                                                                                                `/session/${sessionID}/whiteboard/view?panelIndex=${index}${urlParam}`
                                                                                            );
                                                                                        } else {
                                                                                            history.push(
                                                                                                `/session/${sessionID}/whiteboard/setting?panelIndex=${index}${urlParam}`
                                                                                            );
                                                                                        }
                                                                                    } else {
                                                                                        history.push(
                                                                                            `/session/${sessionID}/tool?panelIndex=${index}${urlParam}`
                                                                                        );
                                                                                    }
                                                                                }
                                                                            } else if (
                                                                                obj.type ===
                                                                                "VIDEO"
                                                                            ) {
                                                                                if (
                                                                                    !obj.video_settings
                                                                                ) {
                                                                                    history.push(
                                                                                        `/session/${sessionID}/viewTool?panelIndex=${index}${urlParam}`
                                                                                    );
                                                                                } else if (
                                                                                    obj.subType ===
                                                                                    "SCREENSHARE"
                                                                                ) {
                                                                                    history.push(
                                                                                        `/session/${sessionID}/screenshare?panelIndex=${index}${urlParam}`
                                                                                    );
                                                                                } else {
                                                                                    history.push(
                                                                                        `/session/${sessionID}/view?panelIndex=${index}${urlParam}`
                                                                                    );
                                                                                }
                                                                            } else if (
                                                                                obj.type ===
                                                                                "ACTIVITY"
                                                                            ) {
                                                                                if (
                                                                                    rightPanelSettings[
                                                                                        index
                                                                                    ]
                                                                                        .subType
                                                                                ) {
                                                                                    history.push(
                                                                                        `/session/${sessionID}/activity/customise?panelIndex=${index}${urlParam}`
                                                                                    );
                                                                                } else {
                                                                                    history.push(
                                                                                        `/session/${sessionID}/activity/list?panelIndex=${index}${urlParam}`
                                                                                    );
                                                                                }
                                                                            }
                                                                        }
                                                                    }}
                                                                >
                                                                    {index !==
                                                                        panelIndex && (
                                                                        <>
                                                                            <FontAwesomeIcon
                                                                                className={
                                                                                    styles.minusicon
                                                                                }
                                                                                style={{
                                                                                    width: "30px",
                                                                                    position:
                                                                                        "absolute",
                                                                                    left: 0,
                                                                                }}
                                                                                icon={
                                                                                    faPlus
                                                                                }
                                                                                onClick={(
                                                                                    ev
                                                                                ) => {
                                                                                    ev.stopPropagation();
                                                                                    if (
                                                                                        show !==
                                                                                        null
                                                                                    ) {
                                                                                        setShow(
                                                                                            null
                                                                                        );
                                                                                    } else {
                                                                                        setShow(
                                                                                            index
                                                                                        );
                                                                                        setRightPanelIndex(
                                                                                            index
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            />
                                                                            {show ===
                                                                                index && (
                                                                                <div
                                                                                    className={
                                                                                        styles.paneloption
                                                                                    }
                                                                                >
                                                                                    <p
                                                                                        onClick={(
                                                                                            event
                                                                                        ) => {
                                                                                            event.preventDefault();
                                                                                            handleImportOption(
                                                                                                index
                                                                                            );
                                                                                            setShow(
                                                                                                null
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        Use
                                                                                        Existing
                                                                                    </p>
                                                                                </div>
                                                                            )}
                                                                            <FontAwesomeIcon
                                                                                className={
                                                                                    styles.minusicon
                                                                                }
                                                                                style={{
                                                                                    width: "30px",
                                                                                    position:
                                                                                        "absolute",
                                                                                    right: 0,
                                                                                }}
                                                                                icon={
                                                                                    faMinus
                                                                                }
                                                                                onClick={(
                                                                                    ev
                                                                                ) => {
                                                                                    ev.stopPropagation();
                                                                                    removeLessionBlock(
                                                                                        index
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}

                                                                    <div
                                                                        className={
                                                                            styles.customDropContainer
                                                                        }
                                                                    >
                                                                        <CustomDropdown
                                                                            dropdownData={
                                                                                lessonData
                                                                            }
                                                                            width="100%"
                                                                            margin="auto"
                                                                            setData={(
                                                                                payload
                                                                            ) => {
                                                                                updateRightPanelSettings(
                                                                                    index,
                                                                                    payload.toUpperCase(),
                                                                                    payload,
                                                                                    (
                                                                                        rpSettings
                                                                                    ) =>
                                                                                        updateSession(
                                                                                            rpSettings,
                                                                                            () => {
                                                                                                if (
                                                                                                    payload ===
                                                                                                    "Lesson"
                                                                                                ) {
                                                                                                    history.push(
                                                                                                        `/session/${sessionID}/tool?panelIndex=${index}${urlParam}`
                                                                                                    );
                                                                                                } else if (
                                                                                                    payload ===
                                                                                                    "Video"
                                                                                                ) {
                                                                                                    history.push(
                                                                                                        `/session/${sessionID}/viewTool?panelIndex=${index}${urlParam}`
                                                                                                    );
                                                                                                } else if (
                                                                                                    payload ===
                                                                                                    "Activity"
                                                                                                ) {
                                                                                                    history.push(
                                                                                                        `/session/${sessionID}/activity/list?panelIndex=${index}${urlParam}`
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                        )
                                                                                );
                                                                            }}
                                                                            data={
                                                                                obj.label
                                                                                    ? `${obj.label}`
                                                                                    : handleData(
                                                                                          obj.type
                                                                                      )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {importModal && (
                                                                    <div
                                                                        className={
                                                                            styles.importModal
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            className={
                                                                                styles.crossIcon
                                                                            }
                                                                            icon={
                                                                                faTimes
                                                                            }
                                                                            onClick={
                                                                                handleImportModal
                                                                            }
                                                                        />
                                                                        <p
                                                                            className={
                                                                                styles.classModalText
                                                                            }
                                                                        >
                                                                            Pick
                                                                            the
                                                                            class
                                                                            you
                                                                            would
                                                                            like
                                                                            to
                                                                            access
                                                                        </p>
                                                                        <div
                                                                            className={
                                                                                styles.modalClassWrapper
                                                                            }
                                                                        >
                                                                            {selectClass ? (
                                                                                <div
                                                                                    className={
                                                                                        styles.modalClassScroll
                                                                                    }
                                                                                >
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <div
                                                                                                className={
                                                                                                    styles.modalClassBlock
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    className={
                                                                                                        styles.modalCoverImage
                                                                                                    }
                                                                                                    src={
                                                                                                        sessionData
                                                                                                            .image_ids[0] &&
                                                                                                        sessionData
                                                                                                            .image_ids[0]
                                                                                                            .location
                                                                                                    }
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                            <p
                                                                                                className={
                                                                                                    styles.modalClassName
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    sessionData.class_name
                                                                                                }
                                                                                            </p>
                                                                                        </Col>
                                                                                        <Col></Col>
                                                                                        <Col></Col>
                                                                                    </Row>
                                                                                    <div
                                                                                        className={
                                                                                            styles.modalLessonWrapper
                                                                                        }
                                                                                    >
                                                                                        {sessionData.configuration.right_panel_settings.map(
                                                                                            (
                                                                                                obj,
                                                                                                index
                                                                                            ) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        className={
                                                                                                            styles.modalLesson
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                            handleRightPanelSetting(
                                                                                                                index
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        <p
                                                                                                            className={
                                                                                                                styles.modalLessonName
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                obj.type
                                                                                                            }
                                                                                                        </p>
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className={
                                                                                        styles.modalClassScroll
                                                                                    }
                                                                                >
                                                                                    <Row>
                                                                                        {sessions.map(
                                                                                            (
                                                                                                obj,
                                                                                                index
                                                                                            ) => {
                                                                                                return (
                                                                                                    <Col
                                                                                                        md={
                                                                                                            4
                                                                                                        }
                                                                                                    >
                                                                                                        <div
                                                                                                            className={
                                                                                                                styles.modalClassBlock
                                                                                                            }
                                                                                                            onClick={(
                                                                                                                event
                                                                                                            ) => {
                                                                                                                handleSelectClass(
                                                                                                                    event,
                                                                                                                    index
                                                                                                                );
                                                                                                            }}
                                                                                                        >
                                                                                                            {obj
                                                                                                                .image_ids
                                                                                                                .length >=
                                                                                                                1 && (
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        position:
                                                                                                                            "relative",
                                                                                                                        height: "100%",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {obj
                                                                                                                        .image_ids
                                                                                                                        .length >
                                                                                                                        1 && (
                                                                                                                        <>
                                                                                                                            <FontAwesomeIcon
                                                                                                                                style={{
                                                                                                                                    color: "#83838b",
                                                                                                                                    width: "30px",
                                                                                                                                    position:
                                                                                                                                        "absolute",
                                                                                                                                    left: "-7px",
                                                                                                                                    marginTop:
                                                                                                                                        "10px",
                                                                                                                                    zIndex: "8",
                                                                                                                                    top: "20px",
                                                                                                                                    cursor: "pointer",
                                                                                                                                }}
                                                                                                                                icon={
                                                                                                                                    faLessThan
                                                                                                                                }
                                                                                                                                onClick={(
                                                                                                                                    event
                                                                                                                                ) => {
                                                                                                                                    event.preventDefault();
                                                                                                                                    event.stopPropagation();
                                                                                                                                    decreaseImgModalFlag(
                                                                                                                                        obj
                                                                                                                                            .image_ids
                                                                                                                                            .length
                                                                                                                                    );
                                                                                                                                }}
                                                                                                                            />
                                                                                                                            <FontAwesomeIcon
                                                                                                                                style={{
                                                                                                                                    color: "#83838b",
                                                                                                                                    width: "30px",
                                                                                                                                    position:
                                                                                                                                        "absolute",
                                                                                                                                    right: "-7px",
                                                                                                                                    marginTop:
                                                                                                                                        "10px",
                                                                                                                                    zIndex: "8",
                                                                                                                                    top: "20px",
                                                                                                                                    cursor: "pointer",
                                                                                                                                }}
                                                                                                                                icon={
                                                                                                                                    faGreaterThan
                                                                                                                                }
                                                                                                                                onClick={(
                                                                                                                                    event
                                                                                                                                ) => {
                                                                                                                                    event.preventDefault();
                                                                                                                                    event.stopPropagation();
                                                                                                                                    increaseImgModalFlag(
                                                                                                                                        obj
                                                                                                                                            .image_ids
                                                                                                                                            .length
                                                                                                                                    );
                                                                                                                                }}
                                                                                                                            />
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                    {obj.image_ids.map(
                                                                                                                        (
                                                                                                                            element,
                                                                                                                            index
                                                                                                                        ) => {
                                                                                                                            const fileType =
                                                                                                                                element.location
                                                                                                                                    .split(
                                                                                                                                        "."
                                                                                                                                    )
                                                                                                                                    .pop();
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    {fileType ===
                                                                                                                                    "mp4"
                                                                                                                                        ? imgModalFlag ===
                                                                                                                                              index && (
                                                                                                                                              <video
                                                                                                                                                  className={
                                                                                                                                                      styles.modalCoverImage
                                                                                                                                                  }
                                                                                                                                                  src={
                                                                                                                                                      element.location
                                                                                                                                                  }
                                                                                                                                                  controls
                                                                                                                                              ></video>
                                                                                                                                          )
                                                                                                                                        : imgModalFlag ===
                                                                                                                                              index && (
                                                                                                                                              <img
                                                                                                                                                  className={
                                                                                                                                                      styles.modalCoverImage
                                                                                                                                                  }
                                                                                                                                                  src={
                                                                                                                                                      element.location
                                                                                                                                                  }
                                                                                                                                                  alt=""
                                                                                                                                              />
                                                                                                                                          )}
                                                                                                                                </>
                                                                                                                            );
                                                                                                                        }
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>
                                                                                                        <p
                                                                                                            className={
                                                                                                                styles.modalClassName
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                obj.class_name
                                                                                                            }
                                                                                                        </p>
                                                                                                    </Col>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Row>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>

                                        <FontAwesomeIcon
                                            className={styles.plusicon}
                                            style={{
                                                width: "30px",
                                            }}
                                            icon={faPlus}
                                            onClick={addLessionBlock}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={2}>
                        <div
                            style={{ position: "relative", cursor: "pointer" }}
                            onClick={() => history.push("/home")}
                        >
                            <img
                                src={Logo}
                                className={styles.logoImg}
                                alt="Logo"
                            />
                            <h1 className={styles.logoText}>
                                plassroom<sup>+</sup>
                            </h1>
                            <p className={styles.logoSubText}>Teacher</p>
                            <h1 className={styles.rightTime}>
                                <Clock />
                            </h1>
                        </div>
                        <div
                            className={`${styles.rightTabSection} ${styles.studentScroll}`}
                        >
                            <FontAwesomeIcon
                                style={{
                                    width: "30px",
                                    marginLeft: "6rem",
                                    cursor: "pointer",
                                    opacity:
                                        rightPanelSettingsToShow[0]?._id !==
                                            rightPanelSettings[0]?._id &&
                                        rightPanelSettings.length > 5
                                            ? 1
                                            : 0,
                                }}
                                icon={faAngleUp}
                                onClick={moveSessionUp}
                            />
                            {rightPanelSettingsToShow.map((obj, index) => {
                                if (
                                    obj._id ===
                                    rightPanelSettings[panelIndex]._id
                                ) {
                                    return (
                                        <div
                                            className={styles.rightTabContainer}
                                        >
                                            <h1
                                                contentEditable="true"
                                                className={styles.rightTab}
                                                onBlur={(ev) => {
                                                    handleRightTab(ev, index);
                                                }}
                                                onKeyDown={(e) =>
                                                    handleTextChange(e)
                                                }
                                                id={`righttab${index}`}
                                            >
                                                {obj.label ? (
                                                    <>{obj.label}</>
                                                ) : (
                                                    <>{rightTab(obj.type)}</>
                                                )}
                                            </h1>
                                            <img
                                                src={Edit}
                                                className={styles.editImg}
                                                alt="Logo"
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor: "#C8E8E5",
                                            }}
                                            className={styles.rightTabContainer}
                                        >
                                            <h1
                                                onBlur={(ev) => {
                                                    handleRightTab(ev, index);
                                                }}
                                                onKeyDown={(e) =>
                                                    handleTextChange(e)
                                                }
                                                contentEditable="true"
                                                className={styles.rightTab}
                                                id={`righttab${index}`}
                                            >
                                                {obj.label ? (
                                                    <>{obj.label}</>
                                                ) : (
                                                    <>{rightTab(obj.type)}</>
                                                )}
                                            </h1>
                                        </div>
                                    );
                                }
                            })}
                            <FontAwesomeIcon
                                style={{
                                    width: "30px",
                                    marginLeft: "6rem",
                                    cursor: "pointer",
                                    opacity:
                                        rightPanelSettingsToShow[
                                            rightPanelSettingsToShow.length - 1
                                        ]?._id !==
                                            rightPanelSettings[
                                                rightPanelSettings.length - 1
                                            ]?._id &&
                                        rightPanelSettings.length > 5
                                            ? 1
                                            : 0,
                                }}
                                icon={faAngleDown}
                                onClick={moveSessionDown}
                            />
                        </div>
                    </Col>
                </Row>
            )}{" "}
        </div>
    );
};
