import axios from "axios";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import Navbar from "../../components/Navbar";
import Snackbar from "../../components/Snackbar";
import ChatBox from "./components/ChatBox";
import RecipientsList from "./components/RecipientsList";
import styles from "./index.module.scss";

const Chat = () => {
    const location = useLocation();
    const history = useHistory();

    const currentScreenWidth = window.screen.width;

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [messagesList, setMessagesList] = useState([]);
    const [searchedUser, setSearchedUser] = useState("");
    const [selectedUser, setSelectedUser] = useState();
    const [passedClassDetails, setPassedClassDetails] = useState();
    const [selectedRoomId, setSelectedRoomId] = useState();
    const [isChatInitiated, setIsChatInitiated] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
    const [loadingFetchUsers, setLoadingFetchUsers] = useState(true);
    const [loadingChatHistory, setLoadingChatHistory] = useState(false);
    const [isParticipantRemoved, setIsParticipantRemoved] = useState(false);

    // For mobile screens
    const [showChatScreen, setShowChatScreen] = useState(
        currentScreenWidth > 865
    );

    const [snackBarStatus, setSnackBarStatus] = useState("");
    const [msg, setMsg] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);

    /**
     * @description Get user authentication.
     */
    useEffect(() => {
        if (localStorage.getItem("auth")) {
            setIsUserAuthenticated(true);
        } else {
            const classDetails = _.get(location, "state.classDetails");

            history.replace("/login?redirect=/chat", {
                classDetails,
            });
        }

        // eslint-disable-next-line
    }, []);

    /**
     * @description Select user if it's passed from Chat screen.
     */
    useEffect(() => {
        const passedteacherId =
            selectedRoomId || _.get(passedClassDetails, "teacher_id._id");

        if (isUserAuthenticated && passedteacherId) {
            const teacher = usersList.find(
                (user) => user._id === passedteacherId
            );
            if (teacher) {
                setPassedClassDetails();
                setSelectedRoomId();
                handleSelectedUser(teacher);
            }
        }

        // eslint-disable-next-line
    }, [location, passedClassDetails, isUserAuthenticated, usersList]);

    /**
     * @description Check selected user on each fetched user list to check new unread messages.
     */
    useEffect(() => {
        if (selectedUser) handleCheckUnreadMessages();

        // eslint-disable-next-line
    }, [selectedUser, usersList]);

    /**
     * @description Initiate the chat if user is logged in.
     */
    useEffect(() => {
        const classDetails = _.get(location, "state.classDetails");
        const type = _.get(location, "state.type");

        if (isUserAuthenticated && classDetails) {
            setIsChatInitiated(true);
            setPassedClassDetails(classDetails);
            handleInitiateChat(classDetails, type);
        }

        // eslint-disable-next-line
    }, [location, isUserAuthenticated]);

    /**
     * @description Fetch users to chat with. API is called every 60 seconds.
     */
    useEffect(() => {
        if (isUserAuthenticated && !isChatInitiated) {
            handleFetchUsers();

            const delayDebounceFunction = setInterval(() => {
                handleFetchUsers();
            }, 100000000);

            return () => clearInterval(delayDebounceFunction);
        }

        // eslint-disable-next-line
    }, [isChatInitiated, searchedUser, isUserAuthenticated]);

    const handleFetchUsers = () => {
        setLoadingFetchUsers(true);
        const query = "?search=" + searchedUser;

        axios
            .get("/api/v1/chat/userList" + query)
            .then((response) => {
                if (_.get(response, "data.success")) {
                    setUsersList(_.get(response, "data.data", []));
                } else {
                    setSnackBarStatus("error");
                    setMsg(
                        _.get(response, "data.title", "Something went wrong.")
                    );
                    setShowSnackbar(true);
                }
            })
            .catch((error) => {
                console.error(error.response);
                setSnackBarStatus("error");
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
            })
            .finally(() => {
                setLoadingFetchUsers(false);
                setLoadingPage(false);
            });
    };

    /**
     * @description Initiate the chat if user is logged in.
     */
    const handleInitiateChat = (classDetails, type) => {
        const requestBody = {
            sessionId: _.get(classDetails, "_id"),
            // sentTo: classDetails.teacher_id._id,
        };

        axios
            .post(
                `api/v1/chat/${
                    type === "group" ? "initiate-group-chat" : "initiate"
                }`,
                requestBody
            )
            .then((response) => {
                if (response.data.success) {
                    if (type === "group") {
                        setSelectedRoomId(_.get(response, "data.data.room_id"));
                    }
                } else {
                    console.error(response);
                    setSnackBarStatus("error");
                    setMsg(
                        _.get(response, "data.title", "Something went wrong.")
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                console.error(error.response);

                setSnackBarStatus("error");
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
            })
            .finally(() => {
                setIsChatInitiated(false);
            });
    };

    /**
     * @description Check selected user on each fetched user list to check new unread messages.
     */
    const handleCheckUnreadMessages = () => {
        const user = usersList.find((user) => user._id === selectedUser._id);

        if (_.get(user, "NoOfUnreadMessages")) {
            handleFetchChatHistory(user);
        }
    };

    /**
     * @description Select user from Recipient List.
     */
    const handleSelectedUser = (user) => {
        const selectedUser = user;

        setSelectedUser(selectedUser);
        setShowChatScreen(true);
        handleFetchChatHistory(selectedUser);
    };

    /**
     * @description Fetch chat history of the selected user.
     */
    const handleFetchChatHistory = (user) => {
        if (_.get(user, "_id")) {
            setLoadingChatHistory(true);
            const query = "?chatType=" + _.get(user, "ChatType");

            axios
                .get("/api/v1/chat/history/" + _.get(user, "_id") + query)
                .then((response) => {
                    if (_.get(response, "data.success")) {
                        setMessagesList(_.get(response, "data.data.chat"));
                        setIsParticipantRemoved(
                            _.get(response, "data.data.participation_removed")
                        );
                    } else {
                        setSnackBarStatus("error");
                        setMsg(
                            _.get(
                                response,
                                "data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                    }
                })
                .catch((error) => {
                    console.error(error.response);
                    setSnackBarStatus("error");
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                })
                .finally(() => {
                    setLoadingChatHistory(false);
                });
        }
    };

    return (
        <Fragment>
            <Snackbar
                status={snackBarStatus}
                message={msg}
                open={showSnackbar}
                setOpen={setShowSnackbar}
            />

            <div className={styles.mainContainer}>
                <Navbar isUserAuthenticated={isUserAuthenticated} />

                <div className={styles.pageContainer}>
                    <div className={styles.contentContainer}>
                        {loadingPage ? (
                            <Loading />
                        ) : (
                            <Fragment>
                                {currentScreenWidth > 865 || !showChatScreen ? (
                                    <RecipientsList
                                        usersList={usersList}
                                        handleSelectedUser={handleSelectedUser}
                                        selectedUser={selectedUser}
                                        setSearchedUser={setSearchedUser}
                                        loadingFetchUsers={loadingFetchUsers}
                                    />
                                ) : null}

                                {currentScreenWidth > 865 || showChatScreen ? (
                                    <ChatBox
                                        selectedUser={selectedUser}
                                        chatsList={messagesList}
                                        loadingChatHistory={loadingChatHistory}
                                        isParticipantRemoved={
                                            isParticipantRemoved
                                        }
                                        setShowChatScreen={setShowChatScreen}
                                    />
                                ) : null}
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Chat;
