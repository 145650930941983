import { faFolder, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fade, Modal } from "@material-ui/core";
import axios from "axios";
import _ from "lodash";
import React, {
    Fragment,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { useDropzone } from "react-dropzone";
import Snackbar from "../../../../components/Snackbar";
import {
    DocumentFormats,
    MusicFormats,
    VideoFormats,
    ImageFormats,
} from "../../utils/AcceptedFileFormats";
import styles from "./ImageInputModal.module.scss";
import ImageInputModalSelectedFiles from "./ImageInputModalSelectedFiles";
import SavedFolderImages from "./SavedFolderImages";

const ShowMoreComponent = () => {
    return (
        <div className={styles.modalWrapper}>
            <p>Types of files you can upload</p>

            <p>Video: MP4, AVI, MOV</p>
            <p>Images: JPEG, GIF, SVG, TIF</p>
            <p>Audio: M4A, MP3, WAVE</p>
            <p>
                Documents: PDF, DOC, DOCX, HTML, XLS, XLSX, TXT, PPT, ODP, KEY
            </p>

            <p>Size of each file is less than 5MB</p>
        </div>
    );
};

const ImageInputModal = ({
    isImageModalOpen,
    setIsImageModalOpen,
    sendMessageFunction,
    selectedFiles,
    setSelectedFiles,
}) => {
    const [showReadMore, setShowReadMore] = useState(false);
    const [showSavedFolder, setShowSavedFolder] = useState(false);
    const [savedImages, setSavedImages] = useState([]);

    const [snackBarStatus, setSnackBarStatus] = useState("");
    const [msg, setMsg] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);

    const inputFile = useRef(null);

    /**
     * @description Get the images for saved folder.
     */
    useEffect(() => {
        if (isImageModalOpen) fetchSavedImages();
        // eslint-disable-next-line
    }, [isImageModalOpen]);

    const fetchSavedImages = () => {
        axios
            .get("/api/v1//user/gallery")
            .then((response) => {
                const { success, data } = _.get(response, "data");
                if (success) {
                    setSavedImages(_.get(data, "gallery", []));
                } else {
                    setShowSnackbar(true);
                    setMsg(data, "title", "something went wrong.");
                    setSnackBarStatus("error");
                }
            })
            .catch((error) => {
                console.error(error.response);

                setShowSnackbar(true);
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setSnackBarStatus("error");
            });
    };

    /**
     * @description Add or remove selected file to send.
     */
    const handleSelectedFile = (newFile) => {
        const fileIndex = selectedFiles.findIndex(
            (file) => file._id === newFile._id
        );

        if (fileIndex === -1) {
            setSelectedFiles((prevSelectedFiles) => {
                return [...prevSelectedFiles, newFile];
            });
        } else {
            const prevSelectedFiles = [...selectedFiles];
            prevSelectedFiles.splice(fileIndex, 1);
            setSelectedFiles(prevSelectedFiles);
        }
    };

    /**
     * @description Handle the files selected to upload by the user.
     */
    const handleUploadSelectedFiles = (event) => {
        const uploadedFiles = _.get(event, "target.files");
        const filesToSelect = [];

        // eslint-disable-next-line
        [...uploadedFiles].map((file, index) => {
            if (file.type) {
                const maxFileSize = 5 * 1024 * 1024;

                const location = URL.createObjectURL(file);
                const _id = `${index}${new Date().getTime()}`;
                const type = file.type.split("/")[0].toUpperCase();
                const fileFormat = _.last(
                    _.split(_.get(file, "name", ""), ".")
                ).toUpperCase();
                let errorMessage = "";

                if (file.size > maxFileSize) {
                    errorMessage =
                        "You can not upload files of size more than 5MB.";
                } else if (
                    ![
                        ...DocumentFormats,
                        ...MusicFormats,
                        ...ImageFormats,
                        ...VideoFormats,
                    ].includes(fileFormat)
                ) {
                    errorMessage =
                        "Please check the available file formats to upload.";
                } else {
                    filesToSelect.push({
                        location,
                        _id,
                        type,
                        file,
                    });
                }

                if (errorMessage) {
                    setShowSnackbar(true);
                    setMsg(errorMessage);
                    setSnackBarStatus("error");
                    errorMessage = "";
                }
            }
        });

        setSelectedFiles((prevSelectedFiles) => {
            return [...prevSelectedFiles, ...filesToSelect];
        });
    };

    /**
     * @description Handle click on "Upload from computer" button.
     */
    const handleOpenInput = () => {
        inputFile.current.click();
    };

    /**
     * @description Handle send message.
     */
    const handleSendMessage = (files) => {
        const filesToSend = selectedFiles.length ? selectedFiles : files;

        if (filesToSend.length) {
            if (filesToSend.length > 8) {
                setShowSnackbar(true);
                setMsg("You can not upload more than 8 files at once.");
                setSnackBarStatus("error");
            } else {
                sendMessageFunction();
            }
        }
        handleClose();
    };

    /**
     * @description Close modal and clear all state.
     */
    const handleClose = () => {
        setIsImageModalOpen(false);
        setShowSavedFolder(false);
        setShowReadMore(false);
        // setSelectedFiles([]);
    };

    /**
     * @description Get the dropped file here.
     */
    const onDrop = useCallback((acceptedFiles) => {
        const event = {
            target: {
                files: acceptedFiles,
            },
        };
        handleUploadSelectedFiles(event);

        // eslint-disable-next-line
    }, []);

    const { getRootProps } = useDropzone({
        onDrop,
    });

    return (
        <Fragment>
            <Snackbar
                status={snackBarStatus}
                message={msg}
                open={showSnackbar}
                setOpen={setShowSnackbar}
            />

            <Modal
                className={styles.ImageInputModalContainer}
                open={isImageModalOpen}
                onClose={handleClose}
            >
                <Fade in={isImageModalOpen}>
                    <div className={styles.ImageInputModalBody}>
                        <div className={styles.crossIconContainer}>
                            <FontAwesomeIcon
                                className={styles.crossIcon}
                                icon={faTimes}
                                onClick={handleClose}
                            />
                        </div>

                        {!showReadMore ? (
                            !showSavedFolder ? (
                                <div
                                    className={styles.modalWrapper}
                                    {...getRootProps()}
                                >
                                    {selectedFiles.length ? null : (
                                        <p className={styles.titleText}>
                                            Drag and drop your image or video
                                            files here or
                                        </p>
                                    )}

                                    <div
                                        className={
                                            styles.uploadFromComputerButton
                                        }
                                        onClick={handleOpenInput}
                                    >
                                        <p>Upload from Computer</p>

                                        <span>
                                            <FontAwesomeIcon
                                                className={styles.uploadIcon}
                                                icon={faPlus}
                                            />
                                        </span>

                                        <input
                                            type="file"
                                            id="file"
                                            ref={inputFile}
                                            onChange={handleUploadSelectedFiles}
                                            multiple
                                        />
                                    </div>

                                    <div
                                        className={
                                            styles.uploadFromSavedFolderButton
                                        }
                                        onClick={() => setShowSavedFolder(true)}
                                    >
                                        <span>
                                            <FontAwesomeIcon
                                                className={styles.uploadIcon}
                                                icon={faFolder}
                                            />
                                        </span>

                                        <p>Select from Saved Folder</p>
                                    </div>

                                    {selectedFiles.length ? (
                                        <ImageInputModalSelectedFiles
                                            selectedFiles={selectedFiles}
                                            handleSelectedFile={
                                                handleSelectedFile
                                            }
                                        />
                                    ) : null}
                                </div>
                            ) : (
                                <div className={styles.modalWrapper}>
                                    <SavedFolderImages
                                        savedImages={savedImages}
                                        selectedFiles={selectedFiles}
                                        handleSelectedFile={handleSelectedFile}
                                    />
                                </div>
                            )
                        ) : (
                            <ShowMoreComponent />
                        )}

                        {!showReadMore && !showSavedFolder ? (
                            <p className={styles.readMoreText}>
                                Want to know which types of files are supported?{" "}
                                <span onClick={() => setShowReadMore(true)}>
                                    Read More
                                </span>
                            </p>
                        ) : null}

                        {!showReadMore ? (
                            !showSavedFolder ? (
                                <div
                                    className={styles.finishButton}
                                    onClick={handleSendMessage}
                                >
                                    {selectedFiles.length ? "Send" : "Close"}
                                </div>
                            ) : (
                                <div
                                    className={
                                        styles.savedFolderNavigationButtonContainer
                                    }
                                >
                                    <div
                                        className={styles.backButton}
                                        onClick={() =>
                                            setShowSavedFolder(false)
                                        }
                                    >
                                        Back
                                    </div>

                                    <div
                                        className={styles.finishButton}
                                        onClick={() =>
                                            setShowSavedFolder(false)
                                        }
                                    >
                                        Next
                                    </div>
                                </div>
                            )
                        ) : (
                            <div
                                className={styles.backButton}
                                onClick={() => setShowReadMore(false)}
                            >
                                Back
                            </div>
                        )}
                    </div>
                </Fade>
            </Modal>
        </Fragment>
    );
};

export default ImageInputModal;
