import React, { Component } from "react";

class Clock extends Component {
    state = {
        time: new Date(),
    };

    componentDidMount() {
        this.timerID = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            time: new Date(),
        });
    }

    render() {
        return (
            <>
                {this.state.time.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                })}
            </>
        );
    }
}

export default Clock;
