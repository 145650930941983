import CrowdCheer from "../../assets/audio/1.Crowd cheer.mp3";
import Tadaa from "../../assets/audio/2.Tadaa.mp3";
import Yay from "../../assets/audio/3.Yay.mp3";
import BellEnd from "../../assets/audio/4.Bell end.mp3";
import ClockChime from "../../assets/audio/5.Clock chime.mp3";
import OrganEnd from "../../assets/audio/6.Organ end.mp3";
import HarpMelody from "../../assets/audio/7.Harp melody.mp3";
import TrumpetCall from "../../assets/audio/8.Trumpet call.mp3";
import WhistleStart from "../../assets/audio/9.Whistle start.wav";
import CrowdAww from "../../assets/audio/10.Crowd aww.mp3";
import Investigate from "../../assets/audio/11.Investigate.mp3";
import NegativeSound from "../../assets/audio/13.Wrong answer.mp3";
import WrongAnswer from "../../assets/audio/4.Bell end.mp3";
import ReadySet from "../../assets/audio/14.Ready,set,go.wav";
import BirdTweet from "../../assets/audio/15.Bird Tweet.mp3";
import CatMeow from "../../assets/audio/16.Cat Meow.wav";
import ChickenClucks from "../../assets/audio/17.Chicken Clucks.mp3";
import DogBark from "../../assets/audio/18.Dog Bark.wav";
import LionRoar from "../../assets/audio/19.Lion Roar.mp3";
import SnakeHiss from "../../assets/audio/20.Snake Hiss.wav";

//graphics

import HappyFaceOne from "../../assets/images/Happy Face 1.gif";
import HappyFace from "../../assets/images/graphics/2.Happy Face 2.gif";
import SadFace from "../../assets/images/graphics/3.Sad Face 1.gif";
import Applause from "../../assets/images/graphics/4.Applause 1.gif";
import ThumbsUp from "../../assets/images/graphics/5.Thumbs Up 1.gif";
import ThumbsUp2 from "../../assets/images/graphics/6.Thumbs Up 2.gif";
import Star1 from "../../assets/images/graphics/7.Star PNG 1.gif";
import Star2 from "../../assets/images/graphics/8.Star PNG 2.gif";
import ShootingStar from "../../assets/images/graphics/9.Shooting Star.gif";
import PlayTime from "../../assets/images/graphics/10.Play Time 4.gif";
import PlayTime2 from "../../assets/images/graphics/11.Play Time 5.gif";
import PlayTime3 from "../../assets/images/graphics/12.Play Time 6.gif";
import Crown1 from "../../assets/images/graphics/13.Crown 1.gif";
import Crown2 from "../../assets/images/graphics/14.Crown 2.gif";
import Trophy1 from "../../assets/images/graphics/15.Trophy 1.gif";
import Trophy2 from "../../assets/images/graphics/16.Trophy 2.gif";
import Heart from "../../assets/images/graphics/17.Heart Emoji 1.gif";
import MusicNotes from "../../assets/images/graphics/18.Music Notes.gif";
import Hand1 from "../../assets/images/graphics/19.Waving Hand 2.gif";
import Hand2 from "../../assets/images/graphics/20.Waving Hand 3.gif";
import Hand3 from "../../assets/images/graphics/21.Waving Hand 4.gif";
import Hand4 from "../../assets/images/graphics/22.Waving Hand 5.gif";
import Wavinghand4 from "../../assets/images/Waving Hand 4.gif";
import Wavinghand5 from "../../assets/images/Waving Hand 5.gif";

const graphics = [
    HappyFaceOne,
    HappyFace,
    SadFace,
    Applause,
    ThumbsUp,
    ThumbsUp2,
    Star1,
    Star2,
    ShootingStar,
    PlayTime,
    PlayTime2,
    PlayTime3,
    Crown1,
    Crown2,
    Trophy1,
    Trophy2,
    Heart,
    MusicNotes,
    Hand1,
    Hand2,
    Hand3,
    Hand4,
    Wavinghand4,
    Wavinghand5,
];

const audioEffects = [
    {
        name: "Crowd cheer",
        audio: CrowdCheer,
    },
    {
        name: "Tadaa",
        audio: Tadaa,
    },
    {
        name: "Yay",
        audio: Yay,
    },
    {
        name: "Bell end",
        audio: BellEnd,
    },
    {
        name: "Clock chime",
        audio: ClockChime,
    },
    {
        name: "Organ end",
        audio: OrganEnd,
    },
    {
        name: "Harp melody",
        audio: HarpMelody,
    },
    {
        name: "Trumpet call",
        audio: TrumpetCall,
    },
    {
        name: "Whistle start",
        audio: WhistleStart,
    },
    {
        name: "Crowd aww",
        audio: CrowdAww,
    },
    {
        name: "Investigate",
        audio: Investigate,
    },
    {
        name: "Negative sound",
        audio: NegativeSound,
    },
    {
        name: "Wrong answer",
        audio: WrongAnswer,
    },
    {
        name: "Ready set go",
        audio: ReadySet,
    },
    {
        name: "Bird Tweet",
        audio: BirdTweet,
    },
    {
        name: "Cat Meow",
        audio: CatMeow,
    },
    {
        name: "Chicken Clucks",
        audio: ChickenClucks,
    },
    {
        name: "Dog Bark",
        audio: DogBark,
    },
    {
        name: "Lion Roar",
        audio: LionRoar,
    },
    {
        name: "Snake Hiss",
        audio: SnakeHiss,
    },
];

export { audioEffects, graphics };
