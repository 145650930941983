import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
// import StarRatings from "react-star-ratings";
import CarouselSmall from "../../components/Carousels/CarouselSmall";
import ChatForm from "../../components/ChatForm";
import Loading from "../../components/Loading/Loading";
import Navbar from "../../components/Navbar";
import Snackbar from "../../components/Snackbar";
// import { currencySymbol } from "../../utils/constants";
// import ParentsReview from "./components/ParentsReview/ParentsReview";
import styles from "./index.module.scss";
import registerBtn from "../../assets/images/NewDesign/register.png";
import FooterNew from "../../components/FooterNew";
import ParentReview from "../GuestUser/components/ParentReview";
import TeacherIntro from "../GuestUser/components/TeacherIntro";
import RelatedClasses from "../GuestUser/components/RelatedClasses";
import InstagramCarousel from "../GuestUser/InstagramCarousel";
import Badge from "../GuestUser/components/Badge";
import BreadCrumbs from "../../components/BreadCrumbs";
import { getCurrencySymbol } from "../../utils/commonFunction";
import RegisterSessionCarousel from "../../components/Carousels/RegisterSessionCarousel";
const ClassDetailsNew = (props) => {
    const currentUserRole = JSON.parse(localStorage.getItem("auth"))?.user
        ?.role;
    let history = useHistory();
    const classID = props.match.params.classID;

    const [classDetails, setClassDetails] = useState({});
    const [ratingAndReviews, setRatingAndReviews] = useState({});
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [recommendedClasses, setRecommendedClasses] = useState([]);

    // const UrlText = (text) => {
    //     const urlRegex = /(https?:\/\/[^\s]+)/g;

    //     return text.split(urlRegex).map((part, i) => {
    //         if (part.match(urlRegex)) {
    //             return (
    //                 <a
    //                     href={part}
    //                     target={"_blank"}
    //                     key={i}
    //                     rel="noopener noreferrer"
    //                 >
    //                     {part}
    //                 </a>
    //             );
    //         }
    //         return part;
    //     });
    // };

    const fetchRecommendedClass = () => {
        let url =
            currentUserRole === "student"
                ? "/api/v1/home/studentRecommendedClasses?perPage=4"
                : "/api/v1/home/recommendedClasses?perPage=4";
        axios
            .get(url)
            .then((response) => {
                if (response?.data?.success) {
                    setRecommendedClasses(response?.data?.data?.sessions);
                }
            })
            .catch((error) => {
                // console.log(error)
            });
    };

    const fetchClassDetails = () => {
        const url = "/api/v1/home/session/" + classID + "/details";
        setLoading(true);

        axios
            .get(url)
            .then((response) => {
                if (response.data.success) {
                    if (
                        response.data?.data?.session?.session_type ===
                        "UNPUBLISHED"
                    ) {
                        history.push("/404?redirect=home");
                    } else {
                        setClassDetails(response.data?.data?.session);
                        setRatingAndReviews(
                            response.data?.data?.session?.ratingAndReviews
                        );
                    }
                } else {
                    setMsg(
                        _.get(response, "data.message", "Something went wrong.")
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                    history.push("/home");
                }, 3000);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchClassDetails();
        fetchRecommendedClass();
        window.scroll(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <div className={styles.mainContainer}>
                <Navbar />

                <div className={styles.pageContainer}>
                    <div className={styles.contentContainer}>
                        {loading ? (
                            <Loading />
                        ) : (
                            <>
                                <article className={styles.classArticle}>
                                    <section className={styles.classHeading}>
                                        <BreadCrumbs
                                            links={[
                                                { to: "/home", label: "Home" },
                                                {
                                                    to: "/classes",
                                                    label: "Upcoming Sesions",
                                                },
                                                {
                                                    to: "/class-details/",
                                                    label: `${classDetails?.class_name}`,
                                                },
                                            ]}
                                            class={styles.breadCrumb}
                                        />
                                    </section>

                                    <section className={styles.classBody}>
                                        <section className={styles.sectionLeft}>
                                            <div className={styles.classTitle}>
                                                <h1>
                                                    {classDetails?.class_name ||
                                                        "-"}
                                                </h1>
                                            </div>
                                            <div
                                                className={
                                                    styles.courseDescription
                                                }
                                            >
                                                <h4>
                                                    {classDetails?.type ===
                                                    "Session"
                                                        ? "Session"
                                                        : "Course"}{" "}
                                                    Description:
                                                </h4>
                                                <p>
                                                    {
                                                        classDetails?.class_description
                                                    }
                                                </p>
                                            </div>
                                            <div className={styles.detailBody}>
                                                <div
                                                    className={
                                                        styles.detailBlock
                                                    }
                                                >
                                                    <h2>
                                                        {
                                                            classDetails
                                                                ?.configuration
                                                                ?.max_students
                                                        }
                                                    </h2>
                                                    <p>Students</p>
                                                </div>
                                                <div
                                                    className={
                                                        styles.detailBlock
                                                    }
                                                >
                                                    <h2>
                                                        {
                                                            classDetails
                                                                ?.configuration
                                                                ?.age_group
                                                        }
                                                    </h2>
                                                    <p>Ages</p>
                                                </div>
                                                <div
                                                    className={
                                                        styles.detailBlock
                                                    }
                                                >
                                                    <h2>
                                                        {
                                                            classDetails
                                                                ?.configuration
                                                                ?.session_duration
                                                        }
                                                    </h2>
                                                    <p>Mins</p>
                                                </div>
                                                <div
                                                    className={
                                                        styles.detailBlock
                                                    }
                                                    style={{
                                                        background:
                                                            classDetails?.is_free_class &&
                                                            "#fdd7c6",
                                                    }}
                                                >
                                                    {classDetails?.type ===
                                                    "Session" ? (
                                                        <>
                                                            <h2>
                                                                {classDetails?.is_free_class
                                                                    ? "Free"
                                                                    : currentUserRole ===
                                                                      "teacher"
                                                                    ? `${getCurrencySymbol(
                                                                          classDetails
                                                                              .configuration
                                                                              ?.fee
                                                                              ?.currency
                                                                      )}${
                                                                          classDetails
                                                                              .configuration
                                                                              ?.fee
                                                                              ?.amount /
                                                                              100 ||
                                                                          0
                                                                      }`
                                                                    : `${getCurrencySymbol(
                                                                          classDetails
                                                                              .configuration
                                                                              ?.fee
                                                                              ?.currency
                                                                      )}${parseFloat(
                                                                          classDetails
                                                                              .configuration
                                                                              ?.fee
                                                                              ?.amount /
                                                                              100 ||
                                                                              0
                                                                      )}`}
                                                            </h2>
                                                            <p>
                                                                {!classDetails?.is_free_class
                                                                    ? "Per Session"
                                                                    : ""}
                                                            </p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h2>
                                                                {
                                                                    classDetails
                                                                        ?.class_slots
                                                                        ?.length
                                                                }
                                                            </h2>
                                                            <p>Sessions</p>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </section>

                                        <section
                                            className={styles.sectionRight}
                                        >
                                            <figure>
                                                <CarouselSmall
                                                    carouselImgs={
                                                        classDetails?.image_ids
                                                    }
                                                    autoPlay={true}
                                                />
                                            </figure>
                                            <div
                                                className={
                                                    styles.registerSection
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.registerPrice
                                                    }
                                                >
                                                    {classDetails?.type ===
                                                        "Course" && (
                                                        <h4>
                                                            Course of{" "}
                                                            {
                                                                classDetails
                                                                    ?.class_slots
                                                                    ?.length
                                                            }{" "}
                                                            sessions:{" "}
                                                            <span>
                                                                {classDetails?.is_free_class
                                                                    ? "free"
                                                                    : `${getCurrencySymbol(
                                                                          classDetails
                                                                              .configuration
                                                                              ?.fee
                                                                              ?.currency
                                                                      )}${parseFloat(
                                                                          classDetails
                                                                              .configuration
                                                                              ?.fee
                                                                              ?.amount /
                                                                              100 ||
                                                                              0
                                                                      )}`}
                                                            </span>
                                                        </h4>
                                                    )}
                                                    {classDetails?.type ===
                                                        "Course" &&
                                                        currentUserRole !==
                                                            "teacher" && (
                                                            <img
                                                                src={
                                                                    registerBtn
                                                                }
                                                                style={{
                                                                    width: "7rem",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() =>
                                                                    history.push(
                                                                        `/course-register/${classID}`
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                </div>
                                            </div>
                                        </section>
                                    </section>
                                </article>
                                {classDetails?.class_slots &&
                                    classDetails?.class_slots.length > 0 && (
                                        <div className={styles.upcoming}>
                                            <h3>
                                                {classDetails?.type === "Course"
                                                    ? "Course sessions"
                                                    : "Available dates and times"}

                                                {classDetails?.type ===
                                                    "Course" && (
                                                    <p
                                                        style={{
                                                            margin: "5px 5px 0px",
                                                        }}
                                                    >
                                                        {
                                                            classDetails
                                                                ?.class_slots
                                                                .length
                                                        }{" "}
                                                        sessions included
                                                    </p>
                                                )}
                                            </h3>
                                            <RegisterSessionCarousel
                                                classDetails={classDetails}
                                                currentUserRole={
                                                    currentUserRole
                                                }
                                            />
                                        </div>
                                    )}
                            </>
                        )}
                    </div>
                    {ratingAndReviews.length ? (
                        <ParentReview
                            ratingAndReviews={ratingAndReviews}
                            page={"classDetail"}
                        />
                    ) : null}
                    <TeacherIntro classDetails={classDetails} />
                    {recommendedClasses.length ? (
                        <RelatedClasses
                            classList={recommendedClasses}
                            title="Other sessions you might be intersted in"
                        />
                    ) : null}
                    <InstagramCarousel />
                    <Badge
                    // setMsg={setMsg}
                    // setShowSnackbar={setShowSnackbar}
                    // setSnackBarStatus={setSnackBarStatus}
                    />
                    <FooterNew />
                </div>
            </div>
            {classDetails ? (
                <ChatForm classDetails={classDetails?.session} />
            ) : null}
        </Fragment>
    );
};

export default ClassDetailsNew;
