export const currencySymbol = {
    GBP: "£",
    EURO: "€",
    USD: "$",
};

export const learningObjectivesData = [
    "Languages",
    "Music",
    "Arts and Craft",
    "Social/Life Skills",
    "Tech",
    "English",
    "Science",
    "Travel/Geography",
    "History",
    "Math",
];

export const educationalPreferenceData = [
    "Preschool",
    "Primary school",
    "High school / secondory",
    "Home education",
    "Flexischolling",
    "Others",
];

export const feePlanData = [
    { label: "Class", value: "CLASS" },
    { label: "Term", value: "TERM" },
];

export const lessonData = [
    {
        label: "Video",
        value: "Video",
    },
    {
        label: "Lesson",
        value: "Lesson",
    },
    { label: "Activity", value: "Activity" },
];

export const recurringClassesData = [
    { label: "Daily", value: "DAILY" },
    { label: "Weekly", value: "WEEKLY" },
    { label: "Biweekly", value: "BIWEEKLY" },
    { label: "Monthly", value: "MONTHLY" },
];

export const maxStudentsData = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
];

export const ageGroupDataFrom = [
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
];

export const ageGroupDataTo = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
];

export const feeCurrencyData = [
    { label: "GBP £", value: "GBP", symbol: "£" },
    { label: "EUR €", value: "EUR", symbol: "€" },
    { label: "USD $", value: "USD", symbol: "$" },
];

export const daysWithAmPm = [
    { day: "Monday", AM: false, PM: false, selected: false },
    { day: "Tuesday", AM: false, PM: false, selected: false },
    { day: "Wednesday", AM: false, PM: false, selected: false },
    { day: "Thursday", AM: false, PM: false, selected: false },
    { day: "Friday", AM: false, PM: false, selected: false },
    { day: "Saturday", AM: false, PM: false, selected: false },
    { day: "Sunday", AM: false, PM: false, selected: false },
];
