import axios from "axios";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Participant from "../../../../../../components/Participant";
import Snackbar from "../../../../../../components/Snackbar";
import StudentSpotlight from "../../../../../../components/StudentSpotlight";
import TwilioParticipant from "../../../../../../components/TwilioParticipant";
import Whiteboard from "../../../../../../components/WhiteboardByNirajan";
import styles from "./Layout1.module.css";

export default ({
    stickerInfo,
    drawData,
    socket,
    selectedItem,
    updateSelectedItem,
    myMarkerColor,
    streams,
    role,
    endUserSession,
    muteUser,
    unMuteUser,
    publishAudio,
    muteAllUsers,
    unMuteAllUsers,
    mutedUsers,
    endEverySession,
    participants,
    room,
    key,
    currentPanel,
    session,
}) => {
    const rowRef = useRef();
    var Teacher = null;
    var Participants = [];
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [initialState, setInitaialState] = useState(false);
    const [fitToScreen, setFitToScreen] = useState(
        currentPanel.lesson_settings.whiteboard.fitToScreen
    );
    const [teacherDrawData, setTeacherDrawData] = useState([]);
    const [showFeed, setShowFeed] = useState(false);
    const [selectedStudentToFocus, setSelectedStudentToFocus] = useState(-1);

    // setFitToScreen(!fitToScreen);
    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;
    const [studentsData, setStudentsData] = useState([]);

    const userIds = participants.map((participant) => {
        var identityObj = JSON.parse(participant.identity);
        return identityObj._id;
    });

    useEffect(() => {
        const getUserDetails = async () => {
            await axios
                .post(`/api/v1/parents`, {
                    data: userIds,
                })
                .then((studentsResult) => {
                    const users = studentsResult.data.data.users;
                    let filteredUsers = [];
                    users.map((user) => {
                        const tempData = {};
                        tempData.name = user.students[0].studentpreferedname;
                        tempData._id = user.students[0]._id;
                        tempData.parentId = user._id;

                        filteredUsers.push(tempData);
                        return 0;
                    });
                    setStudentsData(filteredUsers);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        if (currentUserRole === "teacher") getUserDetails();
        // eslint-disable-next-line
    }, [participants.length]);

    useEffect(() => {
        const getSelectedMedia = async () => {
            const selectedMediaArray =
                currentPanel.lesson_settings?.whiteboard?.image_ids;

            if (selectedMediaArray.length) {
                let galleryData = {
                    selectedMediaArray: selectedMediaArray,
                    userId: session.teacher_id,
                };
                await axios
                    .post(`/api/v1/user/gallery/selected`, galleryData)
                    .then((data) => {
                        if (data.data.success) {
                            const resultMedia = data.data.data.newArray;
                            setSelectedMedia(resultMedia);
                        }
                    })
                    .catch((error) => {
                        setMsg(
                            _.get(
                                error,
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                        return Promise.reject(error);
                    });
            }
        };

        const getDrawData = async () => {
            let teacherData = [];
            drawData.forEach((obj) => {
                teacherData.push(obj.draw_data);
            });

            setTeacherDrawData(teacherData);
            setInitaialState(true);
        };
        getSelectedMedia();
        getDrawData();
        // eslint-disable-next-line
    }, [drawData]);

    if (currentUserRole === "teacher") {
        Teacher = (
            <TwilioParticipant
                key={room.localParticipant.sid}
                participant={room.localParticipant}
                role={"teacher"}
                focusStudent={() => {}}
                selectedStudentToFocus={selectedStudentToFocus}
            />
        );

        let studentParticipants = participants.map((participant, index) => {
            var identityObj = JSON.parse(participant.identity);
            const studentName = studentsData.find(
                (student) => student.parentId === identityObj._id
            );
            return (
                <TwilioParticipant
                    isMutedUser={mutedUsers.indexOf(identityObj._id) !== -1}
                    key={participant.sid}
                    role={"student"}
                    participant={participant}
                    publishAudio={publishAudio}
                    muteUser={() => muteUser(identityObj._id)}
                    unMuteUser={() => unMuteUser(identityObj._id)}
                    endUserSession={() => endUserSession(identityObj._id)}
                    focusStudent={() => {
                        setSelectedStudentToFocus(index);
                    }}
                    screen="teacher"
                    studentName={studentName?.name}
                    index={index}
                    selectedStudentToFocus={selectedStudentToFocus}
                />
            );
        });
        Participants = studentParticipants;
    }

    let noOfParticipants = Participants.length;
    let participantBoxes = [];

    for (let i = 0; i < noOfParticipants; i++) {
        participantBoxes.push(
            <Col md="auto">
                <div
                    className={`${styles.block} ${
                        showFeed ? styles.smallRowDiv : ""
                    }`}
                    style={{
                        width: rowRef
                            ? rowRef.current?.offsetWidth / noOfParticipants -
                              30
                            : "",
                    }}
                >
                    <Participant>{Participants[i]}</Participant>
                </div>
            </Col>
        );
    }

    return (
        <>
            <div className={styles.assets}>
                {msg && (
                    <Snackbar
                        status="error"
                        message={msg}
                        open={showSnackbar}
                        setOpen={setShowSnackbar}
                    ></Snackbar>
                )}
                {initialState && (
                    <div className={styles.whiteboardContainer}>
                        <div className={styles.whiteboard}>
                            <div style={{ width: "1239px", height: "645px" }}>
                                <Whiteboard
                                    myWhiteboard
                                    drawData={teacherDrawData}
                                    socket={socket}
                                    // streams={streams}
                                    // role={role}
                                    participants={participants}
                                    selectedMedia={selectedMedia}
                                    // myMarkerColor={myMarkerColor}
                                    key={key}
                                    position={
                                        currentPanel.lesson_settings.whiteboard
                                            .position
                                    }
                                    fitToScreen={fitToScreen}
                                    setFitToScreen={setFitToScreen}
                                    studentAccess={
                                        currentPanel.lesson_settings.whiteboard
                                            .student_access
                                    }
                                    control={true}
                                    requiredWB={true}
                                    session={session}
                                    panelID={currentPanel._id}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className={`${styles.block} ${styles.blockMedia}`}>
                    <Participant>{Teacher}</Participant>
                </div>
                <div className={styles.rowDiv}>
                    <Row style={{ justifyContent: "center" }} ref={rowRef}>
                        {participantBoxes}
                    </Row>
                </div>

                <div
                    className={styles.showFeedButton}
                    onClick={() => {
                        setShowFeed((prevFlag) => {
                            return !prevFlag;
                        });
                    }}
                >
                    Video Feed
                </div>

                {showFeed ? (
                    <div className={styles.allRowDiv}>
                        <Row style={{ justifyContent: "center" }} ref={rowRef}>
                            <Col md="auto">
                                <div
                                    className={styles.smallBlock}
                                    style={{
                                        width: rowRef
                                            ? rowRef.current?.offsetWidth /
                                                  noOfParticipants -
                                              30
                                            : "",
                                    }}
                                >
                                    <Participant>{Teacher}</Participant>
                                </div>
                            </Col>
                            {participantBoxes}
                        </Row>
                    </div>
                ) : null}

                <StudentSpotlight
                    selectedStudentToFocus={selectedStudentToFocus}
                    Participants={Participants}
                    closeModal={() => {
                        setSelectedStudentToFocus(-1);
                    }}
                />
            </div>
        </>
    );
};
