import {
    faAngleDown,
    faAngleUp,
    faGreaterThan,
    faLessThan,
    faMinus,
    faPlus,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useParams, useHistory } from "react-router-dom";
import AltImg from "../../../../assets/images/backgroundldpi.svg";
import BubblePop from "../../../../assets/images/Bubble pop.svg";
import CloseButton from "../../../../assets/images/Close_button.png";
import Edit from "../../../../assets/images/edit.png";
import View1 from "../../../../assets/images/i_v student1.svg";
import View2 from "../../../../assets/images/i_v student2.svg";
import View3 from "../../../../assets/images/i_v student3.svg";
import View4 from "../../../../assets/images/i_v student4.svg";
import ViewT1 from "../../../../assets/images/i_v teacher1.svg";
import ViewT2 from "../../../../assets/images/i_v teacher2.svg";
import ViewT3 from "../../../../assets/images/i_v teacher3.svg";
import ViewT4 from "../../../../assets/images/i_v teacher4.svg";
import Note from "../../../../assets/images/note.svg";
import Logo from "../../../../assets/images/plass_logo_new.svg";
import PlayIcon from "../../../../assets/images/PlayIcon.png";
import GreyPlayIcon from "../../../../assets/images/GreyPlayIcon.png";
import ScreenIcon from "../../../../assets/images/ScreenArrangement.png";
import UploadContent from "../../../../assets/images/UploadContent.png";
import Clock from "../../../../components/Clock";
import CustomDropdown from "../../../../components/CustomDropdown";
import GameInitModal from "../../../../components/GameInitModal";
import Snackbar from "../../../../components/Snackbar";
import styles from "./customise.module.css";
// import DemoGame from "./Game1";
// import DemoGameTwo from "./Game2";
import WarningModal from "../../../../components/WarningModal";

export default () => {
    const { sessionID } = useParams();
    const location = useLocation();
    const panelindex = Number(
        new URLSearchParams(location.search).get("panelIndex")
    );
    const panelSlotId =
        new URLSearchParams(location.search).get("slotId") || null;
    const [rightPanelSettings, setRightPanelSettings] = useState([]);
    const [rightPanelSettingsToShow, setRightPanelSettingsToShow] = useState(
        []
    );
    const [panelIndex, setPanelIndex] = useState(panelindex);
    const [gameTab, setGameTab] = useState("Game");
    const [imageType, setImageType] = useState(undefined);
    const [grid, setGrid] = useState(undefined);
    const [timer, setTimer] = useState();
    const [scoreboard, setScoreBoard] = useState();
    const [turnBased, setTurnBased] = useState();
    const [gallery, setGallery] = useState([]);
    const [upload, setUpload] = useState(false);
    const [sceneNumber, setSceneNumber] = useState(3);
    const [answerChoice, setAnswerChoice] = useState();
    const [answerImageModal, setAnswerImageModal] = useState(false);
    const [answerImageId, setAnswerImageId] = useState([]);
    const [answerImageGallery, setAnswerImageGallery] = useState([]);
    const [view, setView] = useState("CUSTOMIZE");
    const [studentView, setStudentView] = useState(undefined);
    const [teacherView, setTeacherView] = useState(undefined);
    const [type, setType] = useState(undefined);
    const [rightPanelIndex, setRightPanelIndex] = useState(undefined);
    const [sessions, setSessions] = useState([]);
    const [imgModalFlag, setImgModalFlag] = useState(0);
    const [show, setShow] = useState(undefined);
    const [importModal, setImportModal] = useState(false);
    const [selectClass, setSelectClass] = useState(false);
    const [sessionData, setSessionData] = useState();
    const [msg, setMsg] = useState(null);
    const [backModal, setBackModal] = useState(false);
    const [selectAudio, setSelectAudio] = useState(false);
    const [uploadedMedia, setUploadedMedia] = useState([]);
    const history = useHistory();
    const urlParam = !!panelSlotId ? `&slotId=${panelSlotId}` : ``;

    const [dropdownSceneData, setDropdownSceneData] = useState([
        {
            label: "Scene 1",
            value: "Scene 1",
        },
        {
            label: "Scene 2",
            value: "Scene 2",
        },
        { label: "Scene 3", value: "Scene 3" },
    ]);
    const [sceneData, setSceneData] = useState();
    const [finalImageid, setFinalImageId] = useState([]);
    const [finishModal, setFinishModal] = useState();
    const [category, setCategory] = useState("IMAGE");
    const [savedFolder, setSavedFolder] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [modalFlag, setModalFlag] = useState(0);
    const [versionTime, setVersionTime] = useState(undefined);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [toggleShowMore, setToggleShowMore] = useState(false);
    const [isConfigComplete, setIsConfigComplete] = useState(false);
    const [showDemoGame, setShowDemoGame] = useState(false);
    const [session, setSession] = useState(undefined);
    const [showWarningModal, setShowWarningModal] = useState({
        flag: false,
        type: null,
    });
    const [nextTabData, setNextTabData] = useState({
        obj: null,
        index: null,
    });

    const selectedBGM = useRef();
    const BGMPlaying = useRef();

    useEffect(() => {
        const getSessionInfo = async () => {
            await axios
                .get(`/api/v1/class/${sessionID}`)
                .then(async (result) => {
                    if (result.data.success) {
                        const resultSession = result.data.data.session;
                        setSession(resultSession);
                        let tempType = undefined;
                        if (
                            ["SNAKES_AND_APPLES", "BUILD_YOUR_DREAM"].indexOf(
                                result.data.data.session.configuration
                                    .right_panel_settings[panelIndex].subType
                            ) !== -1
                        ) {
                            setType(3);
                            tempType = 3;
                        }
                        if (
                            ["BUBBLE_POP", "TILE_MATCH"].indexOf(
                                result.data.data.session.configuration
                                    .right_panel_settings[panelIndex].subType
                            ) !== -1
                        ) {
                            setType(1);
                            tempType = 1;
                        }
                        if (
                            ["TREASURE_HUNT", "INTERACTIVE_STORY"].indexOf(
                                result.data.data.session.configuration
                                    .right_panel_settings[panelIndex].subType
                            ) !== -1
                        ) {
                            setType(1);
                            tempType = 1;
                        }
                        setRightPanelSettings(
                            result.data.data.session.configuration
                                .right_panel_settings
                        );
                        setRightPanelSettingsToShow(
                            result.data.data.session.configuration.right_panel_settings.slice(
                                0,
                                5
                            )
                        );
                        setImageType(
                            result.data.data.session.configuration
                                .right_panel_settings[panelIndex]
                                .activity_settings.image_type
                        );
                        setGrid(
                            result.data.data.session.configuration
                                .right_panel_settings[panelIndex]
                                .activity_settings.total_image
                        );
                        setTimer(
                            result.data.data.session.configuration
                                .right_panel_settings[panelIndex]
                                .activity_settings.timer
                        );
                        setScoreBoard(
                            result.data.data.session.configuration
                                .right_panel_settings[panelIndex]
                                .activity_settings.scoreboard
                        );
                        setTurnBased(
                            result.data.data.session.configuration
                                .right_panel_settings[panelIndex]
                                .activity_settings.turn_based
                        );
                        if (
                            result.data.data.session.configuration
                                .right_panel_settings[panelIndex]
                                .activity_settings.scene_number
                        ) {
                            setSceneNumber(
                                result.data.data.session.configuration
                                    .right_panel_settings[panelIndex]
                                    .activity_settings.scene_number
                            );
                        }
                        setSceneData(
                            result.data.data.session.configuration
                                .right_panel_settings[panelIndex]
                                .activity_settings.scene
                        );

                        setAnswerChoice(
                            result.data.data.session.configuration
                                .right_panel_settings[panelIndex]
                                .activity_settings.answer_choice
                        );

                        let rightPanelSettingsRes =
                            result.data.data.session.configuration
                                .right_panel_settings;
                        let emptyData = false;

                        setIsConfigComplete(
                            rightPanelSettingsRes[panelIndex]
                                .isConfigurationCompleted
                        );

                        let studentViewInit =
                            rightPanelSettingsRes[panelIndex].activity_settings
                                .student_view;
                        if (!studentViewInit) {
                            emptyData = true;
                            studentViewInit = "view1";
                            rightPanelSettingsRes[
                                panelIndex
                            ].activity_settings = {
                                ...rightPanelSettingsRes[panelIndex]
                                    .activity_settings,
                                student_view: "view1",
                            };
                        }
                        setStudentView(studentViewInit);

                        let teacherViewInit =
                            rightPanelSettingsRes[panelIndex].activity_settings
                                .teacher_view;
                        if (!teacherViewInit) {
                            emptyData = true;
                            teacherViewInit = "viewT2";
                            rightPanelSettingsRes[
                                panelIndex
                            ].activity_settings = {
                                ...rightPanelSettingsRes[panelIndex]
                                    .activity_settings,
                                teacher_view: "viewT2",
                            };
                        }
                        setTeacherView(teacherViewInit);

                        setRightPanelSettings(rightPanelSettingsRes);
                        setRightPanelSettingsToShow(
                            rightPanelSettingsRes.slice(0, 5)
                        );
                        if (emptyData)
                            await updateSession(rightPanelSettingsRes);

                        const newArray =
                            result.data.data.session.configuration
                                .right_panel_settings[panelIndex]
                                .activity_settings.image_answer_ids;
                        setAnswerImageId(newArray);
                        getUserGallery();

                        if (tempType === 1) {
                            const localRightPanelSettings =
                                result.data.data.session.configuration
                                    .right_panel_settings[panelIndex]
                                    .activity_settings;
                            let breakFlag = false;

                            if (
                                !localRightPanelSettings.image_type ||
                                localRightPanelSettings.image_ids.length <
                                    localRightPanelSettings.image_type
                            ) {
                                setGameTab("Game");
                                breakFlag = true;
                            }
                            if (
                                !breakFlag &&
                                localRightPanelSettings?.background_audio_ids
                                    .length < 1 &&
                                localRightPanelSettings?.background_image_ids
                                    .length < 1
                            ) {
                                setGameTab("General");
                                breakFlag = true;
                            }
                            if (!breakFlag) {
                                setView("SCREEN");
                            }
                        }

                        if (tempType === 3) {
                            setGameTab("In Class");
                        }
                        const updateTime = moment
                            .utc(
                                result.data.data.session.updatedAt,
                                "DD/MM/YYYY HH:mm"
                            )
                            .local()
                            .format("HH:mm");
                        setVersionTime(updateTime);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        getSessionInfo();
        // eslint-disable-next-line
    }, []);

    const handleDefaultImg = (e) => {
        e.target.src = AltImg;
    };

    const getUserGallery = async () => {
        await axios
            .get("/api/v1//user/gallery")
            .then((result) => {
                if (result.data.success) {
                    const galleryData = result.data.data.gallery.reverse();
                    setGallery(galleryData);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const updateSession = async (newSettings, cb) => {
        if (rightPanelSettings.length > 0) {
            const sessionData = {
                id: sessionID,
                rightPanelSettings: newSettings || rightPanelSettings,
                ...(!!panelSlotId
                    ? {
                          rightPanelSettingsSessionId: panelSlotId,
                      }
                    : {}),
            };
            await axios
                .put("/api/v1/sessions", sessionData)
                .then((result) => {
                    if (result.data.success) {
                        if (cb) {
                            cb();
                        }
                        setVersionTime(moment().format("HH:mm"));
                    } else {
                        setMsg(
                            _.get(
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        }
    };

    const updateRightPanelSettings = (index, type, label, cb) => {
        const updatedRightPanel = rightPanelSettings.map((obj, i) => {
            if (index === i && (!obj.type || obj.type !== type)) {
                return {
                    label,
                    type,
                };
            } else {
                return obj;
            }
        });
        setRightPanelSettings(updatedRightPanel);
        setRightPanelSettingsToShow(updatedRightPanel.slice(0, 5));
        cb(updatedRightPanel);
    };

    const lessonData = [
        {
            label: "Video",
            value: "Video",
        },
        {
            label: "Lesson",
            value: "Lesson",
        },
        { label: "Activity", value: "Activity" },
    ];

    const handleData = (type) => {
        if (type === "VIDEO") {
            return "Video";
        } else if (type === "LESSON") {
            return "Lesson";
        } else if (type === "ACTIVITY") {
            return "Activity";
        }
    };

    const rightTab = (data) => {
        if (data === "VIDEO") {
            return "Video";
        } else if (data === "LESSON") {
            return "Lesson";
        } else if (data === "ACTIVITY") {
            return "Activity";
        }
    };

    const addLessionBlock = async () => {
        const newArray = [...rightPanelSettings, {}];
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
    };

    const moveSessionUp = () => {
        const index = rightPanelSettings.indexOf(rightPanelSettingsToShow[0]);

        if (index > 0) {
            let rightPanelSettingsToShowCopy = [...rightPanelSettingsToShow];
            rightPanelSettingsToShowCopy.pop();
            rightPanelSettingsToShowCopy = [
                rightPanelSettings[index - 1],
                ...rightPanelSettingsToShowCopy,
            ];
            setRightPanelSettingsToShow(rightPanelSettingsToShowCopy);
        }
    };

    const moveSessionDown = () => {
        const index = rightPanelSettings.indexOf(rightPanelSettingsToShow[0]);

        if (index < rightPanelSettings.length - 5) {
            const rightPanelSettingsToShowCopy = [...rightPanelSettingsToShow];
            rightPanelSettingsToShowCopy.shift();
            rightPanelSettingsToShowCopy.push(rightPanelSettings[index + 5]);
            setRightPanelSettingsToShow(rightPanelSettingsToShowCopy);
        }
    };

    const removeLessionBlock = async (index) => {
        if (index < panelIndex) {
            setPanelIndex(panelIndex - 1);
        }
        const newArray = rightPanelSettings
            .slice(0, index)
            .concat(rightPanelSettings.slice(index + 1));
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
    };

    const handleRightTab = async (ev, index) => {
        const newArray = [...rightPanelSettings];
        newArray[index] = {
            ...newArray[index],
            label: ev.target.innerText,
        };
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
    };

    const handleTextChange = (e) => {
        if (e.currentTarget.textContent.length > 6 && e.which !== 8) {
            e.preventDefault();
        }
    };

    const handleGameTab = (element) => {
        setGameTab(element);
    };

    const handleImageType = async (number) => {
        const newArray = [...rightPanelSettings];
        newArray[panelIndex].activity_settings = {
            ...newArray[panelIndex].activity_settings,
            image_type: number,
        };
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        setImageType(number);
        await updateSession(newArray);
    };

    const handleGrid = async (number) => {
        const newArray = [...rightPanelSettings];
        newArray[panelIndex].activity_settings = {
            ...newArray[panelIndex].activity_settings,
            total_image: number,
        };
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        setGrid(number);
        await updateSession(newArray);
    };

    const handleImage = async (obj) => {
        const newArray = [...rightPanelSettings];
        let imageIds = newArray[panelIndex].activity_settings.image_ids;
        let element = imageIds.find((item) => item === obj._id);
        if (element) {
            let filtered = newArray[
                panelIndex
            ].activity_settings.image_ids.filter((item) => item !== element);
            newArray[panelIndex].activity_settings.image_ids = filtered;
            setRightPanelSettings(newArray);
            setRightPanelSettingsToShow(newArray.slice(0, 5));
            const selectedMediaArray =
                newArray[panelIndex].activity_settings.image_ids;
            let galleryData = {
                selectedMediaArray: selectedMediaArray,
                userId: "",
            };
            await axios
                .post(`/api/v1//user/gallery/selected`, galleryData)
                .then(async (data) => {
                    if (data.data.success) {
                        setSelectedMedia(data.data.data.newArray);
                        await updateSession(newArray);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        } else {
            if (imageIds.length < imageType) {
                newArray[panelIndex].activity_settings.image_ids.push(obj._id);
                setRightPanelSettings(newArray);
                setRightPanelSettingsToShow(newArray.slice(0, 5));
                const selectedMediaArray =
                    newArray[panelIndex].activity_settings.image_ids;
                let galleryData = {
                    selectedMediaArray: selectedMediaArray,
                    userId: "",
                };
                await axios
                    .post(`/api/v1//user/gallery/selected`, galleryData)
                    .then(async (data) => {
                        if (data.data.success) {
                            setSelectedMedia(data.data.data.newArray);
                            await updateSession(newArray);
                        }
                    })
                    .catch((error) => {
                        setMsg(
                            _.get(
                                error,
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                        return Promise.reject(error);
                    });
            } else {
            }
            setMsg(
                "Please select the number of image to the number you have chosen for image types"
            );
            setTimeout(function () {
                setMsg(null);
            }, 4000);
        }
    };

    const handleTimer = async (status) => {
        setTimer(status);
        const newRightPanelSettings = [...rightPanelSettings];
        newRightPanelSettings[panelIndex].activity_settings.timer = status;
        setRightPanelSettings(newRightPanelSettings);
        setRightPanelSettingsToShow(newRightPanelSettings.slice(0, 5));
        await updateSession(newRightPanelSettings);
    };

    const handleScoreBoard = async (status) => {
        setScoreBoard(status);
        const newRightPanelSettings = [...rightPanelSettings];
        newRightPanelSettings[panelIndex].activity_settings.scoreboard = status;
        setRightPanelSettings(newRightPanelSettings);
        setRightPanelSettingsToShow(newRightPanelSettings.slice(0, 5));
        await updateSession(newRightPanelSettings);
    };

    const handleTurnBased = async (status) => {
        setTurnBased(status);
        const newRightPanelSettings = [...rightPanelSettings];
        newRightPanelSettings[panelIndex].activity_settings.turn_based = status;
        setRightPanelSettings(newRightPanelSettings);
        setRightPanelSettingsToShow(newRightPanelSettings.slice(0, 5));
        await updateSession(newRightPanelSettings);
    };

    const handleBackgroundImage = async (obj) => {
        const newArray = [...rightPanelSettings];
        let imageIds =
            newArray[panelIndex].activity_settings.background_image_ids;
        let element = imageIds.find((item) => item === obj._id);
        if (element) {
            let filtered = newArray[
                panelIndex
            ].activity_settings.background_image_ids.filter(
                (item) => item !== element
            );
            newArray[panelIndex].activity_settings.background_image_ids =
                filtered;
            setRightPanelSettings(newArray);
            setRightPanelSettingsToShow(newArray.slice(0, 5));
            await updateSession(newArray);
        } else {
            newArray[panelIndex].activity_settings.background_image_ids = [
                obj._id,
            ];

            setRightPanelSettings(newArray);
            setRightPanelSettingsToShow(newArray.slice(0, 5));
            await updateSession(newArray);
        }
    };

    const handlePlayBGM = () => {
        selectedBGM.current.play();
    };

    const handleStopBGM = () => {
        selectedBGM.current.pause();
        selectedBGM.current.removeEventListener(
            "canplaythrough",
            handlePlayBGM
        );
    };

    const handleBackgroundAudio = async (obj) => {
        const newArray = [...rightPanelSettings];
        let imageIds =
            newArray[panelIndex].activity_settings.background_audio_ids;
        let element = imageIds.find((item) => item === obj._id);

        if (element) {
            if (selectedBGM.current) {
                handleStopBGM();
            }

            let filtered = newArray[
                panelIndex
            ].activity_settings.background_audio_ids.filter(
                (item) => item !== element
            );
            newArray[panelIndex].activity_settings.background_audio_ids =
                filtered;
            setRightPanelSettings(newArray);
            setRightPanelSettingsToShow(newArray.slice(0, 5));
            await updateSession(newArray);
        } else {
            if (BGMPlaying.current) {
                clearTimeout(BGMPlaying.current);
            }
            if (selectedBGM.current) handleStopBGM();
            const teacherAudio = new Audio(obj.location);
            selectedBGM.current = teacherAudio;
            selectedBGM.current.addEventListener(
                "canplaythrough",
                handlePlayBGM
            );
            BGMPlaying.current = setTimeout(function () {
                handleStopBGM();
            }, 5000);

            newArray[panelIndex].activity_settings.background_audio_ids = [
                obj._id,
            ];
            setRightPanelSettings(newArray);
            setRightPanelSettingsToShow(newArray.slice(0, 5));
            await updateSession(newArray);
        }
    };

    const handleSceneNumber = async (number) => {
        if (number === 5) {
            setDropdownSceneData([
                {
                    label: "Scene 1",
                    value: "Scene 1",
                },
                {
                    label: "Scene 2",
                    value: "Scene 2",
                },
                { label: "Scene 3", value: "Scene 3" },
                { label: "Scene 4", value: "Scene 4" },
                { label: "Scene 5", value: "Scene 5" },
            ]);
            setSceneNumber(number);
        } else if (number === 7) {
            setDropdownSceneData([
                {
                    label: "Scene 1",
                    value: "Scene 1",
                },
                {
                    label: "Scene 2",
                    value: "Scene 2",
                },
                { label: "Scene 3", value: "Scene 3" },
                { label: "Scene 4", value: "Scene 4" },
                { label: "Scene 5", value: "Scene 5" },
                { label: "Scene 6", value: "Scene 6" },
                { label: "Scene 7", value: "Scene 7" },
            ]);
            setSceneNumber(number);
        } else {
            setDropdownSceneData([
                {
                    label: "Scene 1",
                    value: "Scene 1",
                },
                {
                    label: "Scene 2",
                    value: "Scene 2",
                },
                { label: "Scene 3", value: "Scene 3" },
            ]);
            setSceneNumber(number);
        }
    };

    const openUploadModal = () => {
        setUpload(true);
    };

    const closeUploadModal = () => {
        setUpload(false);
        setSelectAudio(false);
        setUploadedMedia([]);
    };

    const inputFile = useRef(null);

    const handleImageUpload = () => {
        inputFile.current.click();
    };

    const fileSelected = (event) => {
        const extension = event.target.files[0].type
            .split("/")[1]
            .toUpperCase();
        const type = event.target.files[0].type.split("/")[0].toUpperCase();
        if (
            type === "IMAGE" &&
            (extension === "PNG" || extension === "JPEG" || extension === "JPG")
        ) {
            const url = URL.createObjectURL(event.target.files[0]);
            setUploadedMedia((prevData) => {
                return [
                    {
                        data: event.target.files[0],
                        type: type,
                        url,
                    },
                    ...prevData,
                ];
            });
        } else if (
            type === "AUDIO" &&
            (extension === "MP4A" ||
                extension === "MP3" ||
                extension === "MPEG")
        ) {
            setUploadedMedia((prevData) => {
                return [
                    {
                        data: event.target.files[0],
                        type: type,
                        url: event.target.files[0].name,
                    },
                    ...prevData,
                ];
            });
        } else {
            setMsg("Invalid file type");
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        }
        event.target.value = "";
    };

    const handleUploadData = () => {
        const prevData = uploadedMedia;
        prevData.map((data) => {
            handleUploadImage(data.data, data.type);
        });
    };

    const handleUploadImage = async (fileData, type) => {
        if (fileData) {
            let formData = new FormData();
            formData.append("image", fileData);
            formData.append("session_id", sessionID);
            formData.append("type", type);
            await axios
                .post("/api/v1/user/gallery/upload", formData)
                .then((result) => {
                    if (result.data.success) {
                        getUserGallery();
                        closeUploadModal();
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                });
        } else {
            setMsg("upload one file");
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        }
    };

    const handleRemoveUploadedMedia = (obj) => {
        const prevData = uploadedMedia;

        const sortedData = prevData.filter((tempData) => tempData !== obj);
        setUploadedMedia(sortedData);
    };

    const handleBackModal = async () => {
        // console.log(view, gameTab);
        if (view === "SCREEN") {
            setView("CUSTOMIZE");
            setGameTab("In Class");
        } else if (view === "CUSTOMIZE") {
            if (gameTab === "In Class") {
                if (type === 3) {
                    const newArray = [...rightPanelSettings];
                    newArray[panelIndex].activity_settings = {};
                    setRightPanelSettings(newArray);
                    setRightPanelSettingsToShow(newArray.slice(0, 5));
                    await updateSession(newArray);
                    history.push(
                        `/session/${sessionID}/activity/list?panelIndex=${panelIndex}${urlParam}`
                    );
                } else {
                    setGameTab("General");
                }
            } else if (gameTab === "General") {
                setGameTab("Game");
            } else if (gameTab === "Game") {
                setBackModal(true);
            }
        }
    };

    const handleAnswerChoice = async (number) => {
        // const newArray = [...rightPanelSettings];
        // newArray[panelIndex].activity_settings = {
        //   ...newArray[panelIndex].activity_settings,
        //   answer_choice: number,
        // };
        // setRightPanelSettings(newArray);
        // setRightPanelSettingsToShow(newArray.slice(0, 5));
        setAnswerChoice(number);
        // await updateSession(newArray);
    };

    const handleBackgroundQuestion = async (obj) => {
        const newArray = [...rightPanelSettings];
        newArray[panelIndex].activity_settings = {
            ...newArray[panelIndex].activity_settings,
            background_question_ids: obj._id,
        };
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
    };

    const handleImageAnswer = async (obj) => {
        const newArray = [...rightPanelSettings];
        let imageIds = newArray[panelIndex].activity_settings.image_answer_ids;
        let element = imageIds.find((item) => item === obj._id);
        if (element) {
            let filtered = newArray[
                panelIndex
            ].activity_settings.image_answer_ids.filter(
                (item) => item !== element
            );
            newArray[panelIndex].activity_settings.image_answer_ids = filtered;
            setRightPanelSettings(newArray);
            setRightPanelSettingsToShow(newArray.slice(0, 5));
            setAnswerImageId(filtered);
            await updateSession(newArray);
        } else if (
            answerChoice >
            rightPanelSettings[panelIndex].activity_settings.image_answer_ids
                .length
        ) {
            newArray[panelIndex].activity_settings.image_answer_ids.push(
                obj._id
            );
            setRightPanelSettings(newArray);
            setRightPanelSettingsToShow(newArray.slice(0, 5));
            await updateSession(newArray);
        } else {
            setMsg("answer choice incorrect");
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        }
    };

    const openAnswerImageModal = async (array) => {
        if (array) {
            let newArray = [];
            for (let i = 0; i < array.length; i++) {
                var item = gallery.find((item) => item._id === array[i]);

                newArray.push(item);
            }

            setAnswerImageGallery(newArray);
        }
        setAnswerImageModal(true);
    };

    const closeImageAnswerModal = async () => {
        setAnswerImageModal(false);
    };

    const handleView = (view) => {
        setView(view);
    };

    const onSelectStudentView = async (selectedView) => {
        const newArray = [...rightPanelSettings];
        newArray[panelIndex].activity_settings = {
            ...newArray[panelIndex].activity_settings,
            student_view: selectedView,
        };
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        setStudentView(selectedView);
        await updateSession(newArray);
    };

    const onSelectTeacherView = async (selectedView) => {
        const newArray = [...rightPanelSettings];
        newArray[panelIndex].activity_settings = {
            ...newArray[panelIndex].activity_settings,
            teacher_view: selectedView,
        };
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        setTeacherView(selectedView);
        await updateSession(newArray);
    };

    const handleLocalSaveData = async () => {
        const newArray = [...rightPanelSettings];
        newArray[panelIndex].activity_settings = {
            ...newArray[panelIndex].activity_settings,
            image_type: imageType,
            total_image: grid,
            timer: timer,
            scoreboard: scoreboard,
            scene_number: sceneNumber,
            answer_choice: answerChoice,
            scene: sceneData,
            turn_based: turnBased,
        };
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
    };

    const handleNext = async () => {
        const newArray = [...rightPanelSettings];
        if (
            newArray[panelIndex].activity_settings.image_ids.length ===
                imageType ||
            type === 3
        ) {
            if (gameTab === "Game") {
                newArray[panelIndex].activity_settings = {
                    ...newArray[panelIndex].activity_settings,
                    image_type: imageType,
                    total_image: grid,
                    timer: timer,
                    scoreboard: scoreboard,
                    scene_number: sceneNumber,
                    answer_choice: answerChoice,
                    scene: sceneData,
                    turn_based: turnBased,
                };
                setGameTab("General");
                setRightPanelSettings(newArray);
                setRightPanelSettingsToShow(newArray.slice(0, 5));
                await updateSession(newArray);
            } else if (gameTab === "General") {
                setGameTab("In Class");
                await updateSession(rightPanelSettings);
            } else if (gameTab === "In Class") {
                setView("SCREEN");
            }
        } else {
            setMsg(
                "Please select the number of image to the number you have chosen for image types"
            );
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        }
    };

    const handleFinalAnswerImmage = (obj) => {
        const newArray = [...finalImageid];
        newArray.push(obj._id);
        // setRightPanelSettings(newArray);
        setFinalImageId(newArray);
    };

    const handleFinalAnswer = () => {
        const newArray = [...rightPanelSettings];
        newArray[panelIndex].activity_settings.final_image_answer_ids =
            finalImageid;
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        setAnswerImageModal(false);
    };

    const checkConfiguration = async () => {
        const localRightPanel = [...rightPanelSettings];
        const currentLocalPanel = localRightPanel[panelIndex];
        if (
            (currentLocalPanel.subType === "BUBBLE_POP" &&
                currentLocalPanel.activity_settings.image_type &&
                currentLocalPanel.activity_settings.image_ids.length ===
                    currentLocalPanel.activity_settings.image_type &&
                currentLocalPanel.activity_settings.total_image &&
                currentLocalPanel.activity_settings.student_view &&
                currentLocalPanel.activity_settings.teacher_view) ||
            (currentLocalPanel.subType === "BUILD_YOUR_DREAM" &&
                currentLocalPanel.activity_settings.student_view &&
                currentLocalPanel.activity_settings.teacher_view)
        ) {
            currentLocalPanel.isConfigurationCompleted = true;
            setFinishModal(true);
            setIsConfigComplete(true);
            localRightPanel.panelIndex = currentLocalPanel;
            // console.log(currentLocalPanel, " currentLocalPanel >>");
            await updateSession(localRightPanel);
        }
    };

    const handleFinish = async () => {
        await checkConfiguration();
    };

    const closeFinishModal = () => {
        setFinishModal(false);
    };

    const handleSavedFolder = () => {
        setSavedFolder(!savedFolder);
    };

    const handleCategories = (category) => {
        setCategory(category);
    };

    const decreaseModalFlag = () => {
        if (modalFlag !== 0) {
            setModalFlag(modalFlag - 1);
        }
    };

    const increaseModalFlag = () => {
        if (modalFlag < uploadedMedia.length - 3) {
            setModalFlag(modalFlag + 1);
        }
    };

    const handleRightPanelSetting = async (index) => {
        const rightPanelData =
            sessionData.configuration.right_panel_settings[index];
        const newArray = rightPanelSettings;
        newArray[rightPanelIndex] = rightPanelData;
        newArray[rightPanelIndex]._id =
            sessionData.configuration.right_panel_settings[rightPanelIndex]._id;
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        setImportModal(false);
        setSelectClass(false);
        await updateSession(newArray);
    };

    const handleImportModal = () => {
        setImportModal(false);
        setSelectClass(false);
    };

    const handleSelectClass = async (event, id) => {
        event.preventDefault();
        await axios
            .get("/api/v1/sessions")
            .then((result) => {
                if (result.data.success) {
                    const data = result.data.sessions.find(
                        (element, index) => index === id
                    );
                    setSessionData(data);
                }
                setSelectClass(true);
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const decreaseImgModalFlag = (length) => {
        if (imgModalFlag > 0) {
            setImgModalFlag(imgModalFlag - 1);
        } else {
            setImgModalFlag(length - 1);
        }
    };

    const increaseImgModalFlag = (length) => {
        if (imgModalFlag < length - 1) {
            setImgModalFlag(imgModalFlag + 1);
        } else {
            setImgModalFlag(0);
        }
    };

    const handleImportOption = async (index) => {
        await axios
            .get("/api/v1/sessions")
            .then((result) => {
                if (result.data.success) {
                    setSessions(result.data.sessions);
                }
                setImportModal(true);
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const handleBack = async () => {
        const newArray = [...rightPanelSettings];
        newArray[panelIndex].activity_settings = {};
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
        history.push(
            `/session/${sessionID}/activity/list?panelIndex=${panelIndex}${urlParam}`
        );
    };

    // const controlAudio = () => {
    //   let x = document.getElementById("myAudio");
    //   setAudio(!audio);
    //   if (!audio) {
    //     x.play();
    //   } else {
    //     x.pause();
    //   }
    // };

    const handleLessonAssets = async (obj) => {
        // const newArray = [...rightPanelSettings];
        // newArray[panelIndex].activity_settings.background_audio_ids.push(obj._id);
        // setRightPanelSettings(newArray);
        // await updateSession(newArray);
        // await getSelectedMedia();
    };

    const handleShowWarningModal = async (decision, moveToOtherTab) => {
        setShowWarningModal({
            flag: false,
            type: null,
        });

        if (moveToOtherTab) {
            handleMoveToOtherTab(true);
        } else if (decision) {
            await updateSession();
            history.push(`/session/${sessionID}`);
        }
    };

    const handleMoveToOtherTab = async (flag, nextObj) => {
        setShowWarningModal({
            flag: false,
            type: null,
        });

        if (flag) {
            await updateSession();

            const obj = nextObj ? nextObj.obj : nextTabData.obj;
            const index = nextObj ? nextObj.index : nextTabData.index;
            if (obj.type === "LESSON") {
                if (rightPanelSettings[index].subType === "ASSET") {
                    if (
                        rightPanelSettings[index].lesson_settings.assets.view
                            ?.student &&
                        rightPanelSettings[index].lesson_settings.assets.view
                            ?.teacher
                    ) {
                        history.push(
                            `/session/${sessionID}/asset/media?panelIndex=${index}${urlParam}`
                        );
                    } else {
                        history.push(
                            `/session/${sessionID}/asset/view?panelIndex=${index}${urlParam}`
                        );
                    }
                } else {
                    if (rightPanelSettings[index].subType === "WHITEBOARD") {
                        if (
                            rightPanelSettings[index].lesson_settings.whiteboard
                                ?.student?.view &&
                            rightPanelSettings[index].lesson_settings.whiteboard
                                ?.teacher?.view
                        ) {
                            history.push(
                                `/session/${sessionID}/whiteboard/media?panelIndex=${index}${urlParam}`
                            );
                        } else if (
                            rightPanelSettings[index].lesson_settings.whiteboard
                        ) {
                            history.push(
                                `/session/${sessionID}/whiteboard/view?panelIndex=${index}${urlParam}`
                            );
                        } else {
                            history.push(
                                `/session/${sessionID}/whiteboard/setting?panelIndex=${index}${urlParam}`
                            );
                        }
                    } else {
                        history.push(
                            `/session/${sessionID}/tool?panelIndex=${index}${urlParam}`
                        );
                    }
                }
            } else if (obj.type === "VIDEO") {
                if (!obj.video_settings) {
                    history.push(
                        `/session/${sessionID}/viewTool?panelIndex=${index}${urlParam}`
                    );
                } else if (obj.subType === "SCREENSHARE") {
                    history.push(
                        `/session/${sessionID}/screenshare?panelIndex=${index}${urlParam}`
                    );
                } else {
                    history.push(
                        `/session/${sessionID}/view?panelIndex=${index}${urlParam}`
                    );
                }
            } else if (obj.type === "ACTIVITY") {
                if (rightPanelSettings[index].subType) {
                    history.push(
                        `/session/${sessionID}/activity/customise?panelIndex=${index}${urlParam}`
                    );
                } else {
                    history.push(
                        `/session/${sessionID}/activity/list?panelIndex=${index}${urlParam}`
                    );
                }
            }
        }
    };

    const handlePreview = () => {
        // setShowPreview(!showPreview);
        history.push({
            pathname: `/session/${sessionID}/preview`,
            state: {
                // handlePreview: handlePreview,
                currentPanel: rightPanelSettings[panelIndex],
                panelIndex: panelIndex,
                session: session,
            },
        });
    };

    return (
        <div>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            {showWarningModal.flag && (
                <WarningModal
                    handleShowWarningModal={handleShowWarningModal}
                    showWarningModal={showWarningModal}
                />
            )}

            {isConfigComplete && showDemoGame ? (
                type === 1 ? (
                    handlePreview()
                ) : // <DemoGame
                //     renderGame={isConfigComplete}
                //     session={session}
                //     currentPanel={rightPanelSettings[panelIndex]}
                //     setIsConfigComplete={setShowDemoGame}
                // />
                type === 3 ? (
                    handlePreview()
                ) : // <DemoGameTwo
                //     renderGame={isConfigComplete}
                //     session={session}
                //     setSession={setSession}
                //     currentPanel={rightPanelSettings[panelIndex]}
                //     setIsConfigComplete={setShowDemoGame}
                // />
                null
            ) : (
                <Row className={styles.row}>
                    <Col xs={10} className={styles.leftsection}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div
                                className={styles.iconsection}
                                onClick={() => {
                                    handleView("CUSTOMIZE");
                                }}
                                style={{
                                    padding:
                                        view === "SCREEN"
                                            ? "4px 34px"
                                            : "6px 34px",
                                    marginRight: view === "SCREEN" ? 0 : "-4px",
                                    zIndex: view === "SCREEN" ? 0 : 22,
                                }}
                            >
                                <img
                                    src={UploadContent}
                                    style={{ width: "40px" }}
                                    alt=""
                                    className={styles.playicon}
                                />
                            </div>
                            <div
                                className={styles.iconsection}
                                onClick={() => {
                                    handleView("SCREEN");
                                }}
                                style={{
                                    padding:
                                        view === "SCREEN"
                                            ? "9px 34px"
                                            : "6px 34px",
                                    marginLeft: view === "SCREEN" ? "-4px" : 0,
                                    zIndex: view === "SCREEN" ? 22 : 0,
                                }}
                            >
                                <img
                                    src={ScreenIcon}
                                    style={{ width: "40px" }}
                                    alt=""
                                    onError={handleDefaultImg}
                                    className={styles.playicon}
                                />
                            </div>

                            <h1
                                className={styles.heading}
                                style={{ marginBottom: 0 }}
                            >
                                {view === "SCREEN"
                                    ? "Screen arrangement"
                                    : " Customisation"}
                            </h1>
                        </div>
                        <div className={styles.leftSection}>
                            <div className={styles.sessionblock}>
                                <div className={styles.crosssection}>
                                    <img
                                        onClick={() => {
                                            !rightPanelSettings[panelIndex]
                                                .isConfigurationCompleted
                                                ? setShowWarningModal({
                                                      flag: true,
                                                      type: "closeButton",
                                                  })
                                                : handleShowWarningModal(true);
                                        }}
                                        className={styles.playicon}
                                        style={{ marginRight: "5px" }}
                                        src={CloseButton}
                                        alt="Logo"
                                        onError={handleDefaultImg}
                                    />
                                    {versionTime && (
                                        <p className={styles.italicPara}>
                                            Last version saved at {versionTime}
                                        </p>
                                    )}
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <h1
                                        className={styles.backBtn}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleBackModal();
                                        }}
                                    >
                                        Back
                                    </h1>
                                    <img
                                        src={
                                            isConfigComplete
                                                ? PlayIcon
                                                : GreyPlayIcon
                                        }
                                        style={{
                                            width: "40px",
                                            marginLeft: "5px",
                                            cursor: isConfigComplete
                                                ? "cursor"
                                                : "default",
                                        }}
                                        alt=""
                                        onClick={() => {
                                            setShowDemoGame(isConfigComplete);
                                            setFinishModal(false);
                                        }}
                                        className={styles.playicon}
                                    />
                                </div>
                                {backModal && (
                                    <div className={styles.uploadModal}>
                                        <div className={styles.finishModal}>
                                            <FontAwesomeIcon
                                                // className={styles.uploadIcon}
                                                style={{
                                                    width: "25px",
                                                    color: "#E36B2D",
                                                    cursor: "pointer",
                                                }}
                                                icon={faTimes}
                                                onClick={() => {
                                                    setBackModal(false);
                                                }}
                                            />
                                        </div>

                                        <div
                                            className={styles.uploadArea}
                                            style={{
                                                margin: "auto",
                                                textAlign: "center",
                                                paddingBottom: "10px",
                                                height: "fit-content",
                                            }}
                                        >
                                            <p>
                                                You will not be able to recover
                                                the changes you have made if you
                                                go back to the activity library
                                                now.
                                            </p>
                                            <p className={styles.dragText}>
                                                Would you still like to proceed?
                                            </p>
                                            <p>
                                                Alternatively, click another
                                                block and begin customising
                                                another activity.
                                            </p>
                                        </div>
                                        <h1
                                            className={styles.backBtnModal}
                                            style={{ float: "left" }}
                                            onClick={() => {
                                                handleBack();
                                            }}
                                        >
                                            Back
                                        </h1>
                                    </div>
                                )}
                                {finishModal && (
                                    <div className={styles.uploadModal}>
                                        <div className={styles.finishModal}>
                                            <FontAwesomeIcon
                                                // className={styles.uploadIcon}
                                                style={{
                                                    width: "25px",
                                                    color: "#E36B2D",
                                                    cursor: "pointer",
                                                }}
                                                icon={faTimes}
                                                onClick={closeFinishModal}
                                            />
                                        </div>

                                        <div
                                            className={styles.uploadArea}
                                            style={{
                                                textAlign: "center",
                                                width: "-webkit-fill-available",
                                            }}
                                        >
                                            <p>Great!</p>
                                            <p className={styles.dragText}>
                                                You can now work on your next
                                                block
                                            </p>
                                            <p> or</p>
                                            <p>
                                                Click to{" "}
                                                <span
                                                    style={{
                                                        color: "#88addb",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        setShowDemoGame(
                                                            isConfigComplete
                                                        )
                                                    }
                                                >
                                                    preview
                                                </span>{" "}
                                                your class so far
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {upload && (
                                    <div className={styles.uploadModal}>
                                        <FontAwesomeIcon
                                            className={styles.crossIcon}
                                            icon={faTimes}
                                            onClick={closeUploadModal}
                                        />
                                        {savedFolder ? (
                                            <>
                                                <div
                                                    className={
                                                        styles.uploadArea
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.mediaType
                                                        }
                                                    >
                                                        {!selectAudio ? (
                                                            <p
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    handleCategories(
                                                                        "IMAGE"
                                                                    );
                                                                }}
                                                            >
                                                                Image
                                                            </p>
                                                        ) : null}
                                                        {selectAudio ? (
                                                            <p
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    handleCategories(
                                                                        "AUDIO"
                                                                    );
                                                                }}
                                                            >
                                                                Audio
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.mediaContent
                                                        }
                                                    >
                                                        <Row
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            {gallery.map(
                                                                (
                                                                    obj,
                                                                    index
                                                                ) => {
                                                                    const objType =
                                                                        obj.type;
                                                                    if (
                                                                        objType ===
                                                                            "VIDEO" &&
                                                                        objType ===
                                                                            category
                                                                    ) {
                                                                        return (
                                                                            <Col
                                                                                md={
                                                                                    3
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.mediaBlockModal
                                                                                    }
                                                                                    onClick={() => {
                                                                                        handleLessonAssets(
                                                                                            obj
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <video
                                                                                        className={
                                                                                            styles.modalCoverImage
                                                                                        }
                                                                                        src={
                                                                                            obj.location
                                                                                        }
                                                                                        controls
                                                                                    ></video>
                                                                                </div>
                                                                            </Col>
                                                                        );
                                                                    } else if (
                                                                        objType ===
                                                                            "AUDIO" &&
                                                                        objType ===
                                                                            category
                                                                    ) {
                                                                        return (
                                                                            <Col
                                                                                md={
                                                                                    3
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.mediaBlockModal
                                                                                    }
                                                                                    onClick={() => {
                                                                                        handleLessonAssets(
                                                                                            obj
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        className={
                                                                                            styles.assets
                                                                                        }
                                                                                        src={
                                                                                            Note
                                                                                        }
                                                                                        onClick={() => {
                                                                                            // controlAudio();
                                                                                        }}
                                                                                        alt=""
                                                                                        onError={
                                                                                            handleDefaultImg
                                                                                        }
                                                                                    />
                                                                                    <audio
                                                                                        id="myAudio"
                                                                                        style={{
                                                                                            width: "100%",
                                                                                            display:
                                                                                                "none",
                                                                                        }}
                                                                                        controls
                                                                                        src={
                                                                                            obj.location
                                                                                        }
                                                                                    ></audio>
                                                                                </div>
                                                                            </Col>
                                                                        );
                                                                    } else if (
                                                                        objType ===
                                                                            "IMAGE" &&
                                                                        objType ===
                                                                            category
                                                                    ) {
                                                                        return (
                                                                            <Col
                                                                                md={
                                                                                    3
                                                                                }
                                                                            >
                                                                                {type ===
                                                                                    1 &&
                                                                                    gameTab ===
                                                                                        "Game" && (
                                                                                        <div
                                                                                            className={
                                                                                                styles.mediaBlockModal
                                                                                            }
                                                                                            onClick={() => {
                                                                                                handleImage(
                                                                                                    obj
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                className={`${
                                                                                                    styles.assets
                                                                                                } ${
                                                                                                    rightPanelSettings[
                                                                                                        panelIndex
                                                                                                    ].activity_settings.image_ids.indexOf(
                                                                                                        obj._id
                                                                                                    ) ===
                                                                                                    -1
                                                                                                        ? null
                                                                                                        : styles.selectedImage
                                                                                                }`}
                                                                                                src={
                                                                                                    obj.location
                                                                                                }
                                                                                                alt=""
                                                                                                onError={
                                                                                                    handleDefaultImg
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                {type ===
                                                                                    1 &&
                                                                                    gameTab ===
                                                                                        "General" && (
                                                                                        <div
                                                                                            className={
                                                                                                styles.mediaBlockModal
                                                                                            }
                                                                                            onClick={() => {
                                                                                                handleBackgroundImage(
                                                                                                    obj
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                className={`${styles.assets}`}
                                                                                                src={
                                                                                                    obj.location
                                                                                                }
                                                                                                alt=""
                                                                                                onError={
                                                                                                    handleDefaultImg
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                            </Col>
                                                                        );
                                                                    }
                                                                    return null;
                                                                }
                                                            )}
                                                        </Row>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <h1
                                                        onClick={() => {
                                                            setSavedFolder(
                                                                false
                                                            );
                                                        }}
                                                        className={
                                                            styles.savedFolderBtn
                                                        }
                                                    >
                                                        Back
                                                    </h1>
                                                    <h1
                                                        className={
                                                            styles.savedFolderBtn
                                                        }
                                                    >
                                                        Next
                                                    </h1>
                                                </div>
                                            </>
                                        ) : !toggleShowMore ? (
                                            <>
                                                <div
                                                    className={
                                                        styles.uploadArea
                                                    }
                                                >
                                                    <p
                                                        className={
                                                            styles.dragText
                                                        }
                                                    >
                                                        Drag and drop your files
                                                        here or
                                                    </p>
                                                    <div
                                                        className={
                                                            styles.uploadMediaModal
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.uploadBtn
                                                            }
                                                            onClick={
                                                                handleImageUpload
                                                            }
                                                        >
                                                            <p
                                                                className={
                                                                    styles.toolText
                                                                }
                                                            >
                                                                Upload From
                                                                Computer
                                                            </p>
                                                            <FontAwesomeIcon
                                                                className={
                                                                    styles.uploadIcon
                                                                }
                                                                style={{
                                                                    width: "25px",
                                                                }}
                                                                icon={faPlus}
                                                            />
                                                            <input
                                                                type="file"
                                                                id="file"
                                                                ref={inputFile}
                                                                style={{
                                                                    display:
                                                                        "none",
                                                                }}
                                                                onChange={
                                                                    fileSelected
                                                                }
                                                                accept={`${
                                                                    selectAudio
                                                                        ? "audio/*"
                                                                        : "image/*"
                                                                }`}
                                                            />
                                                        </div>
                                                        {type === 1 &&
                                                            (gameTab ===
                                                                "Game" ||
                                                                gameTab ===
                                                                    "General") && (
                                                                <>
                                                                    {uploadedMedia.length !==
                                                                        0 && (
                                                                        <div
                                                                            className={
                                                                                styles.modalGalleryWrapper
                                                                            }
                                                                        >
                                                                            {uploadedMedia.length >
                                                                                3 && (
                                                                                <>
                                                                                    <FontAwesomeIcon
                                                                                        style={{
                                                                                            color: "#83838b",
                                                                                            width: "30px",
                                                                                            position:
                                                                                                "absolute",
                                                                                            left: "-2px",
                                                                                            marginTop:
                                                                                                "10px",
                                                                                            zIndex: "8",
                                                                                            top: "30px",
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                        icon={
                                                                                            faLessThan
                                                                                        }
                                                                                        onClick={
                                                                                            decreaseModalFlag
                                                                                        }
                                                                                    />
                                                                                    <FontAwesomeIcon
                                                                                        style={{
                                                                                            color: "#83838b",
                                                                                            width: "30px",
                                                                                            position:
                                                                                                "absolute",
                                                                                            right: "-7px",
                                                                                            marginTop:
                                                                                                "10px",
                                                                                            zIndex: "8",
                                                                                            top: "30px",
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                        icon={
                                                                                            faGreaterThan
                                                                                        }
                                                                                        onClick={
                                                                                            increaseModalFlag
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )}
                                                                            {uploadedMedia
                                                                                .slice(
                                                                                    modalFlag,
                                                                                    modalFlag +
                                                                                        3
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        obj,
                                                                                        index
                                                                                    ) => {
                                                                                        const fileType =
                                                                                            obj.url
                                                                                                .split(
                                                                                                    "."
                                                                                                )
                                                                                                .pop();
                                                                                        return (
                                                                                            <div
                                                                                                className={
                                                                                                    styles.modalImageWrapperContainer
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    className={
                                                                                                        styles.modalImageWrapper
                                                                                                    }
                                                                                                >
                                                                                                    {fileType ===
                                                                                                    "mp4" ? (
                                                                                                        <video
                                                                                                            className={
                                                                                                                styles.modalImageGallery
                                                                                                            }
                                                                                                            src={
                                                                                                                obj.url
                                                                                                            }
                                                                                                            controls
                                                                                                        ></video>
                                                                                                    ) : fileType ===
                                                                                                      "mp3" ? (
                                                                                                        <img
                                                                                                            className={
                                                                                                                styles.modalImageGallery
                                                                                                            }
                                                                                                            style={{
                                                                                                                padding:
                                                                                                                    "18px",
                                                                                                            }}
                                                                                                            src={
                                                                                                                Note
                                                                                                            }
                                                                                                            alt=""
                                                                                                            onError={
                                                                                                                handleDefaultImg
                                                                                                            }
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <img
                                                                                                            className={
                                                                                                                styles.modalImageGallery
                                                                                                            }
                                                                                                            src={
                                                                                                                obj.url
                                                                                                            }
                                                                                                            alt=""
                                                                                                            onError={
                                                                                                                handleDefaultImg
                                                                                                            }
                                                                                                        />
                                                                                                    )}

                                                                                                    <FontAwesomeIcon
                                                                                                        className={
                                                                                                            styles.modalCross
                                                                                                        }
                                                                                                        style={{
                                                                                                            width: "25px",
                                                                                                        }}
                                                                                                        icon={
                                                                                                            faTimes
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                            handleRemoveUploadedMedia(
                                                                                                                obj
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                {fileType ===
                                                                                                "mp3" ? (
                                                                                                    <p
                                                                                                        className={
                                                                                                            styles.modalFileName
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            obj.url
                                                                                                        }
                                                                                                    </p>
                                                                                                ) : null}
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                    </div>
                                                </div>
                                                <p
                                                    style={{
                                                        fontSize: "13px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Want to know which types of
                                                    files are supported?{" "}
                                                    <span
                                                        style={{
                                                            color: "#88ADDB",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            setToggleShowMore(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        Read more
                                                    </span>
                                                </p>
                                                <h1
                                                    className={styles.finishBtn}
                                                    onClick={handleUploadData}
                                                >
                                                    Finish
                                                </h1>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={`${styles.uploadArea} ${styles.dragText}`}
                                                >
                                                    <p>
                                                        Types of files you can
                                                        upload
                                                    </p>

                                                    <p>
                                                        <div>Video: MP4</div>
                                                        <div>
                                                            Audio: MP4A, MP3
                                                        </div>
                                                        <div>
                                                            Images: PNG, JPEG
                                                        </div>
                                                    </p>

                                                    <p>
                                                        Size of each file is
                                                        less than 5MB
                                                    </p>
                                                </div>
                                                <h1
                                                    className={styles.finishBtn}
                                                    onClick={() =>
                                                        setToggleShowMore(false)
                                                    }
                                                    style={{ float: "left" }}
                                                >
                                                    Back
                                                </h1>
                                            </>
                                        )}
                                    </div>
                                )}
                                {answerImageModal && (
                                    <div className={styles.imgAnswerModal}>
                                        <div className={styles.modalTopSection}>
                                            <p>
                                                Pick which is the right
                                                answer(s).
                                            </p>
                                            <FontAwesomeIcon
                                                className={styles.uploadIcon}
                                                style={{ width: "25px" }}
                                                icon={faTimes}
                                                onClick={closeImageAnswerModal}
                                            />
                                        </div>

                                        <div className={styles.uploadArea}>
                                            <Row>
                                                {answerImageGallery.map(
                                                    (obj, index) => {
                                                        return (
                                                            <Col md={4}>
                                                                <div
                                                                    className={
                                                                        styles.imageSection
                                                                    }
                                                                >
                                                                    {finalImageid.indexOf(
                                                                        obj._id
                                                                    ) === -1 ? (
                                                                        <></>
                                                                    ) : (
                                                                        <img
                                                                            className={
                                                                                styles.crossImg
                                                                            }
                                                                            src={
                                                                                CloseButton
                                                                            }
                                                                            alt="Logo"
                                                                            style={{
                                                                                position:
                                                                                    "absolute",
                                                                            }}
                                                                            onError={
                                                                                handleDefaultImg
                                                                            }
                                                                        />
                                                                    )}
                                                                    <img
                                                                        src={
                                                                            obj.location
                                                                        }
                                                                        className={
                                                                            styles.configureImg
                                                                        }
                                                                        onClick={() => {
                                                                            handleFinalAnswerImmage(
                                                                                obj
                                                                            );
                                                                        }}
                                                                        alt=""
                                                                        onError={
                                                                            handleDefaultImg
                                                                        }
                                                                    />
                                                                </div>
                                                            </Col>
                                                        );
                                                    }
                                                )}
                                            </Row>
                                        </div>
                                        <h1
                                            className={styles.finishBtn}
                                            onClick={handleFinalAnswer}
                                        >
                                            Finish
                                        </h1>
                                    </div>
                                )}
                            </div>
                            <div>
                                {view !== "CUSTOMIZE" ? (
                                    <Row>
                                        <Col md={6}>
                                            <div className={styles.studentview}>
                                                <h1 className={styles.heading}>
                                                    Student View
                                                </h1>
                                                <div
                                                    className={
                                                        styles.studentblock
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.studentScroll
                                                        }
                                                    >
                                                        <Row>
                                                            <Col
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    padding:
                                                                        "15px",
                                                                }}
                                                            >
                                                                <img
                                                                    onClick={() =>
                                                                        onSelectStudentView(
                                                                            "view1"
                                                                        )
                                                                    }
                                                                    className={`${
                                                                        styles.imgsection
                                                                    } ${
                                                                        studentView ===
                                                                        "view1"
                                                                            ? styles.highlight
                                                                            : ""
                                                                    }`}
                                                                    src={View1}
                                                                    alt=""
                                                                    onError={
                                                                        handleDefaultImg
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    padding:
                                                                        "15px",
                                                                }}
                                                            >
                                                                <img
                                                                    onClick={() =>
                                                                        onSelectStudentView(
                                                                            "view2"
                                                                        )
                                                                    }
                                                                    className={`${
                                                                        styles.imgsection
                                                                    } ${
                                                                        studentView ===
                                                                        "view2"
                                                                            ? styles.highlight
                                                                            : ""
                                                                    }`}
                                                                    src={View2}
                                                                    alt=""
                                                                    onError={
                                                                        handleDefaultImg
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    padding:
                                                                        "15px",
                                                                }}
                                                            >
                                                                <img
                                                                    onClick={() =>
                                                                        onSelectStudentView(
                                                                            "view3"
                                                                        )
                                                                    }
                                                                    className={`${
                                                                        styles.imgsection
                                                                    } ${
                                                                        studentView ===
                                                                        "view3"
                                                                            ? styles.highlight
                                                                            : ""
                                                                    }`}
                                                                    src={View3}
                                                                    alt=""
                                                                    onError={
                                                                        handleDefaultImg
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    padding:
                                                                        "15px",
                                                                }}
                                                            >
                                                                <img
                                                                    onClick={() =>
                                                                        onSelectStudentView(
                                                                            "view4"
                                                                        )
                                                                    }
                                                                    className={`${
                                                                        styles.imgsection
                                                                    } ${
                                                                        studentView ===
                                                                        "view4"
                                                                            ? styles.highlight
                                                                            : ""
                                                                    }`}
                                                                    src={View4}
                                                                    alt=""
                                                                    onError={
                                                                        handleDefaultImg
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className={styles.studentview}>
                                                <h1 className={styles.heading}>
                                                    Teacher View
                                                </h1>
                                                <div
                                                    className={
                                                        styles.studentblock
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.studentScroll
                                                        }
                                                    >
                                                        {type === "2" ? (
                                                            <>
                                                                <Row
                                                                    style={{
                                                                        marginRight:
                                                                            "-8px",
                                                                    }}
                                                                >
                                                                    <Col
                                                                        md={6}
                                                                        style={{
                                                                            textAlign:
                                                                                "center",
                                                                            padding:
                                                                                "15px",
                                                                            margin: "auto",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            onClick={() =>
                                                                                onSelectTeacherView(
                                                                                    "viewT1"
                                                                                )
                                                                            }
                                                                            className={`${
                                                                                styles.imgsection
                                                                            } ${
                                                                                teacherView ===
                                                                                "viewT1"
                                                                                    ? styles.highlight
                                                                                    : ""
                                                                            }`}
                                                                            src={
                                                                                ViewT1
                                                                            }
                                                                            alt=""
                                                                            onError={
                                                                                handleDefaultImg
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col
                                                                        md={6}
                                                                        style={{
                                                                            textAlign:
                                                                                "center",
                                                                            padding:
                                                                                "15px",
                                                                            margin: "auto",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            onClick={() =>
                                                                                onSelectTeacherView(
                                                                                    "viewT2"
                                                                                )
                                                                            }
                                                                            className={`${
                                                                                styles.imgsection
                                                                            } ${
                                                                                teacherView ===
                                                                                "viewT2"
                                                                                    ? styles.highlight
                                                                                    : ""
                                                                            }`}
                                                                            src={
                                                                                ViewT2
                                                                            }
                                                                            alt=""
                                                                            onError={
                                                                                handleDefaultImg
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row
                                                                    style={{
                                                                        marginRight:
                                                                            "-8px",
                                                                    }}
                                                                >
                                                                    <Col
                                                                        md={6}
                                                                        style={{
                                                                            textAlign:
                                                                                "center",
                                                                            padding:
                                                                                "15px",
                                                                            margin: "auto",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            onClick={() =>
                                                                                onSelectTeacherView(
                                                                                    "viewT3"
                                                                                )
                                                                            }
                                                                            className={`${
                                                                                styles.imgsection
                                                                            } ${
                                                                                teacherView ===
                                                                                "viewT3"
                                                                                    ? styles.highlight
                                                                                    : ""
                                                                            }`}
                                                                            src={
                                                                                ViewT3
                                                                            }
                                                                            alt=""
                                                                            onError={
                                                                                handleDefaultImg
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col
                                                                        md={6}
                                                                        style={{
                                                                            textAlign:
                                                                                "center",
                                                                            padding:
                                                                                "15px",
                                                                            margin: "auto",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            onClick={() =>
                                                                                onSelectTeacherView(
                                                                                    "viewT4"
                                                                                )
                                                                            }
                                                                            className={`${
                                                                                styles.imgsection
                                                                            } ${
                                                                                teacherView ===
                                                                                "viewT4"
                                                                                    ? styles.highlight
                                                                                    : ""
                                                                            }`}
                                                                            src={
                                                                                ViewT4
                                                                            }
                                                                            alt=""
                                                                            onError={
                                                                                handleDefaultImg
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Row
                                                                    style={{
                                                                        marginRight:
                                                                            "-8px",
                                                                    }}
                                                                >
                                                                    <Col
                                                                        style={{
                                                                            textAlign:
                                                                                "center",
                                                                            padding:
                                                                                "15px",
                                                                            margin: "auto",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            onClick={() =>
                                                                                onSelectTeacherView(
                                                                                    "viewT2"
                                                                                )
                                                                            }
                                                                            className={`${
                                                                                styles.imgsection
                                                                            } ${
                                                                                teacherView ===
                                                                                "viewT2"
                                                                                    ? styles.highlight
                                                                                    : ""
                                                                            }`}
                                                                            src={
                                                                                ViewT2
                                                                            }
                                                                            alt=""
                                                                            onError={
                                                                                handleDefaultImg
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col
                                                                        style={{
                                                                            textAlign:
                                                                                "center",
                                                                            padding:
                                                                                "15px",
                                                                            margin: "auto",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            onClick={() =>
                                                                                onSelectTeacherView(
                                                                                    "viewT3"
                                                                                )
                                                                            }
                                                                            className={`${
                                                                                styles.imgsection
                                                                            } ${
                                                                                teacherView ===
                                                                                "viewT3"
                                                                                    ? styles.highlight
                                                                                    : ""
                                                                            }`}
                                                                            src={
                                                                                ViewT3
                                                                            }
                                                                            alt=""
                                                                            onError={
                                                                                handleDefaultImg
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row
                                                                    style={{
                                                                        marginRight:
                                                                            "-8px",
                                                                    }}
                                                                >
                                                                    <Col
                                                                        style={{
                                                                            textAlign:
                                                                                "center",
                                                                            padding:
                                                                                "15px",
                                                                            margin: "auto",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            onClick={() =>
                                                                                onSelectTeacherView(
                                                                                    "viewT4"
                                                                                )
                                                                            }
                                                                            className={`${
                                                                                styles.imgsection
                                                                            } ${
                                                                                teacherView ===
                                                                                "viewT4"
                                                                                    ? styles.highlight
                                                                                    : ""
                                                                            }`}
                                                                            src={
                                                                                ViewT4
                                                                            }
                                                                            alt=""
                                                                            onError={
                                                                                handleDefaultImg
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col
                                                                        style={{
                                                                            textAlign:
                                                                                "center",
                                                                            padding:
                                                                                "15px",
                                                                        }}
                                                                        className={
                                                                            styles.ColLayoutContainer
                                                                        }
                                                                    ></Col>
                                                                </Row>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                ) : (
                                    <div className={styles.leftScroll}>
                                        <Row>
                                            <Col md={5}>
                                                {type === 1 && (
                                                    <>
                                                        <div
                                                            className={
                                                                styles.gameTabs
                                                            }
                                                        >
                                                            <div
                                                                className={`${
                                                                    styles.gameTab
                                                                } ${
                                                                    gameTab ===
                                                                        "Game" &&
                                                                    styles.tabFocus
                                                                }`}
                                                                onClick={() => {
                                                                    handleGameTab(
                                                                        "Game"
                                                                    );
                                                                }}
                                                            >
                                                                <p>Game</p>
                                                            </div>
                                                            <div
                                                                className={`${
                                                                    styles.gameTab
                                                                } ${
                                                                    gameTab ===
                                                                        "General" &&
                                                                    styles.tabFocus
                                                                }`}
                                                                onClick={() => {
                                                                    handleGameTab(
                                                                        "General"
                                                                    );
                                                                }}
                                                            >
                                                                <p>General</p>
                                                            </div>
                                                            <div
                                                                className={`${
                                                                    styles.gameTab
                                                                } ${
                                                                    gameTab ===
                                                                        "In Class" &&
                                                                    styles.tabFocus
                                                                }`}
                                                                onClick={() => {
                                                                    handleGameTab(
                                                                        "In Class"
                                                                    );
                                                                }}
                                                            >
                                                                <p>In Class</p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.customise
                                                            }
                                                        >
                                                            {gameTab ===
                                                                "Game" && (
                                                                <>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        ></Col>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "space-around",
                                                                                    fontFamily:
                                                                                        "RobotoMediumItalic",
                                                                                    color: "#707070",
                                                                                    fontSize:
                                                                                        "20px",
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    Easy
                                                                                </div>
                                                                                <div>
                                                                                    Normal
                                                                                </div>
                                                                                <div>
                                                                                    Hard
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <p
                                                                                className={
                                                                                    styles.textConfig
                                                                                }
                                                                            >
                                                                                Image
                                                                                Types
                                                                            </p>
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "space-between",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                    style={{
                                                                                        border:
                                                                                            imageType ===
                                                                                            2
                                                                                                ? "2px solid #88ADDB"
                                                                                                : "",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        handleImageType(
                                                                                            2
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    2
                                                                                </div>
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                    style={{
                                                                                        border:
                                                                                            imageType ===
                                                                                            3
                                                                                                ? "2px solid #88ADDB"
                                                                                                : "",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        handleImageType(
                                                                                            3
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    3
                                                                                </div>
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                    style={{
                                                                                        border:
                                                                                            imageType ===
                                                                                            4
                                                                                                ? "2px solid #88ADDB"
                                                                                                : "",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        handleImageType(
                                                                                            4
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    4
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <p
                                                                                className={
                                                                                    styles.textConfig
                                                                                }
                                                                            >
                                                                                Total
                                                                                Images
                                                                            </p>
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "space-between",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                    style={{
                                                                                        border:
                                                                                            grid ===
                                                                                            6
                                                                                                ? "2px solid #88ADDB"
                                                                                                : "",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        handleGrid(
                                                                                            6
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    6
                                                                                </div>
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                    style={{
                                                                                        border:
                                                                                            grid ===
                                                                                            9
                                                                                                ? "2px solid #88ADDB"
                                                                                                : "",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        handleGrid(
                                                                                            9
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    9
                                                                                </div>
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                    style={{
                                                                                        border:
                                                                                            grid ===
                                                                                            12
                                                                                                ? "2px solid #88ADDB"
                                                                                                : "",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        handleGrid(
                                                                                            12
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    12
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <p
                                                                        className={
                                                                            styles.textConfig
                                                                        }
                                                                    >
                                                                        Images
                                                                    </p>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                10
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    styles.imageWrapper
                                                                                }
                                                                            >
                                                                                {gallery
                                                                                    .filter(
                                                                                        (
                                                                                            obj
                                                                                        ) =>
                                                                                            obj.type ===
                                                                                            "IMAGE"
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            obj,
                                                                                            index
                                                                                        ) => {
                                                                                            return (
                                                                                                <div
                                                                                                    className={`${
                                                                                                        styles.imageSection
                                                                                                    } ${
                                                                                                        rightPanelSettings[
                                                                                                            panelIndex
                                                                                                        ].activity_settings.image_ids.indexOf(
                                                                                                            obj._id
                                                                                                        ) ===
                                                                                                        -1
                                                                                                            ? null
                                                                                                            : styles.selectedImage
                                                                                                    }`}
                                                                                                    onClick={() => {
                                                                                                        handleImage(
                                                                                                            obj
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    <img
                                                                                                        src={
                                                                                                            obj.location
                                                                                                        }
                                                                                                        className={
                                                                                                            styles.configureImg
                                                                                                        }
                                                                                                        alt=""
                                                                                                        onError={
                                                                                                            handleDefaultImg
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                            </div>
                                                                        </Col>
                                                                        <Col>
                                                                            <FontAwesomeIcon
                                                                                className={
                                                                                    styles.plusicon
                                                                                }
                                                                                style={{
                                                                                    width: "30px",
                                                                                }}
                                                                                icon={
                                                                                    faPlus
                                                                                }
                                                                                onClick={
                                                                                    openUploadModal
                                                                                }
                                                                            />
                                                                        </Col>
                                                                    </Row>

                                                                    <div
                                                                        className={
                                                                            styles.btnWrapper
                                                                        }
                                                                    >
                                                                        <h1
                                                                            className={
                                                                                styles.rightHeading
                                                                            }
                                                                        >
                                                                            Timer
                                                                        </h1>
                                                                        <div
                                                                            className={
                                                                                styles.button
                                                                            }
                                                                            onClick={() => {
                                                                                if (
                                                                                    timer
                                                                                ) {
                                                                                    handleTimer(
                                                                                        false
                                                                                    );
                                                                                } else {
                                                                                    handleTimer(
                                                                                        true
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    timer
                                                                                        ? `${styles.circleLeft}`
                                                                                        : `${styles.circleRight}`
                                                                                }
                                                                            ></div>
                                                                        </div>

                                                                        <h1
                                                                            className={
                                                                                styles.rightHeading
                                                                            }
                                                                        >
                                                                            Scoreboard
                                                                        </h1>
                                                                        <div
                                                                            className={
                                                                                styles.button
                                                                            }
                                                                            onClick={() => {
                                                                                if (
                                                                                    scoreboard
                                                                                ) {
                                                                                    handleScoreBoard(
                                                                                        false
                                                                                    );
                                                                                } else {
                                                                                    handleScoreBoard(
                                                                                        true
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    scoreboard
                                                                                        ? `${styles.circleLeft}`
                                                                                        : `${styles.circleRight}`
                                                                                }
                                                                            ></div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {gameTab ===
                                                                "General" && (
                                                                <>
                                                                    <p
                                                                        className={
                                                                            styles.textConfig
                                                                        }
                                                                    >
                                                                        Background
                                                                        Images
                                                                    </p>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                10
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    styles.imageWrapper
                                                                                }
                                                                            >
                                                                                {gallery.map(
                                                                                    (
                                                                                        obj,
                                                                                        index
                                                                                    ) => {
                                                                                        if (
                                                                                            obj.type ===
                                                                                            "IMAGE"
                                                                                        ) {
                                                                                            return (
                                                                                                <div
                                                                                                    className={`${
                                                                                                        styles.imageSection
                                                                                                    } ${
                                                                                                        rightPanelSettings[
                                                                                                            panelIndex
                                                                                                        ].activity_settings.background_image_ids.indexOf(
                                                                                                            obj._id
                                                                                                        ) ===
                                                                                                        -1
                                                                                                            ? null
                                                                                                            : styles.selectedImage
                                                                                                    }`}
                                                                                                    onClick={() => {
                                                                                                        handleBackgroundImage(
                                                                                                            obj
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    <img
                                                                                                        src={
                                                                                                            obj.location
                                                                                                        }
                                                                                                        className={
                                                                                                            styles.configureImg
                                                                                                        }
                                                                                                        alt=""
                                                                                                        onError={
                                                                                                            handleDefaultImg
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                        return null;
                                                                                    }
                                                                                )}
                                                                            </div>
                                                                        </Col>
                                                                        <Col>
                                                                            <FontAwesomeIcon
                                                                                className={
                                                                                    styles.plusicon
                                                                                }
                                                                                style={{
                                                                                    width: "30px",
                                                                                }}
                                                                                icon={
                                                                                    faPlus
                                                                                }
                                                                                onClick={
                                                                                    openUploadModal
                                                                                }
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <p
                                                                        className={
                                                                            styles.textConfig
                                                                        }
                                                                    >
                                                                        Background
                                                                        Music
                                                                    </p>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                10
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    styles.imageWrapper
                                                                                }
                                                                            >
                                                                                {gallery.map(
                                                                                    (
                                                                                        obj,
                                                                                        index
                                                                                    ) => {
                                                                                        if (
                                                                                            obj.type ===
                                                                                            "AUDIO"
                                                                                        ) {
                                                                                            return (
                                                                                                <div
                                                                                                    className={`${
                                                                                                        styles.imageSection
                                                                                                    } ${
                                                                                                        rightPanelSettings[
                                                                                                            panelIndex
                                                                                                        ].activity_settings.background_audio_ids.indexOf(
                                                                                                            obj._id
                                                                                                        ) ===
                                                                                                        -1
                                                                                                            ? null
                                                                                                            : styles.selectedImage
                                                                                                    }`}
                                                                                                    onClick={() => {
                                                                                                        handleBackgroundAudio(
                                                                                                            obj
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    <img
                                                                                                        className={
                                                                                                            styles.assets
                                                                                                        }
                                                                                                        style={{
                                                                                                            padding:
                                                                                                                "10px",
                                                                                                        }}
                                                                                                        src={
                                                                                                            Note
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                            // controlAudio();
                                                                                                        }}
                                                                                                        alt=""
                                                                                                        onError={
                                                                                                            handleDefaultImg
                                                                                                        }
                                                                                                    />
                                                                                                    <audio
                                                                                                        id="myAudio"
                                                                                                        style={{
                                                                                                            width: "100%",
                                                                                                        }}
                                                                                                        controls
                                                                                                        src={
                                                                                                            obj.location
                                                                                                        }
                                                                                                    ></audio>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                        return null;
                                                                                    }
                                                                                )}
                                                                            </div>
                                                                        </Col>
                                                                        <Col>
                                                                            <FontAwesomeIcon
                                                                                className={
                                                                                    styles.plusicon
                                                                                }
                                                                                style={{
                                                                                    width: "30px",
                                                                                }}
                                                                                icon={
                                                                                    faPlus
                                                                                }
                                                                                onClick={() => {
                                                                                    setSelectAudio(
                                                                                        true
                                                                                    );
                                                                                    handleCategories(
                                                                                        "AUDIO"
                                                                                    );
                                                                                    openUploadModal();
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )}
                                                            {gameTab ===
                                                                "In Class" && (
                                                                <>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        ></Col>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "space-around",
                                                                                    fontFamily:
                                                                                        "RobotoMediumItalic",
                                                                                    color: "#707070",
                                                                                    fontSize:
                                                                                        "20px",
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    Easy
                                                                                </div>
                                                                                <div>
                                                                                    Normal
                                                                                </div>
                                                                                <div>
                                                                                    Hard
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <p
                                                                                className={
                                                                                    styles.textConfig
                                                                                }
                                                                            >
                                                                                Image
                                                                                Types
                                                                            </p>
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "space-between",
                                                                                }}
                                                                                onClick={() => {
                                                                                    handleSceneNumber(
                                                                                        3
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                    style={{
                                                                                        border:
                                                                                            imageType ===
                                                                                            2
                                                                                                ? "2px solid #88ADDB"
                                                                                                : "",
                                                                                    }}
                                                                                >
                                                                                    2
                                                                                </div>
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                    style={{
                                                                                        border:
                                                                                            imageType ===
                                                                                            3
                                                                                                ? "2px solid #88ADDB"
                                                                                                : "",
                                                                                    }}
                                                                                >
                                                                                    3
                                                                                </div>
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                    style={{
                                                                                        border:
                                                                                            imageType ===
                                                                                            4
                                                                                                ? "2px solid #88ADDB"
                                                                                                : "",
                                                                                    }}
                                                                                >
                                                                                    4
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <p
                                                                                className={
                                                                                    styles.textConfig
                                                                                }
                                                                            >
                                                                                Grid
                                                                            </p>
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "space-between",
                                                                                }}
                                                                                onClick={() => {
                                                                                    handleSceneNumber(
                                                                                        7
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                    style={{
                                                                                        border:
                                                                                            grid ===
                                                                                            6
                                                                                                ? "2px solid #88ADDB"
                                                                                                : "",
                                                                                    }}
                                                                                >
                                                                                    6
                                                                                </div>
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                    style={{
                                                                                        border:
                                                                                            grid ===
                                                                                            9
                                                                                                ? "2px solid #88ADDB"
                                                                                                : "",
                                                                                    }}
                                                                                >
                                                                                    9
                                                                                </div>
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                    style={{
                                                                                        border:
                                                                                            grid ===
                                                                                            12
                                                                                                ? "2px solid #88ADDB"
                                                                                                : "",
                                                                                    }}
                                                                                >
                                                                                    12
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <div
                                                                        className={
                                                                            styles.btnWrapper
                                                                        }
                                                                    >
                                                                        <h1
                                                                            className={
                                                                                styles.rightHeading
                                                                            }
                                                                        >
                                                                            Timer
                                                                        </h1>
                                                                        <div
                                                                            className={
                                                                                styles.button
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    timer
                                                                                        ? `${styles.circleLeft}`
                                                                                        : `${styles.circleRight}`
                                                                                }
                                                                            ></div>
                                                                        </div>
                                                                        <h1
                                                                            className={
                                                                                styles.rightHeading
                                                                            }
                                                                        >
                                                                            Scoreboard
                                                                        </h1>
                                                                        <div
                                                                            className={
                                                                                styles.button
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    scoreboard
                                                                                        ? `${styles.circleLeft}`
                                                                                        : `${styles.circleRight}`
                                                                                }
                                                                            ></div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                                {type === 2 && (
                                                    <>
                                                        <div
                                                            className={
                                                                styles.gameTabs
                                                            }
                                                        >
                                                            <div
                                                                className={`${
                                                                    styles.gameTab
                                                                } ${
                                                                    gameTab ===
                                                                        "Game" &&
                                                                    styles.tabFocus
                                                                }`}
                                                                onClick={() => {
                                                                    handleGameTab(
                                                                        "Game"
                                                                    );
                                                                }}
                                                            >
                                                                <p>Game</p>
                                                            </div>
                                                            <div
                                                                className={`${
                                                                    styles.gameTab
                                                                } ${
                                                                    gameTab ===
                                                                        "General" &&
                                                                    styles.tabFocus
                                                                }`}
                                                                onClick={() => {
                                                                    handleGameTab(
                                                                        "General"
                                                                    );
                                                                }}
                                                            >
                                                                <p>General</p>
                                                            </div>
                                                            <div
                                                                className={`${
                                                                    styles.gameTab
                                                                } ${
                                                                    gameTab ===
                                                                        "In Class" &&
                                                                    styles.tabFocus
                                                                }`}
                                                                onClick={() => {
                                                                    handleGameTab(
                                                                        "In Class"
                                                                    );
                                                                }}
                                                            >
                                                                <p>In Class</p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.customise
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.customiseScroll
                                                                }
                                                            >
                                                                {gameTab ===
                                                                    "Game" && (
                                                                    <>
                                                                        <Row>
                                                                            <Col
                                                                                md={
                                                                                    6
                                                                                }
                                                                            >
                                                                                <p
                                                                                    className={
                                                                                        styles.textConfig
                                                                                    }
                                                                                >
                                                                                    Number
                                                                                    of
                                                                                    scenes
                                                                                </p>
                                                                            </Col>
                                                                            <Col
                                                                                md={
                                                                                    6
                                                                                }
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        justifyContent:
                                                                                            "space-between",
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className={
                                                                                            styles.btnCircle
                                                                                        }
                                                                                        style={{
                                                                                            border:
                                                                                                sceneNumber ===
                                                                                                3
                                                                                                    ? "2px solid #88ADDB"
                                                                                                    : "",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            handleSceneNumber(
                                                                                                3
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        3
                                                                                    </div>
                                                                                    <div
                                                                                        className={
                                                                                            styles.btnCircle
                                                                                        }
                                                                                        style={{
                                                                                            border:
                                                                                                sceneNumber ===
                                                                                                5
                                                                                                    ? "2px solid #88ADDB"
                                                                                                    : "",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            handleSceneNumber(
                                                                                                5
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        5
                                                                                    </div>
                                                                                    <div
                                                                                        className={
                                                                                            styles.btnCircle
                                                                                        }
                                                                                        style={{
                                                                                            border:
                                                                                                sceneNumber ===
                                                                                                7
                                                                                                    ? "2px solid #88ADDB"
                                                                                                    : "",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            handleSceneNumber(
                                                                                                7
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        7
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                                marginBottom:
                                                                                    "10px",
                                                                            }}
                                                                        >
                                                                            <p
                                                                                style={{
                                                                                    marginBottom:
                                                                                        "0px",
                                                                                }}
                                                                                className={
                                                                                    styles.textConfig
                                                                                }
                                                                            >
                                                                                Background/Question
                                                                            </p>

                                                                            <CustomDropdown
                                                                                dropdownData={
                                                                                    dropdownSceneData
                                                                                }
                                                                                width="40%"
                                                                                margin="auto"
                                                                                setData={
                                                                                    setSceneData
                                                                                }
                                                                                data={
                                                                                    sceneData
                                                                                }
                                                                            />
                                                                        </div>

                                                                        <Row>
                                                                            <Col
                                                                                md={
                                                                                    10
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.imageWrapper
                                                                                    }
                                                                                >
                                                                                    {gallery.map(
                                                                                        (
                                                                                            obj,
                                                                                            index
                                                                                        ) => {
                                                                                            if (
                                                                                                obj.type ===
                                                                                                "IMAGE"
                                                                                            ) {
                                                                                                return (
                                                                                                    <div
                                                                                                        className={
                                                                                                            styles.imageSection
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                            handleBackgroundQuestion(
                                                                                                                obj
                                                                                                            );
                                                                                                        }}
                                                                                                        style={{
                                                                                                            border:
                                                                                                                rightPanelSettings[
                                                                                                                    panelIndex
                                                                                                                ]
                                                                                                                    .activity_settings
                                                                                                                    .background_question_ids ===
                                                                                                                obj._id
                                                                                                                    ? "5px solid #88ADDB"
                                                                                                                    : "",
                                                                                                        }}
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                obj.location
                                                                                                            }
                                                                                                            className={
                                                                                                                styles.configureImg
                                                                                                            }
                                                                                                            alt=""
                                                                                                            onError={
                                                                                                                handleDefaultImg
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                            return null;
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            </Col>
                                                                            <Col>
                                                                                <FontAwesomeIcon
                                                                                    className={
                                                                                        styles.plusicon
                                                                                    }
                                                                                    style={{
                                                                                        width: "30px",
                                                                                    }}
                                                                                    icon={
                                                                                        faPlus
                                                                                    }
                                                                                    onClick={
                                                                                        openUploadModal
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col
                                                                                md={
                                                                                    6
                                                                                }
                                                                            ></Col>
                                                                            <Col
                                                                                md={
                                                                                    6
                                                                                }
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        justifyContent:
                                                                                            "space-around",
                                                                                        fontFamily:
                                                                                            "RobotoMediumItalic",
                                                                                        color: "#707070",
                                                                                        fontSize:
                                                                                            "20px",
                                                                                    }}
                                                                                >
                                                                                    <div>
                                                                                        Easy
                                                                                    </div>
                                                                                    <div>
                                                                                        Normal
                                                                                    </div>
                                                                                    <div>
                                                                                        Hard
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col
                                                                                md={
                                                                                    6
                                                                                }
                                                                            >
                                                                                <p
                                                                                    className={
                                                                                        styles.textConfig
                                                                                    }
                                                                                >
                                                                                    Answer
                                                                                    Choises
                                                                                </p>
                                                                            </Col>
                                                                            <Col
                                                                                md={
                                                                                    6
                                                                                }
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        justifyContent:
                                                                                            "space-between",
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className={
                                                                                            styles.btnCircle
                                                                                        }
                                                                                        style={{
                                                                                            border:
                                                                                                answerChoice ===
                                                                                                2
                                                                                                    ? "2px solid #88ADDB"
                                                                                                    : "",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            handleAnswerChoice(
                                                                                                2
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        2
                                                                                    </div>
                                                                                    <div
                                                                                        className={
                                                                                            styles.btnCircle
                                                                                        }
                                                                                        style={{
                                                                                            border:
                                                                                                answerChoice ===
                                                                                                5
                                                                                                    ? "2px solid #88ADDB"
                                                                                                    : "",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            handleAnswerChoice(
                                                                                                5
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        5
                                                                                    </div>
                                                                                    <div
                                                                                        className={
                                                                                            styles.btnCircle
                                                                                        }
                                                                                        style={{
                                                                                            border:
                                                                                                answerChoice ===
                                                                                                7
                                                                                                    ? "2px solid #88ADDB"
                                                                                                    : "",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            handleAnswerChoice(
                                                                                                7
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        7
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                            }}
                                                                        >
                                                                            <p
                                                                                className={
                                                                                    styles.textConfig
                                                                                }
                                                                            >
                                                                                Images
                                                                                (Answer)
                                                                            </p>
                                                                            <FontAwesomeIcon
                                                                                // className={styles.plusicon}
                                                                                style={{
                                                                                    width: "30px",
                                                                                }}
                                                                                icon={
                                                                                    faPlus
                                                                                }
                                                                                onClick={() => {
                                                                                    openAnswerImageModal(
                                                                                        answerImageId
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>

                                                                        <Row>
                                                                            <Col
                                                                                md={
                                                                                    10
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.imageWrapper
                                                                                    }
                                                                                >
                                                                                    {gallery.map(
                                                                                        (
                                                                                            obj,
                                                                                            index
                                                                                        ) => {
                                                                                            if (
                                                                                                obj.type ===
                                                                                                "IMAGE"
                                                                                            ) {
                                                                                                return (
                                                                                                    <div
                                                                                                        className={`${
                                                                                                            styles.imageSection
                                                                                                        } ${
                                                                                                            rightPanelSettings[
                                                                                                                panelIndex
                                                                                                            ].activity_settings.image_answer_ids.indexOf(
                                                                                                                obj._id
                                                                                                            ) ===
                                                                                                            -1
                                                                                                                ? null
                                                                                                                : styles.selectedImage
                                                                                                        }`}
                                                                                                        onClick={() => {
                                                                                                            handleImageAnswer(
                                                                                                                obj
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        {rightPanelSettings[
                                                                                                            panelIndex
                                                                                                        ].activity_settings.final_image_answer_ids.indexOf(
                                                                                                            obj._id
                                                                                                        ) ===
                                                                                                        -1 ? (
                                                                                                            <>

                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <img
                                                                                                                className={
                                                                                                                    styles.crossImg
                                                                                                                }
                                                                                                                src={
                                                                                                                    CloseButton
                                                                                                                }
                                                                                                                alt="Logo"
                                                                                                                style={{
                                                                                                                    position:
                                                                                                                        "absolute",
                                                                                                                }}
                                                                                                                onError={
                                                                                                                    handleDefaultImg
                                                                                                                }
                                                                                                            />
                                                                                                        )}
                                                                                                        <img
                                                                                                            src={
                                                                                                                obj.location
                                                                                                            }
                                                                                                            className={
                                                                                                                styles.configureImg
                                                                                                            }
                                                                                                            alt=""
                                                                                                            onError={
                                                                                                                handleDefaultImg
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                            return null;
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            </Col>
                                                                            <Col>
                                                                                <FontAwesomeIcon
                                                                                    className={
                                                                                        styles.plusicon
                                                                                    }
                                                                                    style={{
                                                                                        width: "30px",
                                                                                    }}
                                                                                    icon={
                                                                                        faPlus
                                                                                    }
                                                                                    onClick={
                                                                                        openUploadModal
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </>
                                                                )}
                                                                {gameTab ===
                                                                    "General" && (
                                                                    <>
                                                                        <p
                                                                            className={
                                                                                styles.plusicon
                                                                            }
                                                                        >
                                                                            Background
                                                                            Images
                                                                        </p>
                                                                        <Row>
                                                                            <Col
                                                                                md={
                                                                                    10
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.imageWrapper
                                                                                    }
                                                                                >
                                                                                    {gallery.map(
                                                                                        (
                                                                                            obj,
                                                                                            index
                                                                                        ) => {
                                                                                            if (
                                                                                                obj.type ===
                                                                                                "IMAGE"
                                                                                            ) {
                                                                                                return (
                                                                                                    <div
                                                                                                        className={`${
                                                                                                            styles.imageSection
                                                                                                        } ${
                                                                                                            rightPanelSettings[
                                                                                                                panelIndex
                                                                                                            ].activity_settings.background_image_ids.indexOf(
                                                                                                                obj._id
                                                                                                            ) ===
                                                                                                            -1
                                                                                                                ? null
                                                                                                                : styles.selectedImage
                                                                                                        }`}
                                                                                                        onClick={() => {
                                                                                                            handleBackgroundImage(
                                                                                                                obj
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                obj.location
                                                                                                            }
                                                                                                            className={
                                                                                                                styles.configureImg
                                                                                                            }
                                                                                                            alt=""
                                                                                                            onError={
                                                                                                                handleDefaultImg
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                            return null;
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            </Col>
                                                                            <Col>
                                                                                <FontAwesomeIcon
                                                                                    className={
                                                                                        styles.plusicon
                                                                                    }
                                                                                    style={{
                                                                                        width: "30px",
                                                                                    }}
                                                                                    icon={
                                                                                        faPlus
                                                                                    }
                                                                                    onClick={
                                                                                        openUploadModal
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </Row>

                                                                        <p
                                                                            className={
                                                                                styles.textConfig
                                                                            }
                                                                        >
                                                                            Background
                                                                            Music
                                                                        </p>
                                                                        <Row>
                                                                            <Col
                                                                                md={
                                                                                    10
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.imageWrapper
                                                                                    }
                                                                                >
                                                                                    {gallery.map(
                                                                                        (
                                                                                            obj,
                                                                                            index
                                                                                        ) => {
                                                                                            if (
                                                                                                obj.type ===
                                                                                                "AUDIO"
                                                                                            ) {
                                                                                                return (
                                                                                                    <div
                                                                                                        className={`${
                                                                                                            styles.imageSection
                                                                                                        } ${
                                                                                                            rightPanelSettings[
                                                                                                                panelIndex
                                                                                                            ].activity_settings.background_audio_ids.indexOf(
                                                                                                                obj._id
                                                                                                            ) ===
                                                                                                            -1
                                                                                                                ? null
                                                                                                                : styles.selectedImage
                                                                                                        }`}
                                                                                                        onClick={() => {
                                                                                                            handleBackgroundAudio(
                                                                                                                obj
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        <audio
                                                                                                            id="myAudio"
                                                                                                            style={{
                                                                                                                width: "100%",
                                                                                                            }}
                                                                                                            controls
                                                                                                            src={
                                                                                                                obj.location
                                                                                                            }
                                                                                                        ></audio>
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                            return null;
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            </Col>
                                                                            <Col>
                                                                                <FontAwesomeIcon
                                                                                    className={
                                                                                        styles.plusicon
                                                                                    }
                                                                                    style={{
                                                                                        width: "30px",
                                                                                    }}
                                                                                    icon={
                                                                                        faPlus
                                                                                    }
                                                                                    onClick={
                                                                                        openUploadModal
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </>
                                                                )}
                                                                {gameTab ===
                                                                    "In Class" && (
                                                                    <>
                                                                        <Row>
                                                                            <Col
                                                                                md={
                                                                                    6
                                                                                }
                                                                            ></Col>
                                                                            <Col
                                                                                md={
                                                                                    6
                                                                                }
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        justifyContent:
                                                                                            "space-around",
                                                                                        fontFamily:
                                                                                            "RobotoMediumItalic",
                                                                                        color: "#707070",
                                                                                        fontSize:
                                                                                            "20px",
                                                                                    }}
                                                                                >
                                                                                    <div>
                                                                                        Easy
                                                                                    </div>
                                                                                    <div>
                                                                                        Normal
                                                                                    </div>
                                                                                    <div>
                                                                                        Hard
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col
                                                                                md={
                                                                                    6
                                                                                }
                                                                            >
                                                                                <p
                                                                                    className={
                                                                                        styles.textConfig
                                                                                    }
                                                                                >
                                                                                    Answer
                                                                                    choices
                                                                                </p>
                                                                            </Col>
                                                                            <Col
                                                                                md={
                                                                                    6
                                                                                }
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        justifyContent:
                                                                                            "space-between",
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className={
                                                                                            styles.btnCircle
                                                                                        }
                                                                                        style={{
                                                                                            border:
                                                                                                answerChoice ===
                                                                                                2
                                                                                                    ? "2px solid #88ADDB"
                                                                                                    : "",
                                                                                        }}
                                                                                    >
                                                                                        2
                                                                                    </div>
                                                                                    <div
                                                                                        className={
                                                                                            styles.btnCircle
                                                                                        }
                                                                                        style={{
                                                                                            border:
                                                                                                answerChoice ===
                                                                                                5
                                                                                                    ? "2px solid #88ADDB"
                                                                                                    : "",
                                                                                        }}
                                                                                    >
                                                                                        5
                                                                                    </div>
                                                                                    <div
                                                                                        className={
                                                                                            styles.btnCircle
                                                                                        }
                                                                                        style={{
                                                                                            border:
                                                                                                answerChoice ===
                                                                                                7
                                                                                                    ? "2px solid #88ADDB"
                                                                                                    : "",
                                                                                        }}
                                                                                    >
                                                                                        7
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col
                                                                                md={
                                                                                    6
                                                                                }
                                                                            >
                                                                                <p
                                                                                    className={
                                                                                        styles.textConfig
                                                                                    }
                                                                                >
                                                                                    Scenes
                                                                                </p>
                                                                            </Col>
                                                                            <Col
                                                                                md={
                                                                                    6
                                                                                }
                                                                            >
                                                                                <CustomDropdown
                                                                                    dropdownData={
                                                                                        dropdownSceneData
                                                                                    }
                                                                                    width="40%"
                                                                                    margin="auto"
                                                                                    setData={
                                                                                        setSceneData
                                                                                    }
                                                                                    data={
                                                                                        sceneData
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <p
                                                                            className={
                                                                                styles.textConfig
                                                                            }
                                                                        >
                                                                            Images
                                                                            (Answer)
                                                                        </p>
                                                                        <Row>
                                                                            <Col
                                                                                md={
                                                                                    10
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.imageWrapper
                                                                                    }
                                                                                >
                                                                                    {gallery.map(
                                                                                        (
                                                                                            obj,
                                                                                            index
                                                                                        ) => {
                                                                                            if (
                                                                                                obj.type ===
                                                                                                "IMAGE"
                                                                                            ) {
                                                                                                return (
                                                                                                    <div
                                                                                                        className={
                                                                                                            styles.imageSection
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                obj.location
                                                                                                            }
                                                                                                            className={
                                                                                                                styles.configureImg
                                                                                                            }
                                                                                                            alt=""
                                                                                                            onError={
                                                                                                                handleDefaultImg
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                            return null;
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            </Col>
                                                                            <Col>
                                                                                <FontAwesomeIcon
                                                                                    className={
                                                                                        styles.plusicon
                                                                                    }
                                                                                    style={{
                                                                                        width: "30px",
                                                                                    }}
                                                                                    icon={
                                                                                        faPlus
                                                                                    }
                                                                                    onClick={
                                                                                        openUploadModal
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </Row>

                                                                        <div
                                                                            className={
                                                                                styles.btnWrapper
                                                                            }
                                                                        >
                                                                            <h1
                                                                                className={
                                                                                    styles.rightHeading
                                                                                }
                                                                            >
                                                                                Teams
                                                                            </h1>
                                                                            <div
                                                                                className={
                                                                                    styles.button
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.circleLeft
                                                                                    }
                                                                                ></div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {type === 3 && (
                                                    <>
                                                        <div
                                                            className={
                                                                styles.gameTabs
                                                            }
                                                        >
                                                            <div
                                                                className={`${
                                                                    styles.gameTab
                                                                } ${
                                                                    gameTab ===
                                                                        "In Class" &&
                                                                    styles.tabFocus
                                                                }`}
                                                                onClick={() => {
                                                                    handleGameTab(
                                                                        "In Class"
                                                                    );
                                                                }}
                                                            >
                                                                <p>In Class</p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.customise
                                                            }
                                                        >
                                                            {gameTab ===
                                                                "In Class" && (
                                                                <>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        ></Col>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "space-around",
                                                                                    fontFamily:
                                                                                        "RobotoMediumItalic",
                                                                                    color: "#707070",
                                                                                    fontSize:
                                                                                        "20px",
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    Easy
                                                                                </div>
                                                                                <div>
                                                                                    Normal
                                                                                </div>
                                                                                <div>
                                                                                    Hard
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <p
                                                                                className={
                                                                                    styles.textConfig
                                                                                }
                                                                            >
                                                                                Difficulty
                                                                            </p>
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "space-between",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                >
                                                                                    2
                                                                                </div>
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                >
                                                                                    3
                                                                                </div>
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                >
                                                                                    4
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <p
                                                                                className={
                                                                                    styles.textConfig
                                                                                }
                                                                            >
                                                                                Grid
                                                                            </p>
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "space-between",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                >
                                                                                    6
                                                                                </div>
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                >
                                                                                    9
                                                                                </div>
                                                                                <div
                                                                                    className={
                                                                                        styles.btnCircle
                                                                                    }
                                                                                >
                                                                                    12
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <h1
                                                                                className={
                                                                                    styles.rightHeading
                                                                                }
                                                                            >
                                                                                Timer
                                                                            </h1>
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    styles.button
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.circleLeft
                                                                                    }
                                                                                ></div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <h1
                                                                                className={
                                                                                    styles.rightHeading
                                                                                }
                                                                            >
                                                                                Scoreboard
                                                                            </h1>
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    styles.button
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.circleLeft
                                                                                    }
                                                                                ></div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </Col>
                                            <Col md={7}>
                                                <div
                                                    className={
                                                        styles.activityPreview
                                                    }
                                                >
                                                    {rightPanelSettings[
                                                        panelIndex
                                                    ]?.subType ===
                                                        "BUBBLE_POP" && (
                                                        <>
                                                            {(gameTab ===
                                                                "Game" ||
                                                                gameTab ===
                                                                    "General") && (
                                                                <>
                                                                    <img
                                                                        className={
                                                                            styles.activityImg
                                                                        }
                                                                        src={
                                                                            BubblePop
                                                                        }
                                                                        onError={
                                                                            handleDefaultImg
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                            {gameTab ===
                                                                "In Class" && (
                                                                <>
                                                                    <p
                                                                        className={
                                                                            styles.previewText
                                                                        }
                                                                    >
                                                                        A pop up
                                                                        with
                                                                        these
                                                                        selections
                                                                        will
                                                                        appear
                                                                        in
                                                                        class.
                                                                        <br></br>
                                                                        Select
                                                                        your
                                                                        preference
                                                                        before
                                                                        activity
                                                                        begins.
                                                                    </p>
                                                                    <div
                                                                        className={
                                                                            styles.previewModal
                                                                        }
                                                                    >
                                                                        <GameInitModal
                                                                            showModal={
                                                                                true
                                                                            }
                                                                            handleGame1Start={() => {}}
                                                                            imageTypes={
                                                                                imageType
                                                                            }
                                                                            handleTotalImages={() => {}}
                                                                            handleImageTypes={() => {}}
                                                                            totalImages={
                                                                                grid
                                                                            }
                                                                            turnBased={
                                                                                turnBased
                                                                            }
                                                                            handleTurnBased={() => {}}
                                                                            handleTimer={() => {}}
                                                                            handleScoreboard={() => {}}
                                                                            currentPanel={
                                                                                rightPanelSettings[
                                                                                    panelIndex
                                                                                ]
                                                                            }
                                                                            preview={
                                                                                true
                                                                            }
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}

                                                    {rightPanelSettings[
                                                        panelIndex
                                                    ]?.subType ===
                                                        "BUILD_YOUR_DREAM" && (
                                                        <>
                                                            {gameTab ===
                                                                "In Class" && (
                                                                <>
                                                                    <p
                                                                        className={
                                                                            styles.previewText
                                                                        }
                                                                    >
                                                                        A pop up
                                                                        with
                                                                        these
                                                                        selections
                                                                        will
                                                                        appear
                                                                        in
                                                                        class.
                                                                        <br></br>
                                                                        Select
                                                                        your
                                                                        preference
                                                                        before
                                                                        activity
                                                                        begins.
                                                                    </p>
                                                                    <div
                                                                        className={
                                                                            styles.previewModal
                                                                        }
                                                                    >
                                                                        <GameInitModal
                                                                            showModal={
                                                                                true
                                                                            }
                                                                            handleGame1Start={() => {}}
                                                                            imageTypes={
                                                                                imageType
                                                                            }
                                                                            handleTotalImages={() => {}}
                                                                            handleImageTypes={() => {}}
                                                                            totalImages={
                                                                                grid
                                                                            }
                                                                            turnBased={
                                                                                turnBased
                                                                            }
                                                                            handleTurnBased={() => {}}
                                                                            handleTimer={() => {}}
                                                                            handleScoreboard={() => {}}
                                                                            currentPanel={
                                                                                rightPanelSettings[
                                                                                    panelIndex
                                                                                ]
                                                                            }
                                                                            preview={
                                                                                true
                                                                            }
                                                                            gameName="BUILD_YOUR_DREAM"
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </div>

                            <div>
                                {view === "SCREEN" ? (
                                    <h1
                                        className={styles.createButton}
                                        onClick={() => {
                                            handleFinish();
                                        }}
                                    >
                                        Finish
                                    </h1>
                                ) : (
                                    <h1
                                        className={styles.createButton}
                                        onClick={() => {
                                            handleNext();
                                        }}
                                    >
                                        Next
                                    </h1>
                                )}
                            </div>
                            <div>
                                <div
                                    style={{
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "11vh",
                                    }}
                                >
                                    <div className={styles.scroll}>
                                        <div className={styles.content}>
                                            {rightPanelSettings.map(
                                                (obj, index) => {
                                                    return (
                                                        <div
                                                            className={
                                                                styles.lessonWrapper
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.classblock
                                                                }
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    if (
                                                                        event
                                                                            .target
                                                                            .id ==
                                                                            "downarrow" ||
                                                                        event
                                                                            .target
                                                                            .parentElement
                                                                            .id ==
                                                                            "downarrow" ||
                                                                        document.getElementById(
                                                                            "dropdown"
                                                                        ) ||
                                                                        show !=
                                                                            null
                                                                    ) {
                                                                    } else if (
                                                                        !rightPanelSettings[
                                                                            panelIndex
                                                                        ]
                                                                            .isConfigurationCompleted
                                                                    ) {
                                                                        setShowWarningModal(
                                                                            {
                                                                                flag: true,
                                                                                type: "tab",
                                                                            }
                                                                        );
                                                                        setNextTabData(
                                                                            {
                                                                                obj: obj,
                                                                                index: index,
                                                                            }
                                                                        );
                                                                    } else {
                                                                        handleMoveToOtherTab(
                                                                            true,
                                                                            {
                                                                                obj: obj,
                                                                                index: index,
                                                                            }
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                {index !==
                                                                    panelIndex && (
                                                                    <>
                                                                        <FontAwesomeIcon
                                                                            className={
                                                                                styles.minusicon
                                                                            }
                                                                            style={{
                                                                                width: "30px",
                                                                                position:
                                                                                    "absolute",
                                                                                left: 0,
                                                                            }}
                                                                            icon={
                                                                                faPlus
                                                                            }
                                                                            onClick={(
                                                                                ev
                                                                            ) => {
                                                                                ev.stopPropagation();
                                                                                if (
                                                                                    show !==
                                                                                    undefined
                                                                                ) {
                                                                                    setShow(
                                                                                        undefined
                                                                                    );
                                                                                } else {
                                                                                    setShow(
                                                                                        index
                                                                                    );
                                                                                    setRightPanelIndex(
                                                                                        index
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                        {show ===
                                                                            index && (
                                                                            <div
                                                                                className={
                                                                                    styles.paneloption
                                                                                }
                                                                            >
                                                                                <p
                                                                                    onClick={(
                                                                                        event
                                                                                    ) => {
                                                                                        event.preventDefault();
                                                                                        handleImportOption(
                                                                                            index
                                                                                        );
                                                                                        setShow(
                                                                                            undefined
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Use
                                                                                    Existing
                                                                                </p>
                                                                            </div>
                                                                        )}
                                                                        <FontAwesomeIcon
                                                                            className={
                                                                                styles.minusicon
                                                                            }
                                                                            style={{
                                                                                width: "30px",
                                                                                position:
                                                                                    "absolute",
                                                                                right: 0,
                                                                            }}
                                                                            icon={
                                                                                faMinus
                                                                            }
                                                                            onClick={(
                                                                                ev
                                                                            ) => {
                                                                                ev.stopPropagation();
                                                                                removeLessionBlock(
                                                                                    index
                                                                                );
                                                                            }}
                                                                        />
                                                                    </>
                                                                )}

                                                                <div
                                                                    className={
                                                                        styles.customDropContainer
                                                                    }
                                                                >
                                                                    <CustomDropdown
                                                                        dropdownData={
                                                                            lessonData
                                                                        }
                                                                        width="100%"
                                                                        margin="auto"
                                                                        setData={(
                                                                            payload
                                                                        ) => {
                                                                            updateRightPanelSettings(
                                                                                index,
                                                                                payload.toUpperCase(),
                                                                                payload,
                                                                                (
                                                                                    rpSettings
                                                                                ) =>
                                                                                    updateSession(
                                                                                        rpSettings,
                                                                                        () => {
                                                                                            if (
                                                                                                payload ===
                                                                                                "Lesson"
                                                                                            ) {
                                                                                                history.push(
                                                                                                    `/session/${sessionID}/tool?panelIndex=${index}${urlParam}`
                                                                                                );
                                                                                            } else if (
                                                                                                payload ===
                                                                                                "Video"
                                                                                            ) {
                                                                                                history.push(
                                                                                                    `/session/${sessionID}/viewTool?panelIndex=${index}${urlParam}`
                                                                                                );
                                                                                            } else if (
                                                                                                payload ===
                                                                                                "Activity"
                                                                                            ) {
                                                                                                if (
                                                                                                    rightPanelSettings[
                                                                                                        index
                                                                                                    ]
                                                                                                        .subType
                                                                                                ) {
                                                                                                    history.push(
                                                                                                        `/session/${sessionID}/activity/customise?panelIndex=${index}${urlParam}`
                                                                                                    );
                                                                                                } else {
                                                                                                    history.push(
                                                                                                        `/session/${sessionID}/activity/list?panelIndex=${index}${urlParam}`
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    )
                                                                            );
                                                                        }}
                                                                        data={
                                                                            obj.label
                                                                                ? `${obj.label}`
                                                                                : handleData(
                                                                                      obj.type
                                                                                  )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            {importModal && (
                                                                <div
                                                                    className={
                                                                        styles.importModal
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon
                                                                        className={
                                                                            styles.crossIcon
                                                                        }
                                                                        icon={
                                                                            faTimes
                                                                        }
                                                                        onClick={
                                                                            handleImportModal
                                                                        }
                                                                    />
                                                                    <p
                                                                        className={
                                                                            styles.classModalText
                                                                        }
                                                                    >
                                                                        Pick the
                                                                        class
                                                                        you
                                                                        would
                                                                        like to
                                                                        access
                                                                    </p>
                                                                    <div
                                                                        className={
                                                                            styles.modalClassWrapper
                                                                        }
                                                                    >
                                                                        {selectClass ? (
                                                                            <div
                                                                                className={
                                                                                    styles.modalClassScroll
                                                                                }
                                                                            >
                                                                                <Row>
                                                                                    <Col>
                                                                                        <div
                                                                                            className={
                                                                                                styles.modalClassBlock
                                                                                            }
                                                                                        >
                                                                                            <img
                                                                                                className={
                                                                                                    styles.modalCoverImage
                                                                                                }
                                                                                                src={
                                                                                                    sessionData
                                                                                                        .image_ids[0] &&
                                                                                                    sessionData
                                                                                                        .image_ids[0]
                                                                                                        .location
                                                                                                }
                                                                                                alt=""
                                                                                                onError={
                                                                                                    handleDefaultImg
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        <p
                                                                                            className={
                                                                                                styles.modalClassName
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                sessionData.class_name
                                                                                            }
                                                                                        </p>
                                                                                    </Col>
                                                                                    <Col></Col>
                                                                                    <Col></Col>
                                                                                </Row>
                                                                                <div
                                                                                    className={
                                                                                        styles.modalLessonWrapper
                                                                                    }
                                                                                >
                                                                                    {sessionData.configuration.right_panel_settings.map(
                                                                                        (
                                                                                            obj,
                                                                                            index
                                                                                        ) => {
                                                                                            return (
                                                                                                <div
                                                                                                    className={
                                                                                                        styles.modalLesson
                                                                                                    }
                                                                                                    onClick={() => {
                                                                                                        handleRightPanelSetting(
                                                                                                            index
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    <p
                                                                                                        className={
                                                                                                            styles.modalLessonName
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            obj.type
                                                                                                        }
                                                                                                    </p>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div
                                                                                className={
                                                                                    styles.modalClassScroll
                                                                                }
                                                                            >
                                                                                <Row>
                                                                                    {sessions.map(
                                                                                        (
                                                                                            obj,
                                                                                            index
                                                                                        ) => {
                                                                                            return (
                                                                                                <Col
                                                                                                    md={
                                                                                                        4
                                                                                                    }
                                                                                                >
                                                                                                    <div
                                                                                                        className={
                                                                                                            styles.modalClassBlock
                                                                                                        }
                                                                                                        onClick={(
                                                                                                            event
                                                                                                        ) => {
                                                                                                            handleSelectClass(
                                                                                                                event,
                                                                                                                index
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        {obj
                                                                                                            .image_ids
                                                                                                            .length >=
                                                                                                            1 && (
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    position:
                                                                                                                        "relative",
                                                                                                                    height: "100%",
                                                                                                                }}
                                                                                                            >
                                                                                                                {obj
                                                                                                                    .image_ids
                                                                                                                    .length >
                                                                                                                    1 && (
                                                                                                                    <>
                                                                                                                        <FontAwesomeIcon
                                                                                                                            style={{
                                                                                                                                color: "#83838b",
                                                                                                                                width: "30px",
                                                                                                                                position:
                                                                                                                                    "absolute",
                                                                                                                                left: "-7px",
                                                                                                                                marginTop:
                                                                                                                                    "10px",
                                                                                                                                zIndex: "8",
                                                                                                                                top: "20px",
                                                                                                                                cursor: "pointer",
                                                                                                                            }}
                                                                                                                            icon={
                                                                                                                                faLessThan
                                                                                                                            }
                                                                                                                            onClick={(
                                                                                                                                event
                                                                                                                            ) => {
                                                                                                                                event.preventDefault();
                                                                                                                                event.stopPropagation();
                                                                                                                                decreaseImgModalFlag(
                                                                                                                                    obj
                                                                                                                                        .image_ids
                                                                                                                                        .length
                                                                                                                                );
                                                                                                                            }}
                                                                                                                        />
                                                                                                                        <FontAwesomeIcon
                                                                                                                            style={{
                                                                                                                                color: "#83838b",
                                                                                                                                width: "30px",
                                                                                                                                position:
                                                                                                                                    "absolute",
                                                                                                                                right: "-7px",
                                                                                                                                marginTop:
                                                                                                                                    "10px",
                                                                                                                                zIndex: "8",
                                                                                                                                top: "20px",
                                                                                                                                cursor: "pointer",
                                                                                                                            }}
                                                                                                                            icon={
                                                                                                                                faGreaterThan
                                                                                                                            }
                                                                                                                            onClick={(
                                                                                                                                event
                                                                                                                            ) => {
                                                                                                                                event.preventDefault();
                                                                                                                                event.stopPropagation();
                                                                                                                                increaseImgModalFlag(
                                                                                                                                    obj
                                                                                                                                        .image_ids
                                                                                                                                        .length
                                                                                                                                );
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </>
                                                                                                                )}
                                                                                                                {obj.image_ids.map(
                                                                                                                    (
                                                                                                                        element,
                                                                                                                        index
                                                                                                                    ) => {
                                                                                                                        const fileType =
                                                                                                                            element.location
                                                                                                                                .split(
                                                                                                                                    "."
                                                                                                                                )
                                                                                                                                .pop();
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                {fileType ===
                                                                                                                                "mp4"
                                                                                                                                    ? imgModalFlag ===
                                                                                                                                          index && (
                                                                                                                                          <video
                                                                                                                                              className={
                                                                                                                                                  styles.modalCoverImage
                                                                                                                                              }
                                                                                                                                              src={
                                                                                                                                                  element.location
                                                                                                                                              }
                                                                                                                                              controls
                                                                                                                                          ></video>
                                                                                                                                      )
                                                                                                                                    : imgModalFlag ===
                                                                                                                                          index && (
                                                                                                                                          <img
                                                                                                                                              className={
                                                                                                                                                  styles.modalCoverImage
                                                                                                                                              }
                                                                                                                                              src={
                                                                                                                                                  element.location
                                                                                                                                              }
                                                                                                                                              alt=""
                                                                                                                                              onError={
                                                                                                                                                  handleDefaultImg
                                                                                                                                              }
                                                                                                                                          />
                                                                                                                                      )}
                                                                                                                            </>
                                                                                                                        );
                                                                                                                    }
                                                                                                                )}
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <p
                                                                                                        className={
                                                                                                            styles.modalClassName
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            obj.class_name
                                                                                                        }
                                                                                                    </p>
                                                                                                </Col>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Row>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>

                                    <FontAwesomeIcon
                                        className={styles.plusicon}
                                        style={{
                                            width: "30px",
                                        }}
                                        icon={faPlus}
                                        onClick={addLessionBlock}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={2}>
                        <div
                            style={{ position: "relative" }}
                            onClick={() => history.push("/home")}
                        >
                            <img
                                src={Logo}
                                className={styles.logoImg}
                                alt="Logo"
                            />
                            <h1 className={styles.logoText}>
                                plassroom<sup>+</sup>
                            </h1>
                            <p className={styles.logoSubText}>Teacher</p>
                            <h1 className={styles.rightTime}>
                                <Clock />
                            </h1>
                        </div>
                        <div
                            className={`${styles.rightTabSection} ${styles.studentScroll}`}
                        >
                            <FontAwesomeIcon
                                style={{
                                    width: "30px",
                                    marginLeft: "6rem",
                                    cursor: "pointer",
                                    opacity:
                                        rightPanelSettingsToShow[0]?._id !==
                                            rightPanelSettings[0]?._id &&
                                        rightPanelSettings.length > 5
                                            ? 1
                                            : 0,
                                }}
                                icon={faAngleUp}
                                onClick={moveSessionUp}
                            />
                            {rightPanelSettingsToShow.map((obj, index) => {
                                if (
                                    obj._id ===
                                    rightPanelSettings[panelIndex]._id
                                ) {
                                    return (
                                        <div
                                            className={styles.rightTabContainer}
                                        >
                                            <h1
                                                contentEditable="true"
                                                className={styles.rightTab}
                                                onBlur={(ev) => {
                                                    handleRightTab(ev, index);
                                                }}
                                                onKeyDown={(e) =>
                                                    handleTextChange(e)
                                                }
                                                id={`righttab${index}`}
                                            >
                                                {obj.label ? (
                                                    <>{obj.label}</>
                                                ) : (
                                                    <>{rightTab(obj.type)}</>
                                                )}
                                            </h1>
                                            <img
                                                src={Edit}
                                                className={styles.editImg}
                                                alt="Logo"
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor: "#C8E8E5",
                                            }}
                                            className={styles.rightTabContainer}
                                        >
                                            <h1
                                                onBlur={(ev) => {
                                                    handleRightTab(ev, index);
                                                }}
                                                onKeyDown={(e) =>
                                                    handleTextChange(e)
                                                }
                                                contentEditable="true"
                                                className={styles.rightTab}
                                                id={`righttab${index}`}
                                            >
                                                {obj.label ? (
                                                    <>{obj.label}</>
                                                ) : (
                                                    <>{rightTab(obj.type)}</>
                                                )}
                                            </h1>
                                        </div>
                                    );
                                }
                            })}
                            <FontAwesomeIcon
                                style={{
                                    width: "30px",
                                    marginLeft: "6rem",
                                    cursor: "pointer",
                                    opacity:
                                        rightPanelSettingsToShow[
                                            rightPanelSettingsToShow.length - 1
                                        ]?._id !==
                                            rightPanelSettings[
                                                rightPanelSettings.length - 1
                                            ]?._id &&
                                        rightPanelSettings.length > 5
                                            ? 1
                                            : 0,
                                }}
                                icon={faAngleDown}
                                onClick={moveSessionDown}
                            />
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    );
};
