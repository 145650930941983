import axios from "axios";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import goBack from "../../assets/images/icon/goBack.svg";
import Navbar from "../../components/Navbar";
import Snackbar from "../../components/Snackbar";
import styles from "./index.module.scss";

const StripePaymentCancel = (props) => {
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [studentDetails, setStudentDetails] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchStudentDetails();
        // eslint-disable-next-line
    }, []);

    const fetchStudentDetails = async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(
                `/api/v1/session/${props?.match?.params?.classID}/register`
            );
            setLoading(false);
            if (data?.success) {
                setStudentDetails(data?.data);
            }
        } catch (error) {
            setLoading(false);
            setMsg(
                _.get(error, "response.data.title", "Something went wrong.")
            );
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        }
    };
    return (
        <Fragment>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <div className={styles.mainContainer}>
                <Navbar />

                <div className={styles.paymentCancel}>
                    <p className={styles.oops}>Oops, something went wrong.</p>
                    <p>Payment was unsuccessful. Please try again.</p>
                    <img
                        src={goBack}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            window.location.href = `/register/${props?.match?.params?.classID}`;
                        }}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default StripePaymentCancel;
