import React from "react";
import PropTypes from "prop-types";
import { toTwoDigitString } from "./utils";

DurationPickerColumn.propTypes = {
    onChange: PropTypes.func.isRequired,
    // note that 'minutes' and 'seconds' are abbreviated in this prop
    unit: PropTypes.oneOf(["hours", "mins", "secs"]).isRequired,
    isSmallScreen: PropTypes.bool,
    maxHours: PropTypes.number,
    cellHeight: PropTypes.number,
    initial: PropTypes.number,
};

DurationPickerColumn.defaultProps = {
    isSmallScreen: undefined,
    maxHours: 10,
    cellHeight: 35,
    initial: 0,
};

function DurationPickerColumn(props) {
    const { unit, maxHours, onChange, date } = props;
    const numCells = unit === "hours" ? maxHours + 1 : 60;
    let currentHour = 0;
    let currentMin = 0;

    const numbers = [];

    if (date) {
        const date1 = new Date();
        const date2 = new Date(date);
        const currentDate = date1.getDate();
        const selectedDate = date2.getDate();
        const selectedHrs = date2.getHours();

        if (currentDate === selectedDate - 1) {
            currentHour = date1.getHours();
            currentMin = selectedHrs > currentHour ? 0 : date1.getMinutes();
        }
    }

    for (
        let i =
            unit === "hours" ? currentHour : unit === "mins" ? currentMin : 0;
        i < numCells;
        i++
    ) {
        numbers.push(i);
    }
    const offsetState = {
        cellContents: numbers,
    };

    const setTime = (value) => {
        onChange(value);
    };

    const cells = offsetState.cellContents.map((value) => {
        return (
            <div
                className="rdp-cell"
                key={value}
                onClick={() => setTime(toTwoDigitString(value))}
            >
                {toTwoDigitString(value)}
            </div>
        );
    });

    return (
        <div className="rdp-column-container">
            <div className="rdp-masked-div">
                <div className="rdp-column">{cells}</div>
            </div>
        </div>
    );
}

export default DurationPickerColumn;
