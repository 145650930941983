import _ from "lodash";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import ChatIcon from "../../../../assets/images/PlayPage/MessageIcon.svg";
import Navbar from "../../../../components/Navbar";
import Snackbar from "../../../../components/Snackbar";
import styles from "./mobileLanding.module.scss";
import CommonButton from "../../../../components/Button/CommonButton";
import SubscriptionFooter from "../../../../components/FooterNew/SubscriptionFooter";
import { Link } from "react-router-dom";

const TeacherLanding = ({
    user,
    currentUserRole,
    listOfUpcomingOrOngoingSessions,
}) => {
    const history = useHistory();

    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const formatDate = (time) => {
        let formattedTime = "-";
        if (time) {
            formattedTime = moment(time).format("D MMM");
        }
        return formattedTime;
    };

    const formatTime = (time) => {
        let formattedTime = "-";
        if (time) {
            formattedTime = `${moment(time).format("h:mm A")} ${moment
                .tz(moment.tz.guess())
                .zoneAbbr()}`;
        }
        return formattedTime;
    };

    const minutesToHours = (time) => {
        let duration = `${time} minutes`;

        if (time > 60) {
            const hours = Math.floor(time / 60);
            const minutes = time - hours * 60;

            duration = `${hours} hours ${minutes} minutes`;
        }

        return duration;
    };

    /**
     * @description Initiate individual chat by student.
     */
    const handleInitiateChat = (classDetails) => {
        history.push({
            pathname: "/chat",
            state: {
                classDetails,
            },
        });
    };

    /**
     * @description Initiate group chat by teachers.
     */
    const handleInitializeGroupChat = (session) => {
        history.push({
            pathname: "/chat",
            state: {
                classDetails: session,
                type: "group",
            },
        });
    };

    return (
        <Fragment>
            <Snackbar
                status={"error"}
                message={msg}
                open={showSnackbar}
                setOpen={setShowSnackbar}
            />
            <div
                className={`${styles.landingContainer} ${
                    currentUserRole === "teacher"
                        ? styles.teacherLandingContainer
                        : styles.studentLandingContainer
                }`}
            >
                <Navbar screen={"Landing"} />

                <div className={styles.landingPage}>
                    <div className={styles.userName}>
                        Welcome, {_.get(user, "preferedName", "-")}.<br />
                        List of upcoming Sessions
                    </div>

                    <div className={styles.upcomingClassesListOuterContainer}>
                        <div className={styles.upcomingClassesListHeader}>
                            Please access Plassroom via your PC to see other
                            functions and to attend your sessions. We recommend
                            Google Chrome.
                        </div>

                        <div className={styles.upcomingClassesListContainer}>
                            {listOfUpcomingOrOngoingSessions.map(
                                (session, index) => {
                                    return (
                                        <div
                                            className={
                                                styles.upcomingClassContainer
                                            }
                                            key={index}
                                        >
                                            <div
                                                className={
                                                    styles.classDateContainer
                                                }
                                            >
                                                <div
                                                    className={styles.classDate}
                                                >
                                                    {formatDate(
                                                        _.get(
                                                            session,
                                                            "configuration.session_start_time"
                                                        )
                                                    )}
                                                </div>

                                                {_.size(
                                                    _.get(
                                                        session,
                                                        "students_ids",
                                                        []
                                                    )
                                                ) ? (
                                                    <img
                                                        src={ChatIcon}
                                                        alt="Chat Icon"
                                                        className={
                                                            styles.chatIcon
                                                        }
                                                        onClick={() =>
                                                            currentUserRole ===
                                                            "teacher"
                                                                ? handleInitializeGroupChat(
                                                                      session
                                                                  )
                                                                : handleInitiateChat(
                                                                      session
                                                                  )
                                                        }
                                                    />
                                                ) : null}
                                            </div>

                                            <div
                                                className={
                                                    styles.classDetailsContainer
                                                }
                                            >
                                                <div
                                                    className={styles.className}
                                                >
                                                    {_.get(
                                                        session,
                                                        "class_name"
                                                    )}
                                                </div>

                                                <div
                                                    className={styles.classTime}
                                                >
                                                    {formatTime(
                                                        _.get(
                                                            session,
                                                            "configuration.session_start_time"
                                                        )
                                                    )}{" "}
                                                    (
                                                    {minutesToHours(
                                                        _.get(
                                                            session,
                                                            "configuration.session_duration"
                                                        )
                                                    )}
                                                    )
                                                </div>

                                                <div
                                                    className={
                                                        styles.classTotalStudents
                                                    }
                                                >
                                                    {_.size(
                                                        _.get(
                                                            session,
                                                            "students_ids",
                                                            []
                                                        )
                                                    )}{" "}
                                                    of{" "}
                                                    {_.get(
                                                        session,
                                                        "configuration.max_students",
                                                        "-"
                                                    )}{" "}
                                                    students
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>

                    <CommonButton
                        buttonText="Browse sessions here"
                        handleClick={() => (window.location.href = "/home")}
                        classes={styles.browseBtn}
                    />
                    {currentUserRole != "teacher" && (
                        <Link className={styles.prefer} to="/accountSettings">
                            Update your preferences
                        </Link>
                    )}
                </div>
            </div>
            <SubscriptionFooter />
        </Fragment>
    );
};

export default TeacherLanding;
