import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import styles from "./subFooter.module.scss";
import CoinSvg from "../../assets/images/Plasscoin.svg";
import { dateFormat } from ".././../utils/commonFunction";

const SubscriptionFooter = () => {
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [details, setDetails] = useState(false);
    const [currentPlan, setCurrentPlan] = useState({});
    const [plassCoins, setPlassCoins] = useState(0);

    useEffect(() => {
        if (localStorage.getItem("auth")) {
            if (
                JSON.parse(localStorage.getItem("auth")).user.role === "student"
            ) {
                getSubscriptionDetails();
                fetchPlanDetails();
            }
        }
        // eslint-disable-next-line
    }, []);

    const getSubscriptionDetails = async () => {
        await axios
            .get("api/v1/subscription")
            .then((response) => {
                if (response?.data?.success) {
                    if (response?.data?.data?.subscription?.subscriptionPlan) {
                        setDetails(response?.data?.data?.subscription);
                    }
                    if (!!response?.data?.data?.plan?.isActive) {
                        setCurrentPlan(response?.data?.data?.plan);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchPlanDetails = async (id) => {
        await axios
            .get(`/api/v1/user/plan`)
            .then((response) => {
                if (response?.data?.success) {
                    setPlassCoins(response?.data?.data?.totalRemainingMinutes);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div className={styles.footerContainer}>
            {!!details?.subscriptionPlan?.active ? (
                <div className={styles.activePlanContainer}>
                    <p className={styles.planTitle}>
                        The {currentPlan?.type} Plan
                    </p>

                    <p className={styles.balance}>
                        Balance <img src={CoinSvg} /> <span>{plassCoins}</span>
                        <br />
                        and unlimited free sessions
                    </p>
                    <p className={styles.nextRefresh}>
                        Next Refresh by{" "}
                        {dateFormat(
                            details?.subscriptionEndDate,
                            "DD MMM YYYY"
                        ) || "30 days after applied"}
                    </p>
                </div>
            ) : (
                <div className={styles.noSubscription}>
                    <p className={styles.checkout}>
                        Check out our current subscriptions plan
                    </p>
                    <p
                        className={styles.planTitle}
                        onClick={() => {
                            window.location.href =
                                "/choose-plan-and-subscription-plan";
                        }}
                    >
                        The LITE Plan
                    </p>
                    <p
                        className={styles.planTitle}
                        onClick={() => {
                            window.location.href =
                                "/choose-plan-and-subscription-plan";
                        }}
                    >
                        The PLASS Plan
                    </p>
                </div>
            )}
        </div>
    );
};

export default SubscriptionFooter;
