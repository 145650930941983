import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import "./DurationPicker.css";
import DurationPickerColumn from "./DurationPickerColumn";

DurationPicker.propTypes = {
    onChange: PropTypes.func,
    initialDuration: PropTypes.shape({
        hours: PropTypes.number,
        minutes: PropTypes.number,
        seconds: PropTypes.number,
    }),
    maxHours: PropTypes.number,
    noHours: PropTypes.bool,
};

DurationPicker.defaultProps = {
    maxHours: 10,
    onChange: () => {},
    initialDuration: { hours: 0, minutes: 0, seconds: 0 },
    noHours: false,
};

function DurationPicker(props) {
    const {
        onChange,
        maxHours,
        noHours,
        initialDuration,
        componentName,
        date,
    } = props;
    const [isSmallScreen, setIsSmallScreen] = useState(undefined);
    const [duration, setDuration] = useState(initialDuration);
    // const [hideHours, setHideHours] = useState(false);

    // column onChange handlers
    const onChangeHours = (hours) => {
        setDuration((prevDuration) => ({ ...prevDuration, hours }));
    };

    const onChangeMinutes = useCallback((minutes) => {
        setDuration((prevDuration) => {
            if (minutes === 59 && prevDuration.minutes === 0) {
                return {
                    ...prevDuration,
                    minutes,
                    ...{
                        hours:
                            prevDuration.hours === 0
                                ? 23
                                : prevDuration.hours - 1,
                    },
                };
            }
            if (minutes === 0 && prevDuration.minutes === 59) {
                return {
                    ...prevDuration,
                    minutes,
                    ...{
                        hours:
                            prevDuration.hours === 23
                                ? 0
                                : prevDuration.hours + 1,
                    },
                };
            }
            return { ...prevDuration, minutes };
        });
    }, []);

    const onChangeSeconds = useCallback((seconds) => {
        setDuration((prevDuration) => {
            if (seconds === 59 && prevDuration.seconds === 0) {
                return {
                    ...prevDuration,
                    seconds,
                    ...{
                        hours:
                            prevDuration.hours === 0
                                ? 23
                                : prevDuration.hours - 1,
                    },
                };
            }
            if (seconds === 0 && prevDuration.seconds === 59) {
                return {
                    ...prevDuration,
                    seconds,
                    ...{
                        hours:
                            prevDuration.hours === 23
                                ? 0
                                : prevDuration.hours + 1,
                    },
                };
            }
            return { ...prevDuration, seconds };
        });
    }, []);

    // add/remove resize listener and measure screen size
    useEffect(() => {
        const resizeHandler = () => {
            if (window.innerWidth <= 400) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false);
            }
        };
        resizeHandler();
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
        // eslint-disable-next-line
    }, []);

    // execute callback prop
    useEffect(() => {
        onChange(duration);
        // eslint-disable-next-line
    }, [duration, onChange]);
    return (
        <div className="rdp-picker">
            {duration && (
                <>
                    {!noHours && componentName !== "Game" && (
                        <DurationPickerColumn
                            onChange={onChangeHours}
                            unit="hours"
                            maxHours={maxHours}
                            isSmallScreen={isSmallScreen}
                            initial={initialDuration.hours}
                            date={date}
                        />
                    )}
                    <DurationPickerColumn
                        onChange={onChangeMinutes}
                        unit="mins"
                        focussed
                        isSmallScreen={isSmallScreen}
                        initial={initialDuration.minutes}
                        date={date}
                    />
                    {componentName === "Game" && (
                        <DurationPickerColumn
                            onChange={onChangeSeconds}
                            unit="secs"
                            isSmallScreen={isSmallScreen}
                            initial={initialDuration.seconds}
                            date={date}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default DurationPicker;
