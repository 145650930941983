import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import _ from "lodash";
import React, { useRef, useState } from "react";
import CustomDropdown from "../../../components/CustomDropdown";
import Snackbar from "../../../components/Snackbar";
import styles from "./signup2.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export default ({ data, socialData, marketingMessages, verifyLink }) => {
    const [checkbtn, setCheckbtn] = useState("parent");
    const [student, setStudent] = useState(1);
    // const [studentData, setStudentData] = useState([]);
    const [id, setId] = useState(undefined);
    // const [formData, setFormData] = useState();
    const [fileData, setFileData] = useState();
    const [type, setType] = useState();
    const [msg, setMsg] = useState(null);
    const [duration, setDuration] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [imageName, setImageName] = useState("");
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [imageURL, setImageURL] = useState("");

    // const [student, setStudent] = useState([1]);

    const [parentData, setParentData] = useState({
        name: "",
        preferedName: "",
        birthDate: "",
        student: [
            {
                studentname: "",
                studentpreferedname: "",
                studentbirthdate: "",
            },
        ],
        contact: "",
    });

    const [teacherData, setTeacherData] = useState({
        name: "",
        preferedName: "",
        birthDate: "",
        contact: "",
    });

    const handleCheckbtn = (value) => {
        setCheckbtn(value);
    };

    const handleChange = (evt, key) => {
        const regexPattern = new RegExp("^[+]?[0-9]*$");

        if (checkbtn === "parent") {
            if (key) {
                let object = parentData.student[key - 1];
                object = { ...object, [evt.target.name]: evt.target.value };
                let array = parentData.student;
                array[key - 1] = object;
                setParentData({
                    ...parentData,
                    student: array,
                });
            } else {
                let inputValue = evt.target.value;
                if (
                    evt.target.name === "contact" &&
                    !regexPattern.test(inputValue)
                ) {
                    inputValue = parentData.contact;
                }

                setParentData({
                    ...parentData,
                    [evt.target.name]: inputValue,
                });
            }
        } else {
            let inputValue = evt.target.value;
            if (
                evt.target.name === "contact" &&
                !regexPattern.test(inputValue)
            ) {
                inputValue = parentData.contact;
            }

            setTeacherData({
                ...teacherData,
                [evt.target.name]: inputValue,
            });
        }
    };

    const handleDateChange = (date, name, key) => {
        date = date ? moment(date).format("YYYY-MM-DD") : "";
        if (checkbtn === "parent") {
            if (key) {
                let object = parentData.student[key - 1];
                object = { ...object, [name]: date };
                let array = parentData.student;
                array[key - 1] = object;
                setParentData({
                    ...parentData,
                    student: array,
                });
            } else {
                setParentData({
                    ...parentData,
                    [name]: date,
                });
            }
        } else {
            setTeacherData({
                ...teacherData,
                [name]: date,
            });
        }
    };

    const handleKeyPress = (evt) => {
        if (evt.key === "Enter") {
            handleFinish();
        }
    };

    const handleAnotherStudent = () => {
        setStudent(student + 1);
    };

    const IdData = [
        { label: "Passport", value: "Passport" },
        { label: "Driving license", value: "Driving license" },
        {
            label: "Biometric residence permit",
            value: "Biometric residence permit",
        },
    ];

    const handleUploadImage = async (fileData, type) => {
        if (fileData) {
            const url = URL.createObjectURL(fileData);
            setFileData(fileData);
            setType(type);

            setImageName(fileData.name);
            setImageURL(url);
        } else {
            // setError("Please upload atleast 1 image before proceeding.");
        }
    };

    const handleFinish = async () => {
        let formData = new FormData();
        formData.append("image", fileData);
        formData.append("type", type);
        if (checkbtn === "parent") {
            const formParentData = parentData;

            if (socialData?.email) {
                let userData = {
                    marketingMessages: marketingMessages,
                    data: socialData,
                    parentData: formParentData,
                    role: "student",
                    verifyLink: verifyLink,
                };

                await axios
                    .post("/api/v1/user/register", userData)
                    .then((res) => {
                        if (!res.data.success) {
                            throw res;
                        }
                    })
                    .catch((error) => {
                        let message = _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        );
                        if (message === "Something went wrong.") {
                            message = _.get(
                                error,
                                "data.message",
                                "Something went wrong."
                            );
                        }
                        setMsg(message);
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                            setShowSnackbar(false);
                        }, 3000);
                        return Promise.reject(error);
                    });
            } else {
                let userData = {
                    marketingMessages: marketingMessages,
                    data: data,
                    parentData: formParentData,
                    role: "student",
                    verifyLink: verifyLink,
                };

                await axios
                    .post("/api/v1/user/register", userData)
                    .then((res) => {
                        if (!res.data.success) {
                            throw res;
                        }
                    })
                    .catch((error) => {
                        let message = _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        );
                        if (message === "Something went wrong.") {
                            message = _.get(
                                error,
                                "data.message",
                                "Something went wrong."
                            );
                        }
                        setMsg(message);
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                            setShowSnackbar(false);
                        }, 3000);
                        return Promise.reject(error);
                    });
            }
            localStorage.setItem("plassroom-email", data.email);
            window.location.href = "/registration-successful";
        } else if (fileData && type) {
            const formTeacherData = teacherData;

            if (socialData?.email) {
                let userData = {
                    marketingMessages: marketingMessages,
                    data: socialData,
                    teacherData: formTeacherData,
                    id,
                    role: "teacher",
                };

                await axios
                    .post("/api/v1/user/register", userData)
                    .then(async (result) => {
                        if (result.data.success) {
                            formData.append("userid", result.data.data._id);

                            await axios
                                .post("/api/v1/user/upload", formData)
                                .then((res) => {
                                    if (res.data.success) {
                                        localStorage.setItem(
                                            "plassroom-email",
                                            data.email
                                        );
                                        window.location.href =
                                            "/registration-successful";
                                    } else {
                                        throw res;
                                    }
                                })
                                .catch((error) => {
                                    let message = _.get(
                                        error,
                                        "response.data.title",
                                        "Something went wrong."
                                    );
                                    if (message === "Something went wrong.") {
                                        message = _.get(
                                            error,
                                            "data.message",
                                            "Something went wrong."
                                        );
                                    }
                                    setMsg(message);
                                    setShowSnackbar(true);
                                    setTimeout(function () {
                                        setMsg(null);
                                        setShowSnackbar(false);
                                    }, 3000);
                                });
                        } else {
                            throw result;
                        }
                    })
                    .catch((error) => {
                        let message = _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        );
                        if (message === "Something went wrong.") {
                            message = _.get(
                                error,
                                "data.message",
                                "Something went wrong."
                            );
                        }
                        setMsg(message);
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                            setShowSnackbar(false);
                        }, 3000);
                    });
            } else {
                let userData = {
                    marketingMessages: marketingMessages,
                    data: data,
                    teacherData: formTeacherData,
                    id,
                    role: "teacher",
                };

                await axios
                    .post("/api/v1/user/register", userData)
                    .then(async (result) => {
                        if (result.data.success) {
                            formData.append("userid", result.data.data._id);

                            await axios
                                .post("/api/v1/user/upload", formData)
                                .then((res) => {
                                    if (res.data.success) {
                                        localStorage.setItem(
                                            "plassroom-email",
                                            data.email
                                        );
                                        window.location.href =
                                            "/registration-successful";
                                    } else {
                                        throw res;
                                    }
                                })
                                .catch((error) => {
                                    let message = _.get(
                                        error,
                                        "response.data.title",
                                        "Something went wrong."
                                    );
                                    if (message === "Something went wrong.") {
                                        message = _.get(
                                            error,
                                            "data.message",
                                            "Something went wrong."
                                        );
                                    }
                                    setMsg(message);
                                    setShowSnackbar(true);
                                    setTimeout(function () {
                                        setMsg(null);
                                        setShowSnackbar(false);
                                    }, 3000);
                                });
                        } else {
                            throw result;
                        }
                    })
                    .catch((error) => {
                        let message = _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        );
                        if (message === "Something went wrong.") {
                            message = _.get(
                                error,
                                "data.message",
                                "Something went wrong."
                            );
                        }
                        setMsg(message);
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                            setShowSnackbar(false);
                        }, 3000);
                    });
            }
        } else {
            setMsg(
                "Please upload a clear and genuine photo of your identification document."
            );
            setShowSnackbar(true);
            setDuration(6000);
            setTimeout(function () {
                setMsg(null);
                setDuration(null);
                setShowSnackbar(false);
            }, 8000);
        }
    };

    const inputFile = useRef(null);

    const handleUploadFile = () => {
        inputFile.current.click();
    };

    const fileSelected = (event) => {
        if (event.target.files.length > 0) {
            const extension = event.target.files[0].type
                .split("/")[1]
                .toUpperCase();
            const type = event.target.files[0].type.split("/")[0].toUpperCase();
            const file_size = event.target.files[0].size;
            if (
                type === "IMAGE" &&
                (extension === "PNG" ||
                    extension === "JPEG" ||
                    extension === "JPG")
            ) {
                if (file_size / 1000000 > 5) {
                    setMsg("Please select file lesser than 5 MB");
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                        setShowSnackbar(false);
                    }, 5000);
                } else {
                    handleUploadImage(event.target.files[0], type);
                }
            } else {
                setMsg("Invalid file type");
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                    setShowSnackbar(false);
                }, 3000);
            }
        }
    };

    const deleteFile = () => {
        setFileData();
        setType();
        setImageName();
    };

    return (
        <>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                    duration={duration}
                ></Snackbar>
            )}

            <div className={styles.formWrapper}>
                <h2 className={styles.heading}>Sign Up</h2>
                <div className={styles.typeWrapper}>
                    <div className={styles.radiobtn}>
                        <div
                            className={styles.checkBtn}
                            style={{
                                backgroundColor:
                                    checkbtn === "teacher" ? "#6D9ED4" : "",
                            }}
                            onClick={() => {
                                handleCheckbtn("teacher");
                            }}
                        ></div>
                        <p className={styles.type}>Teacher</p>
                    </div>
                    <div className={styles.radiobtn}>
                        <div
                            className={styles.checkBtn}
                            style={{
                                backgroundColor:
                                    checkbtn === "parent" ? "#6D9ED4" : "",
                            }}
                            onClick={() => {
                                handleCheckbtn("parent");
                            }}
                        ></div>
                        <p className={styles.type}>Parent</p>
                    </div>
                </div>
                <p className={styles.label}> Your Name</p>
                <div className={styles.nameInputWrapper}>
                    <div>
                        <input
                            placeholder="Full name"
                            type="text"
                            className={styles.topInput}
                            name="name"
                            value={
                                checkbtn === "parent"
                                    ? parentData.name
                                    : teacherData.name
                            }
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <div>
                        <input
                            placeholder="Preferred name"
                            type="text"
                            className={styles.middleInput}
                            name="preferedName"
                            value={
                                checkbtn === "parent"
                                    ? parentData.preferedName
                                    : teacherData.preferedName
                            }
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <div>
                        <DatePicker
                            className={styles.bottomInput}
                            popperClassName={styles.datepickerPopper}
                            name="birthDate"
                            dateFormat="dd/MM/yyyy"
                            selected={
                                checkbtn === "parent"
                                    ? parentData?.birthDate &&
                                      new Date(parentData?.birthDate)
                                    : teacherData?.birthDate &&
                                      new Date(teacherData?.birthDate)
                            }
                            onChange={(e) => handleDateChange(e, "birthDate")}
                            placeholderText="Date of Birth"
                            yearDropdownItemNumber={50}
                            scrollableYearDropdown={true}
                            showYearDropdown
                        />
                        {/* <input
                            // placeholder="Birth date"
                            type="date"
                            className={styles.bottomInput}
                            name="birthDate"
                            value={
                                checkbtn === "parent"
                                    ? parentData.birthDate
                                    : teacherData.birthDate
                            }
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                        /> */}
                    </div>
                </div>
                <p className={styles.para}>
                    Make sure it matches the information on your government ID.
                </p>
                {checkbtn === "parent" ? (
                    <>
                        {Array(student)
                            .fill()
                            .map((element, index) => {
                                return (
                                    <div key={index}>
                                        {student === 1 ? (
                                            <p className={styles.label}>
                                                Student{" "}
                                            </p>
                                        ) : (
                                            <p className={styles.label}>
                                                Student {index + 1}
                                            </p>
                                        )}

                                        <div
                                            className={styles.nameInputWrapper}
                                        >
                                            <div>
                                                <input
                                                    key={index + 1}
                                                    placeholder="Full name"
                                                    type="text"
                                                    className={styles.topInput}
                                                    name="studentname"
                                                    value={
                                                        parentData.student.name
                                                    }
                                                    onChange={(e) => {
                                                        handleChange(
                                                            e,
                                                            index + 1
                                                        );
                                                    }}
                                                    onKeyPress={handleKeyPress}
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    key={index + 1}
                                                    placeholder="Preferred name"
                                                    type="text"
                                                    className={
                                                        styles.middleInput
                                                    }
                                                    name="studentpreferedname"
                                                    value={
                                                        parentData.student
                                                            .preferedName
                                                    }
                                                    onChange={(e) => {
                                                        handleChange(
                                                            e,
                                                            index + 1
                                                        );
                                                    }}
                                                    onKeyPress={handleKeyPress}
                                                />
                                            </div>
                                            <div>
                                                {/* <input
                                                    key={index + 1}
                                                    // placeholder="Birth date"
                                                    type="date"
                                                    className={
                                                        styles.bottomInput
                                                    }
                                                    name="studentbirthdate"
                                                    value={
                                                        parentData.student
                                                            .birthDate
                                                    }
                                                    onChange={(e) => {
                                                        handleChange(
                                                            e,
                                                            index + 1
                                                        );
                                                    }}
                                                    // onKeyPress={handleKeyPress}
                                                /> */}
                                                <DatePicker
                                                    key={index + 1}
                                                    className={
                                                        styles.bottomInput
                                                    }
                                                    popperClassName={
                                                        styles.datepickerPopper
                                                    }
                                                    name="studentbirthdate"
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={
                                                        parentData.student[
                                                            index
                                                        ].studentbirthdate &&
                                                        new Date(
                                                            parentData.student[
                                                                index
                                                            ].studentbirthdate
                                                        )
                                                    }
                                                    onChange={(e) =>
                                                        handleDateChange(
                                                            e,
                                                            "studentbirthdate",
                                                            index + 1
                                                        )
                                                    }
                                                    // onKeyPress={handleKeyPress}
                                                    placeholderText="Date of Birth"
                                                    yearDropdownItemNumber={50}
                                                    scrollableYearDropdown={
                                                        true
                                                    }
                                                    showYearDropdown
                                                />
                                            </div>
                                        </div>
                                        <p className={styles.para}>
                                            Make sure it matches the information
                                            on your government ID.
                                        </p>
                                    </div>
                                );
                            })}
                    </>
                ) : (
                    <>
                        <p className={styles.label}>Upload ID picture</p>
                        <p className={styles.para}>
                            This information will be stored securely and will
                            only be accessed if a security concern arises.
                        </p>

                        <CustomDropdown
                            dropdownData={IdData}
                            data={id}
                            setData={setId}
                            width="100%"
                        />

                        <div>
                            <div
                                className={styles.fileupload}
                                onClick={handleUploadFile}
                            >
                                <p className={styles.toolText}>Choose File</p>
                                <FontAwesomeIcon
                                    className={styles.uploadIcon}
                                    style={{ width: "25px" }}
                                    icon={faPlus}
                                />
                            </div>
                            <input
                                type="file"
                                id="file"
                                onChange={fileSelected}
                                ref={inputFile}
                                className={styles.fileInput}
                                accept="image/*"
                            />
                        </div>
                        <p className={styles.para}>
                            PNG,JPEG,JPG,PDF files only
                        </p>

                        {imageName && (
                            <p className={styles.imageName__container}>
                                <div className={styles.imageName_sub_container}>
                                    {imageName}
                                </div>

                                <div
                                    className={
                                        styles.imageName_buttons_container
                                    }
                                >
                                    <span
                                        onClick={() =>
                                            setIsPreviewModalOpen(true)
                                        }
                                    >
                                        Preview
                                    </span>
                                    <span onClick={deleteFile}>Delete</span>
                                </div>
                            </p>
                        )}

                        {isPreviewModalOpen ? (
                            <div className={styles.uploadModal}>
                                <div className={styles.uploadModalContainer}>
                                    <FontAwesomeIcon
                                        className={styles.crossIcon}
                                        icon={faTimes}
                                        onClick={() =>
                                            setIsPreviewModalOpen(false)
                                        }
                                    />

                                    <div
                                        className={
                                            styles.previewImage__container
                                        }
                                    >
                                        <img src={imageURL} alt="" />
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </>
                )}
                <p className={styles.label}>Contact number</p>
                <div>
                    <input
                        placeholder="Optional"
                        type="text"
                        className={styles.numberInput}
                        name="contact"
                        value={
                            checkbtn === "parent"
                                ? parentData.contact
                                : teacherData.contact
                        }
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                    />
                </div>
                <p className={styles.continueBtn} onClick={handleFinish}>
                    Finish
                </p>
            </div>
        </>
    );
};
