import _ from "lodash";
import React from "react";
import styles from "./ChatDetails.module.scss";

const UserProfilePhoto = ({ user }) => {
    const profilePictureIds = _.get(user, "profilePictureIds");
    const photo = _.find(profilePictureIds, ["type", "IMAGE"]);
    let recipientURL;

    if (photo) {
        recipientURL = _.get(photo, "location");
    }

    return (
        <div className={styles.recipientProfilePhotoContainer}>
            {recipientURL ? (
                <img
                    src={recipientURL}
                    alt=""
                    className={styles.recipientProfilePhoto}
                />
            ) : (
                <span className={styles.recipientProfilePhoto}>
                    {_.get(user, "name", "U")?.trim()[0].toUpperCase()}
                </span>
            )}
        </div>
    );
};

const ChatDetails = ({ user }) => {
    const chatType = _.get(user, "ChatType");
    const teacherId = _.get(user, "teacher.username");

    return (
        <div className={styles.chat_details__container}>
            <UserProfilePhoto user={user} />

            <div
                className={styles.userName}
                onClick={() => {
                    window.open(
                        `${process.env.REACT_APP_FRONTEND_URL}/teacher-details/${teacherId}`,
                        "_blank"
                    );
                }}
            >
                {(_.get(user, "name") || "U")?.trim()}{" "}
                {chatType === "GROUP"
                    ? `with teacher ${_.get(
                          user,
                          "teacher.preferedName"
                      ).trim()}`
                    : ""}
            </div>
        </div>
    );
};

export default ChatDetails;
