import React, { useEffect, useState } from "react";
import { Container, Row, Col, Popover, OverlayTrigger } from "react-bootstrap";
import NavigationBtn from "../../components/NavigationBtn";
import styles from "./sessionHome.module.css";
import Layout1 from "./Layouts/layout1";
import Layout2 from "./Layouts/layout2";
import Layout3 from "./Layouts/layout3";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/images/Logo.svg";
import Clock from "../../components/Clock";
import socketIOClient from "socket.io-client";
import axios from "axios";
import { preloadScript } from "opentok-react";
import { withRouter } from "react-router";
import Screens from "../../assets/images/screens.svg";
import Star from "../../assets/images/star.svg";
import Inbox from "../../assets/images/inbox.svg";
import Camera from "../../assets/images/camera.svg";
import Screen1 from "../../assets/images/layout1.svg";
import Screen2 from "../../assets/images/layout2.svg";
import Screen3 from "../../assets/images/layout3.svg";
import Modal from "../../components/Modal";
import Lesson1 from "../SessionHome/Layouts/lesson1";
import Lesson2 from "../SessionHome/Layouts/lesson2";
import Play from "../../assets/images/play.svg";
import Pause from "../../assets/images/pause.svg";
import Stop from "../../assets/images/stop.svg";
import Refresh from "../../assets/images/refresh.svg";
import _ from "lodash";
import { audioEffects, graphics } from "../../components/Effects/effects";
import Video from "twilio-video";
import Snackbar from "../../components/Snackbar";

const SessionHome = (props) => {
    const [myMarkerColor, setMyMarkerColor] = useState("#FABF5E");
    const [initialStateSet, setInitialStateSet] = useState(true);
    const [selectedItem, setSelectedItem] = useState(0);
    const history = useHistory();
    const [sessionHelper, setSessionHelper] = useState(null);
    const [streams, setStreams] = useState([]);
    const [role, setRole] = useState("");
    const [duplicateUser, setDuplicateUser] = useState(false);
    const [socket, setSocket] = useState(null);
    const [socketEventsBinded, setSocketEventsBinded] = useState(false);
    const [publishAudio, setPublishAudio] = useState(true);
    const [teacherLayoutIndex, setTeacherLayoutIndex] = useState(4);
    const [studentLayoutIndex, setStudentLayoutIndex] = useState(0);
    const [mutedUsers, setMutedUsers] = useState([]);
    const [layoutFor, setLayoutFor] = useState("teacher");
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [drawData, setDrawData] = useState(null);
    const [game1Stats, setGame1Stats] = useState([]);
    const [game2Stats, setGame2Stats] = useState({});
    const [game3Stats, setGame3Stats] = useState({});
    const [gameTime, setGameTime] = useState(120);
    const [game3Maze, setGame3Maze] = useState(0);
    const [effects, setEffects] = useState("sounds");
    const [individualGraphic, setIndividualGraphic] = useState(false);
    const [graphic, setGraphic] = useState(null);
    const [graphicReceived, setGraphicReceived] = useState(false);
    const [stickerInfo, setStickerInfo] = useState({});
    const [show, setShow] = useState(false);
    const [activeNavButton, setActiveNavButton] = useState("intro");
    const [twilio, setTwilio] = useState(null);
    const [room, setRoom] = useState(null);
    const [participants, setParticipants] = useState([]);
    const layouts = [Layout1, Layout2, Layout3, Lesson1, Lesson2];
    // game controls
    const [gameStatus, setGameStatus] = useState("pause");
    const [game2Status, setGame2Status] = useState("");
    const [game3Status, setGame3Status] = useState("");
    const [gamesList, setGamesList] = useState([]);
    const [lessonList, setLessonList] = useState([]);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await axios
                .get(`/api/v1/session-state/${props.match.params.sessionId}`)
                .then((response) => {
                    if (!response.data.success) {
                        setMsg(response.data.message);
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    } else if (response.data) {
                        if (response.data.data) {
                            const { student_layout, teacher_layout } =
                                response.data.data;
                            if (student_layout) {
                                setStudentLayoutIndex(student_layout);
                            }
                            if (teacher_layout) {
                                setTeacherLayoutIndex(teacher_layout);
                                if (
                                    teacher_layout === 1 ||
                                    teacher_layout === 2 ||
                                    teacher_layout === 3
                                ) {
                                    setActiveNavButton("intro");
                                }
                                if (
                                    teacher_layout === 3 ||
                                    teacher_layout === 4
                                ) {
                                    setActiveNavButton("lessons");
                                }
                                if (
                                    teacher_layout === 5 ||
                                    teacher_layout === 7 ||
                                    teacher_layout === 8
                                ) {
                                    setActiveNavButton("games");
                                }
                            }
                        }
                        if (response.data.games) {
                            setGamesList(response.data.games);
                        }
                        if (response.data.lessons) {
                            setLessonList(response.data.lessons);
                        }
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

    // Setup socket
    useEffect(() => {
        const socket = socketIOClient(process.env.REACT_APP_BACKEND_URL, {
            query: {
                userId: JSON.parse(localStorage.getItem("auth")).user._id,
                sessionId: props.match.params.sessionId,
                userRole: JSON.parse(localStorage.getItem("auth")).user.role,
            },
            pingTimeout: 60000,
        });
        setSocket(socket);
        // eslint-disable-next-line
    }, []);

    //bind events to socket
    useEffect(() => {
        if (!socket) {
            return;
        }
        bindSocketEvents(socket);
        setSocketEventsBinded(true);
        // eslint-disable-next-line
    }, [socket, mutedUsers, selectedItem, studentLayoutIndex, sessionHelper]);

    // get opentok session info
    useEffect(() => {
        if (!socketEventsBinded) {
            return;
        }
        async function fetchData() {
            await axios
                .get("/api/v1/twilio/session/" + props.match.params.sessionId)
                .then((response) => {
                    if (!response.data.success) {
                        setMsg(response.data.message);
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                        history.push("/");
                    } else {
                        setTwilio(response.data.data);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        }
        fetchData();
        const currentRole = JSON.parse(localStorage.getItem("auth")).user.role;
        setRole(currentRole);
        // eslint-disable-next-line
    }, [socketEventsBinded]);

    useEffect(() => {
        if (!(twilio && socket)) {
            return;
        }

        if (!window.studentToColorMap) {
            window.studentToColorMap = {};
        }
        const participantConnected = (participant) => {
            setParticipants((prevParticipants) => [
                ...prevParticipants,
                participant,
            ]);
        };

        const participantDisconnected = (participant) => {
            setParticipants((prevParticipants) =>
                prevParticipants.filter((p) => p !== participant)
            );
        };
        Video.connect(twilio.token, {
            name: twilio.session.room_name_slug,
        }).then((room) => {
            setRoom(room);
            room.on("participantConnected", participantConnected);
            room.on("participantDisconnected", participantDisconnected);
            room.participants.forEach(participantConnected);
        });

        return () => {
            setRoom((currentRoom) => {
                if (
                    currentRoom &&
                    currentRoom.localParticipant.state === "connected"
                ) {
                    currentRoom.localParticipant.tracks.forEach(function (
                        trackPublication
                    ) {
                        trackPublication.track.stop();
                    });
                    currentRoom.disconnect();
                    return null;
                } else {
                    return currentRoom;
                }
            });
        };
        // eslint-disable-next-line
    }, [twilio, socket]);

    useEffect(() => {
        if (room && room.localParticipant) {
            if (publishAudio) {
                toggleMuteUser(false);
            } else {
                toggleMuteUser(true);
            }
        }
        // eslint-disable-next-line
    }, [publishAudio, room]);

    const toggleMuteUser = (shouldMuteUser) => {
        room.localParticipant.audioTracks.forEach(function (audioTrack) {
            if (shouldMuteUser) {
                audioTrack.track.disable();
            } else {
                audioTrack.track.enable();
            }
        });
    };

    const bindSocketEvents = (socket) => {
        socket.on("sessionState", (data) => {
            if (data.mazeIndex) {
                setGame3Maze(data.mazeIndex);
            }
            if (data.game3Stats) {
                setGame3Stats(data.game3Stats);
            }
            if (data.game2Stats) {
                setGame2Stats(data.game2Stats);
            }
            if (data.game1stats) {
                setGame1Stats(data.game1stats);
            }
            if (data.game1status) {
                setGameStatus(data.game1status);
            }
            if (data.game1Timer) {
                setGameTime(data.game1Timer);
            }
            if (data.draw_data) {
                setDrawData(data.draw_data);
            }
            const currentUserRole = JSON.parse(localStorage.getItem("auth"))
                .user.role;

            if (data && currentUserRole === "teacher") {
                if (data.muted_users) {
                    setMutedUsers(data.muted_users);
                }
                if (data.teacher_layout) {
                    setTeacherLayoutIndex(data.teacher_layout);
                }
                if (data.student_layout) {
                    setStudentLayoutIndex(data.student_layout);
                }
            } else if (data && currentUserRole === "student") {
                if (data) {
                    setPublishAudio(data.publish_audio);
                }
                if (data.student_layout) {
                    setStudentLayoutIndex(data.student_layout);
                }
                if (data.myMarkerColor) {
                    setMyMarkerColor(data.myMarkerColor);
                }
            }
            setInitialStateSet(true);
        });
        socket.on("sessionDrawData", (data) => {
            // console.log("receiveddata", data);
            if (data.draw_data) {
                setDrawData(data.draw_data);
            }
        });
        socket.on("updateLessonSlide", (newIndex) => {
            setSelectedItem(newIndex);
        });
        socket.on("muteUser", () => {
            setPublishAudio(false);
        });
        socket.on("unMuteUser", () => {
            setPublishAudio(true);
        });

        socket.on("studentLayoutChange", (newIndex) => {
            setStudentLayoutIndex(newIndex);
        });
        socket.on("teacherLayoutChange", (newIndex) => {
            setTeacherLayoutIndex(newIndex);
        });
        socket.on("endUserSession", () => {
            socket.disconnect();
            setSocket(null);
            history.push("/");
        });
        socket.on("playSound", (sound) => {
            let audio = new Audio(sound);
            audio.play();
        });
        socket.on("diplayIndividualGraphic", (graphic) => {
            setGraphic(graphic);
            setGraphicReceived(true);
            setTimeout(() => {
                setGraphicReceived(false);
            }, 5000);
        });
        socket.on("displayOnSubscriberScreens", (data) => {
            const { studentId, graphic } = data;
            setStickerInfo({
                ...stickerInfo,
                clickedUserId: studentId,
                graphic,
            });
            setTimeout(() => {
                setStickerInfo({});
            }, 5000);
        });
    };

    const muteUser = (userIds) => {
        socket.emit("message", {
            action: "muteUser",
            payload: {
                userIds,
            },
        });
        setMutedUsers(_.uniq(mutedUsers.concat(userIds)));
    };

    const endEverySession = async () => {
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        endUserSession(userIds);

        await axios
            .put(`/api/v1/session/${props.match.params.sessionId}/end`)
            .then((response) => {
                if (!response.data.success) {
                    setMsg(response.data.message);
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
                localStorage.removeItem("teacherState");
                history.push("/");
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const muteAllUsers = () => {
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        muteUser(userIds);
    };
    const unMuteAllUsers = (userid) => {
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        unMuteUser(userIds);
    };

    const changeStudentLayout = (layoutIndex) => {
        const studentIds = streams.map((obj) => {
            var nameObj = JSON.parse(obj.name);
            return nameObj._id;
        });
        socket.emit("studentLayoutChange", {
            action: "studentLayoutChange",
            payload: {
                userIds: studentIds,
                layoutIndex,
            },
        });
        setStudentLayoutIndex(layoutIndex);
    };

    const changeTeacherLayout = (layoutIndex) => {
        const currentUserId = JSON.parse(localStorage.getItem("auth")).user._id;
        socket.emit("teacherLayoutChange", {
            action: "teacherLayoutChange",
            payload: {
                userId: currentUserId,
                layoutIndex,
            },
        });

        setTeacherLayoutIndex(layoutIndex);
    };

    const unMuteUser = (userIds) => {
        socket.emit("message", {
            action: "unMuteUser",
            payload: {
                userIds,
            },
        });
        const updatedMutedUsers = mutedUsers.filter((userId) => {
            return userIds.indexOf(userId) === -1;
        });
        setMutedUsers(updatedMutedUsers);
    };

    const onPlaySound = (audio) => {
        let teacherAudio = new Audio(audio);
        teacherAudio.play();
        const studentIds = streams.map((obj) => {
            var nameObj = JSON.parse(obj.name);
            return nameObj._id;
        });
        socket.emit("playSound", { sound: audio, studentIds });
    };

    const endUserSession = (userIds) => {
        socket.emit("message", {
            action: "endUserSession",
            payload: {
                userIds,
            },
        });
    };

    const sendIndividualSticker = (studentId) => {
        if (!studentId) {
            return;
        }
        const studentIds = streams
            .filter((obj) => {
                var nameObj = JSON.parse(obj.name);
                return nameObj._id !== studentId;
            })
            .map((obj) => {
                const nameObj = JSON.parse(obj.name);
                return nameObj._id;
            });
        const currentUserId = JSON.parse(localStorage.getItem("auth")).user._id;

        socket.emit("diplayIndividualGraphic", {
            studentId,
            graphic,
            studentIds: studentIds.concat([currentUserId]),
        });
    };
    const sendStickerToAll = () => {
        setGraphicReceived(true);
        setTimeout(() => {
            setGraphicReceived(false);
        }, 5000);
        const studentIds = streams.map((obj) => {
            var nameObj = JSON.parse(obj.name);
            return nameObj._id;
        });
        socket.emit("sendStickerToAll", { studentIds, graphic });
    };

    const stickerReceivers = streams.map((obj) => {
        var nameObj = JSON.parse(obj.name);
        return {
            name: nameObj.name,
            id: nameObj._id,
        };
    });

    const stickerPopover = (
        <Popover id="popover-basic">
            {individualGraphic ? (
                <div
                    className="container-fluid"
                    style={{
                        marginTop: "10px",
                        overflow: "auto",
                    }}
                >
                    {stickerReceivers.length === 0 ? (
                        <div
                            onClick={() => setShow(false)}
                            style={{
                                width: "125px",
                                textAlign: "center",
                                paddingTop: "10px",
                                marginBottom: "5px",
                            }}
                            className={styles.individualGraphic}
                        >
                            No Student Connected
                        </div>
                    ) : (
                        _.chunk(stickerReceivers, 2).map((rowItem) => {
                            return (
                                <Row style={{ marginBottom: "10px" }}>
                                    {rowItem.map((item, key) => {
                                        return (
                                            <Col key={key} xs={6}>
                                                <div
                                                    className={
                                                        styles.individualGraphic
                                                    }
                                                    onClick={() => {
                                                        sendIndividualSticker(
                                                            item.id
                                                        );
                                                        setShow(false);
                                                    }}
                                                >
                                                    {item.name}
                                                </div>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            );
                        })
                    )}
                </div>
            ) : (
                <div>
                    <div
                        id={styles.bar}
                        style={{ height: "90px", margin: "3px" }}
                    ></div>
                    <button
                        onClick={() => {
                            sendStickerToAll();
                            setShow(false);
                        }}
                        style={{ width: "80%", marginTop: "5px" }}
                        className={styles.buttonInActive}
                    >
                        All
                    </button>
                    <button
                        onClick={() => setIndividualGraphic(true)}
                        style={{ width: "80%" }}
                        className={styles.buttonInActive}
                    >
                        Individual
                    </button>
                </div>
            )}
        </Popover>
    );

    const effectsPopover = (
        <Popover id="popover-basic" className={styles.effectsPopover}>
            <Popover.Content>
                <div id={styles.bar}></div>
                <div className={styles.effectsButton}>
                    <button
                        onClick={() => setEffects("sounds")}
                        style={{ marginRight: "5px" }}
                        className={
                            effects === "sounds"
                                ? styles.buttonActive
                                : styles.buttonInActive
                        }
                    >
                        Sounds
                    </button>
                    <button
                        onClick={() => setEffects("stickers")}
                        className={
                            effects === "stickers"
                                ? styles.buttonActive
                                : styles.buttonInActive
                        }
                    >
                        Stickers
                    </button>
                </div>
                <div style={{ overlay: "auto" }}>
                    {effects === "sounds" ? (
                        <Row>
                            {audioEffects.map((obj, index) => (
                                <Col
                                    key={index}
                                    className={styles.sounds}
                                    onClick={() => {
                                        setShow(false);
                                        onPlaySound(obj.audio);
                                    }}
                                    sm={5}
                                >
                                    {obj.name}
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Row>
                            {graphics.map((image, i) => (
                                <Col className={styles.effects} sm={5}>
                                    <OverlayTrigger
                                        key={i}
                                        rootClose
                                        trigger="click"
                                        placement="left"
                                        overlay={stickerPopover}
                                    >
                                        <img
                                            onClick={() => {
                                                setGraphic(image);
                                                setIndividualGraphic(false);
                                            }}
                                            src={image}
                                            alt="individualgraphic"
                                        />
                                    </OverlayTrigger>
                                </Col>
                            ))}
                        </Row>
                    )}
                </div>
            </Popover.Content>
        </Popover>
    );

    const popover = (
        <Popover id="popover-basic" className={styles.popoverBasic}>
            <Popover.Content>
                <div id={styles.popupDiv}>
                    <button
                        style={{ marginRight: "4px" }}
                        className={
                            layoutFor === "student"
                                ? styles.buttonActive
                                : styles.buttonInActive
                        }
                        onClick={() => setLayoutFor("student")}
                    >
                        Student
                    </button>
                    <button
                        className={
                            layoutFor === "teacher"
                                ? styles.buttonActive
                                : styles.buttonInActive
                        }
                        onClick={() => setLayoutFor("teacher")}
                    >
                        Teacher
                    </button>
                </div>
                <div className={styles.screens}>
                    <img
                        id={
                            (teacherLayoutIndex === 0 &&
                                layoutFor === "teacher") ||
                            (studentLayoutIndex === 0 &&
                                layoutFor === "student")
                                ? styles.selectedScreen
                                : null
                        }
                        src={Screen1}
                        alt="layout1"
                        onClick={
                            layoutFor === "teacher"
                                ? () => changeTeacherLayout(0)
                                : () => setShowModal1(true)
                        }
                    />
                    <img
                        id={
                            (teacherLayoutIndex === 1 &&
                                layoutFor === "teacher") ||
                            (studentLayoutIndex === 1 &&
                                layoutFor === "student")
                                ? styles.selectedScreen
                                : null
                        }
                        src={Screen3}
                        alt="layout3"
                        onClick={
                            layoutFor === "teacher"
                                ? () => changeTeacherLayout(1)
                                : () => setShowModal2(true)
                        }
                    />
                    <img
                        id={
                            (teacherLayoutIndex === 2 &&
                                layoutFor === "teacher") ||
                            (studentLayoutIndex === 2 &&
                                layoutFor === "student")
                                ? styles.selectedScreen
                                : null
                        }
                        src={Screen2}
                        alt="layout2"
                        onClick={
                            layoutFor === "teacher"
                                ? () => changeTeacherLayout(2)
                                : () => setShowModal3(true)
                        }
                    />
                </div>
                <Modal
                    show={showModal1}
                    handleClose={() => setShowModal1(!showModal1)}
                    body={
                        "Confirm you would like to change the student's view?"
                    }
                    confirm={() => {
                        changeStudentLayout(0);
                        setShowModal1(false);
                    }}
                />
                <Modal
                    show={showModal2}
                    handleClose={() => setShowModal2(!showModal2)}
                    body={
                        "Confirm you would like to change the student's view?"
                    }
                    confirm={() => {
                        changeStudentLayout(1);
                        setShowModal2(false);
                    }}
                />
                <Modal
                    show={showModal3}
                    handleClose={() => setShowModal3(!showModal3)}
                    body={
                        "Confirm you would like to change the student's view?"
                    }
                    confirm={() => {
                        changeStudentLayout(2);
                        setShowModal3(false);
                    }}
                />
            </Popover.Content>
        </Popover>
    );

    const currentRole = JSON.parse(localStorage.getItem("auth")).user.role;
    var CurrentLayout =
        currentRole === "teacher"
            ? layouts[teacherLayoutIndex]
            : layouts[studentLayoutIndex];

    return (
        <>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            {twilio && room ? (
                <>
                    {duplicateUser ? (
                        <h1>
                            You are already part of this session on some on
                            other browser window. Please continue from there.
                        </h1>
                    ) : (
                        <Container fluid id={styles.container}>
                            {graphicReceived && (
                                <div
                                    style={{
                                        position: "fixed",
                                        left: 0,
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: "flex",
                                        alignItems: "center",
                                        zIndex: 9,
                                        justifyContent: "center",
                                    }}
                                >
                                    <img src={graphic} alt="grpahic" />
                                </div>
                            )}
                            <Row id={styles.topRow}>
                                {initialStateSet && (
                                    <CurrentLayout
                                        stickerInfo={stickerInfo}
                                        setGame3Maze={setGame3Maze}
                                        game3Maze={game3Maze}
                                        game3Status={game3Status}
                                        setGame3Status={setGame3Status}
                                        game3Stats={game3Stats}
                                        game2Status={game2Status}
                                        gameTime={gameTime}
                                        setGameTime={setGameTime}
                                        setGame2Status={setGame2Status}
                                        game1Stats={game1Stats}
                                        game2Stats={game2Stats}
                                        gameStatus={gameStatus}
                                        setGameStatus={setGameStatus}
                                        drawData={drawData}
                                        myMarkerColor={myMarkerColor}
                                        socket={socket}
                                        selectedItem={selectedItem}
                                        updateSelectedItem={(index) => {
                                            const ids = streams.map((obj) => {
                                                var nameObj = JSON.parse(
                                                    obj.name
                                                );
                                                return nameObj._id;
                                            });
                                            socket.emit("updateLessonSlide", {
                                                payload: {
                                                    userIds: ids,
                                                    nextSlideIndex: index,
                                                },
                                            });

                                            setSelectedItem(index);
                                        }}
                                        mutedUsers={mutedUsers}
                                        muteUser={(id) => muteUser([id])}
                                        unMuteUser={(id) => unMuteUser([id])}
                                        endUserSession={(id) => {
                                            endUserSession([id]);
                                        }}
                                        participants={participants || []}
                                        room={room}
                                        streams={streams}
                                        sessionHelper={sessionHelper}
                                        role={role}
                                        publishAudio={publishAudio}
                                        endEverySession={endEverySession}
                                        muteAllUsers={muteAllUsers}
                                        unMuteAllUsers={unMuteAllUsers}
                                    />
                                )}
                                <Col
                                    sm={2}
                                    id={
                                        role === "teacher"
                                            ? styles.rightCol
                                            : styles.rightColStudent
                                    }
                                >
                                    <div id={styles.rightColHead}>
                                        <img
                                            src={Logo}
                                            id={styles.plassLogo}
                                            alt="logo"
                                        />
                                    </div>
                                    <div id={styles.timer}>
                                        <Clock />
                                    </div>
                                    {teacherLayoutIndex === 5 && (
                                        <div className={styles.gameControls}>
                                            {gameStatus !== "stop" &&
                                                gameStatus !== "play" && (
                                                    <img
                                                        src={Play}
                                                        onClick={() =>
                                                            setGameStatus(
                                                                "play"
                                                            )
                                                        }
                                                        alt="play"
                                                    />
                                                )}
                                            {gameStatus !== "stop" &&
                                                gameStatus === "play" && (
                                                    <img
                                                        src={Pause}
                                                        onClick={() =>
                                                            setGameStatus(
                                                                "pause"
                                                            )
                                                        }
                                                        alt="pause"
                                                    />
                                                )}
                                            {gameStatus !== "stop" && (
                                                <img
                                                    src={Stop}
                                                    onClick={() =>
                                                        setGameStatus("stop")
                                                    }
                                                    alt="stop"
                                                />
                                            )}
                                            <img
                                                src={Refresh}
                                                onClick={() =>
                                                    setGameStatus("refresh")
                                                }
                                                alt="refresh"
                                            />
                                        </div>
                                    )}

                                    {teacherLayoutIndex === 7 && (
                                        <div className={styles.gameControls}>
                                            <img
                                                src={Refresh}
                                                onClick={() =>
                                                    setGame2Status("refresh")
                                                }
                                                alt="refresh"
                                            />
                                        </div>
                                    )}

                                    {teacherLayoutIndex === 8 && (
                                        <div className={styles.mazeControls}>
                                            <div
                                                className={
                                                    game3Maze === 0
                                                        ? styles.mazeButtonsActive
                                                        : styles.mazeButtons
                                                }
                                                onClick={() => setGame3Maze(0)}
                                            >
                                                1
                                            </div>
                                            <div
                                                className={
                                                    game3Maze === 1
                                                        ? styles.mazeButtonsActive
                                                        : styles.mazeButtons
                                                }
                                                onClick={() => setGame3Maze(1)}
                                            >
                                                2
                                            </div>
                                            <div
                                                className={
                                                    game3Maze === 2
                                                        ? styles.mazeButtonsActive
                                                        : styles.mazeButtons
                                                }
                                                onClick={() => setGame3Maze(2)}
                                            >
                                                3
                                            </div>
                                        </div>
                                    )}
                                    {teacherLayoutIndex === 8 && (
                                        <div className={styles.gameControls}>
                                            <img
                                                style={{ marginTop: "12vh" }}
                                                src={Refresh}
                                                onClick={() =>
                                                    setGame3Status("refresh")
                                                }
                                                alt="refresh"
                                            />
                                        </div>
                                    )}

                                    <div id={styles.navigationContainer}>
                                        <NavigationBtn
                                            overlay={false}
                                            active={activeNavButton === "intro"}
                                            click={() => {
                                                setActiveNavButton("intro");
                                                changeTeacherLayout(0);
                                                changeStudentLayout(0);
                                            }}
                                            text={"Intro"}
                                        />
                                        {lessonList &&
                                            lessonList.length > 0 && (
                                                <NavigationBtn
                                                    active={
                                                        activeNavButton ===
                                                        "lessons"
                                                    }
                                                    text={"Lesson"}
                                                    overlay={true}
                                                    changeTeacherLayout={
                                                        changeTeacherLayout
                                                    }
                                                    changeStudentLayout={
                                                        changeStudentLayout
                                                    }
                                                    lessonList={lessonList}
                                                    setActiveNavButton={
                                                        setActiveNavButton
                                                    }
                                                    teacherLayoutIndex={
                                                        teacherLayoutIndex
                                                    }
                                                />
                                            )}
                                        {gamesList && gamesList.length > 0 && (
                                            <NavigationBtn
                                                text={"Games"}
                                                active={
                                                    activeNavButton === "games"
                                                }
                                                overlay={true}
                                                changeTeacherLayout={
                                                    changeTeacherLayout
                                                }
                                                changeStudentLayout={
                                                    changeStudentLayout
                                                }
                                                gamesList={gamesList}
                                                setActiveNavButton={
                                                    setActiveNavButton
                                                }
                                                teacherLayoutIndex={
                                                    teacherLayoutIndex
                                                }
                                            />
                                        )}
                                        <NavigationBtn
                                            active={
                                                activeNavButton === "review"
                                            }
                                            click={() => {
                                                setActiveNavButton("review");
                                                changeTeacherLayout(0);
                                                changeStudentLayout(0);
                                            }}
                                            text={"Review"}
                                            overlay={false}
                                        />
                                    </div>
                                    <Row id={styles.rightColIcons}>
                                        <Col
                                            sm={3}
                                            style={{
                                                paddingRight: "7.5px",
                                                paddingLeft: "7.5px",
                                            }}
                                        >
                                            <OverlayTrigger
                                                trigger="click"
                                                placement="top"
                                                overlay={effectsPopover}
                                                alt="screens"
                                                show={show}
                                            >
                                                <img
                                                    style={{
                                                        cursor: "pointer",
                                                        width: "60px",
                                                        height: "60px",
                                                    }}
                                                    src={Star}
                                                    alt="star"
                                                    onClick={() =>
                                                        setShow(!show)
                                                    }
                                                />
                                            </OverlayTrigger>
                                        </Col>
                                        <Col
                                            sm={3}
                                            style={{
                                                paddingRight: "7.5px",
                                                paddingLeft: "7.5px",
                                            }}
                                        >
                                            <OverlayTrigger
                                                trigger={
                                                    [0, 1, 2].includes(
                                                        teacherLayoutIndex
                                                    )
                                                        ? "click"
                                                        : null
                                                }
                                                placement="top"
                                                overlay={popover}
                                                alt="screens"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <img
                                                    style={{
                                                        cursor: "pointer",
                                                        width: "60px",
                                                        height: "60px",
                                                    }}
                                                    src={Screens}
                                                    alt="Screens"
                                                />
                                            </OverlayTrigger>
                                        </Col>
                                        <Col
                                            sm={3}
                                            style={{
                                                paddingRight: "7.5px",
                                                paddingLeft: "7.5px",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    cursor: "pointer",
                                                    width: "60px",
                                                    height: "60px",
                                                }}
                                                src={Inbox}
                                                alt="inbox"
                                            />
                                        </Col>
                                        <Col
                                            sm={3}
                                            style={{
                                                paddingLeft: "7.5px",
                                                paddingRight: "7.5px",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    cursor: "pointer",
                                                    width: "60px",
                                                    height: "60px",
                                                }}
                                                src={Camera}
                                                alt="camera"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    )}
                </>
            ) : null}
        </>
    );
};

export default withRouter(preloadScript(SessionHome));
