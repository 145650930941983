import React, { useState, useRef, useEffect } from "react";
import styles from "./personalDetails.module.css";
import { Form, Formik } from "formik";
import _ from "lodash";
import CustomDropdown from "../../CustomDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import personalDetails from "../../../assets/images/icon/accountSettings/personalDetails.svg";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import storeProfileImg from "../../../assets/images/icon/accountSettings/storeprofile (2).svg";
import check from "../../../assets/images/icon/accountSettings/check.svg";
import Snackbar from "../../../components/Snackbar";
import moment from "moment";

const initialValues = {
    name: "",
    preferedName: "",
    birthDate: "",
    contact: "",
    idType: "",
    idPicture: "",
    criminalDoc: "",
    country: "",
    state: "",
    idDocument: "",
    criminalCheckDocument: "",
};

export default function PersonalDetail({ changeFlag }) {
    const [teacherData, setTeacherData] = useState(initialValues);
    const inputFile = useRef(null);
    const inputFileProfile = useRef(null);
    const inputFileCriminalDoc = useRef(null);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackBarStatus, setSnackBarStatus] = useState("error");
    const [fileData, setFileData] = useState();
    const [criminalfileData, setCriminalFileData] = useState();
    const [type, setType] = useState();
    const [criminalDocType, setCriminalDocType] = useState();
    const [imageName, setImageName] = useState("");
    const [imageURL, setImageURL] = useState("");
    const [imageNameCriminalDoc, setImageNameCriminalDoc] = useState("");
    const [imageURLCriminalDoc, setImageURLCriminalDoc] = useState("");
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    // const [modalOpenCriminal, setModalOpenCriminal] = useState(false);
    const [showStore, setShowStore] = useState(false);
    // const [multipleProfilePics, setMultipleProfilePics] = useState([]);
    const [storeObject, setStoreObject] = useState({
        displayName: "",
        description: "",
        country: "",
        state: "",
    });
    const [previewImage, setPreviewImage] = useState("");
    const [profilePictures, setProfilePictures] = useState([]);
    const [birthdate, setBirthdate] = useState("");
    const [newProfilePics, setNewProfilePics] = useState([]);
    const [newPicURL, setNewPicURL] = useState([]);
    const [savedImagesIds, setSavedImagesIds] = useState([]);
    const [criminalCheck, setCriminalCheck] = useState(false);
    // const [region, setRegion] = useState("");
    const [displayNameNew, setDisplayNameNew] = useState("");
    const [usernameNew, setUsernameNew] = useState("");
    const [isUsernameAllowed, setIsUsernameAllowed] = useState(false);
    const [isUsernameLoading, setIsUsernameLoading] = useState(false);
    const [usernameChangeCount, setUsernameChangeCount] = useState("");
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [unsavedChangesStore, setUnsavedChangesStore] = useState(false);
    const [typeToPreview, setTypeToPreview] = useState("");

    useEffect(() => {
        getUserDetails();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (changeFlag == true) {
            document.getElementById("saveButton").click();
            updateStoreData();
        }
        // eslint-disable-next-line
    }, [changeFlag]);

    const changesPending = () => {
        setUnsavedChanges(true);
        localStorage.setItem("unSaveChanges", true);
    };

    const changesPendingStore = () => {
        setUnsavedChangesStore(true);
        localStorage.setItem("unSaveChanges", true);
    };

    const getUserDetails = async () => {
        await Axios.get("/api/v1/user")
            .then((result) => {
                setDisplayNameNew(result?.data?.user?.preferedName);
                setUsernameNew(result?.data?.user?.username);
                setUsernameChangeCount(result?.data?.user?.usernameChangeCount);
                setTeacherData({
                    ...result?.data?.user,
                    birthDate: new Date(result?.data?.user?.birthDate),
                });
                setImageURL(result?.data?.user?.image_ids[0]?.location);
                result?.data?.user?.image_ids.length > 0
                    ? setImageName("Id Image")
                    : setImageName("");
                setStoreObject({
                    displayName: result?.data?.user?.storeDisplayName,
                    description: result?.data?.user?.storeDescription,
                    country: result?.data?.user?.country,
                    state: result?.data?.user?.state,
                });
                setProfilePictures(result?.data?.user?.profilePictureIds);
                setCriminalFileData(
                    result?.data?.user?.criminalCheckReport?.criminalCheckDocuments.slice(
                        -1
                    )[0]?.documentId
                );
                setImageURLCriminalDoc(
                    result?.data?.user?.criminalCheckReport?.criminalCheckDocuments.slice(
                        -1
                    )[0]?.documentId?.location
                );
                setImageNameCriminalDoc("Criminal Document");
                setBirthdate(
                    moment
                        .utc(result?.data?.user?.birthDate)
                        .format("YYYY-MM-DD")
                );
                setSavedImagesIds(
                    result?.data?.user?.profilePictureIds.map((items) => {
                        return items?._id;
                    })
                );
                setCriminalCheck(result?.data?.criminalCheckReport?.isVerified);
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setSnackBarStatus("error");
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const updateUserData = (value) => {
        let data = new FormData();
        // data.append("image", fileData)

        data.append("name", value.name);
        data.append("preferedName", value.preferedName);
        data.append("birthDate", value.birthDate);
        data.append("contact", value.contact);
        data.append("id", value.id);
        // data.append("country", value.country ? value.country : "");
        // data.append("state", value.state ? value.state : "");
        fileData && data.append("idDocument", fileData);
        criminalfileData &&
            data.append("criminalCheckDocument", criminalfileData);
        //user/save-teacher-store-profile
        const storeData = {
            displayName: value.storeDisplayName,
            description: value.storeDescription,
        };
        Axios.post("/api/v1/user/save-teacher-personal-details", data)
            .then((res) => {
                setMsg(res.data.message);
                setSnackBarStatus("information");
                setShowSnackbar(true);
                setTimeout(() => {
                    setMsg(null);
                }, 3000);
                getUserDetails();
                setUnsavedChanges(false);
                localStorage.setItem("unSaveChanges", false);
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const updateStoreData = async () => {
        if (isUsernameLoading) {
            setMsg("Please wait while we validate your username.");
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        } else if (!isUsernameAllowed) {
            setMsg("Please a valid username.");
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        } else {
            let data = new FormData();
            data.append("displayName", displayNameNew ? displayNameNew : "");
            data.append("username", usernameNew ? usernameNew : "");
            data.append(
                "description",
                storeObject.description ? storeObject.description : ""
            );
            data.append(
                "country",
                storeObject?.country ? storeObject?.country : ""
            );
            data.append("state", storeObject?.state ? storeObject?.state : "");
            if (newProfilePics.length > 0) {
                for (let i = 0; i <= newProfilePics.length - 1; i++) {
                    await data.append("profilePictures", newProfilePics[i]);
                }
            }
            if (savedImagesIds.length > 0) {
                for (let j = 0; j <= savedImagesIds.length - 1; j++) {
                    await data.append("savedImagesIds[]", savedImagesIds[j]);
                }
            }
            Axios.post("/api/v1/user/save-teacher-store-profile", data)
                .then((res) => {
                    setMsg(res.data.message);
                    setSnackBarStatus("information");
                    setShowSnackbar(true);
                    setTimeout(() => {
                        setMsg(null);
                    }, 3000);
                    getUserDetails();
                    setNewProfilePics([]);
                    setNewPicURL([]);
                    setUnsavedChangesStore(false);
                    localStorage.setItem("unSaveChanges", false);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                });
        }
    };

    const handleKeyPress = (evt) => {
        if (evt.key === "Enter") {
            // login(evt);
        }
    };

    useEffect(() => {
        if (usernameNew) {
            setIsUsernameLoading(true);
            const delayDebounceFunction = setTimeout(() => {
                checkNewUsername();
            }, 1000);

            return () => clearTimeout(delayDebounceFunction);
        }

        // eslint-disable-next-line
    }, [usernameNew]);

    const checkNewUsername = () => {
        if (usernameNew.length < 6) {
            setIsUsernameAllowed(false);
            setMsg("Username should be at least 6 characters long.");
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        } else if (usernameNew.length > 25) {
            setIsUsernameAllowed(false);
            setMsg("Username should be at most 25 characters long.");
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        } else {
            Axios.post("/api/v1/user/check-username", {
                username: usernameNew,
            })
                .then((res) => {
                    if (_.get(res, "data.success")) {
                        setIsUsernameAllowed(
                            !Boolean(_.get(res, "data.data.exists"))
                        );
                    } else {
                        setIsUsernameAllowed(false);
                    }
                })
                .catch((error) => {
                    setIsUsernameAllowed(false);
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                })
                .finally(() => {
                    setIsUsernameLoading(false);
                });
        }
    };

    const handleChangeUserName = (e) => {
        let newValue = e.target.value;
        const lastCharacter = newValue.slice(-1);

        if (lastCharacter === " ") {
            newValue = newValue.slice(0, -1) + "-";
        }

        setUsernameNew(newValue);
    };

    const IdData = [
        { label: "Passport", value: "Passport" },
        { label: "Driving license", value: "Driving license" },
        {
            label: "Biometric residence permit",
            value: "Biometric residence permit",
        },
    ];

    const handleUploadFile = (name) => {
        if (name === "profile") {
            inputFileProfile.current.click();
        } else if (name === "criminalDoc") {
            inputFileCriminalDoc.current.click();
        } else if (name === "idPicture") {
            inputFile.current.click();
        }
    };

    const handleUploadImage = async (fileData, type, name) => {
        if (fileData) {
            if (name === "idPicture") {
                const url = URL.createObjectURL(fileData);
                setFileData(fileData);
                setType(type);
                setImageName(fileData.name);
                setImageURL(url);
            } else if (name === "criminalDoc") {
                const url = URL.createObjectURL(fileData);
                setCriminalDocType(type);
                setCriminalFileData(fileData);
                setImageNameCriminalDoc(fileData.name);
                setImageURLCriminalDoc(url);
            } else if (name === "profilePics") {
                if (
                    type === "VIDEO" &&
                    profilePictures.filter((data) => data.type == "VIDEO")
                        .length +
                        newProfilePics.filter(
                            (data) => data.type == "video/mp4"
                        ).length <
                        1
                ) {
                    const url = URL.createObjectURL(fileData);
                    setNewProfilePics([...newProfilePics, fileData]);
                    setNewPicURL([...newPicURL, url]);
                    changesPendingStore();
                } else if (type !== "VIDEO") {
                    const url = URL.createObjectURL(fileData);
                    setNewProfilePics([...newProfilePics, fileData]);
                    setNewPicURL([...newPicURL, url]);
                    changesPendingStore();
                } else {
                    setMsg("You can upload up to 3 image and 1 video only.");
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
            }
        } else {
            // setError("Please upload atleast 1 image before proceeding.");
        }
    };

    const fileSelected = (event) => {
        if (event?.target?.files?.length > 0) {
            const extension = event?.target?.files[0]?.type
                .split("/")[1]
                .toUpperCase();
            const type = event.target.files[0].type.split("/")[0].toUpperCase();
            if (
                (type === "IMAGE" ||
                    type === "APPLICATION" ||
                    type === "VIDEO") &&
                (extension === "PNG" ||
                    extension === "JPEG" ||
                    extension === "JPG" ||
                    extension === "MP4" ||
                    extension === "PDF")
            ) {
                const file_size = event.target.files[0].size;

                if (file_size / 1000000 > 5) {
                    setMsg("Please select file lesser than 5 MB");
                    setShowSnackbar(true);
                    setSnackBarStatus("error");
                    setTimeout(function () {
                        setMsg(null);
                        setSnackBarStatus("");
                        setShowSnackbar(false);
                    }, 5000);
                } else {
                    handleUploadImage(
                        event.target.files[0],
                        type,
                        event.target.id
                    );
                    changesPending();
                }
            } else {
                setMsg("Invalid file type");
                setSnackBarStatus("error");
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                    setSnackBarStatus("");
                    setShowSnackbar(false);
                }, 3000);
            }
        }
    };

    const deleteFile = (name, event) => {
        if (name === "idPicture") {
            setFileData();
            setType();
            setImageName();
            changesPending();
        } else if (name === "criminalPicture") {
            setCriminalFileData();
            setCriminalDocType();
            setImageNameCriminalDoc();
            changesPending();
        } else if (name === "profilePicture") {
            let profilePicturesCopy = [...profilePictures];
            profilePicturesCopy = profilePicturesCopy.filter((profile) => {
                return profile?._id !== event.target.id;
            });
            setProfilePictures(profilePicturesCopy);
            setSavedImagesIds(
                profilePicturesCopy.map((items) => {
                    return items?._id;
                })
            );
            changesPendingStore();
        } else if ((name = "newPics")) {
            let profilePicturesCopy = [...newProfilePics];
            profilePicturesCopy.splice(event.target.id, 1);
            setNewProfilePics(profilePicturesCopy);

            let profilePicturesURL = [...newPicURL];
            profilePicturesURL.splice(event.target.id, 1);
            setNewPicURL(profilePicturesURL);
            changesPendingStore();
        }
    };
    return (
        <React.Fragment>
            {msg && (
                <Snackbar
                    status={snackBarStatus}
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <div style={{ height: "100%" }}>
                <div className={`student-profile ${styles.studentScroll}`}>
                    {isPreviewModalOpen ? (
                        <div className={styles.uploadModal}>
                            <div className={styles.uploadModalContainer}>
                                <FontAwesomeIcon
                                    className={styles.crossIcon}
                                    icon={faTimes}
                                    onClick={() => setIsPreviewModalOpen(false)}
                                />
                                <div className={styles.previewImage__container}>
                                    {/* <pre>
                                        {previewImage
                                            .split(/[#?]/)[0]
                                            .split(".")
                                            .pop()
                                            .trim()}
                                    </pre>
                                    <pre>{JSON.stringify(previewImage)}</pre> */}
                                    {previewImage
                                        .split(/[#?]/)[0]
                                        .split(".")
                                        .pop()
                                        .trim() === "mp4" ||
                                    typeToPreview === "video/mp4" ? (
                                        <video
                                            width="320"
                                            height="240"
                                            controls
                                        >
                                            <source
                                                src={previewImage}
                                                type="video/ogg"
                                            />
                                            Your browser does not support the
                                            video tag.
                                        </video>
                                    ) : (
                                        <img
                                            src={previewImage}
                                            alt=""
                                            width="320"
                                            height="240"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <div
                        className={`container ${styles.detailsContainer} `}
                        hidden={showStore}
                    >
                        <Formik enableReinitialize initialValues={teacherData}>
                            {({
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                values,
                                setFieldValue,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="personal-detail">
                                                <h3>Your details</h3>
                                                <div className="personal-detail-inner">
                                                    <div className="input-item">
                                                        <input
                                                            className={`${styles.inputBox} ${styles.middleInput}`}
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            value={values.name}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                changesPending();
                                                            }}
                                                            placeholder="Full name"
                                                        />
                                                    </div>
                                                    <div className="input-item">
                                                        <input
                                                            className={`${styles.inputBox} ${styles.middleInput}`}
                                                            type="text"
                                                            id="preferedName"
                                                            name="preferedName"
                                                            value={
                                                                values.preferedName
                                                            }
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                changesPending();
                                                            }}
                                                            placeholder="Prefered name"
                                                            // onKeyPress={handleKeyPress}
                                                        />
                                                    </div>
                                                    <div className="input-item">
                                                        <input
                                                            max={moment
                                                                .utc(new Date())
                                                                .format(
                                                                    "YYYY-MM-DD"
                                                                )}
                                                            id="birthDate"
                                                            type="date"
                                                            className={`${styles.inputBox} ${styles.middleInput}`}
                                                            name="birth date"
                                                            value={moment
                                                                .utc(
                                                                    values?.birthDate
                                                                )
                                                                .format(
                                                                    "YYYY-MM-DD"
                                                                )}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    "birthDate",
                                                                    e.target
                                                                        .value
                                                                );
                                                                changesPending();
                                                            }}
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="personal-detail">
                                                <h3>Emergency contact</h3>
                                                <div className="personal-detail-inner">
                                                    <div className="input-item">
                                                        <input
                                                            required
                                                            className={`${styles.inputBox} ${styles.middleInput}`}
                                                            type="text"
                                                            id="contact"
                                                            name="contact"
                                                            value={
                                                                values.contact
                                                            }
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                changesPending();
                                                            }}
                                                            onKeyPress={
                                                                handleKeyPress
                                                            }
                                                        />
                                                    </div>
                                                    {/* <div>
                                                <h3 className={styles.title}>
                                                    Location
                                                </h3>
                                                <div
                                                    style={{
                                                        width: "81%",
                                                        marginTop: "2%",
                                                        position: "relative",
                                                    }}
                                                >
                                                    <CountryDropdown
                                                        required
                                                        id="country"
                                                        name="country"
                                                        valueType="full"
                                                        value={values.country}
                                                        onChange={(val) =>
                                                            setFieldValue(
                                                                "country",
                                                                val
                                                            )
                                                        }
                                                        classes={`${styles.inputBox} ${styles.middleInput}`}
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                    <RegionDropdown
                                                        countryValueType="full"
                                                        valueType="full"
                                                        defaultOptionLabel="Select State"
                                                        country={
                                                            values?.country
                                                        }
                                                        value={values?.state}
                                                        onChange={(val) =>
                                                            setFieldValue(
                                                                "state",
                                                                val
                                                            )
                                                        }
                                                        classes={`${styles.countryBox} ${styles.inputBox} ${styles.middleInput}`}
                                                    />
                                                </div>
                                            </div> */}
                                                </div>
                                            </div>

                                            <div
                                                className={styles.buttonWrapper}
                                            >
                                                <span
                                                    id="saveButton"
                                                    style={{
                                                        color: unsavedChanges
                                                            ? "#E36B2D"
                                                            : "#88addb",
                                                        cursor: "pointer",
                                                        fontFamily:
                                                            "ComfortaBold",
                                                        fontSize: "19px",
                                                    }}
                                                    onClick={(value) => {
                                                        updateUserData(values);
                                                    }}
                                                >
                                                    Save changes
                                                </span>
                                                <img
                                                    src={storeProfileImg}
                                                    onClick={() => {
                                                        setShowStore(true);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="personal-detail">
                                                <h3>Update ID picture</h3>

                                                <CustomDropdown
                                                    name="id"
                                                    customDropdownStyle={{
                                                        border: "1px solid rgba(0, 0, 0, 0.125)",
                                                        position: "absolute",
                                                        top: "-250px",
                                                    }}
                                                    dropdownData={IdData}
                                                    data={values.id}
                                                    setData={(val) => {
                                                        setFieldValue(
                                                            "id",
                                                            val
                                                        );
                                                        changesPending();
                                                    }}
                                                    width="100%"
                                                />
                                            </div>
                                            <div>
                                                <div>
                                                    <div
                                                        className={
                                                            styles.fileupload
                                                        }
                                                        onClick={() => {
                                                            handleUploadFile(
                                                                "idPicture"
                                                            );
                                                        }}
                                                    >
                                                        <p
                                                            className={
                                                                styles.toolText
                                                            }
                                                        >
                                                            Choose File
                                                        </p>
                                                        <FontAwesomeIcon
                                                            className={
                                                                styles.uploadIcon
                                                            }
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                                padding: "4px",
                                                            }}
                                                            icon={faPlus}
                                                        />
                                                    </div>
                                                    <input
                                                        type="file"
                                                        id="idPicture"
                                                        onChange={fileSelected}
                                                        ref={inputFile}
                                                        className={
                                                            styles.fileInput
                                                        }
                                                        accept="image/*, .pdf"
                                                    />
                                                </div>
                                            </div>
                                            <p className={styles.detailText}>
                                                PNG, JPEG, JPG, PDF files only
                                            </p>
                                            {imageName ? (
                                                <p
                                                    className={
                                                        styles.imageName__container
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.imageName_sub_container
                                                        }
                                                    >
                                                        {imageName}
                                                    </div>

                                                    <div
                                                        className={
                                                            styles.imageName_buttons_container
                                                        }
                                                    >
                                                        <span
                                                            onClick={() => {
                                                                setIsPreviewModalOpen(
                                                                    true
                                                                );
                                                                setPreviewImage(
                                                                    imageURL
                                                                );
                                                            }}
                                                        >
                                                            Preview
                                                        </span>
                                                        <span
                                                            onClick={deleteFile.bind(
                                                                this,
                                                                "idPicture"
                                                            )}
                                                        >
                                                            Delete
                                                        </span>
                                                    </div>
                                                </p>
                                            ) : (
                                                <p
                                                    style={{
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    No files uploaded
                                                </p>
                                            )}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="personal-detail">
                                                <h3
                                                    className={styles.title}
                                                    style={{
                                                        marginTop: "38px",
                                                    }}
                                                >
                                                    Latest criminal check
                                                    document
                                                </h3>
                                                <div>
                                                    <div
                                                        className={
                                                            styles.fileupload
                                                        }
                                                        onClick={() => {
                                                            handleUploadFile(
                                                                "criminalDoc"
                                                            );
                                                        }}
                                                    >
                                                        <p
                                                            className={
                                                                styles.toolText
                                                            }
                                                        >
                                                            Choose File
                                                        </p>
                                                        <FontAwesomeIcon
                                                            className={
                                                                styles.uploadIcon
                                                            }
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                                padding: "4px",
                                                            }}
                                                            icon={faPlus}
                                                        />
                                                    </div>
                                                    <input
                                                        type="file"
                                                        id="criminalDoc"
                                                        onChange={fileSelected}
                                                        ref={
                                                            inputFileCriminalDoc
                                                        }
                                                        className={
                                                            styles.fileInput
                                                        }
                                                        accept="image/*, .pdf"
                                                    />
                                                </div>
                                                <p
                                                    className={
                                                        styles.detailText
                                                    }
                                                >
                                                    PNG, JPEG, JPG, PDF files
                                                    only
                                                </p>
                                                {criminalfileData ? (
                                                    <p
                                                        className={
                                                            styles.imageName__container
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.imageName_sub_container
                                                            }
                                                        >
                                                            {
                                                                imageNameCriminalDoc
                                                            }
                                                        </div>

                                                        <div
                                                            className={
                                                                styles.imageName_buttons_container
                                                            }
                                                        >
                                                            <span
                                                                onClick={() => {
                                                                    setIsPreviewModalOpen(
                                                                        true
                                                                    );
                                                                    setPreviewImage(
                                                                        imageURLCriminalDoc
                                                                    );
                                                                }}
                                                            >
                                                                Preview
                                                            </span>
                                                            <span
                                                                onClick={deleteFile.bind(
                                                                    this,
                                                                    "criminalPicture"
                                                                )}
                                                            >
                                                                Delete
                                                            </span>
                                                        </div>
                                                    </p>
                                                ) : (
                                                    <p
                                                        style={{
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        No files uploaded
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div
                        className={`container ${styles.detailsContainer} `}
                        hidden={!showStore}
                    >
                        <div className="d-flex justify-content-between">
                            <div>
                                <h3 style={{ color: "#88ADDB" }}>
                                    Store profile
                                </h3>
                                <p style={{ fontSize: "14px" }}>
                                    Only this information will be published on
                                    the Plassroom store page, under Teacher
                                    Profile.
                                </p>
                            </div>
                            <div>
                                <span
                                    style={{
                                        color: unsavedChangesStore
                                            ? "#E36B2D"
                                            : "#88addb",
                                        cursor: "pointer",
                                        fontFamily: "ComfortaBold",
                                        fontSize: "19px",
                                    }}
                                    onClick={() => {
                                        updateStoreData();
                                    }}
                                >
                                    Save changes
                                </span>
                                <img
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: "2rem",
                                    }}
                                    src={personalDetails}
                                    onClick={() => {
                                        showStore
                                            ? setShowStore(false)
                                            : setShowStore(true);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <h3 className={styles.title}>Display name</h3>
                                <input
                                    className={`${styles.inputBox} ${styles.middleInput}`}
                                    type="text"
                                    id="displayName"
                                    name="displayName"
                                    value={displayNameNew}
                                    onChange={(event) => {
                                        setDisplayNameNew(event.target.value);
                                        changesPendingStore();
                                    }}
                                    // onKeyPress={handleKeyPress}
                                />
                                <h3 className={styles.title}>Username</h3>
                                <input
                                    className={`${styles.inputBox} ${styles.middleInput}`}
                                    type="text"
                                    id="userName"
                                    name="userName"
                                    value={usernameNew}
                                    disabled={Boolean(usernameChangeCount > 0)}
                                    onChange={(event) => {
                                        handleChangeUserName(event);
                                        changesPendingStore();
                                    }}
                                    // onKeyPress={handleKeyPress}
                                />
                                <p className={styles.detailText}>
                                    {isUsernameAllowed
                                        ? "Username allowed."
                                        : "Please choose another username."}
                                </p>
                                <h3 className={styles.title}>
                                    Introduce yourself
                                </h3>
                                <textarea
                                    placeholder="Max. 500 characters (in English)"
                                    className={`${styles.introduceBox} ${styles.middleInput}`}
                                    type="text"
                                    id="introduce"
                                    name="introduce"
                                    rows="8"
                                    value={storeObject.description}
                                    onChange={(event) => {
                                        changesPendingStore();
                                        setStoreObject({
                                            ...storeObject,
                                            description: event.target.value,
                                        });
                                    }}
                                    // onChange={handleChange}
                                    // onKeyPress={handleKeyPress}
                                />
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <h3 className={styles.title}>Location</h3>
                                    <div
                                        style={{
                                            width: "81%",
                                            marginTop: "2%",
                                            position: "relative",
                                            display: "flex",
                                        }}
                                    >
                                        <CountryDropdown
                                            required
                                            id="country"
                                            name="country"
                                            valueType="full"
                                            defaultOptionLabel="Country"
                                            value={storeObject?.country}
                                            // value={values.country}
                                            onChange={(val) => {
                                                changesPendingStore();
                                                setStoreObject({
                                                    ...storeObject,
                                                    country: val,
                                                });
                                            }}
                                            classes={`${styles.countryBox} ${styles.inputBox} ${styles.middleInput} ${styles.arrows}`}
                                            style={{
                                                width: "50%",
                                            }}
                                        />
                                        <RegionDropdown
                                            required
                                            countryValueType="full"
                                            valueType="full"
                                            defaultOptionLabel="State"
                                            blankOptionLabel="State"
                                            style={{
                                                width: "50%",
                                                marginLeft: "1rem",
                                            }}
                                            country={storeObject?.country}
                                            value={storeObject?.state}
                                            onChange={(val) => {
                                                changesPendingStore();
                                                setStoreObject({
                                                    ...storeObject,
                                                    state: val,
                                                });
                                            }}
                                            classes={`${styles.countryBox} ${styles.inputBox} ${styles.middleInput} ${styles.arrows}`}
                                        />
                                    </div>
                                </div>
                                <h3 className={styles.title}>
                                    Upload profile picture{" "}
                                    <span style={{ fontSize: "15px" }}>
                                        {" "}
                                        Up to 3 pictures & 1 video
                                    </span>
                                </h3>
                                <div style={{ width: "81%", marginTop: "2%" }}>
                                    <div>
                                        <div
                                            style={{
                                                backgroundColor:
                                                    profilePictures.length +
                                                        newProfilePics.length ==
                                                    4
                                                        ? "#f3f3f3"
                                                        : "#fff",
                                            }}
                                            className={styles.fileupload}
                                            onClick={() =>
                                                handleUploadFile("profile")
                                            }
                                        >
                                            <p className={styles.toolText}>
                                                Choose File
                                            </p>
                                            <FontAwesomeIcon
                                                className={styles.uploadIcon}
                                                style={{
                                                    width: "25px",
                                                }}
                                                icon={faPlus}
                                            />
                                        </div>
                                        <input
                                            disabled={
                                                profilePictures.length +
                                                    newProfilePics.length ==
                                                4
                                                    ? true
                                                    : false
                                            }
                                            type="file"
                                            id="profilePics"
                                            onChange={fileSelected}
                                            ref={inputFileProfile}
                                            className={styles.fileInput}
                                            accept="image/*, .mp4"
                                            data-max-size="5000"
                                        />
                                        <p className={styles.detailText}>
                                            PNG, JPEG, JPG, MP4 files only
                                        </p>
                                        {profilePictures &&
                                            profilePictures.map(
                                                (data, index) => {
                                                    return (
                                                        <p
                                                            key={index}
                                                            className={
                                                                styles.imageName__container
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.imageName_sub_container
                                                                }
                                                            >
                                                                {`Profile ${data?.type.toLowerCase()} ${
                                                                    index + 1
                                                                }`}
                                                            </div>

                                                            <div
                                                                className={
                                                                    styles.imageName_buttons_container
                                                                }
                                                            >
                                                                <span
                                                                    onClick={() => {
                                                                        setIsPreviewModalOpen(
                                                                            true
                                                                        );
                                                                        setPreviewImage(
                                                                            data?.location
                                                                        );
                                                                        setTypeToPreview(
                                                                            data.type
                                                                        );
                                                                    }}
                                                                >
                                                                    Preview
                                                                </span>
                                                                <span
                                                                    id={
                                                                        data?._id
                                                                    }
                                                                    onClick={deleteFile.bind(
                                                                        this,
                                                                        "profilePicture"
                                                                    )}
                                                                >
                                                                    Delete
                                                                </span>
                                                            </div>
                                                        </p>
                                                    );
                                                }
                                            )}
                                        {newProfilePics &&
                                            newProfilePics.map(
                                                (data, index) => {
                                                    return (
                                                        <p
                                                            key={index}
                                                            className={
                                                                styles.imageName__container
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.imageName_sub_container
                                                                }
                                                            >
                                                                {data?.name}
                                                            </div>

                                                            <div
                                                                className={
                                                                    styles.imageName_buttons_container
                                                                }
                                                            >
                                                                <span
                                                                    onClick={() => {
                                                                        setIsPreviewModalOpen(
                                                                            true
                                                                        );
                                                                        setPreviewImage(
                                                                            newPicURL[
                                                                                index
                                                                            ]
                                                                        );
                                                                        setTypeToPreview(
                                                                            data.type
                                                                        );
                                                                    }}
                                                                >
                                                                    Preview
                                                                </span>
                                                                <span
                                                                    id={index}
                                                                    onClick={deleteFile.bind(
                                                                        this,
                                                                        "newPics"
                                                                    )}
                                                                >
                                                                    Delete
                                                                </span>
                                                            </div>
                                                        </p>
                                                    );
                                                }
                                            )}
                                        <h3
                                            className={styles.title}
                                            style={{
                                                marginBottom: "10px",
                                                marginTop: "45px",
                                            }}
                                        >
                                            Latest criminal check
                                            <span hidden={!criminalCheck}>
                                                <img
                                                    src={check}
                                                    style={{ marginLeft: "5%" }}
                                                />
                                            </span>
                                        </h3>
                                        <p className={styles.detailText}>
                                            Criminal check document needs to be
                                            maximum 3 months ago. Upon upload,
                                            Plassroom will verify this document.
                                        </p>
                                        <h3
                                            className={styles.title}
                                            style={{
                                                color: "#88ADDB",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                showStore
                                                    ? setShowStore(false)
                                                    : setShowStore(true);
                                            }}
                                        >
                                            Upload latest
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
