import React from "react";
import styles from "./index.module.scss";

const InstagramCarousel = () => {
    return (
        <div className={styles.dataContainer}>
            {/* <div className={styles.rowHeader}>
                Follow us on our social media
            </div> */}

            <iframe
                src="https://snapwidget.com/embed/977760"
                className={styles.snapper}
                allowtransparency="true"
                frameBorder="0"
                scrolling="no"
                title="Snap widget"
            />
        </div>
    );
};

export default InstagramCarousel;
