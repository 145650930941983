import React from "react";
import Participant from "../../../../../../components/Participant";
import TwilioParticipant from "../../../../../../components/TwilioParticipant";
import styles from "./layout.module.css";

export default ({ participants, room, publishAudio }) => {
    var Teacher = null;
    var Students = [];

    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;

    if (currentUserRole === "teacher") {
        Teacher = (
            <TwilioParticipant
                key={room.localParticipant.sid}
                participant={room.localParticipant}
                role={"teacher"}
            />
        );
    } else {
        var teacherParticipant = participants.find((obj) => {
            var identityObj = JSON.parse(obj.identity);
            return identityObj.role === "teacher";
        });
        if (teacherParticipant) {
            Teacher = (
                <TwilioParticipant
                    role="teacher"
                    key={teacherParticipant.sid}
                    participant={teacherParticipant}
                />
            );
        }
        if (room) {
            Students = [
                <TwilioParticipant
                    role="student"
                    key={room.localParticipant.sid}
                    participant={room.localParticipant}
                    publishAudio={publishAudio}
                    screen="student"
                    hideVideo={true}
                />,
            ].concat(
                participants
                    .filter((obj) => {
                        var identityObj = JSON.parse(obj.identity);
                        return identityObj.role !== "teacher";
                    })
                    .map((participant) => (
                        <TwilioParticipant
                            role="student"
                            key={participant.sid}
                            participant={participant}
                            publishAudio={publishAudio}
                            screen="student"
                            hideVideo={true}
                        />
                    ))
            );
        }
    }

    let noOfParticipants = participants.length;
    let participantBoxes = [];

    for (let i = 0; i < noOfParticipants; i++) {
        participantBoxes.push(Students[i]);
    }

    return (
        <>
            <div className={styles.block}>
                <Participant>{Teacher}</Participant>

                {participantBoxes}
            </div>
        </>
    );
};
