import ChatIcon from "../../../../assets/images/PlayPage/MessageIcon.svg";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useHistory } from "react-router-dom";

const Recipient = ({ recipient, handleSelectedUser, selectedUser }) => {
    const profilePictureIds = _.get(recipient, "profilePictureIds");
    const photo = _.find(profilePictureIds, ["type", "IMAGE"]);
    let recipientURL;

    if (photo) {
        recipientURL = _.get(photo, "location");
    }

    return (
        <div
            className={`${styles.recipientContainer} ${
                _.get(selectedUser, "_id") === _.get(recipient, "_id")
                    ? styles.selectedUser
                    : null
            }`}
            onClick={() => handleSelectedUser(recipient)}
        >
            <div className={styles.recipientProfilePhotoContainer}>
                {recipientURL ? (
                    <img
                        src={recipientURL}
                        alt=""
                        className={styles.recipientProfilePhoto}
                    />
                ) : (
                    <span className={styles.recipientProfilePhoto}>
                        {/* TODO: Look for it */}
                        {_.get(recipient, "name", "U")?.trim()[0].toUpperCase()}
                    </span>
                )}
            </div>

            <div className={styles.recipientProfileNameContainer}>
                {/* TODO: Look for it */}
                {_.get(recipient, "name", "U")?.trim()}
            </div>

            {_.get(recipient, "NoOfUnreadMessages") ? (
                <div className={styles.unreadMessageCount}>
                    {_.get(recipient, "NoOfUnreadMessages")}
                </div>
            ) : null}
        </div>
    );
};

const RecipientsList = ({
    usersList,
    handleSelectedUser,
    selectedUser,
    setSearchedUser,
    loadingFetchUsers,
}) => {
    const history = useHistory();

    const [userSearchedInput, setUserSearchedInput] = useState("");

    /**
     * @description Trigger API to search after 1 second of searching.
     */
    useEffect(() => {
        const delayDebounceFunction = setTimeout(() => {
            setSearchedUser(userSearchedInput);
        }, 1000);

        return () => clearTimeout(delayDebounceFunction);
        // eslint-disable-next-line
    }, [userSearchedInput]);

    /**
     * @description Handle the words typed in search bar.
     */
    const handleChangeSearchedTerm = (event) => {
        setUserSearchedInput(_.get(event, "target.value"));
    };

    return (
        <div className={styles.recipientsContainer}>
            <img
                src={ChatIcon}
                alt="Chat Icon"
                className={styles.chatIcon}
                onClick={() => {
                    history.goBack();
                }}
            />

            <div className={styles.recipientsHeaderContainer}>
                <div className={styles.recipientsHeader}>Chat</div>

                <div className={styles.recipientsSearch}>
                    <input
                        type="text"
                        name="search"
                        id="search"
                        placeholder="Search"
                        value={userSearchedInput}
                        onChange={handleChangeSearchedTerm}
                    />

                    {loadingFetchUsers ? (
                        <CircularProgress size={20} className={styles.loader} />
                    ) : (
                        <span className={styles.searchIconContainer}>
                            <FontAwesomeIcon
                                icon={faSearch}
                                className={styles.searchIcon}
                            />
                        </span>
                    )}
                </div>
            </div>

            <div className={styles.recipientsList}>
                {usersList.map((user, index) => {
                    return (
                        <Recipient
                            key={index}
                            recipient={user}
                            handleSelectedUser={handleSelectedUser}
                            selectedUser={selectedUser}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default RecipientsList;
