import React from "react";
import Logo from "../../assets/images/LOGO (3).svg";
import Stars from "../../assets/images/stars_image@2x.png";
import styles from "./emailNotMatch.module.css";
import { useHistory } from "react-router";

export default () => {
    const history = useHistory();

    return (
        <div style={{ height: "100vh", overflow: "hidden" }}>
            <div
                className={styles.logoContainer}
                onClick={() => history.push("/")}
            >
                <img src={Logo} className={styles.studentLogoBg} alt="Logo" />
                <h1 className={styles.logoText}>
                    plassroom<sup>+</sup>
                </h1>
            </div>

            <img className={styles.starsImg} src={Stars} alt="Stars"></img>
            <div className={styles.parentWrapper}>
                <div className={styles.content}>
                    <h1>Unable to book class</h1>
                    <p className={styles.p1}>
                        The email address you have given to your teacher does
                        not match the current one that you are logging in from.
                    </p>
                    <p className={styles.p1}>
                        Please register and login with that email address or
                        inform your teacher to update their records to proceed
                        with booking your class.
                    </p>
                    <p className={styles.p2}>
                        If you would like to be directed to your Plassroom home
                        page, <a href="/">click here.</a>
                    </p>
                </div>
            </div>
        </div>
    );
};
