import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { Fragment } from "react";
import Loading from "../../../../components/Loading/Loading";
import ChatDetails from "./ChatDetails";
import ChatList from "./ChatList";
import styles from "./index.module.scss";

const ChatBox = ({
    selectedUser,
    chatsList,
    loadingChatHistory,
    isParticipantRemoved,
    setShowChatScreen,
}) => {
    return selectedUser ? (
        <div className={styles.chatBoxMain}>
            {loadingChatHistory ? (
                <Loading />
            ) : (
                <Fragment>
                    <div
                        className={styles.backButtonContainer}
                        onClick={() => setShowChatScreen(false)}
                    >
                        <span>
                            <FontAwesomeIcon
                                icon={faLongArrowAltLeft}
                                className={styles.backIcon}
                            />
                        </span>{" "}
                        Back
                    </div>

                    <ChatDetails user={selectedUser} />

                    <div className={styles.chatsListContainer}>
                        {chatsList.map((chat, index) => {
                            return _.get(chat, "session.class_name") ? (
                                <ChatList
                                    chat={chat}
                                    user={selectedUser}
                                    key={index}
                                    index={index}
                                    isParticipantRemoved={isParticipantRemoved}
                                />
                            ) : null;
                        })}
                    </div>
                </Fragment>
            )}
        </div>
    ) : null;
};

export default ChatBox;
