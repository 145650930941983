import {
    faGreaterThan,
    faLessThan,
    faPause,
    faPlay,
    faRedo,
    faStop,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DefaultAudio from "../../../../../assets/audio/bensound-ukulele.mp3";
import PopSound from "../../../../../assets/audio/Bubble Pop SFX - Game 1.mp3";
import EndVideo from "../../../../../assets/videos/_End.mp4";
import StartVideo from "../../../../../assets/videos/_Start.mp4";
import Duration from "../../../../../components/Durationpicker";
import Snackbar from "../../../../../components/Snackbar";
import styles from "./Game.module.scss";
import { useHistory } from "react-router-dom";

export default ({
    currentPanel,
    session,
    renderGame,
    setIsConfigComplete,
    studentView,
}) => {
    if (renderGame) {
        const history = useHistory();
        const currentData = JSON.parse(localStorage.getItem("auth")).user._id;
        const totalImages = currentPanel.activity_settings.total_image;
        const imageTypes = currentPanel.activity_settings.image_type;
        const [images, setImages] = useState([]);
        const [imageElements, setImageElements] = useState([]);
        const [msg, setMsg] = useState(null);
        const [showSnackbar, setShowSnackbar] = useState(false);
        const [bgImage, setBgImage] = useState("");
        const imgRefs = useRef([]);
        const [time, setTime] = useState();
        const [hoverControls, setHoverControls] = useState(false);
        const [sessionLocal, setSessionLocal] = useState(session);
        const [showResult, setShowResult] = useState(false);
        let [animateBubbleAnimationObjs, setAnimateBubbleAnimationObjs] =
            useState([]);
        let [sideWaysAnimationObjs, setSideWaysAnimationObjs] = useState([]);
        const [scorePanelFlag, setScorePanelFlag] = useState(0);
        const currentUserId = JSON.parse(localStorage.getItem("auth")).user._id;
        const currentUserRole = JSON.parse(localStorage.getItem("auth")).user
            .role;
        let playAllowed = useRef(false);
        const student = sessionLocal.students_ids.find((obj) => {
            return obj.id === currentUserId;
        });

        if (
            !currentPanel.activity_settings.turn_based ||
            currentUserRole === "teacher"
        ) {
            playAllowed.current = true;
        } else if (student === undefined) {
            playAllowed.current = false;
        } else {
            playAllowed.current = student.access;
        }

        const gameDivRef = useRef();
        const distances = [0];
        const imagesOfOneType = Math.floor(totalImages / imageTypes);
        const difference = 100 / totalImages;
        const remainingImages = totalImages % imageTypes;
        let gameStateRef = useRef("stop");
        let videoRef = useRef();
        let bgMusicRef = useRef();
        let bgMusicLocRef = useRef();

        const animateBubble = [{ bottom: "0" }, { bottom: "200%" }];
        const sideWays = [{ marginLeft: "0px" }, { marginLeft: "50px" }];
        let animateBubbleTiming = {
            duration: 7000,
            iterations: Infinity,
            easing: "linear",
            fill: "forwards",
        };
        const sideWaysTiming = {
            duration: 2000,
            easing: "ease-in-out",
            iterations: Infinity,
            direction: "alternate",
        };
        const fadeOut = [{ opacity: "1" }, { opacity: "0" }];
        const fadeOutTiming = {
            duration: 2000,
            iterations: 1,
            fill: "forwards",
        };

        useEffect(() => {
            if (currentPanel) {
                const gameStatus =
                    currentPanel.activity_settings.game1_status.find(
                        (obj) =>
                            obj.user_id === currentUserId &&
                            obj.user_name === "teacher"
                    );
                let resultData = undefined;
                if (!gameStatus) {
                    gameStatusUpdate(resultData);
                }
            }
            // eslint-disable-next-line
        }, [sessionLocal]);

        useEffect(() => {
            const getSelectedMedia = async () => {
                const selectedMediaArray =
                    currentPanel.activity_settings.image_ids.concat(
                        currentPanel.activity_settings.background_audio_ids,
                        currentPanel.activity_settings.background_image_ids
                    );
                let galleryData = {
                    selectedMediaArray: selectedMediaArray,
                    userId: sessionLocal.teacher_id,
                };
                await axios
                    .post(`/api/v1/user/gallery/selected`, galleryData)
                    .then((data) => {
                        if (data.data.success) {
                            const resultMedia = data.data.data.newArray;
                            const resultImages = resultMedia.slice(
                                0,
                                currentPanel.activity_settings.image_ids.length
                            );
                            const resultBgMusic = resultMedia.slice(
                                currentPanel.activity_settings.image_ids.length,
                                resultMedia.length
                            );
                            setImages(
                                resultImages.map((imgObj) => {
                                    return imgObj?.location;
                                })
                            );

                            const bgImageRes = resultMedia.filter(
                                (media) =>
                                    media._id ===
                                    currentPanel.activity_settings
                                        .background_image_ids[0]
                            );

                            setBgImage(bgImageRes[0]?.location);
                            bgMusicLocRef.current = resultBgMusic[0]?.location;
                        }
                    })
                    .catch((error) => {
                        setMsg(
                            _.get(
                                error,
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                        return Promise.reject(error);
                    });
            };

            getSelectedMedia();
            // eslint-disable-next-line
        }, []);

        useEffect(() => {
            if (images.length > 0) {
                populateImageElements();
            }
            // eslint-disable-next-line
        }, [images, imageTypes, totalImages]);

        useEffect(() => {
            let localArr1 = [];
            let localArr2 = [];
            imgRefs.current.forEach((ref) => {
                animateBubbleTiming.duration = Math.random() * 5000 + 2500;
                gameDivRef.current.style.setProperty("display", "none");
                const animation1 = ref.current.animate(
                    animateBubble,
                    animateBubbleTiming
                );
                const animation2 = ref.current.animate(
                    sideWays,
                    sideWaysTiming
                );
                animation1.id = "bubble";
                animation2.id = "sideWays";
                animation1.pause();
                animation2.pause();
                animation1.currentTime = 0;
                animation2.currentTime = 0;
                localArr1.push(animation1);
                localArr2.push(animation2);
                setAnimateBubbleAnimationObjs(localArr1);
                setSideWaysAnimationObjs(localArr2);
            });
            // eslint-disable-next-line
        }, [imgRefs.current]);

        for (let i = 0; i < totalImages; i++) {
            distances.push(Math.min(distances[i] + difference, 100));
        }

        const populateImageElements = () => {
            const newArr = [];
            const refsArr = [];
            let imgIndex = 0;
            for (let i = 0; i < imageTypes; i++) {
                for (let j = 0; j < imagesOfOneType; j++) {
                    const ref = React.createRef();
                    refsArr.push(ref);
                    newArr.push(
                        <img
                            id={`${imgIndex}-${i}`}
                            key={imgIndex}
                            ref={ref}
                            onClick={() => {
                                imageOnclickHandler(ref);
                            }}
                            src={images[i]}
                            className={styles.bubble}
                            style={{ left: `${distances[imgIndex++]}%` }}
                            alt=""
                        />
                    );
                }
            }
            for (let i = 0; i < remainingImages; i++) {
                const ref = React.createRef();
                refsArr.push(ref);
                newArr.push(
                    <img
                        id={`${imgIndex}-${i}`}
                        ref={ref}
                        key={imgIndex}
                        onClick={() => {
                            imageOnclickHandler(ref);
                        }}
                        src={images[i]}
                        className={styles.bubble}
                        style={{ left: `${distances[imgIndex++]}%` }}
                        alt=""
                    />
                );
            }
            imgRefs.current = [...refsArr];
            setImageElements(newArr);
        };

        const imageOnclickHandler = (ref) => {
            if (playAllowed.current) {
                const isOpaque =
                    window
                        .getComputedStyle(ref.current)
                        .getPropertyValue("opacity") > 0.5;
                if (isOpaque) {
                    const bubble = ref.current
                        .getAnimations()
                        .find((animation) => animation.id === "bubble");
                    const sideWays = ref.current
                        .getAnimations()
                        .find((animation) => animation.id === "sideWays");
                    if (gameStateRef.current === "play") {
                        if (currentPanel.activity_settings.scoreboard) {
                            const newGameStatus = {
                                ...currentPanel.activity_settings.game1_status.find(
                                    (obj) =>
                                        obj.user_id === currentUserId &&
                                        obj.user_name === "teacher"
                                ),
                            };
                            newGameStatus.result_data = [1, 3];
                            gameStatusUpdate(newGameStatus.result_data);
                        }
                        bubblePop(ref, bubble, sideWays, currentData);
                    }
                }
            }
        };

        const gameStatusUpdate = async (newResultData) => {
            const userIds = [session.teacher_id];
            const payload = {
                gameStatus: [
                    {
                        user_id: userIds[0],
                        result_data: newResultData || [],
                        user_name: "teacher",
                        user_color: "#ffaa00",
                    },
                ],
            };
            handleGameStatusChange(payload);
        };

        const bubblePop = (ref, bubble, sideWays, userID) => {
            new Audio(PopSound).play();
            const currentStyle = ref.current.style;
            const gameStatusTemp = currentPanel.activity_settings.game1_status;
            const userColor = gameStatusTemp.find(
                (user) => user.user_id === userID
            );
            // console.log("USER COLOR IS ", userColor.user_color);
            currentStyle.border = `5px solid ${userColor.user_color}`;

            setTimeout(() => {
                currentStyle.border = "none";
            }, 100);

            currentStyle.borderRadius = "100%";
            bubble.pause();
            sideWays.pause();
            ref.current.animate(fadeOut, fadeOutTiming).onfinish = () => {
                currentStyle.display = "none";

                setTimeout(() => {
                    currentStyle.display = "block";
                    currentStyle.setProperty("opacity", "1", "important");
                    bubble.currentTime =
                        bubble.effect.getComputedTiming().duration - 500;
                    if (gameStateRef.current !== "pause") {
                        bubble.play();
                        sideWays.play();
                    }
                }, 2000);
            };
        };

        const handleGameStatusChange = (data) => {
            const newSession = {
                ...sessionLocal,
                ...{
                    configuration: {
                        ...sessionLocal.configuration,
                        right_panel_settings:
                            sessionLocal.configuration.right_panel_settings.map(
                                (obj) => {
                                    if (obj._id === currentPanel._id) {
                                        obj.activity_settings.game1_status =
                                            data.gameStatus;
                                    }
                                    return obj;
                                }
                            ),
                    },
                },
            };
        };

        const handleStartVideoEnd = () => {
            handleBgMusic();
            gameDivRef.current.style.setProperty("display", "block");
            videoRef.current.style.setProperty("display", "none");
            animateBubbleAnimationObjs.forEach((animation) => {
                animation.play();
            });
            sideWaysAnimationObjs.forEach((animation) => {
                animation.play();
            });
            gameStateRef.current = "play";
            videoRef.current.removeEventListener("ended", handleStartVideoEnd);
        };

        const handleStopVideoEnd = () => {
            videoRef.current.style.setProperty("display", "none");
            videoRef.current.removeEventListener("ended", handleStopVideoEnd);
        };

        const handleBgMusicPlay = () => {
            bgMusicRef.current.play();
        };

        const pauseGame = () => {
            gameDivRef.current.style.setProperty("display", "block");
            if (bgMusicRef.current) {
                bgMusicRef.current.pause();
                bgMusicRef.current.removeEventListener(
                    "canplaythrough",
                    handleBgMusicPlay
                );
            }
            animateBubbleAnimationObjs.forEach((animation) => {
                animation.pause();
            });
            sideWaysAnimationObjs.forEach((animation) => {
                animation.pause();
            });
            gameStateRef.current = "pause";
        };

        const playGame = async () => {
            if (gameStateRef.current !== "play") {
                setShowResult(false);

                if (gameStateRef.current === "stop") {
                    let color = "";
                    const newSession = {
                        ...sessionLocal,
                        ...{
                            configuration: {
                                ...sessionLocal.configuration,
                                right_panel_settings:
                                    sessionLocal.configuration.right_panel_settings.map(
                                        (obj) => {
                                            if (obj._id === currentPanel._id) {
                                                const gameStatus =
                                                    obj.activity_settings.game1_status.find(
                                                        (obj) =>
                                                            obj.user_id ===
                                                                currentUserId &&
                                                            obj.user_name ===
                                                                "teacher"
                                                    );
                                                color = gameStatus?.user_color;
                                                obj.activity_settings.game1_status =
                                                    [];
                                            }
                                            return obj;
                                        }
                                    ),
                            },
                        },
                    };
                    setSessionLocal(newSession);
                } else if (gameStateRef.current === "pause") {
                    gameDivRef.current.style.setProperty("display", "block");
                    animateBubbleAnimationObjs.forEach((animation) => {
                        animation.play();
                    });
                    sideWaysAnimationObjs.forEach((animation) => {
                        animation.play();
                    });
                    gameStateRef.current = "play";
                    return;
                }
                videoRef.current.style.setProperty("display", "block");
                videoRef.current.src = StartVideo;
                videoRef.current.addEventListener("ended", handleStartVideoEnd);
                videoRef.current.load();
                await videoRef.current.play().catch((e) => {
                    console.log(e);
                });
            }
        };

        const handleBgMusic = () => {
            if (bgMusicLocRef.current) {
                let audioComponent = new Audio(bgMusicLocRef.current);
                audioComponent.addEventListener("error", () => {
                    bgMusicLocRef.current = DefaultAudio;
                    handleBgMusic();
                });
                bgMusicRef.current = audioComponent;
                bgMusicRef.current.loop = true;
                bgMusicRef.current.volume = 0.25;
                bgMusicRef.current.addEventListener(
                    "canplaythrough",
                    handleBgMusicPlay
                );
            }
        };

        const exitGame = async () => {
            if (bgMusicRef.current) {
                bgMusicRef.current.pause();
                bgMusicRef.current.removeEventListener(
                    "canplaythrough",
                    handleBgMusicPlay
                );
            }
            // setIsConfigComplete(false);
            history.goBack();
        };

        const stopGame = async () => {
            setShowResult(true);
            if (bgMusicRef.current) {
                bgMusicRef.current.pause();
                bgMusicRef.current.removeEventListener(
                    "canplaythrough",
                    handleBgMusicPlay
                );
            }
            gameDivRef.current.style.setProperty("display", "none");
            videoRef.current.style.setProperty("display", "block");
            videoRef.current.src = EndVideo;
            videoRef.current.addEventListener("ended", handleStopVideoEnd);
            videoRef.current.load();
            await videoRef.current.play().catch((e) => {
                console.log(e);
            });
            animateBubbleAnimationObjs.forEach((animation) => {
                animation.pause();
                animation.currentTime = 0;
            });
            sideWaysAnimationObjs.forEach((animation) => {
                animation.pause();
                animation.currentTime = 0;
            });
            gameStateRef.current = "stop";
        };

        const refreshGame = () => {
            gameDivRef.current.style.setProperty("display", "none");
            if (bgMusicRef.current) {
                bgMusicRef.current.pause();
                bgMusicRef.current.removeEventListener(
                    "canplaythrough",
                    handleBgMusicPlay
                );
            }
            animateBubbleAnimationObjs.forEach((animation) => {
                animation.pause();
                animation.currentTime = 0;
            });
            sideWaysAnimationObjs.forEach((animation) => {
                animation.pause();
                animation.currentTime = 0;
            });
            gameStateRef.current = "stop";
        };

        const handleSetTime = (value) => {
            if (value && !studentView) {
                setTime(value);
            }
        };

        const decreaseFlag = () => {
            if (scorePanelFlag > 0) {
                setScorePanelFlag(scorePanelFlag - 1);
            } else {
                setScorePanelFlag(Math.ceil(gameStatus.length / 3) - 1);
            }
        };

        const increaseFlag = () => {
            if (scorePanelFlag < gameStatus.length / 3 - 1) {
                setScorePanelFlag(scorePanelFlag + 1);
            } else {
                setScorePanelFlag(0);
            }
        };

        let totalScore = [];
        let result = undefined;
        let gameStatus = [];
        const gameStatusTemp = currentPanel.activity_settings.game1_status;
        gameStatus = gameStatusTemp.sort((obj) => {
            return !(
                obj.user_id === currentUserId && obj.user_name === "teacher"
            );
        });

        if (
            gameStatus[0]?.result_data.length > 0 &&
            currentPanel.activity_settings.scoreboard
        ) {
            result = (
                <div className={styles.scoreboard}>
                    <h1 className={styles.resultHeading}>Well Done!</h1>
                    <Row className={styles.resultsRow}>
                        {gameStatus.length > 3 && (
                            <Col xs={1} className={styles.flex}>
                                <FontAwesomeIcon
                                    icon={faLessThan}
                                    onClick={decreaseFlag}
                                    className={styles.leftArrow}
                                />
                            </Col>
                        )}
                        {gameStatus.map((participantStatus, index) => {
                            if (
                                index >= 3 * scorePanelFlag &&
                                index < 3 * scorePanelFlag + 3
                            ) {
                                totalScore =
                                    participantStatus.result_data.reduce(
                                        (a, b) => a + b,
                                        0
                                    );
                                return (
                                    <Col xs={3} key={index}>
                                        <div
                                            className={styles.scoreCard}
                                            style={{
                                                borderColor:
                                                    participantStatus.user_color,
                                            }}
                                        >
                                            <h2
                                                className={
                                                    styles.studentNameAndScore
                                                }
                                            >
                                                {participantStatus.user_name}
                                                <span
                                                    className={
                                                        styles.totalScore
                                                    }
                                                >
                                                    {totalScore}
                                                </span>
                                            </h2>
                                            <div
                                                className={
                                                    styles.splitScoresRow
                                                }
                                            >
                                                {participantStatus.result_data.map(
                                                    (score, index) => {
                                                        return (
                                                            <div
                                                                key={`${index}_splitScoresCol`}
                                                                className={
                                                                    styles.splitScoresCol
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        images[
                                                                            index
                                                                        ]
                                                                    }
                                                                    className={`${styles.bubble} ${styles.scoreImage}`}
                                                                    alt=""
                                                                />
                                                                <p
                                                                    className={
                                                                        styles.splitScore
                                                                    }
                                                                >
                                                                    {score}
                                                                </p>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            }
                        })}
                        {gameStatus.length > 3 && (
                            <Col xs={1} className={styles.flex}>
                                <FontAwesomeIcon
                                    icon={faGreaterThan}
                                    onClick={increaseFlag}
                                    className={styles.rightArrow}
                                />
                            </Col>
                        )}
                    </Row>
                </div>
            );
        }

        return (
            <div className={styles.gameScreen}>
                {/* <div
                    className={styles.closeButton}
                    onClick={() => {
                        exitGame();
                    }}
                >
                    Close
                </div> */}
                <div className={styles.gameContainer}>
                    <div className={styles.assets}>
                        {msg && (
                            <Snackbar
                                status="error"
                                message={msg}
                                open={showSnackbar}
                                setOpen={setShowSnackbar}
                            ></Snackbar>
                        )}

                        <video
                            className={styles.video}
                            ref={videoRef}
                            type="video/mp4"
                        ></video>
                        <div
                            className={styles.gameDiv}
                            ref={gameDivRef}
                            style={{
                                backgroundImage: `url(${bgImage})`,
                                backgroundSize: "cover",
                                borderRadius: "25px",
                            }}
                        >
                            {imageElements}
                        </div>

                        {currentPanel.activity_settings.timer &&
                            !showResult &&
                            !studentView && (
                                <div className={styles.time}>
                                    <Duration
                                        data={time}
                                        setData={handleSetTime}
                                        width="200px"
                                        currentUserRole={currentUserRole}
                                        componentName="Game"
                                    />
                                </div>
                            )}

                        {!studentView && (
                            <div
                                className={styles.gameControls}
                                onMouseEnter={() => setHoverControls(true)}
                                onMouseLeave={() => setHoverControls(false)}
                            >
                                <FontAwesomeIcon
                                    icon={faPlay}
                                    onClick={playGame}
                                    alt="play"
                                    className={styles.gameControl}
                                />
                                {hoverControls && (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faPause}
                                            onClick={pauseGame}
                                            alt="pause"
                                            className={styles.gameControl}
                                        />
                                        <FontAwesomeIcon
                                            icon={faStop}
                                            onClick={stopGame}
                                            alt="stop"
                                            className={styles.gameControl}
                                        />
                                        <FontAwesomeIcon
                                            icon={faRedo}
                                            onClick={refreshGame}
                                            alt="refresh"
                                            className={styles.gameControl}
                                        />
                                    </>
                                )}
                            </div>
                        )}

                        {showResult &&
                            currentPanel.activity_settings.scoreboard &&
                            result}
                    </div>
                </div>
            </div>
        );
    } else return null;
};
