import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import styles from "./index.module.scss";
import PageLoader from "../../../../components/PageLoader";

const LandingPageCarousel = ({ setMsg, setShowSnackbar }) => {
    const [index, setIndex] = useState(0);
    const [carousalPhotos, setCarousalItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [width, setWidth] = React.useState(window.innerWidth);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const handleNextImage = () => {
        const newIndex = index + 1 < carousalPhotos.length ? index + 1 : 0;
        setIndex(newIndex);
    };

    useEffect(() => {
        handleFetchImages();
        // eslint-disable-next-line
    }, []);

    const handleFetchImages = () => {
        const url = "/api/v1/home/carouselItems";
        setLoading(true);
        axios
            .get(url)
            .then((res) => {
                const responseData = res.data;

                if (responseData.success) {
                    const carousalItems = _.get(responseData, "data", []);
                    setCarousalItems(carousalItems);
                    setLoading(false);
                } else {
                    setMsg(
                        _.get(responseData, "message", "Something went wrong.")
                    );
                    setLoading(false);
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
            })
            .catch((err) => {
                setMsg(
                    _.get(err, "response.data.title", "Something went wrong.")
                );
                setLoading(false);
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
            });
    };

    const redirectToSignup = (index) => {
        if (index === 0) {
            window.location.href = "/subscription";
        } else if (index === 1) {
            window.location.href = `/signup`;
        }
    };

    return (
        <div className={styles.carouselContainer}>
            {loading && <PageLoader open={loading} />}
            <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                interval={5000}
                className={styles.mainCarousel}
                fade={true}
            >
                {carousalPhotos.map((photo, index) => {
                    return (
                        <Carousel.Item
                            className={styles.mainCarouselItem}
                            key={photo._id}
                            onClick={() => {
                                redirectToSignup(index);
                            }}
                            style={{
                                cursor:
                                    index === 0 || index === 1
                                        ? "pointer"
                                        : "unset",
                            }}
                        >
                            <img
                                className="d-block w-100"
                                src={
                                    window.screen.width < 615
                                        ? photo.mobile
                                        : window.screen.width < 1281
                                        ? photo.tablet
                                        : index === 0
                                        ? photo.tablet
                                        : photo.desktop
                                }
                                alt="Slide"
                                style={{}}
                            />
                        </Carousel.Item>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default LandingPageCarousel;
