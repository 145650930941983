import React from "react";
import Navbar from "../../../components/Navbar";
import styles from "./index.module.scss";

const PlanDetailsFaq = () => {
    return (
        <>
            <Navbar />
            <div className={styles?.container}>
                <div className={styles.mainContainer}>
                    <div className={styles.heading}>
                        Plan details and Frequently Asked Questions
                    </div>
                    <div
                        className={styles.chooseHead}
                        onClick={() =>
                            (window.location.href =
                                "/choose-plan-and-subscription-plan")
                        }
                    >
                        Take me to choose your plan
                    </div>
                    <div className={styles.planHead}>
                        The Lite Plan
                        <span style={{ marginLeft: "10px" }}>GBP 10</span>
                    </div>
                    <ul className={styles.planList}>
                        <li>
                            30 days free trial upon signing up to a student
                            account - only once per student.
                        </li>
                        <li>
                            Use Plasscoins to redeem 60 minutes of learning
                            (estimate worth GBP 12).
                        </li>
                        <li>Unlimited registration to any free sessions. </li>
                        <li>
                            Unused Plasscoins will roll over for 3 months from
                            date Plasscoins were entered into your account.
                        </li>
                        <li>
                            To subscribe, please click on the plan of your
                            choice on our Plan Subscription page and follow
                            through to confirm subscription.
                        </li>
                        <li>
                            To request for a cancellation once a subscription is
                            in place, please write to enquiry@plassroom.com. We
                            will endeavour to respond as soon as we can or
                            within 3 working days.
                        </li>
                        <li>
                            The cancellation will kick in upon the next calendar
                            month, ie. you will not be charged on the next bill
                            cycle and there will be no additional Plasscoins
                            assigned for the next month. Plasscoins obtained
                            apart from default subscription are not affected.
                        </li>
                        <li>
                            Personal concierge - reminders and tech support will
                            be communicated via the email tied to your account.
                        </li>
                        <li>
                            Plasscoins cannot be used for courses or 1 to 1
                            sessions.{" "}
                        </li>
                    </ul>

                    <div className={styles.planHead}>
                        The PLASS Plan
                        <span style={{ marginLeft: "10px" }}>GBP 18</span>
                    </div>
                    <ul className={styles.planList}>
                        <li>
                            Use Plasscoins to redeem 120 minutes of learning
                            (estimate worth GBP 20).
                        </li>
                        <li>Unlimited registration to any free sessions. </li>
                        <li>
                            Unused Plasscoins will roll over for 3 months from
                            date Plasscoins were entered into your account.
                        </li>
                        <li>
                            To subscribe, please click on the plan of your
                            choice on our Plan Subscription page and follow
                            through to confirm subscription.{" "}
                        </li>
                        <li>
                            To request for a cancellation once a subscription is
                            in place, please write to enquiry@plassroom.com. We
                            will endeavour to respond as soon as we can or
                            within 3 working days.
                        </li>
                        <li>
                            The cancellation will kick in upon the next calendar
                            month, ie. you will not be charged on the next bill
                            cycle and there will be no additional Plasscoins
                            assigned for the next month. Plasscoins obtained
                            apart from default subscription are not affected.
                        </li>
                        <li>
                            Personal concierge - reminders and tech support will
                            be communicated via the email tied to your account.
                        </li>
                        <li>
                            Plasscoins cannot be used for courses or 1 to 1
                            sessions.{" "}
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default PlanDetailsFaq;
