import React from "react";
import { Link } from "react-router-dom";
import FooterNew from "../../components/FooterNew";
import Navbar from "../../components/Navbar";
import styles from "./index.module.scss";

const PrivacyPolicy = () => {
    return (
        <div className={styles.mainContainer}>
            <Navbar />

            <div className={styles.pageContainer}>
                <div className={styles.contentContainer}>
                    <h1 className={styles.pageHeading}>
                        Plassroom Terms of Service
                    </h1>

                    <div className={styles.updatedTime}>
                        Last Updated: 10 Nov 2021
                    </div>

                    <div className={styles.bodyContainer}>OVERVIEW</div>

                    <div className={styles.bodyContainer}>
                        This website is operated by Plassroom Ltd. Throughout
                        the site, the terms “we”, “us” and “our”and “Plassroom”
                        refer to Plassroom Ltd. Plassroom offers this website,
                        including all information and services available from
                        this site to you, the user, conditioned upon your
                        acceptance of all terms, conditions, policies and
                        notices stated here.
                    </div>

                    <div className={styles.bodyContainer}>
                        By visiting our site, using the tools available on our
                        web application and/or purchasing something from our
                        Store, you engage in our “Service” and agree to be bound
                        by the following terms and conditions (“Terms of
                        Service”, “Terms”), including those additional terms and
                        conditions and policies referenced herein and/or
                        available by hyperlink on our site. These Terms of
                        Service apply to all users of the site, including
                        without limitation users who are browsers, teachers,
                        students, merchants, and/ or contributors of content.
                        Please read these Terms of Service carefully before
                        accessing or using our website. By accessing or using
                        any part of the site, you agree to be bound by these
                        Terms of Service. If you do not agree to all the terms
                        and conditions of this agreement, then you may not
                        access the website or use any services. If these Terms
                        of Service are considered an offer, acceptance is
                        expressly limited to these Terms of Service.
                    </div>

                    <div className={styles.bodyContainer}>
                        Any new features or tools which are added to the current
                        website and/or application shall also be subject to the
                        Terms of Service. You can review the most current
                        version of the Terms of Service at any time on this
                        page. We reserve the right to update, change or replace
                        any part of these Terms of Service by posting updates
                        and/or changes to our website. We will post changes on
                        this page and will indicate at the top of this page the
                        date these terms were last revised. It is your
                        responsibility to check this page periodically for
                        changes. Your continued use of or access to the website
                        following the posting of any changes constitutes
                        acceptance of those changes.
                    </div>

                    <div className={styles.bodyContainer}>
                        When using certain Services, you will be subject to any
                        additional terms applicable to such services that may be
                        posted on the Service from time to time, including but
                        not limited to, our{" "}
                        <Link to="/privacypolicy">Privacy Policy</Link>, Teacher
                        Guide etc. All such terms are hereby incorporated by
                        reference into these Terms of Service.
                    </div>

                    <div className={styles.bodyContainer}>
                        Our web application may be integrated with other third
                        party applications. They provide us with additional
                        functionalities that allows us to maintain the site and
                        sell our services to you. We adhere to their Terms &
                        Conditions and other relevant policies as well when
                        providing our services to you.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 1 – GENERAL CONDITIONS
                    </div>

                    <div className={styles.bodyContainer}>
                        Plassroom’s Service is a tool for teachers to setup and
                        host their online class content and an online
                        marketplace for children classes. It is designed for for
                        Teachers to create and store their online class content,
                        market, sell and conduct their Classes and Parents to
                        find and book Classes for the benefit of their child or
                        children. As the provider of online teaching and video
                        conferencing tools and an online marketplace, Plassroom
                        does not own, create, sell, resell, control, or manage
                        any Classes. Plassroom’s responsibilities are limited
                        to: (i) providing the Site with online class preparation
                        and post class feedback tools and tools for use in an
                        online live class setting; (ii) providing the Site as an
                        online marketplace and platform to facilitate the sale,
                        purchase, and conduct of Classes, and (iii) serving as
                        the limited agent of each Teacher for the purpose of
                        accepting payments from a Parent on behalf of the
                        Teacher. There are risks that you assume when dealing
                        with other Users (including those who may be acting
                        under false pretenses).
                    </div>

                    <div className={styles.bodyContainer}>
                        By agreeing to these Terms of Service, you represent
                        that you are at least of legal age in your state or
                        country of residence, or that of where you reside and
                        you have given us your consent to allow any of your
                        minor dependents to use this site.
                    </div>

                    <div className={styles.bodyContainer}>
                        You may not use our products for any illegal or
                        unauthorised purpose nor may you, in the use of the
                        Service, violate any laws in your jurisdiction
                        (including but not limited to copyright laws). Our
                        services involve children and we are aware of the
                        importance of safeguarding for all services offered on
                        our platform. It is the Parents or carers’
                        responsibility to ensure that they monitor their child
                        from time to time in class, educate them on internet
                        safety and they have appropriate content controls and
                        internet security software to protect their child from
                        inappropriate online content.
                    </div>

                    <div className={styles.bodyContainer}>
                        We treat complaints by parents very seriously on any
                        matter relating to the safety of children and will
                        conduct quality assurances where we can. We reserve all
                        rights and responsibilities on this matter. Please write
                        to enquiry@plassroom.com with any feedback, concerns,
                        disputes and comments.
                    </div>

                    <div className={styles.bodyContainer}>
                        You may not direct sell, harass any user or any other
                        methods to sell any personal products or services on
                        Plassroom, especially to children directly.
                    </div>

                    <div className={styles.bodyContainer}>
                        We will never provide any contact information of our
                        users to a third party without first seeking consent
                        from you. You may not obtain contact information of
                        users on Plassroom.
                    </div>

                    <div className={styles.bodyContainer}>
                        You must not transmit any worms or viruses or any code
                        of a destructive nature.
                    </div>

                    <div className={styles.bodyContainer}>
                        A breach or violation of any of the Terms will result in
                        an immediate termination of your Services.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 2 – ACCOUNT RELATED INFORMATION
                    </div>

                    <div className={styles.bodyContainer}>
                        You will be required to register with Plassroom in order
                        to access and use certain features of the Service. If
                        you choose to register for the Service, you agree to
                        provide and maintain true, accurate, current and
                        complete information about yourself as prompted by the
                        Service’s registration form. Users will be responsible
                        for maintaining accuracy of these information on the
                        Personal Details tab within the Settings page upon
                        logging into their account on Plassroom. Registration
                        data and certain other information about you are
                        governed by our{" "}
                        <Link to="/privacypolicy">Privacy Policy</Link>. You
                        must be of legal age to form a binding contract to
                        register for the Service (in many jurisdictions, this
                        age is 18). If you are not yet of legal age to form a
                        binding contract, then you must get your Parent to read
                        these Terms of Service and agree to them for you before
                        you use the Service. If you are a Parent and you provide
                        your consent to your child's use of the Service, then
                        you agree to be bound by these Terms of Service with
                        respect to your child’s use of the Service. We reserve
                        the right to refuse service to anyone for any reason at
                        any time.
                    </div>

                    <div className={styles.bodyContainer}>
                        You are responsible for maintaining the confidentiality
                        of your password and account, if any, and are fully
                        responsible for any and all activities that occur under
                        your password or account. You agree to (a) immediately
                        notify Plassroom of any unauthorized use of your
                        password or account or any other breach of security, and
                        (b) ensure that you exit from your account at the end of
                        each session when accessing the Service. The Logout
                        button can be accessed via the Settings icon on your
                        home page. Plassroom will not be liable for any loss or
                        damage arising from your failure to comply with this
                        Section.
                    </div>

                    <div className={styles.bodyContainer}>
                        You understand that your content (not including credit
                        card information), may be transferred unencrypted and
                        involve (a) transmissions over various networks; and (b)
                        changes to conform and adapt to technical requirements
                        of connecting networks or devices. Credit card
                        information is stored and proceed by Stripe Ltd, and no
                        information is stored on our servers.
                    </div>

                    <div className={styles.bodyContainer}>
                        You agree not to reproduce, duplicate, copy, sell,
                        resell or exploit any portion of the Service, use of the
                        Service, or access to the Service or any contact on the
                        website through which the service is provided, without
                        express written permission by us.
                    </div>

                    <div className={styles.bodyContainer}>
                        The headings used in this agreement are included for
                        convenience only and will not limit or otherwise affect
                        these Terms.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF
                        INFORMATION
                    </div>

                    <div className={styles.bodyContainer}>
                        We are not responsible if information made available on
                        this site is not accurate, complete or current. The
                        material on this site is provided for general
                        information only and should not be relied upon or used
                        as the sole basis for making decisions without
                        consulting primary, more accurate, more complete or more
                        timely sources of information. Any reliance on the
                        material on this site is at your own risk.
                    </div>

                    <div className={styles.bodyContainer}>
                        While Plassroom strives to provide a safe and welcoming
                        environment for its Users, you agree that all of these
                        risks are ultimately borne by you, and not Plassroom.
                        Plassroom does not control the behaviour of Users or the
                        quality of the Classes. As a result, Plassroom cannot
                        guarantee the authenticity, quality, safety, legality,
                        or appropriateness of the Classes. Plassroom will ensure
                        the tools we provide are stable and reliable, however it
                        does not control nor guarantee the perfect condition of
                        these tools at all times. We will do our best to ensure
                        that tools are in working condition for most of the
                        time, and if there are any technical issues reported to
                        us by Users, we will fix them as soon as reasonably
                        possible, according to the Service Level Agreements of
                        the platforms we have integrated. For classes that are
                        cancelled or unable to be fulfilled in an acceptable way
                        due to any serious technical issues with the system on
                        Plassroom eg. system break down, it is for the teacher
                        user to inform us and if found to be so Plassroom will
                        not take the commission due for the particular class.
                        Plassroom reserves the right in deciding which matters
                        are serious technical issues and all decisions made are
                        final and binding.
                    </div>

                    <div className={styles.bodyContainer}>
                        This site may contain certain historical information.
                        Historical information, necessarily, is not current and
                        is provided for your reference only. We reserve the
                        right to modify the contents of this site at any time,
                        but we have no obligation to update any information on
                        our site. You agree that it is your responsibility to
                        monitor changes to our site.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 4 - SERVICES AND PRICES
                    </div>

                    <div className={styles.bodyContainer}>
                        Classes which are listed on our store have a fixed date,
                        time, duration and price for bookings (or also known as
                        “fee”) by customers. Teacher users are not able to
                        change that unless they unpublish the class from the
                        store. Classes cannot be unpublished from the store
                        within 24 hours of the class time.
                    </div>

                    <div className={styles.bodyContainer}>
                        Only registered users on Plassroom are able to book a
                        class from our store and will be required to pay the
                        full price via our integration with Stripe Ltd
                        (henceforth also known as “Stripe”) to be considered a
                        successful booking. This will then be held by Plassroom
                        until class is successfully conducted and paid out to
                        the teacher in 7 days according to Stripe’s service
                        guarantees if no dispute or refund request is raised
                        within 5 days after the class. Once payment has been
                        made to the teacher, no refunds can be made to parent
                        users through Plassroom.
                    </div>

                    <div className={styles.bodyContainer}>
                        Teacher users have the sole discretion of rejecting the
                        student registration due to, but not limited to age
                        related, level/skills related, class requirements not
                        met or other reasons decided by the teacher. Full refund
                        of total paid fees by the parent user will be refunded.
                        A parent user can get in touch with the teacher using
                        our messaging function to explain the situation and
                        discuss with the teacher if the student would be
                        suitable for the class. If the teacher refuses the
                        student, the full amount of fees will be refunded to the
                        customer. Teacher users will normally reply on an
                        enquiry within 1-2 working days, otherwise a request to
                        contact the teacher can be raised to Plassroom and
                        Plassroom would do it’s best to contact the teacher.
                        Plassroom reserves a right to charge an administrative
                        fee to the teacher user if there has been a significant
                        amount of refunds that happened for the account.
                    </div>

                    <div className={styles.bodyContainer}>
                        Parent users who are no longer able to attend a class
                        they have booked into should cancel their booking by
                        sending a message to the teacher of the class through
                        their account before the stipulated start date and time
                        of the class to qualify for a full refund. It is the
                        sole discretion of teachers if to allow the refund. Once
                        approved, Plassroom will do its best to process this
                        approval within 5 days. However, please refer to
                        Stripe’s service guarantees on times for refunds into
                        customer accounts.
                    </div>

                    <div className={styles.bodyContainer}>
                        If student users do not attend a booked class, parent
                        users should speak with the teacher to explain their
                        reasons, and teachers have the final decision if to
                        allow the refund to be made payable back to the user
                        account. This should be informed and decided upon within
                        5 days after class before the refund period is closed
                        and fees are paid out to the teacher. If there is an
                        open dispute, Plassroom will not process the class
                        payout to the teacher until the dispute has been
                        resolved.
                    </div>

                    <div className={styles.bodyContainer}>
                        Users can submit a dispute to Plassroom if there is an
                        exceptional circumstance which require a further
                        investigation or mediation with the teacher if
                        legitimate effort has been incurred to discuss an issue
                        yet no agreement can be found . Plassroom will then
                        attempt to mediate between teacher and parent, however
                        teachers will still make the final decision on refunds.
                        If the issue relates to teacher misconduct,
                        misrepresentation of class details to a significant
                        extent, quality of class or due to technical issues,
                        Plassroom reserves the right of final decision of
                        refunds.
                    </div>

                    <div className={styles.bodyContainer}>
                        One hour before the stipulated time of the class, the
                        class will be removed from the store and no new
                        registrations can be made to the class.
                    </div>

                    <div className={styles.bodyContainer}>
                        Teacher users reserve the right to refuse any bookings
                        for free taster classes that they conduct. They may, in
                        their sole discretion, refuse booking of a user which
                        they deem not suitable for the particular taster class.
                        Reasons may include, but not limited to, student user
                        not being within the recommended age range or level of
                        the class, student has attended the free taster class
                        once before etc.
                    </div>

                    <div className={styles.bodyContainer}>
                        Teacher users are required to pay 20% of fees paid by
                        each student user per class (excluding VAT where
                        required) (henceforth also known as “commission”) to
                        Plassroom for usage of our web application, store and
                        other services provided by Plassroom to its users. If
                        fee is refunded to users then the commission will also
                        be refunded by Plassroom to the users. Upon a successful
                        execution of a class, the amount of total fees per class
                        less any refunds payable to parents directly and less
                        commission payable to Plassroom will be paid to the
                        teacher’s Stripe wallet 7 days after the end of the
                        class in accordance with Stripe’s service guarantees,
                        once all disputes raised have been resolved.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 5 – MODIFICATION OF SERVICES AND PRICES
                    </div>

                    <div className={styles.bodyContainer}>
                        Classes published on the store can be cancelled by the
                        teacher through unpublishing the class up to 24 hours
                        before stipulated time of the class. Upon cancellation
                        of class by the teacher, if there are any students that
                        have signed up to the particular class then the full
                        amount of fees will be refunded to the customer. This
                        will be triggered by Plassroom within 5 days, and
                        process after will follow according to Stripe’s service
                        guarantees on time for refunds to reach customers
                        accounts.
                    </div>

                    <div className={styles.bodyContainer}>
                        If teacher users find they are not able to attend the
                        class within 24 hours of the stipulated class time, they
                        should inform all parents as soon as they reasonably can
                        to Plassroom to trigger a refund for the class. Parent
                        users are also able to contact Plassroom to notify of a
                        class that was cancelled to initiate a refund process.
                        This process will normally take 5 days to obtain
                        confirmation from the teacher and system checks before
                        full refund to take place. Please see Section 9 for
                        conditions when user may be suspended from Plassroom.
                    </div>

                    <div className={styles.bodyContainer}>
                        Prices for future classes (ie. not yet published in the
                        Plassroom online store) are subject to change by teacher
                        users without notice. Teachers reserve the right at any
                        time to modify or discontinue classes (or any part or
                        content thereof) without notice at any time.
                    </div>

                    <div className={styles.bodyContainer}>
                        Plassroom reserve the right to modify the commission
                        rate of 20% upon all future classes excluding classes
                        already published on the platform. Plassroom will inform
                        all teachers via email of this change if this occurs and
                        will provide a notice of at least 2 weeks before the
                        change occurs. After which, upon publishing more
                        classes, teachers are deemed to have accepted this
                        change.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION
                    </div>

                    <div className={styles.bodyContainer}>
                        You agree to provide current, complete and accurate
                        personal information on your account at all times. You
                        agree to promptly update your account and other
                        information for us to maintain communication with you.
                        Plassroom commits that no personal information will be
                        accessed by any staff unless required for investigation
                        or communication purposes and this will be made known to
                        the user. Plassroom will not share any personal
                        information with any third party in a way that is
                        identifiable to a single user. Please see our Privacy
                        Policy for more details.
                    </div>

                    <div className={styles.bodyContainer}>
                        All teacher users are required to create and update
                        their bank account information on their Stripe wallet
                        before publishing any class and monies will be paid
                        through into the wallet in the currency of Pound
                        Sterling. Payout is triggered by teachers and available
                        once there is money within the wallet. Currency exchange
                        rates and any additional feels will be according to
                        rates provided by Stripe during time of payout to
                        teachers. Plassroom retains no bank account information
                        on its servers and receives no monies from Stripe in
                        relation to payouts.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 7 – RECORDINGS AND OTHER UPLOADED RESOURCES ON
                        THE PLATFORM
                    </div>

                    <div className={styles.bodyContainer}>
                        All uploaded resources, class related information are
                        created and thus own solely by teacher users on the
                        platform. You agree that all classes and related
                        information thereunder will not violate any right of any
                        third-party, including copyright, trademark, privacy or
                        other personal or proprietary right. Any such disputes
                        will be solely borne by users. Plassroom reserves the
                        right to immediately remove these material from the
                        website or Service until the dispute has been resolved.
                    </div>

                    <div className={styles.bodyContainer}>
                        Plassroom has a policy of recording all live
                        interactions between teachers and pupils. All class
                        recordings remain securely on our software provider’s
                        servers, with limited access by employees or any related
                        parties of Plassroom unless for quality assurance,
                        investigation or safeguarding purposes. Any user who
                        needs to access these recordings will need to submit a
                        request to Plassroom via{" "}
                        <a href="mailto:enquiry@plassroom.com">
                            enquiry@plassroom.com
                        </a>{" "}
                        and the request will be assessed to be legitimate and
                        based on the above reasons. You agree that all decisions
                        made by Plassroom are final and binding on all parties.
                    </div>

                    <div className={styles.bodyContainer}>
                        You agree to Plassroom’s use for class related and
                        teacher profile related material such as graphics,
                        video, text excerpts, reviews and comments etc. that you
                        have uploaded onto the platform to be used for our
                        marketing purposes on the website and social media
                        without an express request for permission to use by us.
                        We will not include any contact details of a specific
                        user in our marketing materials.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 8 – USER COMMENTS, FEEDBACK AND OTHER
                        SUBMISSIONS
                    </div>

                    <div className={styles.bodyContainer}>
                        If, at our request, you send certain specific
                        submissions (for example comments, reviews or feedback
                        to a Service) or without a request from us you send
                        creative ideas, suggestions, proposals, plans, or other
                        materials, whether online, by email, by postal mail, or
                        otherwise (collectively, 'comments'), you agree that we
                        may, at any time, without restriction, edit, copy,
                        publish, distribute, translate and otherwise use in any
                        medium any comments that you forward to us. We are and
                        shall be under no obligation (1) to maintain any
                        comments in confidence; (2) to pay compensation for any
                        comments; or (3) to respond to any comments. We may, but
                        have no obligation to, monitor, edit or remove content
                        that we determine in our sole discretion are unlawful,
                        offensive, threatening, libelous, defamatory,
                        pornographic, obscene or otherwise objectionable or
                        violates any party’s intellectual property or these
                        Terms of Service.
                    </div>

                    <div className={styles.bodyContainer}>
                        You agree that your comments will not violate any right
                        of any third-party, including copyright, trademark,
                        privacy, personality or other personal or proprietary
                        right. You further agree that your comments will not
                        contain libelous or otherwise unlawful, abusive or
                        obscene material, or contain any computer virus or other
                        malware that could in any way affect the operation of
                        the Service or any related website. Teacher users can
                        report these comments and reviews and Plassroom reserve
                        the right to remove them from being published on the
                        site. You may not use a false e-mail address, pretend to
                        be someone other than yourself, include any links to
                        direct viewers out of the system, share any contact
                        details or otherwise mislead us or third-parties as to
                        the origin of any comments. You are solely responsible
                        for any comments you make and their accuracy. We take no
                        responsibility and assume no liability for any comments
                        posted by you or any third-party.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 9 - PROHIBITED USES
                    </div>

                    <div className={styles.bodyContainer}>
                        In addition to other prohibitions as set forth in the
                        Terms of Service, you are prohibited from using the site
                        or its content:
                        <br />
                        (a) for any unlawful purpose; (b) to solicit others to
                        perform or participate in any unlawful acts; (c) to
                        violate any international, federal, provincial or state
                        regulations, rules, laws, or local ordinances; (d) to
                        infringe upon or violate our intellectual property
                        rights or the intellectual property rights of others;
                        (e) to harass, abuse, insult, harm, defame, slander,
                        disparage, intimidate, or discriminate based on gender,
                        sexual orientation, religion, ethnicity, race, age,
                        national origin, or disability; (f) to submit false or
                        misleading information; (g) to upload or transmit
                        viruses or any other type of malicious code that will or
                        may be used in any way that will affect the
                        functionality or operation of the Service or of any
                        related website, other websites, or the Internet; (h) to
                        collect or track the personal information of others; (i)
                        to spam, phish, pharm, pretext, spider, crawl, or
                        scrape; (j) for any obscene or immoral purpose; (k) any
                        content which restricts or inhibits any other person
                        from using or enjoying the Service, or which may expose
                        Plassroom or its users to any harm or liability in any
                        type or (l) to interfere with or circumvent the security
                        features of the Service or any related website, other
                        websites, or the Internet. We reserve the right to
                        terminate your use of the Service and Account for
                        violating any of the prohibited uses.
                    </div>

                    <div className={styles.bodyContainer}>
                        Teacher users are not allowed to promote or direct users
                        to a third party website for payment or service
                        purchases that would substitute the users use on
                        Plassroom. Users should not excessively cancel classes.
                        Plassroom reserves the right to investigate if matters
                        related to these issues have been raised or found to be
                        happening amongst users on Plassroom.
                    </div>

                    <div className={styles.bodyContainer}>
                        Plassroom reserves all right to suspend with a view of
                        terminating an account that is related to a dispute or
                        internal investigation of all the above matters.
                        Plassroom will notify the user via email connected to
                        the account to determine if any fault related to, but
                        not limited to the above listed prohibited uses has
                        occurred. If it has, Plassroom will then notify user of
                        the result, and compensation process to take place.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 10 - DISCLAIMER OF WARRANTIES; LIMITATION OF
                        LIABILITY
                    </div>

                    <div className={styles.bodyContainer}>
                        We do not guarantee, represent or warrant that your use
                        of our service will be uninterrupted, timely, secure or
                        error-free.
                    </div>

                    <div className={styles.bodyContainer}>
                        We do not warrant that the results that may be obtained
                        from the use of the service will be accurate or
                        reliable. You expressly agree that your use of, or
                        inability to use, the service is at your sole risk.
                    </div>

                    <div className={styles.bodyContainer}>
                        You agree that from time to time we may remove the
                        service for indefinite periods of time or cancel the
                        service at any time, without notice to you.
                    </div>

                    <div className={styles.bodyContainer}>
                        In no case shall Plassroom, our directors, officers,
                        employees, affiliates, agents, contractors, interns,
                        suppliers, service providers or licensors be liable for
                        any injury, loss, claim, or any direct, indirect,
                        incidental, punitive, special, or consequential damages
                        of any kind, including, without limitation lost profits,
                        lost revenue, lost savings, loss of data, replacement
                        costs, or any similar damages, whether based in
                        contract, tort (including negligence), strict liability
                        or otherwise, arising from your use of any of the
                        Service or any products procured using the Service, or
                        for any other claim related in any way to your use of
                        the service or any product, including, but not limited
                        to, any errors or omissions in any content, or any loss
                        or damage of any kind incurred as a result of the use of
                        the service or any content (or product) posted,
                        transmitted, or otherwise made available via the
                        service, even if advised of their possibility.
                    </div>

                    <div className={styles.bodyContainer}>
                        Because some states or jurisdictions do not allow the
                        exclusion or the limitation of liability for
                        consequential or incidental damages, in such states or
                        jurisdictions, our liability shall be limited to the
                        maximum extent permitted by law.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 11 - INDEMNIFICATION
                    </div>

                    <div className={styles.bodyContainer}>
                        You agree to indemnify, defend and hold harmless
                        Plassroom and our parent, subsidiaries, affiliates,
                        partners, officers, directors, agents, contractors,
                        licensors, service providers, subcontractors, suppliers,
                        interns and employees, harmless from any claim or
                        demand, including reasonable attorneys’ fees, made by
                        any third-party due to or arising out of your breach of
                        these Terms of Service or the documents they incorporate
                        by reference, or your violation of any law or the rights
                        of a third-party.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 12 - SEVERABILITY
                    </div>

                    <div className={styles.bodyContainer}>
                        In the event that any provision of these Terms of
                        Service is determined to be unlawful, void or
                        unenforceable, such provision shall nonetheless be
                        enforceable to the fullest extent permitted by
                        applicable law, and the unenforceable portion shall be
                        deemed to be severed from these Terms of Service, such
                        determination shall not affect the validity and
                        enforceability of any other remaining provisions.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 13 - TERMINATION
                    </div>

                    <div className={styles.bodyContainer}>
                        The obligations and liabilities of the parties incurred
                        prior to the termination date shall survive the
                        termination of this agreement for all purposes.
                    </div>

                    <div className={styles.bodyContainer}>
                        These Terms of Service are effective unless and until
                        terminated by either you or us. You may terminate these
                        Terms of Service at any time by notifying us that you no
                        longer wish to use our Services, or when you cease using
                        our site.
                    </div>

                    <div className={styles.bodyContainer}>
                        If in our sole judgment you fail, or we suspect that you
                        have failed, to comply with any term or provision of
                        these Terms of Service, we also may terminate this
                        agreement at any time without notice and you will remain
                        liable for all amounts due, including due compensation
                        to loss that Plassroom will bear in relation to your
                        actions up to and including the date of termination;
                        and/or accordingly may deny you access to our Services
                        (or any part thereof).
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 14 - ENTIRE AGREEMENT
                    </div>

                    <div className={styles.bodyContainer}>
                        The failure of us to exercise or enforce any right or
                        provision of these Terms of Service shall not constitute
                        a waiver of such right or provision.
                    </div>

                    <div className={styles.bodyContainer}>
                        These Terms of Service and any policies or operating
                        rules posted by us on this site or in respect to The
                        Service constitutes the entire agreement and
                        understanding between you and us and govern your use of
                        the Service, superseding any prior or contemporaneous
                        agreements, communications and proposals, whether oral
                        or written, between you and us (including, but not
                        limited to, any prior versions of the Terms of Service).
                    </div>

                    <div className={styles.bodyContainer}>
                        Any ambiguities in the interpretation of these Terms of
                        Service shall not be construed against the drafting
                        party.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 15 - GOVERNING LAW, JURISDICTION AND COMPLAINTS
                    </div>

                    <div className={styles.bodyContainer}>
                        These Terms of Service and any separate agreements
                        whereby we provide you Services shall be governed by and
                        construed in accordance with the laws of England and
                        Wales.
                    </div>

                    <div className={styles.bodyContainer}>
                        Disputes can be submitted to the jurisdiction of the
                        courts of England and Wales, or, where the User lives in
                        Scotland or Northern Ireland, in the courts of
                        respectively Scotland or Northern Ireland.
                    </div>

                    <div className={styles.bodyContainer}>
                        We try to avoid any dispute, so we deal with complaints
                        as follows:
                    </div>

                    <div className={styles.bodyContainer}>
                        Complaints about class, teacher related matters or
                        refunds should be directed immediately to the teacher in
                        question through the messages function. If there are
                        more specific methods addressed in this document
                        regarding an escalation process, the specific method
                        stated is to be deemed as priority.
                    </div>

                    <div className={styles.bodyContainer}>
                        Any complaint directed to Plassroom regarding these
                        matters will be channelled to the teacher in question.
                        Teacher has sole discretion of dealing with these
                        complaints and Plassroom only acts to pass on the
                        message to the teacher, unless where illegality,
                        misconduct, misrepresentation etc. (see above for full
                        list) matters have taken place. Plassroom will not take
                        responsibility of whether the complaint is addressed or
                        dealt with appropriately or not by the teacher.
                    </div>

                    <div className={styles.bodyContainer}>
                        If all methods of communication to the user have been
                        exhausted and reasonable amount of time given for reply
                        by the user in question, Plassroom will assist to the
                        best of its ability in further contacting the user via
                        the contact number in our records or via email. Any
                        updates will be given to the user to take the matter
                        further, including assisting with information required
                        for users to make a report to the legal authorities if
                        required. It is still the sole responsibility of users
                        to find a resolution method satisfactory to both parties
                        in a dispute.
                    </div>

                    <div className={styles.bodyContainer}>
                        SECTION 16 - CONTACT INFORMATION
                    </div>

                    <div className={styles.bodyContainer}>
                        Questions about the Terms of Service should be sent to
                        us at{" "}
                        <a href="mailto:enquiry@plassroom.com">
                            enquiry@plassroom.com
                        </a>
                        .
                    </div>
                </div>

                <FooterNew />
            </div>
        </div>
    );
};

export default PrivacyPolicy;
