import { useHistory } from "react-router-dom";
import {
    faAngleDown,
    faAngleUp,
    faMinus,
    faShareAlt,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import _ from "lodash";
import moment from "moment-timezone";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Bag from "../../../assets/images/bag.png";
import Dashboard from "../../../assets/images/Dashboard@2x.png";
import Create from "../../../assets/images/Group 45.svg";
import reviewModal from "../../../assets/images/icon/accountSettings/reviewModal.svg";
import Back from "../../../assets/images/icon/back.svg";
import createClass from "../../../assets/images/icon/createClass.svg";
import DashboardText from "../../../assets/images/icon/dashboardText.svg";
import greyBag from "../../../assets/images/icon/grayImg/greyBag.svg";
import greyClassFiles from "../../../assets/images/class_file.png";
import greyMedals from "../../../assets/images/icon/grayImg/greyMedals.svg";
import greyMoments from "../../../assets/images/moments.svg";
import greyShelfS from "../../../assets/images/icon/grayImg/greyShelfS.svg";
import greyShelfT from "../../../assets/images/icon/grayImg/greyShelfT.svg";
import removeIcon from "../../../assets/images/icon/remove.svg";
import Setting from "../../../assets/images/icon/Settings.svg";
import SettingsText from "../../../assets/images/icon/SettingsText.svg";
import ChatIcon from "../../../assets/images/chat/chat.svg";
import ChatText from "../../../assets/images/chat/Chat word.svg";
import Store from "../../../assets/images/icon/Store.svg";
import StoreText from "../../../assets/images/icon/StoreText.svg";
import StudentLogoBg from "../../../assets/images/LOGO (3).svg";
import BeginStudent from "../../../assets/images/plass_begin_student.png";
import BeginTeacher from "../../../assets/images/plass_begin_teacher.png";
import Play from "../../../assets/images/play.png";
import Tasks from "../../../assets/images/tasks.png";
import Snackbar from "../../../components/Snackbar";
import styles from "./landing.module.css";
import TeacherLanding from "./MobileLanding/TeacherLanding";
import PageLoader from "../../../components/PageLoader";
import NavbarLanding from "../../../components/NavbarLanding";
import SubscriptionFooter from "../../../components/FooterNew/SubscriptionFooter";

export default () => {
    const history = useHistory();
    const [sessionID, setSessionID] = useState(undefined);
    const [lastSession, setLastSession] = useState();
    const [showDashboardModal, setShowDashboardModal] = useState(false);
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [sessions, setSessions] = useState();
    const [singleRow, setSingleRow] = useState({
        isSingleRow: false,
        index: null,
        action: "",
    });
    let studentList = [];
    const [email, setEmail] = useState("");
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;
    const savedId = JSON.parse(localStorage.getItem("auth"))?.user?._id;
    const reviewPopupCount = JSON.parse(
        localStorage?.getItem("reviewPopupCount")
    );
    const sessionRows = [];
    let actionBox = "";
    let studentsForCurrentSession = [];
    const [user, setUser] = useState();
    const [showStudentsDropdown, setShowStudentsDropdown] = useState(false);
    const [selectedStudentIndex, setSelectedStudentIndex] = useState(0);
    let playAccess = false;
    const [showStudentCalendarModal, setShowStudentCalendarModal] =
        useState(false);
    const studentSessionRows = [];
    let studentUpcomingOrOngoingSessions = [];
    const [sessionPending, setSessionPending] = useState("");
    const [snackbarStatus, setSnackbarStatus] = useState("");
    const [deletePopup, setDeletePopup] = useState(false);
    const [deleteUser, setDeleteUser] = useState({});
    const [tasksList, setTasksList] = useState([]);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);

        return (_) => {
            window.removeEventListener("resize", handleResize);
        };
    });

    /**
     * @description Fetch unread messages count.
     */
    useEffect(() => {
        handleFetchUnreadMessageCount();

        const delayDebounceFunction = setInterval(() => {
            handleFetchUnreadMessageCount();
        }, 60000);

        return () => clearInterval(delayDebounceFunction);
        // eslint-disable-next-line
    }, []);

    const handleFetchUnreadMessageCount = () => {
        axios
            .get("/api/v1/chat/unread-message-count")
            .then((response) => {
                if (_.get(response, "data.success")) {
                    setUnreadMessageCount(
                        _.get(response, "data.unreadMessageCount", 0)
                    );
                } else {
                    setMsg(
                        _.get(response, "data.title", "Something went wrong.")
                    );
                    setShowSnackbar(true);
                }
            })
            .catch((error) => {
                console.error(error.response);
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
            })
            .finally(() => {});
    };

    /**
     * @description Initiate individual chat by student.
     */
    const handleInitiateChat = (classDetails) => {
        history.push({
            pathname: "/chat",
            state: {
                classDetails,
            },
        });
    };

    /**
     * @description Initiate group chat by teachers.
     */
    const handleInitializeGroupChat = (session) => {
        history.push({
            pathname: "/chat",
            state: {
                classDetails: session,
                type: "group",
            },
        });
    };

    useEffect(() => {
        const getUserTasks = async () => {
            await axios
                .get("/api/v1/user/tasks")
                .then((response) => {
                    if (response?.data?.success) {
                        // console.log(response.data.data);
                        setTasksList(response.data.data);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };

        getUserTasks();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchPendingReviews();
        getSessionInfo();
        const getUserDetails = async () => {
            await axios
                .get("/api/v1/user")
                .then((result) => {
                    setUser(result.data.user);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        getSessionInfo();
        getUserDetails();
        const sessionIntervalObj = setInterval(getSessionInfo, 30000);

        return () => {
            clearInterval(sessionIntervalObj);
        };
        // eslint-disable-next-line
    }, []);

    const getSessionInfo = async () => {
        await axios
            .get(`/api/v1/sessions-current`)
            .then(async (currentSessionsResult) => {
                await axios
                    .get(`/api/v1/sessions/students`)
                    .then(async (allUserSessionsResult) => {
                        if (
                            currentSessionsResult?.data?.success &&
                            allUserSessionsResult?.data?.success
                        ) {
                            const lastSession =
                                currentSessionsResult?.data?.sessions[
                                    currentSessionsResult?.data?.sessions
                                        .length - 1
                                ];
                            setSessions(allUserSessionsResult?.data?.sessions);
                            if (lastSession) {
                                setLastSession(lastSession);
                                const id = lastSession._id;
                                setSessionID(id);
                            }
                        }
                    })
                    .catch((error) => {
                        setMsg(
                            _.get(
                                error,
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                        return Promise.reject(error);
                    });
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const fetchPendingReviews = () => {
        axios
            .get("api/v1/ratingAndReviews/pending")
            .then(async (response) => {
                let currentUserPending =
                    response?.data?.data?.pendingRatingAndReviewsData[0]?.students_ids?.filter(
                        (data) => {
                            return data?.id == savedId;
                        }
                    );
                setSessionPending(
                    response?.data?.data?.pendingRatingAndReviewsData[0]?._id
                );

                return currentUserPending;
            })
            .then((data) => {
                if (
                    data[0]?.is_Attended == true &&
                    data[0]?.is_rated == false &&
                    data[0]?.rate_popup_count >= 0 &&
                    data[0]?.rate_popup_count < 2
                ) {
                    setShowReviewModal(true);
                } else {
                    setShowReviewModal(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const increaseCount = () => {
        // PUT session/:id/rate/popup-count/increase
        axios
            .put(`api/v1/session/${sessionPending}/rate/popup-count/increase`)
            .then((response) => {
                // console.log(response);
            })
            .catch((error) => {
                // console.log(error);
            });
    };

    const getUpdatedSessions = async () => {
        await axios
            .get(`/api/v1/sessions/students`)
            .then((result) => {
                if (result.data.success) {
                    setSessions(result.data.sessions);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    function calculateAge(date) {
        // birthday is a date
        var birthDate = new Date(date);
        var ageDifMs = Date.now() - birthDate.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    const handlePlay = () => {
        console.log(user);
        const identifier =
            user?.students[selectedStudentIndex]?.studentpreferedname ||
            user?.students[0]?.studentpreferedname ||
            user?.preferedName;
        window.location.href = `/session/${sessionID}/play?identifier=${btoa(
            identifier
        )}`;

        // if (currentUser.role === "student") {
        //   let findid = lastSession.students_ids.find(
        //     (element) => element._id === currentUser._id
        //   );
        //   console.log(lastSession.students_ids);
        //   console.log(currentUser);

        //   if (findid) {
        //     window.location.href = `/session/${sessionID}/play`;
        //   } else {
        //     alert("student not assign to this session");
        //   }
        // } else {
        //   window.location.href = `/session/${sessionID}/play`;
        // }
    };

    const handleDeleteStudent = async (parentId, sessionIdVal, studentId) => {
        const sessionId = deleteUser?.sessionId;
        if (
            deleteUser?.studentId &&
            deleteUser?.sessionId &&
            deleteUser?.parentId
        ) {
            await axios
                .delete(
                    `/api/v1/session/student/${deleteUser?.studentId}?parentId=${deleteUser?.parentId}`,
                    { data: { sessionId } }
                )
                .then((res) => {
                    getUpdatedSessions();
                    setDeletePopup(false);
                    getUpdatedSessions();

                    if (res?.data?.success == false) {
                        setMsg(
                            _.get(
                                res?.data?.message,
                                "res?.data?.message",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    } else if (res?.data?.success == true) {
                        getUpdatedSessions();
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        }
    };

    let teacherUpcomingOrOngoingSessions = [];

    if (currentUserRole === "teacher") {
        if (lastSession) {
            playAccess = true;
        }

        if (sessions) {
            teacherUpcomingOrOngoingSessions = sessions.filter((session) => {
                const duration = {
                    hours:
                        session.configuration.session_duration > 60
                            ? Math.floor(
                                  session.configuration.session_duration / 60
                              )
                            : 0,
                    minutes: session.configuration.session_duration % 60,
                };
                const currentTime = moment();
                const sessionEndTime = moment
                    .utc(session.configuration.session_start_time)
                    .local()
                    .add(duration);
                return currentTime < sessionEndTime;
            });
            teacherUpcomingOrOngoingSessions.forEach((session, index) => {
                // console.log("SESSION IS ", session);
                const url = `${window.location.href}class-details/${session?.class_name_slug}`;
                const startTimeTemp = moment
                    .utc(session?.configuration?.session_start_time)
                    .format("DD/MM/YYYY hh:mm a");
                const startDate = moment
                    .utc(startTimeTemp, "DD/MM/YYYY hh:mm a")
                    .local()
                    .format("DD MMM");
                const startTime = moment
                    .utc(startTimeTemp, "DD/MM/YYYY hh:mm a")
                    .local()
                    .format("HH:mma zz");
                const duration = {
                    hours:
                        session?.configuration?.session_duration > 59
                            ? Math.floor(
                                  session?.configuration?.session_duration / 60
                              ).toString() + " hour"
                            : "",
                    minutes:
                        (
                            session?.configuration?.session_duration % 60
                        ).toString() + " minutes",
                };
                let registeredStudentsNo = 0;
                session.students_ids.forEach(
                    (parent) => (registeredStudentsNo += parent.students.length)
                );
                sessionRows.push(
                    <div key={startDate}>
                        <Row className={styles.classRow}>
                            <Col xs={2} className={styles.classDetail}>
                                {startDate}
                            </Col>
                            <Col xs={3} className={styles.classDetail}>
                                {session.class_name}
                                <FontAwesomeIcon
                                    icon={faShareAlt}
                                    style={{ marginLeft: "8%" }}
                                    hidden={!singleRow?.isSingleRow}
                                    onClick={() => {
                                        navigator.clipboard.writeText(url);
                                        setSnackbarStatus("information");
                                        setMsg(
                                            "Class link has been copied onto clipboard"
                                        );
                                        setShowSnackbar(true);
                                        setTimeout(function () {
                                            setMsg(null);
                                        }, 3000);
                                    }}
                                />
                            </Col>
                            <Col xs={3} className={styles.classDetail}>
                                {startTime} ({duration.hours} {duration.minutes}
                                )
                            </Col>
                            <Col xs={4} className={styles.classDetail}>
                                {registeredStudentsNo} of{" "}
                                {session.configuration.max_students}
                                {registeredStudentsNo ? (
                                    <img
                                        src={ChatIcon}
                                        className={styles.chatIcon}
                                        onClick={() => {
                                            handleInitializeGroupChat(session);
                                        }}
                                        alt=""
                                    />
                                ) : null}
                                {/* <img
                                src={Remove}
                                className={styles.minusIcon}
                                onClick={() => {
                                    setSingleRow({
                                        isSingleRow: true,
                                        index: index,
                                        action: "remove",
                                    });
                                }}
                                alt=""
                            ></img> */}
                                <FontAwesomeIcon
                                    style={{
                                        transform: [{ rotateY: "180deg" }],
                                    }}
                                    icon={
                                        singleRow?.isSingleRow
                                            ? faAngleUp
                                            : faAngleDown
                                    }
                                    className={styles.dropdownIcon}
                                    onClick={() => {
                                        singleRow?.isSingleRow
                                            ? setSingleRow({
                                                  isSingleRow: false,
                                                  index: index,
                                                  action: "",
                                              })
                                            : setSingleRow({
                                                  isSingleRow: true,
                                                  index: index,
                                                  action: "add",
                                              });
                                    }}
                                />
                                {/* <img
                                src={Edit}
                                alt=""
                            ></img> */}
                            </Col>
                        </Row>
                    </div>
                );
            });

            if (singleRow.isSingleRow) {
                const ageGroup =
                    teacherUpcomingOrOngoingSessions[
                        singleRow.index
                    ]?.configuration?.age_group.split("-");
                const sessionId =
                    teacherUpcomingOrOngoingSessions[singleRow.index]?._id;
                const newStudentsForCurrentSession = [];
                let intArray = [];
                for (let i = 0; i < ageGroup?.length; i++) {
                    intArray[i] = parseInt(ageGroup[i]);
                }
                const newStudentList = (
                    <div style={{ width: "100%" }}>
                        <table
                            className={`table`}
                            style={{
                                borderCollapse: "collapse",
                                border: "none !important",
                                marginLeft: "1%",
                            }}
                        >
                            <thead>
                                {teacherUpcomingOrOngoingSessions[
                                    singleRow.index
                                ]?.students_ids.length > 0 && (
                                    <tr className="text-center">
                                        <th style={{ width: "40%" }}>
                                            Student name
                                        </th>
                                        <th style={{ width: "20%" }}>Age</th>
                                        <th>Location</th>
                                    </tr>
                                )}
                            </thead>
                        </table>
                        {teacherUpcomingOrOngoingSessions[singleRow.index]
                            ?.students_ids.length > 0 && (
                            <div
                                style={{
                                    width: "98%",
                                    border: "2px solid #ccc",
                                    borderRadius: "21px",
                                    marginLeft: "2%",
                                }}
                            >
                                <table
                                    className={`${styles.mytable} ${styles.table}`}
                                    style={{
                                        borderCollapse: "collapse",
                                        width: "100%",
                                    }}
                                >
                                    <tbody>
                                        {teacherUpcomingOrOngoingSessions[
                                            singleRow.index
                                        ].students_ids.map((parent, index) => {
                                            const country = parent?.id?.country
                                                ? `${parent?.id?.country}`
                                                : "-";
                                            const state = parent?.id?.state
                                                ? `${parent?.id?.state},`
                                                : "";
                                            const sNo = index + 1;
                                            const parentId = parent?._id;
                                            const studentId =
                                                parent?.id?.students[0]?._id;
                                            const birthDate =
                                                parent?.id?.students[0]
                                                    ?.studentbirthdate;
                                            const userName =
                                                parent?.id?.students[0]
                                                    ?.studentname;
                                            const calculatedAge =
                                                birthDate &&
                                                calculateAge(birthDate);
                                            const registeredStudentIds =
                                                parent.students.map(
                                                    (student) => {
                                                        return student.id;
                                                    }
                                                );
                                            const studentNames = [];

                                            registeredStudentIds.forEach(
                                                (id) => {
                                                    const studentDetails =
                                                        parent?.id?.students.find(
                                                            (student) => {
                                                                return (
                                                                    student?._id ===
                                                                    id
                                                                );
                                                            }
                                                        );
                                                    studentNames.push(
                                                        studentDetails?.studentname
                                                    );
                                                    newStudentsForCurrentSession.push(
                                                        {
                                                            parentObjId:
                                                                parent._id,
                                                            studentName:
                                                                studentDetails?.studentname,
                                                            studentObjId:
                                                                studentDetails?._id,
                                                        }
                                                    );
                                                }
                                            );
                                            const listItems = studentNames.map(
                                                (studentName, i) => {
                                                    return (
                                                        <tr
                                                            style={{
                                                                borderBottom:
                                                                    "1px solid #ccc",
                                                            }}
                                                            key={i}
                                                        >
                                                            <td
                                                                style={{
                                                                    width: "40%",
                                                                }}
                                                                className={
                                                                    styles.tableData
                                                                }
                                                            >
                                                                {`${sNo}. ${userName}`}
                                                                <span
                                                                    className={
                                                                        styles.removeTag
                                                                    }
                                                                    // hidden={
                                                                    //     calculatedAge >= intArray[0] && calculatedAge <= intArray[1]
                                                                    // }

                                                                    onClick={() => {
                                                                        setDeletePopup(
                                                                            true
                                                                        );
                                                                        setDeleteUser(
                                                                            {
                                                                                parentId:
                                                                                    parentId,
                                                                                sessionId:
                                                                                    sessionId,
                                                                                studentId:
                                                                                    studentId,
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    Remove
                                                                </span>
                                                            </td>
                                                            <td
                                                                className={`text-center ${styles.tableData}`}
                                                                style={{
                                                                    width: "20%",
                                                                }}
                                                            >
                                                                {calculatedAge
                                                                    ? calculatedAge
                                                                    : "-"}
                                                            </td>
                                                            <td
                                                                className={`text-center ${styles.tableData}`}
                                                            >
                                                                {`${state} ${country}`}
                                                            </td>
                                                        </tr>

                                                        // <li>
                                                        //     <span
                                                        //         className={styles.parentNames}
                                                        //     >
                                                        //         {studentName}
                                                        //     </span>{" "}
                                                        //     {parent.id.email}
                                                        // </li>
                                                    );
                                                }
                                            );
                                            return listItems;
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                );
                studentsForCurrentSession = newStudentsForCurrentSession;
                studentList = newStudentList;
            }
        }

        const handleEmailInputChange = (e) => {
            setEmail(e.target.value);
        };

        const handleCreateInvite = async (e, sessionId) => {
            e.preventDefault();
            await axios
                .post("/api/v1/invite", {
                    inviteeEmail: email,
                    sessionId: sessionId,
                })
                .then((result) => {
                    const url = `${process.env.REACT_APP_FRONTEND_URL}/invites/${result.data.data.inviteId}`;
                    setEmail(url);
                    navigator.clipboard.writeText(url);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };

        const CleanDropdown = ({ handleSubmit, studentsForCurrentSession }) => {
            const [dropdown, setDropdown] = useState();
            const [selectedValueIndex, setSelectedValueIndex] = useState(0);
            return (
                <>
                    {!dropdown ? (
                        <div className={styles.inviteLink}>
                            <span
                                onClick={() => {
                                    setDropdown(true);
                                }}
                                className={styles.link}
                            >
                                {
                                    studentsForCurrentSession[
                                        selectedValueIndex
                                    ]?.studentName
                                }
                            </span>
                            <div className={styles.removeStudentIcon}>
                                <FontAwesomeIcon
                                    icon={faMinus}
                                    onClick={() => {
                                        handleSubmit(
                                            studentsForCurrentSession[
                                                selectedValueIndex
                                            ].parentObjId,
                                            teacherUpcomingOrOngoingSessions[
                                                singleRow.index
                                            ]._id,
                                            studentsForCurrentSession[
                                                selectedValueIndex
                                            ].studentObjId
                                        );
                                    }}
                                    className={styles.minusFaIcon}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className={styles.inviteLink}>
                            {studentsForCurrentSession.map((student, index) => {
                                return (
                                    <p
                                        onClick={() => {
                                            setDropdown(false);
                                            setSelectedValueIndex(index);
                                        }}
                                        className={styles.dropdownItem}
                                        key={index}
                                    >
                                        {student.studentName}
                                    </p>
                                );
                            })}
                        </div>
                    )}
                </>
            );
        };

        if (singleRow.action === "add") {
            const inviteBox = "";
            // <Row className={styles.invitationBox}>
            //     <p className={styles.dashboardColHeading}>
            //         Send out invitation to class
            //     </p>
            //     <p className={styles.invitationBoxText}>
            //         Copy and paste this link to a prospective parent and
            //         they will be taken through the process of registering
            //         for this class.
            //     </p>
            //     <div className={styles.inviteLink}>
            //         <form
            //             onSubmit={(e) =>
            //                 handleCreateInvite(
            //                     e,
            //                     teacherUpcomingOrOngoingSessions[
            //                         singleRow.index
            //                     ]._id
            //                 )
            //             }
            //             className={styles.addStudentForm}
            //         >
            //             <input
            //                 className={styles.emailInput}
            //                 onChange={(e) => handleEmailInputChange(e)}
            //                 type="text"
            //                 placeholder="Enter email"
            //                 value={email}
            //             />
            //             <button className={styles.copyButton} type="submit">
            //                 <img
            //                     src={Copy}
            //                     className={styles.copyIcon}
            //                     alt=""
            //                 ></img>
            //             </button>
            //         </form>
            //     </div>
            // </Row>
            // );
            actionBox = inviteBox;
        } else if (singleRow.action === "remove") {
            const removeBox = (
                <Row className={styles.invitationBox}>
                    <p className={styles.dashboardColHeading}>
                        Remove student from class
                    </p>
                    <p className={styles.invitationBoxText}>
                        An email will be sent out notifying the parent that the
                        student will no longer be allowed to attend the session.
                    </p>
                    <CleanDropdown
                        handleSubmit={handleDeleteStudent}
                        studentsForCurrentSession={studentsForCurrentSession}
                    ></CleanDropdown>
                </Row>
            );
            actionBox = removeBox;
        }
    } else {
        let isStudentRegisteredInTheClass = false;
        let hasTeacherEntered = false;

        if (user?.students[selectedStudentIndex] && lastSession) {
            const selectedStudentId = user.students[selectedStudentIndex]._id;

            lastSession.students_ids.forEach((parent) => {
                parent.students.forEach((student) => {
                    if (student.id === selectedStudentId)
                        isStudentRegisteredInTheClass = true;
                });
            });

            hasTeacherEntered = lastSession.teacher_entered;
        }

        playAccess = isStudentRegisteredInTheClass && hasTeacherEntered;

        if (sessions && user) {
            const currentStudentSessions = sessions.filter((session) => {
                let foundStudent = false;
                session.students_ids.forEach((parent) => {
                    const index = parent.students.findIndex((student) => {
                        return (
                            student.id ===
                            user.students[selectedStudentIndex]._id
                        );
                    });
                    if (index !== -1) foundStudent = true;
                });
                return foundStudent;
            });

            studentUpcomingOrOngoingSessions = currentStudentSessions.filter(
                (session) => {
                    const duration = {
                        hours:
                            session.configuration.session_duration > 59
                                ? Math.floor(
                                      session.configuration.session_duration /
                                          60
                                  )
                                : 0,
                        minutes: session.configuration.session_duration % 60,
                    };
                    const currentTime = moment();
                    const sessionEndTime = moment
                        .utc(session.configuration.session_start_time)
                        .local()
                        .add(duration);
                    return currentTime < sessionEndTime;
                }
            );

            studentUpcomingOrOngoingSessions.forEach((session) => {
                const startTimeTemp = moment
                    .utc(session.configuration.session_start_time)
                    .format("DD/MM/YYYY hh:mm a");
                const startDate = moment
                    .utc(startTimeTemp, "DD/MM/YYYY hh:mm a")
                    .local()
                    .format("DD MMM");
                const startTime = moment
                    .utc(startTimeTemp, "DD/MM/YYYY hh:mm a")
                    .local()
                    .format("HH:mma zz");
                const duration = {
                    hours:
                        session.configuration.session_duration > 59
                            ? Math.floor(
                                  session.configuration.session_duration / 60
                              ).toString() + " hours"
                            : "",
                    minutes:
                        (
                            session.configuration.session_duration % 60
                        ).toString() + " minutes",
                };
                let registeredStudentsNo = 0;
                session.students_ids.forEach(
                    (parent) => (registeredStudentsNo += parent.students.length)
                );
                studentSessionRows.push(
                    <div>
                        <Row className={styles.classRow}>
                            <Col xs={2} className={styles.classDetail}>
                                {startDate}
                            </Col>
                            <Col xs={3} className={styles.classDetail}>
                                {session.class_name}
                            </Col>
                            <Col xs={3} className={styles.classDetail}>
                                {startTime} ({duration.hours} {duration.minutes}
                                )
                            </Col>
                            <Col xs={4} className={styles.classDetail}>
                                {registeredStudentsNo} of{" "}
                                {session.configuration.max_students}
                                {registeredStudentsNo ? (
                                    <img
                                        src={ChatIcon}
                                        className={styles.chatIcon}
                                        onClick={() => {
                                            handleInitiateChat(session);
                                        }}
                                        alt=""
                                    />
                                ) : null}
                                {/* <img
                                    src={Remove}
                                    className={`${styles.minusIcon} ${styles.minusIconStudentModal}`}
                                    onClick={() => {
                                        handleDeleteStudent(
                                            user._id,
                                            session._id,
                                            user.students[0]._id
                                        );
                                    }}
                                    alt=""
                                ></img> */}
                            </Col>
                        </Row>
                    </div>
                );
            });
        }
    }

    const StudentsDropdown = () => {
        return (
            <>
                <div
                    className={`${styles.studentsDropdown} ${
                        showStudentsDropdown
                            ? styles.showStudentsDropdown
                            : null
                    }`}
                >
                    <p key={`${selectedStudentIndex}`}>
                        {
                            user?.students[selectedStudentIndex]
                                ?.studentpreferedname
                        }
                        {showStudentsDropdown ? "" : "."}
                        <FontAwesomeIcon
                            icon={faAngleDown}
                            onClick={() =>
                                setShowStudentsDropdown(!showStudentsDropdown)
                            }
                            className={styles.angleDownFaIcon}
                        />
                    </p>
                    {showStudentsDropdown &&
                        user.students.map((obj, i) => {
                            if (i === selectedStudentIndex) {
                                return null;
                            } else {
                                return (
                                    <p
                                        key={`${i}`}
                                        className={styles.studentName}
                                        onClick={() => {
                                            setSelectedStudentIndex(i);
                                            setShowStudentsDropdown(
                                                !showStudentsDropdown
                                            );
                                        }}
                                    >
                                        {obj.studentpreferedname}
                                    </p>
                                );
                            }
                        })}
                </div>
            </>
        );
    };

    // playAccess = true; //

    return (
        <>
            {user ? <></> : <PageLoader open={true} />}
            {windowWidth < 600 ? (
                <TeacherLanding
                    user={user}
                    currentUserRole={currentUserRole}
                    listOfUpcomingOrOngoingSessions={
                        currentUserRole === "teacher"
                            ? teacherUpcomingOrOngoingSessions
                            : studentUpcomingOrOngoingSessions
                    }
                />
            ) : (
                <div style={{ height: "auto" }}>
                    {msg && (
                        <Snackbar
                            status={snackbarStatus || "error"}
                            message={msg}
                            open={showSnackbar}
                            setOpen={setShowSnackbar}
                        ></Snackbar>
                    )}
                    {deletePopup && (
                        <div
                            className={`${styles.uploadModal} text-center`}
                            style={{ top: "30%" }}
                        >
                            <FontAwesomeIcon
                                className={styles.crossIcon}
                                icon={faTimes}
                                onClick={() => {
                                    setDeletePopup(false);
                                }}
                            />
                            <div className={styles.uploadAreaBox}>
                                <p className={styles.modalHeading}>
                                    An email will be sent out notifying the
                                    parent that the student will be removed from
                                    class and register fee will be refunded.
                                </p>
                                <img
                                    src={removeIcon}
                                    onClick={() => {
                                        handleDeleteStudent();
                                    }}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </div>
                    )}
                    <div>
                        <NavbarLanding
                            userRole={currentUserRole}
                            userName={`Welcome, ${
                                !!user
                                    ? user?.students[0]?.studentpreferedname ||
                                      user?.preferedName
                                    : ""
                            }.`}
                            unreadMessageCount={unreadMessageCount}
                            setShowDashboardModal={setShowDashboardModal}
                        />
                    </div>
                    {currentUserRole === "student" && (
                        <div>
                            <Modal
                                contentClassName={styles.dashboardModalReview}
                                backdropClassName={
                                    styles.dashboardModalBackdrop
                                }
                                show={showReviewModal}
                                onHide={() => {
                                    setShowReviewModal(false);
                                    increaseCount();
                                }}
                                size="md"
                                centered
                            >
                                <Modal.Body>
                                    <div className="text-center">
                                        <div className={styles.uploadArea}>
                                            <p className={styles.modalHeading}>
                                                Would you like to rate and leave
                                                a review on the class that you
                                                just had? <br></br>Your feedback
                                                is very important to us.
                                            </p>
                                        </div>
                                        <Link
                                            to={{
                                                pathname: "/accountSettings",
                                                state: { tab: "classRatings" },
                                            }}
                                        >
                                            <img
                                                src={reviewModal}
                                                onClick={() => increaseCount()}
                                            />
                                        </Link>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    )}
                    {currentUserRole === "teacher" && (
                        <div>
                            <Modal
                                contentClassName={styles.dashboardModal}
                                backdropClassName={
                                    styles.dashboardModalBackdrop
                                }
                                show={showDashboardModal}
                                onHide={() => {
                                    setShowDashboardModal(false);
                                }}
                                keyboard={false}
                                size="xl"
                                centered
                            >
                                <Modal.Body>
                                    <Row className={styles.dashboardHeader}>
                                        <Col
                                            className={styles.dashboardHeading}
                                        >
                                            Teacher Dashboard
                                        </Col>
                                        <Col>
                                            <img
                                                className={`${styles.createClassButton}`}
                                                onClick={() => {
                                                    if (
                                                        !singleRow.isSingleRow
                                                    ) {
                                                        setShowDashboardModal(
                                                            false
                                                        );
                                                        history.push(
                                                            "/sessions"
                                                        );
                                                    } else {
                                                        setSingleRow({
                                                            isSingleRow: false,
                                                            action: "",
                                                            index: null,
                                                        });
                                                    }
                                                }}
                                                src={
                                                    !singleRow.isSingleRow
                                                        ? createClass
                                                        : Back
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            width: "95%",
                                            marginLeft: "1.5%",
                                        }}
                                    >
                                        <Col
                                            xs={2}
                                            className={
                                                styles.dashboardColHeading
                                            }
                                        >
                                            Date
                                        </Col>
                                        <Col
                                            xs={3}
                                            className={
                                                styles.dashboardColHeading
                                            }
                                        >
                                            Name of class
                                        </Col>
                                        <Col
                                            xs={3}
                                            className={
                                                styles.dashboardColHeading
                                            }
                                        >
                                            Time (Duration)
                                        </Col>
                                        <Col
                                            xs={4}
                                            className={
                                                styles.dashboardColHeading
                                            }
                                        >
                                            Students registered
                                        </Col>
                                    </Row>
                                    <div className={styles.scrollContainer}>
                                        {singleRow.isSingleRow ? (
                                            <>
                                                {sessionRows[singleRow.index]}

                                                <Row
                                                    className={
                                                        styles.studentList
                                                    }
                                                >
                                                    {studentList}
                                                </Row>
                                                {actionBox}
                                            </>
                                        ) : (
                                            sessionRows
                                        )}
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    )}
                    {currentUserRole === "student" && (
                        <div>
                            <Modal
                                contentClassName={styles.dashboardModal}
                                backdropClassName={styles.studentModalBackdrop}
                                show={showStudentCalendarModal}
                                onHide={() => {
                                    setShowStudentCalendarModal(false);
                                }}
                                keyboard={false}
                                size="xl"
                                centered
                            >
                                <Modal.Body>
                                    <Row className={styles.dashboardHeader}>
                                        <Col
                                            className={styles.dashboardHeading}
                                        >
                                            List of upcoming classes
                                        </Col>
                                    </Row>
                                    <Row className={styles.studentColumn}>
                                        <Col
                                            xs={2}
                                            className={
                                                styles.dashboardColHeading
                                            }
                                        >
                                            Date
                                        </Col>
                                        <Col
                                            xs={3}
                                            className={
                                                styles.dashboardColHeading
                                            }
                                        >
                                            Name of class
                                        </Col>
                                        <Col
                                            xs={3}
                                            className={
                                                styles.dashboardColHeading
                                            }
                                        >
                                            Time (Duration)
                                        </Col>
                                        <Col
                                            xs={4}
                                            className={
                                                styles.dashboardColHeading
                                            }
                                        >
                                            Students registered
                                        </Col>
                                    </Row>
                                    {studentSessionRows}
                                </Modal.Body>
                            </Modal>
                        </div>
                    )}
                    <div
                        className={
                            currentUserRole === "teacher"
                                ? styles.mainSectionTeacher
                                : styles.mainSectionStudent
                        }
                    >
                        <Row style={{ width: "100%" }}>
                            <img
                                src={Bag}
                                alt="Logo"
                                onClick={() => {
                                    if (currentUserRole === "student")
                                        setShowStudentCalendarModal(true);
                                }}
                                className={
                                    currentUserRole === "student"
                                        ? styles.bag
                                        : styles.teacherBag
                                }
                            />
                            <img
                                src={greyMoments}
                                alt="Moments"
                                className={
                                    currentUserRole === "student"
                                        ? styles.studentMoments
                                        : styles.teacherMoments
                                }
                            />
                            <Col md={3} style={{ textAlign: "center" }}></Col>
                            <Col
                                md={6}
                                className={styles.middleSectionContainer}
                            >
                                <div className={styles.middleSection}>
                                    <img
                                        src={
                                            currentUserRole === "teacher"
                                                ? BeginTeacher
                                                : BeginStudent
                                        }
                                        className={styles.middleImg}
                                        alt="plass Begin"
                                    />
                                </div>

                                <div
                                    className={
                                        currentUserRole === "student"
                                            ? styles.studentTasks
                                            : styles.teacherTasks
                                    }
                                >
                                    <img src={Tasks} alt="Tasks" />
                                    <div className={styles.tasksContainer}>
                                        <div className={styles.tasksHeader}>
                                            Tasks
                                        </div>

                                        <div className={styles.tasksList}>
                                            {tasksList.map((task, index) => {
                                                return (
                                                    <Link
                                                        key={index}
                                                        className={
                                                            styles.taskContainer
                                                        }
                                                        to={
                                                            ["REVIEW"].includes(
                                                                task.type
                                                            )
                                                                ? {
                                                                      pathname:
                                                                          "/accountsettings",
                                                                      state: {
                                                                          tab: "classRatings",
                                                                      },
                                                                  }
                                                                : [
                                                                      "PAYMENT",
                                                                  ].includes(
                                                                      task.type
                                                                  )
                                                                ? {
                                                                      pathname: `/register/${task.session_id}`,
                                                                  }
                                                                : [
                                                                      "STORE_PROFILE",
                                                                  ].includes(
                                                                      task.type
                                                                  )
                                                                ? {
                                                                      pathname:
                                                                          "/accountsettings",
                                                                      state: {
                                                                          tab: "personalDetails",
                                                                      },
                                                                  }
                                                                : [
                                                                      "STRIPE_ACCOUNT",
                                                                  ].includes(
                                                                      task.type
                                                                  )
                                                                ? {
                                                                      pathname:
                                                                          "/accountsettings",
                                                                      state: {
                                                                          tab: "paymentDet",
                                                                      },
                                                                  }
                                                                : ""
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                styles.taskSymbol
                                                            }
                                                        >
                                                            +
                                                        </span>

                                                        <span
                                                            className={
                                                                styles.taskTitle
                                                            }
                                                            onClick={() =>
                                                                history.push(
                                                                    "/"
                                                                )
                                                            }
                                                        >
                                                            {task.title}
                                                        </span>
                                                    </Link>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className={styles.rightSection}>
                                    <img
                                        onClick={(event) => {
                                            event.preventDefault();
                                            if (playAccess) handlePlay();
                                        }}
                                        src={Play}
                                        alt="Logo"
                                        className={`${styles.playButton} ${
                                            playAccess
                                                ? ""
                                                : styles.playButtonFilter
                                        }`}
                                    />
                                    {currentUserRole === "teacher" ? (
                                        <>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginTop: "40px",
                                                }}
                                            >
                                                <img
                                                    src={greyClassFiles}
                                                    alt="plass Begin"
                                                    className={
                                                        styles.classFileIcon
                                                    }
                                                />
                                                <img
                                                    onClick={() => {
                                                        window.location.href = `/sessions`;
                                                    }}
                                                    src={Create}
                                                    alt="plass create"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className={styles.deskContainer}
                                            >
                                                <img
                                                    src={greyShelfT}
                                                    alt="Desk"
                                                />
                                                <div
                                                    className={`${styles.deskShelf} ${styles.deskTopShelf}`}
                                                    style={
                                                        currentUserRole ===
                                                            "teacher" && {
                                                            display: "block",
                                                        }
                                                    }
                                                >
                                                    {/* <div
                                                className={styles.topShelfIcons}
                                            >
                                                <div
                                                    className={
                                                        styles.topShelfIconContainer
                                                    }
                                                >
                                                    <img
                                                        src={Audio}
                                                        alt="Audio"
                                                        className={
                                                            styles.topShelfIcon
                                                        }
                                                    />
                                                    <img src={AudioBg} alt="" />
                                                </div>
                                                <div
                                                    className={
                                                        styles.topShelfIconContainer
                                                    }
                                                >
                                                    <img
                                                        src={Video}
                                                        alt="Video"
                                                        className={`${styles.topShelfIcon} ${styles.videoIcon}`}
                                                    />
                                                    <img src={VideoBg} alt="" />
                                                </div>
                                                <div
                                                    className={
                                                        styles.topShelfIconContainer
                                                    }
                                                >
                                                    <img
                                                        src={Image}
                                                        alt="Image"
                                                        className={
                                                            styles.topShelfIcon
                                                        }
                                                    />
                                                    <img src={ImageBg} alt="" />
                                                </div>
                                                <div
                                                    className={
                                                        styles.topShelfIconContainer
                                                    }
                                                >
                                                    <img
                                                        src={Folder}
                                                        alt="Folder"
                                                        className={
                                                            styles.topShelfIcon
                                                        }
                                                    />
                                                    <img
                                                        src={FolderBg}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    styles.horizontalBooksContainer
                                                }
                                            >
                                                <img
                                                    src={HorizontalBook1}
                                                    alt="Horizontal Book 1"
                                                    className={
                                                        styles.leftShelfItem
                                                    }
                                                />
                                                <img
                                                    src={HorizontalBook2}
                                                    alt="Horizontal Book 2"
                                                />
                                            </div> */}
                                                </div>
                                                {/* <div
                                            className={`${styles.deskShelf} ${styles.deskBottomShelfTeacher}`}
                                        >
                                            <img
                                                src={greyMedals}
                                                alt="Trophy Books"
                                            />
                                        </div> */}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "left",
                                                    marginTop: "30px",
                                                }}
                                            >
                                                <img
                                                    src={greyMedals}
                                                    className={
                                                        styles.trophyImages
                                                    }
                                                    alt="Trophy"
                                                />

                                                {/* <img
                                            src={StarTrophy}
                                            className={styles.trophyImages}
                                            alt="Star Trophy"
                                        />
                                        <img
                                            src={Badge}
                                            className={styles.trophyImages}
                                            alt="Badge"
                                        /> */}
                                            </div>
                                            <div
                                                className={styles.deskContainer}
                                            >
                                                <img
                                                    src={greyShelfS}
                                                    alt="Desk"
                                                />
                                                <div
                                                    className={`${styles.deskShelf} ${styles.deskTopShelf}`}
                                                ></div>
                                            </div>
                                            {/* <div className={styles.deskContainer}>
                                        <img src={Desk} alt="Desk" />
                                        <div
                                            className={`${styles.deskShelf} ${styles.deskTopShelf}`}
                                        >
                                            <img
                                                src={HorizontalBooks}
                                                alt="Horizontal Books"
                                                className={styles.leftShelfItem}
                                            />
                                            <img
                                                src={VerticalBooks}
                                                alt="Vertical Books"
                                            />
                                        </div>
                                        <div
                                            className={`${styles.deskShelf} ${styles.deskBottomShelf}`}
                                        >
                                            <img
                                                src={CookieJar}
                                                alt="Cookie Jar"
                                                className={`${styles.leftShelfItem} ${styles.cookieJar}`}
                                            />
                                            <img
                                                src={IslandFlag}
                                                alt="Island Flag"
                                                className={styles.islandFlag}
                                            />
                                        </div>
                                    </div> */}
                                        </>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        {currentUserRole === "student" && (
                            <SubscriptionFooter />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
