import React, { useEffect } from "react";
import Loading from "../../Loading/Loading";
import Header from "./../../Header/index";
import { Link, useHistory } from "react-router-dom";

export default function AddSuccessfully() {
    const history = useHistory();

    useEffect(() => {
        setTimeout(function () {
            history.push({
                pathname: "/accountSettings",
                state: { tab: "paymentDet" },
            });
        }, 5000);
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <Header showDashboard={false} />

            <Loading />

            <div className="container h-80" style={{ marginTop: "-10rem" }}>
                <div className="my-auto text-center">
                    <h4
                        style={{
                            color: "#88ADDB",
                            fontSize: "2em",
                        }}
                    >
                        Payment account added successfully
                    </h4>

                    <div
                        style={{
                            fontSize: "1.3em",
                        }}
                    >
                        <div>
                            You will now be redirected automatically to the
                            payment details page.
                        </div>

                        <div>
                            If not, please click{" "}
                            <Link
                                to={{
                                    pathname: "/accountSettings",
                                    state: { tab: "paymentDet" },
                                }}
                                style={{
                                    textDecoration: "underline",
                                }}
                            >
                                here
                            </Link>
                            .
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
