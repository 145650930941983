import React from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "../../assets/images/icon/facebookBlue.svg";
import InstagramIcon from "../../assets/images/icon/instagramBlue.svg";
import EmailIcon from "../../assets/images/icon/mailBlue.svg";
import UCLIcon from "../../assets/images/icon/UCL.svg";
import kingIcon from "../../assets/images/icon/kings.svg";

import styles from "./index.module.scss";
import ClubHubVerification from "../../assets/images/icon/club.svg";
import { getSeasons } from "../../utils/commonFunction";

const FooterNew = ({ removeStar }) => {
    return (
        <div className={styles.footerContainer}>
            <div className={styles.badge_outer__container}>
                <div className={styles.badge}>
                    <div className={styles.badge_image}>
                        <img src={ClubHubVerification} alt="Club Hub UK" />
                    </div>
                    <div className={styles.badge_sub}>
                        <img src={UCLIcon} alt="UCL edtech labs" />
                        <p>{getSeasons()}</p>
                    </div>
                </div>
                <div className={styles.college}>
                    <p>Endorsed by:</p>
                    <img src={kingIcon} alt="king's college" />
                    <p>Entrepreneurship Institute</p>
                </div>
            </div>
            <div className={styles.text_outer_container}>
                <div className={styles.footerIcons}>
                    <img
                        src={FacebookIcon}
                        alt="Facebook"
                        className={styles.icon}
                        onClick={() =>
                            window.open("https://www.facebook.com/plassroom")
                        }
                    />
                    <img
                        src={InstagramIcon}
                        alt="Instagram"
                        className={styles.icon}
                        onClick={() =>
                            window.open("https://www.instagram.com/plassroom")
                        }
                    />

                    <a href="mailto:enquiry@plassroom.com">
                        <img
                            src={EmailIcon}
                            alt="Email"
                            className={styles.icon}
                        />
                    </a>
                </div>
                <div className={styles.footerLinks}>
                    <div className={styles.footerLink}>
                        <Link to="/privacypolicy" style={{ color: "#13385C" }}>
                            Privacy Policy
                        </Link>
                    </div>
                    <div className={styles.footerLink}>
                        <Link to="/termsofservice" style={{ color: "#13385C" }}>
                            Terms of Service
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterNew;
