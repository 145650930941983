import axios from "axios";
import _ from "lodash";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Snackbar from "../../components/Snackbar";
import styles from "./forgotpassword.module.scss";

export default () => {
    const history = useHistory();
    const [email, setEmail] = useState();
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarStatus, setSnackbarStatus] = useState();

    const handleChange = (evt) => {
        setEmail(evt.target.value);
    };

    const handleResetPassword = async () => {
        let data = {
            email: email,
        };
        await axios
            .post("/api/v1/user/forgotpassowrd", data)
            .then((res) => {
                if (!res.data.success) {
                    throw res;
                } else {
                    setMsg(_.get(res, "data.message", "Something went wrong."));
                    setShowSnackbar(true);
                    setSnackbarStatus("information");
                    setTimeout(function () {
                        setMsg(null);
                        setSnackbarStatus();
                        history.push("/login");
                    }, 3000);
                }
            })
            .catch((error) => {
                let message = _.get(
                    error,
                    "response.data.title",
                    "Something went wrong."
                );
                if (message === "Something went wrong.") {
                    message = _.get(
                        error,
                        "data.message",
                        "Something went wrong."
                    );
                }
                setMsg(message);
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const handleSignup = () => {
        history.push("/signup");
        window.location.href = "./signup";
    };

    return (
        <div className={styles.mainDiv}>
            {msg && (
                <Snackbar
                    status={snackbarStatus || "error"}
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <Navbar />

            <div className={styles.formWrapper}>
                <h2 className={styles.heading}> Forgot password</h2>

                <p className={styles.para}>
                    Don’t worry, just enter the email you registered with and we
                    will send you instructions on how to reset your password.
                </p>
                <div className={styles.inputWrapper}>
                    <div>
                        <input
                            className={styles.emailInput}
                            placeholder="Email"
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <p className={styles.continueBtn} onClick={handleResetPassword}>
                    Reset password
                </p>

                <p className={styles.signupText}>
                    Dont have an account?{" "}
                    <span className={styles.signup} onClick={handleSignup}>
                        Signup
                    </span>
                </p>
            </div>
        </div>
    );
};
