import { Modal } from "react-bootstrap";
import React from "react";
import styles from "./TimeoutWarningModal.module.css";

export default ({ showTimeoutWarningModal, setShowTimeoutWarningModal }) => {
    return (
        <Modal
            contentClassName={styles.content}
            backdropClassName={styles.backdrop}
            show={showTimeoutWarningModal}
            onHide={() => {
                setShowTimeoutWarningModal(false);
            }}
            keyboard={false}
            size="md"
            centered
        >
            <Modal.Body className={styles.body}>
                Your session will end in five minutes. We encourage you to end
                your class soon.
            </Modal.Body>
        </Modal>
    );
};
