import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default ({ Component }) => {
    const [showComponent, setShowComponent] = useState(false);

    const history = useHistory();
    useEffect(() => {
        if (!localStorage.getItem("auth")) {
            history.replace("/");
        } else {
            const authData = JSON.parse(localStorage.getItem("auth"));
            if (authData.user.role !== "admin") {
                history.replace("/");
            } else {
                setShowComponent(true);
            }
        }
        // eslint-disable-next-line
    }, []);
    return <>{showComponent && <Component />}</>;
};
