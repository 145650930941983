import React, { useRef, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import styles from "./Layout4.module.css";
import TwilioParticipant from "../../../../../../components/TwilioParticipant";
import Participant from "../../../../../../components/Participant";
import Whiteboard from "../../../../../../components/WhiteboardByNirajan";
import Snackbar from "../../../../../../components/Snackbar";
import _ from "lodash";

export default ({
    participants,
    drawData,
    room,
    mutedUsers,
    publishAudio,
    socket,
    role,
    key,
    currentPanel,
    session,
}) => {
    var Teacher = null;
    var Participants = [];
    const rowRef = useRef();

    const [selectedMedia, setSelectedMedia] = useState([]);
    const [initialState, setInitaialState] = useState(false);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [fitToScreen, setFitToScreen] = useState(
        currentPanel.lesson_settings.whiteboard.fitToScreen
    );
    const [teacherDrawData, setTeacherDrawData] = useState([]);

    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;

    useEffect(() => {
        const getSelectedMedia = async () => {
            const selectedMediaArray =
                currentPanel.lesson_settings?.whiteboard?.image_ids;

            if (selectedMediaArray.length) {
                let galleryData = {
                    selectedMediaArray: selectedMediaArray,
                    userId: session.teacher_id,
                };
                await axios
                    .post(`/api/v1/user/gallery/selected`, galleryData)
                    .then((data) => {
                        if (data.data.success) {
                            const resultMedia = data.data.data.newArray;

                            setSelectedMedia(resultMedia);
                        }
                    })
                    .catch((error) => {
                        setMsg(
                            _.get(
                                error,
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                        return Promise.reject(error);
                    });
            }
        };
        const getDrawData = async () => {
            let teacherData = [];

            drawData.forEach((obj) => {
                teacherData.push(obj.draw_data);
            });

            setTeacherDrawData(teacherData);
            setInitaialState(true);
        };
        getSelectedMedia();
        getDrawData();
        // eslint-disable-next-line
    }, [drawData]);

    if (currentUserRole === "student") {
        var teacherParticipant = participants.find((obj) => {
            var identityObj = JSON.parse(obj.identity);
            return identityObj.role === "teacher";
        });
        if (teacherParticipant) {
            Teacher = (
                <TwilioParticipant
                    role="teacher"
                    key={teacherParticipant.sid}
                    participant={teacherParticipant}
                />
            );
        }
        Participants.push(Teacher);

        let studentParticipants = [
            <TwilioParticipant
                role="student"
                key={room.localParticipant.sid}
                participant={room.localParticipant}
                publishAudio={publishAudio}
                screen="student"
            />,
        ].concat(
            participants
                .filter((obj) => {
                    var identityObj = JSON.parse(obj.identity);
                    return identityObj.role !== "teacher";
                })
                .map((participant) => (
                    <TwilioParticipant
                        role="student"
                        key={participant.sid}
                        participant={participant}
                        publishAudio={publishAudio}
                        screen="student"
                    />
                ))
        );
        Participants = [...Participants, ...studentParticipants];
    }

    let noOfParticipants = Participants.length;
    let participantBoxes = [];

    for (let i = 0; i < noOfParticipants; i++) {
        participantBoxes.push(
            <Col md="auto">
                <div
                    className={styles.block}
                    style={{
                        width: rowRef
                            ? rowRef.current?.offsetWidth / noOfParticipants -
                              30
                            : "",
                    }}
                >
                    <Participant>{Participants[i]}</Participant>
                </div>
            </Col>
        );
    }

    // console.log("LAYOUT 4");

    return (
        <div className={styles.assets}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            {initialState && (
                <Whiteboard
                    myWhiteboard
                    drawData={teacherDrawData}
                    socket={socket}
                    // streams={streams}
                    // role={role}
                    participants={participants}
                    selectedMedia={selectedMedia}
                    // myMarkerColor={myMarkerColor}
                    key={key}
                    position={currentPanel.lesson_settings.whiteboard.position}
                    fitToScreen={fitToScreen}
                    setFitToScreen={setFitToScreen}
                    studentAccess={
                        currentPanel.lesson_settings.whiteboard.student_access
                    }
                    requiredWB={
                        currentPanel.lesson_settings.whiteboard.teacher
                            .shared_use
                    }
                    panelID={currentPanel._id}
                />
            )}
            <div className={styles.rowDiv}>
                <Row style={{ justifyContent: "center" }} ref={rowRef}>
                    {participantBoxes}
                </Row>
            </div>
        </div>
    );
};
