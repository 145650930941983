import styles from "./index.module.scss";
import React from "react";
import { Card } from "react-bootstrap";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CarouselSmall from "../../../../components/Carousels/CarouselSmall";
import FreeBG from "../../../../assets/images/LOGO (4).svg";
import StudentLogoBg from "../../../../assets/images/LOGO (7).png";
import moment from "moment";
import { getCurrencySymbol } from "../../../../utils/commonFunction";

export default function ClassCard({ classList }) {
    const history = useHistory();
    // const [img, setImg] = useState(classList[0]?.image_ids[0]?.location)

    const handelRoute = (singleClass) => {
        history.push(`/class-details/${singleClass.class_name_slug}`);
    };

    const ratingStar = (rating) => {
        const ratingArr = [];

        for (let i = 0; i < rating; i++) {
            ratingArr.push(
                <FontAwesomeIcon
                    key={i}
                    icon={faStar}
                    className={styles.starIcon}
                />
            );
        }

        return ratingArr;
    };

    return (
        <div className={`upcoming-section ${styles.container}`}>
            {classList &&
                classList.map((classDetails, index) => {
                    return (
                        <div
                            className={`slide-list ${styles.cardRow}`}
                            key={index}
                        >
                            <Card className={styles.classcard}>
                                <Card.Body className={styles.cardBody}>
                                    {/* <Card.Img variant="top" src={classDetails?.image_ids[0]?.location} /> */}
                                    <div className={styles.classImage}>
                                        {classDetails?.type === "Course" ? (
                                            <div
                                                className={styles.logoContainer}
                                            >
                                                <h1 className={styles.logoText}>
                                                    Course
                                                </h1>
                                                <img
                                                    src={StudentLogoBg}
                                                    className={
                                                        styles.studentLogoBg
                                                    }
                                                    alt="Logo"
                                                />
                                            </div>
                                        ) : classDetails.is_free_class ? (
                                            <img
                                                src={FreeBG}
                                                alt=""
                                                className={styles.freeBG}
                                            />
                                        ) : null}

                                        {/* <img
                                    src={_.get(
                                        classDetails,
                                        "image_ids[0].location",
                                        Photo1
                                    )}
                                    alt="Profile"
                                    className={styles.profilePhoto}
                                /> */}

                                        <CarouselSmall
                                            carouselImgs={_.get(
                                                classDetails,
                                                "image_ids",
                                                []
                                            )}
                                            onClickEvent={() =>
                                                handelRoute(classDetails)
                                            }
                                            specialClass="br19"
                                            playOnHover={true}
                                            smallCarousel={true}
                                        />
                                    </div>
                                    <div className={styles.bottomBlock}>
                                        <div className={styles.classRating}>
                                            {ratingStar(
                                                parseInt(
                                                    _.get(
                                                        classDetails,
                                                        "teacherRate",
                                                        0
                                                    )
                                                )
                                            )}
                                        </div>
                                        <Card.Text
                                            style={{ fontSize: "medium" }}
                                        >
                                            {classDetails?.class_name}
                                        </Card.Text>
                                        <div className={styles.blockRow}>
                                            <div
                                                className={`blue-card-blog ${styles.blueBlock}`}
                                            >
                                                <h4>
                                                    {
                                                        classDetails
                                                            ?.configuration
                                                            ?.age_group
                                                    }
                                                </h4>
                                                <p>Ages</p>
                                            </div>
                                            <div className={styles.blueBlock}>
                                                <h4>
                                                    {
                                                        classDetails
                                                            ?.configuration
                                                            ?.session_duration
                                                    }
                                                </h4>
                                                <p>Mins</p>
                                            </div>
                                            <div className={styles.blueBlock}>
                                                {classDetails?.is_free_class ? (
                                                    <h4
                                                        style={{
                                                            paddingTop: "0.5em",
                                                        }}
                                                    >{`Free`}</h4>
                                                ) : (
                                                    <>
                                                        {/* <h4>{`£ ${classDetails?.configuration?.fee?.amount}`}</h4> */}
                                                        <h4>
                                                            {classDetails?.is_free_class
                                                                ? "Free"
                                                                : `${getCurrencySymbol(
                                                                      classDetails
                                                                          .configuration
                                                                          ?.fee
                                                                          ?.currency
                                                                  )} ${
                                                                      classDetails
                                                                          .configuration
                                                                          ?.fee
                                                                          ?.amount /
                                                                          100 ||
                                                                      0
                                                                  }`}
                                                        </h4>
                                                        <p>
                                                            {classDetails?.type ===
                                                            "Course"
                                                                ? `${classDetails?.class_slots?.length} Sessions`
                                                                : "Per Class"}
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className={styles.infoBlock}>
                                            {/* <p className={styles.classNameTxt}>{classDetails?.class_name}</p> */}
                                            {
                                                <>
                                                    <p
                                                        className={
                                                            styles.startTime
                                                        }
                                                    >
                                                        {moment(
                                                            classDetails
                                                                ?.class_slots[0]
                                                                ?.configuration
                                                                ?.session_start_time
                                                        ).format(
                                                            "MMM DD (ddd) hh:mm A"
                                                        )}{" "}
                                                        {moment
                                                            .tz(
                                                                moment.tz.guess()
                                                            )
                                                            .zoneAbbr()}
                                                    </p>
                                                    <p
                                                        className={
                                                            styles.startTime
                                                        }
                                                    >
                                                        {classDetails
                                                            ?.class_slots
                                                            .length > 1
                                                            ? `and more...`
                                                            : null}
                                                    </p>
                                                </>
                                            }
                                        </div>
                                        <div>
                                            <p
                                                className={styles.hostedBlock}
                                                style={{
                                                    margin:
                                                        classDetails
                                                            ?.class_slots
                                                            .length > 1 &&
                                                        "0px 0px 12.5px",
                                                }}
                                            >{`Hosted by ${classDetails?.teacher[0]?.preferedName}`}</p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    );
                })}
        </div>
    );
}
