import {
    faAngleDown,
    faAngleUp,
    faGreaterThan,
    faLessThan,
    faMinus,
    faPlus,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useParams, useHistory } from "react-router-dom";
import BubblePop from "../../../../assets/images/BubblePop.svg";
import BuildYourDream from "../../../../assets/images/BuildYourDreams.svg";
import CloseButton from "../../../../assets/images/Close_button.png";
import Edit from "../../../../assets/images/edit.png";
import Logo from "../../../../assets/images/plass_logo_new.svg";
import PlayIcon from "../../../../assets/images/GreyPlayIcon.png";
import ScreenIcon from "../../../../assets/images/ScreenArrangement.png";
import Clock from "../../../../components/Clock";
import CustomDropdown from "../../../../components/CustomDropdown";
import Snackbar from "../../../../components/Snackbar";
import styles from "./list.module.css";
import WarningModal from "../../../../components/WarningModal";

export default () => {
    const { sessionID } = useParams();
    const location = useLocation();
    const history = useHistory();
    const panelindex = Number(
        new URLSearchParams(location.search).get("panelIndex")
    );
    const panelSlotId =
        new URLSearchParams(location.search).get("slotId") || null;
    const [rightPanelSettings, setRightPanelSettings] = useState([]);
    const [rightPanelSettingsToShow, setRightPanelSettingsToShow] = useState(
        []
    );
    const [panelIndex, setPanelIndex] = useState(panelindex);
    const [rightPanelIndex, setRightPanelIndex] = useState(undefined);
    const [sessions, setSessions] = useState([]);
    const [imgModalFlag, setImgModalFlag] = useState(0);
    const [show, setShow] = useState(undefined);
    const [importModal, setImportModal] = useState(false);
    const [selectClass, setSelectClass] = useState(false);
    const [sessionData, setSessionData] = useState();
    const [versionTime, setVersionTime] = useState(undefined);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState({
        flag: false,
        type: null,
    });
    const [nextTabData, setNextTabData] = useState({
        obj: null,
        index: null,
    });
    const urlParam = !!panelSlotId ? `&slotId=${panelSlotId}` : ``;

    useEffect(() => {
        const getSessionInfo = async () => {
            await axios
                .get(`/api/v1/class/${sessionID}`)
                .then((result) => {
                    if (result.data.success) {
                        const settings =
                            result.data.data.session.configuration
                                .right_panel_settings;
                        setRightPanelSettings(settings);
                        setRightPanelSettingsToShow(settings.slice(0, 5));
                        const updateTime = moment
                            .utc(
                                result.data.data.session.updatedAt,
                                "DD/MM/YYYY HH:mm"
                            )
                            .local()
                            .format("HH:mm");
                        setVersionTime(updateTime);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        getSessionInfo();
        // eslint-disable-next-line
    }, []);

    const updateSession = async (newSettings, cb) => {
        if (rightPanelSettings.length > 0) {
            const sessionData = {
                id: sessionID,
                rightPanelSettings: newSettings || rightPanelSettings,
                ...(!!panelSlotId
                    ? {
                          rightPanelSettingsSessionId: panelSlotId,
                      }
                    : {}),
            };

            await axios
                .put("/api/v1/sessions", sessionData)
                .then((result) => {
                    if (result.data.success) {
                        if (cb) {
                            cb();
                        }
                        setVersionTime(moment().format("HH:mm"));
                    } else {
                        setMsg(
                            _.get(
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        }
    };

    const updateRightPanelSettings = (index, type, label, cb) => {
        const updatedRightPanel = rightPanelSettings.map((obj, i) => {
            if (index === i && (!obj.type || obj.type !== type)) {
                return {
                    label,
                    type,
                };
            } else {
                return obj;
            }
        });
        setRightPanelSettings(updatedRightPanel);
        setRightPanelSettingsToShow(updatedRightPanel.slice(0, 5));
        cb(updatedRightPanel);
    };

    const lessonData = [
        {
            label: "Video",
            value: "Video",
        },
        {
            label: "Lesson",
            value: "Lesson",
        },
        { label: "Activity", value: "Activity" },
    ];

    const handleData = (type) => {
        if (type === "VIDEO") {
            return "Video";
        } else if (type === "LESSON") {
            return "Lesson";
        } else if (type === "ACTIVITY") {
            return "Activity";
        }
    };

    const rightTab = (data) => {
        if (data === "VIDEO") {
            return "Video";
        } else if (data === "LESSON") {
            return "Lesson";
        } else if (data === "ACTIVITY") {
            return "Activity";
        }
    };

    const moveSessionUp = () => {
        const index = rightPanelSettings.indexOf(rightPanelSettingsToShow[0]);

        if (index > 0) {
            let rightPanelSettingsToShowCopy = [...rightPanelSettingsToShow];
            rightPanelSettingsToShowCopy.pop();
            rightPanelSettingsToShowCopy = [
                rightPanelSettings[index - 1],
                ...rightPanelSettingsToShowCopy,
            ];
            setRightPanelSettingsToShow(rightPanelSettingsToShowCopy);
        }
    };

    const moveSessionDown = () => {
        const index = rightPanelSettings.indexOf(rightPanelSettingsToShow[0]);

        if (index < rightPanelSettings.length - 5) {
            const rightPanelSettingsToShowCopy = [...rightPanelSettingsToShow];
            rightPanelSettingsToShowCopy.shift();
            rightPanelSettingsToShowCopy.push(rightPanelSettings[index + 5]);
            setRightPanelSettingsToShow(rightPanelSettingsToShowCopy);
        }
    };

    const addLessionBlock = async () => {
        const newArray = [...rightPanelSettings, {}];
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
    };

    const removeLessionBlock = async (index) => {
        if (index < panelIndex) {
            setPanelIndex(panelIndex - 1);
        }
        const newArray = rightPanelSettings
            .slice(0, index)
            .concat(rightPanelSettings.slice(index + 1));
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
    };

    const handleRightTab = async (ev, index) => {
        const newArray = [...rightPanelSettings];
        newArray[index] = {
            ...newArray[index],
            label: ev.target.innerText,
        };
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
    };

    const handleTextChange = (e) => {
        if (e.currentTarget.textContent.length > 6 && e.which !== 8) {
            e.preventDefault();
        }
    };

    const handleActivity = async (game, ev) => {
        const newArray = rightPanelSettings.map((obj, i) => {
            if (i === Number(panelIndex)) {
                if (game !== obj.subType) {
                    return {
                        type: obj.type,
                        label: obj.label,
                        subType: game,
                    };
                } else {
                    return obj;
                }
            } else {
                return obj;
            }
        });
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
        history.push(
            `/session/${sessionID}/activity/customise?panelIndex=${panelIndex}${urlParam}`
        );
    };

    const handleRightPanelSetting = async (index) => {
        const rightPanelData =
            sessionData.configuration.right_panel_settings[index];
        const newArray = rightPanelSettings;
        newArray[rightPanelIndex] = rightPanelData;
        newArray[rightPanelIndex]._id =
            sessionData.configuration.right_panel_settings[rightPanelIndex]._id;
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        setImportModal(false);
        setSelectClass(false);
        await updateSession(newArray);
    };

    const handleImportModal = () => {
        setImportModal(false);
        setSelectClass(false);
    };

    const handleSelectClass = async (event, id) => {
        event.preventDefault();
        await axios
            .get("/api/v1/sessions")
            .then((result) => {
                if (result.data.success) {
                    const data = result.data.sessions.find(
                        (element, index) => index === id
                    );
                    setSessionData(data);
                }
                setSelectClass(true);
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const decreaseImgModalFlag = (length) => {
        if (imgModalFlag > 0) {
            setImgModalFlag(imgModalFlag - 1);
        } else {
            setImgModalFlag(length - 1);
        }
    };

    const increaseImgModalFlag = (length) => {
        if (imgModalFlag < length - 1) {
            setImgModalFlag(imgModalFlag + 1);
        } else {
            setImgModalFlag(0);
        }
    };

    const handleImportOption = async (index) => {
        await axios
            .get("/api/v1/sessions")
            .then((result) => {
                if (result.data.success) {
                    setSessions(result.data.sessions);
                }
                setImportModal(true);
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const handleShowWarningModal = async (decision, moveToOtherTab) => {
        setShowWarningModal({
            flag: false,
            type: null,
        });

        if (moveToOtherTab) {
            handleMoveToOtherTab(true);
        } else if (decision) {
            await updateSession();
            history.push(`/session/${sessionID}`);
        }
    };

    const handleMoveToOtherTab = async (flag, nextObj) => {
        setShowWarningModal({
            flag: false,
            type: null,
        });

        if (flag) {
            await updateSession();

            const obj = nextObj ? nextObj.obj : nextTabData.obj;
            const index = nextObj ? nextObj.index : nextTabData.index;
            if (obj.type === "LESSON") {
                if (rightPanelSettings[index].subType === "ASSET") {
                    if (
                        rightPanelSettings[index].lesson_settings.assets.view
                            ?.student &&
                        rightPanelSettings[index].lesson_settings.assets.view
                            ?.teacher
                    ) {
                        history.push(
                            `/session/${sessionID}/asset/media?panelIndex=${index}${urlParam}`
                        );
                    } else {
                        history.push(
                            `/session/${sessionID}/asset/view?panelIndex=${index}${urlParam}`
                        );
                    }
                } else {
                    if (rightPanelSettings[index].subType === "WHITEBOARD") {
                        if (
                            rightPanelSettings[index].lesson_settings.whiteboard
                                ?.student?.view &&
                            rightPanelSettings[index].lesson_settings.whiteboard
                                ?.teacher?.view
                        ) {
                            history.push(
                                `/session/${sessionID}/whiteboard/media?panelIndex=${index}${urlParam}`
                            );
                        } else if (
                            rightPanelSettings[index].lesson_settings.whiteboard
                        ) {
                            history.push(
                                `/session/${sessionID}/whiteboard/view?panelIndex=${index}${urlParam}`
                            );
                        } else {
                            history.push(
                                `/session/${sessionID}/whiteboard/setting?panelIndex=${index}${urlParam}`
                            );
                        }
                    } else {
                        history.push(
                            `/session/${sessionID}/tool?panelIndex=${index}${urlParam}`
                        );
                    }
                }
            } else if (obj.type === "VIDEO") {
                if (!obj.video_settings) {
                    history.push(
                        `/session/${sessionID}/viewTool?panelIndex=${index}${urlParam}`
                    );
                } else if (obj.subType === "SCREENSHARE") {
                    history.push(
                        `/session/${sessionID}/screenshare?panelIndex=${index}${urlParam}`
                    );
                } else {
                    history.push(
                        `/session/${sessionID}/view?panelIndex=${index}${urlParam}`
                    );
                }
            } else if (obj.type === "ACTIVITY") {
                if (rightPanelSettings[index].subType) {
                    history.push(
                        `/session/${sessionID}/activity/customise?panelIndex=${index}${urlParam}`
                    );
                } else {
                    history.push(
                        `/session/${sessionID}/activity/list?panelIndex=${index}${urlParam}`
                    );
                }
            }
        }
    };

    return (
        <div>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            {showWarningModal.flag && (
                <WarningModal
                    handleShowWarningModal={handleShowWarningModal}
                    showWarningModal={showWarningModal}
                />
            )}

            <Row className={styles.row}>
                <Col xs={10} className={styles.leftsection}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                            className={styles.iconsection}
                            style={{
                                padding: "9px 34px",
                            }}
                        >
                            <img
                                src={ScreenIcon}
                                style={{ width: "40px" }}
                                alt=""
                                className={styles.playicon}
                            />
                        </div>
                        <h1
                            className={styles.heading}
                            style={{ marginLeft: "10px", marginBottom: 0 }}
                        >
                            Activity Library
                        </h1>
                    </div>
                    <div className={styles.leftSection}>
                        <div className={styles.sessionblock}>
                            <div className={styles.crosssection}>
                                <img
                                    onClick={() => {
                                        setShowWarningModal({
                                            flag: true,
                                            type: "closeButton",
                                        });
                                    }}
                                    src={CloseButton}
                                    alt="Logo"
                                    className={styles.playicon}
                                    style={{ marginRight: "5px" }}
                                />
                                <p className={styles.italicPara}>
                                    Last version saved at {versionTime}
                                </p>
                            </div>
                            <div>
                                <img
                                    src={PlayIcon}
                                    style={{
                                        width: "40px",
                                        cursor: "default",
                                    }}
                                    alt=""
                                    className={styles.playicon}
                                />
                            </div>
                        </div>
                        <div className={styles.leftScroll}>
                            <Row>
                                <Col xl={4} md={5}>
                                    <div
                                        className={`${styles.activityBlock} ${
                                            rightPanelSettings[panelIndex]
                                                ?.subType === "BUBBLE_POP" &&
                                            styles.highlight
                                        }`}
                                        onClick={(ev) => {
                                            handleActivity("BUBBLE_POP", ev);
                                        }}
                                        id="activity"
                                    >
                                        <img
                                            className={styles.activityImg}
                                            src={BubblePop}
                                            alt="pop"
                                        />
                                    </div>
                                    <h5 className={styles.activityName}>
                                        Bubble pop
                                    </h5>
                                    <p
                                        className={`${styles.activityDescription} ${styles.tempHidden}`}
                                    >
                                        Description
                                    </p>
                                </Col>
                                <Col md={4} className={styles.tempHidden}>
                                    <div
                                        className={`${styles.activityBlock} ${
                                            rightPanelSettings[panelIndex]
                                                ?.subType === "TREASURE_HUNT" &&
                                            styles.highlight
                                        }`}
                                        onClick={(ev) => {
                                            handleActivity("TREASURE_HUNT", ev);
                                        }}
                                    >
                                        <p>Treasure Hunt (2)</p>
                                    </div>
                                    <h5 className={styles.activityName}>
                                        Name of Activity
                                    </h5>
                                    <p className={styles.activityDescription}>
                                        Description
                                    </p>
                                </Col>
                                <Col md={4} className={styles.tempHidden}>
                                    <div
                                        className={`${styles.activityBlock} ${
                                            rightPanelSettings[panelIndex]
                                                ?.subType ===
                                                "SNAKES_AND_APPLES" &&
                                            styles.highlight
                                        }`}
                                        onClick={(ev) => {
                                            handleActivity(
                                                "SNAKES_AND_APPLES",
                                                ev
                                            );
                                        }}
                                    >
                                        <p>Snakes and apples (3)</p>
                                    </div>
                                    <h5 className={styles.activityName}>
                                        Name of Activity
                                    </h5>
                                    <p className={styles.activityDescription}>
                                        Description
                                    </p>
                                </Col>
                                <Col md={4} className={styles.tempHidden}>
                                    <div
                                        className={`${styles.activityBlock} ${
                                            rightPanelSettings[panelIndex]
                                                ?.subType === "TILE_MATCH" &&
                                            styles.highlight
                                        }`}
                                        onClick={(ev) => {
                                            handleActivity("TILE_MATCH", ev);
                                        }}
                                    >
                                        <p>Tile match (1)</p>
                                    </div>
                                    <h5 className={styles.activityName}>
                                        Name of Activity
                                    </h5>
                                    <p className={styles.activityDescription}>
                                        Description
                                    </p>
                                </Col>
                                <Col md={4} className={styles.tempHidden}>
                                    <div
                                        className={`${styles.activityBlock} ${
                                            rightPanelSettings[panelIndex]
                                                ?.subType ===
                                                "INTERACTIVE_STORY" &&
                                            styles.highlight
                                        }`}
                                        onClick={(ev) => {
                                            handleActivity(
                                                "INTERACTIVE_STORY",
                                                ev
                                            );
                                        }}
                                    >
                                        <p>Interactive story (2)</p>
                                    </div>
                                    <h5 className={styles.activityName}>
                                        Name of Activity
                                    </h5>
                                    <p className={styles.activityDescription}>
                                        Description
                                    </p>
                                </Col>
                                <Col xl={4} md={5}>
                                    <div
                                        className={`${styles.activityBlock} ${
                                            rightPanelSettings[panelIndex]
                                                ?.subType ===
                                                "BUILD_YOUR_DREAM" &&
                                            styles.highlight
                                        }`}
                                        onClick={(ev) => {
                                            handleActivity(
                                                "BUILD_YOUR_DREAM",
                                                ev
                                            );
                                        }}
                                    >
                                        <img
                                            className={styles.activityImg}
                                            src={BuildYourDream}
                                            alt="build"
                                        />
                                    </div>
                                    <h5 className={styles.activityName}>
                                        Build your dreams
                                    </h5>
                                    <p
                                        className={`${styles.activityDescription} ${styles.tempHidden}`}
                                    >
                                        Description
                                    </p>
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <div
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div className={styles.scroll}>
                                    <div className={styles.content}>
                                        {rightPanelSettings.map(
                                            (obj, index) => {
                                                return (
                                                    <div
                                                        className={
                                                            styles.lessonWrapper
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.classblock
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={(
                                                                event
                                                            ) => {
                                                                event.preventDefault();
                                                                if (
                                                                    event.target
                                                                        .id ==
                                                                        "downarrow" ||
                                                                    event.target
                                                                        .parentElement
                                                                        .id ==
                                                                        "downarrow" ||
                                                                    document.getElementById(
                                                                        "dropdown"
                                                                    ) ||
                                                                    show != null
                                                                ) {
                                                                } else {
                                                                    setShowWarningModal(
                                                                        {
                                                                            flag: true,
                                                                            type: "tab",
                                                                        }
                                                                    );
                                                                    setNextTabData(
                                                                        {
                                                                            obj: obj,
                                                                            index: index,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {index !==
                                                                panelIndex && (
                                                                <>
                                                                    <FontAwesomeIcon
                                                                        className={
                                                                            styles.minusicon
                                                                        }
                                                                        style={{
                                                                            width: "30px",
                                                                            position:
                                                                                "absolute",
                                                                            left: 0,
                                                                        }}
                                                                        icon={
                                                                            faPlus
                                                                        }
                                                                        onClick={(
                                                                            ev
                                                                        ) => {
                                                                            ev.stopPropagation();
                                                                            if (
                                                                                show !==
                                                                                undefined
                                                                            ) {
                                                                                setShow(
                                                                                    undefined
                                                                                );
                                                                            } else {
                                                                                setShow(
                                                                                    index
                                                                                );
                                                                                setRightPanelIndex(
                                                                                    index
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                    {show ===
                                                                        index && (
                                                                        <div
                                                                            className={
                                                                                styles.paneloption
                                                                            }
                                                                        >
                                                                            <p
                                                                                onClick={(
                                                                                    event
                                                                                ) => {
                                                                                    event.preventDefault();
                                                                                    handleImportOption(
                                                                                        index
                                                                                    );
                                                                                    setShow(
                                                                                        undefined
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Use
                                                                                Existing
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                    <FontAwesomeIcon
                                                                        className={
                                                                            styles.minusicon
                                                                        }
                                                                        style={{
                                                                            width: "30px",
                                                                            position:
                                                                                "absolute",
                                                                            right: 0,
                                                                        }}
                                                                        icon={
                                                                            faMinus
                                                                        }
                                                                        onClick={(
                                                                            ev
                                                                        ) => {
                                                                            ev.stopPropagation();
                                                                            removeLessionBlock(
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                </>
                                                            )}

                                                            <div
                                                                className={
                                                                    styles.customDropContainer
                                                                }
                                                            >
                                                                <CustomDropdown
                                                                    dropdownData={
                                                                        lessonData
                                                                    }
                                                                    width="100%"
                                                                    margin="auto"
                                                                    setData={(
                                                                        payload
                                                                    ) => {
                                                                        updateRightPanelSettings(
                                                                            index,
                                                                            payload.toUpperCase(),
                                                                            payload,
                                                                            (
                                                                                rpSettings
                                                                            ) =>
                                                                                updateSession(
                                                                                    rpSettings,
                                                                                    () => {
                                                                                        if (
                                                                                            payload ===
                                                                                            "Lesson"
                                                                                        ) {
                                                                                            history.push(
                                                                                                `/session/${sessionID}/tool?panelIndex=${index}${urlParam}`
                                                                                            );
                                                                                        } else if (
                                                                                            payload ===
                                                                                            "Video"
                                                                                        ) {
                                                                                            history.push(
                                                                                                `/session/${sessionID}/viewTool?panelIndex=${index}${urlParam}`
                                                                                            );
                                                                                        } else if (
                                                                                            payload ===
                                                                                            "Activity"
                                                                                        ) {
                                                                                            history.push(
                                                                                                `/session/${sessionID}/activity/list?panelIndex=${index}${urlParam}`
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                )
                                                                        );
                                                                    }}
                                                                    data={
                                                                        obj.label
                                                                            ? `${obj.label}`
                                                                            : handleData(
                                                                                  obj.type
                                                                              )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        {importModal && (
                                                            <div
                                                                className={
                                                                    styles.importModal
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    className={
                                                                        styles.crossIcon
                                                                    }
                                                                    icon={
                                                                        faTimes
                                                                    }
                                                                    onClick={
                                                                        handleImportModal
                                                                    }
                                                                />
                                                                <p
                                                                    className={
                                                                        styles.classModalText
                                                                    }
                                                                >
                                                                    Pick the
                                                                    class you
                                                                    would like
                                                                    to access
                                                                </p>
                                                                <div
                                                                    className={
                                                                        styles.modalClassWrapper
                                                                    }
                                                                >
                                                                    {selectClass ? (
                                                                        <div
                                                                            className={
                                                                                styles.modalClassScroll
                                                                            }
                                                                        >
                                                                            <Row>
                                                                                <Col>
                                                                                    <div
                                                                                        className={
                                                                                            styles.modalClassBlock
                                                                                        }
                                                                                    >
                                                                                        <img
                                                                                            className={
                                                                                                styles.modalCoverImage
                                                                                            }
                                                                                            src={
                                                                                                sessionData
                                                                                                    .image_ids[0] &&
                                                                                                sessionData
                                                                                                    .image_ids[0]
                                                                                                    .location
                                                                                            }
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <p
                                                                                        className={
                                                                                            styles.modalClassName
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            sessionData.class_name
                                                                                        }
                                                                                    </p>
                                                                                </Col>
                                                                                <Col></Col>
                                                                                <Col></Col>
                                                                            </Row>
                                                                            <div
                                                                                className={
                                                                                    styles.modalLessonWrapper
                                                                                }
                                                                            >
                                                                                {sessionData.configuration.right_panel_settings.map(
                                                                                    (
                                                                                        obj,
                                                                                        index
                                                                                    ) => {
                                                                                        return (
                                                                                            <div
                                                                                                className={
                                                                                                    styles.modalLesson
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    handleRightPanelSetting(
                                                                                                        index
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <p
                                                                                                    className={
                                                                                                        styles.modalLessonName
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        obj.type
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            className={
                                                                                styles.modalClassScroll
                                                                            }
                                                                        >
                                                                            <Row>
                                                                                {sessions.map(
                                                                                    (
                                                                                        obj,
                                                                                        index
                                                                                    ) => {
                                                                                        return (
                                                                                            <Col
                                                                                                md={
                                                                                                    4
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    className={
                                                                                                        styles.modalClassBlock
                                                                                                    }
                                                                                                    onClick={(
                                                                                                        event
                                                                                                    ) => {
                                                                                                        handleSelectClass(
                                                                                                            event,
                                                                                                            index
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    {obj
                                                                                                        .image_ids
                                                                                                        .length >=
                                                                                                        1 && (
                                                                                                        <div
                                                                                                            style={{
                                                                                                                position:
                                                                                                                    "relative",
                                                                                                                height: "100%",
                                                                                                            }}
                                                                                                        >
                                                                                                            {obj
                                                                                                                .image_ids
                                                                                                                .length >
                                                                                                                1 && (
                                                                                                                <>
                                                                                                                    <FontAwesomeIcon
                                                                                                                        style={{
                                                                                                                            color: "#83838b",
                                                                                                                            width: "30px",
                                                                                                                            position:
                                                                                                                                "absolute",
                                                                                                                            left: "-7px",
                                                                                                                            marginTop:
                                                                                                                                "10px",
                                                                                                                            zIndex: "8",
                                                                                                                            top: "20px",
                                                                                                                            cursor: "pointer",
                                                                                                                        }}
                                                                                                                        icon={
                                                                                                                            faLessThan
                                                                                                                        }
                                                                                                                        onClick={(
                                                                                                                            event
                                                                                                                        ) => {
                                                                                                                            event.preventDefault();
                                                                                                                            event.stopPropagation();
                                                                                                                            decreaseImgModalFlag(
                                                                                                                                obj
                                                                                                                                    .image_ids
                                                                                                                                    .length
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    <FontAwesomeIcon
                                                                                                                        style={{
                                                                                                                            color: "#83838b",
                                                                                                                            width: "30px",
                                                                                                                            position:
                                                                                                                                "absolute",
                                                                                                                            right: "-7px",
                                                                                                                            marginTop:
                                                                                                                                "10px",
                                                                                                                            zIndex: "8",
                                                                                                                            top: "20px",
                                                                                                                            cursor: "pointer",
                                                                                                                        }}
                                                                                                                        icon={
                                                                                                                            faGreaterThan
                                                                                                                        }
                                                                                                                        onClick={(
                                                                                                                            event
                                                                                                                        ) => {
                                                                                                                            event.preventDefault();
                                                                                                                            event.stopPropagation();
                                                                                                                            increaseImgModalFlag(
                                                                                                                                obj
                                                                                                                                    .image_ids
                                                                                                                                    .length
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </>
                                                                                                            )}
                                                                                                            {obj.image_ids.map(
                                                                                                                (
                                                                                                                    element,
                                                                                                                    index
                                                                                                                ) => {
                                                                                                                    const fileType =
                                                                                                                        element.location
                                                                                                                            .split(
                                                                                                                                "."
                                                                                                                            )
                                                                                                                            .pop();
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            {fileType ===
                                                                                                                            "mp4"
                                                                                                                                ? imgModalFlag ===
                                                                                                                                      index && (
                                                                                                                                      <video
                                                                                                                                          className={
                                                                                                                                              styles.modalCoverImage
                                                                                                                                          }
                                                                                                                                          src={
                                                                                                                                              element.location
                                                                                                                                          }
                                                                                                                                          controls
                                                                                                                                      ></video>
                                                                                                                                  )
                                                                                                                                : imgModalFlag ===
                                                                                                                                      index && (
                                                                                                                                      <img
                                                                                                                                          className={
                                                                                                                                              styles.modalCoverImage
                                                                                                                                          }
                                                                                                                                          src={
                                                                                                                                              element.location
                                                                                                                                          }
                                                                                                                                          alt=""
                                                                                                                                      />
                                                                                                                                  )}
                                                                                                                        </>
                                                                                                                    );
                                                                                                                }
                                                                                                            )}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                                <p
                                                                                                    className={
                                                                                                        styles.modalClassName
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        obj.class_name
                                                                                                    }
                                                                                                </p>
                                                                                            </Col>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Row>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>

                                <FontAwesomeIcon
                                    className={styles.plusicon}
                                    style={{
                                        width: "30px",
                                    }}
                                    icon={faPlus}
                                    onClick={addLessionBlock}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={2}>
                    <div
                        style={{ position: "relative", cursor: "pointer" }}
                        onClick={() => history.push("/home")}
                    >
                        <img src={Logo} className={styles.logoImg} alt="Logo" />
                        <h1 className={styles.logoText}>
                            plassroom<sup>+</sup>
                        </h1>
                        <p className={styles.logoSubText}>Teacher</p>
                        <h1 className={styles.rightTime}>
                            <Clock />
                        </h1>
                    </div>
                    <div
                        className={`${styles.rightTabSection} ${styles.studentScroll}`}
                    >
                        <FontAwesomeIcon
                            style={{
                                width: "30px",
                                marginLeft: "5rem",
                                cursor: "pointer",
                                opacity:
                                    rightPanelSettingsToShow[0]?._id !==
                                        rightPanelSettings[0]?._id &&
                                    rightPanelSettings.length > 5
                                        ? 1
                                        : 0,
                            }}
                            icon={faAngleUp}
                            onClick={moveSessionUp}
                        />
                        {rightPanelSettingsToShow.map((obj, index) => {
                            if (
                                obj._id === rightPanelSettings[panelIndex]._id
                            ) {
                                return (
                                    <div className={styles.rightTabContainer}>
                                        <h1
                                            contentEditable="true"
                                            className={styles.rightTab}
                                            onBlur={(ev) => {
                                                handleRightTab(ev, index);
                                            }}
                                            onKeyDown={(e) =>
                                                handleTextChange(e)
                                            }
                                            id={`righttab${index}`}
                                        >
                                            {obj.label ? (
                                                <>{obj.label}</>
                                            ) : (
                                                <>{rightTab(obj.type)}</>
                                            )}
                                        </h1>
                                        <img
                                            src={Edit}
                                            className={styles.editImg}
                                            alt="Logo"
                                        />
                                    </div>
                                );
                            } else {
                                return (
                                    <div
                                        style={{
                                            backgroundColor: "#C8E8E5",
                                        }}
                                        className={styles.rightTabContainer}
                                    >
                                        <h1
                                            onBlur={(ev) => {
                                                handleRightTab(ev, index);
                                            }}
                                            onKeyDown={(e) =>
                                                handleTextChange(e)
                                            }
                                            contentEditable="true"
                                            className={styles.rightTab}
                                            id={`righttab${index}`}
                                        >
                                            {obj.label ? (
                                                <>{obj.label}</>
                                            ) : (
                                                <>{rightTab(obj.type)}</>
                                            )}
                                        </h1>
                                    </div>
                                );
                            }
                        })}
                        <FontAwesomeIcon
                            style={{
                                width: "30px",
                                marginLeft: "5rem",
                                cursor: "pointer",
                                opacity:
                                    rightPanelSettingsToShow[
                                        rightPanelSettingsToShow.length - 1
                                    ]?._id !==
                                        rightPanelSettings[
                                            rightPanelSettings.length - 1
                                        ]?._id && rightPanelSettings.length > 5
                                        ? 1
                                        : 0,
                            }}
                            icon={faAngleDown}
                            onClick={moveSessionDown}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
};
