import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faAngleDown,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import CloseButton from "../../../assets/images/Close_button.png";
import FreeBG from "../../../assets/images/LOGO (4).svg";
import Snackbar from "../../../components/Snackbar";
import Logo from "../../../assets/images/plass_logo_new.svg";
import StudentLogoBg from "../../../assets/images/LOGO (7).png";

import styles from "./list.module.css";
import axios from "axios";
import Clock from "../../../components/Clock";
import moment from "moment";
// import { object } from "prop-types";
// import { constant } from "lodash";
import _ from "lodash";
import Loading from "../../../components/Loading/Loading";
import { useHistory } from "react-router-dom";

export default () => {
    const history = useHistory();
    const [unpublishedSessions, setUnpublishedSessions] = useState([]);
    const [publishedSessions, setPublishedSessions] = useState([]);
    const [show, setShow] = useState(undefined);
    const [imgFlag, setImgFlag] = useState(0);
    const [publish, setPublish] = useState(false);
    const [publishSession, setPublishSession] = useState({
        isPublished: false,
        classDetails: undefined,
    });
    const [dataUpdated, setDataUpdated] = useState(false);

    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteClassId, setDeleteClassId] = useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const [loading, setLoading] = useState(false);

    let date = new Date();
    // const currentTime = moment(date).format("HH:mm");
    // let formatDate = date.toLocaleDateString();
    const userLocal = JSON.parse(localStorage.getItem("auth")).user;
    const [versionTime, setVersionTime] = useState(undefined);
    const [user, setUser] = useState(undefined);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    useEffect(() => {
        const getUserDetails = async () => {
            await axios
                .get("/api/v1/user")
                .then((result) => {
                    setUser(result.data.user);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };

        async function fetchData() {
            setLoading(true);
            await axios
                .get("/api/v1/sessions")
                .then((response) => {
                    setLoading(false);
                    const sessions = response.data.sessions.filter((obj) => {
                        return obj.teacher_id?._id === userLocal._id;
                    });
                    if (response.data.success) {
                        const updateTimes = response.data.sessions.map(
                            (session) => {
                                return moment.utc(session.updatedAt).local();
                            }
                        );
                        const lastUpdatedSessionTime = moment(
                            Math.max.apply(null, updateTimes)
                        ).local();
                        setVersionTime(lastUpdatedSessionTime.format("HH:mm"));
                    }

                    let unpublishedSessionsArr = sessions.filter((obj) => {
                        return obj.session_type === "UNPUBLISHED";
                    });
                    unpublishedSessionsArr.sort(function (a, b) {
                        const firstTime = moment(a.updatedAt);
                        const secondTime = moment(b.updatedAt);

                        if (firstTime.isBefore(secondTime)) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });
                    // unpublishedSessionsArr.map((session) => {
                    //      console.log(session.updatedAt);
                    // });
                    setUnpublishedSessions(unpublishedSessionsArr);

                    let publishedSessionsArr = sessions.filter((obj) => {
                        return obj.session_type === "PUBLISHED";
                    });
                    publishedSessionsArr.sort(function (a, b) {
                        const firstTime = moment(
                            a.configuration.session_start_time
                        );
                        const secondTime = moment(
                            b.configuration.session_start_time
                        );

                        if (firstTime.isBefore(secondTime)) {
                            return -1;
                        } else {
                            return 1;
                        }
                    });
                    setPublishedSessions(publishedSessionsArr);
                })
                .catch((error) => {
                    setLoading(false);
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        }
        fetchData();
        getUserDetails();
        // eslint-disable-next-line
    }, [dataUpdated]);

    const handleClick = async (type) => {
        await axios
            .post("/api/v1//create-class", { type })
            .then((result) => {
                if (result.data.success) {
                    history.push("/session/" + result.data.data.sessionId);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const handleDuplicate = async (id) => {
        await axios
            .post(`api/v1/session/${id}/duplicate`)
            .then((result) => {
                if (result.data.success) {
                    history.push("/session/" + result.data.data.sessionId);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const handleDelete = async (id) => {
        setDeleteModal(true);
        setDeleteClassId(id);
    };

    const updateSession = async () => {
        const sessionData = {
            id: publishSession.classDetails._id,
            sessionType: publishSession.isPublished
                ? "PUBLISHED"
                : "UNPUBLISHED",
        };
        await axios
            .put("/api/v1/sessions", sessionData)
            .then((result) => {
                if (result.data.success && publishSession.isPublished) {
                    history.push("/effect/" + result.data.data._id);
                } else {
                    setPublish(false);
                    setShow(undefined);
                    setDataUpdated(!dataUpdated);
                    setMsg(result?.data?.message);
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const handlePublish = (obj, flag) => {
        let incompleteConfig = true;

        if (!publish && flag) {
            obj.configuration.right_panel_settings.map((panel) => {
                if (!panel.isConfigurationCompleted) {
                    incompleteConfig = false;
                }
            });
        }

        if (incompleteConfig || !flag) {
            setPublish(true);
            setPublishSession({
                isPublished: flag,
                classDetails: obj,
            });
        } else {
            setMsg(
                "This class cannot be published as some of your class blocks have not been completed."
            );
            setShowSnackbar(true);
        }
    };

    const handlePublishClass = () => {
        updateSession();
    };

    const closeModal = () => {
        setPublish(false);
    };

    const handleDeleteConfirm = async () => {
        const data = {
            id: deleteClassId,
        };
        await axios
            .post("/api/v1/session/delete", data)
            .then((result) => {
                if (result.data.success) {
                    setDeleteModal(false);
                    setShow(undefined);
                } else {
                    setMsg(
                        _.get(result, "data.message", "Something went wrong.")
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
                setDataUpdated(!dataUpdated);
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
            });
    };

    return (
        <div className={styles.classList}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <Row>
                <Col xs={10} sm={10} className={styles.leftsection}>
                    {publish && (
                        <div className={styles.modal}>
                            <FontAwesomeIcon
                                className={styles.modalCross}
                                style={{ width: "35px", height: "35px" }}
                                icon={faTimes}
                                onClick={closeModal}
                            />
                            {publishSession.isPublished ? (
                                <p className={styles.modalText}>
                                    Are you sure you would like to publish this
                                    class onto the Store+?
                                </p>
                            ) : (
                                <>
                                    <p className={styles.modalText}>
                                        Are you sure you would like to remove
                                        this class from the Store+. The students
                                        that have signed up will be fully
                                        refunded the class fees.
                                    </p>
                                </>
                            )}

                            <p
                                className={styles.modalButton}
                                onClick={handlePublishClass}
                            >
                                {publishSession.isPublished
                                    ? "Publish"
                                    : "Unpublish"}
                            </p>
                        </div>
                    )}

                    {deleteModal && (
                        <div className={styles.modal}>
                            <FontAwesomeIcon
                                className={styles.modalCross}
                                style={{ width: "35px", height: "35px" }}
                                icon={faTimes}
                                onClick={() => setDeleteModal(false)}
                            />
                            <p className={styles.modalText}>
                                You will not be able to recover this class after
                                deleting it.
                                <br></br>
                                <br></br>
                                Would you still like to proceed?
                            </p>

                            <p
                                className={styles.modalButton}
                                onClick={() => {
                                    handleDeleteConfirm();
                                }}
                            >
                                Delete
                            </p>
                        </div>
                    )}
                    <div
                        className={styles.leftSection}
                        style={{
                            backgroundColor: loading ? "#fff" : "#f3f3f3",
                        }}
                    >
                        <div className={styles.leftScroll}>
                            <div className={styles.sessionblock}>
                                <div className={styles.crosssection}>
                                    <img
                                        onClick={() => {
                                            history.push("/home");
                                        }}
                                        src={CloseButton}
                                        style={{ cursor: "pointer" }}
                                        alt="Logo"
                                        className={styles.crossButton}
                                    />
                                    {versionTime && (
                                        <p
                                            className={styles.italicPara}
                                            style={{ marginBottom: "0px" }}
                                        >
                                            Last version saved at {versionTime}
                                        </p>
                                    )}
                                </div>

                                <div>
                                    <h2
                                        className={styles.heading}
                                        style={{ color: "#88ADDB" }}
                                    >
                                        Welcome, {user?.preferedName}
                                    </h2>
                                </div>
                            </div>

                            {loading ? (
                                <Loading />
                            ) : (
                                <>
                                    <div className={styles.publishedClass}>
                                        <h3 className={styles.heading}>
                                            Published Classes
                                        </h3>
                                    </div>
                                    <div className={styles.scroll}>
                                        {publishedSessions.map((obj, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={
                                                        styles.classBoxWrapper
                                                    }
                                                    onClick={() =>
                                                        history.push(
                                                            "/session/" +
                                                                obj._id
                                                        )
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.classBox
                                                        }
                                                    >
                                                        {obj?.type ===
                                                        "Course" ? (
                                                            <div
                                                                className={
                                                                    styles.freeBG
                                                                }
                                                            >
                                                                <h1
                                                                    className={
                                                                        styles.courseText
                                                                    }
                                                                >
                                                                    Course
                                                                </h1>
                                                                <img
                                                                    src={
                                                                        StudentLogoBg
                                                                    }
                                                                    className={
                                                                        styles.studentLogoBg
                                                                    }
                                                                    alt="Logo"
                                                                />
                                                            </div>
                                                        ) : obj.is_free_class ? (
                                                            <img
                                                                src={FreeBG}
                                                                alt=""
                                                                className={
                                                                    styles.freeBG
                                                                }
                                                            />
                                                        ) : null}
                                                        <FontAwesomeIcon
                                                            className={
                                                                styles.angleDownIcon
                                                            }
                                                            style={{
                                                                width: "35px",
                                                                height: "35px",
                                                            }}
                                                            icon={faAngleDown}
                                                            onClick={(ev) => {
                                                                ev.stopPropagation();
                                                                if (show) {
                                                                    setShow(
                                                                        undefined
                                                                    );
                                                                } else {
                                                                    setShow(
                                                                        obj._id
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        {show === obj._id && (
                                                            <div
                                                                className={
                                                                    styles.option
                                                                }
                                                            >
                                                                <p
                                                                    onClick={(
                                                                        event
                                                                    ) => {
                                                                        event.preventDefault();
                                                                        event.stopPropagation();
                                                                        handlePublish(
                                                                            obj,
                                                                            false
                                                                        );
                                                                    }}
                                                                >
                                                                    Unpublish
                                                                </p>
                                                                {obj.is_free_class ? null : (
                                                                    <p
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            event.preventDefault();
                                                                            event.stopPropagation();
                                                                            handleDuplicate(
                                                                                obj._id
                                                                            );
                                                                        }}
                                                                    >
                                                                        Duplicate
                                                                    </p>
                                                                )}
                                                            </div>
                                                        )}
                                                        {obj.image_ids.length >=
                                                            1 && (
                                                            <div
                                                                style={{
                                                                    position:
                                                                        "relative",
                                                                    height: "100%",
                                                                }}
                                                            >
                                                                {obj.image_ids.map(
                                                                    (
                                                                        element,
                                                                        index
                                                                    ) => {
                                                                        const fileType =
                                                                            element.location
                                                                                .split(
                                                                                    "."
                                                                                )
                                                                                .pop();
                                                                        return (
                                                                            <>
                                                                                {fileType ===
                                                                                "mp4"
                                                                                    ? imgFlag ===
                                                                                          index && (
                                                                                          <video
                                                                                              key={
                                                                                                  index
                                                                                              }
                                                                                              className={
                                                                                                  styles.coverImage
                                                                                              }
                                                                                              src={
                                                                                                  element.location
                                                                                              }
                                                                                              controls
                                                                                          ></video>
                                                                                      )
                                                                                    : imgFlag ===
                                                                                          index && (
                                                                                          <img
                                                                                              key={
                                                                                                  index
                                                                                              }
                                                                                              className={
                                                                                                  styles.coverImage
                                                                                              }
                                                                                              src={
                                                                                                  element.location
                                                                                              }
                                                                                              alt=""
                                                                                          />
                                                                                      )}
                                                                            </>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginLeft: "5px",
                                                        }}
                                                    >
                                                        <p
                                                            className={
                                                                styles.className
                                                            }
                                                        >
                                                            {obj.class_name}
                                                        </p>
                                                        {/* <div
                                                    className={styles.dateTime}
                                                >
                                                    <p className={styles.date}>
                                                        {moment(
                                                            obj.configuration
                                                                .session_start_time
                                                        ).format("YYYY-MM-DD")}
                                                    </p>
                                                    <p className={styles.time}>
                                                        {moment(
                                                            obj.configuration
                                                                .session_start_time
                                                        ).format("HH:mm")}
                                                    </p>
                                                </div> */}
                                                        {/* <p className={styles.dateTime}>
                                                    {obj.students_ids.length}{" "}
                                                    students booked in
                                                </p> */}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        {publishedSessions.length === 0 && (
                                            <h2 className={styles.text}>
                                                You have no published classes
                                                currently.
                                            </h2>
                                        )}
                                    </div>
                                    <div className={styles.unpublishedsection}>
                                        <h3 className={styles.heading}>
                                            Unpublished Classes
                                        </h3>
                                        <div
                                            className={
                                                showMenu
                                                    ? styles.buttonWrapper
                                                    : ""
                                            }
                                            style={{ padding: "5px" }}
                                        >
                                            <FontAwesomeIcon
                                                className={styles.plusIcon}
                                                style={{ width: "3vh" }}
                                                icon={faPlus}
                                                onClick={() => {
                                                    // handleClick();
                                                    setShowMenu(!showMenu);
                                                }}
                                            />
                                            {showMenu && (
                                                <div
                                                    className={
                                                        styles.menuOption
                                                    }
                                                >
                                                    <p
                                                        onClick={() =>
                                                            handleClick(
                                                                "Session"
                                                            )
                                                        }
                                                    >
                                                        Session
                                                    </p>
                                                    <p
                                                        onClick={() =>
                                                            handleClick(
                                                                "Course"
                                                            )
                                                        }
                                                    >
                                                        Course
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.scroll}>
                                        {unpublishedSessions.map(
                                            (obj, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={
                                                            styles.classBoxWrapper
                                                        }
                                                        onClick={() =>
                                                            history.push(
                                                                "/session/" +
                                                                    obj._id
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.classBox
                                                            }
                                                        >
                                                            {obj?.type ===
                                                            "Course" ? (
                                                                <div
                                                                    className={
                                                                        styles.freeBG
                                                                    }
                                                                >
                                                                    <h1
                                                                        className={
                                                                            styles.courseText
                                                                        }
                                                                    >
                                                                        Course
                                                                    </h1>
                                                                    <img
                                                                        src={
                                                                            StudentLogoBg
                                                                        }
                                                                        className={
                                                                            styles.studentLogoBg
                                                                        }
                                                                        alt="Logo"
                                                                    />
                                                                </div>
                                                            ) : obj.is_free_class ? (
                                                                <img
                                                                    src={FreeBG}
                                                                    alt=""
                                                                    className={
                                                                        styles.freeBG
                                                                    }
                                                                />
                                                            ) : null}
                                                            <FontAwesomeIcon
                                                                className={
                                                                    styles.angleDownIcon
                                                                }
                                                                style={{
                                                                    width: "35px",
                                                                    height: "35px",
                                                                }}
                                                                icon={
                                                                    faAngleDown
                                                                }
                                                                onClick={(
                                                                    ev
                                                                ) => {
                                                                    ev.stopPropagation();
                                                                    if (show) {
                                                                        setShow(
                                                                            undefined
                                                                        );
                                                                    } else {
                                                                        setShow(
                                                                            obj._id
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                            {show ===
                                                                obj._id && (
                                                                <div
                                                                    className={
                                                                        styles.option
                                                                    }
                                                                >
                                                                    <p
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            event.preventDefault();
                                                                            event.stopPropagation();
                                                                            handlePublish(
                                                                                obj,
                                                                                true
                                                                            );
                                                                        }}
                                                                    >
                                                                        Publish
                                                                    </p>
                                                                    {obj.is_free_class ? null : (
                                                                        <p
                                                                            onClick={(
                                                                                event
                                                                            ) => {
                                                                                event.preventDefault();
                                                                                event.stopPropagation();
                                                                                handleDuplicate(
                                                                                    obj._id
                                                                                );
                                                                            }}
                                                                        >
                                                                            Duplicate
                                                                        </p>
                                                                    )}
                                                                    {obj.is_free_class ? null : (
                                                                        <p
                                                                            onClick={(
                                                                                event
                                                                            ) => {
                                                                                event.preventDefault();
                                                                                event.stopPropagation();
                                                                                handleDelete(
                                                                                    obj._id
                                                                                );
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {obj.image_ids
                                                                .length >=
                                                                1 && (
                                                                <div
                                                                    style={{
                                                                        position:
                                                                            "relative",
                                                                        height: "100%",
                                                                    }}
                                                                >
                                                                    {obj.image_ids.map(
                                                                        (
                                                                            element,
                                                                            index
                                                                        ) => {
                                                                            const fileType =
                                                                                element.location
                                                                                    .split(
                                                                                        "."
                                                                                    )
                                                                                    .pop();
                                                                            return (
                                                                                <>
                                                                                    {fileType ===
                                                                                    "mp4"
                                                                                        ? imgFlag ===
                                                                                              index && (
                                                                                              <video
                                                                                                  key={
                                                                                                      index
                                                                                                  }
                                                                                                  className={
                                                                                                      styles.coverImage
                                                                                                  }
                                                                                                  src={
                                                                                                      element.location
                                                                                                  }
                                                                                                  controls
                                                                                              ></video>
                                                                                          )
                                                                                        : imgFlag ===
                                                                                              index && (
                                                                                              <img
                                                                                                  key={
                                                                                                      index
                                                                                                  }
                                                                                                  className={
                                                                                                      styles.coverImage
                                                                                                  }
                                                                                                  src={
                                                                                                      element.location
                                                                                                  }
                                                                                                  alt=""
                                                                                              />
                                                                                          )}
                                                                                </>
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div
                                                            style={{
                                                                marginLeft:
                                                                    "5px",
                                                            }}
                                                        >
                                                            <p
                                                                className={
                                                                    styles.className
                                                                }
                                                            >
                                                                {obj.class_name}
                                                            </p>
                                                            {/* <div
                                                    className={styles.dateTime}
                                                >
                                                    <p className={styles.date}>
                                                        {moment(
                                                            obj.configuration
                                                                .session_start_time
                                                        ).format("YYYY-MM-DD")}
                                                    </p>
                                                    <p className={styles.time}>
                                                        {moment(
                                                            obj.configuration
                                                                .session_start_time
                                                        ).format("HH:mm")}
                                                    </p>
                                                </div> */}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                        {unpublishedSessions.length === 0 && (
                                            <h2 className={styles.text}>
                                                You have no unpublished classes
                                                currently.
                                            </h2>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Col>
                <Col xs={2} sm={2}>
                    <div
                        style={{ position: "relative", cursor: "pointer" }}
                        onClick={() => history.push("/home")}
                    >
                        <img src={Logo} className={styles.logoImg} alt="Logo" />
                        <h1 className={styles.logoText}>
                            plassroom<sup>+</sup>
                        </h1>
                        <p className={styles.logoSubText}>Teacher</p>
                        <h1 className={styles.rightTime}>
                            <Clock />
                        </h1>
                    </div>
                </Col>
            </Row>
            )
        </div>
    );
};
