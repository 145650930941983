import React, { useState } from "react";
import styles from "./userCeate.module.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Snackbar from "../../../components/Snackbar";
import _ from "lodash";

export default () => {
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userName, setUserName] = useState("");
    const [role, setRole] = useState("");
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const createUser = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        await axios
            .post("/api/v1/user/register", {
                username: userName,
                password,
                role: role ? role.value : null,
                email,
            })
            .then((response) => {
                if (response.data.success) {
                    history.replace("/admin");
                } else {
                    setMsg(response.data.message);
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };
    return (
        <Container fluid id={styles.container}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <FontAwesomeIcon
                icon={faTimes}
                onClick={() => {
                    history.push("/admin");
                }}
                id={styles.icon}
            />
            <Row>
                <Col sm={4} id={styles.userHeader}>
                    <h1 id={styles.heading}>Create User</h1>
                    <Form onSubmit={createUser}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                onChange={(ev) => setEmail(ev.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter UserName"
                                value={userName}
                                onChange={(ev) => setUserName(ev.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>User Role</Form.Label>
                            <Select
                                onChange={(data) => {
                                    setRole(data);
                                }}
                                value={role}
                                className="basic-single"
                                classNamePrefix="select"
                                // defaultValue={colourOptions[0]}
                                name="teacher"
                                options={[
                                    { label: "Teacher", value: "teacher" },
                                    { label: "Student", value: "student" },
                                ]}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(ev) => setPassword(ev.target.value)}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Create
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};
