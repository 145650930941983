import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./emailPref.module.css";
import "./style.css";
import Snackbar from "../../Snackbar";
import _ from "lodash";
import Update from "../../../assets/images/icon/accountSettings/Update.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ComingSoon from "./ComingSoon";
import PrivacyPolicy from "../../../assets/documents/Privacy Policy 1.pdf";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

export default function EmailPreference(props) {
    const [companyNewsletterChecked, setCompanyNewsletterChecked] =
        useState(false);
    const [marketingMessages, setMarketingMessages] = useState(false);
    const [notification, setNotification] = useState(false);
    const [systemRelated, setSytemRelated] = useState(false);
    const [wheneverAvailable, setWheneverAvailable] = useState(false);
    const [weekly, setWeekly] = useState(false);
    const [monthly, setMonthly] = useState(false);
    const [user, setUser] = useState(false);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [preference, setPreference] = useState({
        companyNewsletter: "",
        marketingMessages: "",
        notification: "",
        email: "",
        wheneverAvailable: "",
        weekly: "",
        monthly: "",
    });
    const [frequency, setFrequency] = useState("");
    const [snackBarStatus, setSnackBarStatus] = useState("error");
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [newEmail, setNewEmail] = useState("");
    const [detailsUpdated, setDetailsUpdated] = useState(false);

    useEffect(() => {
        getUserDetails();
        // eslint-disable-next-line
    }, []);

    const getUserDetails = async () => {
        await axios
            .get("api/v1/user/email/preferences")
            .then((result) => {
                setUser(result?.data?.data);
                setPreference(result?.data?.data?.emailPreferences);
                setCompanyNewsletterChecked(
                    result?.data?.data?.emailPreferences?.companynewsletter
                );
                setMarketingMessages(
                    result?.data?.data?.emailPreferences?.marketingMessages
                );
                setNotification(
                    result?.data?.data?.emailPreferences
                        ?.notificationAndTaskReminders
                );
                setSytemRelated(
                    result?.data?.data?.emailPreferences?.systemRelated
                );
                setFrequency(result?.data?.data?.emailPreferences?.frequency);
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const changeEmail = () => {
        axios
            .put("api/v1/user/email/change", {
                email: newEmail,
            })
            .then((response) => {
                if (response?.data?.success === true) {
                    setMsg(
                        "Email has been sent for email verification. Please verify to proceed."
                    );
                    setSnackBarStatus("information");
                    setShowSnackbar(true);
                    setTimeout(() => {
                        setMsg(null);
                    }, 3000);
                    getUserDetails();
                    setIsPreviewModalOpen(false);
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
                setMsg("Something went wrong.");
                setSnackBarStatus("error");
                setShowSnackbar(true);
                setTimeout(() => {
                    setMsg(null);
                }, 4000);
                getUserDetails();
            });
    };

    const handlePreferences = () => {
        const preferenceData = {
            companynewsletter: true,
            frequency: frequency,
            marketingMessages: true,
            notificationAndTaskReminders: true,
            systemRelated: systemRelated,
        };
        axios
            .post("api/v1/user/email/preferences", preferenceData)
            .then((response) => {
                if (response?.data?.success === true) {
                    getUserDetails();
                    setSnackBarStatus("information");
                    setMsg("Details saved successfully");
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    setDetailsUpdated(true);
                } else {
                    setSnackBarStatus("error");
                    setMsg(
                        _.get("response.data.title", "Something went wrong.")
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                console.log(error);
                setSnackBarStatus("error");
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
            });
    };

    const handleFrequency = (frequency) => {
        setFrequency(frequency);
        if (frequency === "weekly") {
            setWheneverAvailable(false);
            setWeekly(true);
            setMonthly(false);
        } else if (frequency === "monthly") {
            setWheneverAvailable(false);
            setWeekly(false);
            setMonthly(true);
        } else if (frequency === "anytime") {
            setWheneverAvailable(true);
            setWeekly(false);
            setMonthly(false);
        } else if (frequency === "off") {
            setWheneverAvailable(false);
            setWeekly(false);
            setMonthly(false);
        }
    };

    return (
        <React.Fragment>
            {msg && (
                <Snackbar
                    status={snackBarStatus}
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            {isPreviewModalOpen && (
                <div className={styles.uploadModalImage}>
                    <div className={styles.uploadModalContainer}>
                        <FontAwesomeIcon
                            className={styles.crossIconImageModal}
                            icon={faTimes}
                            onClick={() => setIsPreviewModalOpen(false)}
                        />
                        <div className={styles.previewImage__container}>
                            <input
                                className={styles.inputBox}
                                type="text"
                                placeholder="Enter new email"
                                value={newEmail}
                                onChange={(e) => setNewEmail(e.target.value)}
                            />
                            <img
                                src={Update}
                                onClick={() => newEmail && changeEmail()}
                            />
                        </div>
                    </div>
                </div>
            )}
            {
                <div>
                    <div className={`email-profile ${styles.studentScroll}`}>
                        <div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="personal-detail">
                                        <h3>Email address</h3>
                                        <div className="personal-detail-inner">
                                            <div className="input-item">
                                                <h4>{user?.email}</h4>
                                            </div>
                                        </div>
                                        <p
                                            className={styles.oneRem}
                                            style={{
                                                cursor: "pointer",
                                                color: "#88ADDB",
                                                fontFamily: "Comforta",
                                                fontSize: "13px",
                                                fontWeight: "normal",
                                                marginBottom: "0",
                                                marginLeft: "15px",
                                                marginTop: "5px",
                                            }}
                                            onClick={() =>
                                                setIsPreviewModalOpen(true)
                                            }
                                        >
                                            Change
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="personal-detail">
                                        <h3>Frequency of notifications</h3>
                                        <div className="email-check-outer">
                                            <div className="emali-checklist">
                                                <div className="check-detail">
                                                    <p>
                                                        Newsletter & educational
                                                        resources
                                                        <br /> Upcoming sessions
                                                        <br /> Marketing
                                                        messages
                                                    </p>
                                                </div>
                                                <div className="check-inner">
                                                    <Form>
                                                        {["radio"].map(
                                                            (type) => (
                                                                <div
                                                                    key={`inline-${type}`}
                                                                    className="checkbox-list"
                                                                >
                                                                    <Form.Check
                                                                        inline
                                                                        label="Weekly"
                                                                        name="group1"
                                                                        type={
                                                                            type
                                                                        }
                                                                        id={`inline-${type}-1`}
                                                                        onClick={() =>
                                                                            handleFrequency(
                                                                                "weekly"
                                                                            )
                                                                        }
                                                                        checked={
                                                                            frequency ===
                                                                            "weekly"
                                                                        }
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Monthly"
                                                                        name="group1"
                                                                        type={
                                                                            type
                                                                        }
                                                                        id={`inline-${type}-2`}
                                                                        onClick={() =>
                                                                            handleFrequency(
                                                                                "monthly"
                                                                            )
                                                                        }
                                                                        checked={
                                                                            frequency ===
                                                                            "monthly"
                                                                        }
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Off"
                                                                        name="group1"
                                                                        type={
                                                                            type
                                                                        }
                                                                        id={`inline-${type}-3`}
                                                                        onClick={() =>
                                                                            handleFrequency(
                                                                                "off"
                                                                            )
                                                                        }
                                                                        checked={
                                                                            frequency ===
                                                                            "off"
                                                                        }
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </Form>
                                                </div>
                                            </div>
                                            <div className="emali-checklist">
                                                <div className="check-detail">
                                                    <p>
                                                        Booking reminders System
                                                        notifications
                                                    </p>
                                                </div>
                                                <div className="check-inner">
                                                    <Form>
                                                        {["radio"].map(
                                                            (type) => (
                                                                <div
                                                                    key={`inline-${type}`}
                                                                    className="checkbox-list"
                                                                >
                                                                    <Form.Check
                                                                        inline
                                                                        label="Always"
                                                                        name="group2"
                                                                        onClick={() =>
                                                                            setSytemRelated(
                                                                                true
                                                                            )
                                                                        }
                                                                        checked={
                                                                            systemRelated ===
                                                                            true
                                                                        }
                                                                        type={
                                                                            type
                                                                        }
                                                                        id={`inline-${type}-1`}
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Off"
                                                                        name="group2"
                                                                        onClick={() =>
                                                                            setSytemRelated(
                                                                                false
                                                                            )
                                                                        }
                                                                        checked={
                                                                            systemRelated ===
                                                                            false
                                                                        }
                                                                        type={
                                                                            type
                                                                        }
                                                                        id={`inline-${type}-2`}
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </Form>
                                                </div>
                                            </div>

                                            <p
                                                className={styles.oneRem}
                                                style={{
                                                    color: "#13385C",
                                                    marginTop: "61px",
                                                }}
                                            >
                                                Please take note that these
                                                changes will take place
                                                beginning the next email being
                                                sent out. Thank you.
                                            </p>
                                            <p
                                                className={styles.oneRem}
                                                style={{
                                                    color: "#88ADDB",
                                                    fontSize: "18px",
                                                    fontFamily: "Comforta",
                                                    cursor: "pointer",
                                                    width: "fit-content",
                                                }}
                                            >
                                                <Link to="/privacypolicy">
                                                    See our Privacy Policy & Non
                                                    Discrimination Policy
                                                </Link>
                                            </p>

                                            <div
                                                className={styles.updateButton}
                                            >
                                                <img
                                                    src={Update}
                                                    onClick={() =>
                                                        handlePreferences()
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    );
}
