import styles from "./index.module.scss";
import React from "react";
import Coin from "../../../../assets/images/Plasscoin.svg";
import { dateFormat } from "../../../../utils/commonFunction";

const SubscriptionDetailsBox = (props) => {
    const { title, endTitle, totalPlassCoins, plans, details } = props;
    const { subscription, plan } = details;
    return (
        <div className={styles.container}>
            {/* <h4 className={styles.tableTitle}>Subscription Details</h4> */}
            <div className={`sub-detail ${styles.table}`}>
                <div className={`sub-detail-inner ${styles.tableRow}`}>
                    <p className={styles.label}>
                        {!!plan?.type ? `The ${plan?.type} Plan` : "-"}
                    </p>
                    <p className={styles.lightvalue}>
                        {subscription?.subscriptionStartDate
                            ? `as of ${dateFormat(
                                  subscription?.subscriptionStartDate,
                                  "DD MMM YYYY"
                              )} `
                            : "-"}
                    </p>
                </div>
                <div className={`sub-detail-inner ${styles.tableRow}`}>
                    <p className={styles.label}>Next refresh</p>
                    <p
                        className={styles.label}
                        style={{ textAlign: "right", paddingRight: 0 }}
                    >
                        {dateFormat(
                            subscription?.subscriptionEndDate,
                            "DD MMM YYYY"
                        )}
                    </p>
                </div>
                <div className={`sub-detail-inner ${styles.tableRow}`}>
                    <p className={styles.label}>
                        {totalPlassCoins || 0}
                        <img src={Coin} className={styles.coinSvg} /> Plasscoins
                    </p>
                    <div className={styles.lastRightCell}>
                        {plans?.map((p, i) => {
                            return (
                                <>
                                    <p className={styles.lightvalue} key={i}>
                                        {p?.totalRemainingMinutes}
                                        <img
                                            src={Coin}
                                            className={styles.coinSvg}
                                        />{" "}
                                        {p?._id}
                                    </p>
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="subscription-des">
                <p className={styles.tableFooter}>
                    Balance Plasscoins will rollover for 3 months when
                    subscribed to the plan
                </p>
            </div>
        </div>
    );
};

export default SubscriptionDetailsBox;
