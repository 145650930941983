import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faAngleUp,
    faMinus,
    faAngleDown,
    faTimes,
    faLessThan,
    faGreaterThan,
} from "@fortawesome/free-solid-svg-icons";
import View1 from "../../../../assets/images/i_v student1.svg";
import View2 from "../../../../assets/images/i_v student2.svg";
import View3 from "../../../../assets/images/i_v student3.svg";
import View4 from "../../../../assets/images/i_v student4.svg";
import ViewT1 from "../../../../assets/images/i_v teacher1.svg";
import ViewT2 from "../../../../assets/images/i_v teacher2.svg";
import ViewT3 from "../../../../assets/images/i_v teacher3.svg";
import ViewT4 from "../../../../assets/images/i_v teacher4.svg";
import Logo from "../../../../assets/images/plass_logo_new.svg";
import styles from "./view.module.css";
import CustomDropdown from "../../../../components/CustomDropdown";
import Clock from "../../../../components/Clock";
import axios from "axios";
import Edit from "../../../../assets/images/edit.png";
import moment from "moment-timezone";
import CloseButton from "../../../../assets/images/Close_button.png";
import ScreenIcon from "../../../../assets/images/ScreenArrangement.png";
import PlayIcon from "../../../../assets/images/GreyPlayIcon.png";
import UploadContent from "../../../../assets/images/UploadContent.png";
import Snackbar from "../../../../components/Snackbar";
import _ from "lodash";
import WarningModal from "../../../../components/WarningModal";

export default (props) => {
    const [rightPanelSettings, setRightPanelSettings] = useState([]);
    const [rightPanelSettingsToShow, setRightPanelSettingsToShow] = useState(
        []
    );
    const { sessionID } = useParams();
    const location = useLocation();
    const history = useHistory();
    const panelindex = Number(
        new URLSearchParams(location.search).get("panelIndex")
    );
    const panelSlotId =
        new URLSearchParams(location.search).get("slotId") || null;
    const [studentView, setStudentView] = useState(undefined);
    const [teacherView, setTeacherView] = useState(undefined);
    const [versionTime, setVersionTime] = useState(undefined);
    const [panelIndex, setPanelIndex] = useState(panelindex);
    const [showOption, setShowOption] = useState(false);
    const [rightPanelIndex, setRightPanelIndex] = useState(undefined);
    const [sessions, setSessions] = useState([]);
    const [imgModalFlag, setImgModalFlag] = useState(0);
    const [show, setShow] = useState(null);
    const [importModal, setImportModal] = useState(false);
    const [selectClass, setSelectClass] = useState(false);
    const [sessionData, setSessionData] = useState();
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState({
        flag: false,
        type: null,
    });
    const [nextTabData, setNextTabData] = useState({
        obj: null,
        index: null,
    });
    const urlParam = !!panelSlotId ? `&slotId=${panelSlotId}` : ``;

    useEffect(() => {
        const getSessionInfo = async () => {
            await axios
                .get(`/api/v1/class/${sessionID}`)
                .then(async (result) => {
                    if (result.data.success) {
                        const updateTime = moment
                            .utc(
                                result.data.data.session.updatedAt,
                                "DD/MM/YYYY HH:mm"
                            )
                            .local()
                            .format("HH:mm");
                        setVersionTime(updateTime);

                        let rightPanelSettingsRes =
                            result.data.data.session.configuration
                                .right_panel_settings;
                        let emptyData = false;

                        let studentViewInit =
                            rightPanelSettingsRes[panelIndex]?.lesson_settings
                                ?.assets?.view?.student;
                        if (!studentViewInit) {
                            emptyData = true;
                            studentViewInit = "view1";
                            rightPanelSettingsRes[
                                panelIndex
                            ].lesson_settings.assets.view = {
                                ...rightPanelSettingsRes[panelIndex]
                                    .lesson_settings.assets.view,
                                student: "view1",
                            };
                        }
                        setStudentView(studentViewInit);

                        let teacherViewInit =
                            rightPanelSettingsRes[panelIndex]?.lesson_settings
                                ?.assets?.view?.teacher;
                        if (!teacherViewInit) {
                            emptyData = true;
                            teacherViewInit = "viewT1";
                            rightPanelSettingsRes[
                                panelIndex
                            ].lesson_settings.assets.view = {
                                ...rightPanelSettingsRes[panelIndex]
                                    .lesson_settings.assets.view,
                                teacher: "viewT1",
                            };
                        }
                        setTeacherView(teacherViewInit);

                        setRightPanelSettings(rightPanelSettingsRes);
                        setRightPanelSettingsToShow(
                            rightPanelSettingsRes.slice(0, 5)
                        );
                        if (emptyData)
                            await updateSession(rightPanelSettingsRes);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        getSessionInfo();
        // eslint-disable-next-line
    }, []);

    const updateSession = async (newSettings, cb) => {
        if (rightPanelSettings.length > 0) {
            const sessionData = {
                id: sessionID,
                rightPanelSettings: newSettings || rightPanelSettings,
                ...(!!panelSlotId
                    ? {
                          rightPanelSettingsSessionId: panelSlotId,
                      }
                    : {}),
            };
            await axios
                .put("/api/v1/sessions", sessionData)
                .then((result) => {
                    if (result.data.success) {
                        if (cb) {
                            cb();
                        }
                        setVersionTime(moment().format("HH:mm"));
                    } else {
                        setMsg(
                            _.get(
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        }
    };

    const updateRightPanelSettings = (index, type, label, cb) => {
        const updatedRightPanel = rightPanelSettings.map((obj, i) => {
            if (index === i && (!obj.type || obj.type !== type)) {
                return {
                    label,
                    type,
                };
            } else {
                return obj;
            }
        });
        setRightPanelSettings(updatedRightPanel);
        setRightPanelSettingsToShow(updatedRightPanel.slice(0, 5));
        cb(updatedRightPanel);
    };

    const onSelectTeacherView = async (selectedView) => {
        setTeacherView(selectedView);
        const newArray = [...rightPanelSettings];
        newArray[panelIndex].lesson_settings.assets.view = {
            ...newArray[panelIndex].lesson_settings.assets.view,
        };
        newArray[panelIndex].lesson_settings.assets.view = {
            ...newArray[panelIndex].lesson_settings.assets.view,
            teacher: selectedView,
        };
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
    };

    const onSelectStudentView = async (selectedView) => {
        setStudentView(selectedView);
        const newArray = [...rightPanelSettings];
        newArray[panelIndex].lesson_settings.assets.view = {
            ...newArray[panelIndex].lesson_settings.assets.view,
        };
        newArray[panelIndex].lesson_settings.assets.view = {
            ...newArray[panelIndex].lesson_settings.assets.view,
            student: selectedView,
        };
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
    };

    const lessonData = [
        {
            label: "Video",
            value: "Video",
        },
        {
            label: "Lesson",
            value: "Lesson",
        },
        { label: "Activity", value: "Activity" },
    ];

    const addLessionBlock = async () => {
        const newArray = [...rightPanelSettings, {}];
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
    };

    const removeLessionBlock = (index) => {
        if (index < panelIndex) {
            setPanelIndex(panelIndex - 1);
        }
        const newArray = rightPanelSettings
            .slice(0, index)
            .concat(rightPanelSettings.slice(index + 1));
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
    };

    const handleData = (type) => {
        if (type === "VIDEO") {
            return "Video";
        } else if (type === "LESSON") {
            return "Lesson";
        } else if (type === "ACTIVITY") {
            return "Activity";
        }
    };

    const rightTab = (data) => {
        if (data === "VIDEO") {
            return "Video";
        } else if (data === "LESSON") {
            return "Lesson";
        } else if (data === "ACTIVITY") {
            return "Activity";
        }
    };

    const saveSubtypeAndResetIfDifferent = async (param) => {
        // api call
        if (rightPanelSettings.length > 0) {
            const sessionData = {
                id: sessionID,
                rightPanelSettings: rightPanelSettings.map((obj, index) => {
                    if (parseInt(panelindex) === index) {
                        if (obj.subType !== param.toUpperCase()) {
                            return {
                                type: obj.type.toUpperCase(),
                                label: obj.label,
                                subType: param.toUpperCase(),
                            };
                        }
                        obj.subType = param.toUpperCase();
                    }
                    return obj;
                }),
                ...(!!panelSlotId
                    ? {
                          rightPanelSettingsSessionId: panelSlotId,
                      }
                    : {}),
            };
            await axios
                .put("/api/v1/sessions", sessionData)
                .then((response) => {
                    if (response.data.success) {
                        setShowOption(false);
                        if (param.toUpperCase() === "WHITEBOARD") {
                            history.push(
                                `/session/${sessionID}/${param.toLowerCase()}/setting?panelIndex=${panelindex}${urlParam}`
                            );
                        } else {
                            history.push(
                                `/session/${sessionID}/${param.toLowerCase()}/view?panelIndex=${panelindex}${urlParam}`
                            );
                        }
                    } else {
                        setMsg(
                            _.get(
                                "response.data.title",
                                "Something went wrong."
                            )
                        );
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        }
    };

    const handleRightTab = async (ev, index) => {
        const newArray = [...rightPanelSettings];
        newArray[index] = {
            ...newArray[index],
            label: ev.target.innerText,
        };
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        await updateSession(newArray);
    };

    const moveSessionUp = () => {
        const index = rightPanelSettings.indexOf(rightPanelSettingsToShow[0]);

        if (index > 0) {
            let rightPanelSettingsToShowCopy = [...rightPanelSettingsToShow];
            rightPanelSettingsToShowCopy.pop();
            rightPanelSettingsToShowCopy = [
                rightPanelSettings[index - 1],
                ...rightPanelSettingsToShowCopy,
            ];
            setRightPanelSettingsToShow(rightPanelSettingsToShowCopy);
        }
    };

    const moveSessionDown = () => {
        const index = rightPanelSettings.indexOf(rightPanelSettingsToShow[0]);

        if (index < rightPanelSettings.length - 5) {
            const rightPanelSettingsToShowCopy = [...rightPanelSettingsToShow];
            rightPanelSettingsToShowCopy.shift();
            rightPanelSettingsToShowCopy.push(rightPanelSettings[index + 5]);
            setRightPanelSettingsToShow(rightPanelSettingsToShowCopy);
        }
    };

    const handleTextChange = (e) => {
        if (e.currentTarget.textContent.length > 6 && e.which !== 8) {
            e.preventDefault();
        }
    };

    const handleRightPanelSetting = async (index) => {
        const rightPanelData =
            sessionData.configuration.right_panel_settings[index];
        const newArray = rightPanelSettings;
        newArray[rightPanelIndex] = rightPanelData;
        newArray[rightPanelIndex]._id =
            sessionData.configuration.right_panel_settings[rightPanelIndex]._id;
        setRightPanelSettings(newArray);
        setRightPanelSettingsToShow(newArray.slice(0, 5));
        setImportModal(false);
        setSelectClass(false);
        await updateSession(newArray);
    };

    const handleImportModal = () => {
        setImportModal(false);
        setSelectClass(false);
    };

    const handleSelectClass = async (event, id) => {
        event.preventDefault();
        await axios
            .get("/api/v1/sessions")
            .then((result) => {
                if (result.data.success) {
                    const data = result.data.sessions.find(
                        (element, index) => index === id
                    );
                    setSessionData(data);
                }
                setSelectClass(true);
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const decreaseImgModalFlag = (length) => {
        if (imgModalFlag > 0) {
            setImgModalFlag(imgModalFlag - 1);
        } else {
            setImgModalFlag(length - 1);
        }
    };

    const increaseImgModalFlag = (length) => {
        if (imgModalFlag < length - 1) {
            setImgModalFlag(imgModalFlag + 1);
        } else {
            setImgModalFlag(0);
        }
    };

    const handleImportOption = async (index) => {
        await axios
            .get("/api/v1/sessions")
            .then((result) => {
                if (result.data.success) {
                    setSessions(result.data.sessions);
                }
                setImportModal(true);
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const handleShowWarningModal = async (decision, moveToOtherTab) => {
        setShowWarningModal({
            flag: false,
            type: null,
        });

        if (moveToOtherTab) {
            handleMoveToOtherTab(true);
        } else if (decision) {
            await updateSession();
            history.push(`/session/${sessionID}`);
        }
    };

    const handleMoveToOtherTab = async (flag, nextObj) => {
        setShowWarningModal({
            flag: false,
            type: null,
        });

        if (flag) {
            await updateSession();

            const obj = nextObj ? nextObj.obj : nextTabData.obj;
            const index = nextObj ? nextObj.index : nextTabData.index;
            if (obj.type === "LESSON") {
                if (rightPanelSettings[index].subType === "ASSET") {
                    if (
                        rightPanelSettings[index].lesson_settings.assets.view
                            ?.student &&
                        rightPanelSettings[index].lesson_settings.assets.view
                            ?.teacher
                    ) {
                        history.push(
                            `/session/${sessionID}/asset/media?panelIndex=${index}${urlParam}`
                        );
                    } else {
                        history.push(
                            `/session/${sessionID}/asset/view?panelIndex=${index}${urlParam}`
                        );
                    }
                } else {
                    if (rightPanelSettings[index].subType === "WHITEBOARD") {
                        if (
                            rightPanelSettings[index].lesson_settings.whiteboard
                                ?.student?.view &&
                            rightPanelSettings[index].lesson_settings.whiteboard
                                ?.teacher?.view
                        ) {
                            history.push(
                                `/session/${sessionID}/whiteboard/media?panelIndex=${index}${urlParam}`
                            );
                        } else if (
                            rightPanelSettings[index].lesson_settings.whiteboard
                        ) {
                            history.push(
                                `/session/${sessionID}/whiteboard/view?panelIndex=${index}${urlParam}`
                            );
                        } else {
                            history.push(
                                `/session/${sessionID}/whiteboard/setting?panelIndex=${index}${urlParam}`
                            );
                        }
                    } else {
                        history.push(
                            `/session/${sessionID}/tool?panelIndex=${index}${urlParam}`
                        );
                    }
                }
            } else if (obj.type === "VIDEO") {
                if (!obj.video_settings) {
                    history.push(
                        `/session/${sessionID}/viewTool?panelIndex=${index}${urlParam}`
                    );
                } else if (obj.subType === "VIDEO") {
                    history.push(
                        `/session/${sessionID}/view?panelIndex=${index}${urlParam}`
                    );
                } else if (obj.subType === "SCREENSHARE") {
                    history.push(
                        `/session/${sessionID}/screenshare?panelIndex=${index}${urlParam}`
                    );
                }
            } else if (obj.type === "ACTIVITY") {
                if (rightPanelSettings[index].subType) {
                    history.push(
                        `/session/${sessionID}/activity/customise?panelIndex=${index}${urlParam}`
                    );
                } else {
                    history.push(
                        `/session/${sessionID}/activity/list?panelIndex=${index}${urlParam}`
                    );
                }
            }
        }
    };

    return (
        <div>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            {showWarningModal.flag && (
                <WarningModal
                    handleShowWarningModal={handleShowWarningModal}
                    showWarningModal={showWarningModal}
                />
            )}

            <Row className={styles.row}>
                <Col xs={10} className={styles.leftsection}>
                    <div className=""></div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                            className={styles.iconsection}
                            style={{
                                padding: "9px 34px",
                                marginRight: "-4px",
                                zIndex: 22,
                            }}
                        >
                            <img
                                src={ScreenIcon}
                                style={{ width: "40px" }}
                                alt=""
                                className={styles.playicon}
                            />
                        </div>
                        <div
                            className={styles.iconsection}
                            style={{
                                padding: "4px 34px",
                                marginLeft: 0,
                                zIndex: 0,
                            }}
                        >
                            <img
                                src={UploadContent}
                                style={{ width: "40px" }}
                                alt=""
                                className={styles.playicon}
                                onClick={async () => {
                                    await updateSession();
                                    history.push(
                                        `/session/${sessionID}/asset/media?panelIndex=${panelindex}${urlParam}`
                                    );
                                }}
                            />
                        </div>
                        <h1
                            className={styles.heading}
                            style={{
                                marginLeft: "10px",
                                marginBottom: 0,
                                marginTop: 0,
                            }}
                        >
                            Screen Arrangement
                        </h1>
                    </div>
                    <div className={styles.leftSection}>
                        <div className={styles.sessionblock}>
                            <div className={styles.crosssection}>
                                <img
                                    onClick={() => {
                                        setShowWarningModal({
                                            flag: true,
                                            type: "closeButton",
                                        });
                                    }}
                                    style={{ marginRight: "5px" }}
                                    src={CloseButton}
                                    alt="Logo"
                                    className={styles.playicon}
                                />
                                <p className={styles.italicPara}>
                                    Last version saved at {versionTime}
                                </p>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <h1
                                    className={styles.backBtn}
                                    onClick={async () => {
                                        await updateSession();
                                        history.push(
                                            `/session/${sessionID}/tool?panelIndex=${panelindex}${urlParam}`
                                        );
                                    }}
                                >
                                    Back
                                </h1>
                                <div style={{ position: "relative" }}>
                                    <div className={styles.selectedTool}>
                                        <p className={styles.toolText}>
                                            Presentation
                                        </p>
                                        <FontAwesomeIcon
                                            className={styles.downAngleIcon}
                                            style={{
                                                width: "30px",
                                                cursor: showOption
                                                    ? "initial"
                                                    : "pointer",
                                            }}
                                            icon={faAngleDown}
                                            onClick={() => {
                                                setShowOption(!showOption);
                                            }}
                                        />
                                    </div>
                                    {showOption && (
                                        <div className={styles.toolSection}>
                                            <p
                                                className={styles.toolType}
                                                onClick={() => {
                                                    saveSubtypeAndResetIfDifferent(
                                                        "Asset"
                                                    );
                                                }}
                                            >
                                                Presentation
                                            </p>
                                            <p
                                                className={styles.toolType}
                                                onClick={() => {
                                                    saveSubtypeAndResetIfDifferent(
                                                        "Whiteboard"
                                                    );
                                                }}
                                            >
                                                Whiteboard
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <img
                                        src={PlayIcon}
                                        style={{
                                            width: "40px",
                                            marginLeft: "5px",
                                            cursor: "default",
                                        }}
                                        alt=""
                                        className={styles.playicon}
                                    />
                                </div>
                            </div>
                        </div>
                        <Row>
                            <Col>
                                <div className={styles.studentview}>
                                    <h1 className={styles.heading}>
                                        Student View
                                    </h1>
                                    <div className={styles.studentblock}>
                                        <div className={styles.studentScroll}>
                                            <Row
                                                style={{
                                                    marginRight: "-8px",
                                                }}
                                            >
                                                <Col
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "15px",
                                                        margin: "auto",
                                                    }}
                                                >
                                                    <img
                                                        onClick={() =>
                                                            onSelectStudentView(
                                                                "view1"
                                                            )
                                                        }
                                                        className={`${
                                                            styles.layoutImg
                                                        } ${
                                                            studentView ===
                                                            "view1"
                                                                ? styles.highlight
                                                                : ""
                                                        }`}
                                                        src={View1}
                                                        alt=""
                                                    />
                                                </Col>
                                                <Col
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "15px",
                                                        margin: "auto",
                                                    }}
                                                >
                                                    <img
                                                        onClick={() =>
                                                            onSelectStudentView(
                                                                "view2"
                                                            )
                                                        }
                                                        className={`${
                                                            styles.layoutImg
                                                        } ${
                                                            studentView ===
                                                            "view2"
                                                                ? styles.highlight
                                                                : ""
                                                        }`}
                                                        src={View2}
                                                        alt=""
                                                    />
                                                </Col>
                                            </Row>
                                            <Row
                                                style={{
                                                    marginRight: "-8px",
                                                }}
                                            >
                                                <Col
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "15px",
                                                        margin: "auto",
                                                    }}
                                                >
                                                    <img
                                                        onClick={() =>
                                                            onSelectStudentView(
                                                                "view3"
                                                            )
                                                        }
                                                        className={`${
                                                            styles.layoutImg
                                                        } ${
                                                            studentView ===
                                                            "view3"
                                                                ? styles.highlight
                                                                : ""
                                                        }`}
                                                        src={View3}
                                                        alt=""
                                                    />
                                                </Col>
                                                <Col
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "15px",
                                                        margin: "auto",
                                                    }}
                                                >
                                                    <img
                                                        onClick={() =>
                                                            onSelectStudentView(
                                                                "view4"
                                                            )
                                                        }
                                                        className={`${
                                                            styles.layoutImg
                                                        } ${
                                                            studentView ===
                                                            "view4"
                                                                ? styles.highlight
                                                                : ""
                                                        }`}
                                                        src={View4}
                                                        alt=""
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <h1 className={styles.heading}>
                                        Teacher View
                                    </h1>
                                    <div className={styles.teacherblock}>
                                        <div className={styles.studentScroll}>
                                            <Row
                                                style={{
                                                    marginRight: "-8px",
                                                }}
                                            >
                                                <Col
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "15px",
                                                        margin: "auto",
                                                    }}
                                                >
                                                    <img
                                                        onClick={() =>
                                                            onSelectTeacherView(
                                                                "viewT1"
                                                            )
                                                        }
                                                        className={`${
                                                            styles.layoutImg
                                                        } ${
                                                            teacherView ===
                                                            "viewT1"
                                                                ? styles.highlight
                                                                : ""
                                                        }`}
                                                        src={ViewT1}
                                                        alt=""
                                                    />
                                                </Col>
                                                <Col
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "15px",
                                                        margin: "auto",
                                                    }}
                                                >
                                                    <img
                                                        onClick={() =>
                                                            onSelectTeacherView(
                                                                "viewT2"
                                                            )
                                                        }
                                                        className={`${
                                                            styles.layoutImg
                                                        } ${
                                                            teacherView ===
                                                            "viewT2"
                                                                ? styles.highlight
                                                                : ""
                                                        }`}
                                                        src={ViewT2}
                                                        alt=""
                                                    />
                                                </Col>
                                            </Row>
                                            <Row
                                                style={{
                                                    marginRight: "-8px",
                                                }}
                                            >
                                                <Col
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "15px",
                                                        margin: "auto",
                                                    }}
                                                >
                                                    <img
                                                        onClick={() =>
                                                            onSelectTeacherView(
                                                                "viewT3"
                                                            )
                                                        }
                                                        className={`${
                                                            styles.layoutImg
                                                        } ${
                                                            teacherView ===
                                                            "viewT3"
                                                                ? styles.highlight
                                                                : ""
                                                        }`}
                                                        src={ViewT3}
                                                        alt=""
                                                    />
                                                </Col>
                                                <Col
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "15px",
                                                        margin: "auto",
                                                    }}
                                                >
                                                    <img
                                                        onClick={() =>
                                                            onSelectTeacherView(
                                                                "viewT4"
                                                            )
                                                        }
                                                        className={`${
                                                            styles.layoutImg
                                                        } ${
                                                            teacherView ===
                                                            "viewT4"
                                                                ? styles.highlight
                                                                : ""
                                                        }`}
                                                        src={ViewT4}
                                                        alt=""
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className={styles.createButtonSection}>
                            <h1
                                className={styles.createButton}
                                onClick={async () => {
                                    await updateSession();
                                    history.push(
                                        `/session/${sessionID}/asset/media?panelIndex=${panelindex}${urlParam}`
                                    );
                                }}
                            >
                                Next
                            </h1>
                        </div>
                        <div>
                            <div
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div className={styles.scroll}>
                                    <div className={styles.content}>
                                        {rightPanelSettings.map(
                                            (obj, index) => {
                                                return (
                                                    <div
                                                        className={
                                                            styles.lessonWrapper
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.classblock
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={(
                                                                event
                                                            ) => {
                                                                event.preventDefault();
                                                                if (
                                                                    event.target
                                                                        .id ==
                                                                        "downarrow" ||
                                                                    event.target
                                                                        .parentElement
                                                                        .id ==
                                                                        "downarrow" ||
                                                                    document.getElementById(
                                                                        "dropdown"
                                                                    ) ||
                                                                    show != null
                                                                ) {
                                                                } else {
                                                                    setShowWarningModal(
                                                                        {
                                                                            flag: true,
                                                                            type: "tab",
                                                                        }
                                                                    );
                                                                    setNextTabData(
                                                                        {
                                                                            obj: obj,
                                                                            index: index,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {index !==
                                                                panelIndex && (
                                                                <>
                                                                    <FontAwesomeIcon
                                                                        className={
                                                                            styles.minusicon
                                                                        }
                                                                        style={{
                                                                            width: "30px",
                                                                            position:
                                                                                "absolute",
                                                                            left: 0,
                                                                        }}
                                                                        icon={
                                                                            faPlus
                                                                        }
                                                                        onClick={(
                                                                            ev
                                                                        ) => {
                                                                            ev.stopPropagation();
                                                                            if (
                                                                                show !==
                                                                                null
                                                                            ) {
                                                                                setShow(
                                                                                    null
                                                                                );
                                                                            } else {
                                                                                setShow(
                                                                                    index
                                                                                );
                                                                                setRightPanelIndex(
                                                                                    index
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                    {show ===
                                                                        index && (
                                                                        <div
                                                                            className={
                                                                                styles.paneloption
                                                                            }
                                                                        >
                                                                            <p
                                                                                onClick={(
                                                                                    event
                                                                                ) => {
                                                                                    event.preventDefault();
                                                                                    handleImportOption(
                                                                                        index
                                                                                    );
                                                                                    setShow(
                                                                                        null
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Use
                                                                                Existing
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                    <FontAwesomeIcon
                                                                        className={
                                                                            styles.minusicon
                                                                        }
                                                                        style={{
                                                                            width: "30px",
                                                                            position:
                                                                                "absolute",
                                                                            right: 0,
                                                                        }}
                                                                        icon={
                                                                            faMinus
                                                                        }
                                                                        onClick={(
                                                                            ev
                                                                        ) => {
                                                                            ev.stopPropagation();
                                                                            removeLessionBlock(
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                            <div
                                                                className={
                                                                    styles.customDropContainer
                                                                }
                                                            >
                                                                <CustomDropdown
                                                                    dropdownData={
                                                                        lessonData
                                                                    }
                                                                    width="100%"
                                                                    margin="auto"
                                                                    setData={(
                                                                        payload
                                                                    ) => {
                                                                        updateRightPanelSettings(
                                                                            index,
                                                                            payload.toUpperCase(),
                                                                            payload,
                                                                            (
                                                                                rpSettings
                                                                            ) =>
                                                                                updateSession(
                                                                                    rpSettings,
                                                                                    () => {
                                                                                        if (
                                                                                            payload ===
                                                                                            "Lesson"
                                                                                        ) {
                                                                                            history.push(
                                                                                                `/session/${sessionID}/tool?panelIndex=${index}${urlParam}`
                                                                                            );
                                                                                        } else if (
                                                                                            payload ===
                                                                                            "Video"
                                                                                        ) {
                                                                                            history.push(
                                                                                                `/session/${sessionID}/viewTool?panelIndex=${index}${urlParam}`
                                                                                            );
                                                                                        } else if (
                                                                                            payload ===
                                                                                            "Activity"
                                                                                        ) {
                                                                                            history.push(
                                                                                                `/session/${sessionID}/activity/list?panelIndex=${index}${urlParam}`
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                )
                                                                        );
                                                                    }}
                                                                    data={
                                                                        obj.label
                                                                            ? `${obj.label}`
                                                                            : handleData(
                                                                                  obj.type
                                                                              )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        {importModal && (
                                                            <div
                                                                className={
                                                                    styles.importModal
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    className={
                                                                        styles.crossIcon
                                                                    }
                                                                    icon={
                                                                        faTimes
                                                                    }
                                                                    onClick={
                                                                        handleImportModal
                                                                    }
                                                                />
                                                                <p
                                                                    className={
                                                                        styles.classModalText
                                                                    }
                                                                >
                                                                    Pick the
                                                                    class you
                                                                    would like
                                                                    to access
                                                                </p>
                                                                <div
                                                                    className={
                                                                        styles.modalClassWrapper
                                                                    }
                                                                >
                                                                    {selectClass ? (
                                                                        <div
                                                                            className={
                                                                                styles.modalClassScroll
                                                                            }
                                                                        >
                                                                            <Row>
                                                                                <Col>
                                                                                    <div
                                                                                        className={
                                                                                            styles.modalClassBlock
                                                                                        }
                                                                                    >
                                                                                        <img
                                                                                            className={
                                                                                                styles.modalCoverImage
                                                                                            }
                                                                                            src={
                                                                                                sessionData
                                                                                                    .image_ids[0] &&
                                                                                                sessionData
                                                                                                    .image_ids[0]
                                                                                                    .location
                                                                                            }
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <p
                                                                                        className={
                                                                                            styles.modalClassName
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            sessionData.class_name
                                                                                        }
                                                                                    </p>
                                                                                </Col>
                                                                                <Col></Col>
                                                                                <Col></Col>
                                                                            </Row>
                                                                            <div
                                                                                className={
                                                                                    styles.modalLessonWrapper
                                                                                }
                                                                            >
                                                                                {sessionData.configuration.right_panel_settings.map(
                                                                                    (
                                                                                        obj,
                                                                                        index
                                                                                    ) => {
                                                                                        return (
                                                                                            <div
                                                                                                className={
                                                                                                    styles.modalLesson
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    handleRightPanelSetting(
                                                                                                        index
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <p
                                                                                                    className={
                                                                                                        styles.modalLessonName
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        obj.type
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            className={
                                                                                styles.modalClassScroll
                                                                            }
                                                                        >
                                                                            <Row>
                                                                                {sessions.map(
                                                                                    (
                                                                                        obj,
                                                                                        index
                                                                                    ) => {
                                                                                        return (
                                                                                            <Col
                                                                                                md={
                                                                                                    4
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    className={
                                                                                                        styles.modalClassBlock
                                                                                                    }
                                                                                                    onClick={(
                                                                                                        event
                                                                                                    ) => {
                                                                                                        handleSelectClass(
                                                                                                            event,
                                                                                                            index
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    {obj
                                                                                                        .image_ids
                                                                                                        .length >=
                                                                                                        1 && (
                                                                                                        <div
                                                                                                            style={{
                                                                                                                position:
                                                                                                                    "relative",
                                                                                                                height: "100%",
                                                                                                            }}
                                                                                                        >
                                                                                                            {obj
                                                                                                                .image_ids
                                                                                                                .length >
                                                                                                                1 && (
                                                                                                                <>
                                                                                                                    <FontAwesomeIcon
                                                                                                                        style={{
                                                                                                                            color: "#83838b",
                                                                                                                            width: "30px",
                                                                                                                            position:
                                                                                                                                "absolute",
                                                                                                                            left: "-7px",
                                                                                                                            marginTop:
                                                                                                                                "10px",
                                                                                                                            zIndex: "8",
                                                                                                                            top: "20px",
                                                                                                                            cursor: "pointer",
                                                                                                                        }}
                                                                                                                        icon={
                                                                                                                            faLessThan
                                                                                                                        }
                                                                                                                        onClick={(
                                                                                                                            event
                                                                                                                        ) => {
                                                                                                                            event.preventDefault();
                                                                                                                            event.stopPropagation();
                                                                                                                            decreaseImgModalFlag(
                                                                                                                                obj
                                                                                                                                    .image_ids
                                                                                                                                    .length
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    <FontAwesomeIcon
                                                                                                                        style={{
                                                                                                                            color: "#83838b",
                                                                                                                            width: "30px",
                                                                                                                            position:
                                                                                                                                "absolute",
                                                                                                                            right: "-7px",
                                                                                                                            marginTop:
                                                                                                                                "10px",
                                                                                                                            zIndex: "8",
                                                                                                                            top: "20px",
                                                                                                                            cursor: "pointer",
                                                                                                                        }}
                                                                                                                        icon={
                                                                                                                            faGreaterThan
                                                                                                                        }
                                                                                                                        onClick={(
                                                                                                                            event
                                                                                                                        ) => {
                                                                                                                            event.preventDefault();
                                                                                                                            event.stopPropagation();
                                                                                                                            increaseImgModalFlag(
                                                                                                                                obj
                                                                                                                                    .image_ids
                                                                                                                                    .length
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </>
                                                                                                            )}
                                                                                                            {obj.image_ids.map(
                                                                                                                (
                                                                                                                    element,
                                                                                                                    index
                                                                                                                ) => {
                                                                                                                    const fileType =
                                                                                                                        element.location
                                                                                                                            .split(
                                                                                                                                "."
                                                                                                                            )
                                                                                                                            .pop();
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            {fileType ===
                                                                                                                            "mp4"
                                                                                                                                ? imgModalFlag ===
                                                                                                                                      index && (
                                                                                                                                      <video
                                                                                                                                          className={
                                                                                                                                              styles.modalCoverImage
                                                                                                                                          }
                                                                                                                                          src={
                                                                                                                                              element.location
                                                                                                                                          }
                                                                                                                                          controls
                                                                                                                                      ></video>
                                                                                                                                  )
                                                                                                                                : imgModalFlag ===
                                                                                                                                      index && (
                                                                                                                                      <img
                                                                                                                                          className={
                                                                                                                                              styles.modalCoverImage
                                                                                                                                          }
                                                                                                                                          src={
                                                                                                                                              element.location
                                                                                                                                          }
                                                                                                                                          alt=""
                                                                                                                                      />
                                                                                                                                  )}
                                                                                                                        </>
                                                                                                                    );
                                                                                                                }
                                                                                                            )}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                                <p
                                                                                                    className={
                                                                                                        styles.modalClassName
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        obj.class_name
                                                                                                    }
                                                                                                </p>
                                                                                            </Col>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Row>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>

                                <FontAwesomeIcon
                                    className={styles.plusicon}
                                    style={{
                                        width: "30px",
                                    }}
                                    icon={faPlus}
                                    onClick={addLessionBlock}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={2}>
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <div
                            style={{ position: "relative", cursor: "pointer" }}
                            onClick={() => history.push("/home")}
                        >
                            <img
                                src={Logo}
                                className={styles.logoImg}
                                alt="Logo"
                            />
                            <h1 className={styles.logoText}>
                                plassroom<sup>+</sup>
                            </h1>
                            <p className={styles.logoSubText}>Teacher</p>
                            <h1 className={styles.rightTime}>
                                <Clock />
                            </h1>
                        </div>
                        <div
                            className={`${styles.rightTabSection} ${styles.studentScroll}`}
                        >
                            <FontAwesomeIcon
                                style={{
                                    width: "30px",
                                    marginLeft: "1rem",
                                    cursor: "pointer",
                                    opacity:
                                        rightPanelSettingsToShow[0]?._id !==
                                            rightPanelSettings[0]?._id &&
                                        rightPanelSettings.length > 5
                                            ? 1
                                            : 0,
                                }}
                                icon={faAngleUp}
                                onClick={moveSessionUp}
                            />
                            {rightPanelSettingsToShow.map((obj, index) => {
                                if (
                                    obj._id ===
                                    rightPanelSettings[panelIndex]._id
                                ) {
                                    return (
                                        <div
                                            className={styles.rightTabContainer}
                                        >
                                            <h1
                                                contentEditable="true"
                                                className={styles.rightTab}
                                                onBlur={(ev) => {
                                                    handleRightTab(ev, index);
                                                }}
                                                onKeyDown={(e) =>
                                                    handleTextChange(e)
                                                }
                                                id={`righttab${index}`}
                                            >
                                                {obj.label ? (
                                                    <>{obj.label}</>
                                                ) : (
                                                    <>{rightTab(obj.type)}</>
                                                )}
                                            </h1>
                                            <img
                                                src={Edit}
                                                className={styles.editImg}
                                                alt="Logo"
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor: "#C8E8E5",
                                            }}
                                            className={styles.rightTabContainer}
                                        >
                                            <h1
                                                onBlur={(ev) => {
                                                    handleRightTab(ev, index);
                                                }}
                                                onKeyDown={(e) =>
                                                    handleTextChange(e)
                                                }
                                                contentEditable="true"
                                                className={styles.rightTab}
                                                id={`righttab${index}`}
                                            >
                                                {obj.label ? (
                                                    <>{obj.label}</>
                                                ) : (
                                                    <>{rightTab(obj.type)}</>
                                                )}
                                            </h1>
                                        </div>
                                    );
                                }
                            })}
                            <FontAwesomeIcon
                                style={{
                                    width: "30px",
                                    marginLeft: "1rem",
                                    cursor: "pointer",
                                    opacity:
                                        rightPanelSettingsToShow[
                                            rightPanelSettingsToShow.length - 1
                                        ]?._id !==
                                            rightPanelSettings[
                                                rightPanelSettings.length - 1
                                            ]?._id &&
                                        rightPanelSettings.length > 5
                                            ? 1
                                            : 0,
                                }}
                                icon={faAngleDown}
                                onClick={moveSessionDown}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
