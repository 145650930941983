import Loading from "../../assets/videos/Loading.mp4";
import axios from "axios";
import React, { useState } from "react";
import Logo from "../../assets/images/LOGO (3).svg";
import PlusIcons from "../../assets/images/Plus_icons.svg";
import Snackbar from "../../components/Snackbar";
import styles from "./emailConfirmation.module.css";
import _ from "lodash";

export default () => {
    const [resendEmailVerificationCount, setResendEmailVerificationCount] =
        useState(0);
    const [verifyingAPI, setVerifyingAPI] = useState(false);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const handleResendEmail = async () => {
        setVerifyingAPI(true);
        const randomString = window.location.pathname.split("/")[2] || "";
        let userEmail = "";

        if (!randomString) {
            userEmail = localStorage.getItem("plassroom-email");
        }

        await axios
            .post("/api/v1/user/resend/email-verification-link", {
                randStr: "",
                email: userEmail,
            })
            .then((response) => {
                setResendEmailVerificationCount(
                    response.data.data.resendEmailVerificationCount
                );
                setVerifyingAPI(false);
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    return (
        <div className={styles.waiting_screen}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            {verifyingAPI ? (
                <div className={styles.waiting_content__container}>
                    <video className="VideoTag" autoPlay loop muted>
                        <source src={Loading} type="video/mp4" />
                    </video>
                </div>
            ) : (
                <div className={styles.success_content__container}>
                    <div>
                        <p className={styles.success_heading}>
                            An email has been sent.
                        </p>

                        <p>
                            Please follow the instructions on the email to
                            complete your registration. Thank you.
                        </p>

                        {resendEmailVerificationCount < 3 ? (
                            <p>
                                If you need the verification email to be resent
                                to you, please{" "}
                                <span onClick={handleResendEmail}>
                                    click here
                                </span>
                                .
                            </p>
                        ) : resendEmailVerificationCount > 3 ? (
                            <p>
                                Email not sent. You've exceeded maximum number
                                of attempts to resend email.
                            </p>
                        ) : (
                            <p>
                                You've exceeded maximum number of attempts to
                                resend email.
                            </p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
