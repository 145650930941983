import React, { useState } from "react";
import styles from "../ForgotPassword/forgotpassword.module.scss";
import Logo from "../../assets/images/LOGO (3).svg";
import Snackbar from "../../components/Snackbar";
import axios from "axios";
import { useLocation } from "react-router-dom";
import * as QueryString from "query-string";
import _ from "lodash";

export default () => {
    const location = useLocation();
    const tokenObject = QueryString.parse(location.search);

    const [password, setPassword] = useState();
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const handleChange = (evt) => {
        setPassword(evt.target.value);
    };

    const handleResetPassword = async () => {
        let data = {
            token: tokenObject.token,
            password: password,
        };

        await axios
            .post("/api/v1/user/resetpassowrd", data)
            .then((result) => {
                if (result.data.success) {
                    window.location.href = "/login";
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const handleSignup = () => {
        window.location.href = "./signup";
    };
    return (
        <div className={styles.mainDiv}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <div className={styles.logoContainer}
                style={{ position: "relative", cursor: "pointer" }}
                onClick={() => (window.location.href = "/home")}
            >
                <img src={Logo} className={styles.studentLogoBg} alt="Logo" />
                <h1 className={styles.logoText}>
                    plassroom<sup>+</sup>
                </h1>
            </div>

            <div className={styles.formWrapper}>
                <h2 className={styles.heading}> Forgot password</h2>

                <p className={styles.para}>Please enter your new password.</p>
                <div className={styles.inputWrapper}>
                    <div>
                        <input
                            className={styles.emailInput}
                            placeholder="new password"
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <p className={styles.continueBtn} onClick={handleResetPassword}>
                    Reset password
                </p>

                <p className={styles.signupText}>
                    Dont have an account?{" "}
                    <span className={styles.signup} onClick={handleSignup}>
                        Signup
                    </span>
                </p>
            </div>
        </div>
    );
};
