import React from "react";
import Snake from "../../assets/images/snake.jpg";
import Bird from "../../assets/images/bird.jpg";
import Dog from "../../assets/images/dog.jpg";
import Cat from "../../assets/images/cat.jpg";
import Duck from "../../assets/images/duck.jpg";
import Lion from "../../assets/images/lion.jpeg";
import styles from "./slider.module.css";
import { Col, Row } from "react-bootstrap";

export default ({ role, selectedItem, updateSelectedItem }) => {
    const handleSelect = (selectedIndex, e) => {
        updateSelectedItem(selectedIndex);
    };
    var slides = [Snake, Bird, Dog, Cat, Duck, Lion];
    return (
        <div style={{ display: "flex", position: "relative" }}>
            {role === "teacher" ? (
                <>
                    <img
                        src={slides[selectedItem]}
                        style={{ width: "100%" }}
                        alt="sliderImage"
                    />
                    <Row
                        style={{ position: "absolute", top: "78%", left: "2%" }}
                    >
                        {slides &&
                            slides.map((image, index) => {
                                return (
                                    <Col sm={2}>
                                        <img
                                            className={styles.sliderImage}
                                            src={slides[index]}
                                            onClick={() => handleSelect(index)}
                                            alt="sliderImages"
                                        />
                                    </Col>
                                );
                            })}
                    </Row>
                </>
            ) : (
                <img
                    src={slides[selectedItem]}
                    style={{ width: "100%" }}
                    alt=""
                />
            )}
        </div>
    );
};
