import React from "react";
import { Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";

import styles from "./gameInitModal.module.scss";

export default ({
    showModal,
    imageTypes,
    handleImageTypes,
    totalImages,
    handleTotalImages,
    isIndividual,
    handleGame1Start,
    turnBased,
    handleTurnBased,
    currentPanel,
    handleTimer,
    handleScoreboard,
    preview,
    gameName,
}) => {
    let ModalComponent = undefined;
    if (preview) {
        ModalComponent = Modal.Dialog;
    } else {
        ModalComponent = Modal;
    }
    return (
        <ModalComponent
            contentClassName={styles.modal}
            backdropClassName={styles.modalBackdrop}
            show={showModal}
            onHide={() => {}}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Body>
                <Row>
                    <div className={styles.modalSubBlock}>
                        <Row>
                            <Col md={6}></Col>
                            <Col md={6}>
                                <div className={styles.modalTextBigger}>
                                    <div>Easy</div>
                                    <div>Normal</div>
                                    <div>Hard</div>
                                </div>
                            </Col>
                        </Row>
                        <Row className={styles.divRow}>
                            <Col md={6}>
                                <p className={styles.textConfig}>Image Types</p>
                            </Col>
                            <Col md={6}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        className={`${styles.btnCircle} ${
                                            imageTypes === 2
                                                ? styles.selected
                                                : ""
                                        }`}
                                        onClick={() => handleImageTypes(2)}
                                    >
                                        2
                                    </div>
                                    <div
                                        className={`${styles.btnCircle} ${
                                            imageTypes === 3
                                                ? styles.selected
                                                : ""
                                        }`}
                                        onClick={() => handleImageTypes(3)}
                                    >
                                        3
                                    </div>
                                    <div
                                        className={`${styles.btnCircle} ${
                                            imageTypes === 4
                                                ? styles.selected
                                                : ""
                                        }`}
                                        onClick={() => handleImageTypes(4)}
                                    >
                                        4
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className={styles.divRow}>
                            <Col md={6}>
                                <p className={styles.textConfig}>
                                    Total Images
                                </p>
                            </Col>
                            <Col md={6}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        className={`${styles.btnCircle} ${
                                            totalImages === 6
                                                ? styles.selected
                                                : ""
                                        }`}
                                        onClick={() => handleTotalImages(6)}
                                    >
                                        6
                                    </div>
                                    <div
                                        className={`${styles.btnCircle} ${
                                            totalImages === 9
                                                ? styles.selected
                                                : ""
                                        }`}
                                        onClick={() => handleTotalImages(9)}
                                    >
                                        9
                                    </div>
                                    <div
                                        className={`${styles.btnCircle} ${
                                            totalImages === 12
                                                ? styles.selected
                                                : ""
                                        }`}
                                        onClick={() => handleTotalImages(12)}
                                    >
                                        12
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className={styles.divRow}>
                            <Col
                                xs={2}
                                className={`${styles.modalTextBigger} ${styles.buttonLeftText}`}
                            >
                                Timer
                            </Col>
                            <Col xs={4}>
                                <div
                                    className={styles.button}
                                    onClick={() => {
                                        handleTimer(
                                            !currentPanel?.activity_settings
                                                .timer,
                                            currentPanel?._id
                                        );
                                    }}
                                >
                                    <div
                                        className={
                                            currentPanel?.activity_settings
                                                .timer ||
                                            gameName === "BUILD_YOUR_DREAM"
                                                ? `${styles.circleLeft}`
                                                : `${styles.circleRight}`
                                        }
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={2}
                                className={`${styles.modalTextBigger} ${styles.buttonLeftText}`}
                            >
                                Scoreboard
                            </Col>
                            <Col xs={4}>
                                <div
                                    className={styles.button}
                                    onClick={() => {
                                        handleScoreboard(
                                            !currentPanel?.activity_settings
                                                .scoreboard,
                                            currentPanel._id
                                        );
                                    }}
                                >
                                    <div
                                        className={
                                            currentPanel?.activity_settings
                                                .scoreboard ||
                                            gameName === "BUILD_YOUR_DREAM"
                                                ? `${styles.circleLeft}`
                                                : `${styles.circleRight}`
                                        }
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Row>

                <Row className={styles.startButtonSection}>
                    <h1
                        className={styles.startButton}
                        onClick={() => {
                            handleGame1Start();
                        }}
                    >
                        Start
                    </h1>
                </Row>
            </Modal.Body>
        </ModalComponent>
    );
};
