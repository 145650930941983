import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Logo from "../../assets/images/LOGO (3).svg";
import Stars from "../../assets/images/stars_image@2x.png";
import styles from "./studentRegistration.module.css";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment-timezone";
import CustomDropdown from "../../components/CustomDropdown";
import Snackbar from "../../components/Snackbar";
import _ from "lodash";

export default () => {
    const history = useHistory();
    const inviteId = useParams().id;
    const [session, setSession] = useState();
    const [students, setStudents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(undefined);
    const [invitee, setInvitee] = useState();
    const [studentSelectError, setStudentSelectError] = useState(false);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    useEffect(() => {
        const getInviteInfo = async () => {
            await axios
                .get(`/api/v1/invite/${inviteId}`)
                .then(async (inviteRes) => {
                    const invite = inviteRes.data.data.invite;
                    await axios
                        .get(`/api/v1/session/${invite._session._id}`)
                        .then(async (sessionRes) => {
                            const session = sessionRes.data.data.session;
                            setSession(session);
                            await axios
                                .get(`/api/v1/user?email=${invite._invitee}`)
                                .then((inviteeRes) => {
                                    const inviteeStudents =
                                        inviteeRes.data.user.students.map(
                                            (obj) => {
                                                return {
                                                    label: obj.studentname,
                                                    value: obj._id,
                                                };
                                            }
                                        );
                                    setStudents(inviteeStudents);
                                    setSelectedStudent(
                                        inviteeStudents[0].value
                                    );
                                    setInvitee(inviteeRes.data.user);
                                })
                                .catch((error) => {
                                    setMsg(
                                        _.get(
                                            error,
                                            "response.data.title",
                                            "Something went wrong."
                                        )
                                    );
                                    setShowSnackbar(true);
                                    setTimeout(function () {
                                        setMsg(null);
                                    }, 3000);
                                    return Promise.reject(error);
                                });
                        })
                        .catch((error) => {
                            setMsg(
                                _.get(
                                    error,
                                    "response.data.title",
                                    "Something went wrong."
                                )
                            );
                            setShowSnackbar(true);
                            setTimeout(function () {
                                setMsg(null);
                            }, 3000);
                            return Promise.reject(error);
                        });
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        getInviteInfo();
        // eslint-disable-next-line
    }, []);

    let currency = "";
    let startDate = "";
    let startTime = "";
    let duration = "";

    if (session) {
        const currencyMap = {
            USD: "$",
            EUR: "€",
            GBP: "£",
        };
        currency = session.configuration.fee?.currency
            ? currencyMap[session.configuration.fee.currency]
            : "£";
        startDate = moment
            .utc(session.configuration.session_start_time, "DD/MM/YYYY hh:mm a")
            .local()
            .format("DD MMMM YYYY, dddd");
        const generatedStartTime = moment.utc(
            session.configuration.session_start_time,
            "DD/MM/YYYY hh:mm a"
        );
        const tz = moment.tz(moment.tz.guess()).zoneAbbr();
        startTime = generatedStartTime.local().format("HH:mm a") + " " + tz;
        duration = {
            hours:
                session.configuration.session_duration > 60
                    ? Math.floor(
                          session.configuration.session_duration / 60
                      ).toString() + " hours"
                    : "",
            minutes:
                (session.configuration.session_duration % 60).toString() +
                " minutes",
        };
    }

    const payAndConfirm = async () => {
        if (!selectedStudent) {
            setStudentSelectError(true);
            return;
        }
        let newSession = session;
        let parentExists = false;

        newSession.students_ids.forEach((parent) => {
            if (parent.id === invitee._id) {
                parentExists = true;
                if (
                    parent.students.findIndex(
                        (obj) => obj.id === selectedStudent
                    ) === -1
                ) {
                    parent.students.push({ id: selectedStudent });
                }
            }
        });
        if (!parentExists) {
            const selectedStudentDoc = { id: selectedStudent };
            newSession.students_ids.push({
                id: invitee._id,
                students: selectedStudentDoc,
            });
        }

        const students = invitee.students;
        const studentName = students.find(
            (student) => student._id === selectedStudent
        ).studentname;

        await axios
            .put(`/api/v1/session/${session._id}/students`, {
                newSession: newSession,
                email: invitee.email,
                studentName: studentName,
                startDate: startDate,
                startTime: startTime,
                duration: duration,
                parentName: invitee.name,
            })
            .then(() => {
                history.push(`/invites/${inviteId}/confirmation`);
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    return (
        <div style={{ height: "100vh", overflow: "hidden" }}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <div
                className={styles.logoContainer}
                onClick={() => history.push("/")}
            >
                <img src={Logo} className={styles.studentLogoBg} alt="Logo" />
                <h1 className={styles.logoText}>
                    plassroom<sup>+</sup>
                </h1>
                <p className={styles.logoSubText}>Register for class</p>
            </div>

            <img className={styles.starsImg} src={Stars} alt="Stars"></img>
            <Snackbar
                status="error"
                message="Please select Student Name."
                open={studentSelectError}
                setOpen={setStudentSelectError}
            />
            <div className={styles.parentWrapper}>
                <div className={styles.outerBlock}>
                    <div className={styles.wrapper}>
                        <Row className={styles.upperRow}>
                            <Col xs={3}></Col>
                            <Col xs={9}>
                                <h1 className={styles.heading}>
                                    {session?.class_name}
                                </h1>
                                <div className={styles.classDetails}>
                                    <Row>
                                        <Col className={styles.time}>
                                            <p
                                                className={styles.timeText}
                                            >{`${startDate}`}</p>
                                            <p
                                                className={styles.timeText}
                                            >{`${startTime} (${duration.hours}${duration.minutes})`}</p>
                                        </Col>
                                        <Col className={styles.normalText}>
                                            <p
                                                className={
                                                    styles.descriptionText
                                                }
                                            >{`Students age ${session?.configuration.age_group} years`}</p>
                                            <p
                                                className={
                                                    styles.descriptionText
                                                }
                                            >{`${session?.configuration.max_students} students maximum per class`}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <h1 className={styles.heading}>Student Name</h1>
                                <CustomDropdown
                                    dropdownData={students}
                                    setData={setSelectedStudent}
                                    isRegistration={true}
                                ></CustomDropdown>
                            </Col>
                            <Col xs={9}>
                                <h1 className={styles.heading}>
                                    Payment Details
                                </h1>
                                <div className={styles.paymentDetailsWrapper}>
                                    <p className={styles.normalText}>
                                        Your teacher will inform you of how to
                                        pay this fee.
                                    </p>
                                    <p className={styles.price}>{`${currency}${
                                        session?.configuration.fee?.amount
                                            ? session?.configuration.fee
                                                  ?.amount / 100
                                            : 0
                                    }`}</p>
                                    <h1
                                        className={styles.confirmButton}
                                        onClick={payAndConfirm}
                                    >
                                        Confirm booking
                                    </h1>
                                    <p
                                        className={`${styles.normalText} ${styles.paymentDetailsBottomText}`}
                                    >
                                        Coming soon: You will be able to pay
                                        this amount upon booking a class on
                                        Plassroom+.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
};
