import React, { useState, useEffect } from "react";
import { Tabs, Tab, Table, Button } from "react-bootstrap";
import axios from "axios";
import styles from "./dashboard.module.css";
import { useHistory } from "react-router-dom";
import Snackbar from "../../../components/Snackbar";
import _ from "lodash";

export default () => {
    const history = useHistory();
    const [key, setKey] = useState("Users");
    const [userList, setUserList] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await axios
                .get("/api/v1/users")
                .then((response) => {
                    setUserList(response.data.users);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

    const onSelect = async (key) => {
        setKey(key);
        if (key === "Users") {
            await axios
                .get("/api/v1/users")
                .then((response) => {
                    if (response.data.success) {
                        setUserList(response.data.users);
                    } else {
                        setMsg(response.data.message);
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        }
        if (key === "Sessions") {
            await axios
                .get("/api/v1/sessions")
                .then((response) => {
                    if (response.data.success) {
                        setSessionList(response.data.sessions);
                    } else {
                        setMsg(response.data.message);
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        }
    };

    const Users = (
        <Table responsive>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Role</th>
                    <th>Email</th>
                </tr>
            </thead>
            <tbody>
                {userList.length > 0 &&
                    userList.map((user, index) => (
                        <tr key={index}>
                            <td>{user.username}</td>
                            <td>{user.role}</td>
                            <td>{user.email}</td>
                        </tr>
                    ))}
            </tbody>
        </Table>
    );

    const Session = (
        <Table responsive>
            <thead>
                <tr>
                    <th>Teacher</th>
                    <th>Students</th>
                    <th>Session Link</th>
                </tr>
            </thead>
            <tbody>
                {sessionList.length > 0 &&
                    sessionList.map((session, index) => (
                        <tr key={index}>
                            <td>{`${session.teacher_id.username} (${session.teacher_id.email})`}</td>
                            <td>
                                {session.students_ids.map((obj, index) => {
                                    return (
                                        <li
                                            key={index}
                                        >{`${obj.username} (${obj.email})`}</li>
                                    );
                                })}
                            </td>
                            <td>
                                {process.env.REACT_APP_FRONTEND_URL +
                                    "/session/" +
                                    session._id}
                            </td>
                        </tr>
                    ))}
            </tbody>
        </Table>
    );

    return (
        <>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(key) => onSelect(key)}
            >
                <Tab eventKey="Users" title="Users">
                    <Button
                        variant="info"
                        className={styles.btn}
                        onClick={() => {
                            history.push("/admin/user/create");
                        }}
                    >
                        Create User
                    </Button>
                    {Users}
                </Tab>
                <Tab eventKey="Sessions" title="Sessions">
                    <Button
                        variant="info"
                        className={styles.btn}
                        onClick={() => {
                            history.push("/admin/session/create");
                        }}
                    >
                        Create Session
                    </Button>
                    {Session}
                </Tab>
            </Tabs>
        </>
    );
};
