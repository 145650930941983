import React, { Fragment, useEffect, useState } from "react";
import Dropdown from "../Dropdown";
import DurationPicker from "../DurationPickerComponent";

export default ({
    width,
    data,
    setData,
    isTime,
    type,
    date,
    currentUserRole,
    componentName,
    isPublished,
}) => {
    const [showDropdown, setShowdropdown] = useState(false);
    const [tempData, setTempData] = useState(undefined);
    const [initialMinutes, setInitialMinutes] = useState(0);
    const currentDate = new Date();
    const currentHrs = currentDate.getHours();
    const currentMin = currentDate.getMinutes();

    const handleDuration = (duration) => {
        setInitialMinutes(
            currentDate === date && parseInt(data?.hours) === currentHrs
                ? currentMin
                : parseInt(data?.minutes)
        );
        let selectedDate = 0;
        if (date) {
            selectedDate = date;
            selectedDate.setHours(duration.hours);
            selectedDate.setMinutes(duration.minutes);
            selectedDate.setSeconds(duration.seconds);
        }
        setTempData(duration);
        if (componentName === "Game") setData(duration);
    };

    useEffect(() => {
        setTempData(data);
        // eslint-disable-next-line
    }, [data]);

    const getDisplayString = (tempData) => {
        if (tempData) {
            if (componentName === "Game") {
                return isTime
                    ? `${
                          tempData.minutes.toString().length === 1
                              ? "0" + tempData.minutes
                              : tempData.minutes
                      }:${
                          tempData.seconds.toString().length === 1
                              ? "0" + tempData.seconds
                              : tempData.seconds
                      }`
                    : `${
                          tempData.minutes.toString().length === 1
                              ? "0" + tempData.minutes
                              : tempData.minutes
                      }minutes${
                          tempData.seconds.toString().length === 1
                              ? "0" + tempData.seconds
                              : tempData.seconds
                      }seconds`;
            } else {
                return isTime
                    ? `${
                          tempData.hours.toString().length === 1
                              ? "0" + tempData.hours
                              : tempData.hours
                      }:${
                          tempData.minutes.toString().length === 1
                              ? "0" + tempData.minutes
                              : tempData.minutes
                      }`
                    : `${
                          tempData.hours.toString().length === 1
                              ? "0" + tempData.hours
                              : tempData.hours
                      }hours${
                          tempData.minutes.toString().length === 1
                              ? "0" + tempData.minutes
                              : tempData.minutes
                      }minutes`;
            }
        } else {
            return null;
        }
    };

    const handleSaveData = () => {
        if (
            componentName !== "Game" &&
            (!currentUserRole || currentUserRole === "teacher")
        ) {
            setData(tempData);
        }
    };

    useEffect(() => {
        if (!showDropdown && tempData) handleSaveData();
        // eslint-disable-next-line
    }, [showDropdown]);

    // window.addEventListener("mouseup", function (event) {
    //     var element = document.getElementById("inputContainer");
    //     var dropdownElement = document.getElementsByClassName("rdp-picker")
    //     if (event.target !== element && !element?.contains(event.target)) {
    //         setShowdropdown(false);
    //     }
    // });

    return (
        <Fragment>
            <div style={{ width: `${width}` }} id="inputContainer">
                <Dropdown
                    displayValue={
                        tempData ? getDisplayString(tempData) : "Time"
                    }
                    show={showDropdown}
                    handleToggleShow={() => {
                        if (!currentUserRole || currentUserRole === "teacher") {
                            if (componentName !== "Game") setData(tempData);
                            setShowdropdown(!showDropdown && !isPublished);
                        }
                    }}
                    setShowdropdown={setShowdropdown}
                    isPublished={isPublished}
                >
                    <DurationPicker
                        initialDuration={
                            data
                                ? {
                                      hours: parseInt(data.hours),
                                      minutes: parseInt(data.minutes),
                                      seconds: 0,
                                  }
                                : { hours: 0, minutes: 0, seconds: 0 }
                        }
                        onChange={handleDuration}
                        maxHours={23}
                        componentName={componentName}
                        date={date}
                    />
                </Dropdown>
            </div>
        </Fragment>
    );
};
