import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./layout3.module.css";
import axios from "axios";
import Snackbar from "../../../../../../components/Snackbar";
import _ from "lodash";

import TwilioParticipant from "../../../../../../components/TwilioParticipant";
import Participant from "../../../../../../components/Participant";

export default ({ participants, room, publishAudio }) => {
    var Teacher = null;
    var Students = [];
    const bottomRowRef = useRef(null);

    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;
    const [studentsData, setStudentsData] = useState([]);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const userIds = participants.map((participant) => {
        var identityObj = JSON.parse(participant.identity);
        return identityObj._id;
    });

    useEffect(() => {
        const getUserDetails = async () => {
            await axios
                .post(`/api/v1/parents`, {
                    data: userIds,
                })
                .then((studentsResult) => {
                    const users = studentsResult.data.data.users;
                    let filteredUsers = [];
                    users.map((user) => {
                        const tempData = {};
                        tempData.name = user.students[0].studentpreferedname;
                        tempData._id = user.students[0]._id;
                        tempData.parentId = user._id;

                        filteredUsers.push(tempData);
                        return 0;
                    });
                    setStudentsData(filteredUsers);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        if (currentUserRole === "teacher") getUserDetails();
        // eslint-disable-next-line
    }, [participants.length]);

    if (currentUserRole === "teacher") {
        Teacher = (
            <TwilioParticipant
                key={room.localParticipant.sid}
                participant={room.localParticipant}
                role={"teacher"}
            />
        );

        Students = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            const studentName = studentsData.find(
                (student) => student.parentId === identityObj._id
            );
            return (
                <TwilioParticipant
                    key={participant.sid}
                    role={"student"}
                    participant={participant}
                    screen="teacher"
                    studentName={studentName?.name}
                />
            );
        });
    } else {
        var teacherParticipant = participants.find((obj) => {
            var identityObj = JSON.parse(obj.identity);
            return identityObj.role === "teacher";
        });
        if (teacherParticipant) {
            Teacher = (
                <TwilioParticipant
                    role="teacher"
                    key={teacherParticipant.sid}
                    participant={teacherParticipant}
                />
            );
        }
        Students = [
            <TwilioParticipant
                role="student"
                key={room.localParticipant.sid}
                participant={room.localParticipant}
                publishAudio={publishAudio}
                screen="student"
            />,
        ].concat(
            participants
                .filter((obj) => {
                    var identityObj = JSON.parse(obj.identity);
                    return identityObj.role !== "teacher";
                })
                .map((participant) => (
                    <TwilioParticipant
                        role="student"
                        key={participant.sid}
                        participant={participant}
                        publishAudio={publishAudio}
                        screen="student"
                    />
                ))
        );
    }

    let noOfParticipants = participants.length;
    let participantBoxesBottomRow = [];

    if (bottomRowRef.current) {
    }

    for (let i = 0; i < (noOfParticipants > 4 ? 4 : noOfParticipants); i++) {
        participantBoxesBottomRow.push(
            <Col md="auto" key={i}>
                <div className={styles.block}>
                    <Participant>{Students[i]}</Participant>
                </div>
            </Col>
        );
    }

    let participantBoxesTopRow = [];
    let participantBoxesMidRow = [];

    if (noOfParticipants > 4) {
        if (noOfParticipants < 7) {
            for (let i = 4; i < noOfParticipants; i++)
                participantBoxesMidRow.push(
                    <div className={styles.block} key={i}>
                        <Participant>{Students[i]}</Participant>
                    </div>
                );
        }
        if (noOfParticipants < 9) {
            for (let i = 6; i < noOfParticipants; i++)
                participantBoxesTopRow.push(
                    <div className={styles.block} key={i}>
                        <Participant>{Students[i]}</Participant>
                    </div>
                );
        }
    }

    return (
        <>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <Row style={{ justifyContent: "space-between" }}>
                <Col
                    md={3}
                    style={{ flexDirection: "column-reverse", display: "flex" }}
                >
                    {noOfParticipants > 6 && (
                        <div className={styles.block}>
                            <Participant>{Students[6]}</Participant>
                        </div>
                    )}
                    {noOfParticipants > 4 && (
                        <div className={styles.block}>
                            <Participant>{Students[4]}</Participant>
                        </div>
                    )}
                </Col>
                <Col md={6}>
                    <div className={styles.blockTeacher}>
                        <Participant>{Teacher}</Participant>
                    </div>
                </Col>
                <Col
                    md={3}
                    style={{ flexDirection: "column-reverse", display: "flex" }}
                >
                    {noOfParticipants > 7 && (
                        <div
                            className={styles.block}
                            style={{ marginLeft: "auto" }}
                        >
                            <Participant>{Students[7]}</Participant>
                        </div>
                    )}
                    {noOfParticipants > 5 && (
                        <div
                            className={styles.block}
                            style={{ marginLeft: "auto" }}
                        >
                            <Participant>{Students[5]}</Participant>
                        </div>
                    )}
                </Col>
            </Row>
            <Row
                style={{
                    justifyContent:
                        noOfParticipants > 3 ? "space-between" : "center",
                }}
                ref={bottomRowRef}
            >
                {participantBoxesBottomRow}
            </Row>
        </>
    );
};
