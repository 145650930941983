import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import CloseButton from "../../../assets/images/Close_button.png";
import Logo from "../../../assets/images/plass_logo_new.svg";
import styles from "./effects.module.css";
import Clock from "../../../components/Clock";
import HappyFaceOne from "../../../assets/images/Happy Face 1.gif";
import HappyFace from "../../../assets/images/2.Happy Face 2.gif";
import SadFace from "../../../assets/images/3.Sad Face 1.gif";
import Applause from "../../../assets/images/4.Applause 1.gif";
import Thumbsup1 from "../../../assets/images/5.Thumbs Up 1.gif";
import Thumbsup2 from "../../../assets/images/6.Thumbs Up 2.gif";
import Star1 from "../../../assets/images/7.Star PNG 1.gif";
import Star2 from "../../../assets/images/8.Star PNG 2.gif";
import ShootingStar from "../../../assets/images/9.Shooting Star.gif";
import Play1 from "../../../assets/images/12.Play Time 6.gif";
import Play2 from "../../../assets/images/11.Play Time 5.gif";
import Play3 from "../../../assets/images/10.Play Time 4.gif";
import Crown1 from "../../../assets/images/13.Crown 1.gif";
import Crown2 from "../../../assets/images/14.Crown 2.gif";
import Trophy1 from "../../../assets/images/15.Trophy 1.gif";
import Trophy2 from "../../../assets/images/16.Trophy 2.gif";
import Heart from "../../../assets/images/17.Heart Emoji 1.gif";
import Music from "../../../assets/images/18.Music Notes.gif";
import Wavinghand1 from "../../../assets/images/19.Waving Hand 2 (1).gif";
import Wavinghand3 from "../../../assets/images/20.Waving Hand 3.gif";
import Wavinghand4 from "../../../assets/images/Waving Hand 4.gif";
import Wavinghand5 from "../../../assets/images/Waving Hand 5.gif";

import CrowdCheer from "../../../assets/audio/1.Crowd cheer.mp3";
import Tadaa from "../../../assets/audio/2.Tadaa.mp3";
import Yay from "../../../assets/audio/3.Yay.mp3";
import Bellend from "../../../assets/audio/4.Bell end.mp3";
import ClockChime from "../../../assets/audio/5.Clock chime.mp3";
import OrganEnd from "../../../assets/audio/6.Organ end.mp3";
import HarpMelody from "../../../assets/audio/7.Harp melody.mp3";
import TrumpetCall from "../../../assets/audio/8.Trumpet call.mp3";
import WhistleStart from "../../../assets/audio/9.Whistle start.wav";
import CrowdAww from "../../../assets/audio/10.Crowd aww.mp3";
import Investigate from "../../../assets/audio/11.Investigate.mp3";
import NegativeSound from "../../../assets/audio/12.Negative sound.mp3";
import WrongAnswer from "../../../assets/audio/13.Wrong answer.mp3";
import Readysetgo from "../../../assets/audio/14.Ready,set,go.wav";
import BirdTweet from "../../../assets/audio/15.Bird Tweet.mp3";
import CatMeow from "../../../assets/audio/16.Cat Meow.wav";
import ChickenCluck from "../../../assets/audio/17.Chicken Clucks.mp3";
import DogBark from "../../../assets/audio/18.Dog Bark.wav";
import LionRoar from "../../../assets/audio/19.Lion Roar.mp3";
import SnakeHiss from "../../../assets/audio/20.Snake Hiss.wav";
import { useParams, useHistory } from "react-router-dom";

export default () => {
    const { id } = useParams();
    const [sticker, setSticker] = useState(null);
    const [sound, setSound] = useState(null);
    const history = useHistory();

    const handleNext = () => {
        history.push(`/session/${id}`);
    };

    const handleSticker = (value) => {
        setSticker(value);
    };

    const handlePlay = (e, value) => {
        setSound(value);
        let element = e.currentTarget;
        var r1 = element.querySelector("audio");
        r1.play();
    };
    return (
        <div className={styles.classList}>
            <Row>
                <Col xs={10} sm={10} className={styles.leftsection}>
                    <div className={styles.leftSection}>
                        <div className={styles.leftScroll}>
                            <div className={styles.sessionblock}>
                                <div className={styles.crosssection}>
                                    <img src={CloseButton} alt="Logo" />
                                    <p
                                        className={styles.italicPara}
                                        style={{ marginBottom: "0px" }}
                                    >
                                        Last version saved at 10:59am
                                    </p>
                                </div>
                            </div>
                            <div className={styles.publishedClass}>
                                <h3 className={styles.heading}>
                                    Preview effects available for use in this
                                    class.
                                </h3>
                            </div>
                            <Row>
                                <Col sm={6}>
                                    <h2>Stickers</h2>
                                    <div className={styles.stickerContainer}>
                                        <div className={styles.stickerScroll}>
                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(
                                                            HappyFaceOne
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker ===
                                                            HappyFaceOne
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={HappyFaceOne}
                                                        alt="Logo"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(
                                                            HappyFace
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker ===
                                                            HappyFace
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={HappyFace}
                                                        alt="Logo"
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(SadFace);
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker === SadFace
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={SadFace}
                                                        alt="Logo"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(Applause);
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker === Applause
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Applause}
                                                        alt="Logo"
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(
                                                            Thumbsup1
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker ===
                                                            Thumbsup1
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Thumbsup1}
                                                        alt="Logo"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(
                                                            Thumbsup2
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker ===
                                                            Thumbsup2
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Thumbsup2}
                                                        alt="Logo"
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(Star1);
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker === Star1
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Star1}
                                                        alt="Logo"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(Star2);
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker === Star2
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Star2}
                                                        alt="Logo"
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(
                                                            ShootingStar
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker ===
                                                            ShootingStar
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={ShootingStar}
                                                        alt="Logo"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(Play1);
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker === Play1
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Play1}
                                                        alt="Logo"
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(Play2);
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker === Play2
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Play2}
                                                        alt="Logo"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(Play3);
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker === Play3
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Play3}
                                                        alt="Logo"
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(Crown1);
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker === Crown1
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Crown1}
                                                        alt="Logo"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(Crown2);
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker === Crown2
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Crown2}
                                                        alt="Logo"
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(Trophy1);
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker === Trophy1
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Trophy1}
                                                        alt="Logo"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(Trophy2);
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker === Trophy2
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Trophy2}
                                                        alt="Logo"
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(Heart);
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker === Heart
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Heart}
                                                        alt="Logo"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(Music);
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker === Music
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Music}
                                                        alt="Logo"
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(
                                                            Wavinghand1
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker ===
                                                            Wavinghand1
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Wavinghand1}
                                                        alt="Logo"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(
                                                            Wavinghand3
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker ===
                                                            Wavinghand3
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Wavinghand3}
                                                        alt="Logo"
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(
                                                            Wavinghand4
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker ===
                                                            Wavinghand4
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Wavinghand4}
                                                        alt="Logo"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={() => {
                                                        handleSticker(
                                                            Wavinghand5
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sticker ===
                                                            Wavinghand5
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.sticker
                                                        }
                                                        src={Wavinghand5}
                                                        alt="Logo"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <h2>Sounds</h2>
                                    <div className={styles.stickerContainer}>
                                        <div className={styles.stickerScroll}>
                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            CrowdCheer
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound === CrowdCheer
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={CrowdCheer}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                        alt="Logo"
                                                    >
                                                        Crowd Cheer
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            Tadaa
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound === Tadaa
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={Tadaa}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Tadaa
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(event, Yay);
                                                    }}
                                                    style={{
                                                        border:
                                                            sound === Yay
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={Yay}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Yay
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            Bellend
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound === Bellend
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={Bellend}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Bell end
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            ClockChime
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound === ClockChime
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={ClockChime}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Clock chime
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            OrganEnd
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound === OrganEnd
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={OrganEnd}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Organ end
                                                    </p>
                                                </div>
                                            </div>

                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            HarpMelody
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound === HarpMelody
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={HarpMelody}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Harp melody
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            TrumpetCall
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound ===
                                                            TrumpetCall
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={TrumpetCall}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Trumpet call
                                                    </p>
                                                </div>
                                            </div>

                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            WhistleStart
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound ===
                                                            WhistleStart
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={WhistleStart}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Whistle start
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            CrowdAww
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound === CrowdAww
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={CrowdAww}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Crowd aww
                                                    </p>
                                                </div>
                                            </div>

                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            Investigate
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound ===
                                                            Investigate
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={Investigate}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Investi-gate
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            NegativeSound
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound ===
                                                            NegativeSound
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={NegativeSound}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Negative sound
                                                    </p>
                                                </div>
                                            </div>

                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            WrongAnswer
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound ===
                                                            WrongAnswer
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={WrongAnswer}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Wrong answer
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            Readysetgo
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound === Readysetgo
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={Readysetgo}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Ready, set,go
                                                    </p>
                                                </div>
                                            </div>

                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            BirdTweet
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound === BirdTweet
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={BirdTweet}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Bird tweet
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            CatMeow
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound === CatMeow
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={CatMeow}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Cat meow
                                                    </p>
                                                </div>
                                            </div>

                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            ChickenCluck
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound ===
                                                            ChickenCluck
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={ChickenCluck}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Chicken cluck
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            DogBark
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound === DogBark
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={DogBark}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Dog bark
                                                    </p>
                                                </div>
                                            </div>

                                            <div className={styles.stickerRow}>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            LionRoar
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound === LionRoar
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={LionRoar}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Lion roar
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        styles.stickerWraper
                                                    }
                                                    onClick={(event) => {
                                                        handlePlay(
                                                            event,
                                                            SnakeHiss
                                                        );
                                                    }}
                                                    style={{
                                                        border:
                                                            sound === SnakeHiss
                                                                ? "3px solid #88ADDB"
                                                                : "",
                                                    }}
                                                >
                                                    <audio id="myAudio">
                                                        <source
                                                            src={SnakeHiss}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    <p
                                                        className={
                                                            styles.sticker
                                                        }
                                                    >
                                                        Snake hiss
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className={styles.btn}>
                                <p
                                    className={styles.nextBtn}
                                    onClick={handleNext}
                                >
                                    Next
                                </p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={2} sm={2}>
                    <div
                        style={{ position: "relative", cursor: "pointer" }}
                        onClick={() => history.push("/home")}
                    >
                        <img src={Logo} className={styles.logoImg} alt="Logo" />
                        <h1 className={styles.logoText}>
                            plassroom<sup>+</sup>
                        </h1>
                        <p className={styles.logoSubText}>Teacher</p>
                        <h1 className={styles.rightTime}>
                            <Clock />
                        </h1>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
