import React, { useState, useEffect, useRef } from "react";
import styles from "./classRatings.module.css";
import StarRatings from "react-star-ratings";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../../routes/GuestUser/components/Pagination/index";
import Report from "../../../assets/images/icon/accountSettings/Report.svg";
import ReportDark from "../../../assets/images/icon/accountSettings/ReportDark.svg";
import Confirm from "../../../assets/images/icon/accountSettings/confirm.svg";
// import Submit from "../../../assets/images/icon/accountSettings/Submit.svg"
import Snackbar from "../../Snackbar";
import { useHistory } from "react-router";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function ClassRatings() {
    const history = useHistory();
    const currentUserRole = JSON.parse(localStorage.getItem("auth"))?.user
        ?.role;
    const [noClassAttended, setNoClassAttended] = useState(false);
    const [reviewPending, setReviewPending] = useState(true);
    const [classRatings, setClassRatings] = useState(0);
    const [teacherRatings, setTeacherRatings] = useState(0);

    const [ratingAndReviewsData, setRatingAndReviewsData] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [reviewsPerPage, setReviewsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [upload, setUpload] = useState(false);
    const [reason, setReason] = useState();
    const [files, setFiles] = useState([]);
    const inputFile = useRef(null);
    const [sessionId, setSessionId] = useState();
    const [reportSuccess, setReportSuccess] = useState(false);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [snackBarStatus, setSnackBarStatus] = useState("error");
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const openUploadModal = () => {
        setUpload(true);
    };

    const closeUploadModal = () => {
        setUpload(false);
        setReason("");
        setFiles([]);
        setSessionId("");
    };

    const fetchReviewsAndRatings = async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(
                `/api/v1/ratingAndReviews?page=${pageNumber}&perPage=${reviewsPerPage}`
            );
            if (data?.success) {
                setRatingAndReviewsData(data?.data?.ratingAndReviewsData);
                setPaginationInfo(data?.data?.pagination);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            setMsg(
                _.get(error, "response.data.title", "Something went wrong.")
            );
            setSnackBarStatus("error");
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
            }, 3000);
            return Promise.reject(error);
        }
    };

    useEffect(() => {
        fetchReviewsAndRatings();
        // eslint-disable-next-line
    }, [pageNumber, reviewsPerPage]);

    const reportRating = async () => {
        const formData = new FormData();
        await formData.append("reason", reason);
        for (let i = 0; i <= files.length - 1; i++) {
            await formData.append("report-attachments", files[i]);
        }
        axios
            .post(`/api/v1/ratingAndReviews/${sessionId}/report`, formData)
            .then((response) => {
                if (response?.data?.success == true) {
                    closeUploadModal();
                    setReportSuccess(true);
                } else {
                    setMsg(response?.data?.message);
                    setSnackBarStatus("information");
                    setShowSnackbar(true);
                    setTimeout(() => {
                        setMsg(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                console.log(error);
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setSnackBarStatus("error");
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const handleImageUpload = () => {
        inputFile.current.click();
    };

    const fileSelected = (event) => {
        const extension = event?.target?.files[0]?.type
            .split("/")[1]
            .toUpperCase();
        const type = event?.target?.files[0]?.type
            ?.split("/")[0]
            ?.toUpperCase();
        if (
            type === "IMAGE" &&
            (extension === "PNG" || extension === "JPEG" || extension === "JPG")
        ) {
            setFiles([...files, event.target.files[0]]);
        } else {
            setMsg("Invalid file type");
            setShowSnackbar(true);
            setSnackBarStatus("error");
            setTimeout(function () {
                setMsg(null);
                setSnackBarStatus("");
            }, 3000);
        }
    };

    const deleteReportImage = (index) => {
        let filesCopy = [...files];
        filesCopy.splice(index, 1);
        setFiles(filesCopy);
    };

    return (
        <React.Fragment>
            {/* Report Modal */}
            {upload && (
                <div className={styles.uploadModal}>
                    <FontAwesomeIcon
                        className={styles.crossIcon}
                        icon={faTimes}
                        onClick={closeUploadModal}
                    />
                    <p className={styles.modalHeading}>
                        Please provide a reason and attach any supporting
                        documents or pictures.
                    </p>
                    <div>
                        <textarea
                            className={styles.uploadArea}
                            rows={4}
                            onChange={(e) => {
                                setReason(e.target.value);
                            }}
                        />
                    </div>
                    <div className="d-flex justify-content-between">
                        <div
                            className={styles.uploadBtn}
                            onClick={handleImageUpload}
                        >
                            <p className={styles.toolText}>Upload</p>
                            <FontAwesomeIcon
                                className={styles.uploadIcon}
                                style={{
                                    width: "25px",
                                }}
                                icon={faPlus}
                            />
                            <input
                                type="file"
                                id="file"
                                ref={inputFile}
                                style={{
                                    display: "none",
                                }}
                                onChange={fileSelected}
                                accept="audio/*,video/*,image/*"
                            />
                        </div>
                        <img
                            src={reason ? ReportDark : Report}
                            className={styles.submitButton}
                            onClick={() => reason && reportRating()}
                        />
                    </div>
                    {files &&
                        files.map((file, index) => {
                            return (
                                <div className="d-flex" key={index}>
                                    <p>{file?.name}</p>
                                    <p
                                        className={styles.previewDelete}
                                        onClick={() => {
                                            setIsPreviewModalOpen(true);
                                            setImageUrl(
                                                URL.createObjectURL(file)
                                            );
                                        }}
                                    >
                                        Preview
                                    </p>
                                    <p
                                        className={styles.previewDelete}
                                        onClick={deleteReportImage.bind(
                                            this,
                                            index
                                        )}
                                    >
                                        Delete
                                    </p>
                                </div>
                            );
                        })}
                    {isPreviewModalOpen ? (
                        <div className={styles.uploadModalImage}>
                            <div className={styles.uploadModalContainer}>
                                <FontAwesomeIcon
                                    className={styles.crossIconImageModal}
                                    icon={faTimes}
                                    onClick={() => setIsPreviewModalOpen(false)}
                                />

                                <div className={styles.previewImage__container}>
                                    <img src={imageUrl} alt="" />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            )}

            {/* After Report Modal */}
            {reportSuccess && (
                <div
                    className={`${styles.uploadModal} text-center`}
                    style={{ top: "30%" }}
                >
                    <FontAwesomeIcon
                        className={styles.crossIcon}
                        icon={faTimes}
                        onClick={() => {
                            setReportSuccess(false);
                            fetchReviewsAndRatings();
                        }}
                    />
                    <div className={styles.uploadArea}>
                        <p className={styles.modalHeading}>
                            Thank you for your feedback. The report will now be
                            reviewed by Plassroom and an email will be sent to
                            you with updates on the case.
                        </p>
                    </div>
                </div>
            )}
            <div className="rating-outer">
                {currentUserRole == "teacher" ? (
                    <>
                        <div className="rating-info">
                            {ratingAndReviewsData?.length > 0 ? (
                                <h2 className={styles.heading}>
                                    Recent ratings and reviews
                                </h2>
                            ) : (
                                <div>
                                    <p className={styles.noRating}>
                                        No previous ratings and reviews.{" "}
                                    </p>
                                    <h2
                                        className={styles.seeList}
                                        onClick={() => history.push("/")}
                                    >
                                        See list of upcoming classes
                                    </h2>
                                </div>
                            )}
                        </div>

                        {ratingAndReviewsData?.length > 0 &&
                            ratingAndReviewsData?.map((review, reviewIdx) => {
                                return (
                                    <div
                                        className="rating-list"
                                        key={reviewIdx}
                                    >
                                        <div className="rating-blog">
                                            <h3>
                                                {review?.session_history_id
                                                    ?.class_name &&
                                                    review?.session_history_id
                                                        ?.class_name}
                                            </h3>
                                            <h4>
                                                {review?.teacher_id
                                                    ?.preferedName
                                                    ? review?.teacher_id
                                                          ?.preferedName
                                                    : review?.teacher_id
                                                          ?.name}{" "}
                                                <span
                                                    style={{
                                                        fontWeight: 500,
                                                        paddingLeft: "20px",
                                                        color: "#13385c",
                                                        fontFamily:
                                                            "ComfortaBold",
                                                    }}
                                                >
                                                    {" "}
                                                    Date:{" "}
                                                    {moment(
                                                        review?.updatedAt
                                                    ).format("DD MMM YYYY")}
                                                </span>
                                            </h4>

                                            <div className="review-rating">
                                                <div className="d-flex align-items-center mb-2">
                                                    <p>Rating of class</p>
                                                    <div>
                                                        <StarRatings
                                                            starRatedColor="#FCB648"
                                                            starDimension="32px"
                                                            starSpacing="1px"
                                                            numberOfStars={
                                                                review?.class_rate
                                                            }
                                                            rating={
                                                                review?.class_rate
                                                            }
                                                            svgIconViewBox={
                                                                "0 0 1000 1000"
                                                            }
                                                            svgIconPath={
                                                                "M10,394.5c0-14.8,10.9-23.9,32.7-27.4l295.4-42.2L471,56.9c7.7-16.2,17.2-24.3,28.5-24.3s21.1,8.1,29.5,24.3l131.9,267.9l295.4,42.2c22.5,3.5,33.8,12.7,33.8,27.4c0,8.4-5.3,17.9-15.8,28.5L760,630.8l50.6,294.3c0.7,2.8,1.1,7,1.1,12.7c0,7.7-2.1,14.4-6.3,20c-4.2,5.6-10.2,8.8-17.9,9.5c-7,0-14.8-2.5-23.2-7.4L499.5,820.7L235.7,959.9c-9.1,4.9-17.2,7.4-24.3,7.4c-7.7,0-13.7-3.2-17.9-9.5c-4.2-6.3-6.3-13-6.3-20c0-2.8,0.4-7,1.1-12.7l50.6-294.3L24.8,423C14.9,412.4,10,402.9,10,394.5L10,394.5z"
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-2">
                                                    <p>Rating of facilitator</p>
                                                    <div>
                                                        <StarRatings
                                                            starRatedColor="#FCB648"
                                                            starDimension="32px"
                                                            starSpacing="1px"
                                                            numberOfStars={
                                                                review?.teacher_rate
                                                            }
                                                            rating={
                                                                review?.teacher_rate
                                                            }
                                                            svgIconViewBox={
                                                                "0 0 1000 1000"
                                                            }
                                                            svgIconPath={
                                                                "M10,394.5c0-14.8,10.9-23.9,32.7-27.4l295.4-42.2L471,56.9c7.7-16.2,17.2-24.3,28.5-24.3s21.1,8.1,29.5,24.3l131.9,267.9l295.4,42.2c22.5,3.5,33.8,12.7,33.8,27.4c0,8.4-5.3,17.9-15.8,28.5L760,630.8l50.6,294.3c0.7,2.8,1.1,7,1.1,12.7c0,7.7-2.1,14.4-6.3,20c-4.2,5.6-10.2,8.8-17.9,9.5c-7,0-14.8-2.5-23.2-7.4L499.5,820.7L235.7,959.9c-9.1,4.9-17.2,7.4-24.3,7.4c-7.7,0-13.7-3.2-17.9-9.5c-4.2-6.3-6.3-13-6.3-20c0-2.8,0.4-7,1.1-12.7l50.6-294.3L24.8,423C14.9,412.4,10,402.9,10,394.5L10,394.5z"
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <Form>
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="exampleForm.ControlTextarea1"
                                                    >
                                                        <Form.Label>
                                                            Review (optional)
                                                            {!review?.review && (
                                                                <span
                                                                    className={
                                                                        styles.reviewDetail
                                                                    }
                                                                >
                                                                    : n/a
                                                                </span>
                                                            )}
                                                        </Form.Label>
                                                        <p
                                                            className={
                                                                styles.reviewDetail
                                                            }
                                                        >
                                                            {review?.review}
                                                        </p>
                                                    </Form.Group>
                                                    <div className="btn-wrapper">
                                                        <Button
                                                            className="btn btn-orange"
                                                            disabled={
                                                                review?.is_reported
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    !review?.is_reported
                                                                ) {
                                                                    openUploadModal();
                                                                    setSessionId(
                                                                        review?._id
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            Report
                                                        </Button>
                                                        {review?.is_reported &&
                                                            !review?.reported_req_completed_by_admin && (
                                                                <span
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                        fontFamily:
                                                                            "RobotoMediumItalic",
                                                                    }}
                                                                >
                                                                    Pending
                                                                    Review by{" "}
                                                                    <br></br>{" "}
                                                                    Plassroom
                                                                </span>
                                                            )}
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                        {paginationInfo ? (
                            <Pagination
                                paginationInfo={paginationInfo}
                                setPageNumber={setPageNumber}
                            />
                        ) : null}
                    </>
                ) : (
                    <div>
                        {reviewPending && (
                            <div>
                                <p
                                    style={{
                                        fontSize: "23px",
                                        marginLeft: "13px",
                                    }}
                                >
                                    You have (one) class to rate and review.
                                    Would you like to do it now?
                                </p>
                                <div className="rating-list">
                                    <div className="rating-blog">
                                        <h3>
                                            Name of Class Lorem Ipsum Dolor Sit
                                            Amet Consec{" "}
                                        </h3>
                                        <h4>Teacher Name</h4>

                                        <div className="review-rating">
                                            <div className="d-flex align-items-center mb-2">
                                                <p>Rating of class</p>
                                                <div>
                                                    <StarRatings
                                                        starRatedColor="#FCB648"
                                                        starDimension="32px"
                                                        starSpacing="1px"
                                                        numberOfStars={5}
                                                        starEmptyColor="rgb(203, 211, 227)"
                                                        starHoverColor="#FCB648"
                                                        rating={classRatings}
                                                        changeRating={(
                                                            newRatings
                                                        ) =>
                                                            setClassRatings(
                                                                newRatings
                                                            )
                                                        }
                                                        svgIconViewBox={
                                                            "0 0 1000 1000"
                                                        }
                                                        svgIconPath={
                                                            "M10,394.5c0-14.8,10.9-23.9,32.7-27.4l295.4-42.2L471,56.9c7.7-16.2,17.2-24.3,28.5-24.3s21.1,8.1,29.5,24.3l131.9,267.9l295.4,42.2c22.5,3.5,33.8,12.7,33.8,27.4c0,8.4-5.3,17.9-15.8,28.5L760,630.8l50.6,294.3c0.7,2.8,1.1,7,1.1,12.7c0,7.7-2.1,14.4-6.3,20c-4.2,5.6-10.2,8.8-17.9,9.5c-7,0-14.8-2.5-23.2-7.4L499.5,820.7L235.7,959.9c-9.1,4.9-17.2,7.4-24.3,7.4c-7.7,0-13.7-3.2-17.9-9.5c-4.2-6.3-6.3-13-6.3-20c0-2.8,0.4-7,1.1-12.7l50.6-294.3L24.8,423C14.9,412.4,10,402.9,10,394.5L10,394.5z"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-2">
                                                <p>Rating of facilitator</p>
                                                <div>
                                                    <StarRatings
                                                        starRatedColor="#FCB648"
                                                        starDimension="32px"
                                                        starSpacing="1px"
                                                        numberOfStars={5}
                                                        starEmptyColor="rgb(203, 211, 227)"
                                                        starHoverColor="#FCB648"
                                                        rating={
                                                            teacherRatings
                                                                ? teacherRatings
                                                                : 0
                                                        }
                                                        changeRating={(
                                                            newRatings
                                                        ) =>
                                                            setTeacherRatings(
                                                                newRatings
                                                            )
                                                        }
                                                        svgIconViewBox={
                                                            "0 0 1000 1000"
                                                        }
                                                        svgIconPath={
                                                            "M10,394.5c0-14.8,10.9-23.9,32.7-27.4l295.4-42.2L471,56.9c7.7-16.2,17.2-24.3,28.5-24.3s21.1,8.1,29.5,24.3l131.9,267.9l295.4,42.2c22.5,3.5,33.8,12.7,33.8,27.4c0,8.4-5.3,17.9-15.8,28.5L760,630.8l50.6,294.3c0.7,2.8,1.1,7,1.1,12.7c0,7.7-2.1,14.4-6.3,20c-4.2,5.6-10.2,8.8-17.9,9.5c-7,0-14.8-2.5-23.2-7.4L499.5,820.7L235.7,959.9c-9.1,4.9-17.2,7.4-24.3,7.4c-7.7,0-13.7-3.2-17.9-9.5c-4.2-6.3-6.3-13-6.3-20c0-2.8,0.4-7,1.1-12.7l50.6-294.3L24.8,423C14.9,412.4,10,402.9,10,394.5L10,394.5z"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <Form>
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlTextarea1"
                                                >
                                                    <Form.Label>
                                                        Review (optional)
                                                        <span
                                                            className={
                                                                styles.reviewDetail
                                                            }
                                                        >
                                                            : This will appear
                                                            on the Plassroom
                                                            store and can be
                                                            seen by all teachers
                                                            and parents.
                                                        </span>
                                                    </Form.Label>
                                                    <textarea
                                                        rows="5"
                                                        type="text"
                                                        placeholder="Optional"
                                                        className={
                                                            styles.reviewInput
                                                        }
                                                    />
                                                </Form.Group>
                                                <div className="btn-wrapper">
                                                    <Button className="btn btn-orange">
                                                        Submit
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {noClassAttended ? (
                            <div>
                                <p className={styles.noRating}>
                                    No previous ratings and reviews. <br></br>
                                    After your child attends a class, we will
                                    prompt you to rate and review the class.
                                </p>
                                <h2
                                    className={styles.seeList}
                                    onClick={() => history.push("/")}
                                >
                                    See list of upcoming classes
                                </h2>
                            </div>
                        ) : (
                            <div>
                                <h2 className={styles.heading}>
                                    Previous ratings and reviews
                                </h2>
                                {ratingAndReviewsData &&
                                    ratingAndReviewsData?.map(
                                        (review, reviewIdx) => {
                                            return (
                                                <div
                                                    className="rating-list"
                                                    key={reviewIdx}
                                                >
                                                    <div className="rating-blog">
                                                        <h3>
                                                            {review
                                                                ?.session_history_id
                                                                ?.class_name &&
                                                                review
                                                                    ?.session_history_id
                                                                    ?.class_name}
                                                        </h3>
                                                        <h4>
                                                            {review?.teacher_id
                                                                ?.preferedName
                                                                ? review
                                                                      ?.teacher_id
                                                                      ?.preferedName
                                                                : review
                                                                      ?.teacher_id
                                                                      ?.name}{" "}
                                                            <span
                                                                style={{
                                                                    fontWeight: 500,
                                                                    paddingLeft:
                                                                        "20px",
                                                                    color: "#13385c",
                                                                }}
                                                            >
                                                                {" "}
                                                                Date:{" "}
                                                                {moment(
                                                                    review?.updatedAt
                                                                ).format(
                                                                    "DD[/]MM[/]YY"
                                                                )}
                                                            </span>
                                                        </h4>

                                                        <div className="review-rating">
                                                            <div className="d-flex align-items-center mb-2">
                                                                <p>
                                                                    Rating of
                                                                    class
                                                                </p>
                                                                <div>
                                                                    <StarRatings
                                                                        starRatedColor="#FCB648"
                                                                        starDimension="32px"
                                                                        starSpacing="1px"
                                                                        numberOfStars={
                                                                            review?.class_rate
                                                                        }
                                                                        rating={
                                                                            review?.class_rate
                                                                        }
                                                                        svgIconViewBox={
                                                                            "0 0 1000 1000"
                                                                        }
                                                                        svgIconPath={
                                                                            "M10,394.5c0-14.8,10.9-23.9,32.7-27.4l295.4-42.2L471,56.9c7.7-16.2,17.2-24.3,28.5-24.3s21.1,8.1,29.5,24.3l131.9,267.9l295.4,42.2c22.5,3.5,33.8,12.7,33.8,27.4c0,8.4-5.3,17.9-15.8,28.5L760,630.8l50.6,294.3c0.7,2.8,1.1,7,1.1,12.7c0,7.7-2.1,14.4-6.3,20c-4.2,5.6-10.2,8.8-17.9,9.5c-7,0-14.8-2.5-23.2-7.4L499.5,820.7L235.7,959.9c-9.1,4.9-17.2,7.4-24.3,7.4c-7.7,0-13.7-3.2-17.9-9.5c-4.2-6.3-6.3-13-6.3-20c0-2.8,0.4-7,1.1-12.7l50.6-294.3L24.8,423C14.9,412.4,10,402.9,10,394.5L10,394.5z"
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <p>
                                                                    Rating of
                                                                    facilitator
                                                                </p>
                                                                <div>
                                                                    <StarRatings
                                                                        starRatedColor="#FCB648"
                                                                        starDimension="32px"
                                                                        starSpacing="1px"
                                                                        numberOfStars={
                                                                            review?.teacher_rate
                                                                        }
                                                                        rating={
                                                                            review?.teacher_rate
                                                                        }
                                                                        svgIconViewBox={
                                                                            "0 0 1000 1000"
                                                                        }
                                                                        svgIconPath={
                                                                            "M10,394.5c0-14.8,10.9-23.9,32.7-27.4l295.4-42.2L471,56.9c7.7-16.2,17.2-24.3,28.5-24.3s21.1,8.1,29.5,24.3l131.9,267.9l295.4,42.2c22.5,3.5,33.8,12.7,33.8,27.4c0,8.4-5.3,17.9-15.8,28.5L760,630.8l50.6,294.3c0.7,2.8,1.1,7,1.1,12.7c0,7.7-2.1,14.4-6.3,20c-4.2,5.6-10.2,8.8-17.9,9.5c-7,0-14.8-2.5-23.2-7.4L499.5,820.7L235.7,959.9c-9.1,4.9-17.2,7.4-24.3,7.4c-7.7,0-13.7-3.2-17.9-9.5c-4.2-6.3-6.3-13-6.3-20c0-2.8,0.4-7,1.1-12.7l50.6-294.3L24.8,423C14.9,412.4,10,402.9,10,394.5L10,394.5z"
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <Form>
                                                                <Form.Group
                                                                    className="mb-3"
                                                                    controlId="exampleForm.ControlTextarea1"
                                                                >
                                                                    <Form.Label>
                                                                        Review
                                                                        (optional)
                                                                        {!review?.review && (
                                                                            <span
                                                                                className={
                                                                                    styles.reviewDetail
                                                                                }
                                                                            >
                                                                                :
                                                                                n/a
                                                                            </span>
                                                                        )}
                                                                    </Form.Label>
                                                                    <p
                                                                        className={
                                                                            styles.reviewDetail
                                                                        }
                                                                    >
                                                                        {
                                                                            review?.review
                                                                        }
                                                                    </p>
                                                                </Form.Group>
                                                                <div className="btn-wrapper">
                                                                    <Button className="btn btn-orange">
                                                                        Report
                                                                    </Button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}

                                {paginationInfo ? (
                                    <Pagination
                                        paginationInfo={paginationInfo}
                                        setPageNumber={setPageNumber}
                                    />
                                ) : null}
                            </div>
                        )}
                    </div>
                )}
            </div>
            {/* Snack bar message */}
            {msg && (
                <Snackbar
                    status={snackBarStatus}
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
        </React.Fragment>
    );
}
