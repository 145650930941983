import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { SnackbarContent } from "@material-ui/core";

function CustomizedSnackbars({ status, message, open, setOpen, duration }) {
    // const [open, setOpen] = React.useState(true);
    const statusToColor = {
        error: "#E36B2D",
        warning: "#FDD7C6",
        information: "#C8E8E5",
        success: "#88ADDB",
    };
    const statusToTextColor = {
        error: "#F3F3F3",
        success: "#F3F3F3",
        information: "#707070",
        warning: "#707070",
    };
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={open}
                autoHideDuration={duration || 3000}
                onClose={() => setOpen(false)}
            >
                <SnackbarContent
                    style={{
                        fontFamily: "RobotoMediumItalic",
                        fontSize: "20px",
                        color: statusToTextColor[status],
                        backgroundColor: statusToColor[status],
                        borderRadius: "15px",
                        padding: "8px 50px",
                    }}
                    message={message}
                />
            </Snackbar>
        </div>
    );
}

export default CustomizedSnackbars;
