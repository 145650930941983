const reviewData = [
    { review: "My 9 year old daughter absolutely loved this class. She said she loved learning how to draw a swimming costume and deciding on hairstyles, patterns and make up.", rating: 5 },
    { review: "My 9 year old daughter absolutely loved this class. She said she loved learning how to draw a swimming costume and deciding on hairstyles, patterns and make up.", rating: 3 },
    { review: "My 9 year old daughter absolutely loved this class. She said she loved learning how to draw a swimming costume and deciding on hairstyles, patterns and make up.", rating: 4 },
    // { review: "ipsum dolor sit amet consectetur adipisicing elit. Nostrum iure sed sint vero dolores.", rating: 4 },
    // { review: "amet consectetur adipisicing elit. Nostrum iure sed sint vero dolores.", rating: 2 },
    // { review: "dolor sit amet consectetur adipisicing elit. Nostrum iure sed sint vero dolores.", rating: 5 },
    // { review: "maksc, Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nostrum iure sed sint vero dolores.", rating: 1 }
]

export default reviewData