import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { colors } from "./../../../../utils/colors";
import styles from "../paymentDetails.module.css";
import stripe from "../../../../assets/images/stripe/stripe.png";
import SubscriptionDetailsBox from "../SubscriptionDetailsBox";
import Snackbar from "../../../../components/Snackbar";
import "../style.css";
import PageLoader from "../../../PageLoader";
// import PrivacyPolicy from "../../../../assets/documents/Privacy Policy 1.pdf";
// import TermsOfService from "../../../../assets/documents/Terms of Service 1.pdf";

export default function StudentPaymentDetails() {
    const [data, setData] = useState([]);
    const [sortData, setSortData] = useState([]);
    const [expand, setExpand] = useState(true);
    const [collapseRow, setCollapseRow] = useState(null);
    const [showDetail, setShowDetail] = useState(false);
    const currentUserRole = JSON.parse(localStorage.getItem("auth"))?.user
        ?.role;
    const [savedCard, setSavedCard] = useState("");
    const [details, setDetails] = useState({});
    const [plassCoins, setPlassCoins] = useState(0);
    const [plans, setPlans] = useState([]);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackBarStatus, setSnackBarStatus] = useState("error");
    const [loading, setLoading] = useState(false);

    const getSubscriptionDetails = async () => {
        // setDetails(dataD);
        await axios
            .get("api/v1/subscription")
            .then((response) => {
                if (response?.data?.success) {
                    if (response?.data?.data) {
                        setDetails(response?.data?.data);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchPlanDetails = async () => {
        await axios
            .get("/api/v1/user/plan")
            .then((response) => {
                if (response?.data?.success) {
                    setPlassCoins(response?.data?.data?.totalRemainingMinutes);
                    setPlans(response?.data?.data?.plans);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        studentTransactionHistory();
        checkAccountDetailsStudent();
        if (localStorage.getItem("auth")) {
            if (
                JSON.parse(localStorage.getItem("auth")).user.role === "student"
            ) {
                getSubscriptionDetails();
                fetchPlanDetails();
            }
        }
        // eslint-disable-next-line
    }, []);

    const studentTransactionHistory = () => {
        axios
            .get("api/v1/payment/student-transaction-history")
            .then((res) => {
                if (res?.data?.data?.length > 0) {
                    setData(res?.data?.data);
                    setSortData(
                        res?.data?.data
                            ?.sort((a, b) => {
                                return (
                                    new Date(b?.updatedAt) -
                                    new Date(a?.updatedAt)
                                );
                            })
                            .slice(0, 3)
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const checkAccountDetailsStudent = () => {
        axios
            .get("api/v1/payment/get-stripe-customer-cards")
            .then((res) => {
                setSavedCard(res?.data?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const createAccount = () => {
        axios
            .get("api/v1/payment/stripe-account-link")
            .then((res) => {
                window.open(res?.data?.data?.url, "_self");
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleUpgradePlan = async (id) => {
        console.log(id);

        setLoading(true);
        await axios
            .put(`api/v1/subscription/upgrade/${id}`)
            .then((response) => {
                if (response?.data?.success) {
                    window.open(response?.data?.data?.checkout, "_self");
                    setLoading(false);
                } else {
                    setLoading(false);
                    setMsg("Something went wrong");
                    setSnackBarStatus("error");
                    setShowSnackbar(true);
                    setTimeout(() => {
                        setMsg(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setLoading(false);
                setMsg(error);
                setSnackBarStatus("error");
                setShowSnackbar(true);
                setTimeout(() => {
                    setMsg(null);
                }, 3000);
            });
    };

    return (
        <React.Fragment>
            {msg && (
                <Snackbar
                    status={snackBarStatus}
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            {loading && <PageLoader open={loading} />}
            <div className={styles.studentblock}>
                <div className={`payment-outer ${styles.studentScroll}`}>
                    <div
                        className={`payment-outer-wrapper ${styles.innerContainer}`}
                    >
                        <div className="row">
                            <div className="col-12">
                                <div className="personal-detail">
                                    <h3>Subscription details</h3>
                                    {!!details && !!plans ? (
                                        <div className="subscription-detail">
                                            <SubscriptionDetailsBox
                                                totalPlassCoins={plassCoins}
                                                plans={plans}
                                                details={details}
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <Link
                                        style={{ paddingLeft: "2vw" }}
                                        to="/choose-plan-and-subscription-plan"
                                    >
                                        Subscribe
                                    </Link>
                                    {details?.plan &&
                                        details?.plan?.type === "Lite" && (
                                            <>
                                                {" "}
                                                <span> or </span>
                                                <span
                                                    onClick={() =>
                                                        handleUpgradePlan(
                                                            details
                                                                ?.subscription
                                                                ?._id
                                                        )
                                                    }
                                                    style={{
                                                        color: "#88addb",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Upgrade
                                                </span>
                                            </>
                                        )}
                                    <br />
                                    <a
                                        href="mailto:enquiry@plassroom.com"
                                        style={{
                                            paddingLeft: "2vw",
                                            color: "#88addb",
                                            cursor: "pointer",
                                        }}
                                    >
                                        Contact us
                                    </a>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="personal-detail payment-detail">
                                    {savedCard?.length > 0 && (
                                        <div className="d-flex flex-row justify-content-between mt-3">
                                            <h3>Current payment details</h3>
                                            <img
                                                width="42px"
                                                height="21px"
                                                src={stripe}
                                                alt="stripe"
                                            />
                                        </div>
                                    )}
                                    <div className="d-flex flex-column mb-3">
                                        {savedCard?.length > 0 &&
                                            savedCard.map((cardData, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="d-flex flex-row justify-content-start"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                styles.cardBoxStudent
                                                            }
                                                        >
                                                            <p
                                                                className={`mr-3 ${styles.ltd} ${styles.oneRem}`}
                                                            >
                                                                {cardData?.brand
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                    cardData?.brand.slice(
                                                                        1
                                                                    )}{" "}
                                                                {` ending  ****${cardData?.last4}`}
                                                            </p>
                                                            {/* <Link
                                                                to="/paymentdetails/editaccount"
                                                                className={
                                                                    styles.edit
                                                                }
                                                            >
                                                                Edit
                                                            </Link> */}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                    <div className="payment-des">
                                        <p>
                                            We use Stripe to keep your details
                                            secure.{" "}
                                            <span
                                                style={{
                                                    cursor: "pointer",
                                                    color: "#88ADDB",
                                                }}
                                                onClick={() => createAccount()}
                                            >
                                                Click
                                            </span>{" "}
                                            to set up your payments on Stripe.
                                        </p>
                                        <Link to="/termsofservice">
                                            See our{" "}
                                            <Link to="/termsofservice">
                                                Terms of Service and Payment
                                                Terms of Service
                                            </Link>{" "}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="personal-detail mt-3">
                                    <div className="history-title d-flex justify-content-between">
                                        <h3>Transaction history</h3>
                                        <p>
                                            GBP (<span className="in">in</span>/
                                            <span className="out">out</span>)
                                        </p>
                                    </div>
                                    {sortData.length <= 0 ? (
                                        <p style={{ marginLeft: "8px" }}>
                                            No transaction done yet
                                        </p>
                                    ) : (
                                        <div className="table-outer">
                                            <table
                                                className={`table ${styles.paymentTable}`}
                                                id="historyTable"
                                            >
                                                <tbody
                                                    className={
                                                        styles.tableBodyMain
                                                    }
                                                >
                                                    <tr
                                                        className={
                                                            styles.tableBody
                                                        }
                                                        style={{
                                                            color: "#88addb",
                                                        }}
                                                    >
                                                        <td>Date</td>
                                                        <td>Name of class</td>
                                                        <td className="text-center">
                                                            Time (Duration)
                                                        </td>
                                                        <td
                                                            className="text-center"
                                                            hidden={
                                                                currentUserRole ===
                                                                "teacher"
                                                                    ? false
                                                                    : true
                                                            }
                                                        >
                                                            Students attended
                                                        </td>
                                                        <td className="text-center">
                                                            Transacted (£) in/
                                                            <span
                                                                style={{
                                                                    color: colors.orange,
                                                                }}
                                                            >
                                                                out
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                {sortData &&
                                                    sortData.map(
                                                        (data, index) => {
                                                            return (
                                                                <tbody
                                                                    className={
                                                                        styles.tableBodyMain
                                                                    }
                                                                    key={index}
                                                                >
                                                                    <tr
                                                                        className={
                                                                            styles.tableBody
                                                                        }
                                                                        style={{
                                                                            backgroundColor:
                                                                                data
                                                                                    ?.refund
                                                                                    ?.status ===
                                                                                "succeeded"
                                                                                    ? "#E9F3FB"
                                                                                    : data?.paymentStatus ===
                                                                                      "succeeded"
                                                                                    ? "#FDD7C6"
                                                                                    : data?.applicationFeeAmount ===
                                                                                      0
                                                                                    ? "#FDD7C6"
                                                                                    : "#E2E2E2",
                                                                        }}
                                                                    >
                                                                        <td>
                                                                            {data
                                                                                ?.refund
                                                                                ?.status ===
                                                                            "succeeded"
                                                                                ? moment
                                                                                      .utc(
                                                                                          data
                                                                                              ?.refund
                                                                                              ?.date
                                                                                      )
                                                                                      .format(
                                                                                          "DD MMM YYYY"
                                                                                      )
                                                                                : moment
                                                                                      .utc(
                                                                                          data?.updatedAt
                                                                                      )
                                                                                      .format(
                                                                                          "DD MMM YYYY"
                                                                                      )}
                                                                        </td>
                                                                        <td>
                                                                            {data
                                                                                ?.refund
                                                                                ?.status ===
                                                                                "succeeded" &&
                                                                                `Refund - `}
                                                                            {
                                                                                data?.className
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {data
                                                                                ?.refund
                                                                                ?.status !==
                                                                                "succeeded" &&
                                                                                moment
                                                                                    .utc(
                                                                                        data?.time
                                                                                    )
                                                                                    .format(
                                                                                        "hh:mma"
                                                                                    )}
                                                                            {data
                                                                                ?.refund
                                                                                ?.status !==
                                                                            "succeeded"
                                                                                ? ` (${data?.duration}mins)`
                                                                                : "-"}
                                                                        </td>
                                                                        <td
                                                                            className="text-center"
                                                                            style={{
                                                                                color:
                                                                                    data
                                                                                        ?.refund
                                                                                        ?.status ===
                                                                                    "succeeded"
                                                                                        ? "#002B4A"
                                                                                        : data?.paymentStatus ===
                                                                                              "succeeded" ||
                                                                                          data?.applicationFeeAmount ===
                                                                                              0
                                                                                        ? "#E36B2D"
                                                                                        : "#707070",
                                                                            }}
                                                                        >
                                                                            {data
                                                                                ?.refund
                                                                                ?.status ===
                                                                            "succeeded" ? (
                                                                                typeof data?.totalPayableAmount ===
                                                                                "number" ? (
                                                                                    Number(
                                                                                        data?.totalPayableAmount
                                                                                    ).toFixed(
                                                                                        2
                                                                                    )
                                                                                ) : (
                                                                                    data?.totalPayableAmount
                                                                                )
                                                                            ) : data?.paymentStatus ===
                                                                                  "succeeded" ||
                                                                              data?.applicationFeeAmount ===
                                                                                  0 ? (
                                                                                typeof data?.totalPayableAmount ===
                                                                                "number" ? (
                                                                                    Number(
                                                                                        data?.totalPayableAmount
                                                                                    ).toFixed(
                                                                                        2
                                                                                    )
                                                                                ) : (
                                                                                    data?.totalPayableAmount
                                                                                )
                                                                            ) : (
                                                                                <span
                                                                                    style={{
                                                                                        fontStyle:
                                                                                            "italic",
                                                                                    }}
                                                                                >
                                                                                    Failed
                                                                                    transaction
                                                                                </span>
                                                                            )}
                                                                            {/* {Number(
                                                                            data?.totalPayableAmount
                                                                        ).toFixed(2)} */}
                                                                        </td>
                                                                    </tr>
                                                                    <div
                                                                        className={
                                                                            styles.classDetail
                                                                        }
                                                                        hidden={
                                                                            showDetail &&
                                                                            collapseRow ===
                                                                                index
                                                                                ? false
                                                                                : true
                                                                        }
                                                                    ></div>
                                                                    {/* <p>Attended:</p> */}
                                                                </tbody>
                                                            );
                                                        }
                                                    )}
                                            </table>
                                            {data?.length > 3 && (
                                                <p
                                                    style={{
                                                        color: colors.blue,
                                                        cursor: "pointer",
                                                        fontSize: "18px",
                                                        marginLeft: "10px",
                                                        fontFamily:
                                                            "ComfortaBold",
                                                    }}
                                                    onClick={() => {
                                                        if (expand) {
                                                            setExpand(false);
                                                            setSortData(data);
                                                        } else {
                                                            setExpand(true);
                                                            setSortData(
                                                                data.slice(0, 3)
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {expand
                                                        ? "See more..."
                                                        : "See less..."}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
