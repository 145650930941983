import React from "react";
import styles from "./Layout1.module.css";
import TwilioParticipant from "../../../../../../components/TwilioParticipant";
import Participant from "../../../../../../components/Participant";
import Game from "../../Game/index";

export default ({
    participants,
    room,
    mutedUsers,
    publishAudio,
    socket,
    role,
    sessionID,
    rightTabIndex,
    totalImages,
    imageTypes,
    status,
    currentPanel,
    session,
    setSession,
    identifier,
}) => {
    var Teacher = null;
    var Students = [];

    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;

    if (currentUserRole === "student") {
        var teacherParticipant = participants.find((obj) => {
            var identityObj = JSON.parse(obj.identity);
            return identityObj.role === "teacher";
        });
        if (teacherParticipant) {
            Teacher = (
                <TwilioParticipant
                    role="teacher"
                    key={teacherParticipant.sid}
                    participant={teacherParticipant}
                />
            );
        }
        if (room) {
            Students = [
                <TwilioParticipant
                    role="student"
                    key={room.localParticipant.sid}
                    participant={room.localParticipant}
                    publishAudio={publishAudio}
                    screen="student"
                    hideVideo={true}
                />,
            ].concat(
                participants
                    .filter((obj) => {
                        var identityObj = JSON.parse(obj.identity);
                        return identityObj.role !== "teacher";
                    })
                    .map((participant) => (
                        <TwilioParticipant
                            role="student"
                            key={participant.sid}
                            participant={participant}
                            publishAudio={publishAudio}
                            screen="student"
                            hideVideo={true}
                        />
                    ))
            );
        }
    }

    let noOfParticipants = participants.length;
    let participantBoxes = [];

    for (let i = 0; i < noOfParticipants; i++) {
        participantBoxes.push(Students[i]);
    }

    return (
        <div className={styles.assets}>
            <Game
                sessionID={sessionID}
                socket={socket}
                participants={participants}
                room={room}
                // key={participants.length}
                status={status}
                currentPanel={currentPanel}
                session={session}
                setSession={setSession}
                userName={identifier}
                renderGame={true}
            />
            <div className={`${styles.block} ${styles.blockMedia}`}>
                <Participant>{Teacher}</Participant>

                {participantBoxes}
            </div>
        </div>
    );
};
