import React, { useEffect, useState } from "react";
import styles from "./play.module.css";
import ControlsBar from "./ControlsBar";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import Video from "twilio-video";
import StudentLayout1 from "./Video/Student/Layout1/index";
import StudentLayout2 from "./Video/Student/Layout2/index";
import StudentLayout3 from "./Video/Student/Layout3/index";
import StudentLayout4 from "./Video/Student/Layout4/index";
import TeacherLayout1 from "./Video/Teacher/Layout1/index";
import TeacherLayout2 from "./Video/Teacher/Layout2/index";
import TeacherLayout3 from "./Video/Teacher/Layout3/index";
import TeacherLayout4 from "./Video/Teacher/Layout4/index";
import LessonTeacherLayout1 from "./Lesson/Teacher/Layout1/index";
import LessonTeacherLayout2 from "./Lesson/Teacher/Layout2/index";
import LessonTeacherLayout3 from "./Lesson/Teacher/Layout3/index";
import LessonTeacherLayout4 from "./Lesson/Teacher/Layout4/index";
import LessonStudentLayout1 from "./Lesson/Student/Layout1/index";
import LessonStudentLayout2 from "./Lesson/Student/Layout2/index";
import LessonStudentLayout3 from "./Lesson/Student/Layout3/index";
import LessonStudentLayout4 from "./Lesson/Student/Layout4/index";
import Watermark from "../../../assets/images/plassroomwatermark.svg";
import socketIOClient from "socket.io-client";
import _ from "lodash";
import Modal from "../Play/Whiteboard/Modal/index";
import TimeoutWarningModal from "./TimoutWarningModal/index";
// import Whiteboard from "../../../components/WhiteboardByNirajan";
import WhiteboardTeacherLayout1 from "./Whiteboard/Teacher/Layout1/index";
import WhiteboardTeacherLayout2 from "./Whiteboard/Teacher/Layout2/index";
import WhiteboardTeacherLayout3 from "./Whiteboard/Teacher/Layout3/index";
import WhiteboardStudentLayout1 from "./Whiteboard/Student/Layout1/index";
import WhiteboardStudentLayout2 from "./Whiteboard/Student/Layout2/index";
import WhiteboardStudentLayout3 from "./Whiteboard/Student/Layout3/index";
import WhiteboardStudentLayout4 from "./Whiteboard/Student/Layout4/index";
import WhiteboardReqTeacherLayout1 from "./Whiteboard/RequiredStudentWB/Teacher/Layout1/index";
import WhiteboardReqTeacherLayout2 from "./Whiteboard/RequiredStudentWB/Teacher/Layout2/index";
import WhiteboardReqTeacherLayout3 from "./Whiteboard/RequiredStudentWB/Teacher/Layout3/index";
import WhiteboardReqStudentLayout1 from "./Whiteboard/RequiredStudentWB/Student/Layout1/index";
import WhiteboardReqStudentLayout2 from "./Whiteboard/RequiredStudentWB/Student/Layout2/index";
import WhiteboardReqStudentLayout3 from "./Whiteboard/RequiredStudentWB/Student/Layout3/index";
import WhiteboardReqStudentLayout4 from "./Whiteboard/RequiredStudentWB/Student/Layout4/index";
import Game1Teacher from "../../SessionHome/Layouts/Game1/Teacher/Layout1/index";
import Game1Student from "../../SessionHome/Layouts/Game1/Student/Layout1/index";
import Game2Teacher from "../../SessionHome/Layouts/Game2/Teacher/Layout1/index";
import Game2Student from "../../SessionHome/Layouts/Game2/Student/Layout1/index";
import StudentScreen from "./SharedScreen/StudentScreen";
import TeacherScreen from "./SharedScreen/TeacherScreen";
import moment from "moment-timezone";
import Snackbar from "../../../components/Snackbar";

// import { audioEffects, graphics } from "../../../components/Effects/effects";
import CountDown from "../../../components/CountDown/CountDown";
import StopSharingModal from "./StopSharingModal";
// import HappyFace from "../../../assets/images/2.Happy Face 2.gif";

export default () => {
    const { sessionID } = useParams();
    const [session, setSession] = useState(undefined);
    const location = useLocation();
    let identifier = new URLSearchParams(location.search).get("identifier");
    identifier = atob(identifier);
    const [state, setState] = useState(undefined);
    const [btn, setBtn] = useState(false);
    const [rightPanelSettings, setRightPanelSettings] = useState([]);
    const [twilio, setTwilio] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [room, setRoom] = useState(null);
    const [screenArrangement, setScreenArrangement] = useState(false);
    const [whiteboardTeacherView, setWhiteboardTeacherView] =
        useState(undefined);
    const [whiteboardStudentView, setWhiteboardStudentView] =
        useState(undefined);
    const [socketEventsBinded, setSocketEventsBinded] = useState(false);
    const [mutedUsers, setMutedUsers] = useState([]);
    const [publishAudio, setPublishAudio] = useState(true);
    const [mute, setMute] = useState(false);
    const [key, setKey] = useState(0);
    const [isIndividual, setIsIndividual] = useState(false);
    const [wrapperStyle, setWrapperStyle] = useState("wrapper");
    const [totalImages, setTotalImages] = useState();
    const [initialImageTypes, setInitialImageTypes] = useState();
    const [game1Status, setGame1Status] = useState("stop");
    // const [selectedTeam, setSelectedTeam] = useState();
    // const [teams, setTeams] = useState();
    const [drawData, setDrawData] = useState(null);
    const [graphicReceived, setGraphicReceived] = useState(false);
    const [graphic, setGraphic] = useState(null);
    const [sound, setSound] = useState(null);
    const [soundReceived, setSoundReceived] = useState(false);
    const [socket, setSocket] = useState(null);
    const [showTimeoutWarningModal, setShowTimeoutWarningModal] =
        useState(false);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showScreenSharedModal, setShowScreenSharedModal] = useState(false);
    const [shareScreen, setShareScreen] = useState(false);
    const [isConfigChanged, setIsConfigChanged] = useState(false);
    const [accessAll, setAccessAll] = useState(true);
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [screenTrackState, setscreenTrackState] = useState(null);
    const currentUserRole = JSON.parse(localStorage?.getItem("auth"))?.user
        ?.role;

    window.onbeforeunload = (e) => {
        if (shareScreen) {
            handleStopSharing();
            return true;
        }
    };

    useEffect(() => {
        let timeoutWarningTimer = undefined;
        if (currentUserRole === "student") {
            localStorage.setItem("reviewPopupCount", 1);
        }
        const getSessionInfo = async () => {
            await axios
                .get(`/api/v1/session/${sessionID}`)
                .then((result) => {
                    if (result.data.success) {
                        const resultSession = result.data.data.session;
                        setSession(resultSession);

                        const role = JSON.parse(localStorage.getItem("auth"))
                            .user.role;
                        let currentPanel =
                            resultSession.configuration.right_panel_settings.find(
                                (obj) => obj.isActive
                            ) ||
                            resultSession.configuration.right_panel_settings[0];

                        setAccessAll(
                            !Boolean(
                                currentPanel.lesson_settings.whiteboard
                                    ?.student_access.length
                            )
                        );

                        const duration = {
                            hours:
                                resultSession.configuration.session_duration >
                                60
                                    ? Math.floor(
                                          resultSession.configuration
                                              .session_duration / 60
                                      )
                                    : 0,
                            minutes:
                                resultSession.configuration.session_duration %
                                60,
                        };
                        // const sessionStartTime =
                        //     resultSession.configuration.session_start_time;
                        const currentTime = moment();
                        // const currentTimeUTC = currentTime
                        //     .utc()
                        //     .format("DD/MM/YYYY hh:mm a");
                        // const endTime = moment
                        //     .utc(resultSession.configuration.session_end_time)
                        //     .format("DD/MM/YYYY hh:mm a");
                        const sessionEndTime = moment
                            .utc(
                                resultSession.configuration.session_start_time,
                                "DD/MM/YYYY hh:mm a"
                            )
                            .local()
                            .add(duration);
                        const timeoutInterval =
                            sessionEndTime.diff(currentTime);
                        if (role === "teacher") {
                            if (currentPanel.type === "ACTIVITY") {
                                setWrapperStyle("wrapperWB");
                            } else if (
                                currentPanel.type === "VIDEO" &&
                                currentPanel.subType === "SCREENSHARE"
                            ) {
                                setWrapperStyle("wrapperScreenShare");
                            } else {
                                setWrapperStyle("wrapper");
                            }

                            if (timeoutInterval >= 5 * 60 * 1000) {
                                timeoutWarningTimer = setTimeout(() => {
                                    // setShowTimeoutWarningModal(true)
                                }, timeoutInterval - 5 * 60 * 1000);
                            }
                            if (resultSession?.configuration?.screen_shared) {
                                updateSession(
                                    {
                                        ...resultSession,
                                        configuration: {
                                            ...resultSession?.configuration,
                                            screen_shared: false,
                                        },
                                    },
                                    true
                                );
                            }
                        } else if (role === "student") {
                            setWrapperStyle("wrapper");
                            handleAttendStudent(resultSession._id);
                        }
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        getSessionInfo();
        return () => {
            clearTimeout(timeoutWarningTimer);
        };
        // eslint-disable-next-line
    }, [sessionID, isConfigChanged]);

    let assetType;
    let assetSubType;

    useEffect(() => {
        const socket = socketIOClient(process.env.REACT_APP_BACKEND_URL, {
            query: {
                userId: JSON.parse(localStorage.getItem("auth")).user._id,
                sessionId: sessionID,
                userRole: JSON.parse(localStorage.getItem("auth")).user.role,
            },
            pingTimeout: 60000,
        });

        setSocket(socket);
        // eslint-disable-next-line
    }, [sessionID]);

    useEffect(() => {
        if (!socket) {
            return;
        }
        let sessionEndTimer = undefined;
        if (session) {
            const role = JSON.parse(localStorage.getItem("auth")).user.role;
            const duration = {
                hours:
                    session.configuration.session_duration > 60
                        ? Math.floor(
                              session.configuration.session_duration / 60
                          )
                        : 0,
                minutes: session.configuration.session_duration % 60,
            };
            const currentTime = moment();
            const sessionEndTime = moment
                .utc(
                    session.configuration.session_end_time
                    // "DD/MM/YYYY hh:mm a"
                )
                .local()
                .add(duration);
            const timeoutInterval = sessionEndTime.diff(currentTime);
            if (role === "teacher") {
                sessionEndTimer = setTimeout(() => {
                    endTeacherCallOnTimerEnd();
                }, timeoutInterval);
            } else if (role === "student") {
                sessionEndTimer = setTimeout(
                    () => handleEndUserSession(),
                    timeoutInterval
                );
            }
        }

        bindSocketEvents(socket);
        setSocketEventsBinded(true);
        return () => {
            socket.off("studentLayoutChange", handleStudentLayout);
            socket.off("sessionState", handleSessionState);
            socket.off("sessionDrawData", handleSessionDrawData);
            socket.off("endUserSession", handleEndUserSession);
            socket.off("muteUser", handleMuteUser);
            socket.off("unMuteUser", handleUnMuteUser);
            socket.off("lessonChange", handleLessonChange);
            socket.off("imageTypesChange", handleImageTypeChange);
            socket.off("totalImagesChange", handleTotalImagesChange);
            socket.off("turnBasedChange", handleTurnBasedChange);
            socket.off("timerChange", handleTimerChange);
            socket.off("scoreboardChange", handleScoreboardChange);
            socket.off("studentConfigChange", onStudentConfigChange);
            socket.off("shareScreen", handleShare);
            clearTimeout(sessionEndTimer);
        };
        // eslint-disable-next-line
    }, [socket, mutedUsers, session]);

    const handleAttendStudent = (sessionID) => {
        axios
            .put(`/api/v1/session/${sessionID}/attend`)
            .then(() => {})
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const handleStudentLayout = (data) => {
        if (session) {
            const newSession = {
                ...session,
                ...{
                    configuration: {
                        ...session.configuration,
                        right_panel_settings:
                            session.configuration.right_panel_settings.map(
                                (obj) => {
                                    if (obj._id === data.changedPanel._id) {
                                        return data.changedPanel;
                                    }
                                    return obj;
                                }
                            ),
                    },
                },
            };
            setSession(newSession);
        }
    };

    useEffect(() => {
        if (!socketEventsBinded) {
            return;
        }
        async function fetchData() {
            await axios
                .get(`/api/v1/twilio/session/${sessionID}`)
                .then((response) => {
                    if (!response.data.success) {
                        setMsg(response.data.message);
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    } else {
                        setTwilio(response.data.data);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        }
        fetchData();
        // eslint-disable-next-line
    }, [socketEventsBinded]);

    useEffect(() => {
        if (!(twilio && socket)) {
            return;
        }

        if (!window.studentToColorMap) {
            window.studentToColorMap = {};
        }
        const participantConnected = (participant) => {
            setParticipants((prevParticipants) => [
                ...prevParticipants,
                participant,
            ]);
        };

        const participantDisconnected = (participant) => {
            setParticipants((prevParticipants) =>
                prevParticipants.filter((p) => p !== participant)
            );
        };
        Video.connect(twilio.token, {
            name: twilio.session.room_name_slug,
        })
            .then((room) => {
                setRoom(room);
                room.on("participantConnected", participantConnected);
                room.on("participantDisconnected", participantDisconnected);
                room.participants.forEach(participantConnected);
            })
            .catch((error) => {
                console.log("Could not connect to the Room:", error.message);
            });

        return () => {
            setRoom((currentRoom) => {
                if (
                    currentRoom &&
                    currentRoom.localParticipant.state === "connected"
                ) {
                    currentRoom.localParticipant.tracks.forEach(function (
                        trackPublication
                    ) {
                        trackPublication.track.stop();
                    });
                    currentRoom.disconnect();
                    return null;
                } else {
                    return currentRoom;
                }
            });
        };
        // eslint-disable-next-line
    }, [twilio, socket]);

    const changeShareView = async (value) => {
        const newSession = {
            ...session,
            configuration: {
                ...session.configuration,
                screen_shared: value,
            },
        };
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });

        socket.emit("shareScreen", {
            action: "shareScreen",
            payload: {
                userIds,
                newSession,
            },
        });
        setSession(newSession);
        await updateSession(newSession);
    };

    var screenTrack;
    const shareScreenHandler = () => {
        if (!screenTrack) {
            navigator.mediaDevices
                .getDisplayMedia()
                .then((stream) => {
                    screenTrack = new Video.LocalVideoTrack(
                        stream.getTracks()[0]
                    );
                    room.localParticipant.publishTrack(screenTrack);

                    screenTrack.mediaStreamTrack.onended = () => {
                        shareScreenHandler();
                    };
                    room.localParticipant.publishTrack(screenTrack);
                    changeShareView(true);
                    setShareScreen(true);
                    setscreenTrackState(screenTrack);
                })
                .catch(() => {
                    setMsg("Could not share the screen.");
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    changeShareView(false);
                    setRefreshFlag(refreshFlag + 1);
                    setShareScreen(false);
                });
        } else {
            room.localParticipant.unpublishTrack(screenTrack);
            screenTrack.stop();
            screenTrack = null;
            changeShareView(false);
            setRefreshFlag(refreshFlag + 1);
            setShareScreen(false);
        }
    };

    const handleStopSharing = () => {
        room.localParticipant.unpublishTrack(screenTrackState);
        screenTrackState.stop();
        setscreenTrackState(null);
        changeShareView(false);
        setRefreshFlag(refreshFlag + 1);
        setShareScreen(false);
    };

    // const wrapperUpdate = () => {
    //   const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;
    //   if (assetSubType === "WHITEBOARD" && !isStudentWBRequired) {
    //     if (currentUserRole === "student") {
    //       if (whiteboardStudentView === 1 || whiteboardStudentView === 3)
    //         setWrapperStyle(styles.wrapper);
    //       else setWrapperStyle(styles.wrapperWB);
    //     } else {
    //       if (whiteboardTeacherView === 3) setWrapperStyle(styles.wrapper);
    //       else setWrapperStyle(styles.wrapperWB);
    //     }
    //   } else {
    //     setWrapperStyle(styles.wrapper);
    //   }
    // };

    // useEffect(() => {
    //   wrapperUpdate();
    // }, [whiteboardTeacherView, whiteboardStudentView]);

    const handleSessionState = (data) => {
        if (data.draw_data) {
            setDrawData(data.draw_data);
        }
        const currentUserRole = JSON.parse(localStorage.getItem("auth")).user
            .role;

        if (data && currentUserRole === "teacher") {
            if (data.muted_users) {
                setMutedUsers(data.muted_users);
            }
        } else if (data && currentUserRole === "student") {
            if (data) {
                setPublishAudio(data.publish_audio);
            }
        }
    };

    const handleSessionDrawData = (data) => {
        if (data.draw_data) {
            setDrawData(data.draw_data);
        }
    };

    const handleEndUserSession = () => {
        socket.disconnect();
        setSocket(null);
        window.location.href = "/";
    };

    const handleLessonChange = (data) => {
        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj, index) => {
                                obj.isActive =
                                    obj._id === data.changedPanel._id;
                                if (obj._id === data.changedPanel._id) {
                                    return data.changedPanel;
                                }
                                return obj;
                            }
                        ),
                },
            },
        };
        setSession(newSession);
    };

    const handleTotalImagesChange = (data) => {
        setTotalImages(data);
        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === currentPanel?._id)
                                    obj.activity_settings.total_image = data;
                                return obj;
                            }
                        ),
                },
            },
        };
        setSession(newSession);
    };

    const handleTurnBasedChange = (data) => {
        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === currentPanel?._id)
                                    obj.activity_settings.turn_based = data;
                                return obj;
                            }
                        ),
                },
            },
        };
        setSession(newSession);
    };

    const handleTimerChange = (data) => {
        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === currentPanel?._id)
                                    obj.activity_settings.timer = data;
                                return obj;
                            }
                        ),
                },
            },
        };
        setSession(newSession);
    };

    const handleScoreboardChange = (data) => {
        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === currentPanel?._id)
                                    obj.activity_settings.scoreboard = data;
                                return obj;
                            }
                        ),
                },
            },
        };
        setSession(newSession);
    };

    const handleImageTypeChange = (data) => {
        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === currentPanel?._id)
                                    obj.activity_settings.image_type = data;
                                return obj;
                            }
                        ),
                },
            },
        };
        setSession(newSession);
    };

    const handleShare = async (data) => {
        await setSession(data?.newSession);
    };

    const bindSocketEvents = (socket) => {
        socket.on("sessionState", handleSessionState);
        socket.on("sessionDrawData", handleSessionDrawData);
        socket.on("endUserSession", handleEndUserSession);
        socket.on("muteUser", handleMuteUser);
        socket.on("unMuteUser", handleUnMuteUser);
        socket.on("studentLayoutChange", handleStudentLayout);
        socket.on("lessonChange", handleLessonChange);
        socket.on("imageTypesChange", handleImageTypeChange);
        socket.on("totalImagesChange", handleTotalImagesChange);
        socket.on("turnBasedChange", handleTurnBasedChange);
        socket.on("timerChange", handleTimerChange);
        socket.on("scoreboardChange", handleScoreboardChange);
        socket.on("diplayIndividualGraphic", handleGraphic);
        socket.on("playSound", handleSound);
        socket.on("studentConfigChange", onStudentConfigChange);
        socket.on("shareScreen", handleShare);
    };

    useEffect(() => {
        if (soundReceived) {
            var r1 = document.querySelector("audio");
            r1.play();
        }
        // eslint-disable-next-line
    }, [soundReceived]);

    const handleSound = (data) => {
        setSound(data);
        setSoundReceived(true);

        setTimeout(() => {
            setGraphicReceived(false);
        }, 10000);
    };

    const handleGraphic = (data) => {
        setGraphic(data);
        setGraphicReceived(true);
        setTimeout(() => {
            setGraphicReceived(false);
        }, 2000);
    };

    const handleSwitch = (index) => {
        setState(index);
    };

    const handleLeave = () => {
        setState(undefined);
    };

    const handleBtn = () => {
        if (!screenArrangement) {
            setBtn(!btn);
        }
    };

    const updateSession = async (newSession, sharedScreen) => {
        const sessionData = newSession;
        await axios
            .put("/api/v1/sessions", sessionData)
            .then((response) => {
                if (response.data.success) {
                    if (sharedScreen) {
                    } else {
                        handleChangeStudentConfig();
                    }
                } else {
                    setMsg(
                        _.get("response.data.title", "Something went wrong.")
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const handleTeacherView = async (panelId, value) => {
        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === panelId) {
                                    if (currentPanel.type === "VIDEO") {
                                        obj.video_settings.teacher_view = value;
                                    } else if (currentPanel.type === "LESSON") {
                                        if (currentPanel.subType === "ASSET") {
                                            obj.lesson_settings.assets.view.teacher =
                                                value;
                                        } else {
                                            obj.lesson_settings.whiteboard.student.view =
                                                value.slice(0, 4) +
                                                value.slice(5, value.length);
                                            obj.lesson_settings.whiteboard.teacher.view =
                                                value;
                                        }
                                    } else if (
                                        currentPanel.type === "ACTIVITY"
                                    ) {
                                        obj.activity_settings.teacher_view =
                                            value;
                                    }
                                }
                                return obj;
                            }
                        ),
                },
            },
        };
        setSession(newSession);
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        socket.emit("clearDrawData", {
            payload: {
                userIds,
            },
        });
        await updateSession(newSession);
    };

    const handleImageTypes = async (number) => {
        if (number > initialImageTypes) return;

        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === currentPanel?._id)
                                    obj.activity_settings.image_type = number;
                                return obj;
                            }
                        ),
                },
            },
        };
        setSession(newSession);
        await updateSession(newSession);

        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        socket.emit("imageTypesChange", {
            action: "imageTypesChange",
            payload: {
                imageTypes: number,
                userIds,
            },
        });
    };

    const handleTotalImages = async (number) => {
        // let settings = rightPanelSettings;
        // settings[key].activity_settings.total_image = number;
        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === currentPanel?._id)
                                    obj.activity_settings.total_image = number;
                                return obj;
                            }
                        ),
                },
            },
        };
        setSession(newSession);
        // setRightPanelSettings(settings);
        setTotalImages(number);
        await updateSession(newSession);
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        socket.emit("totalImagesChange", {
            action: "totalImagesChange",
            payload: {
                totalImages: number,
                userIds,
            },
        });
    };

    const handleTurnBased = async (value, panelId) => {
        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === panelId) {
                                    obj.activity_settings.turn_based = value;
                                }
                                return obj;
                            }
                        ),
                },
            },
        };
        setRightPanelSettings(newSession.configuration.right_panel_settings);
        await updateSession(newSession);
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        socket.emit("turnBasedChange", {
            action: "turnBasedChange",
            payload: {
                turnBased: value,
                userIds,
            },
        });
    };

    const handleTimer = async (value, panelId) => {
        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === panelId) {
                                    obj.activity_settings.timer = value;
                                }
                                return obj;
                            }
                        ),
                },
            },
        };
        setRightPanelSettings(newSession.configuration.right_panel_settings);
        await updateSession(newSession);
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        socket.emit("timerChange", {
            action: "timerChange",
            payload: {
                timer: value,
                userIds,
            },
        });
    };

    const handleScoreboard = async (value, panelId) => {
        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === panelId) {
                                    obj.activity_settings.scoreboard = value;
                                }
                                return obj;
                            }
                        ),
                },
            },
        };
        setRightPanelSettings(newSession.configuration.right_panel_settings);
        await updateSession(newSession);
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        socket.emit("scoreboardChange", {
            action: "scoreboardChange",
            payload: {
                scoreboard: value,
                userIds,
            },
        });
    };

    const endUserSession = (userIds) => {
        socket.emit("message", {
            action: "endUserSession",
            payload: {
                userIds,
            },
        });
    };

    const endCall = async () => {
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        endUserSession(userIds);
        await axios
            .put(`/api/v1/session/${sessionID}/end`)
            .then((response) => {
                if (!response.data.success) {
                    setMsg(response.data.message);
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
                localStorage.removeItem("teacherState");
                window.location.href = "/";
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const endTeacherCallOnTimerEnd = async () => {
        await axios
            .put(`/api/v1/session/${sessionID}/end`)
            .then((response) => {
                if (!response.data.success) {
                    setMsg(response.data.message);
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                }
                localStorage.removeItem("teacherState");
                window.location.href = "/";
            })
            .catch((error) => {
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
                setTimeout(function () {
                    setMsg(null);
                }, 3000);
                return Promise.reject(error);
            });
    };

    const toggleMuteUser = (shouldMuteUser) => {
        room.localParticipant.audioTracks.forEach(function (audioTrack) {
            if (shouldMuteUser) {
                audioTrack.track.disable();
            } else {
                audioTrack.track.enable();
            }
        });
    };

    useEffect(() => {
        if (room && room.localParticipant) {
            if (publishAudio) {
                toggleMuteUser(false);
            } else {
                toggleMuteUser(true);
            }
        }
        // eslint-disable-next-line
    }, [publishAudio, room]);

    const muteUser = (userIds) => {
        socket.emit("message", {
            action: "muteUser",
            payload: {
                userIds,
            },
        });
        setMutedUsers(_.uniq(mutedUsers.concat(userIds)));
    };

    const unMuteUser = (userIds) => {
        socket.emit("message", {
            action: "unMuteUser",
            payload: {
                userIds,
            },
        });
        const updatedMutedUsers = mutedUsers.filter((userId) => {
            return userIds.indexOf(userId) === -1;
        });
        setMutedUsers(updatedMutedUsers);
    };

    const handleMuteUser = () => {
        setPublishAudio(false);
    };

    const handleUnMuteUser = () => {
        setPublishAudio(true);
    };

    const muteAll = () => {
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        muteUser(userIds);
    };

    const unMuteAll = () => {
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        unMuteUser(userIds);
    };

    const handleStudentView = async (panelId, value) => {
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === panelId) {
                                    if (currentPanel.type === "VIDEO") {
                                        obj.video_settings.student_view = value;
                                    } else if (currentPanel.type === "LESSON") {
                                        if (currentPanel.subType === "ASSET") {
                                            obj.lesson_settings.assets.view.student =
                                                value;
                                        } else {
                                            obj.lesson_settings.whiteboard.student.view =
                                                value;
                                            obj.lesson_settings.whiteboard.teacher.view =
                                                value.slice(0, 4) +
                                                "T" +
                                                value.slice(4, value.length);
                                        }
                                    } else if (
                                        currentPanel.type === "ACTIVITY"
                                    ) {
                                        obj.activity_settings.student_view =
                                            value;
                                    }
                                }
                                return obj;
                            }
                        ),
                },
            },
        };
        setSession(newSession);
        socket.emit("clearDrawData", {
            payload: {
                userIds,
            },
        });
        await updateSession(newSession);

        const newCurrentPanel =
            newSession.configuration.right_panel_settings.find(
                (obj) => obj.isActive
            ) || newSession.configuration.right_panel_settings[0];

        socket.emit("studentLayoutChange", {
            action: "studentLayoutChange",
            payload: {
                userIds,
                newCurrentPanel,
            },
        });
    };

    const handleChangeStudentConfig = () => {
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });

        socket.emit("message", {
            action: "studentConfigChange",
            payload: {
                userIds,
            },
        });
    };

    const onStudentConfigChange = () => {
        setIsConfigChanged(!isConfigChanged);
    };

    const handleWhiteboardStudentView = (layoutIndex) => {
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });

        socket.emit("studentLayoutChange", {
            action: "studentLayoutChange",
            payload: {
                userIds,
                layoutIndex,
                assetType,
                assetSubType,
                key,
            },
        });

        setWhiteboardStudentView(layoutIndex.charAt(layoutIndex.length - 1));
    };

    const handleWhiteboardTeacherView = async (layoutIndex) => {
        setWhiteboardTeacherView(layoutIndex.charAt(layoutIndex.length - 1));
        const newArray = rightPanelSettings;
        newArray[key].lesson_settings.whiteboard.teacher = {
            ...newArray[key].lesson_settings.whiteboard.teacher,
            view: layoutIndex,
        };
        setRightPanelSettings(newArray);
        await updateSession(newArray);
    };

    const handleRightTab = async (activePanelId) => {
        if (shareScreen) {
            setShowScreenSharedModal(true);
        } else {
            const newSession = {
                ...session,
                ...{
                    configuration: {
                        ...session.configuration,
                        right_panel_settings:
                            session.configuration.right_panel_settings.map(
                                (obj, index) => {
                                    obj.isActive = obj._id === activePanelId;
                                    return obj;
                                }
                            ),
                    },
                },
            };
            const userIds = participants.map((participant) => {
                var identityObj = JSON.parse(participant.identity);
                return identityObj._id;
            });

            const newCurrentPanel =
                newSession.configuration.right_panel_settings.find(
                    (obj) => obj.isActive
                );

            setAccessAll(
                !Boolean(
                    newCurrentPanel.lesson_settings.whiteboard?.student_access
                        .length
                )
            );

            socket.emit("lessonChange", {
                action: "lessonChange",
                payload: {
                    userIds,
                    newCurrentPanel,
                },
            });
            if (role === "teacher") {
                if (newCurrentPanel.type === "ACTIVITY") {
                    setWrapperStyle("wrapperWB");
                } else if (
                    newCurrentPanel.type === "VIDEO" &&
                    newCurrentPanel.subType === "SCREENSHARE"
                ) {
                    setWrapperStyle("wrapperScreenShare");
                } else {
                    setWrapperStyle("wrapper");
                }
            }
            setSession(newSession);
            await updateSession(newSession);
        }
    };

    const role = JSON.parse(localStorage.getItem("auth")).user.role;
    var currentPanel = undefined;
    var CurrentLayout = undefined;

    if (session) {
        currentPanel =
            session.configuration.right_panel_settings.find(
                (obj) => obj.isActive
            ) || session.configuration.right_panel_settings[0];

        if (role.toLowerCase() === "teacher") {
            if (
                currentPanel.type === "VIDEO" &&
                currentPanel?.subType !== "SCREENSHARE"
            ) {
                const viewToLayoutMap = {
                    viewT1: TeacherLayout1,
                    viewT2: TeacherLayout2,
                    viewT3: TeacherLayout3,
                    viewT4: TeacherLayout4,
                };
                CurrentLayout =
                    viewToLayoutMap[currentPanel.video_settings.teacher_view];
            } else if (
                currentPanel.type === "VIDEO" &&
                currentPanel?.subType === "SCREENSHARE"
            ) {
                const viewToLayoutMap = {
                    viewT1: TeacherScreen,
                };
                CurrentLayout =
                    viewToLayoutMap[currentPanel.video_settings.teacher_view];
            }

            if (currentPanel.type === "LESSON") {
                if (currentPanel.subType === "ASSET") {
                    const viewToLayoutMap = {
                        viewT1: LessonTeacherLayout1,
                        viewT2: LessonTeacherLayout2,
                        viewT3: LessonTeacherLayout3,
                        viewT4: LessonTeacherLayout4,
                    };
                    CurrentLayout =
                        viewToLayoutMap[
                            currentPanel.lesson_settings.assets.view.teacher
                        ];
                } else {
                    if (
                        currentPanel.lesson_settings.whiteboard?.student
                            .required_whiteboard
                    ) {
                        const viewToLayoutMap = {
                            viewT1: WhiteboardReqTeacherLayout1,
                            viewT2: WhiteboardReqTeacherLayout2,
                            viewT3: WhiteboardReqTeacherLayout3,
                        };

                        CurrentLayout =
                            viewToLayoutMap[
                                currentPanel.lesson_settings.whiteboard.teacher
                                    .view
                            ];
                    } else {
                        const viewToLayoutMap = {
                            viewT1: WhiteboardTeacherLayout1,
                            viewT2: WhiteboardTeacherLayout2,
                            viewT3: WhiteboardTeacherLayout3,
                        };

                        CurrentLayout =
                            viewToLayoutMap[
                                currentPanel.lesson_settings.whiteboard.teacher
                                    .view
                            ];
                    }
                }
            }

            if (currentPanel.type === "ACTIVITY") {
                const viewToLayoutMap = {
                    BUBBLE_POP: {
                        viewT1: Game1Teacher,
                        viewT2: Game1Teacher,
                        viewT3: Game1Teacher,
                        viewT4: Game1Teacher,
                    },
                    BUILD_YOUR_DREAM: {
                        viewT1: Game2Teacher,
                        viewT2: Game2Teacher,
                        viewT3: Game2Teacher,
                        viewT4: Game2Teacher,
                    },
                };
                CurrentLayout =
                    viewToLayoutMap[currentPanel.subType][
                        currentPanel.activity_settings.teacher_view
                    ];
            }
        } else if (role.toLowerCase() === "student") {
            if (currentPanel.type === "VIDEO") {
                const viewToLayoutMap = {
                    view1: StudentLayout1,
                    view2: StudentLayout2,
                    view3: StudentLayout3,
                    view4: StudentLayout4,
                };
                CurrentLayout = session.configuration?.screen_shared
                    ? StudentScreen
                    : viewToLayoutMap[currentPanel.video_settings.student_view];
            }

            if (currentPanel.type === "LESSON") {
                if (currentPanel.subType === "ASSET") {
                    const viewToLayoutMap = {
                        view1: LessonStudentLayout1,
                        view2: LessonStudentLayout2,
                        view3: LessonStudentLayout3,
                        view4: LessonStudentLayout4,
                    };
                    if (currentPanel.lesson_settings.assets.view?.student) {
                        CurrentLayout = session.configuration?.screen_shared
                            ? StudentScreen
                            : viewToLayoutMap[
                                  currentPanel.lesson_settings.assets.view
                                      .student
                              ];
                    }
                } else {
                    if (
                        currentPanel.lesson_settings.whiteboard?.student
                            ?.required_whiteboard
                    ) {
                        const viewToLayoutMap = {
                            view1: WhiteboardReqStudentLayout1,
                            view2: WhiteboardReqStudentLayout2,
                            view3: WhiteboardReqStudentLayout3,
                            view4: WhiteboardReqStudentLayout4,
                        };

                        if (
                            currentPanel.lesson_settings.whiteboard?.student
                                ?.view
                        ) {
                            CurrentLayout = session.configuration?.screen_shared
                                ? StudentScreen
                                : viewToLayoutMap[
                                      currentPanel.lesson_settings.whiteboard
                                          .student.view
                                  ];
                        }
                    } else {
                        const viewToLayoutMap = {
                            view1: WhiteboardStudentLayout1,
                            view2: WhiteboardStudentLayout2,
                            view3: WhiteboardStudentLayout3,
                            view4: WhiteboardStudentLayout4,
                        };

                        if (
                            currentPanel.lesson_settings.whiteboard?.student
                                ?.view
                        ) {
                            CurrentLayout = session.configuration?.screen_shared
                                ? StudentScreen
                                : viewToLayoutMap[
                                      currentPanel.lesson_settings.whiteboard
                                          .student.view
                                  ];
                        }
                    }
                }
            }

            if (currentPanel.type === "ACTIVITY") {
                const viewToLayoutMap = {
                    BUBBLE_POP: {
                        view1: Game1Student,
                        view2: Game1Student,
                        view3: Game1Student,
                        view4: Game1Student,
                    },
                    BUILD_YOUR_DREAM: {
                        view1: Game2Student,
                        view2: Game2Student,
                        view3: Game2Student,
                        view4: Game2Student,
                    },
                };
                CurrentLayout = session.configuration?.screen_shared
                    ? StudentScreen
                    : viewToLayoutMap[currentPanel.subType][
                          currentPanel.activity_settings.student_view
                      ];
            }
        }
    }

    const wrapperStyleMap = {
        wrapper: styles.wrapper,
        wrapperWB: styles.wrapperWB,
        wrapperScreenShare: styles.wrapperScreenShare,
    };
    let wrapperStyleClass = wrapperStyleMap[wrapperStyle];

    return (
        <div
            className={`${styles.parentWrapper} ${
                assetType === "ACTIVITY" && role === "student"
                    ? styles.cursor
                    : ""
            }`}
        >
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            {/* <div className={styles.logoRight}>
                <img
                    src={Logo}
                    style={{ fontFamily: "ComfortaBold" }}
                    alt="plass Begin"
                />
            </div> */}
            <div className={styles.outerBlock}>
                <div className={wrapperStyleClass}>
                    {
                        <div className={styles.logo}>
                            <img
                                src={Watermark}
                                style={{ fontFamily: "ComfortaBold" }}
                                alt="plass Begin"
                            />
                        </div>
                    }
                    {/* {!currentPanel?.lesson_settings?.whiteboard && (
                        <div className={styles.clock}>
                            <Clock />
                        </div>
                    )} */}
                    {role === "teacher" &&
                        session?.configuration?.session_end_time && (
                            <div className={styles.timer}>
                                <CountDown
                                    endTime={
                                        session?.configuration?.session_end_time
                                    }
                                    endClass={() => endCall()}
                                    setShowTimeoutWarningModal={() =>
                                        setShowTimeoutWarningModal(true)
                                    }
                                    showTimeoutWarningModal={
                                        showTimeoutWarningModal
                                    }
                                />
                            </div>
                        )}
                    {graphicReceived && (
                        <div
                            style={{
                                position: "fixed",
                                left: 0,
                                top: 0,
                                right: 0,
                                bottom: 0,
                                display: "flex",
                                alignItems: "center",
                                zIndex: 9,
                                justifyContent: "center",
                            }}
                        >
                            <img src={graphic} alt="grpahic" />
                        </div>
                    )}

                    {soundReceived && (
                        <audio id="myAudio">
                            <source src={sound} type="audio/mpeg" />
                        </audio>
                    )}
                    <>
                        {twilio && room && CurrentLayout && (
                            <>
                                <CurrentLayout
                                    key={currentPanel._id}
                                    isIndividual={isIndividual}
                                    handleImageTypes={handleImageTypes}
                                    handleTotalImages={handleTotalImages}
                                    handleTurnBased={handleTurnBased}
                                    handleTimer={handleTimer}
                                    handleScoreboard={handleScoreboard}
                                    mutedUsers={mutedUsers}
                                    room={room}
                                    participants={participants}
                                    publishAudio={publishAudio}
                                    endUserSession={(id) => {
                                        endUserSession([id]);
                                    }}
                                    muteUser={(id) => muteUser([id])}
                                    unMuteUser={(id) => unMuteUser([id])}
                                    drawData={drawData}
                                    socket={socket}
                                    sessionID={session._id}
                                    totalImages={totalImages}
                                    status={game1Status}
                                    // selectedTeam={selectedTeam}
                                    // teams={teams}
                                    currentPanel={currentPanel}
                                    role={role}
                                    session={session}
                                    updateSession={updateSession}
                                    setSession={setSession}
                                    setWrapperStyle={setWrapperStyle}
                                    identifier={identifier}
                                />
                            </>
                        )}
                    </>
                </div>

                {role === "teacher" &&
                    session?.configuration?.right_panel_settings && (
                        <div className={styles.switchContainer}>
                            {session.configuration.right_panel_settings.map(
                                (item, index) => {
                                    return item.isConfigurationCompleted ? (
                                        <div
                                            className={styles.switchWrapper}
                                            key={index}
                                        >
                                            {item.type && (
                                                <div
                                                    onMouseEnter={() => {
                                                        handleSwitch(index);
                                                    }}
                                                    onMouseLeave={() => {
                                                        handleLeave();
                                                    }}
                                                    className={styles.switch}
                                                    style={{
                                                        backgroundColor:
                                                            currentPanel?._id ===
                                                            item._id
                                                                ? "#fdd7c6"
                                                                : "#C8E8E5",
                                                        cursor:
                                                            currentPanel?._id ===
                                                            item._id
                                                                ? "initial"
                                                                : "pointer",
                                                    }}
                                                >
                                                    {state === index ? (
                                                        <p
                                                            onClick={() => {
                                                                handleRightTab(
                                                                    item._id
                                                                );
                                                            }}
                                                        >
                                                            {item.label
                                                                ? item.label
                                                                : item.type}
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            {item.label
                                                                ? item.label.charAt(
                                                                      0
                                                                  )
                                                                : item.type.charAt(
                                                                      0
                                                                  )}
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ) : null;
                                }
                            )}
                        </div>
                    )}
            </div>
            {role === "teacher" && (
                <>
                    <TimeoutWarningModal
                        showTimeoutWarningModal={showTimeoutWarningModal}
                        setShowTimeoutWarningModal={setShowTimeoutWarningModal}
                    />
                    <ControlsBar
                        handleBtn={handleBtn}
                        btn={btn}
                        session={session}
                        endCall={endCall}
                        mute={mute}
                        setMute={setMute}
                        muteAll={muteAll}
                        handleStudentView={(newView) => {
                            handleStudentView(currentPanel?._id, newView);
                        }}
                        participants={participants}
                        socket={socket}
                        unMuteAll={unMuteAll}
                        screenArrangement={screenArrangement}
                        studentView={currentPanel?.video_settings?.student_view}
                        teacherView={currentPanel?.video_settings?.teacher_view}
                        handleTeacherView={(newView) => {
                            handleTeacherView(currentPanel?._id, newView);
                        }}
                        setScreenArrangement={setScreenArrangement}
                        handleWhiteboardStudentView={
                            handleWhiteboardStudentView
                        }
                        handleWhiteboardTeacherView={
                            handleWhiteboardTeacherView
                        }
                        whiteboardStudentView={whiteboardStudentView}
                        whiteboardTeacherView={whiteboardTeacherView}
                        shareScreenHandler={shareScreenHandler}
                        currentPanel={currentPanel}
                        twilio={twilio}
                    />
                </>
            )}

            <>
                {role === "teacher" &&
                    currentPanel?.subType === "WHITEBOARD" && (
                        <Modal
                            participants={participants}
                            currentPanel={currentPanel}
                            session={session}
                            handleChangeStudentConfig={
                                handleChangeStudentConfig
                            }
                            accessAll={accessAll}
                            setAccessAll={setAccessAll}
                            updateSession={updateSession}
                            socket={socket}
                        />
                    )}
            </>
            <>
                {role === "teacher" && showScreenSharedModal && (
                    <StopSharingModal
                        handleShowWarningModal={shareScreenHandler}
                        showWarningModal={false}
                        stopSharing={shareScreenHandler}
                        continueShare={() => setShowScreenSharedModal(false)}
                        // continueShare={() => handleStopSharing()}
                    />
                )}
            </>
        </div>
    );
};
