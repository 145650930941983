import React from "react";
import styles from "./index.module.scss";

const StopSharingModal = ({ stopSharing, continueShare }) => {
    return (
        <div className={styles.warningModalOuterContainer}>
            <div className={styles.warningModalContainer}>
                <div className={styles.contentContainer}>
                    <div className={styles.contentLine}>
                        You are sharing your screen.
                    </div>
                    <div className={styles.contentLine}>
                        Stop sharing before refresh or switching the block.
                    </div>
                </div>

                <div className={styles.actionButtonsContainer}>
                    {/* <div
                        className={styles.actionButton}
                        onClick={() =>
                            stopSharing()
                        }
                    >
                        Stop Sharing
                    </div> */}
                    <div
                        className={`${styles.actionButton} ${styles.primaryActionButton}`}
                        onClick={() => continueShare()
                        }
                    >
                        Continue
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StopSharingModal;
