import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { dateFormat } from "../../../utils/commonFunction";
import ChatForm from "../../../components/ChatForm";
import styles from "./index.module.scss";

const ClassInfoBox = ({
    studentDetails,
    sessionName,
    teacherName,
    sessionStartTime,
    sessionDuration,
}) => {
    const history = useHistory();

    const nameOfSession =
        studentDetails?.session?.class_name || sessionName || "-";
    const nameOfTeacher = studentDetails?.session?.teacher_id?.preferedName
        ? studentDetails?.session?.teacher_id?.preferedName || "-"
        : studentDetails?.session?.teacher_id?.name || teacherName || "-";
    const startTime =
        studentDetails?.session?.configuration?.session_start_time ||
        sessionStartTime;
    const duration =
        studentDetails?.session?.configuration?.session_duration ||
        sessionDuration ||
        "-";

    return (
        studentDetails && (
            <div className={styles.firstContainer}>
                <div className={styles.classInfoBox}>
                    <h4 className={styles.dateHeadText}>
                        {dateFormat(startTime, "DD MMM")}
                    </h4>
                    {studentDetails ? (
                        <ChatForm
                            classDetails={studentDetails?.session}
                            classes={styles.chattingIcon}
                        />
                    ) : null}
                </div>
                <div className={styles.payContainerBox}>
                    <p className={styles.classNameText}>{nameOfSession}</p>
                    <p className={styles.teacherName}>
                        <span
                            className={styles.teacherNameSpan}
                            onClick={() => {
                                if (studentDetails?.session?.teacher_id?._id)
                                    history.push(
                                        `/teacher-details/${studentDetails?.session?.teacher_id?.username}`
                                    );
                            }}
                        >
                            {`Teacher ${nameOfTeacher}`}
                        </span>
                    </p>
                    <p className={styles.teacherName}>
                        {dateFormat(startTime, "h:mm A")}
                        {` ${moment.tz(moment.tz.guess()).zoneAbbr()}`}
                        {` (${duration} mins)`}
                    </p>
                </div>
            </div>
        )
    );
};

export default ClassInfoBox;
