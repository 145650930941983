import {
    faArrowAltCircleDown,
    faFile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import FileDownload from "js-file-download";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import Snackbar from "../../../../components/Snackbar";
import { ImageFormats, VideoFormats } from "../../utils/AcceptedFileFormats";
import styles from "./ChatList.module.scss";
import MessageImageModal from "./MessageImageModal";
import NewMessageComponent from "./NewMessageComponent";

const UserProfilePhoto = ({ chat }) => {
    const profilePictureIds = _.get(chat, "sent_by.profilePictureIds");
    const photo = _.find(profilePictureIds, ["type", "IMAGE"]);
    let recipientURL;

    if (photo) {
        recipientURL = _.get(photo, "location");
    }

    return (
        <div className={styles.recipientProfilePhotoContainer}>
            {recipientURL ? (
                <img
                    src={recipientURL}
                    alt=""
                    className={styles.recipientProfilePhoto}
                />
            ) : (
                <span
                    className={`${styles.recipientProfilePhoto} ${
                        _.get(chat, "sent_by.role") === "student"
                            ? styles.studentProfilePhoto
                            : ""
                    }`}
                >
                    {_.get(chat, "sent_by.name", "U").trim()[0].toUpperCase()}
                </span>
            )}
        </div>
    );
};

const FormatTime = ({ time }) => {
    return moment(time).format("DD MMM hh:mm a");
};

const FormatFullTime = ({ time }) => {
    return `${moment(time).format("MMM D (ddd) h:mm A")} ${moment
        .tz(moment.tz.guess())
        .zoneAbbr()}`;
};

const ImageGridComponent = ({ files, handleDownloadFile }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filesToShow, setFilesToShow] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showAllFiles, setShowAllFiles] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState();

    useEffect(() => {
        let filesArray;

        filesArray = showAllFiles ? [...files] : files.slice(0, 4);

        setFilesToShow(filesArray);
        // eslint-disable-next-line
    }, [showAllFiles, files]);

    const handleToggleShowAllFiles = () => {
        setShowAllFiles((prevState) => {
            return !prevState;
        });
    };

    return files ? (
        <Fragment>
            <MessageImageModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                files={files}
            />

            <div className={styles.ImageGridComponent}>
                {filesToShow.map((file, index) => {
                    const fileFormat = _.last(
                        _.split(_.get(file, "location", ""), ".")
                    ).toUpperCase();

                    return [...ImageFormats, ...VideoFormats].includes(
                        fileFormat
                    ) ? (
                        <div
                            key={_.get(file, "_id")}
                            className={styles.gridContainer}
                        >
                            {[...ImageFormats].includes(fileFormat) ? (
                                <img
                                    src={_.get(file, "location")}
                                    alt="Selected Item"
                                    onClick={() => {
                                        setCurrentIndex(index);
                                        setIsModalOpen(true);
                                    }}
                                />
                            ) : (
                                <video
                                    src={_.get(file, "location")}
                                    alt="Selected Item"
                                    onClick={() => {
                                        setCurrentIndex(index);
                                        setIsModalOpen(true);
                                    }}
                                />
                            )}

                            <span
                                className={styles.downloadIconContainer}
                                onClick={() =>
                                    downloadLoading === _.get(file, "_id")
                                        ? null
                                        : handleDownloadFile({
                                              fileId: _.get(file, "_id"),
                                              fileName: _.get(
                                                  file,
                                                  "original_name",
                                                  "Name of file"
                                              ),
                                              setDownloadLoading:
                                                  setDownloadLoading,
                                          })
                                }
                            >
                                {downloadLoading === _.get(file, "_id") ? (
                                    <CircularProgress
                                        className={styles.downloadIcon}
                                        size={30}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faArrowAltCircleDown}
                                        className={styles.downloadIcon}
                                    />
                                )}
                            </span>
                        </div>
                    ) : null;
                })}
            </div>

            {files.length > 4 ? (
                <div
                    onClick={handleToggleShowAllFiles}
                    className={styles.toggleText}
                >
                    {showAllFiles ? "See less..." : "See more..."}
                </div>
            ) : null}
        </Fragment>
    ) : null;
};

const DocumentFileComponent = ({ files, handleDownloadFile }) => {
    const [downloadLoading, setDownloadLoading] = useState();

    return files.map((file, index) => {
        return (
            <Fragment key={index}>
                <div
                    className={styles.docFileContainer}
                    onClick={() =>
                        downloadLoading === _.get(file, "_id")
                            ? null
                            : handleDownloadFile({
                                  fileId: _.get(file, "_id"),
                                  fileName: _.get(
                                      file,
                                      "original_name",
                                      "Name of file"
                                  ),
                                  setDownloadLoading: setDownloadLoading,
                              })
                    }
                >
                    <span className={styles.fileIconContainer}>
                        <FontAwesomeIcon
                            icon={faFile}
                            className={styles.fileIcon}
                        />
                    </span>

                    <span className={styles.fileName}>
                        {_.get(file, "original_name", "Name of file")}
                    </span>

                    <span className={styles.downloadIconContainer}>
                        {downloadLoading === _.get(file, "_id") ? (
                            <CircularProgress
                                className={styles.downloadIcon}
                                size={30}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faArrowAltCircleDown}
                                className={styles.downloadIcon}
                            />
                        )}
                    </span>
                </div>
            </Fragment>
        );
    });
};

const FileChatComponent = ({ chat }) => {
    const imagesArray = [];
    const otherFilesArray = [];

    const [snackBarStatus, setSnackBarStatus] = useState("");
    const [msg, setMsg] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);

    for (let file in chat.file_ids) {
        const currentFile = chat.file_ids[file];
        const fileFormat = _.last(
            _.split(_.get(currentFile, "location", ""), ".")
        ).toUpperCase();

        if ([...ImageFormats, ...VideoFormats].includes(fileFormat)) {
            imagesArray.push(currentFile);
        } else {
            otherFilesArray.push(currentFile);
        }
    }

    const handleDownloadFile = ({ fileId, fileName, setDownloadLoading }) => {
        let fileNameArray = fileName.split(".");
        fileNameArray[fileNameArray.length - 2] =
            fileNameArray[fileNameArray.length - 2] +
            "-" +
            new Date().getTime();
        fileNameArray = fileNameArray.join(".");
        setDownloadLoading(fileId);

        axios
            .get("/api/v1/chat/files/" + fileId + "/download", {
                responseType: "blob",
            })
            .then((response) => {
                // if (_.get(response, "data.success")) {
                FileDownload(response.data, fileNameArray);
                // } else {
                //     setSnackBarStatus("error");
                //     setMsg(
                //         _.get(response, "data.title", "Something went wrong.")
                //     );
                //     setShowSnackbar(true);
                // }
            })
            .catch((error) => {
                console.error(error.response);
                setSnackBarStatus("error");
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
            })
            .finally(() => {
                setDownloadLoading();
            });
    };

    return (
        <Fragment>
            <Snackbar
                status={snackBarStatus}
                message={msg}
                open={showSnackbar}
                setOpen={setShowSnackbar}
            />

            <ImageGridComponent
                files={imagesArray}
                handleDownloadFile={handleDownloadFile}
            />

            <DocumentFileComponent
                files={otherFilesArray}
                handleDownloadFile={handleDownloadFile}
            />
        </Fragment>
    );
};

const ChatListComponent = ({ chats, firstUnreadMessage }) => {
    let userData = localStorage.getItem("auth");
    if (userData) userData = JSON.parse(userData);

    return chats.map((chat, index) => {
        return (
            <Fragment key={index}>
                {_.get(firstUnreadMessage, "_id") === chat._id &&
                _.get(firstUnreadMessage, "sent_by._id") !==
                    _.get(userData, "user._id") ? (
                    <div className={styles.newChatLabel}>
                        <span>New</span>
                    </div>
                ) : null}

                <div className={styles.chatContainer}>
                    <div
                        className={
                            _.get(chat, "sent_by._id") ===
                            _.get(userData, "user._id")
                                ? styles.chatFromUser
                                : ""
                        }
                    />

                    <div
                        className={`${styles.chatMessageOuterContainer} ${
                            _.get(chat, "sent_by._id") ===
                            _.get(userData, "user._id")
                                ? styles.chatMessageOuterContainerFromUser
                                : ""
                        }`}
                    >
                        <div className={styles.chatMessageTimeContainer}>
                            <FormatTime time={_.get(chat, "sent_time")} />
                        </div>

                        <div className={styles.chatMessageContainer}>
                            <UserProfilePhoto chat={chat} />

                            <div className={styles.chatMessage}>
                                {_.get(chat, "text")}

                                <FileChatComponent chat={chat} />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    });
};

const ChatList = ({ chat, user, index, isParticipantRemoved }) => {
    let userData = localStorage.getItem("auth");
    if (userData) userData = JSON.parse(userData);

    const [chats, setChats] = useState([]);
    const [chatsToDisplay, setChatsToDisplay] = useState([]);
    const [showAllChatsFlag, setShowAllChatsFlag] = useState(true);
    const [chatsCount, setChatsCount] = useState(
        _.get(user, "NoOfUnreadMessages") > 10
            ? _.get(user, "NoOfUnreadMessages") + 1
            : 10
    );
    const [firstUnreadMessage, setFirstUnreadMessage] = useState();
    const [loadingAPI, setLoadingAPI] = useState(false);

    const [snackBarStatus, setSnackBarStatus] = useState("");
    const [msg, setMsg] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);

    /**
     * @description Extract first <chatsCount> chats from the chat list.
     */
    useEffect(() => {
        const slicedArray = chats.slice(Math.max(chats.length - chatsCount, 0));
        setChatsToDisplay(slicedArray);
        // eslint-disable-next-line
    }, [chats, chatsCount]);

    /**
     * @description Fetch chat list from response.
     */
    useEffect(() => {
        const allChats = _.get(chat, "chats", []).reverse();
        const firstUnreadChat = _.find(allChats, function (chat) {
            return (
                !chat.read_status &&
                _.get(chat, "sent_by._id") !== _.get(userData, "user._id")
            );
        });

        setChats(allChats);
        setFirstUnreadMessage(firstUnreadChat);
        // eslint-disable-next-line
    }, [chat]);

    // eslint-disable-next-line
    const handleReadMessage = (chat) => {
        const body = {
            messagesFrom: _.get(user, "_id"),
        };

        axios
            .put("/api/v1/chat/read-message", body)
            .then((response) => {
                if (!_.get(response, "data.success")) {
                    setSnackBarStatus("error");
                    setMsg(
                        _.get(response, "data.title", "Something went wrong.")
                    );
                    setShowSnackbar(true);
                }
            })
            .catch((error) => {
                console.error(error.response);
                setSnackBarStatus("error");
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
            });
    };

    /**
     * @description Fetch data from form data and send.
     */
    const sendMessage = (formData) => {
        setLoadingAPI(true);

        formData.append("session_id", _.get(chat, "session_id"));
        if (Boolean(_.get(chat, "session_history_id")))
            formData.append(
                "session_history_id",
                _.get(chat, "session_history_id")
            );
        // formData.append("session_id", _.get(chat, "session._id"));
        // formData.append("sent_to", _.get(user, "_id"));
        formData.append("room_id", _.get(chat, "room_id"));
        formData.append("chat_type", _.get(chat, "chat_type"));

        axios
            .post("/api/v1/chat/save-message", formData)
            .then((response) => {
                if (
                    _.get(response, "data.success") &&
                    _.get(response, "data.data")
                ) {
                    setChats((prevChats) => {
                        return [...prevChats, _.get(response, "data.data")];
                    });

                    // handleReadMessage(chat);
                } else {
                    setSnackBarStatus("error");
                    setMsg(
                        _.get(response, "data.title", "Something went wrong.")
                    );
                    setShowSnackbar(true);
                }
            })
            .catch((error) => {
                console.error(error.response);
                setSnackBarStatus("error");
                setMsg(
                    _.get(error, "response.data.title", "Something went wrong.")
                );
                setShowSnackbar(true);
            })
            .finally(() => {
                setLoadingAPI(false);
            });
    };

    /**
     * @description Increase <chatsCount> by 10.
     */
    const incrementChatsCount = () => {
        setChatsCount((prevChatsCount) => {
            return prevChatsCount + 10 > chats.length
                ? chats.length
                : prevChatsCount + 10;
        });
    };

    /**
     * @description Toggle the flag to show or hide the chat of particular lesson.
     */
    const toggleShowAllChats = () => {
        setShowAllChatsFlag((flag) => {
            return !flag;
        });
    };

    return (
        <Fragment>
            <Snackbar
                status={snackBarStatus}
                message={msg}
                open={showSnackbar}
                setOpen={setShowSnackbar}
            />

            <div className={styles.chatListContainer}>
                <div
                    className={styles.chatListTitle}
                    onClick={toggleShowAllChats}
                >
                    <span className={styles.classListHeader}>
                        <span>Message about</span>

                        <span
                            onClick={(e) => {
                                e.stopPropagation();
                                const session_type = _.get(
                                    chat,
                                    "session.session_type"
                                );

                                if (session_type === "PUBLISHED") {
                                    window.open(
                                        `${
                                            process.env.REACT_APP_FRONTEND_URL
                                        }/class-details/${_.get(
                                            chat,
                                            "session.class_name_slug"
                                        )}`,
                                        "_blank"
                                    );
                                }
                            }}
                            className={`${styles.classListTitleName} ${
                                _.get(chat, "session.session_type") ===
                                "PUBLISHED"
                                    ? styles.classListTitleNameActive
                                    : ""
                            }`}
                        >
                            {_.get(chat, "session.class_name")}
                        </span>
                    </span>

                    <span>
                        At{" "}
                        <FormatFullTime
                            time={_.get(chat, "session.session_start_time")}
                        />
                    </span>
                </div>

                {showAllChatsFlag ? (
                    <Fragment>
                        {chatsCount < chats.length ? (
                            <div
                                onClick={incrementChatsCount}
                                className={styles.showMoreLabel}
                            >
                                <span>Show More</span>
                            </div>
                        ) : null}

                        <ChatListComponent
                            chats={chatsToDisplay}
                            firstUnreadMessage={firstUnreadMessage}
                        />

                        <NewMessageComponent
                            sendMessage={sendMessage}
                            loadingAPI={loadingAPI}
                            index={index}
                            isParticipantRemoved={isParticipantRemoved}
                        />
                    </Fragment>
                ) : null}
            </div>
        </Fragment>
    );
};

export default ChatList;
