import axios from "axios";
// import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import FooterNew from "../../../components/FooterNew";
import Navbar from "../../../components/Navbar";
import Carousel from "../components/Carousel";
import Pagination from "../components/Pagination";
import ClassesList from "./ClassesList";
import styles from "./index.module.scss";
import BreadCrumbs from "../../../components/BreadCrumbs";
import PageLoader from "../../../components/PageLoader";

const Classes = ({ listToDisplay, title, setMsg, setShowSnackbar }) => {
    // const role = JSON.parse(localStorage.getItem("auth"))?.user?.role;
    // List of classes to display.
    const [recommendedClasses, setRecommendedClasses] = useState({
        classList: [],
        classToDisplay: [],
    });

    const [paginationInfo, setPaginationInfo] = useState();

    // Page  number to fetch data.
    const [pageNumber, setPageNumber] = useState(1);
    const [isSearchedFlag, setIsSearchedFlag] = useState(false);
    const [searchedTerm, setSearchedTerm] = useState();
    const [isFilteredFlag, setIsFilteredFlag] = useState(false);
    const [filterKey, setFilterKey] = useState();
    const [sortKey, setSortKey] = useState();
    const [loading, setLoading] = useState(false);
    const [mobileView, setMobileView] = useState(window.innerWidth <= 598);

    useEffect(() => {
        const handleWindowResize = () => {
            window.innerWidth < 598
                ? setMobileView(true)
                : setMobileView(false);
        };

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, [mobileView]);

    useEffect(() => {
        fetchFilteredClasses();
        // eslint-disable-next-line
    }, [searchedTerm, filterKey, sortKey, pageNumber]);

    const fetchFilteredClasses = () => {
        let url = "";

        url = url + "&sortDirection=ASC";
        if (searchedTerm) {
            url = "&search=" + searchedTerm;
            setIsSearchedFlag(true);
            setPageNumber(1);
        }
        if (filterKey) {
            setIsFilteredFlag(true);
            if (filterKey.age.length) {
                url = url + "&age=";
                filterKey.age.forEach((age) => (url = url + age + ","));
                url = url.slice(0, -1);
            }

            if (filterKey.classTopic.length) {
                url = url + "&learningObjectives=";
                filterKey.classTopic.forEach(
                    (topic) => (url = url + topic + ",")
                );
                url = url.slice(0, -1);
            }

            if (filterKey.classSize.length) {
                url = url + "&classSize=";
                filterKey.classSize.forEach((size) => (url = url + size + ","));
                url = url.slice(0, -1);
            }

            if (filterKey.time.length) {
                url = url + "&time=";
                filterKey.time.forEach((time) => (url = url + time + ","));
                url = url.slice(0, -1);
            }

            if (filterKey.free) {
                url = url + "&freeClass=" + filterKey.free;
            }

            setPageNumber(1);
        }
        if (sortKey) {
            url = url + "&sortField=" + sortKey;
            setPageNumber(1);
        }

        let currentClasses = recommendedClasses;
        currentClasses = {
            classList: [],
            classToDisplay: [],
        };

        fetchClasses(url, currentClasses);
    };

    // Function to get recommended classes list.
    const fetchClasses = (updatedUrl, updatedCurrentClass) => {
        let currentClasses = updatedCurrentClass;
        if (!currentClasses) {
            currentClasses = recommendedClasses;
        }

        let url = "?perPage=8&page=" + pageNumber;
        url = "/api/v1/home/classes" + url;

        // if (title === "Learn, play and interact with various fun enrichment topics") {
        //     url = "/api/v1/home/classes" + url;
        // } else if (role === "student") {
        //     url = "/api/v1/home/studentRecommendedClasses" + url;
        // } else {
        //     url = "/api/v1/home/classes" + url;
        // }

        if (updatedUrl) {
            url = url + updatedUrl;
        } else {
            url = url;
        }
        setLoading(true);
        axios
            .get(url)
            .then((res) => {
                const responseClasses = res.data;
                const classesRes = [
                    ...currentClasses.classList,
                    ...responseClasses.data.sessions,
                ];
                const classesToDisplay = classesRes.slice(
                    0,
                    window.screen.width < 415 ? 4 : 12
                );
                const pagination = responseClasses.data.pagination;
                setRecommendedClasses({
                    classList: classesRes,
                    classToDisplay: classesToDisplay,
                });
                setPaginationInfo(pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                // setMsg(
                //     _.get(err, "response.data.title", "Something went wrong.")
                // );
                // setShowSnackbar(true);
                // setTimeout(function () {
                //     setMsg(null);
                // }, 3000);
            });
    };

    return (
        <Fragment>
            <Navbar />
            {loading && <PageLoader open={loading} />}
            {listToDisplay ? (
                <div className={styles.mainContainer}>
                    <div className={styles.pageContainer}>
                        <div className={styles.contentContainer}>
                            <div className={styles.carouselContainer}>
                                <Carousel />
                                <BreadCrumbs
                                    links={[
                                        { to: "/home", label: "Home" },
                                        {
                                            to: "/classes",
                                            label: "Upcoming Sesions",
                                        },
                                    ]}
                                    class={styles.breadCrumb}
                                />
                            </div>

                            <ClassesList
                                recommendedClasses={recommendedClasses}
                                title={title}
                                listToDisplay={listToDisplay}
                                setFilterKey={setFilterKey}
                                isSearchedFlag={isSearchedFlag}
                                isFilteredFlag={isFilteredFlag}
                                searchedTerm={searchedTerm}
                                setSearchedTerm={setSearchedTerm}
                                setSortKey={setSortKey}
                            />
                        </div>

                        {paginationInfo ? (
                            <Pagination
                                paginationInfo={paginationInfo}
                                setPageNumber={setPageNumber}
                            />
                        ) : null}

                        <FooterNew />
                    </div>
                </div>
            ) : (
                <>
                    <ClassesList
                        recommendedClasses={recommendedClasses}
                        title={title}
                        setFilterKey={setFilterKey}
                        isSearchedFlag={isSearchedFlag}
                        isFilteredFlag={isFilteredFlag}
                        searchedTerm={searchedTerm}
                        setSearchedTerm={setSearchedTerm}
                        setSortKey={setSortKey}
                        mobileView={mobileView}
                    />

                    {!!mobileView ? (
                        <p
                            className={styles.seeMore}
                            onClick={() => (window.location.href = "/classes")}
                        >
                            see more...
                        </p>
                    ) : paginationInfo ? (
                        <Pagination
                            paginationInfo={paginationInfo}
                            setPageNumber={setPageNumber}
                        />
                    ) : null}
                </>
            )}
        </Fragment>
    );
};

export default Classes;
