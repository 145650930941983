import axios from "axios";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import Calender from "../../../assets/images/PlayPage/Calender.svg";
import Click from "../../../assets/images/PlayPage/Click.svg";
import Home from "../../../assets/images/PlayPage/Home.svg";
import MoneyBag from "../../../assets/images/PlayPage/MoneyBag.svg";
import RegisterBtn from "../../../assets/images/PlayPage/RegisterBtn.svg";
import Settings from "../../../assets/images/PlayPage/Settings.svg";
import SignUpBtn from "../../../assets/images/PlayPage/SignUpBtn.svg";
import Teacher from "../../../assets/images/PlayPage/Teacher.svg";
import FooterNew from "../../../components/FooterNew";
import Navbar from "../../../components/Navbar";
import Snackbar from "../../../components/Snackbar";
import styles from "./index.module.scss";

const validateEmail = (emailAddress) => {
    let input = emailAddress;

    let isValid = true;
    if (!input) {
        isValid = false;
    }
    if (typeof input !== "undefined") {
        var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(input)) {
            isValid = false;
        }
    }

    return isValid;
};

const Teach = () => {
    const history = useHistory();
    const [userData, setUserData] = useState({
        email: "",
        firstName: "",
        lastName: "",
    });
    const [loading, isLoading] = useState({
        newsLetter: false,
    });
    const [snackBarStatus, setSnackBarStatus] = useState("error");
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const registerForNewsLetter = () => {
        if (!userData.firstName || !userData.lastName || !userData.email) {
            setMsg("Please enter valid details.");
            setSnackBarStatus("error");
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        } else if (validateEmail(userData.email)) {
            isLoading((loadingCopy) => {
                return {
                    ...loadingCopy,
                    newsLetter: true,
                };
            });

            axios
                .post("/api/v1/newsletter", userData)
                .then((response) => {
                    if (response.data.success) {
                        setMsg(
                            _.get(
                                response,
                                "data.message",
                                "Successfully registered a user for newsletter."
                            )
                        );
                        setSnackBarStatus("information");
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    } else {
                        setMsg(
                            _.get(
                                response,
                                "data.message",
                                "Something went wrong."
                            )
                        );
                        setSnackBarStatus("error");
                        setShowSnackbar(true);
                        setTimeout(function () {
                            setMsg(null);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setSnackBarStatus("error");
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                })
                .finally(() => {
                    isLoading((loadingCopy) => {
                        return {
                            ...loadingCopy,
                            newsLetter: false,
                        };
                    });
                    setUserData({
                        email: "",
                        firstName: "",
                        lastName: "",
                    });
                });
        } else {
            setMsg("Please enter a valid email address.");
            setSnackBarStatus("error");
            setShowSnackbar(true);
            setTimeout(function () {
                setMsg(null);
            }, 3000);
        }
    };

    const handleUserData = (e) => {
        const inputId = e.target.id;
        const inputValue = e.target.value;

        setUserData((userDataCopy) => {
            return {
                ...userDataCopy,
                [inputId]: inputValue,
            };
        });
    };

    return (
        <Fragment>
            {msg && (
                <Snackbar
                    status={snackBarStatus}
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}

            <div className={styles.mainContainer}>
                <Navbar />

                <div className={styles.pageContainer}>
                    <div className={styles.contentContainer}>
                        <div className={styles.introContainer}>
                            <div className={styles.teacherStudentImg}>
                                <img src={Teacher} alt="Teacher" />
                            </div>

                            <div className={styles.introData}>
                                <div className={styles.introHeader}>
                                    FACILITATE BENEFECIAL ONLINE EXPERIENCES FOR
                                    CHILDREN
                                </div>

                                <div className={styles.introSubHeader}>
                                    Designed by educators for educators
                                </div>

                                <div className={styles.introPara}>
                                    Plassroom provides you variety of useful
                                    tools to create and host interactive live
                                    online sessions for children. Sessions can
                                    be listed on our online store to be booked
                                    by parents on the platform.
                                </div>

                                <div className={styles.introSignUpBtn}>
                                    <img
                                        src={SignUpBtn}
                                        alt="Sign Up Btn"
                                        className={styles.signUpBtn}
                                        onClick={() => history.push("/signup")}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={styles.infoContainer}>
                            <div className={styles.infoHeader}>
                                WE HELP FACILITATORS EXPAND THEIR REACH
                            </div>

                            <div className={styles.infoPara}>
                                Plassroom handles the administrative and
                                technical aspects on the platform and help you
                                with marketing your classes so you can focus on
                                what you do best.
                            </div>
                        </div>

                        <div className={styles.iconsContainer}>
                            <div className={styles.iconContainer}>
                                <img src={Settings} alt="Settings" />

                                <p>Online tools designed for a class setting</p>
                            </div>

                            <div
                                className={styles.iconContainer}
                                style={{
                                    maxWidth: "8.4rem",
                                }}
                            >
                                <img src={Click} alt="Click" />

                                <p>Easy to use and saves time</p>
                            </div>

                            <div className={styles.iconContainer}>
                                <img src={Home} alt="Home" />

                                <p>
                                    Usage fee only upon successful class booking
                                </p>
                            </div>

                            <div
                                className={styles.iconContainer}
                                style={{
                                    maxWidth: "8.9rem",
                                }}
                            >
                                <img src={MoneyBag} alt="MoneyBag" />

                                <p>Timely and secure payment</p>
                            </div>

                            <div className={styles.iconContainer}>
                                <img src={Calender} alt="Calender" />

                                <p>Control and flexibility of work schedule</p>
                            </div>
                        </div>

                        <div className={styles.contactBGContainer}>
                            <div className={styles.contactHeader}>
                                Empowering facilitators to teach your own
                                content with easy to use tools
                            </div>

                            <div className={styles.contactPara}>
                                We understand what you need to execute a good
                                online live class, after all we are educators
                                ourselves. The tools on our platform help you
                                save time whilst improving your students’ online
                                learning experience.
                            </div>

                            <div className={styles.contactSignUpBtn}>
                                <img
                                    src={SignUpBtn}
                                    alt="Sign Up Btn"
                                    className={styles.signUpBtn}
                                    onClick={() => history.push("/signup")}
                                />
                            </div>

                            <div className={styles.contactLink}>
                                Are you a <span>parent</span> looking for
                                interactive online sessions?{" "}
                                <span
                                    onClick={() => {
                                        history.push("/play");
                                    }}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Click here
                                </span>
                            </div>
                        </div>

                        <div className={styles.contactUsContainer}>
                            <div className={styles.contactUsDetails}>
                                <div className={styles.contactUsDetailsHeader}>
                                    Stay updated
                                </div>

                                <div className={styles.contactUsDetailsPara}>
                                    Come and be part of a community of like
                                    minded facilitators and get updates on
                                    educational information and new product
                                    features right into your mailbox!
                                </div>
                            </div>

                            <div className={styles.contactUsInputContainer}>
                                <div className={styles.nameInputContainer}>
                                    <input
                                        type="text"
                                        name="FName"
                                        id="firstName"
                                        placeholder="First Name"
                                        value={userData.firstName}
                                        onChange={handleUserData}
                                        disabled={loading.newsLetter}
                                    />

                                    <input
                                        type="text"
                                        name="LName"
                                        id="lastName"
                                        placeholder="Last Name"
                                        value={userData.lastName}
                                        onChange={handleUserData}
                                        disabled={loading.newsLetter}
                                    />
                                </div>

                                <input
                                    type="email"
                                    name="EmailAddress"
                                    id="email"
                                    placeholder="Email Address"
                                    className={styles.emailAddress}
                                    value={userData.email}
                                    onChange={handleUserData}
                                    disabled={loading.newsLetter}
                                />

                                <img
                                    src={RegisterBtn}
                                    alt="Register Btn"
                                    className={styles.registerBtn}
                                    onClick={
                                        loading.newsLetter
                                            ? null
                                            : registerForNewsLetter
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <FooterNew />
                </div>
            </div>
        </Fragment>
    );
};

export default Teach;
