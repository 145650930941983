import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default ({ Component }) => {
    const [showComponent, setShowComponent] = useState(false);

    const history = useHistory();
    useEffect(() => {
        if (!localStorage.getItem("auth")) {
            const redirectPath = window.location.pathname;
            if (redirectPath.length > 1) {
                history.replace(`/login?redirect=${redirectPath}`);
            } else {
                history.replace("/home");
            }
        } else {
            setShowComponent(true);
        }
        // eslint-disable-next-line
    }, []);
    return <>{showComponent && <Component />}</>;
};
