import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import FooterNew from "../../components/FooterNew";
import Navbar from "../../components/Navbar";
import styles from "./index.module.css";

export default () => {
    const location = useLocation();
    const history = useHistory();
    const redirectURL = new URLSearchParams(location.search).get("redirect");

    useEffect(() => {
        if (redirectURL) {
            setTimeout(function () {
                history.push(`/${redirectURL}`);
            }, 5000);
        }

        // eslint-disable-next-line
    }, [redirectURL]);

    return (
        <div className={styles.waiting_screen}>
            <Navbar />

            <div className={styles.error_content__container}>
                <div
                    style={{
                        textAlign: "center",
                    }}
                >
                    <p className={styles.error_heading}>Page not found.</p>
                </div>
            </div>

            <FooterNew />
        </div>
    );
};
