import React, { useEffect, useRef, useState } from "react";
import styles from "./layout2.module.css";
import TwilioParticipant from "../../../../../components/TwilioParticipant";
import Participant from "../../../../../components/Participant";
import axios from "axios";
import Snackbar from "../../../../../components/Snackbar";
import _ from "lodash";

export default ({ participants, room, publishAudio }) => {
    var Teacher = null;
    var Students = [];
    const rowRef = useRef(null);
    const [studentsData, setStudentsData] = useState([]);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const userIds = participants.map((participant) => {
        var identityObj = JSON.parse(participant.identity);
        return identityObj._id;
    });

    useEffect(() => {
        const getUserDetails = async () => {
            await axios
                .post(`/api/v1/parents`, {
                    data: userIds,
                })
                .then((studentsResult) => {
                    const users = studentsResult.data.data.users;
                    let filteredUsers = [];
                    users.map((user) => {
                        const tempData = {};
                        tempData.name = user.students[0].studentpreferedname;
                        tempData._id = user.students[0]._id;
                        tempData.parentId = user._id;

                        filteredUsers.push(tempData);
                        return 0;
                    });
                    setStudentsData(filteredUsers);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        if (currentUserRole === "teacher") getUserDetails();
        // eslint-disable-next-line
    }, [participants.length]);

    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;

    if (currentUserRole === "teacher") {
        // Teacher = (
        //     <TwilioParticipant1
        //         key={room.localParticipant.sid}
        //         participant={room.localParticipant}
        //         role={"teacher"}
        //     />
        // );
        // Students = participants.map((participant) => {
        //     var identityObj = JSON.parse(participant.identity);
        //     const studentName = studentsData.find(
        //         (student) => student.parentId === identityObj._id
        //     );
        //     return (
        //         <TwilioParticipant
        //             key={participant.sid}
        //             role={"student"}
        //             participant={participant}
        //             screen="teacher"
        //             studentName={studentName?.name}
        //         />
        //     );
        // });
    } else {
        // console.log("participants", participants)
        var teacherParticipant = participants.find((obj) => {
            var identityObj = JSON.parse(obj.identity);
            return identityObj.role === "teacher";
        });

        if (teacherParticipant) {
            Teacher = (
                <TwilioParticipant
                    role="teacherScreen"
                    key={teacherParticipant.sid}
                    participant={teacherParticipant}
                />
            );
            Students = [
                <TwilioParticipant
                    role="teacherCamera"
                    key={teacherParticipant.sid}
                    participant={teacherParticipant}
                    // role="student"
                    // key={room.localParticipant.sid}
                    // participant={room.localParticipant}
                    // publishAudio={publishAudio}
                    // screen="student"
                />,
            ];
        }
        // if (room) {
        //     Students = [
        //         <TwilioParticipant
        //             role="teacher"
        //             key={teacherParticipant.sid}
        //             participant={teacherParticipant}
        //         // role="student"
        //         // key={room.localParticipant.sid}
        //         // participant={room.localParticipant}
        //         // publishAudio={publishAudio}
        //         // screen="student"
        //         />,
        //     ]
        //     // .concat(
        //     //     participants
        //     //         .filter((obj) => {
        //     //             var identityObj = JSON.parse(obj.identity);
        //     //             return identityObj.role !== "teacher";
        //     //         })
        //     //         .map((participant) => (
        //     //             <TwilioParticipant
        //     //                 role="student"
        //     //                 key={participant.sid}
        //     //                 participant={participant}
        //     //                 publishAudio={publishAudio}
        //     //                 screen="student"
        //     //             />
        //     //         ))
        //     // );
        // }
    }

    let noOfParticipants = participants.length;
    let participantBoxes = [];
    // console.log("noOfParticipants", noOfParticipants)
    // console.log("Students", Students)
    for (let i = 0; i < 1; i++) {
        participantBoxes.push(
            // <Col md="auto">
            <div
                className={styles.block}
                style={{
                    width: rowRef
                        ? rowRef.current?.offsetWidth / noOfParticipants - 30
                        : "",
                }}
            >
                <Participant>{Students[i]}</Participant>
            </div>
            // </Col>
        );
    }

    return (
        <div className={styles.blockTeacher}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <Participant>{Teacher}</Participant>
            {/* <div style={{ position: "absolute", bottom: "0px", width: "100%" }}> */}
            <div className={`${styles.block2} ${styles.blockMedia}`}>
                {/* <Row style={{ justifyContent: "center" }} ref={rowRef}> */}
                {participantBoxes}
                {/* </Row> */}
            </div>
        </div>
    );
};
