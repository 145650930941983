import React, { useEffect, useState } from "react";
import styles from "../sessionHome.module.css";
import { Row, Col } from "react-bootstrap";
import Participant from "../../../components/Participant";
import Logo from "../../../assets/images/Logo.svg";
import EndCall from "../../../assets/images/all-end-call.svg";
import Unmute from "../../../assets/images/unmute.svg";
import Muted from "../../../assets/images/muted.svg";
import Modal from "../../../components/Modal";
import Whiteboard from "../../../components/Whiteboard";
import TwilioParticipant from "../../../components/TwilioParticipant";
import axios from "axios";
import Snackbar from "../../../components/Snackbar";
import _ from "lodash";

export default ({
    stickerInfo,
    drawData,
    socket,
    selectedItem,
    updateSelectedItem,
    myMarkerColor,
    streams,
    role,
    endUserSession,
    muteUser,
    unMuteUser,
    publishAudio,
    muteAllUsers,
    unMuteAllUsers,
    mutedUsers,
    endEverySession,
    participants,
    room,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [muteStudentsMuted, setMuteStudentsMuted] = useState(false);
    var Students = [];
    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;
    const [studentsData, setStudentsData] = useState([]);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const userIds = participants.map((participant) => {
        var identityObj = JSON.parse(participant.identity);
        return identityObj._id;
    });

    useEffect(() => {
        const getUserDetails = async () => {
            await axios
                .post(`/api/v1/parents`, {
                    data: userIds,
                })
                .then((studentsResult) => {
                    const users = studentsResult.data.data.users;
                    let filteredUsers = [];
                    users.map((user) => {
                        const tempData = {};
                        tempData.name = user.students[0].studentpreferedname;
                        tempData._id = user.students[0]._id;
                        tempData.parentId = user._id;

                        filteredUsers.push(tempData);
                        return 0;
                    });
                    setStudentsData(filteredUsers);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        if (role === "teacher") getUserDetails();
        // eslint-disable-next-line
    }, [participants.length]);

    if (currentUserRole === "teacher") {
        Students = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            const studentName = studentsData.find(
                (student) => student.parentId === identityObj._id
            );
            return (
                <TwilioParticipant
                    isMutedUser={mutedUsers.indexOf(identityObj._id) !== -1}
                    key={participant.sid}
                    role={"student"}
                    participant={participant}
                    publishAudio={publishAudio}
                    muteUser={() => muteUser(identityObj._id)}
                    unMuteUser={() => unMuteUser(identityObj._id)}
                    endUserSession={() => endUserSession(identityObj._id)}
                    screen="teacher"
                    studentName={studentName?.name}
                />
            );
        });
    } else {
        Students = [
            <TwilioParticipant
                role="student"
                key={room.localParticipant.sid}
                participant={room.localParticipant}
                publishAudio={publishAudio}
                screen="student"
            />,
        ].concat(
            participants
                .filter((obj) => {
                    var identityObj = JSON.parse(obj.identity);
                    return identityObj.role !== "teacher";
                })
                .map((participant) => (
                    <TwilioParticipant
                        role="student"
                        key={participant.sid}
                        participant={participant}
                        publishAudio={publishAudio}
                        screen="student"
                    />
                ))
        );
    }

    // const muteStudentsMuted =
    //   streams.length !== 0 && streams.length === mutedUsers.length;
    const handleMuteStudents = () => {
        setMuteStudentsMuted(!muteStudentsMuted);
    };
    return (
        <>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <Col
                sm={12}
                id={
                    role === "student"
                        ? styles.plassStudentLogoDiv
                        : styles.plassTeacherLogoDiv
                }
            >
                <img style={{ width: "13%" }} src={Logo} alt="" />
            </Col>
            <Col
                sm={role === "teacher" ? 10 : 12}
                id={role === "teacher" ? styles.leftCol : styles.leftColStudent}
            >
                <Row style={{ marginBottom: "15px" }}>
                    <Col sm={6}>
                        <div>
                            {socket && room && (
                                <Whiteboard
                                    drawData={drawData}
                                    socket={socket}
                                    streams={streams}
                                    role={role}
                                    myMarkerColor={myMarkerColor}
                                />
                            )}
                        </div>
                    </Col>
                    <Col sm={6}>
                        <Participant multiRow>
                            {/* <Participant>{Teacher}</Participant> */}
                        </Participant>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Row>
                            <Col sm={3}>
                                <Participant style={{ height: "150px" }}>
                                    {typeof Students[6] !== "undefined" &&
                                        Students[6]}
                                </Participant>
                            </Col>
                            <Col sm={3}>
                                <Participant style={{ height: "150px" }}>
                                    {typeof Students[5] !== "undefined" &&
                                        Students[5]}
                                </Participant>
                            </Col>
                            <Col sm={3}>
                                <Participant style={{ height: "150px" }}>
                                    {typeof Students[3] !== "undefined" &&
                                        Students[3]}
                                </Participant>
                            </Col>
                            <Col sm={3}>
                                <Participant style={{ height: "150px" }}>
                                    {typeof Students[0] !== "undefined" &&
                                        Students[0]}
                                </Participant>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={6}>
                        <Row>
                            <Col sm={3}>
                                <Participant style={{ height: "150px" }}>
                                    {typeof Students[1] !== "undefined" &&
                                        Students[1]}
                                </Participant>
                            </Col>
                            <Col sm={3}>
                                <Participant style={{ height: "150px" }}>
                                    {typeof Students[2] !== "undefined" &&
                                        Students[2]}
                                </Participant>
                            </Col>
                            <Col sm={3}>
                                <Participant style={{ height: "150px" }}>
                                    {typeof Students[4] !== "undefined" &&
                                        Students[4]}
                                </Participant>
                            </Col>
                            <Col sm={3}>
                                <Participant style={{ height: "150px" }}>
                                    {typeof Students[7] !== "undefined" &&
                                        Students[7]}
                                </Participant>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {role === "teacher" ? (
                    <Col sm={12} className={styles.teacherControls}>
                        <img
                            style={{
                                cursor: "pointer",
                                height: "60px",
                                width: "60px",
                            }}
                            src={EndCall}
                            alt="callend"
                            onClick={() => setShowModal(!showModal)}
                        />
                        {muteStudentsMuted ? (
                            <img
                                style={{
                                    height: "60px",
                                    width: "60px",
                                    cursor: "pointer",
                                }}
                                src={Muted}
                                onClick={() => {
                                    unMuteAllUsers();
                                    handleMuteStudents();
                                }}
                                alt="muted"
                            />
                        ) : (
                            <img
                                style={{
                                    cursor: "pointer",
                                    height: "60px",
                                    width: "60px",
                                }}
                                src={Unmute}
                                alt="unmute-all"
                                onClick={() => {
                                    muteAllUsers();
                                    handleMuteStudents();
                                }}
                            />
                        )}
                        <Modal
                            show={showModal}
                            handleClose={() => setShowModal(!showModal)}
                            body={"Confirm you would like to end session?"}
                            confirm={() => endEverySession()}
                        />
                    </Col>
                ) : null}
            </Col>
        </>
    );
};
