import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import axios from "axios";
import _ from "lodash";
import styles from "./accountSetting.module.css";
import StudentLogoBg from "../../../assets/images/LOGO (3).svg";
import Dashboard from "../../../assets/images/Dashboard@2x.png";
import Setting from "../../../assets/images/icon/Settings.svg";
import Store from "../../../assets/images/icon/Store.svg";
import Messages from "../../../assets/images/icon/Messages.svg";
import MessagesText from "../../../assets/images/icon/MessagesText.svg";
import SettingsText from "../../../assets/images/icon/SettingsText.svg";
import StoreText from "../../../assets/images/icon/StoreText.svg";
// import PersonalDetail from "../PersonalDetail/index";
// import EmailPreference from "../EmailPreference/index";
// import PaymentDetails from "../PaymentDetails/index";
// import ClassRatings from "../ClassRatings/index";
import "./style.css";
// import Dropdown from "../../Dropdown";
// import CustomDropdown from "../../CustomDropdown";
// import PrivacyPolicy from "../../../assets/documents/Privacy Policy 1.pdf";
// import TermsOfService from "../../../assets/documents/Terms of Service 1.pdf";
import { Link, useHistory } from "react-router-dom";

export default function EditAccount() {
    const history = useHistory();
    const currentUserRole = JSON.parse(localStorage.getItem("auth"))?.user
        ?.role;
    const [user, setUser] = useState();
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    // const [showDashboardModal, setShowDashboardModal] = useState(false);
    const [sessions, setSessions] = useState();
    const [lastSession, setLastSession] = useState();
    const [sessionID, setSessionID] = useState(undefined);
    const dropdownData = [
        { label: "Class", value: "CLASS" },
        { label: "Term", value: "TERM" },
    ];

    useEffect(() => {
        const getSessionInfo = async () => {
            await axios
                .get(`/api/v1/sessions-current`)
                .then(async (currentSessionsResult) => {
                    await axios
                        .get(`/api/v1/sessions/students`)
                        .then((allUserSessionsResult) => {
                            if (
                                currentSessionsResult.data.success &&
                                allUserSessionsResult.data.success
                            ) {
                                const lastSession =
                                    currentSessionsResult.data.sessions[
                                        currentSessionsResult.data.sessions
                                            .length - 1
                                    ];
                                setSessions(
                                    allUserSessionsResult.data.sessions
                                );

                                if (lastSession) {
                                    setLastSession(lastSession);
                                    const id = lastSession._id;
                                    setSessionID(id);
                                }
                            }
                        })
                        .catch((error) => {
                            setMsg(
                                _.get(
                                    error,
                                    "response.data.title",
                                    "Something went wrong."
                                )
                            );
                            setShowSnackbar(true);
                            setTimeout(function () {
                                setMsg(null);
                            }, 3000);
                            return Promise.reject(error);
                        });
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        const getUserDetails = async () => {
            await axios
                .get("/api/v1/user")
                .then((result) => {
                    setUser(result.data.user);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        getSessionInfo();
        getUserDetails();
        const sessionIntervalObj = setInterval(getSessionInfo, 60000);

        return () => {
            clearInterval(sessionIntervalObj);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <Row className={styles.topRow}>
                <Col md={3}>
                    <div
                        className={styles.logoContainer}
                        onClick={() => window.location.replace("/home")}
                    >
                        <img
                            src={StudentLogoBg}
                            className={styles.studentLogoBg}
                            alt="Logo"
                        />
                        <h1 className={styles.logoText}>
                            plassroom<sup>+</sup>
                        </h1>
                    </div>
                </Col>
                <Col md={9}>
                    <div>
                        <Row>
                            <Col md={6}>
                                <div>
                                    <span
                                        className={styles.welcomeText}
                                        id="welcome"
                                    >
                                        Account Settings
                                    </span>
                                </div>
                            </Col>
                            <Col md={6} style={{ textAlign: "end" }}>
                                <div>
                                    {currentUserRole === "teacher" && (
                                        <span className={styles.topIcon}>
                                            <div
                                                className={`row ${styles.iconRow}`}
                                            >
                                                <div
                                                    className={`${styles.iconColumn}`}
                                                >
                                                    <img
                                                        id="MessagesText"
                                                        src={MessagesText}
                                                        className={
                                                            styles.hoverText
                                                        }
                                                        style={{
                                                            visibility:
                                                                "hidden",
                                                        }}
                                                        alt="message"
                                                    ></img>
                                                    <img
                                                        onMouseOver={() =>
                                                            (document.querySelector(
                                                                "#MessagesText"
                                                            ).style.visibility =
                                                                "visible")
                                                        }
                                                        onMouseLeave={() =>
                                                            (document.querySelector(
                                                                "#MessagesText"
                                                            ).style.visibility =
                                                                "hidden")
                                                        }
                                                        id="messageImage"
                                                        src={Messages}
                                                        className={
                                                            styles.dashboardIcon
                                                        }
                                                        onClick={() =>
                                                            (window.location.href =
                                                                "/accountsettings")
                                                        }
                                                        alt="message"
                                                    ></img>
                                                </div>
                                                <div
                                                    className={` ${styles.iconColumn}`}
                                                >
                                                    <img
                                                        style={{
                                                            visibility:
                                                                "hidden",
                                                        }}
                                                        id="storeText"
                                                        src={StoreText}
                                                        className={
                                                            styles.hoverText
                                                        }
                                                        alt="message"
                                                    ></img>
                                                    <img
                                                        onMouseOver={() =>
                                                            (document.querySelector(
                                                                "#storeText"
                                                            ).style.visibility =
                                                                "visible")
                                                        }
                                                        onMouseLeave={() =>
                                                            (document.querySelector(
                                                                "#storeText"
                                                            ).style.visibility =
                                                                "hidden")
                                                        }
                                                        src={Store}
                                                        className={`${styles.dashboardIcon} ${styles.storeHover}`}
                                                        onClick={() =>
                                                            history.push(
                                                                "/home"
                                                            )
                                                        }
                                                        alt="Store"
                                                    ></img>
                                                </div>
                                                <div
                                                    className={` ${styles.iconColumn}`}
                                                >
                                                    <img
                                                        style={{
                                                            visibility:
                                                                "hidden",
                                                        }}
                                                        id="settingText"
                                                        src={SettingsText}
                                                        className={
                                                            styles.hoverText
                                                        }
                                                        alt="message"
                                                    ></img>
                                                    <img
                                                        onMouseOver={() =>
                                                            (document.querySelector(
                                                                "#settingText"
                                                            ).style.visibility =
                                                                "visible")
                                                        }
                                                        onMouseLeave={() =>
                                                            (document.querySelector(
                                                                "#settingText"
                                                            ).style.visibility =
                                                                "hidden")
                                                        }
                                                        src={Setting}
                                                        className={
                                                            styles.dashboardIcon
                                                        }
                                                        onClick={() =>
                                                            (window.location.href =
                                                                "/accountsettings")
                                                        }
                                                        alt="message"
                                                    ></img>
                                                </div>
                                                <div
                                                    className={` ${styles.iconColumn}`}
                                                >
                                                    <img
                                                        style={{
                                                            visibility:
                                                                "hidden",
                                                        }}
                                                        id="dashboardText"
                                                        src={MessagesText}
                                                        className={
                                                            styles.hoverText
                                                        }
                                                        alt="message"
                                                    ></img>
                                                    <img
                                                        onMouseOver={() =>
                                                            (document.querySelector(
                                                                "#dashboardText"
                                                            ).style.visibility =
                                                                "visible")
                                                        }
                                                        onMouseLeave={() =>
                                                            (document.querySelector(
                                                                "#dashboardText"
                                                            ).style.visibility =
                                                                "hidden")
                                                        }
                                                        src={Dashboard}
                                                        className={
                                                            styles.dashboardIcon
                                                        }
                                                        onClick={() =>
                                                            (window.location.href =
                                                                "/accountsettings")
                                                        }
                                                        alt="message"
                                                    ></img>
                                                </div>
                                            </div>
                                        </span>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            {/* <div className={styles.tabsHead}> */}
            {/* <Tabs
                // defaultActiveKey="paymentDet"
                transition={false}
                id="noanim-tab-example"
                className={`mb-2 mt-3 ${styles.tabsHead}`}
            >
                <Tab
                    eventKey="personalDetails"
                    title="Personal Details"
                    className={styles.selectedtab}
                >
                    <PersonalDetail />
                </Tab>
                <Tab
                    eventKey="emailPref"
                    title="Email Preference"
                    className={styles.selectedtab}
                >
                    <EmailPreference />
                </Tab>
                <Tab
                    eventKey="paymentDet"
                    title="Payment Details"
                    className={styles.selectedtab}
                >
                    <PaymentDetails />
                </Tab>
                <Tab
                    eventKey="classRatings"
                    title="Class Ratings"
                    className={styles.selectedtab}
                >
                    <ClassRatings />
                </Tab>
            </Tabs> */}
            <div className="container">
                <div className={styles.editContainer}>
                    <div className="row">
                        <h2 className={styles.heading}>
                            Link a bank account for payouts
                        </h2>
                    </div>
                    {/* <div>
                        <h4 className={styles.dropdownTitle}>Country</h4>
                        <CustomDropdown
                            width="100px"
                            dropdownData={dropdownData}
                        // data={dropdownData}
                        // setData={setFeeCurrency}
                        // isPublished={publish}
                        />
                    </div> */}
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <div>
                                <h4 className={styles.inputTitle}>Country</h4>
                                <input
                                    className={styles.feeInput}
                                    type="number"
                                />
                            </div>

                            <div>
                                <h4 className={styles.inputTitle}>
                                    Mobile Number
                                </h4>
                                <input
                                    className={styles.feeInput}
                                    type="number"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div>
                                <h4 className={styles.inputTitle}>Sort Code</h4>
                                <input
                                    className={styles.feeInput}
                                    type="number"
                                    placeholder="123123"
                                />
                            </div>
                            <div>
                                <h4 className={styles.inputTitle}>
                                    Account Number
                                </h4>
                                <input
                                    className={styles.feeInput}
                                    type="number"
                                    placeholder="1234567"
                                />
                            </div>
                            <div>
                                <h4 className={styles.inputTitle}>
                                    Confirm Account Number
                                </h4>
                                <input
                                    className={styles.feeInput}
                                    type="number"
                                    placeholder="1234567"
                                />
                            </div>
                        </div>
                    </div>
                    <p
                        className="mt-2"
                        style={{ fontSize: "14px", marginLeft: "13px" }}
                    >
                        By submitting a bank account, I authorise Stripe to
                        transfer to and from this bank account through the
                        Bankers’ Automated Clearing Services (BACS), protected
                        by the BACS direct debit guarantee, and confirm that I
                        have read and agree to the Services Agreement, including
                        the BACS Direct Debit Instructions.
                    </p>
                    <div className={`row ${styles.bottomRow}`}>
                        <p className={styles.terms}>
                            See our
                            <Link to="/termsofservice">
                                Terms of Service and Payment Terms of Service
                            </Link>
                        </p>
                        <button className="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
