import { feeCurrencyData } from "./constants";
import moment from "moment";

export const dateFormat = (value, format) => {
    return value ? moment(value).format(format) : null;
};

export const getCurrencySymbol = (value) => {
    const symbolfeeCurrencyData = feeCurrencyData
        .filter((o) => o.value === value)
        .map((o) => o.symbol)[0];
    return "£";
};

export const getSeasons = () => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear().toString().slice(-2); // gets the last two digits of the current year
    let currentSeason, nextSeason;
    if (currentMonth >= 0 && currentMonth <= 2) {
        currentSeason = "Winter";
        nextSeason = "Spring";
    } else if (currentMonth >= 3 && currentMonth <= 5) {
        currentSeason = "Spring";
        nextSeason = "Summer";
    } else if (currentMonth >= 6 && currentMonth <= 8) {
        currentSeason = "Summer";
        nextSeason = "Autumn";
    } else {
        currentSeason = "Autumn";
        nextSeason = "Winter";
    }

    return `${currentSeason}/${nextSeason} '${currentYear}`;
};
