import axios from "axios";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Participant from "../../../../../../components/Participant";
import Snackbar from "../../../../../../components/Snackbar";
import StudentSpotlight from "../../../../../../components/StudentSpotlight";
import TwilioParticipant from "../../../../../../components/TwilioParticipant";
import AudioPlayer from "../../AudioPlayer/index";
import VideoPlayer from "../../VideoPlayer/index";
import styles from "./layout4.module.css";

export default ({
    participants,
    room,
    mutedUsers,
    publishAudio,
    endUserSession,
    muteUser,
    unMuteUser,
    socket,
    sessionID,
    currentPanel,
    session,
    updateSession,
}) => {
    var Teacher = null;
    var Participants = [];

    const rowRef = useRef();
    const [isSelected, setIsSelected] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState();
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [studentsData, setStudentsData] = useState([]);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [selectedStudentToFocus, setSelectedStudentToFocus] = useState(-1);

    const userIds = participants.map((participant) => {
        var identityObj = JSON.parse(participant.identity);
        return identityObj._id;
    });

    useEffect(() => {
        const getUserDetails = async () => {
            await axios
                .post(`/api/v1/parents`, {
                    data: userIds,
                })
                .then((studentsResult) => {
                    const users = studentsResult.data.data.users;
                    let filteredUsers = [];
                    users.map((user) => {
                        const tempData = {};
                        tempData.name = user.students[0].studentpreferedname;
                        tempData._id = user.students[0]._id;
                        tempData.parentId = user._id;

                        filteredUsers.push(tempData);
                        return 0;
                    });
                    setStudentsData(filteredUsers);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        if (currentUserRole === "teacher") getUserDetails();
        // eslint-disable-next-line
    }, [participants.length]);

    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;

    if (currentUserRole === "teacher") {
        Teacher = (
            <TwilioParticipant
                key={room.localParticipant.sid}
                participant={room.localParticipant}
                role={"teacher"}
                focusStudent={() => {}}
                selectedStudentToFocus={selectedStudentToFocus}
            />
        );
        Participants.push(Teacher);

        let studentParticipants = participants.map((participant, index) => {
            var identityObj = JSON.parse(participant.identity);
            const studentName = studentsData.find(
                (student) => student.parentId === identityObj._id
            );
            return (
                <TwilioParticipant
                    isMutedUser={mutedUsers.indexOf(identityObj._id) !== -1}
                    key={participant.sid}
                    role={"student"}
                    participant={participant}
                    publishAudio={publishAudio}
                    muteUser={() => muteUser(identityObj._id)}
                    unMuteUser={() => unMuteUser(identityObj._id)}
                    endUserSession={() => endUserSession(identityObj._id)}
                    screen="teacher"
                    studentName={studentName?.name}
                    focusStudent={() => {
                        setSelectedStudentToFocus(index);
                    }}
                    selectedStudentToFocus={selectedStudentToFocus}
                />
            );
        });
        Participants = [...Participants, ...studentParticipants];
    }

    useEffect(() => {
        const getSelectedMedia = async () => {
            const sessionMediaIds =
                currentPanel.lesson_settings.assets.image_ids;
            let galleryData = {
                selectedMediaArray: sessionMediaIds,
                userId: "",
            };
            await axios
                .post(`/api/v1/user/gallery/selected`, galleryData)
                .then((data) => {
                    if (data.data.success) {
                        const resultMedia = data.data.data.newArray;
                        setSelectedMedia(resultMedia);
                        setIsSelected(
                            new Array(resultMedia.length).fill(false)
                        );
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        getSelectedMedia();
        // eslint-disable-next-line
    }, []);

    const handleSelect = async (index) => {
        const newArray = new Array(isSelected.length).fill(false);
        newArray[index] = true;
        setIsSelected([...newArray]);
        if (selectedMedia[newArray.indexOf(true)]) {
            setSelectedIndex(index);
        }
        const userIds = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            return identityObj._id;
        });
        const newSession = {
            ...session,
            ...{
                configuration: {
                    ...session.configuration,
                    right_panel_settings:
                        session.configuration.right_panel_settings.map(
                            (obj) => {
                                if (obj._id === currentPanel?._id)
                                    obj.lesson_settings.assets.activeSlideIndex =
                                        index;
                                return obj;
                            }
                        ),
                },
            },
        };
        updateSession(newSession);
        socket.emit("updateLessonSlide", {
            action: "updateLessonSlide",
            payload: {
                userIds,
                item: selectedMedia[index],
                index,
            },
        });
    };

    let noOfParticipants = Participants.length;
    let participantBoxes = [];
    //(rowRef.current.offsetWidth/noOfParticipants) - 30;
    for (let i = 0; i < noOfParticipants; i++) {
        participantBoxes.push(
            <Col md="auto">
                <div
                    className={styles.block}
                    style={{
                        width: rowRef
                            ? rowRef.current?.offsetWidth / noOfParticipants -
                              30
                            : "",
                    }}
                >
                    <Participant>{Participants[i]}</Participant>
                </div>
            </Col>
        );
    }

    let carouselItems = [];
    carouselItems = selectedMedia.map((obj, index) => {
        const type = obj.type;
        if (type === "VIDEO") {
            const createVid = (bar) => {
                const userIds = participants.map((participant) => {
                    var identityObj = JSON.parse(participant.identity);
                    return identityObj._id;
                });
                const vid = (
                    <VideoPlayer
                        source={obj.location}
                        isSelectedVid={bar}
                        top="50%"
                        socket={socket}
                        userIds={userIds}
                        isStudent={false}
                        key={index}
                    ></VideoPlayer>
                );
                return vid;
            };
            return createVid;
        } else if (type === "AUDIO") {
            const createAud = (bar) => {
                const userIds = participants.map((participant) => {
                    var identityObj = JSON.parse(participant.identity);
                    return identityObj._id;
                });
                const aud = (
                    <AudioPlayer
                        source={obj.location}
                        isSelectedAud={bar}
                        top="50%"
                        socket={socket}
                        userIds={userIds}
                        isStudent={false}
                        key={index}
                    ></AudioPlayer>
                );
                return aud;
            };
            return createAud;
        } else if (type === "IMAGE") {
            const createImg = () => {
                return (
                    <img
                        className={`${styles.assets} ${styles.image}`}
                        src={obj.location}
                        alt=""
                    />
                );
            };
            return createImg;
        }
        return null;
    });

    let carouselItemsGrouped = [];
    for (let i = 0; i < selectedMedia.length; i += 3) {
        carouselItemsGrouped.push(
            <Carousel.Item>
                <div style={{ height: "12vh", width: "10vw" }}>
                    <Row className={styles.rowCarousel}>
                        <div
                            className={`${styles.block} ${
                                styles.blockCarouselItem
                            } ${isSelected[i] ? styles.selected : ""}`}
                            onClick={() => {
                                handleSelect(i);
                            }}
                        >
                            {carouselItems[i](false)}
                        </div>
                        {selectedMedia[i + 1] && (
                            <div
                                className={`${styles.block} ${
                                    styles.blockCarouselItem
                                } ${isSelected[i + 1] ? styles.selected : ""}`}
                                onClick={() => {
                                    handleSelect(i + 1);
                                }}
                            >
                                {carouselItems[i + 1](false)}
                            </div>
                        )}
                        {selectedMedia[i + 2] && (
                            <div
                                className={`${styles.block} ${
                                    styles.blockCarouselItem
                                } ${isSelected[i + 2] ? styles.selected : ""}`}
                                onClick={() => {
                                    handleSelect(i + 2);
                                }}
                            >
                                {carouselItems[i + 2](false)}
                            </div>
                        )}
                    </Row>
                </div>
            </Carousel.Item>
        );
    }

    return (
        <div className={styles.blockTeacher}>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            {carouselItems[selectedIndex]
                ? carouselItems[selectedIndex](true)
                : ""}
            <Carousel
                indicators={false}
                className={styles.carousel}
                interval={null}
            >
                {carouselItemsGrouped}
            </Carousel>
            <div className={styles.rowDiv}>
                <Row style={{ justifyContent: "center" }} ref={rowRef}>
                    {participantBoxes}
                </Row>
            </div>

            <StudentSpotlight
                selectedStudentToFocus={selectedStudentToFocus}
                Participants={Participants}
                closeModal={() => {
                    setSelectedStudentToFocus(-1);
                }}
            />
        </div>
    );
};
