import React, { useState, useEffect } from "react";
import styles from "./Layout3.module.css";
import { Row, Col } from "react-bootstrap";
import Participant from "../../../../../../../components/Participant";
import TwilioParticipant from "../../../../../../../components/TwilioParticipant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan, faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import Whiteboard from "../../../../../../../components/WhiteboardByNirajan";
import axios from "axios";
import Snackbar from "../../../../../../../components/Snackbar";
import _ from "lodash";

export default ({
    stickerInfo,
    drawData,
    socket,
    selectedItem,
    updateSelectedItem,
    myMarkerColor,
    streams,
    role,
    endUserSession,
    muteUser,
    unMuteUser,
    publishAudio,
    muteAllUsers,
    unMuteAllUsers,
    mutedUsers,
    endEverySession,
    participants,
    room,
    key,
    currentPanel,
    session,
}) => {
    var Teacher = null;
    var Participants = [];
    const [modalFlag, setModalFlag] = useState(0);
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [teacherDrawData, setTeacherDrawData] = useState([]);
    const [studentDrawData, setStudentDrawData] = useState([]);
    const [initialState, setInitaialState] = useState(false);
    const [fitToScreen, setFitToScreen] = useState(
        currentPanel.lesson_settings.whiteboard.fitToScreen
    );
    const [studentFlag, setStudentFlag] = useState(0);
    const [studentsData, setStudentsData] = useState([]);
    const [showFeed, setShowFeed] = useState(false);
    const [position, setPosition] = useState(
        currentPanel?.lesson_settings?.whiteboard?.position
    );

    const userIds = participants.map((participant) => {
        var identityObj = JSON.parse(participant.identity);
        return identityObj._id;
    });
    const changePosition = (data) => {
        setPosition(data);
    };

    useEffect(() => {
        const getUserDetails = async () => {
            await axios
                .post(`/api/v1/parents`, {
                    data: userIds,
                })
                .then((studentsResult) => {
                    const users = studentsResult.data.data.users;
                    let filteredUsers = [];
                    users.map((user) => {
                        const tempData = {};
                        tempData.name = user.students[0].studentpreferedname;
                        tempData._id = user.students[0]._id;
                        tempData.parentId = user._id;

                        filteredUsers.push(tempData);
                        return 0;
                    });
                    setStudentsData(filteredUsers);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        if (role === "teacher") getUserDetails();
        // eslint-disable-next-line
    }, [participants.length]);

    useEffect(() => {
        var studentParticipant = participants[studentFlag];

        let studentId;

        if (studentParticipant) {
            var identityObj = JSON.parse(studentParticipant.identity);
            studentId = identityObj._id;
        }

        const getSelectedMedia = async () => {
            const selectedMediaArray =
                currentPanel.lesson_settings?.whiteboard?.image_ids;
            let galleryData = {
                selectedMediaArray: selectedMediaArray,
                userId: session.teacher_id,
            };
            await axios
                .post(`/api/v1/user/gallery/selected`, galleryData)
                .then((data) => {
                    if (data.data.success) {
                        const resultMedia = data.data.data.newArray;

                        setSelectedMedia(resultMedia);
                        setInitaialState(true);
                    }
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };

        const getDrawData = async () => {
            let teacherData = [];
            let studentData = [];
            if (drawData) {
                drawData.forEach((obj) => {
                    if (
                        obj.user_id ===
                        JSON.parse(localStorage.getItem("auth")).user._id
                    ) {
                        teacherData.push(obj.draw_data);
                    } else if (studentId && obj.user_id === studentId) {
                        studentData.push(obj.draw_data);
                    }
                });
            }

            setTeacherDrawData(teacherData);
            setStudentDrawData(studentData);
            setInitaialState(true);
        };
        getSelectedMedia();
        getDrawData();
        // eslint-disable-next-line
    }, [drawData]);

    const increaseStudentFlag = () => {
        if (studentFlag < participants.length - 1) {
            setStudentFlag(studentFlag + 1);
        }
    };

    const decreaseStudentFlag = () => {
        if (studentFlag !== 0) {
            setStudentFlag(studentFlag - 1);
        }
    };

    const currentUserRole = JSON.parse(localStorage.getItem("auth")).user.role;

    if (currentUserRole === "teacher") {
        Teacher = (
            <TwilioParticipant
                key={room.localParticipant.sid}
                participant={room.localParticipant}
                role={"teacher"}
            />
        );
        Participants.push(Teacher);

        let studentParticipants = participants.map((participant) => {
            var identityObj = JSON.parse(participant.identity);
            const studentName = studentsData.find(
                (student) => student.parentId === identityObj._id
            );
            return (
                <TwilioParticipant
                    isMutedUser={mutedUsers.indexOf(identityObj._id) !== -1}
                    key={participant.sid}
                    role={"student"}
                    participant={participant}
                    publishAudio={publishAudio}
                    muteUser={() => muteUser(identityObj._id)}
                    unMuteUser={() => unMuteUser(identityObj._id)}
                    endUserSession={() => endUserSession(identityObj._id)}
                    screen="teacher"
                    studentName={studentName?.name}
                />
            );
        });
        Participants = [...Participants, ...studentParticipants];
    }

    let noOfParticipants = Participants.length;
    let participantBoxes = [];
    let boxWidth = "10vw";
    //(rowRef.current.offsetWidth/noOfParticipants) - 30;
    for (let i = 0; i < noOfParticipants; i++) {
        participantBoxes.push(
            <div className={styles.block} style={{ width: boxWidth }}>
                <Participant>{Participants[i]}</Participant>
            </div>
        );
    }

    const decreaseModalFlag = () => {
        if (modalFlag !== 0) {
            setModalFlag(modalFlag - 1);
        }
    };

    const increaseModalFlag = () => {
        if (modalFlag < participantBoxes.length - 3) {
            setModalFlag(modalFlag + 1);
        }
    };

    return (
        <>
            {msg && (
                <Snackbar
                    status="error"
                    message={msg}
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                ></Snackbar>
            )}
            <div className={styles.assets}>
                <Row className={styles.whiteboardRow}>
                    <Col className={styles.whiteboardLeft}>
                        {initialState && (
                            <div className={styles.wbContainerLeft}>
                                <div
                                    style={{ width: "617px", height: "645px" }}
                                >
                                    <Whiteboard
                                        myWhiteboard
                                        drawData={teacherDrawData}
                                        session={session}
                                        socket={socket}
                                        // streams={streams}
                                        // role={role}
                                        participants={participants}
                                        selectedMedia={selectedMedia}
                                        // myMarkerColor={myMarkerColor}
                                        key={key}
                                        position={
                                            currentPanel.lesson_settings
                                                .whiteboard.position
                                        }
                                        fitToScreen={fitToScreen}
                                        setFitToScreen={setFitToScreen}
                                        studentAccess={
                                            currentPanel.lesson_settings
                                                .whiteboard.student_access
                                        }
                                        control={true}
                                        requiredWB={true}
                                        orientation="left"
                                        panelID={currentPanel._id}
                                        setPosition={changePosition}
                                    />
                                </div>
                            </div>
                        )}
                    </Col>
                    <Col className={styles.whiteboardRight}>
                        {initialState && (
                            <div className={styles.wbContainerRight}>
                                <div
                                    style={{ width: "617px", height: "645px" }}
                                >
                                    {studentsData.length > 1 && (
                                        <div className={styles.arrowsContainer}>
                                            {studentFlag > 0 && (
                                                <FontAwesomeIcon
                                                    style={{
                                                        color: "#707070",
                                                        width: "30px",
                                                        zIndex: "8",
                                                        cursor: "pointer",
                                                    }}
                                                    icon={faLessThan}
                                                    onClick={
                                                        decreaseStudentFlag
                                                    }
                                                />
                                            )}
                                            <p>
                                                {studentsData[studentFlag].name}
                                            </p>
                                            {studentFlag <
                                                studentsData.length - 1 && (
                                                <FontAwesomeIcon
                                                    style={{
                                                        color: "#707070",
                                                        width: "30px",
                                                        zIndex: "8",
                                                        cursor: "pointer",
                                                    }}
                                                    icon={faGreaterThan}
                                                    onClick={
                                                        increaseStudentFlag
                                                    }
                                                />
                                            )}
                                        </div>
                                    )}

                                    <Whiteboard
                                        drawData={studentDrawData}
                                        socket={socket}
                                        // streams={streams}
                                        // role={role}
                                        participants={participants}
                                        selectedMedia={selectedMedia}
                                        // myMarkerColor={myMarkerColor}
                                        key={key}
                                        // position={
                                        //     currentPanel.lesson_settings
                                        //         .whiteboard.position
                                        // }
                                        fitToScreen={fitToScreen}
                                        setFitToScreen={setFitToScreen}
                                        studentAccess={
                                            currentPanel.lesson_settings
                                                .whiteboard.student_access
                                        }
                                        control={false}
                                        requiredWB={false}
                                        orientation="right"
                                        position={position}
                                        setPosition={setPosition}
                                    />
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>

                <div
                    className={styles.showFeedButton}
                    onClick={() => {
                        setShowFeed((prevFlag) => {
                            return !prevFlag;
                        });
                    }}
                >
                    Video Feed
                </div>

                <div
                    className={`${styles.participantsList} ${
                        showFeed ? styles.smallList : ""
                    }`}
                >
                    {noOfParticipants > 4 && (
                        <FontAwesomeIcon
                            style={{
                                color: "#83838b",
                                width: "30px",
                                position: "absolute",
                                zIndex: "8",
                                cursor: "pointer",
                                transform: "rotate(90deg)",
                                top: "0",
                            }}
                            icon={faLessThan}
                            onClick={decreaseModalFlag}
                        />
                    )}
                    <div
                        className={
                            noOfParticipants > 4
                                ? styles.moreParticipantBoxesCol
                                : styles.lessParticipantBoxesCol
                        }
                    >
                        {participantBoxes
                            .slice(modalFlag, modalFlag + 4)
                            .map((obj, index) => {
                                return obj;
                            })}
                    </div>
                    {noOfParticipants > 4 && (
                        <FontAwesomeIcon
                            style={{
                                color: "#83838b",
                                width: "30px",
                                position: "absolute",
                                zIndex: "8",
                                cursor: "pointer",
                                transform: "rotate(90deg)",
                                bottom: "0",
                            }}
                            icon={faGreaterThan}
                            onClick={increaseModalFlag}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
