import { faArrowRight, faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, TextareaAutosize } from "@material-ui/core";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import ImageInputModal from "./ImageInputModal";
import styles from "./NewMessageComponent.module.scss";

const NewMessageComponent = ({
    sendMessage,
    loadingAPI,
    index,
    isParticipantRemoved,
}) => {
    let userData = localStorage.getItem("auth");
    if (userData) userData = JSON.parse(userData);

    const [messageText, setMessageText] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);

    /**
     * @description Send selected files from input modal.
     */
    const sendFileFunction = () => {
        const formData = new FormData();

        for (let fileIndex in selectedFiles) {
            const file = selectedFiles[fileIndex];

            if (file.file) {
                formData.append("file", file.file);
            } else {
                formData.append("file_ids[]", file._id);
            }
        }

        formData.append("type", "FILE");

        if (messageText) {
            formData.append("text", messageText);
        }

        sendMessage(formData);
        setMessageText("");
        setSelectedFiles([]);
    };

    /**
     * @description Send typed text.
     */
    const sendMessageFunction = () => {
        if (selectedFiles.length) {
            sendFileFunction();
        } else {
            const formData = new FormData();

            formData.append("text", messageText);
            formData.append("type", "TEXT");

            sendMessage(formData);
            setMessageText("");
        }
    };

    return (
        <Fragment>
            <ImageInputModal
                isImageModalOpen={isImageModalOpen}
                setIsImageModalOpen={setIsImageModalOpen}
                sendMessageFunction={sendFileFunction}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
            />

            <div className={styles.newMessageComponent}>
                {loadingAPI ? (
                    <div className={styles.circularProgressContainer}>
                        <CircularProgress />
                    </div>
                ) : isParticipantRemoved ? (
                    <div className={styles.disabledUserMessageText}>
                        You have been removed from the class. You can not
                        message.
                    </div>
                ) : (
                    <Fragment>
                        <TextareaAutosize
                            autoFocus={Boolean(index === 0)}
                            type="text"
                            className={styles.newMessageInput}
                            placeholder="Type your message here"
                            value={messageText}
                            maxRows={4}
                            onChange={(event) =>
                                setMessageText(_.get(event, "target.value"))
                            }
                            onKeyUp={(event) => {
                                if (event.key === "Enter" && event.ctrlKey) {
                                    sendMessageFunction();
                                }
                            }}
                        />

                        <div
                            className={styles.sendFileIconContainer}
                            onClick={() => setIsImageModalOpen(true)}
                        >
                            <FontAwesomeIcon
                                icon={faFolder}
                                className={styles.sendFileIcon}
                            />
                        </div>

                        <div
                            className={styles.sendMessageIconContainer}
                            onClick={sendMessageFunction}
                        >
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                className={styles.sendMessageIcon}
                            />
                        </div>
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
};

export default NewMessageComponent;
