import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import axios from "axios";
import _ from "lodash";
import styles from "./Header.module.css";
import StudentLogoBg from "../../assets/images/LOGO (3).svg";
import Dashboard from "../../assets/images/Dashboard@2x.png";
import Setting from "../../assets/images/icon/Settings.svg";
import Store from "../../assets/images/icon/Store.svg";
import Messages from "../../assets/images/icon/Messages.svg";
import MessagesText from "../../assets/images/icon/MessagesText.svg";
import SettingsText from "../../assets/images/icon/SettingsText.svg";
import StoreText from "../../assets/images/icon/StoreText.svg";
import { useHistory } from "react-router-dom";
import "./style.css";

export default function Header(props) {
    const history = useHistory();
    const [user, setUser] = useState();
    const [msg, setMsg] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showDashboard, setShowDashboard] = useState("");
    const [sessions, setSessions] = useState();
    const [lastSession, setLastSession] = useState();
    const [sessionID, setSessionID] = useState(undefined);

    useEffect(() => {
        if (props.showDashboard !== undefined) {
            setShowDashboard(props.showDashboard);
        } else {
            setShowDashboard(true);
        }
        const getSessionInfo = async () => {
            await axios
                .get(`/api/v1/sessions-current`)
                .then(async (currentSessionsResult) => {
                    await axios
                        .get(`/api/v1/sessions/students`)
                        .then((allUserSessionsResult) => {
                            if (
                                currentSessionsResult.data.success &&
                                allUserSessionsResult.data.success
                            ) {
                                const lastSession =
                                    currentSessionsResult.data.sessions[
                                        currentSessionsResult.data.sessions
                                            .length - 1
                                    ];
                                setSessions(
                                    allUserSessionsResult.data.sessions
                                );

                                if (lastSession) {
                                    setLastSession(lastSession);
                                    const id = lastSession._id;
                                    setSessionID(id);
                                }
                            }
                        })
                        .catch((error) => {
                            setMsg(
                                _.get(
                                    error,
                                    "response.data.title",
                                    "Something went wrong."
                                )
                            );
                            setShowSnackbar(true);
                            setTimeout(function () {
                                setMsg(null);
                            }, 3000);
                            return Promise.reject(error);
                        });
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        const getUserDetails = async () => {
            await axios
                .get("/api/v1/user")
                .then((result) => {
                    setUser(result.data.user);
                })
                .catch((error) => {
                    setMsg(
                        _.get(
                            error,
                            "response.data.title",
                            "Something went wrong."
                        )
                    );
                    setShowSnackbar(true);
                    setTimeout(function () {
                        setMsg(null);
                    }, 3000);
                    return Promise.reject(error);
                });
        };
        getSessionInfo();
        getUserDetails();
        const sessionIntervalObj = setInterval(getSessionInfo, 60000);

        return () => {
            clearInterval(sessionIntervalObj);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <Row className={styles.topRow}>
                <Col md={3}>
                    <div
                        className={styles.logoContainer}
                        onClick={() => window.location.replace("/")}
                    >
                        <img
                            src={StudentLogoBg}
                            className={styles.studentLogoBg}
                            alt="Logo"
                        />
                        <h1 className={styles.logoText}>
                            plassroom<sup>+</sup>
                        </h1>
                    </div>
                </Col>
                <Col md={9}>
                    <div>
                        <Row>
                            <Col md={6}>
                                <div>
                                    <span className={styles.welcomeText}>
                                        {props.heading}
                                    </span>
                                </div>
                            </Col>
                            <Col md={6} style={{ textAlign: "end" }}>
                                <div>
                                    {showDashboard && (
                                        <span className={styles.topIcon}>
                                            <div
                                                className={`row ${styles.iconRow}`}
                                            >
                                                <div
                                                    className={`${styles.iconColumn}`}
                                                >
                                                    <img
                                                        id="MessagesText"
                                                        src={MessagesText}
                                                        className={
                                                            styles.hoverText
                                                        }
                                                        style={{
                                                            visibility:
                                                                "hidden",
                                                        }}
                                                        alt="MessageText"
                                                    ></img>
                                                    <img
                                                        onMouseOver={() =>
                                                            (document.querySelector(
                                                                "#MessagesText"
                                                            ).style.visibility =
                                                                "visible")
                                                        }
                                                        onMouseLeave={() =>
                                                            (document.querySelector(
                                                                "#MessagesText"
                                                            ).style.visibility =
                                                                "hidden")
                                                        }
                                                        id="messageImage"
                                                        src={Messages}
                                                        className={
                                                            styles.dashboardIcon
                                                        }
                                                        onClick={() =>
                                                            (window.location.href =
                                                                "/accountsettings")
                                                        }
                                                        alt="message"
                                                    ></img>
                                                </div>
                                                <div
                                                    className={` ${styles.iconColumn}`}
                                                >
                                                    <img
                                                        style={{
                                                            visibility:
                                                                "hidden",
                                                        }}
                                                        id="storeText"
                                                        src={StoreText}
                                                        className={
                                                            styles.hoverText
                                                        }
                                                        alt="message"
                                                    ></img>
                                                    <img
                                                        onMouseOver={() =>
                                                            (document.querySelector(
                                                                "#storeText"
                                                            ).style.visibility =
                                                                "visible")
                                                        }
                                                        onMouseLeave={() =>
                                                            (document.querySelector(
                                                                "#storeText"
                                                            ).style.visibility =
                                                                "hidden")
                                                        }
                                                        src={Store}
                                                        className={`${styles.dashboardIcon} ${styles.storeHover}`}
                                                        onClick={() =>
                                                            history.push(
                                                                "/home"
                                                            )
                                                        }
                                                        alt="Store"
                                                    ></img>
                                                </div>
                                                <div
                                                    className={` ${styles.iconColumn}`}
                                                >
                                                    <img
                                                        style={{
                                                            visibility:
                                                                "hidden",
                                                        }}
                                                        id="settingText"
                                                        src={SettingsText}
                                                        className={
                                                            styles.hoverText
                                                        }
                                                        alt="message"
                                                    ></img>
                                                    <img
                                                        onMouseOver={() =>
                                                            (document.querySelector(
                                                                "#settingText"
                                                            ).style.visibility =
                                                                "visible")
                                                        }
                                                        onMouseLeave={() =>
                                                            (document.querySelector(
                                                                "#settingText"
                                                            ).style.visibility =
                                                                "hidden")
                                                        }
                                                        src={Setting}
                                                        className={
                                                            styles.dashboardIcon
                                                        }
                                                        onClick={() =>
                                                            (window.location.href =
                                                                "/accountsettings")
                                                        }
                                                        alt="message"
                                                    ></img>
                                                </div>
                                                <div
                                                    className={` ${styles.iconColumn}`}
                                                >
                                                    <img
                                                        style={{
                                                            visibility:
                                                                "hidden",
                                                        }}
                                                        id="dashboardText"
                                                        src={MessagesText}
                                                        className={
                                                            styles.hoverText
                                                        }
                                                        alt="message"
                                                    ></img>
                                                    <img
                                                        onMouseOver={() =>
                                                            (document.querySelector(
                                                                "#dashboardText"
                                                            ).style.visibility =
                                                                "visible")
                                                        }
                                                        onMouseLeave={() =>
                                                            (document.querySelector(
                                                                "#dashboardText"
                                                            ).style.visibility =
                                                                "hidden")
                                                        }
                                                        src={Dashboard}
                                                        className={
                                                            styles.dashboardIcon
                                                        }
                                                        onClick={() =>
                                                            (window.location.href =
                                                                "/accountsettings")
                                                        }
                                                        alt="message"
                                                    ></img>
                                                </div>
                                            </div>
                                        </span>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}
